import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { QyWechatTicket } from "src/models/QyWechatTicket";

export const THIRD_INTEGRATION_SERVICE_NAME = "thirdintegration";
export class ThirdIntegrationServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, THIRD_INTEGRATION_SERVICE_NAME);
  }

  async getQyWechatTicket(companyId: string): Promise<QyWechatTicket> {
    const req = this.buildRequest({
      url: `/v1/qywechat/ticket/corpticket?companyId=${companyId}&url=${encodeURIComponent(
        window.location.href
      )}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }
}
