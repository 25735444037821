/** @jsxImportSource @emotion/react */
import { ExclamationCircleFilled } from "@ant-design/icons";
import { css } from "@emotion/react";
import { message as AntMessage } from "antd";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import Alert from "src/components/Alert";
import Button from "src/components/Button";
import UpgradeVersionMask from "src/components/UpgradeVersionMask";
import NoPermission from "src/containers/errors/NoPermission";
import AppHeader from "src/containers/layout/AppHeader";
import AppMain from "src/containers/layout/AppMain";
import AppSideMenu from "src/containers/layout/AppSideMenu";
import {
  useApplicationInsights,
  useFormatMessage,
  useGetContainer,
  useTheme,
  useUserEdition,
} from "src/hooks";
import messageIds from "src/locales/messageIds";
import { MenuDefinition } from "src/routes/_shared";
import { CurrentPermissionModel } from "src/store/models/logic/currentPermission";
import { GlobalSelectCompanyUIModel } from "src/store/models/logic/globalSelectCompany";
import { GlobalSelectDepartmentModel } from "src/store/models/logic/globalSelectDepartment";
import { RouterModel } from "src/store/models/router";
import { DashboardUIModel } from "src/store/models/ui/dashboard/dashboard";
import { EnvironmentModel } from "src/store/models/ui/environment/environment";
import { ErrorUIModel } from "src/store/models/ui/error/error";
import { LicenseUIModel } from "src/store/models/ui/license/license";
import { QuotaUIModel } from "src/store/models/ui/quota/quota";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";
import { eventEmitter } from "src/utils/eventEmitter";
import { getBreadcrumbRouters } from "src/utils/router";

interface ErrorType {
  code: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: Array<any>;
  message?: string;
  Message?: string;
}

function useValidateLoginUser() {
  const getContainer = useGetContainer();
  const router = getContainer(RouterModel);
  const loginUserUIModel = getContainer(LoginUserUIModel);
  const loggedinUser = useSelector(() => loginUserUIModel.state.loggedinUser);

  useEffect(() => {
    loginUserUIModel.actions.addLogoutListener.dispatch({});
  }, [loginUserUIModel.actions.addLogoutListener]);

  useEffect(() => {
    if (Number(loggedinUser?.status) === 2) {
      router.actions.navigateByRouteInfo.dispatch({
        type: "discontinued",
        params: {},
      });
    }
  }, [loggedinUser, router.actions.navigateByRouteInfo]);
}

function useInitApplicationInsights() {
  const applicationInsights = useApplicationInsights();
  const getContainer = useGetContainer();
  const formatMessage = useFormatMessage();

  const router = getContainer(RouterModel);
  const loginUserUIModel = getContainer(LoginUserUIModel);
  const environmentContainer = getContainer(EnvironmentModel);
  const globalSelectCompanyContainer = getContainer(GlobalSelectCompanyUIModel);
  const globalSelectDepartmentContainer = getContainer(
    GlobalSelectDepartmentModel
  );
  const currentRouteInfo = useSelector(() => router.getters.currentRouteInfo);
  const breadcrumbRouters = useMemo(() => {
    return getBreadcrumbRouters(currentRouteInfo.type);
  }, [currentRouteInfo.type]);

  const pathname = useSelector(() => router.getters.pathname);
  const currentCompanyUser = useSelector(
    () => loginUserUIModel.state.currentCompanyUser
  );
  const getCurrentMenu = useCallback((): MenuDefinition | undefined => {
    return breadcrumbRouters[breadcrumbRouters.length - 1];
  }, [breadcrumbRouters]);
  const environmentVersion = useSelector(
    () => environmentContainer.state.environmentVersion
  );
  const environmentEdition = useSelector(
    () => environmentContainer.state.environmentEdition
  );
  const selectCompany = useSelector(
    () => globalSelectCompanyContainer.getters.selectedGlobalCompony
  );
  const selectedDepartment = useSelector(
    () => globalSelectDepartmentContainer.getters.selectedDepartment
  );

  const [lastVisitedPageInfo, setLastVisitedPageInfo] = useState<{
    time: string;
    url: string;
    name?: string;
  } | null>(null);

  const onloadApplicationInsights = useCallback(() => {
    applicationInsights.loadAppInsights();
  }, [applicationInsights]);

  useEffect(() => {
    eventEmitter.addListener("loadAppInsights", onloadApplicationInsights);
    if (eventEmitter.events.get("loadAppInsights")?.length === 1) {
      onloadApplicationInsights();
    }
  }, [onloadApplicationInsights]);

  const setCommonInsightParams = useCallback(() => {
    if (
      currentCompanyUser &&
      selectCompany &&
      environmentVersion &&
      environmentEdition &&
      selectedDepartment
    ) {
      const consoleType =
        environmentEdition === "Private"
          ? "私有化企业版"
          : selectCompany.edition === "Enterprise"
          ? "公有云企业版"
          : "个人免费版";

      applicationInsights.commonProperties = {
        UserID: currentCompanyUser.userId,
        ConsoleType: consoleType,
        ConsoleVersion: environmentVersion,
        //TODO api暂没提供手机号
        // UserPhone: currentCompanyUser.phoneNumber,
        // TODO 邮箱api未提供
        UserEmail: currentCompanyUser.email,
        CompanyID: selectCompany.companyId,
        CompanyName: selectCompany.name,
        DepartmentID: selectedDepartment.id,
      };
    }
  }, [
    applicationInsights,
    currentCompanyUser,
    environmentEdition,
    environmentVersion,
    selectCompany,
    selectedDepartment,
  ]);

  const sendTrackPageView = useCallback(() => {
    const menu = getCurrentMenu();
    if (applicationInsights.commonProperties.UserID) {
      applicationInsights.sendTrackEvent(
        { name: "Console_AccessPageEvent" },
        {
          AccessMenu: menu?.name && formatMessage(menu.name),
          StartAccessTime: Date(),
        }
      );
      setLastVisitedPageInfo({
        time: Date(),
        url: window.location.href,
        name: menu?.name,
      });
    }
  }, [applicationInsights, formatMessage, getCurrentMenu]);

  const sendTrackLastPage = useCallback(() => {
    if (applicationInsights.commonProperties.UserID) {
      if (
        lastVisitedPageInfo &&
        lastVisitedPageInfo.url !== window.location.href
      ) {
        applicationInsights.sendTrackEvent(
          { name: "Console_AccessPageEvent" },
          {
            AccessMenu:
              lastVisitedPageInfo.name &&
              formatMessage(lastVisitedPageInfo.name),
            StartAccessTime: lastVisitedPageInfo.time,
            EndAccessTime: Date(),
            Url: lastVisitedPageInfo.url,
          }
        );
      }
    }
  }, [applicationInsights, formatMessage, lastVisitedPageInfo]);

  useEffect(() => {
    setCommonInsightParams();
  }, [setCommonInsightParams]);

  useEffect(() => {
    sendTrackLastPage();
    return () => {
      if (lastVisitedPageInfo) {
        applicationInsights.sendTrackEvent(
          { name: "Console_AccessPageEvent" },
          {
            AccessMenu: lastVisitedPageInfo?.name,
            StartAccessTime: lastVisitedPageInfo?.time,
            EndAccessTime: Date(),
            Url: window.location.href,
          }
        );
      }
    };
  }, [applicationInsights, lastVisitedPageInfo, pathname, sendTrackLastPage]);

  useEffect(() => {
    sendTrackPageView();
  }, [pathname, sendTrackPageView]);
}

function useErrorHandler() {
  const formatMessage = useFormatMessage();
  const getContainer = useGetContainer();
  const errorModel = getContainer(ErrorUIModel);

  // todo 优化
  useEffect(() => {
    errorModel.actions.registerError.dispatch({
      errorAction: (err) => {
        if (!err.response) return;
        switch (err.response.status) {
          case 404:
            return AntMessage.info(
              formatMessage(messageIds.errors.notFound.request)
            );
          case 401:
            return;
          case 406:
            if (err.response.data.code === "ReferencedByOthers") {
              return;
            }
        }
        if (err.response.data) {
          switch (
            Object.prototype.toString
              .call(err.response.data)
              .slice(8, -1)
              .toLowerCase()
          ) {
            case "string":
              AntMessage.error(err.response.data);
              break;
            case "object": {
              const { message, Message, errors } = err.response
                .data as ErrorType;
              const errorMessage = [] as Array<string>;
              if (errors && Array.isArray(errors)) {
                errors.forEach((item: { errors: Array<{ error: string }> }) => {
                  item.errors.forEach((childItem) => {
                    if (childItem.error !== message) {
                      errorMessage.push(childItem.error);
                    }
                  });
                });
                if (errorMessage.length) {
                  const errorContent = (
                    <div
                      css={css`
                        display: inline-block;
                        vertical-align: top;
                        text-align: left;
                      `}
                    >
                      <div
                        css={css`
                          font-weight: 600;
                        `}
                      >
                        {message}
                      </div>
                      {errorMessage.map((item) => (
                        <div key={item}>{item}</div>
                      ))}
                    </div>
                  );
                  return AntMessage.error(errorContent, 5);
                }
              }
              AntMessage.error(message ?? Message);
              break;
            }
          }
        }
      },
    });
  }, [errorModel.actions.registerError, formatMessage]);
}
function usePermission() {
  const getContainer = useGetContainer();
  const permissionContainer = getContainer(CurrentPermissionModel);
  const permissionShowTypeValues = useSelector(
    () => permissionContainer.getters.permissionShowTypeValues
  );
  const loadedPermissions = useSelector(
    () => permissionContainer.state.loadedPermissions
  );
  const loadPermissionsFailed = useSelector(
    () => permissionContainer.state.loadPermissionsFailed
  );
  const router = getContainer(RouterModel);
  const routerNeedShowPermissions = useSelector(
    () => router.getters.currentRouteInfo.showPermissions
  );

  const noPermission = useMemo(() => {
    if (!loadedPermissions || loadPermissionsFailed) {
      return null;
    }
    const noPermission = routerNeedShowPermissions
      ? (routerNeedShowPermissions instanceof Array
          ? routerNeedShowPermissions
          : [routerNeedShowPermissions]
        ).every((item) => permissionShowTypeValues?.[item] === "Hidden")
      : false;
    return noPermission ? <NoPermission /> : null;
  }, [
    loadPermissionsFailed,
    loadedPermissions,
    permissionShowTypeValues,
    routerNeedShowPermissions,
  ]);

  return { noPermission };
}

function usePermissionShowLimited() {
  const getContainer = useGetContainer();
  const permissionContainer = getContainer(CurrentPermissionModel);
  const permissionShowTypeValues = useSelector(
    () => permissionContainer.getters.permissionShowTypeValues
  );

  const router = getContainer(RouterModel);
  const routerNeedShowPermissions = useSelector(
    () => router.getters.currentRouteInfo.showPermissions
  );

  const isRestrictedUsed = useMemo(() => {
    return routerNeedShowPermissions
      ? (routerNeedShowPermissions instanceof Array
          ? routerNeedShowPermissions
          : [routerNeedShowPermissions]
        ).every((item) => permissionShowTypeValues?.[item] === "ShowLimited")
      : false;
  }, [permissionShowTypeValues, routerNeedShowPermissions]);

  return { isRestrictedUsed };
}

export default React.memo(function AppLayout() {
  const formatMessage = useFormatMessage();
  const getContainer = useGetContainer();
  const theme = useTheme();
  const router = getContainer(RouterModel);
  const quota = getContainer(QuotaUIModel);

  useValidateLoginUser();
  useInitApplicationInsights();
  useErrorHandler();
  const { noPermission } = usePermission();
  const { isRestrictedUsed } = usePermissionShowLimited();
  const userEdition = useUserEdition();

  const globalSelectCompanyContainer = getContainer(GlobalSelectCompanyUIModel);

  const currentRouteInfo = useSelector(() => router.getters.currentRouteInfo);

  const licenseContainer = getContainer(LicenseUIModel);

  const dashboardUIModel = getContainer(DashboardUIModel);

  const consoleWarnings = useSelector(
    () => licenseContainer.state.consoleWarnings
  );

  const dingTalk = useMemo(
    () => router.getters.searchParams.get("dingTalk"),
    [router.getters.searchParams]
  );

  const currentRouteType = useSelector(
    () => router.getters.currentRouteInfo.type
  );

  const selectCompanyId = useSelector(
    () => globalSelectCompanyContainer.state.selectedGlobalComponyId
  );

  const mainNode = useMemo(() => {
    if (currentRouteInfo.component) {
      const LoadComponent = React.lazy(
        () => import(`${currentRouteInfo.component}`)
      );
      return (
        <Suspense fallback={null}>
          {isRestrictedUsed && <UpgradeVersionMask />}
          <LoadComponent />
        </Suspense>
      );
    }
    return null;
  }, [currentRouteInfo.component, isRestrictedUsed]);

  const navigateToLicense = useCallback(() => {
    router.actions.navigateByRouteInfo.dispatch({
      type: "license",
      params: {},
    });
  }, [router.actions.navigateByRouteInfo]);

  const navigateToOrders = useCallback(() => {
    router.actions.navigateByRouteInfo.dispatch({
      type: "userCenter",
      params: {
        path: "orders",
      },
    });
  }, [router.actions.navigateByRouteInfo]);

  const onCloseNotice = useCallback(async () => {
    await licenseContainer.actions.closeNotice.dispatch({});
    sessionStorage.setItem("notificationStatus", "closed");
  }, [licenseContainer.actions.closeNotice]);

  useEffect(() => {
    if (selectCompanyId) {
      licenseContainer.actions.getWarnings.dispatch({});
      quota.actions.getQuotalist.dispatch({});
      dashboardUIModel.actions.timerangelimit.dispatch({});
    }
  }, [licenseContainer.actions.getWarnings, selectCompanyId, quota.actions.getQuotalist, dashboardUIModel.actions.timerangelimit]);

  if (
    currentRouteInfo.type === "companyEntrySelect" ||
    currentRouteInfo.type === "discontinued"
  ) {
    return <AppMain>{mainNode}</AppMain>;
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        min-width: 0;
        min-height: 0;
        background-color: ${theme.bodyFrameBackground};
      `}
    >
      <AppHeader />
      {consoleWarnings &&
        sessionStorage.getItem("notificationStatus") !== "closed" && (
          <Alert
            banner
            showIcon={false}
            closable
            type="warning"
            message={
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 16px;
                `}
              >
                <ExclamationCircleFilled
                  style={{ color: "#faad14", marginRight: 10 }}
                />
                {consoleWarnings}
                <Button
                  type="primary"
                  size="small"
                  onClick={navigateToLicense}
                  css={css`
                    margin-left: 10px;
                    border-radius: 4px;
                  `}
                >
                  {formatMessage(messageIds.common.view)}
                </Button>
                {userEdition === "Community" && (
                  <Button
                    type="primary"
                    size="small"
                    onClick={navigateToOrders}
                    css={css`
                      margin-left: 10px;
                      border-radius: 4px;
                    `}
                  >
                    {formatMessage(messageIds.personal.myOrder.buttons.renew)}
                  </Button>
                )}
              </div>
            }
            onClose={onCloseNotice}
          />
        )}

      <div
        css={css`
          display: flex;
          flex-grow: 1;
          min-width: 0;
          min-height: 0;
        `}
      >
        {!dingTalk &&
          ![
            "paymentOrder",
            "paymentProduct",
            "index",
            "userCenter",
            "confirmedProduct",
            "renewOrder",
          ].includes(currentRouteType) && <AppSideMenu />}
        <div
          css={css`
            display: flex;
            flex-grow: 1;
            min-width: 0;
            min-height: 0;
          `}
        >
          {selectCompanyId && (
            <AppMain>{noPermission ? noPermission : mainNode}</AppMain>
          )}
        </div>
      </div>
    </div>
  );
});
