import { CoeToDoWorkDetailUIModel } from "src/store/models/ui/coeToDoWork/coeToDoWorkDetail";
import { CoeToDoWorkHistoryUIModel } from "src/store/models/ui/coeToDoWork/coeToDoWorkHistory";
import { CoeToDoWorkSideUIModel } from "src/store/models/ui/coeToDoWork/coeToDoWorkSide";
import { CoeToDoWorkTableUIModel } from "src/store/models/ui/coeToDoWork/coeToDoWorkTable";

export const encooCoeToDoWorkUIModels = {
  coeToDoWorkTable: CoeToDoWorkTableUIModel,
  coeToDoWorkSide: CoeToDoWorkSideUIModel,
  coeToDoWorkDetail: CoeToDoWorkDetailUIModel,
  coeToDoWorkHistory: CoeToDoWorkHistoryUIModel,
};
