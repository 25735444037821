import { createModel, createSelector } from "nyax";
import { Queue } from "src/models/queue";
import { ModelBase } from "src/store/ModelBase";
import { DataPermissionEntityModel } from "src/store/models/entity/dataPermission/entity";
import { QueueEntityModel } from "src/store/models/entity/queue/entity";
import { QueueHelperModel } from "src/store/models/entity/queue/helper";

export const QueueDetailV2UIModel = createModel(
  class extends ModelBase {
    private get _id() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.containerKey!;
    }
    public selectors() {
      return {
        queueDetail: createSelector(
          () => this.getContainer(QueueEntityModel).state.byId,
          () => this.getContainer(DataPermissionEntityModel).state.byId,
          (queueById, permissionById): Queue | null => {
            const item = queueById[this._id];
            return item
              ? {
                  ...item,
                  permissionValues:
                    permissionById[this._id]?.permissionValues ?? [],
                }
              : null;
          }
        ),
      };
    }
    public effects() {
      return {
        requestDetail: async () => {
          await this.getContainer(QueueHelperModel).actions.getById.dispatch({
            queueId: this._id,
          });
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
