// eslint-disable-next-line import/no-anonymous-default-export
export default {
  userCenter: {
    userInfo: "个人信息",
    settings: "基本设置",
    orders: "用户订单",
    name: "个人姓名",
    phone: "手机号",
    email: "邮箱",
    wechat: "微信",
    binding: "绑定",
    replace: "更换",
    unbind: "解绑",
    setDefaultTenant: "设置默认租户",
    setDefaultResource: "设置默认资源组",
    setDefaultTimeZone: "设置默认时区",
    setDefaultLanguage: "设置默认语言",
    updateName: "修改姓名",
    currentName: "当前姓名",
    currentPhone: "当前手机号",
    currentEmail: "当前邮箱",
    placeholder: {
      inputName: "姓名不能为空",
      inputBindingEmail: "请输入绑定的邮箱",
      inputEmailCode: "请输入邮箱验证码",
      inputOriginalPassword: "请输入原始密码",
      inputNewPassword:
        "请输入新密码（请输入8-16个字符，不包含空格，区分大小）",
      inputNewPasswordAgain: "请确认登陆密码",
      inputNewEmail: "请输入新的邮箱",
      inputNewPhone: "请输入新的手机号",
      inputPhoneCode: "请输入短信验证码",
      passwordNotMatch: "两次输入的密码不匹配",
      nameLimited: "名字最多输入35个字符",
    },
    passwordConditions:
      "密码格式为8-16个字符，至少包含大写、小写、数字、特殊字符任意3种",
    passwordConditionError: "请输入正确格式的密码",
    unbindEmailTips:
      "您是否要解绑邮箱 【{email}】? 解绑后，将无法通过此{type}登录、接收平台信息。",
    unbindPhoneTips:
      "您是否要解绑手机号 【{phoneNumber}】? 解绑后，将无法通过此{type}登录、接收平台信息。",
    unbindWeChatTips:
      "您是否要解绑微信 【{wechat}】? 解绑后，将无法通过此{type}登录、接收平台信息。",
    bindEmail: "绑定邮箱",
    updatePassword: "修改密码",
    updatePhone: "更换手机号",
    updateEmail: "更换邮箱",
    bindWeChat: "绑定微信",
    bindWeChatTip: "绑定微信后，可使用微信扫码方式登录",
    getVerificationCode: "获取验证码",
    tenantName: "用户姓名",
    selectBuiltInAvatar: "选择内置头像",
    signOut: "注销账号",
  },
};
