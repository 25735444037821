/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import {
  CustomPermissionRoleInfo,
  NewPermissionRoleInfo,
  PermissionTable,
} from "src/models/permissionSetting";
import { RoleQuery } from "src/models/role";
import { EncooListEntity } from "src/models/_shared";

export const SERVICE_NAME = "customPermissionRole";

export class CustomPermissionRoleServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, SERVICE_NAME);
  }

  // 1. 获取请求列表
  list = async (
    pageIndex?: number,
    pageSize?: number,
    query?: RoleQuery
  ): Promise<EncooListEntity<CustomPermissionRoleInfo>> => {
    const req = this.buildRequestV2({
      url: `/v3/RoleManagement`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
        keyword: query?.keyword,
        roleFrom: query?.roleFrom,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  };

  // 1.1 获取请求列表_old
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async getRoleList() {
    const req = this.buildRequestV2({
      url: `/v2/RoleManagement`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  // 2. 获取默认的权限表格
  async getPermissionTable(): Promise<PermissionTable> {
    const req = this.buildRequestV2({
      url: `/v2/PermissionTemplates/permissiontable`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  // 3. 创建自定义模板
  async createNewRole(
    data: NewPermissionRoleInfo
  ): Promise<CustomPermissionRoleInfo> {
    const payload = data;
    const req = this.buildRequestV2({
      url: `/v3/RoleManagement`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  // 4. 获取权限模板详情
  async getRole(id: string) {
    const req = this.buildRequestV2({
      url: `/v2/permissiontemplates/${id}`,
      method: "GET",
    });
    return await this.sendRequest(req);
  }

  // 5. 修改权限模板
  async editRole(
    id: string,
    payload: NewPermissionRoleInfo
  ): Promise<CustomPermissionRoleInfo> {
    const req = this.buildRequestV2({
      url: `/v3/RoleManagement/${id}`,
      method: "PATCH",
      payload,
    });
    return await super.updateInternal(req);
  }

  // 6. 删除权限模板
  async deleteRole(id: string) {
    const req = this.buildRequestV2({
      url: `/v3/RoleManagement/${id}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }
}
