import { createModel } from "nyax";
import { FeatureData } from "src/models/feature";
import { ModelBase } from "src/store/ModelBase";

export const FeatureModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        featureData: null as FeatureData | null,
      };
    }

    public reducers() {
      return {
        setFeatureData: (value: FeatureData | null) => {
          this.state.featureData = value;
        },
      };
    }

    public effects() {
      return {
        requestFeature: async () => {
          const value = await this.dependencies.serviceClient.feature.getAll();
          await this.actions.setFeatureData.dispatch(value);
        },
      };
    }
  }
);
