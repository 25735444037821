import { createModel } from "nyax";
import { AppV2 } from "src/models/appV2";
import { ViCodeApp, ViCodeAppStatus } from "src/models/vicodeApp";
import { ModelBase } from "src/store/ModelBase";
import { AppHelperModel } from "src/store/models/entity/apps/helper";
import { ViCodeAppHelperModel } from "src/store/models/entity/vicodeApp/helper";

export type ViCodeAppWithPermission = ViCodeApp & {
  permissionValues?: string[];
};

export interface ViCodeAppQuery {
  nameLike?: string;
  appStatus?: ViCodeAppStatus;
}

export interface ViCodeAppCreationData {
  name: string;
  description?: string;
  icon?: string;
}

export const UIViCodeAppSharedModel = createModel(
  class extends ModelBase {
    private get _vicodeAppHelper() {
      return this.getContainer(ViCodeAppHelperModel);
    }

    private get _appV1Helper() {
      return this.getContainer(AppHelperModel);
    }

    public effects() {
      return {
        favorite: async (payload: { app: ViCodeApp | AppV2 }) => {
          const { app } = payload;
          const isViCodeApp = !("anonymous" in app);

          if (isViCodeApp) {
            if (app.isFavorite) {
              await this._vicodeAppHelper.actions.deleteFavorite.dispatch({
                appId: app.id,
              });
            } else {
              await this._vicodeAppHelper.actions.addFavorite.dispatch({
                appId: app.id,
              });
            }
            await this._vicodeAppHelper.actions.readById.dispatch({
              id: app.id,
              force: true,
            });
          } else {
            if (app.isFavorite) {
              await this._appV1Helper.actions.deleteFavorite.dispatch({
                appId: app.id,
              });
            } else {
              await this._appV1Helper.actions.addFavorite.dispatch({
                appId: app.id,
              });
            }
            await this._appV1Helper.actions.readById.dispatch({
              id: app.id,
              force: true,
            });
          }
        },
      };
    }
  }
);
