// eslint-disable-next-line import/no-anonymous-default-export
export default {
  auditlog: {
    introduction:
      "This page displays the detailed operation records of each user of the console for the convenience of audit and tracking.",
    createdAt: "Operation Time",
    auditObjectTypeDisplayName: "Operation Details",
    auditObjectType: "Operation Module",
    userDisplayName: "Operator",
    startTime: "Start Time",
    endTime: "End Time",
  },
};
