import {
  Constants,
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { JobGroup, JobGroupQuery } from "src/models/job";
import {
  TaskScheduler,
  WorkflowLayout,
  WorkflowLayoutCopyData,
  WorkflowLayoutCreationData,
  WorkflowLayoutQuery,
  WorkflowLayoutTriggerCreationData,
  WorkflowLayoutTriggerUpdateData,
  WorkflowLayoutUpdateData,
} from "src/models/workflowLayout";
import { EncooBatchResult, EncooListEntity } from "src/models/_shared";

export const WORKFLOWLAYOUT_SERVICE_NAME = "workflowLayout";
export class WorkflowLayoutService extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, WORKFLOWLAYOUT_SERVICE_NAME);
  }

  async list(
    departmentId: string,
    pageIndex: number,
    pageSize: number,
    query?: WorkflowLayoutQuery
  ): Promise<EncooListEntity<WorkflowLayout>> {
    const req = this.buildRequestV2({
      url: `/v2/workflowlayouts`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
        orderBy: query?.orderBy ?? "CreatedAt",
        searchString: query?.searchString,
        timeSearchBy: query?.timeSearchBy ?? "CreatedAt",
        startTime: undefined,
        endTime: undefined,
        isDesc: (query?.sortOrder && query?.sortOrder === "descend") ?? true,
      },
      departmentId,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getById(workflowLayoutId: string): Promise<WorkflowLayout> {
    const req = this.buildRequestV2({
      url: `/v2/workflowLayouts/${workflowLayoutId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async create(payload: WorkflowLayoutCreationData): Promise<WorkflowLayout> {
    const req = this.buildRequestV2({
      url: `/v2/workflowLayouts`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  async update(param: {
    id: string;
    workflowLayout: WorkflowLayoutUpdateData;
  }): Promise<WorkflowLayout> {
    const req = this.buildRequestV2({
      url: `/v2/workflowLayouts/${param.id}`,
      method: "PATCH",
      payload: param.workflowLayout,
    });
    if (param.workflowLayout.eTag) {
      req.headers?.set(
        Constants.HeaderConstants.IF_MATCH,
        param.workflowLayout.eTag
      );
    }
    return await super.updateInternal(req);
  }

  async delete(workflowLayoutId: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/workflowLayouts/${workflowLayoutId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }

  async batchDelete(workflowLayoutIds: string[]): Promise<EncooBatchResult> {
    const req = this.buildRequestV2({
      url: `/v2/workflowlayouts/batch/delete`,
      method: "POST",
      payload: {
        workflowLayoutIds,
      },
    });
    return await super.updateInternal<EncooBatchResult>(req);
  }

  async batchExecute(workflowLayoutIds: string[]): Promise<EncooBatchResult> {
    const req = this.buildRequestV2({
      url: `/v2/workflowlayouts/batch/execute`,
      method: "POST",
      payload: {
        workflowLayoutIds,
      },
    });
    return await super.updateInternal<EncooBatchResult>(req);
  }

  async execute(workflowLayoutId: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/workflowlayouts/${workflowLayoutId}/execute`,
      method: "POST",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async copy(
    workflowLayoutId: string,
    payload: WorkflowLayoutCopyData
  ): Promise<WorkflowLayout> {
    const req = this.buildRequestV2({
      url: `/v2/workflowlayouts/${workflowLayoutId}/copy`,
      method: "POST",
      payload,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async triggerList(
    workflowLayoutId: string,
    pageIndex: number,
    pageSize: number
  ): Promise<EncooListEntity<TaskScheduler>> {
    const req = this.buildRequestV2({
      url: `/v2/workflowlayouts/${workflowLayoutId}/triggers`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getTriggerById(
    workflowLayoutId: string,
    triggerId: string
  ): Promise<TaskScheduler> {
    const req = this.buildRequestV2({
      url: `/v2/workflowLayouts/${workflowLayoutId}/triggers/${triggerId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async createTrigger(
    workflowLayoutId: string,
    payload: WorkflowLayoutTriggerCreationData
  ): Promise<TaskScheduler> {
    const req = this.buildRequestV2({
      url: `/v2/workflowlayouts/${workflowLayoutId}/triggers`,
      method: "POST",
      payload,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async updateTrigger(
    triggerId: string,
    workflowLayoutId: string,
    payload: WorkflowLayoutTriggerUpdateData
  ): Promise<TaskScheduler> {
    const req = this.buildRequestV2({
      url: `/v2/workflowLayouts/${workflowLayoutId}/triggers/${triggerId}`,
      method: "PATCH",
      payload: payload,
    });
    if (payload.eTag) {
      req.headers?.set(Constants.HeaderConstants.IF_MATCH, payload.eTag);
    }
    return await super.updateInternal(req);
  }

  async deleteTrigger(
    workflowLayoutId: string,
    triggerId: string
  ): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/workflowLayouts/${workflowLayoutId}/triggers/${triggerId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }

  async enableDisabledTrigger(
    workflowLayoutId: string,
    triggerId: string,
    isEnabled: boolean
  ): Promise<TaskScheduler> {
    let url = `/v2/workflowLayouts/${workflowLayoutId}/triggers/${triggerId}/disable`;
    if (isEnabled) {
      url = `/v2/workflowLayouts/${workflowLayoutId}/triggers/${triggerId}/enable`;
    }
    const req = this.buildRequestV2({
      url: url,
      method: "PATCH",
    });
    return await this.updateInternal(req);
  }

  //#region  Jobs API
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async listAllJobs(
    // departmentId: string,
    workflowLayoutId: string,
    pageIndex?: number,
    pageSize?: number,
    query?: JobGroupQuery
  ): Promise<EncooListEntity<JobGroup>> {
    const req = this.buildRequestV2({
      url: `/v2/JobGroups`,
      method: "GET",
      query: {
        ownerId: workflowLayoutId,
        pageIndex,
        pageSize,
        // orderBy: query?.orderBy,
        queryTimeType: query?.queryTimeType,
        startQueryTime: query?.startQueryTime,
        endQueryTime: query?.endQuerytime,
        // isDesc: query?.sortOrder === "descend",
        // timeZone: -new Date().getTimezoneOffset(),
      },
      // departmentId,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getJobGroupById(jobGroupId: string): Promise<JobGroup> {
    const req = this.buildRequestV2({
      url: `v2/JobGroups/${jobGroupId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async stopJobGroup(jobGroupId: string): Promise<boolean> {
    const url = `/v2/JobGroups/${jobGroupId}/stop`;
    const req = this.buildRequestV2({
      url: url,
      method: "POST",
    });
    return await this.updateInternal(req);
  }

  async deleteJobGroup(jobGroupId: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/JobGroups/${jobGroupId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }
}
