/** @jsxImportSource @emotion/react */
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { css } from "@emotion/react";
import { Col, Modal, Row } from "antd";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import Button from "src/components/Button";
import { MODAL_BODY_MAXHEIGHT, ModalSizeType } from "src/components/Modal";
import { useFormatMessage, useTheme } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { reactMemo } from "src/utils/react";

interface FooterProps {
  okText?: ReactNode | string;
  cancelText?: ReactNode | string;
  cancelVisible?: boolean;
  okVisible?: boolean;
  onOk?: () => Promise<boolean | void>;
  onCancel?: () => Promise<boolean | void>;
}
interface ConfigTypes extends Omit<FooterProps, "onOk" | "onCancel"> {
  className?: string;
  type?: "error" | "info" | "success" | "warning";
  title?: string;
  content: ReactNode | ((key: number) => ReactNode);
  headerVisible?: boolean;
  onOk?: () => void;
  onCancel?: () => void;
  footer?: ReactNode;
  width?: number;
  size?: ModalSizeType;
}
interface PackConfigTypes extends ConfigTypes {
  key: number;
}

interface AlertNodeProps {
  config: PackConfigTypes;
  destroy: (key: number) => void;
}

export const Footer = reactMemo(function Footer(props: FooterProps) {
  const {
    okText,
    onOk,
    cancelText,
    onCancel,
    cancelVisible = true,
    okVisible = true,
  } = props;
  const formatMessage = useFormatMessage();

  return (
    <div
      css={css`
        button {
          margin-left: 20px !important;
          min-width: 88px;
        }
      `}
    >
      {cancelVisible ? (
        <Button onClick={onCancel}>
          {cancelText || formatMessage(messageIds.common.cancel)}
        </Button>
      ) : null}
      {okVisible ? (
        <Button type="primary" onClick={onOk}>
          {okText || formatMessage(messageIds.common.confirm)}
        </Button>
      ) : null}
    </div>
  );
});

export type ContextTypes = {
  show: (config: ConfigTypes) => number;
  close: (key: number) => void;
} | null;

const Context = React.createContext<ContextTypes>(null);

export const AlertProvider = React.memo(function AlertProvider(props) {
  const [configs, setConfigs] = useState<PackConfigTypes[]>([]);
  const destroyHandler = useCallback(
    (key: number) => {
      setConfigs((configs) =>
        configs.filter((item: PackConfigTypes) => item.key !== key)
      );
    },
    [setConfigs]
  );
  const showFn = useCallback((config) => {
    const key = Date.now();
    setConfigs((configs) => [...configs, { key, ...config }]);
    return key;
  }, []);

  const deleteFn = useCallback((id: number) => {
    setConfigs((configs) =>
      configs.filter((item: PackConfigTypes) => item.key !== id)
    );
  }, []);

  const providerValue = useMemo(
    () => ({
      show: showFn,
      close: deleteFn,
    }),
    [deleteFn, showFn]
  );
  return (
    <Context.Provider value={providerValue}>
      {configs.map((item: PackConfigTypes) => {
        return (
          <AlertNode
            key={item.key}
            config={item}
            destroy={destroyHandler}
          ></AlertNode>
        );
      })}
      {props.children}
    </Context.Provider>
  );
});
const AlertNode = React.memo(function (props: AlertNodeProps) {
  const formatMessage = useFormatMessage();
  const theme = useTheme();
  const typeMap = useMemo(
    () => ({
      error: (
        <CloseCircleOutlined
          css={css`
            color: ${theme.errorColor};
          `}
        />
      ),
      info: (
        <InfoCircleOutlined
          css={css`
            color: ${theme.primaryColor};
          `}
        />
      ),
      success: (
        <CheckCircleOutlined
          css={css`
            color: ${theme.successColor};
          `}
        />
      ),
      warning: (
        <InfoCircleOutlined
          css={css`
            color: ${theme.rownColor};
          `}
        />
      ),
    }),
    [theme.errorColor, theme.primaryColor, theme.rownColor, theme.successColor]
  );
  const { destroy: destory, config } = props;
  const {
    key,
    className,
    title = formatMessage(messageIds.common.alertTitle),
    okText = formatMessage(messageIds.common.confirm),
    content,
    type,
    cancelText = formatMessage(messageIds.common.cancel),
    onOk,
    onCancel,
    cancelVisible = true,
    okVisible = true,
    footer,
    width,
    size,
    ...restProps
  } = config;

  const modalWidth = useMemo(() => {
    const defaultWidth = 480;
    if (width) {
      return width;
    }
    if (type) {
      return defaultWidth;
    }
    switch (size) {
      case "small":
        return defaultWidth;
      case "large":
        return 800;
      default:
        return defaultWidth;
    }
  }, [width, type, size]);

  const [visible, setVisible] = useState(true);
  const canceHandle = useCallback(async () => {
    const flag = (await onCancel?.()) || false;
    setVisible(flag);
    if (!flag) destory(key);
  }, [onCancel, destory, key]);

  const sureHandle = useCallback(async () => {
    const flag = (await onOk?.()) || false;
    setVisible(flag);
    if (!flag) destory(key);
  }, [destory, key, onOk]);

  const renderContent = useCallback(() => {
    return (
      <Row
        align="top"
        css={css`
          .alert-icon {
            font-size: 20px;
            line-height: 1;
          }
          .alert-content {
            flex: 1;
          }
        `}
        wrap={false}
      >
        {type ? (
          <Col className="alert-icon" flex="30px">
            {typeMap[type]}
          </Col>
        ) : null}
        <Col className="alert-content">
          {typeof content === "function" ? content(config.key) : content}
        </Col>
      </Row>
    );
  }, [config.key, content, type, typeMap]);
  return (
    <Modal
      title={title}
      width={modalWidth}
      visible={visible}
      onCancel={canceHandle}
      maskClosable={false}
      css={css`
        .ant-modal-header {
          border-bottom: 0;
          padding: 20px;
          .ant-modal-title {
            font-weight: 600;
          }
        }
        .ant-modal-body {
          padding: 0 20px;
          max-height: ${MODAL_BODY_MAXHEIGHT};
          overflow-y: auto;
        }
        .ant-modal-footer {
          border-top: 0;
          padding: 20px;
        }
        .ant-modal-footer button + button {
          margin-left: 20px;
        }
      `}
      className={className}
      destroyOnClose={true}
      footer={
        footer !== undefined ? (
          footer
        ) : (
          <Footer
            cancelVisible={cancelVisible}
            okVisible={okVisible}
            okText={okText}
            onOk={sureHandle}
            cancelText={cancelText}
            onCancel={canceHandle}
          />
        )
      }
      {...restProps}
    >
      {renderContent()}
    </Modal>
  );
});
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDialog = function useDialog() {
  const value = useContext(Context);
  return useCallback(
    (config: Omit<ConfigTypes, "headerVisible">) => {
      return value?.show(config);
    },
    [value]
  );
};
export const useCloseDialog = function useDialog() {
  const value = useContext(Context);
  return useCallback(
    (key: number) => {
      return value?.close(key);
    },
    [value]
  );
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useModal = function useDialog() {
  const value = useContext(Context);
  return useMemo(
    () => ({
      info: (config: Omit<ConfigTypes, "headerVisible" | "type">) => {
        value?.show({ headerVisible: false, type: "info", ...config });
      },
      error: (config: Omit<ConfigTypes, "headerVisible" | "type">) => {
        value?.show({ headerVisible: false, type: "error", ...config });
      },
      success: (config: Omit<ConfigTypes, "headerVisible" | "type">) => {
        value?.show({ headerVisible: false, type: "success", ...config });
      },
      warning: (config: Omit<ConfigTypes, "headerVisible" | "type">) => {
        value?.show({ headerVisible: false, type: "warning", ...config });
      },
    }),
    [value]
  );
};
