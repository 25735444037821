/** @jsxImportSource @emotion/react */
import {
  ConnectorMappingItem,
  DataConnection,
  MappingEditor,
} from "@encoo-web/encoo-ui";
import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useGetContainer, useGlobalDepartment } from "src/hooks";
import { DataConnectionEntityModel } from "src/store/models/entity/dataConnection/entity";
import { DataConnectionHelperModel } from "src/store/models/entity/dataConnection/helper";
import { DataConnectionUiModel } from "src/store/models/ui/dataConnection/dataConnection";

export default React.memo(function AppMapping(props: {
  onMappingsDataChange: (data: ConnectorMappingItem[]) => void;
  mappings: ConnectorMappingItem[];
}) {
  const getContainer = useGetContainer();

  const dataConnectionContainer = getContainer(DataConnectionUiModel);
  const dataConnectionEntityContainer = getContainer(DataConnectionEntityModel);
  const dataConnectionHelper = getContainer(DataConnectionHelperModel);
  const dataConnectionTemplateInfos = useSelector(
    () => dataConnectionContainer.state.dataConnectionTemplateInfos
  );

  const { mappings, onMappingsDataChange } = props;
  const department = useGlobalDepartment();
  const dataConnectionList = useSelector(
    () => dataConnectionEntityContainer.getters.items
  );

  const list = useMemo(() => {
    return dataConnectionList.map((item) => {
      return {
        key: item.id,
        name: item.name,
        dataType: item.type,
        env: item.environment,
      };
    });
  }, [dataConnectionList]);

  const getConnectorInfo = useCallback(
    async (id: string) => {
      try {
        const item = await dataConnectionHelper.actions.getById.dispatch({
          id,
        });
        return item as DataConnection;
      } catch (error) {
        return new Error(error as string | undefined);
      }
    },
    [dataConnectionHelper.actions.getById]
  );

  useEffect(() => {
    if (department.id) {
      dataConnectionHelper.actions.getAll.dispatch({});
    }
  }, [dataConnectionHelper.actions.getAll, department.id]);

  useEffect(() => {
    dataConnectionContainer.actions.fetchTemplates.dispatch(null);
  }, [dataConnectionContainer.actions.fetchTemplates]);

  return (
    <div>
      <MappingEditor
        list={list}
        mappings={mappings}
        getConnectorInfo={getConnectorInfo}
        onChange={onMappingsDataChange}
        isEditing={true}
        dataConnectionTemplateInfos={dataConnectionTemplateInfos ?? undefined}
      />
    </div>
  );
});
