// eslint-disable-next-line import/no-anonymous-default-export
export default {
  personal: {
    myOrder: {
      name: "My order",
      version: {
        personal: "Personal Professional Edition",
        free: "Personal free version",
        sass: "Enterprise SASS Edition",
        custom: "Enterprise Privatization Customized Edition",
      },
      status: {
        new: "Order has been created",
        awaitingPayment: "to be paid",
        paid: "Paid",
        processing: "Order in process",
        shipped: "The order has been shipped",
        completed: "Paid",
        canceled: "Canceled",
        refunding: "to be refunded",
        refunded: "Refunded",
        refundFailed: "Refund failed",
        timeout: "Order times out",
        payed: "Paid",
        unPay: "Not paid",
        awaitPay: "To be paid",
      },
      label: {
        orderInfo: "Order Details",
        invoicing: "Invoicing",
        invoicingAmount: "Invoicing amount",
        validity: "Valid until",
        dateRange: "Validity period",
        orderRecord: "Order record",
        orderId: "Order number",
        wxOrder: "Wechat Payment Order Number",
        version: "Version",
        productType: "Product Type",
        orderType: "Specification",
        product: "Product",
        count: "Count",
        robotCount: "Number of robots",
        licenseValid: "The license has a term",
        robotInfo: "Robot Details",
        time: "Time",
        status: "Status",
        serialId: "Serial number",
        licenseId: "License number",
        orderStatus: "Order status",
        orderPrice: "Paid amount",
        discountCode: "Discount Code",
        discountPrice: "Discount Price",
        discountDeduction: "Discount deduction",
        payTime: "Payment time",
        orderTime: "Order time",
        validTime: "Validity period",
        originalPrice: "Original Price",
        invoiceType: "Invoice type",
        invoiceOptions: "Invoicing options",
        invoiceAmount: "Invoice Amount",
        invoiceTitle: "Invoice title",
        invoiceContent: "Invoice contents",
        email: "Contact Email",
        operation: "Operation",
        vipSupport: "VIP Technical Support",
      },
      buttons: {
        renew: "Renew",
        invoicing: "Invoicing",
        invoicingRecord: "invoicing record",
        personalVersion: "Upgrade to Personal Professional",
        enterpriseVersion: "Upgrade to Enterprise Edition",
        info: "Details",
        cancel: "Cancel",
        pay: "Pay",
        refund: "Refund",
        cancelOrder: "Cancel the order",
      },
      tips: {
        cancelOrder: "Please confirm whether to cancel this order",
        invoicing:
          "If invoicing is required, please add a VIP technical support group to contact the administrator",
        joinUs: "Welcome to join",
        vipGroup: "VIP Technical Support Group",
        invoicingInstructions:
          "I have read and agree to the InvoicingInstructions",
        contactUs: {
          name: "Please contact us",
          email: "Mailbox",
          tel: "Telephone",
        },
        refund: {
          name: "Refund Request",
          label:
            "Please add micro customer service, and provide payment order number or wechat Pay",
        },
      },
    },
  },
};
