import { Entity } from "@encoo-web/encoo-lib/types";
export enum PaymentAppNameEnum {
  "Console" = "Console",
  "AIPlatform" = "AIPlatform",
  "ViCode" = "ViCode",
}
export enum PaymentCategoryEnum {
  "Persional" = "Persional",
  "OCR" = "OCR",
  "ViCode" = "ViCode",
}
export type PaymentProduct = {
  id: string;
  name: string;
  description: string;
  price: number;
  displayPrice: number;
  appName: PaymentAppNameEnum;
  category: PaymentCategoryEnum;
  tag?: string;
  purchasable: boolean;
  specification: string;
  visible: boolean;
  dailyQuotaDigitalServices: [];
  unifiedProduct: {
    edition: string;
    sku: "Community";
    specification: string;
    validityPeriodType: "Day";
    validityPeriodValue: number;
    ProductName: "Robot";
  };
};
type PaymentOrderItem = {
  productId: string;
  quantity: number;
  price: number;
  productSnapshoot: PaymentProduct;
};
export enum PaymentOrderStatus {
  /// 刚刚创建的订单，等待确认
  New = "New",
  /// 订单已确认，等待客户付款。
  AwaitingPayment = "AwaitingPayment",
  /// 客户已经完成了付款。
  Paid = "Paid",
  /// 商家正在处理订单，准备发货。
  Processing = "Processing",
  /// 商家已经发货。
  Shipped = "Shipped",
  /// 订单已经完成，客户已经确认收到商品。
  Completed = "Completed",
  /// 订单已被取消，无法再次使用。
  Canceled = "Canceled",
  /// 客户申请了退款，商家正在处理。
  Refunding = "Refunding",
  /// 商家已经处理了退款请求，款项已退还给客户。
  Refunded = "Refunded",
  /// 商家无法处理退款请求，退款失败。
  RefundFailed = "RefundFailed",
  Timeout = "Timeout",
}
export interface PaymentOrder extends Entity {
  customerId: string;
  appName: PaymentAppNameEnum;
  no: string;
  paymentOrderNo?: string;
  expiredTimeOfPayment: string;
  id: string;
  items: PaymentOrderItem[];
  orderAmount: number;
  orderTime: string;
  payTime?: string;
  status: PaymentOrderStatus;
  shippedInfo: {
    shipId: string;
    shipType: "License";
  };
  resourceType: string;
  validFrom?: string;
  validTo?: string;
  couponCode?: string;
}

export interface PaymentOrderCalculate {
  oriOrderAmount: number;
  orderAmount: number;
}
