import { createModel, createSelector } from "nyax";
import { Workflow } from "src/models/workflow";
import { ModelBase } from "src/store/ModelBase";
import { DataPermissionEntityModel } from "src/store/models/entity/dataPermission/entity";
import { DataPermissionHelperModel } from "src/store/models/entity/dataPermission/helper";
import { WorkflowEntityModel } from "src/store/models/entity/workflow/entity";
import { WorkflowHelperModel } from "src/store/models/entity/workflow/helper";

export const PackageWorkflowDynamicUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: this.containerKey!,
      };
    }

    public selectors() {
      return {
        dataSource: createSelector(
          () => this.getContainer(WorkflowEntityModel).getters.items,
          (items) =>
            items
              .filter((item) => item.packageId === this.state.id)
              .sort(function (item1, item2) {
                let result = 0;
                const a = item1?.packageVersion?.split(".");
                const b = item2?.packageVersion?.split(".");
                while (b?.length) {
                  if (
                    (result =
                      (Number(b?.shift()) || 0) - (Number(a?.shift()) || 0))
                  ) {
                    return result;
                  }
                }
                return -(a?.length ?? 0);
              })
        ),
        dataSourceWithPermission: createSelector(
          (): Workflow[] => this.getters.dataSource,
          () => this.getContainer(DataPermissionEntityModel).state.byId,
          (items, byId) =>
            items.map((item) => ({
              ...item,
              permissionValues: byId[item.id]?.permissionValues ?? [],
            }))
        ),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        requestWorkflowByPackageId: async (
          includeExecutionTarget?: boolean,
          force = true
        ) => {
          const items = await this.getContainer(
            WorkflowHelperModel
          ).actions.readByPackageId.dispatch({
            packageIds: [this.state.id],
            includeExecutionTarget,
            force,
          });

          const dataPermissionHelper = this.getContainer(
            DataPermissionHelperModel
          );
          const dataPermissionPayload = items.map((item: Workflow) => {
            const { resourceType, id } = item;
            return { resourceType, id };
          });
          await dataPermissionHelper.actions.getAll.dispatch(
            dataPermissionPayload
          );
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
