export const VICODE_ICON_PATH = "/vicode_icons/";
export const VICODEAPP_ICON_DEFAULT = `${VICODE_ICON_PATH}default.svg`;

export const VICODE_ICONS = [
  "company.svg",
  "advise.svg",
  "scan.svg",
  "search.svg",
  "taxi.svg",
  "pie_chart.svg",
  "department.svg",
  "schedule.svg",
  "travel.svg",
  "hotal.svg",
  "approval.svg",
  "computer.svg",
  "airplane.svg",
  "tree.svg",
  "web_disk.svg",
  "fingerpost.svg",
  "group_chat.svg",
  "todo.svg",
  "net_meeting.svg",
  "mail.svg",
  "guide.svg",
  "notice.svg",
  "user_auth.svg",
  "verif.svg",
  "contacts.svg",
  "rmb.svg",
  "task.svg",
  "assistance.svg",
  "vote.svg",
  "chart.svg",
  "subscription.svg",
  "message.svg",
  "work_circle.svg",
  "news.svg",
  "robat.svg",
  "setting.svg",
  "app_center.svg",
  "person.svg",
  "warn.svg",
  "default.svg",
].map((item) => `${VICODE_ICON_PATH}${item}`);
