import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";

export const WorkflowScheduledTaskUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {};
    }

    public reducers() {
      return {};
    }

    public effects() {
      return {
        ...super.effects(),
        requestScheduledTaskById: async (payload: { id: string }) => {
          const { id } = payload;
          return await this.dependencies.serviceClient.workflow.getAllTriggers(
            id
          );
        },
      };
    }
  }
);
