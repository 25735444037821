/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useCallback } from "react";
import upgradeVersionImg from "src/assets/upgradeVersion.png";
import Button from "src/components/Button";
import { useFormatMessage, useGetContainer } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { RouterModel } from "src/store/models/router";

export default React.memo(function UpgradeVersionMask() {
  const formatMessage = useFormatMessage();
  const getContainer = useGetContainer();
  const router = getContainer(RouterModel);

  const onGo = useCallback(() => {
    router.actions.replaceByRouteInfo.dispatch({
      type: "paymentProduct",
      params: {},
    });
  }, [router.actions.replaceByRouteInfo]);

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 900;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <img
          src={upgradeVersionImg}
          alt=""
          css={css`
            margin-bottom: 20px;
          `}
        />
        <p
          css={css`
            color: #ffffff;
            font-size: 21px;
            font-weight: bold;
            margin-bottom: 0;
          `}
        >
          {formatMessage(messageIds.common.upgradeTip)}
        </p>
        <Button
          type="primary"
          onClick={onGo}
          css={css`
            margin-top: 40px;
          `}
        >
          {formatMessage(messageIds.common.goToUpgrade)}
        </Button>
      </div>
    </div>
  );
});
