/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Spin, Tooltip } from "antd";
import React, {
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Text from "src/components/Text";
import { useFormatMessage, useTheme } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { Workflow } from "src/models/workflow";
import { WorkflowNode } from "src/models/workflowLayout";
import { cssTextTruncate } from "src/styles/text";
import { reactMemo } from "src/utils/react";

const ListItem = reactMemo(function (props: {
  item?: Workflow & {
    permissionValues: string[];
  };
  children?: ReactNode;
  active?: boolean;
  onClick?: MouseEventHandler;
  rightContent?: ReactNode;
  className?: string;
  showTooltip?: boolean;
  showPrefix?: boolean;
  draggable?: boolean;
  onDragStart?: ((node: WorkflowNode) => Promise<void>) | undefined;
  onDragEnd?: () => void;
}) {
  const theme = useTheme();
  const {
    active,
    children,
    onClick,
    rightContent,
    className,
    showTooltip = true,
    showPrefix = true,
    draggable = false,
  } = props;
  const [rightContentVisible, setRightContentVisible] =
    useState<boolean>(false);

  const onMouseEnter = useCallback(() => {
    if (!rightContentVisible) {
      setRightContentVisible(true);
    }
  }, [rightContentVisible]);

  const onMouseLeave = useCallback(() => {
    if (!active) {
      setRightContentVisible(false);
    }
  }, [active]);

  useEffect(() => {
    setRightContentVisible(active ?? false);
  }, [active]);

  const content = useMemo(
    () => (
      <div
        className="list-item-content"
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          min-height: 30px;
        `}
      >
        <div
          className="list-item-content-left"
          css={[
            showPrefix
              ? css`
                  ::before {
                    content: "";
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    margin-right: 10px;
                    background-color: ${active
                      ? theme.primaryColor
                      : theme.rectBackground};
                  }
                `
              : undefined,
            cssTextTruncate,
          ]}
        >
          {children}
        </div>
        <div className="list-item-content-right">
          {rightContentVisible && rightContent}
        </div>
      </div>
    ),
    [
      active,
      children,
      rightContent,
      rightContentVisible,
      showPrefix,
      theme.primaryColor,
      theme.rectBackground,
    ]
  );

  return (
    <li
      draggable={draggable}
      css={[
        css`
          padding: 10px 0px 10px 20px;
          font-size: 14px;
          cursor: pointer;
          :hover {
            background-color: ${theme.bodyFrameBackground};
          }
        `,
        active &&
          css`
            color: ${theme.primaryColor};
            background-color: ${theme.bodyFrameBackground};
          `,
      ]}
      onClick={onClick}
      onMouseEnter={rightContent ? onMouseEnter : undefined}
      onMouseLeave={rightContent ? onMouseLeave : undefined}
      className={className}
    >
      {showTooltip ? <Tooltip title={children}>{content}</Tooltip> : content}
    </li>
  );
});

export interface ListPaginationProps {
  isLoading?: boolean;
  hasNext?: boolean;
  onLoadNext?: () => void;
}

export interface ListProps {
  title?: ReactNode;
  iconType?: string;
  titleRightContent?: ReactNode;
  children?: ReactNode;
  pagination?: ListPaginationProps | false;
  className?: string;
}

export default reactMemo(function List(props: ListProps) {
  const theme = useTheme();
  const formatMessage = useFormatMessage();

  const {
    title,
    iconType,
    titleRightContent,
    children,
    pagination,
    className,
  } = props;
  const onScroll = useCallback(
    (event: React.UIEvent<HTMLUListElement, UIEvent>) => {
      const ul = event.currentTarget;
      const rect = ul.getBoundingClientRect();

      if (
        pagination &&
        pagination.hasNext &&
        !pagination.isLoading &&
        ul.scrollTop + rect.height + 44 >= ul.scrollHeight
      ) {
        pagination.onLoadNext?.();
      }
    },
    [pagination]
  );

  const loadNext = useCallback(() => {
    if (pagination && pagination.hasNext && !pagination.isLoading) {
      pagination.onLoadNext?.();
    }
  }, [pagination]);

  return (
    <div
      css={css`
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        min-height: 0;
        margin-top: 16px;
      `}
      className={className}
    >
      {title && (
        <div
          className="list-title"
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;
            margin: 0 20px 5px 20px;
            border-bottom: 1px solid ${theme.bodyDivider};
          `}
        >
          <div>
            {iconType && (
              <img
                src={iconType}
                alt=""
                css={css`
                  margin-right: 12px;
                  width: 24px;
                  height: 24px;
                  vertical-align: bottom;
                `}
              />
            )}
            <Text
              css={css`
                font-size: 16px;
                font-weight: 500;
                color: ${theme.bodyText};
              `}
            >
              {title}
            </Text>
          </div>
          {titleRightContent}
        </div>
      )}
      <ul
        css={css`
          margin: 0px;
          padding: 0;
          height: 100%;
          overflow: auto;
        `}
        onScroll={pagination ? onScroll : undefined}
      >
        {children}
        {pagination && pagination.hasNext && (
          <li
            css={css`
              display: flex;
              align-items: center;
              text-align: center;
              height: 44px;
              cursor: pointer;
            `}
            onClick={loadNext}
          >
            <div
              css={css`
                margin: auto;
              `}
            >
              {pagination.isLoading && (
                <Spin
                  css={css`
                    margin-right: 5px;
                  `}
                  size="small"
                ></Spin>
              )}
              <Text>
                {pagination.isLoading
                  ? formatMessage(messageIds.common.loading)
                  : formatMessage(messageIds.common.loadNext)}
              </Text>
            </div>
          </li>
        )}
      </ul>
    </div>
  );
});

export { ListItem };
