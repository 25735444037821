import { createSelector, GetContainer } from "nyax";
import { ViCodeApp } from "src/models/vicodeApp";
import { ModelBase } from "src/store/ModelBase";
import { ViCodeAppEntityModel } from "src/store/models/entity/vicodeApp/entity";
import { ViCodeAppQuery } from "src/store/models/ui/vicodeApp/_shared";
import { createUIListV2Model } from "src/store/models/ui/_shared";

type TablePaginationConfigType = {
  current: number;
  pageSize: number;
  total: number;
  onChange: (current: number) => void;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createViCodeAppListBaseModal = function () {
  return createUIListV2Model<ViCodeApp, ViCodeAppQuery>({
    setItems: (getContainer, items) =>
      getContainer(ViCodeAppEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(ViCodeAppEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(ViCodeAppEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  });
};

const PAGE_SIZE = 20;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createViCodeAppPaginationBaseModal = function <
  TEntity,
  KEntity
>(payload: {
  getList1Pagination: (getContainer: GetContainer) => TablePaginationConfigType;
  getList2Pagination: (getContainer: GetContainer) => TablePaginationConfigType;
  getDataSource1: (getContainer: GetContainer) => TEntity[];
  getDataSource2: (getContainer: GetContainer) => KEntity[];
  setPageSizeByList2?: (
    getContainer: GetContainer,
    pageSize: number
  ) => Promise<void>;
}) {
  const {
    getList1Pagination,
    getList2Pagination,
    getDataSource1,
    getDataSource2,
  } = payload;

  return class extends ModelBase {
    public initialState() {
      return {
        current: 1,
        pageSize: PAGE_SIZE,
      };
    }

    public reducers() {
      return {
        setCurrent: (value: number) => {
          this.state.current = value;
        },
      };
    }

    public selectors() {
      return {
        total: createSelector(
          (): TablePaginationConfigType =>
            getList1Pagination(this.getContainer),
          (): TablePaginationConfigType =>
            getList2Pagination(this.getContainer),
          (): number => this.state.pageSize,
          (list1Pagination, list2Pagination, pageSize): number => {
            return (
              list1Pagination.total +
              list2Pagination.total +
              (list1Pagination.total
                ? pageSize - (list1Pagination.total % pageSize)
                : 0)
            );
          }
        ),
        onChange: createSelector(() => async (value: number) => {
          await this.actions.$onChange.dispatch(value);
        }),
        pagination: createSelector(
          (): number => this.state.current,
          (): number => this.state.pageSize,
          (): number => this.getters.total,
          (): ((value: number) => void) => this.getters.onChange,
          (current, pageSize, total, onChange) => {
            return {
              current,
              pageSize,
              total,
              onChange,
            };
          }
        ),
        list1MaxPageIndex: createSelector(
          (): TablePaginationConfigType =>
            getList1Pagination(this.getContainer),
          (): number => this.state.pageSize,
          (list1Pagination, pageSize): number =>
            list1Pagination.total
              ? Math.ceil(list1Pagination.total / pageSize)
              : 0
        ),
        list1RestPageSize: createSelector(
          (): number => this.state.pageSize,
          (): number => getList1Pagination(this.getContainer).total,
          (): number => this.getters.list1MaxPageIndex,

          (pageSize, total, maxPageIndex): number => {
            return pageSize * maxPageIndex - total;
          }
        ),
        dataSource: createSelector(
          (): number => this.getters.list1MaxPageIndex,
          (): number => this.getters.list1RestPageSize,
          (): number => this.getters.pagination.current,
          (): TEntity[] => getDataSource1(this.getContainer),
          (): KEntity[] => getDataSource2(this.getContainer),
          (
            list1MaxPageIndex,
            list1RestPageSize,
            current,
            dataSource1,
            dataSource2
          ): (TEntity | KEntity)[] => {
            if (current <= list1MaxPageIndex) {
              return dataSource1;
            } else {
              return dataSource2;
            }
          }
        ),
      };
    }

    public effects() {
      return {
        $onChange: async (_current: number) => {
          await this.actions.setCurrent.dispatch(_current);

          const list1MaxPageIndex = this.getters.list1MaxPageIndex;

          if (_current <= list1MaxPageIndex) {
            getList1Pagination(this.getContainer).onChange(this.state.current);
          } else {
            getList2Pagination(this.getContainer).onChange(
              _current - list1MaxPageIndex
            );
          }
        },
      };
    }
  };
};
