// eslint-disable-next-line import/no-anonymous-default-export
export default {
  robot: {
    entityTypeName: "Robot",
    robotName: "Robot Name",
    upgrade: "Upgrade Software",
    introduction:
      "Robot Management is mainly used to create and manage the connection of dedicated robots, and can simultaneously manage the license status of the robots.",
    resourceGroupIntroduction:
      "The resource group robots are mainly used to create and manage the connection of the dedicated robots under the resource group, and can simultaneously manage the license status of the resource group robots.",
    sharedIntroduction:
      "Shared robots are mainly used to create and manage the connection of the shared robots of tenants, and can simultaneously manage the license status of the shared robots.",
    lastTime: "Last Communication Time",
    addRobot: "Add Robot",
    remoteDesktop: "Remote desktop",
    computerName: "Computer",
    connectStatusName: "Connection Status",
    licenseSku: "License Sku",
    licenseStatusName: "License Status",
    allLicenseStatusName: "All Connect Status",
    countDown: "Refresh Countdown",
    unableGet: "No version number detected",
    upgrading: "Upgrading",
    cpu: "CPU",
    memory: "RAM",
    allResource: "All sources",
    disk: "Disk",
    network: "Network",
    lastFive: "Last 5 minutes",
    networkDownlink: "Network Downlink",
    networkUplink: "Network Uplink",
    diskRead: "Disk Reading",
    diskWrite: "Disk Writing",
    emptyTip: "No data was collected, please try again later.",
    licenseStatus: {
      unlicensed: "Unoccupied",
      licensed: "Occupied",
    },
    status: {
      all: "All status",
      free: "Idle",
      busy: "Busy",
      noResponse: "No Response",
      break: "Interrupted",
      connect: "Connection",
      unLicensed: "Unlicensed",
      unlogged: "Unlogged",
      tip: {
        unlogged:
          "The computer where this robot is located is in a user-not-logged-in state. Please complete the login first, otherwise the process will fail to run.",
      },
    },
    resource: {
      title: "Group Migration",
      current: "Current Resource Group",
      target: "Migrate to Resource Group",
    },
    detail: {
      title: "Robot Management Details",
      bindUser: "Bound Domain Name/User name",
      name: "Robot Name",
      status: "Robot Status",
      code: "Robot Connection String",
      copyCode:
        "Copy the robot connection string before pasting the robot connection string into the client robot for activation.",
      tag: "Robot Tag",
      remark: "Robot Remarks",
      time: "Robot Last Communication Time",
      workflow: "Associated Flow Deployment",
      queue: "Associated Robot Group",
      cpu: "CPU Utilization",
      memory: "RAM Utilization",
      listeningStatus: "Robot Status",
      listeningStatusPrefix: "When changed to",
      listeningStatusSubfix: "When",
      thresholdTrigger: "When the threshold is reached",
      emailTo: "Send Email Automatically",
      terminateCurrTask: "Terminate Current Flow",
      unsettled: "Not set",
      undo: "No processing",
      bigger: "Greater Than",
    },
    validate: {
      name: "Robot Name",
      status: "Robot Status",
      code: "Robot Connection String",
    },
    placeholder: {
      tags: "Please enter the tag and press Enter for confirmation.",
      type: "User Robot",
    },
    info: {
      bindConfirm: "Whether to bind {entityTypeName} for {name}?",
      unbindConfirm: "Whether to delete {name}{entityTypeName}?",
    },
    tips: {
      copy: "Copied",
      create: {
        code: "Robot Connection String",
        content:
          "The robot is created. Please copy the connection activation string for activating the robot client.",
        mark: "You can view the robot connection string in the robot detail page.",
      },
      unBind:
        "After being unbound, the client robot will unable to execute the flow. Are you sure you want to unbind the robot?",
      bindDelete: "The bound licenses cannot be deleted!",
      bindFail: {
        title: "Unbinding failed.",
        content:
          "The robot has tasks waiting to be executed and being executed.",
      },
      upgrade: {
        title:
          "Please confirm whether to upgrade the selected robot software version to the latest version",
        content: {
          title: "During upgrade：",
          network:
            "-Please ensure that the network connection is normal, otherwise the update will fail",
          robot:
            "-The robot will not be able to run the process. Please prepare in advance",
          planTime: "Planned time：",
          rightNow: "Right now",
          whenIdle: "Upgrade immediately when the robot is idle",
          specificTime: "Scheduled upgrade",
        },
      },
    },
    scope: {
      resourceGroup: "Robot",
      shared: "Shared Robot",
    },
    executionPlan: "Execution Plan",
    listName: "{name} Name",
    robotScope: "Robot Level",
    tab: {
      warning: "Prewarning Configuration",
      monitor: "Computer Monitoring",
      configuration: "Basic configuration",
    },
    hardwareMonitor: "Hardware Monitor",
    warningConfig: "Prewarning Threshold Configuration",
    share: "Share Robot",
    taskName: "Task Name",
    nextRuntime: "Next Execution Time",
    effective: "Effective",
    notEffective: "Not Effective",
    setting: {
      startupSettings: "Startup settings:",
      executionProcess: "Execution process:",
      longRange: "RDP remote:",
      setUp: {
        powerOn: "Power on",
        minimize: "Interface minimization during process execution",
        progressNotice:
          "Displays the current execution node when executing a process",
        unlockScreen: "Automatically unlock screen when executing process",
        username: "user name:",
        password: "password:",
        resolvingPower: "Hold session, home screen resolution size:",
      },
      tip: "When this option is checked, when the screen lock is detected during the execution of the process, the robot will automatically unlock the screen, run the process, and automatically lock the screen after the execution.",
      syncTip:
        "This version of robot is not applicable to this function, that is, the update [basic configuration] will not be synchronized to this robot",
    },
    recording: {
      saveTime: "Hold time:",
      storageCapacity: "storage capacity:",
      recordingUp: {
        timeBefore: "Keep only recent",
        timeAfter: "day videos",
        capacityBefore: "Maximum total video storage capacity",
        capacityAfter: "G",
      },
    },
    hoursUp: {
      timeFrame: "time frame",
      allDay: "all day",
      specificTime: "Specific time",
      tips: "Specifies the time range within which the robot can accept console scheduling",
    },
    logUp: {
      featureSwitch: "Features:",
      enableErrorLogScreenshot:
        "Generate a screenshot when the workflow error log appears",
      logBefore: "Only the log files of process execution in the last ",
      logAfter: "days of the machine are retained",
      storagePath: "Storage Path:",
    },
    pip: {
      clipboard: "Clipboard:",
      shareClipboard: "Share clipboard",
    },
  },
};
