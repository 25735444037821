import { createModel } from "nyax";
import { Application } from "src/models/application";
import { createHelperModel } from "src/store/models/entity/_shared";
import { ApplicationEntityModel } from "src/store/models/entity/application/entity";

export const ApplicationHelperModel = createModel(
  class extends createHelperModel<Application>({
    setItems: (getContainer, items) =>
      getContainer(ApplicationEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(ApplicationEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(ApplicationEntityModel).state.byId[id],
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(ApplicationEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        getById: async (payload: {
          applicationId: string;
          force?: boolean;
        }) => {
          const { applicationId, force = true } = payload;
          return await this._readById({
            id: applicationId,
            getByIdAction: async () => {
              const item =
                await this.dependencies.serviceClient.application.getById(
                  applicationId
                );
              // await this.getContainer(
              //   DataPermissionHelperModel
              // ).actions.getAll.dispatch([
              //   { resourceType: item.resourceType, id: item.id },
              // ]);
              return item;
            },
            force,
          });
        },

        update: async (params: {
          application: Partial<Application> & Pick<Application, "id">;
        }) => {
          const { application } = params;
          return await this._update({
            id: application.id,
            updateAction: () =>
              this.dependencies.serviceClient.application.update(application),
          });
        },

        getKnowledgeBaseList: async (payload: {
          pageIndex: number;
          pageSize: number;
        }) => {
          const { pageIndex = 0, pageSize = 20 } = payload;
          return await this.dependencies.serviceClient.knowledge.list(
            pageIndex,
            pageSize
          );
        },

        getKnowledgeBaseStartUrl: async (id: string) => {
          return await this.dependencies.serviceClient.application.getKnowledgeBaseStartUrl(
            id
          );
        },
      };
    }
  }
);
