import { createModel } from "nyax";
import { Knowledge } from "src/models/knowledge";
import { createHelperModel } from "src/store/models/entity/_shared";
import { KnowledgeEntityModel } from "src/store/models/entity/knowledge/entity";

export const KnowledgeHelperModel = createModel(
  class extends createHelperModel<Knowledge>({
    setItems: (getContainer, items) =>
      getContainer(KnowledgeEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(KnowledgeEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(KnowledgeEntityModel).state.byId[id],
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(KnowledgeEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        getById: async (payload: { knowledgeId: string; force?: boolean }) => {
          const { knowledgeId, force = true } = payload;
          return await this._readById({
            id: knowledgeId,
            getByIdAction: async () => {
              const item =
                await this.dependencies.serviceClient.knowledge.getById(
                  knowledgeId
                );
              // await this.getContainer(
              //   DataPermissionHelperModel
              // ).actions.getAll.dispatch([
              //   { resourceType: item.resourceType, id: item.id },
              // ]);
              return item;
            },
            force,
          });
        },

        update: async (params: {
          knowledge: Partial<Knowledge> & Pick<Knowledge, "id">;
        }) => {
          const { knowledge } = params;
          return await this._update({
            id: knowledge.id,
            updateAction: () =>
              this.dependencies.serviceClient.knowledge.update(knowledge),
          });
        },

        uploadFile: async (payload: {
          id: string;
          fileName: string;
          lastModifiedAt?: string;
          overwrite?: boolean;
          fileData: File;
        }) => {
          const { id, fileName, lastModifiedAt, overwrite, fileData } = payload;
          return await this.dependencies.serviceClient.knowledge.uploadFile({
            id,
            fileName,
            lastModifiedAt,
            overwrite,
            fileData,
          });
        },

        deleteFile: async (payload: {
          knowledgeId: string;
          fileId: string;
        }) => {
          const { knowledgeId, fileId } = payload;
          await this.dependencies.serviceClient.knowledge.deleteFile(
            knowledgeId,
            fileId
          );
        },
      };
    }
  }
);
