import { createModel } from "nyax";
import {
  Trigger,
  TriggerCreationData,
  TriggerSchedulerConfig,
  TriggerUpdateData,
} from "src/models/workflow";
import { TriggerEntityModel } from "src/store/models/entity/trigger/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const TriggerHelperModel = createModel(
  class extends createHelperModel<Trigger>({
    setItems: (getContainer, items) =>
      getContainer(TriggerEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(TriggerEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(TriggerEntityModel).state.byId[id],
  }) {
    public effects() {
      return {
        ...super.effects(),

        readByWorkflowIds: async (payload: {
          workflowIds: string[];
          force?: boolean;
        }) => {
          const { workflowIds, force } = payload;
          return await this._readByParentIds({
            parentIds: workflowIds,
            getAllAction: (workflowId) => {
              return this.dependencies.serviceClient.workflow.getAllTriggers(
                workflowId
              );
            },
            getEntityParentId: (entity: Trigger) => entity.workflowId,
            force,
          });
        },
        create: async (payload: {
          workflowId: string;
          params: Omit<
            TriggerCreationData,
            "workflowId" | "triggerType" | "isEnabled"
          >;
        }) => {
          const { workflowId, params } = payload;
          return await this._create({
            createAction: () =>
              this.dependencies.serviceClient.workflow.createPlan(workflowId, {
                ...params,
                workflowId,
                isEnabled: true,
                // triggerType: params.repeatConfig?.onceTriggerAtDt
                //   ? "Once"
                //   : "Cron",
                repeatType: params.repeatType,
              }),
          });
        },
        update: async (payload: {
          workflowId: string;
          triggerId: string;
          params: TriggerUpdateData;
        }) => {
          const { workflowId, triggerId, params } = payload;
          return await this._update({
            id: triggerId,
            updateAction: () =>
              this.dependencies.serviceClient.workflow.updateTrigger(
                workflowId,
                triggerId,
                params
              ),
          });
        },
        delete: async (payload: { workflowId: string; triggerId: string }) => {
          const { workflowId, triggerId } = payload;
          return await this._delete({
            id: triggerId,
            deleteAction: () =>
              this.dependencies.serviceClient.workflow.deleteTrigger(
                workflowId,
                triggerId
              ),
          });
        },
        getById: async (payload: {
          workflowId: string;
          triggerId: string;
          force?: boolean;
        }) => {
          const { workflowId, triggerId, force } = payload;
          return await this._readById({
            id: triggerId,
            getByIdAction: () =>
              this.dependencies.serviceClient.workflow.getTrigger(
                workflowId,
                triggerId
              ),
            force,
          });
        },
        execute: async (payload: {
          workflowId: string;
          params: Exclude<Partial<TriggerCreationData>, "triggerType">;
        }) => {
          const { workflowId, params } = payload;
          const item =
            await this.dependencies.serviceClient.workflow.createTrigger(
              workflowId,
              { ...params, triggerType: "Once" } as TriggerCreationData
            );
          return item;
        },
        enableDisabled: async (payload: {
          workflowId: string;
          triggerId: string;
          isEnabled: boolean;
        }) => {
          const { workflowId, triggerId, isEnabled } = payload;
          return await this._update({
            id: triggerId,
            updateAction: () =>
              this.dependencies.serviceClient.workflow.enableDisabled(
                workflowId,
                triggerId,
                isEnabled
              ),
          });
        },
        getTriggerScheduler: async (payload: TriggerSchedulerConfig) => {
          const item =
            await this.dependencies.serviceClient.workflow.calculateTriggerScheduler(
              payload
            );
          return item;
        },
      };
    }
  }
);
