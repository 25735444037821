import { createModel } from "nyax";
import { Application, ApplicationQuery } from "src/models/application";
import { ApplicationEntityModel } from "src/store/models/entity/application/entity";
import { createUISideListWithPermissionModel } from "src/store/models/ui/_shared";

export const ApplicationSideUIModel = createModel(
  class extends createUISideListWithPermissionModel<
    Application,
    ApplicationQuery
  >({
    setItems: (getContainer, items) =>
      getContainer(ApplicationEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(ApplicationEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(ApplicationEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initial: async () => {
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query: ApplicationQuery
            ) =>
              this.dependencies.serviceClient.application.list(
                pageIndex,
                pageSize,
                query
              ),
          });
        },
      };
    }
  }
);
