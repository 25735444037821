import { createModel } from "nyax";
import {
  CustomPermissionRoleInfo,
  NewPermissionRoleInfo,
} from "src/models/permissionSetting";
import { RoleManagementEntityModel } from "src/store/models/entity/roleManagement/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const RoleManagementHelperModel = createModel(
  class extends createHelperModel<CustomPermissionRoleInfo>({
    setItems: (getContainer, items) =>
      getContainer(RoleManagementEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(RoleManagementEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(RoleManagementEntityModel).state.byId[id],
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(RoleManagementEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        getPermissionTable: async () => {
          return await this.dependencies.serviceClient.customPermissionRoleServiceClient.getPermissionTable();
        },
        getAll: async () => {
          return await this._readAll({
            getAllAction: async () => {
              return this.dependencies.serviceClient.customPermissionRoleServiceClient.getRoleList();
            },
          });
        },
        create: async (payload: { data: NewPermissionRoleInfo }) => {
          const { data } = payload;
          return await this._create({
            createAction: () =>
              this.dependencies.serviceClient.customPermissionRoleServiceClient.createNewRole(
                data
              ),
          });
        },
        update: async (payload: {
          roleId: string;
          data: NewPermissionRoleInfo;
        }) => {
          const { roleId, data } = payload;
          return this._update({
            id: roleId,
            updateAction: () => {
              return this.dependencies.serviceClient.customPermissionRoleServiceClient.editRole(
                roleId,
                data
              );
            },
          });
        },
        delete: async (payload: { id: string }) => {
          const { id } = payload;
          return await this._delete({
            id,
            deleteAction: () =>
              this.dependencies.serviceClient.customPermissionRoleServiceClient.deleteRole(
                id
              ),
          });
        },
      };
    }
  }
);
