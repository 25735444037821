import { createModel } from "nyax";
import { Package, PackageQuery } from "src/models/package";
import { PackageEntityModel } from "src/store/models/entity/package/entity";
import { PackageSideUIModel } from "src/store/models/ui/package/packageSide";
import { createUITableWithPermissionModel } from "src/store/models/ui/_shared";

export type PackageWithPermission = Package & {
  permissionValues: string[];
};

export const PackageTableUIModel = createModel(
  class extends createUITableWithPermissionModel<Package, PackageQuery>({
    setItems: (getContainer, items) =>
      getContainer(PackageEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(PackageEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(PackageEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      const superEffects = super.effects();
      return {
        ...superEffects,
        updateQuery: async (params: PackageQuery) => {
          await superEffects.updateQuery(params);
          await this.getContainer(PackageSideUIModel).actions.setQuery.dispatch(
            this.state.query
          );
        },
        initial: async (payload: { departmentId: string }) => {
          const { departmentId } = payload;
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query: PackageQuery
            ) =>
              this.dependencies.serviceClient.package.list(
                departmentId,
                pageIndex,
                pageSize,
                query
              ),
          });
        },
      };
    }
  }
);
