/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ConnectorMappingItem } from "@encoo-web/encoo-ui";
import { message, Space } from "antd";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "src/components/Button";
import ContentItem from "src/components/ContentItem";
import DescriptionTip from "src/components/DescriptionTip";
import Modal from "src/components/Modal";
import AppMapping from "src/containers/apps/components/AppMapping";
import { useFormatMessage, useGetContainer } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { AppHelperModel } from "src/store/models/entity/apps/helper";
import { AppVersionHelperModel } from "src/store/models/entity/appVersion/helper";
import { DataSourceEntityModel } from "src/store/models/entity/dataSource/entity";
import { DataSourceHelperModel } from "src/store/models/entity/dataSource/helper";
import { AppsUIModel, EnabledAppPayload } from "src/store/models/ui/apps/apps";

export const AppEnable = React.memo(function (props: {
  appId: string;
  appName: string;
  appVersionId: string;
  onCancle: () => Promise<void>;
  handleType?: EnabledAppPayload["handleType"];
}) {
  const getContainer = useGetContainer();

  const appVersionHelper = getContainer(AppVersionHelperModel);
  const appsHelper = getContainer(AppHelperModel);
  const appsUiContainer = getContainer(AppsUIModel);
  const dataSourceEntityContainer = getContainer(DataSourceEntityModel);
  const dataSourceHelperContainer = getContainer(DataSourceHelperModel);

  const { appId, appVersionId, onCancle, appName } = props;

  const [mappingsData, setMappingsData] = useState<ConnectorMappingItem[]>([]);
  const dataSourceList = useSelector(
    () => dataSourceEntityContainer.getters.items
  );

  const appVersionItem = useSelector(
    () => appsUiContainer.getters.enabledAppItem
  );

  const formatMessage = useFormatMessage();

  const onEnabled = useCallback(async () => {
    const mappingsValue = mappingsData.filter(
      (item) => item.prodDataConnectorId
    );

    const payload = {
      dataConnectorMappings: mappingsValue,
      launchVersionId: appVersionId,
    };

    await appsHelper.actions.enableApp.dispatch({
      appId,
      payload,
    });
    message.success(formatMessage(messageIds.apps.connector.enableSuccessful));
    onCancle();
  }, [
    appId,
    appVersionId,
    appsHelper.actions.enableApp,
    formatMessage,
    mappingsData,
    onCancle,
  ]);

  const initHandle = useCallback(async () => {
    await appVersionHelper.actions.readById.dispatch({
      appId,
      versionId: appVersionId,
    });
    await dataSourceHelperContainer.actions.getAll.dispatch({
      dataSourceFoderType: "appversion",
      folderId: appVersionId,
    });
  }, [
    appId,
    appVersionHelper.actions.readById,
    appVersionId,
    dataSourceHelperContainer.actions.getAll,
  ]);

  useEffect(() => {
    initHandle();
  }, [initHandle]);

  useEffect(() => {
    const initConnectorMappings = appVersionItem?.dataConnectorMappings;
    if (dataSourceList) {
      let connctorList = dataSourceList.map((item) => {
        const mappingInfo = initConnectorMappings?.find(
          (mappingItem) => mappingItem.devDataConnectorId === item.connectionId
        );
        return {
          devDataConnectorId: item.connectionId,
          prodDataConnectorId: mappingInfo?.prodDataConnectorId ?? "",
        };
      });
      connctorList = _.uniqBy(connctorList, "devDataConnectorId");
      setMappingsData(connctorList);
    }
  }, [appVersionItem, dataSourceList]);

  return (
    <div css={css``}>
      <ContentItem
        name={formatMessage(messageIds.apps.connector.appsName)}
        value={appName}
      />
      <ContentItem
        name={formatMessage(messageIds.apps.connector.enableVersion)}
        value={appVersionItem?.version}
      />
      <DescriptionTip
        css={css`
          padding-bottom: 30px;
          font-weight: bold;
        `}
        tip={formatMessage(messageIds.apps.connector.mappingTip)}
      >
        {formatMessage(messageIds.apps.connector.connectionMap)}
      </DescriptionTip>
      <AppMapping
        mappings={mappingsData}
        onMappingsDataChange={setMappingsData}
      />
      <Space
        size={20}
        css={css`
          display: flex;
          justify-content: flex-end;
          padding: 20px 0;
        `}
      >
        <Button onClick={onCancle}>
          {formatMessage(messageIds.common.cancel)}
        </Button>
        <Button onClick={onEnabled} type="primary">
          {formatMessage(messageIds.common.save)}
        </Button>
      </Space>
    </div>
  );
});

export default React.memo(function AppEnableDialog(props: {
  appId: string;
  appVersionId: string;
  appVersion?: string;
  onCancle: () => Promise<void>;
  handleType?: EnabledAppPayload["handleType"];
  appName: string;
}) {
  const formatMessage = useFormatMessage();

  const { appId, appVersionId, onCancle, handleType, appName } = props;

  return (
    <Modal
      visible={!!(appId && appVersionId)}
      title={formatMessage(messageIds.apps.connector.enableApps)}
      size="large"
      onCancel={onCancle}
      footer={null}
    >
      <AppEnable
        appId={appId}
        appVersionId={appVersionId}
        onCancle={onCancle}
        handleType={handleType}
        appName={appName}
      />
    </Modal>
  );
});
