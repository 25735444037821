// eslint-disable-next-line import/no-anonymous-default-export
export default {
  package: {
    package: "流程包",
    version: "版本号",
    introduction:
      "流程包管理主要用于管理编辑器上传或手动上传的流程包及其对应版本详情，支持流程包下载、删除等操作。",
    uploadTime: "上传时间",
    uploadUser: "上传用户",
    packageDetail: "流程包版本详情",
    count: "流程包部署次数",
    packageNote: "流程包备注",
    deploymentName: "流程部署名称",
    deploymentCount: "流程部署次数",
    deploymentNote: "部署备注",
    deploymentDetail: "部署详情",
    deployed: "已部署",
    notDeploy: "未部署",
    preStep: "上一步",
    nextStep: "下一步",
    save: "保存并设置触发器",
    share: "分享流程包",
    baseConfig: "基本配置",
    performanceTargets: "执行目标",
    packageName: "流程包名称",
    parameterManagement: "流程包参数管理",
    name: "名称",
    parameterType: "参数类型",
    direction: {
      title: "参数方向",
      In: "输入",
      Out: "输出",
      InOut: "输入/输出",
      Property: "属性",
    },
    noConnectedWorkflow: "未关联流程部署",
    parameterDefault: "参数默认值",
    queueExecution: "指定机器人组执行",
    robotExecution: "指定机器人执行",
    addWorkflow: "新增部署",
    uploadPackage: "上传流程包成功",
    previousVersion: "上一版本号",
    latestVersion: "最新版本号",
    packageVersionFormat: "版本号格式为 x.x.x",
    runAsAdmin: "以管理员权限执行",
    sameName: "重名文件正在上传。",
    overfile:
      "当前选中文件数量已超过剩余额度，当前选中文件数量为 {count}，剩余额度为 {limit}",
  },
};
