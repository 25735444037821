import { ProductSku } from "@encoo-web/encoo-lib";
import { createModel } from "nyax";
import { Installation, ProductName } from "src/models/installation";
import { ModelBase } from "src/store/ModelBase";
import { InstallationEntityModel } from "src/store/models/entity/installation/entity";
import { InstallationHelperModel } from "src/store/models/entity/installation/helper";

export const InstallationUIModel = createModel(
  class extends ModelBase {
    private get _helperContainer() {
      return this.getContainer(InstallationHelperModel);
    }
    private get _entityContainer() {
      return this.getContainer(InstallationEntityModel);
    }
    public initialState() {
      return {
        isInitialized: false,
        selectedAppIds: [] as string[],
        robot: null as Installation | null,
        studio: null as Installation | null,
        android: null as Installation | null,
        ios: null as Installation | null,
        appium: null as Installation | null,
        ocr: null as Installation | null,
      };
    }
    public reducers() {
      return {
        initializeSuccess: () => {
          this.state.isInitialized = true;
        },
        setSelectedAppIds: (selectedAppIds: string[]) => {
          this.state.selectedAppIds = selectedAppIds;
        },
        setRobot: (robot: Installation | null) => {
          this.state.robot = robot;
        },
        setStudio: (studio: Installation | null) => {
          this.state.studio = studio;
        },
        setAndroid: (android: Installation | null) => {
          this.state.android = android;
        },
        setIos: (ios: Installation | null) => {
          this.state.ios = ios;
        },
        setAppium: (appium: Installation | null) => {
          this.state.appium = appium;
        },
        setOCR: (ocr: Installation | null) => {
          this.state.ocr = ocr;
        },
      };
    }

    public effects() {
      return {
        initializeRequest: async (payload: {
          productNames: ProductName[];
          sku: ProductSku;
        }) => {
          const { productNames, sku } = payload;
          const items =
            await this._helperContainer.actions.getPublishedPackages.dispatch({
              productNames,
              sku,
              force: true,
            });
          const robot = items.find(
            (item) => item.productName === ProductName.Robot
          );
          const studis = items.find(
            (item) => item.productName === ProductName.Studio
          );
          const andriod = items.find(
            (item) => item.productName === ProductName.AndroidAutomation
          );
          const ios = items.find(
            (item) => item.productName === ProductName.IOSAutomation
          );
          const appium = items.find(
            (item) => item.productName === ProductName.AndroidAutomationV2
          );
          const ocr = items.find(
            (item) => item.productName === ProductName.OCROffline
          );
          await this.actions.setRobot.dispatch(robot ?? null);
          await this.actions.setStudio.dispatch(studis ?? null);
          await this.actions.setIos.dispatch(ios ?? null);
          await this.actions.setAndroid.dispatch(andriod ?? null);
          await this.actions.setAppium.dispatch(appium ?? null);
          await this.actions.setOCR.dispatch(ocr ?? null);

          await this.actions.initializeSuccess.dispatch({});
        },
        downloadPackage: async (payload: { id: string | undefined }) => {
          const { id } = payload;
          const res =
            await this.dependencies.serviceClient.installation.getDownloadUrlById(
              id as string
            );
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = res;
          link.setAttribute("download", `download`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
      };
    }
  }
);
