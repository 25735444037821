import { Key } from "antd/lib/table/interface";
import { createModel } from "nyax";
import { StepProps } from "src/components/Step";
import { QueueBasicData } from "src/models/queue";
import { ModelBase } from "src/store/ModelBase";
export type CreateQueueContentType = "basic" | "bindRobot";

export const QueueUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        isShowCreateModal: false,
        currentStep: "basic",
        selectedIds: [] as Key[],
        currentIndex: 0,
        configData: {} as QueueBasicData,
        queueName: "",
      };
    }

    public reducers() {
      return {
        setCreateModal: (value: boolean) => {
          this.state.isShowCreateModal = value;
        },
        setCurrentStep: (value: CreateQueueContentType) => {
          this.state.currentStep = value;
        },
        setSelectedRobotIds: async (value: Key[]) => {
          this.state.selectedIds = value;
        },
        setCurrentIndex: (value: number) => {
          this.state.currentIndex = value;
        },
        setConfigData: (value: QueueBasicData) => {
          this.state.configData = value;
        },
        setQueueName: (value: string) => {
          this.state.queueName = value;
        },
      };
    }
    public effects() {
      return {
        updateStep: async (payload: { value: number; steps: StepProps[] }) => {
          const { value, steps } = payload;
          await this.actions.setCurrentIndex.dispatch(value);
          await this.actions.setCurrentStep.dispatch(
            steps[value].name as CreateQueueContentType
          );
        },
        handleSelectedRobotIds: async (payload: { value: Key[] }) => {
          const { value } = payload;

          await this.actions.setSelectedRobotIds.dispatch(value);
        },
        clearRobotBindingData: async () => {
          await this.actions.setSelectedRobotIds.dispatch([]);
        },
      };
    }
  }
);
