/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  PagedAsyncIterableIterator,
  PageOptions,
} from "@encoo-web/encoo-lib/types/http/paging";
import _ from "lodash";
import {
  createModel,
  createSelector,
  GetContainer,
  mergeModels,
  mergeSubModels,
} from "nyax";
import { EncooListEntity } from "src/models/_shared";
import { createItemsEntityModel } from "src/store/itemsEntity";
import { createItemsReadWriteModel } from "src/store/itemsReadWrite";
import { ModelBase } from "src/store/ModelBase";

export const createEntityModel = function <TEntity extends object>(
  ...[getItemId]: TEntity extends { id: string }
    ? [((item: TEntity) => string)?]
    : [(item: TEntity) => string]
) {
  if (!getItemId) {
    getItemId = (item) => (item as { id: string }).id;
  }

  return createModel(
    class extends createItemsEntityModel<TEntity>(getItemId) {
      public selectors() {
        return {
          ...super.selectors(),
        };
      }
    }
  );
};

export const createHelperModel = function <TEntity extends object>(payload: {
  setItems: (
    context: GetContainer,
    items: (string | TEntity)[]
  ) => Promise<void>;
  getItems: (getContainer: GetContainer) => TEntity[];
  getItem: (getContainer: GetContainer, id: string) => TEntity | undefined;
  refreshList?: (getContainer: GetContainer) => Promise<void>;
  getItemId?: (entity: TEntity) => string;
}) {
  const { setItems, getItems, getItem, refreshList } = payload;
  let { getItemId } = payload;
  if (!getItemId) {
    getItemId = (entity: TEntity) => (entity as { id: string }).id;
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const internalGetItemId = getItemId!;

  return class BaseHelperModel extends mergeModels(
    ModelBase,
    mergeSubModels({
      _rw: createItemsReadWriteModel<TEntity>({
        getItemId: internalGetItemId,
        setItems: ({ getContainer }, items) => setItems(getContainer, items),
      }),
      _rwByParentId: createItemsReadWriteModel(),
    })
  ) {
    protected _readAll = async (payload: {
      getAllAction: () => Promise<TEntity[]>;
    }) => {
      const { getAllAction } = payload;
      const items = await getAllAction();
      await this.actions._rw.endRead.dispatch({
        items: [...getItems(this.getContainer), ...items],
        timestamp: Date.now(),
      });
      return items;
    };
    protected _readByParentIds = async (payload: {
      parentIds: string[];
      getAllAction: (parentId: string) => Promise<TEntity[]>;
      getEntityParentId: (entity: TEntity) => string;
      force?: boolean;
    }) => {
      const { parentIds, getAllAction, getEntityParentId, force } = payload;
      const { ids: beginReadIds, timestamp } =
        await this.actions._rwByParentId.beginRead.dispatch({
          ids: parentIds,
          force,
        });

      try {
        if (beginReadIds.length > 0) {
          const items = _.flatten(
            await Promise.all(
              beginReadIds.map((parentId) => getAllAction(parentId))
            )
          );
          const { ids: endReadIds } =
            await this.actions._rwByParentId.endRead.dispatch({
              items: beginReadIds,
              timestamp,
            });

          if (endReadIds.length > 0) {
            const endGroupIdSet = new Set(endReadIds);

            await this.actions._rw.endRead.dispatch({
              items: [
                ...getItems(this.getContainer)
                  .filter((item) => endGroupIdSet.has(getEntityParentId(item)))
                  .map(internalGetItemId),
                ...items,
              ],
              timestamp,
            });
          }
        }
        const parentIdSet = new Set(parentIds);
        return getItems(this.getContainer).filter((item) =>
          parentIdSet.has(getEntityParentId(item))
        );
      } catch (error) {
        await this.actions._rwByParentId.endRead.dispatch({
          items: [],
          timestamp,
        });
        throw error;
      }
    };

    protected _readById = async (payload: {
      id: string;
      getByIdAction: () => Promise<TEntity>;
      force?: boolean;
    }) => {
      const { id, getByIdAction, force } = payload;
      const { ids: beginReadIds, timestamp } =
        await this.actions._rw.beginRead.dispatch({
          ids: [id],
          force,
        });

      try {
        if (beginReadIds.length > 0) {
          const item = await getByIdAction();

          await this.actions._rw.endRead.dispatch({
            items: [item],
            timestamp,
          });
        }

        return getItem(this.getContainer, id);
      } catch (error) {
        await this.actions._rw.endRead.dispatch({
          items: [],
          timestamp,
        });
        throw error;
      }
    };

    protected _create = async (payload: {
      createAction: () => Promise<TEntity>;
    }) => {
      const { createAction } = payload;
      const item = await createAction();
      await this.actions._rw.endRead.dispatch({
        items: [item],
        timestamp: Date.now(),
      });
      await refreshList?.(this.getContainer);
      return item;
    };

    protected _update = async (payload: {
      id: string;
      updateAction: () => Promise<TEntity>;
    }) => {
      const { id, updateAction } = payload;
      const { timestamp } = await this.actions._rw.beginWrite.dispatch({
        ids: [id],
      });

      try {
        const item = await updateAction();
        await this.actions._rw.endWrite.dispatch({
          items: [item],
          timestamp,
        });
        return item;
      } catch (error) {
        await this.actions._rw.endWrite.dispatch({
          items: [],
          timestamp,
        });
        throw error;
      }
    };

    protected _delete = async (payload: {
      id: string;
      deleteAction: (id: string) => Promise<boolean>;
    }) => {
      const { id, deleteAction } = payload;
      const { timestamp } = await this.actions._rw.beginWrite.dispatch({
        ids: [id],
      });
      try {
        await deleteAction(id);
        await this.actions._rw.endWrite.dispatch({
          items: [id],
          timestamp,
        });
      } catch (error) {
        await this.actions._rw.endWrite.dispatch({
          items: [],
          timestamp,
        });
        throw error;
      }
    };
  };
};

const PAGE_SIZE = 20;
const LIMITED_SIZE = 100;
export const createListModel = function <TEntity extends object>(payload: {
  setItems: (
    context: GetContainer,
    items: (string | TEntity)[]
  ) => Promise<void>;
  getItems: (getContainer: GetContainer) => TEntity[];
  getItem: (getContainer: GetContainer, id: string) => TEntity | undefined;
  getItemId: (entity: TEntity) => string;
  onLoadedItems?: (
    getContainer: GetContainer,
    items: TEntity[]
  ) => Promise<void>;
}) {
  const { setItems, getItems, getItem, getItemId, onLoadedItems } = payload;
  return class BaseListModel extends mergeModels(
    ModelBase,
    mergeSubModels({
      _rw: createItemsReadWriteModel<TEntity>({
        getItemId: (item) => getItemId(item),
        setItems: ({ getContainer }, items) => setItems(getContainer, items),
      }),
    })
  ) {
    private _initialAction?: () => Promise<
      PagedAsyncIterableIterator<TEntity, TEntity[], PageOptions>
    >;

    protected async _saveEntity(items: TEntity[], force?: boolean) {
      const { ids: beginReadIds, timestamp } =
        await this.actions._rw.beginRead.dispatch({
          ids: items.map((item) => getItemId(item)),
          force,
        });

      try {
        if (beginReadIds.length > 0) {
          await this.actions._rw.endRead.dispatch({
            items: items,
            timestamp,
          });
        }
      } catch (error) {
        await this.actions._rw.endRead.dispatch({
          items: [],
          timestamp,
        });
        throw error;
      }
    }

    public initialState() {
      return {
        ...super.initialState(),
        currentPageNumber: 0,
        maxPageNumber: -1,
        pageNumberFromServer: 0,
        iterator: null as AsyncIterableIterator<TEntity[]> | null,
        dataIndexs: [] as string[],
        isLoading: false,
        pageSize: PAGE_SIZE,
      };
    }

    public reducers() {
      return {
        ...super.reducers(),
        setPageSize: (pageSize: number) => {
          this.state.pageSize = pageSize;
        },
        setCurrentPageNumber: (pageNumber: number) => {
          this.state.currentPageNumber = pageNumber;
        },
        setIterator: (iterator: AsyncIterableIterator<TEntity[]>) => {
          this.state.iterator = iterator;
        },
        setMaxPageNumber: (pageNumber: number) => {
          this.state.maxPageNumber = pageNumber;
        },
        setDataIndexs: (indexs: string[]) => {
          this.state.dataIndexs = indexs;
        },
        setPageNumberFromServer: (pageNumber: number) => {
          this.state.pageNumberFromServer = pageNumber;
        },
        setIsLoading: (isLoading: boolean) => {
          this.state.isLoading = isLoading;
        },
      };
    }

    public selectors() {
      return {
        ...super.selectors(),
        dataSource: createSelector(
          () => this.state.dataIndexs,
          () => getItems(this.getContainer),
          (dataIndexs, items) => {
            const pageData: TEntity[] = [];
            dataIndexs.forEach((id) => {
              const entity = getItem(this.getContainer, id);
              if (entity) {
                pageData.push(entity);
              }
            });

            return pageData;
          }
        ),
        pageSize: () => this.state.pageSize,
        hasNext: () => this.state.maxPageNumber === -1 && !!this._initialAction,
        maxLimited: () => LIMITED_SIZE,
      };
    }

    protected async _initialIterator(payload: {
      initialAction: () => Promise<
        PagedAsyncIterableIterator<TEntity, TEntity[], PageOptions>
      >;
      force?: boolean;
    }) {
      const { initialAction, force } = payload;
      await this.actions.setCurrentPageNumber.dispatch(0);
      await this.actions.setMaxPageNumber.dispatch(-1);
      await this.actions.setPageNumberFromServer.dispatch(0);
      await this.actions.setDataIndexs.dispatch([]);

      const list = await initialAction();

      const iterator = list.byPage({
        limit: this.getters.maxLimited,
      });

      this._initialAction = initialAction;

      await this.actions.setIterator.dispatch(iterator);
      await this.actions.loadNext.dispatch(force);
      await this.actions.setCurrentPageNumber.dispatch(1);
    }

    public effects() {
      return {
        ...super.effects(),

        refresh: async () => {
          if (this._initialAction && this.state.iterator) {
            this._initialIterator({
              initialAction: this._initialAction,
              force: true,
            });
          }
        },

        changePage: async (page: number) => {
          if (
            this.state.maxPageNumber !== -1 &&
            page > this.state.maxPageNumber
          ) {
            return;
          }

          if (page >= this.state.pageNumberFromServer - 2) {
            await this.actions.loadNext.dispatch(true);
          }

          this.actions.setCurrentPageNumber.dispatch(page);
        },

        loadNext: async (force?: boolean) => {
          if (
            (this.state.maxPageNumber !== -1 &&
              this.state.pageNumberFromServer >= this.state.maxPageNumber) ||
            this.state.isLoading
          ) {
            return;
          }

          if (!this.state.iterator) {
            throw new Error(
              "iterator is null.Please use 'initialIterator' first."
            );
          }

          await this.actions.setIsLoading.dispatch(true);

          try {
            const result = await this.state.iterator.next();
            const entites: TEntity[] | undefined = result.value;

            if (!entites) {
              await this.actions.setMaxPageNumber.dispatch(
                this.state.pageNumberFromServer
              );
              return;
            }

            await onLoadedItems?.(this.getContainer, entites);

            const dataIndexs = [
              ...this.state.dataIndexs,
              ...entites.map((item) => getItemId(item)),
            ];

            await this.actions.setDataIndexs.dispatch(dataIndexs);
            await this._saveEntity(entites, force);
            const pageNumberFromServer =
              this.state.pageNumberFromServer +
              Math.ceil(entites.length / PAGE_SIZE);

            if (entites.length < this.getters.maxLimited) {
              await this.actions.setMaxPageNumber.dispatch(
                pageNumberFromServer
              );
            }
            await this.actions.setPageNumberFromServer.dispatch(
              pageNumberFromServer
            );
          } finally {
            await this.actions.setIsLoading.dispatch(false);
          }
        },
      };
    }
  };
};

// 支持真翻页
export const createListV2Model = function <TEntity extends object>(payload: {
  setItems: (
    context: GetContainer,
    items: (string | TEntity)[]
  ) => Promise<void>;
  getItems: (getContainer: GetContainer) => TEntity[];
  getItem: (getContainer: GetContainer, id: string) => TEntity | undefined;
  getItemId: (entity: TEntity) => string;
  onLoadedItems?: (
    getContainer: GetContainer,
    items: TEntity[]
  ) => Promise<void>;
}) {
  const { setItems, getItems, getItem, getItemId, onLoadedItems } = payload;
  return class BaseListModel extends mergeModels(
    ModelBase,
    mergeSubModels({
      _rw: createItemsReadWriteModel<TEntity>({
        getItemId: (item) => getItemId(item),
        setItems: ({ getContainer }, items) => setItems(getContainer, items),
      }),
    })
  ) {
    private _initialAction?: (
      pageIndex: number,
      pageSize: number
    ) => Promise<EncooListEntity<TEntity>>;

    protected async _saveEntity(items: TEntity[], force?: boolean) {
      const { ids: beginReadIds, timestamp } =
        await this.actions._rw.beginRead.dispatch({
          ids: items.map((item) => getItemId(item)),
          force,
        });

      try {
        if (beginReadIds.length > 0) {
          await this.actions._rw.endRead.dispatch({
            items: items,
            timestamp,
          });
        }
      } catch (error) {
        await this.actions._rw.endRead.dispatch({
          items: [],
          timestamp,
        });
        throw error;
      }
    }

    public initialState() {
      return {
        ...super.initialState(),
        currentPageIndex: 0,
        pageSize: PAGE_SIZE,
        totalCount: null as number | null,
        dataIndexs: {} as Record<number, string[] | undefined>,
        isLoading: false,
      };
    }

    public reducers() {
      return {
        ...super.reducers(),

        setCurrentPageIndex: (pageIndex: number) => {
          this.state.currentPageIndex = pageIndex;
        },

        setPageSize: (pageSize: number) => {
          this.state.pageSize = pageSize;
        },

        setTotalCount: (totalCount: number | null) => {
          this.state.totalCount = totalCount;
        },
        setDataIndexs: (indexs: Record<number, string[] | undefined>) => {
          this.state.dataIndexs = indexs;
        },
        setIsLoading: (isLoading: boolean) => {
          this.state.isLoading = isLoading;
        },
      };
    }

    public selectors() {
      return {
        ...super.selectors(),
        dataSource: createSelector(
          () => this.state.dataIndexs,
          () => getItems(this.getContainer),
          () => this.state.currentPageIndex,
          (dataIndexs, items, pageIndex) => {
            const pageData: TEntity[] = [];
            const ids = dataIndexs[pageIndex];
            ids?.forEach((id) => {
              const entity = getItem(this.getContainer, id);
              if (entity) {
                pageData.push(entity);
              }
            });

            return pageData;
          }
        ),
        listDataSource: createSelector(
          () => this.state.dataIndexs,
          () => getItems(this.getContainer),
          () => this.state.currentPageIndex,
          (dataIndexs, items, pageIndex) => {
            const pageData: TEntity[] = [];
            for (let index = 0; index <= pageIndex; index++) {
              const ids = dataIndexs[index];

              if (!ids) {
                break;
              }

              ids.forEach((id) => {
                const entity = getItem(this.getContainer, id);
                if (entity) {
                  pageData.push(entity);
                }
              });
            }
            return pageData;
          }
        ),
        currentPageNumber: createSelector(
          () => this.state.currentPageIndex,
          (pageIndex) => pageIndex + 1
        ),
        maxPageIndex: createSelector(
          () => this.state.totalCount,
          (): number => this.state.pageSize,
          (totalCount, pageSize) =>
            totalCount ? Math.ceil(totalCount / pageSize) - 1 : null
        ),
        hasNext: () =>
          this.getters.maxPageIndex !== this.state.currentPageIndex,
        $onChange: createSelector(
          () => (page: number) => this.actions.changePage.dispatch(page)
        ),
        $onLoadNext: createSelector(
          () => () => this.actions.loadNext.dispatch(true)
        ),
        pagination: createSelector(
          (): number => this.getters.currentPageNumber,
          (): number => this.state.pageSize,
          (): number => this.state.totalCount ?? 0,
          (): ((page: number) => void) => this.getters.$onChange,
          (current, pageSize, total, onChange) => {
            return {
              current,
              pageSize,
              total,
              onChange,
            };
          }
        ),
        listPagination: createSelector(
          (): boolean => this.state.isLoading,
          (): boolean => this.getters.hasNext,
          (): (() => void) => this.getters.$onLoadNext,
          (isLoading, hasNext, onLoadNext) => {
            return {
              isLoading,
              hasNext,
              onLoadNext,
            };
          }
        ),
      };
    }

    protected async _initial(payload: {
      initialAction: (
        pageIndex: number,
        pageSize: number
      ) => Promise<EncooListEntity<TEntity>>;
    }) {
      const { initialAction } = payload;

      await this.actions.setTotalCount.dispatch(null);
      await this.actions.setDataIndexs.dispatch([]);
      await this.actions.setCurrentPageIndex.dispatch(0);
      this._initialAction = initialAction;

      await this.actions.changePage.dispatch(1);
    }

    public effects() {
      return {
        ...super.effects(),

        refresh: async () => {
          if (this._initialAction) {
            await this.actions.changePage.dispatch(1);
          }
        },

        changePage: async (pageNumber: number) => {
          const pageIndex = pageNumber - 1;

          if (
            this.getters.maxPageIndex !== null &&
            pageIndex > this.getters.maxPageIndex
          ) {
            return;
          }

          try {
            await this.actions.setIsLoading.dispatch(true);
            const list = await this._initialAction?.(
              pageIndex,
              this.state.pageSize
            );

            // 参数的页码大于总页码的情况，调整页面到最后一页，再请求
            // if (list?.list.length === 0 && list.count > 0) {
            //   pageIndex = Math.ceil(list.count / this.getters.pageSize) - 1;

            //   list = await this._initialAction?.(
            //     pageIndex,
            //     this.getters.pageSize
            //   );
            // }

            const entites = list?.list ?? [];
            await onLoadedItems?.(this.getContainer, entites);

            const ids = entites.map((item) => getItemId(item));
            const dataIndexs = { ...this.state.dataIndexs };
            dataIndexs[pageIndex] = ids;
            await this.actions.setDataIndexs.dispatch(dataIndexs);
            await this._saveEntity(entites, true);
            await this.actions.setTotalCount.dispatch(list?.count ?? null);
            await this.actions.setCurrentPageIndex.dispatch(pageIndex);
          } finally {
            await this.actions.setIsLoading.dispatch(false);
          }
        },

        loadNext: async () => {
          await this.actions.changePage.dispatch(
            this.getters.currentPageNumber + 1
          );
        },

        resetPageSize: async () => {
          await this.actions.setPageSize.dispatch(PAGE_SIZE);
        },
      };
    }
  };
};
