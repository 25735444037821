import { createModel, createSelector } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { DataPermissionEntityModel } from "src/store/models/entity/dataPermission/entity";
import { DeviceEntityModel } from "src/store/models/entity/device/entity";
import { DeviceHelperModel } from "src/store/models/entity/device/helper";

export const DeviceDetailUIModel = createModel(
  class extends ModelBase {
    private get _id() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.containerKey!;
    }
    public selectors() {
      return {
        deviceDetail: createSelector(
          () => this.getContainer(DeviceEntityModel).state.byId,
          () => this.getContainer(DataPermissionEntityModel).state.byId,
          (deviceById, permissionById) => {
            const device = deviceById[this._id];
            return device
              ? {
                  ...device,
                  permissionValues:
                    permissionById[this._id]?.permissionValues ?? [],
                }
              : null;
          }
        ),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        requestDetail: async () => {
          await this.getContainer(DeviceHelperModel).actions.getById.dispatch({
            deviceId: this._id,
          });
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
