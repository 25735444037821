import { Asset, AssetCreationData } from "@encoo-web/encoo-lib";
import { createModel } from "nyax";
import { AssetEntityModel } from "src/store/models/entity/asset/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const AssetHelperModel = createModel(
  class extends createHelperModel<Asset>({
    setItems: (getContainer, items) =>
      getContainer(AssetEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(AssetEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(AssetEntityModel).state.byId[id],
  }) {
    public effects() {
      return {
        ...super.effects(),

        readById: async (payload: { id: string; force?: boolean }) => {
          const { id, force } = payload;
          return await this._readById({
            id,
            getByIdAction: () => {
              return this.dependencies.serviceClient.assetV2.getById(id);
            },
            force,
          });
        },

        create: async (payload: { assetCreationData: AssetCreationData }) => {
          const { assetCreationData } = payload;
          return await this._create({
            createAction: async () => {
              return await this.dependencies.serviceClient.assetV2.create(
                assetCreationData
              );
            },
          });
        },

        update: async (payload: {
          id: string;
          assetCreationData: AssetCreationData;
        }) => {
          const { id, assetCreationData } = payload;
          return await this._update({
            id: id,
            updateAction: () =>
              this.dependencies.serviceClient.assetV2.update(
                id,
                assetCreationData
              ),
          });
        },

        delete: async (payload: { id: string }) => {
          const { id } = payload;
          return await this._delete({
            id,
            deleteAction: () =>
              this.dependencies.serviceClient.assetV2.delete(id),
          });
        },
      };
    }
  }
);
