import { CalendarsDetailUIModel } from "src/store/models/ui/calendars/calendarsDetail";
import { CalendarsSideUIModel } from "src/store/models/ui/calendars/calendarsSide";
import { CalendarsTableUIModel } from "src/store/models/ui/calendars/calendarsTable";
import { CalendarsCreateDialogUIModel } from "./calendarsCreateDialog";
import { ReferencedDataAlertDialogUIModel } from "./referencedDataAlertDialog";

export const encooCalendarsUIModels = {
  calendarsTable: CalendarsTableUIModel,
  calendarsSide: CalendarsSideUIModel,
  calendarsDetail: CalendarsDetailUIModel,
  calendarsCreateDialog: CalendarsCreateDialogUIModel,
  referencedDataAlertDialog: ReferencedDataAlertDialogUIModel,
};
