import { createModel } from "nyax";
import { RunInstanceLog, RunInstanceResult } from "src/models/runInstance";
import { ModelBase } from "src/store/ModelBase";
import { RunInstanceHelperModel } from "src/store/models/entity/runInstance/helper";
import { handleErrorNotFound } from "src/utils/error";

export const RunInstanceLogModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        selectedRunInstanceId: "" as string,
        selectedTimeLogItem: null as RunInstanceLog | null,
        runInstanceResult: null as RunInstanceResult | null,
      };
    }

    public reducers() {
      return {
        setSelectedRunInstanceId: (value: string) => {
          this.state.selectedRunInstanceId = value;
        },
        setSelectedTimeLogItem: (value: RunInstanceLog | null) => {
          this.state.selectedTimeLogItem = value;
        },
        setRunInstanceResult: (value: RunInstanceResult | null) => {
          this.state.runInstanceResult = value;
        },
      };
    }

    public effects() {
      return {
        ...super.effects(),
        requestRuninstanceResult: async (payload: {
          runinstanceId: string;
        }) => {
          const { runinstanceId } = payload;
          try {
            const value = await this.getContainer(
              RunInstanceHelperModel
            ).actions.getResult.dispatch({
              runinstanceId,
            });
            await this.actions.setRunInstanceResult.dispatch(value);
          } catch (error) {
            handleErrorNotFound(error);
          }
        },
      };
    }
  }
);
