// eslint-disable-next-line import/no-anonymous-default-export
export default {
  menu: {
    dashboard: {
      name: "仪表盘",
      overallMonitor: { name: "总体监控" },
      userFlowStatistics: { name: "用户流程统计表" },
      robotRunStatistics: { name: "机器人运行统计表" },
      robotStatusStatistics: { name: "机器人监控" },
      queueStatistics: { name: "机器人组监控" },
    },
    coe: {
      name: "自动化卓越中心",
      myWork: { name: "我发起的" },
      toDoWork: { name: "待处理的" },
      initiated: { name: "我发起的" },
      pending: { name: "待处理的" },
      dataAnalysis: { name: "数据分析" },
    },
    rpa: {
      name: "RPA中心",
      package: { name: "流程包管理" },
      workflow: { name: "流程部署" },
      workflowLayout: {
        name: "流程编排",
        editor: {
          name: "编辑模板",
        },
      },
      robot: { name: "机器人管理" },
      jobQueue: { name: "任务队列" },
      queue: { name: "机器人组" },
      log: { name: "执行记录" },
      device: { name: "计算机管理" },
      calendars: { name: "日历管理" },
    },
    apps: {
      name: "ViCode 管理",
      app: { name: "应用管理" },
      myApp: { name: "我的应用" },
      appDev: { name: "应用开发" },
    },
    market: {
      name: "市场",
    },
    aiPlatform: {
      name: "AI中心",
      understanding: {
        name: "文档理解",
      },
      ocr: {
        name: "OCR",
      },
      aiManagement: {
        name: "AI服务管理",
      },
    },
    lamoss: {
      name: "智能知识库",
      knowledge: {
        name: "知识库",
      },
      application: {
        name: "应用",
      },
      manage: {
        name: "管理",
      },
    },
    understanding: {
      name: "文档理解",
      editor: {
        name: "编辑模板",
      },
      tester: {
        name: "测试结果",
      },
    },

    data: {
      name: "数据中心",
      asset: { name: "资产管理" },
      file: { name: "文件服务" },
      dataTable: { name: "数据表管理" },
      dataConnection: { name: "连接管理" },
      dataQueue: { name: "数据队列" },
    },
    setting: {
      name: "管理中心",
      organization: { name: "组织架构管理" },
      user: { name: "用户管理" },
      role: { name: "角色管理" },
      userManager: { name: "用户管理" },
      resourcegroup: { name: "资源组管理" },
      auditlog: { name: "审计日志" },
      license: { name: "许可证管理" },
      systemSetting: { name: "系统设置" },
    },
  },
  home: {
    tabs: {
      default: "默认视图",
      security: "安全管控",
      custom: "自定义视图",
    },
    welcomePanel: {
      title: "欢迎使用云扩超自动化平台",
      lastLandingTime: "上次登录时间",
      currentCompany: "当前公司",
      enterprise: "企业版",
      community: "个人免费版",
      personal: "个人专业版",
      applyForEnterprise: "申请企业版许可证",
    },
    navigation: {
      title: "常用导航",
      dashboard: "仪表盘",
      uploadPackage: "上传流程包",
      processDeployment: "流程部署",
      robotManagement: "机器人管理",
      performLog: "执行记录",
    },
    service: {
      title: "更多服务",
      documentationUnderstand: "文档理解",
      appManagement: "应用管理",
      dataCenter: "数据中心",
    },
    practicalLink: {
      title: "实用链接",
      consoleDocument: "产品文档",
      consoleCourse: "云扩课程",
      consoleBBS: "云扩社区",
      consoleAcademy: "云扩学院",
      consoleAcademyCourse: "培训课程",
      consoleAcademyDocs: "产品文档",
      consoleAPIDocs: "API文档",
    },
    colltion: {
      title: "收藏应用",
      more: "更多收藏",
    },
    packageDownload: {
      title: "安装包下载",
      name: "应用名称",
      editName: "云扩RPA编辑器",
      robotName: "云扩RPA机器人",
      releaseTime: "发布时间",
      version: "版本号",
      download: "下载",
    },
    serviceAddress: {
      title: "服务地址",
      address: "连接字符串",
      reminder:
        "(此字符串用于编辑器或机器人登录控制台激活使用，请点击按钮进行字符串复制)",
      signScuess: "正在登录中...",
    },
  },
  header: {
    support: {
      name: "支持",
    },
    notification: {
      name: "通知",
      content: "敬请期待",
    },
  },
};
