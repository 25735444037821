import {
  FilePermissData,
  FilePermissionUpdateData,
  PermissionMode,
  User,
  UserPermissionInfo,
} from "@encoo-web/encoo-lib/types";
import { createModel } from "nyax";
import { EncooFile } from "src/models/file";
import { EncooSort } from "src/models/_shared";
import { ModelBase } from "src/store/ModelBase";
import { DataPermissionEntityModel } from "src/store/models/entity/dataPermission/entity";
import { FileEntityModel } from "src/store/models/entity/file/entity";
import { FileHelperModel } from "src/store/models/entity/file/helper";
import { RouterModel } from "src/store/models/router";
import { FileUIModel } from "src/store/models/ui/file/file";

export interface FileItem extends EncooFile {
  children?: FileItem[];
  loadedChildren?: boolean;
  connectionErr?: false;
}

export interface UserCheckedData extends User {
  checked?: boolean;
  permission?: PermissionMode;
}

export type UserInfo = Partial<UserCheckedData & UserPermissionInfo>;

export const FileItemUIModel = createModel(
  class extends ModelBase {
    private get _id() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.containerKey!;
    }

    private get _helperContainer() {
      return this.getContainer(FileHelperModel);
    }

    private get _entityContainer() {
      return this.getContainer(FileEntityModel);
    }

    private get _dataPermissionEntityModel() {
      return this.getContainer(DataPermissionEntityModel);
    }

    public initialState() {
      return {
        children: [] as string[],
        loadedChildren: false,
        connectionErr: false,
        filePermission: {} as FilePermissData,
        isShowUserModal: false,
        checkedUser: [] as UserInfo[],
        currentFile: null as EncooFile | null,
      };
    }

    public reducers() {
      return {
        setChildren: (children: string[]) => {
          this.state.children = children;
        },
        setLoadedChildren: (loadedChildren: boolean) => {
          this.state.loadedChildren = loadedChildren;
        },
        setConnectionErr: (value: boolean) => {
          this.state.connectionErr = value;
        },
        setFilePermission: (filePermission: FilePermissData) => {
          this.state.filePermission = filePermission;
        },
        connectCheckedUser: (value: UserInfo[]) => {
          this.state.checkedUser = [...this.state.checkedUser, ...value];
        },
        setCheckedUser: (value: UserInfo[]) => {
          this.state.checkedUser = value;
        },
        setShowUserModal: (value: boolean) => {
          this.state.isShowUserModal = value;
        },
        setCurrentFile: (file: EncooFile | null) => {
          this.state.currentFile = file;
        },
      };
    }

    public selectors() {
      return {
        ...super.selectors(),
        file: (): FileItem => {
          const byId = this._entityContainer.state.byId;
          const id = this._id;
          const children = this.state.children;
          const loadedChildren = this.state.loadedChildren;
          const connectionErr = this.state.connectionErr;
          const permissionById = this._dataPermissionEntityModel.state.byId;
          const file = byId[id]
            ? {
                ...byId[id],
                permissionValues: permissionById[byId[id]?.rootId ?? ""],
              }
            : {
                id,
                nodeName: "",
                directory: "",
                fullName: "",
                contentType: "",
                size: 0,
                fileType: "Directory",
                createdAt: "",
                createdByName: "",
                resourceType: "File",
                departmentId: "",
                permissionValues: [],
                isRoot: true,
                rootId: "",
                dataConnectionId: "",
              };
          const childrenItems = children.map((id: string) => ({
            ...this.getContainer(FileItemUIModel, id).getters.file,
            permissionValues:
              permissionById[file.rootId ?? ""]?.permissionValues ?? [],
          }));
          return {
            ...file,
            children: childrenItems,
            loadedChildren,
            connectionErr,
          } as FileItem;
        },
        parentId: () =>
          this._entityContainer.state.byId[this._id]?.parentId ?? null,
        fullName: () =>
          this._entityContainer.state.byId[this._id]?.fullName ?? "",
      };
    }
    public effects() {
      return {
        ...super.effects(),
        requestChildrenFiles: async (payload: {
          isRoot?: boolean;
          force?: boolean;
          query: EncooSort;
        }) => {
          const { isRoot, force = true, query } = payload;

          const loadedChildren = this.state.loadedChildren;
          const fullName = isRoot ? "" : this.getters.fullName;
          if (!loadedChildren || force) {
            try {
              await this._helperContainer.actions.getAllChildren.dispatch({
                fullName,
                parentId: this._id,
                query,
              });

              const files = await this._helperContainer.actions.list.dispatch({
                fullName,
                query,
              });

              const children = files.map((file) => file.id);
              await this.actions.setChildren.dispatch(children);
              await this.actions.setConnectionErr.dispatch(false);
              await this.actions.setLoadedChildren.dispatch(true);
            } catch (err) {
              await this.actions.setChildren.dispatch([]);
              await this.actions.setConnectionErr.dispatch(true);
              await this.actions.setLoadedChildren.dispatch(true);
            }
          }
        },
        createChildDirectory: async (payload: {
          directoryName: string;
          dataConnectionId?: string;
        }) => {
          const fullName = this.getters.fullName;
          const { directoryName, dataConnectionId } = payload;
          const query = this.getContainer(FileUIModel).state.query;
          const file =
            await this._helperContainer.actions.createDirectory.dispatch({
              parentId: this._id,
              fullName,
              name: directoryName,
              dataConnectionId,
            });

          await this.actions.requestChildrenFiles.dispatch({
            force: true,
            query,
          });

          return file;
        },
        delete: async () => {
          const backupFullName = this.getters.fullName;
          const backupParentId = this.getters.parentId;

          await this._helperContainer.actions.delete.dispatch({
            id: this._id,
            fullName: this.getters.fullName,
          });

          if (backupParentId) {
            const parentContainer = this.getContainer(
              FileItemUIModel,
              backupParentId
            );
            await parentContainer.actions.setChildren.dispatch(
              parentContainer.state.children.filter((id) => this._id !== id)
            );

            const routerContainer = this.getContainer(RouterModel);

            const params = routerContainer.getters.currentRouteInfo
              .params as Record<string, string>;
            const { fullName } = params;

            if (fullName && fullName.startsWith(backupFullName)) {
              await routerContainer.actions.navigateByRouteInfo.dispatch({
                type: "file",
                params: {
                  fullName: parentContainer.getters.fullName,
                },
              });
            }
          }
        },
        getFilePermission: async () => {
          const filePermission =
            await this._helperContainer.actions.getFilePermission.dispatch(
              this._id
            );
          await this.actions.setCheckedUser.dispatch(
            filePermission.userPermissions
          );
          await this.actions.setFilePermission.dispatch(
            filePermission as FilePermissData
          );
        },
        updateFilePermission: async (payload: {
          permissionId: string;
          filePermissionData: FilePermissionUpdateData;
        }) => {
          const { permissionId, filePermissionData } = payload;
          await this._helperContainer.actions.updateFilePermission.dispatch({
            id: permissionId,
            filePermissionData,
          });
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
