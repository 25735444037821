import { WorkflowLayoutDetailUIModel } from "src/store/models/ui/workflowLayout/workflowLayoutDetail";
import { WorkflowLayoutJobUIModel } from "src/store/models/ui/workflowLayout/workflowLayoutJob";
import { WorkflowLayoutNodesEditorUIModel } from "src/store/models/ui/workflowLayout/workflowLayoutNodesEditor";
import { WorkflowLayoutSideUIModel } from "src/store/models/ui/workflowLayout/workflowLayoutSide";
import { WorkflowLayoutTableUIModel } from "src/store/models/ui/workflowLayout/workflowLayoutTable";

export const encooWorkflowLayoutUIModels = {
  workflowLayoutTable: WorkflowLayoutTableUIModel,
  workflowLayoutSide: WorkflowLayoutSideUIModel,
  workflowLayoutDetail: WorkflowLayoutDetailUIModel,
  workflowLayoutNodesEditor: WorkflowLayoutNodesEditorUIModel,
  WorkflowLayoutJobTable: WorkflowLayoutJobUIModel,
};
