import moment from "moment";
import { createModel, createSelector } from "nyax";
import messageIds from "src/locales/messageIds";
import {
  DashboardQueryData,
  JobDailyCount,
  JobStatus,
  JobStatusCounts,
  TimeRangeLimit,
} from "src/models/dashboard";
import { ModelBase } from "src/store/ModelBase";
import { timezoneOffsetRelativeToUtc } from "src/utils/string";

export interface StaticsType {
  packages?: number;
  workflow?: number;
  queue?: number;
  job?: number;
  robot?: number;
}
export type RobotType = {
  Disconnected: number;
  Ready: number;
  Busy: number;
};
export type JobsTypes = {
  date: string;
  status: JobStatus;
  count: number;
  percent: number;
};
const jobColor = [
  "#ccddff",
  "#99bbff",
  "#6699ff",
  "#3377ff",
  "#d0d3d8",
  "#d0d3d8",
  "#4C6398",
];
export const JobStatusMap = {
  Queued: {
    name: messageIds.dashboard.table.wait,
    sort: 0,
    color: jobColor[0],
  },
  Running: {
    name: messageIds.dashboard.table.operation,
    sort: 1,
    color: jobColor[1],
  },
  Paused: {
    name: messageIds.dashboard.table.paused,
    sort: 2,
    color: jobColor[2],
  },
  Cancelled: {
    name: messageIds.dashboard.table.cancelled,
    sort: 3,
    color: jobColor[5],
  },
  Succeeded: {
    name: messageIds.dashboard.table.success,
    sort: 4,
    color: jobColor[3],
  },
  Failed: {
    name: messageIds.dashboard.table.fail,
    sort: 5,
    color: jobColor[6],
  },
};

export const DashboardUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        dateRange: {
          startDate: moment().subtract(29, "days").format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
        },
        containsSubDepartment: false,
        statics: {
          packages: 0,
          workflow: 0,
          queue: 0,
          job: 0,
          robot: 0,
        },
        robots: {
          Disconnected: 0,
          Ready: 0,
          Busy: 0,
        },
        jobsDailyStatics: [] as Array<JobDailyCount>,
        jobTotalStatics: [] as Array<JobStatusCounts>,
        jobColor,
        timerangelimit: {} as TimeRangeLimit,
      };
    }
    public reducers() {
      return {
        setDateRange: (
          dateRange: Pick<DashboardQueryData, "startDate" | "endDate">
        ) => {
          this.state.dateRange = dateRange;
        },
        setContainsSubDepartment: (value: boolean) => {
          this.state.containsSubDepartment = value;
        },
        setStatics: (statics: StaticsType) => {
          this.state.statics = { ...this.state.statics, ...statics };
        },
        setJobTotalStatics: (statics: Array<JobStatusCounts>) => {
          this.state.jobTotalStatics = statics;
        },
        setJobsDailyStatics: (statics: Array<JobDailyCount>) => {
          this.state.jobsDailyStatics = statics;
        },
        setRobots: (robots: RobotType) => {
          this.state.robots = robots;
        },
        setTimerangelimit: (value: TimeRangeLimit) => {
          this.state.timerangelimit = value;
        },
      };
    }
    public selectors() {
      return {
        jobTotalData: createSelector(
          () => this.state.jobTotalStatics,
          () => JobStatusMap,
          (items, nameMap) => {
            const statusMap = new Map<string, JobStatusCounts>();
            items.forEach((item) => {
              statusMap.set(item.status, item);
            });
            return [
              {
                ...nameMap.Queued,
                count: statusMap.get("Queued")?.count,
                percent: statusMap.get("Queued")?.percent,
              },

              {
                ...nameMap.Running,
                count: statusMap.get("Running")?.count,
                percent: statusMap.get("Running")?.percent,
              },
              {
                ...nameMap.Paused,
                count: statusMap.get("Paused")?.count,
                percent: statusMap.get("Paused")?.percent,
              },
              {
                ...nameMap.Cancelled,
                count: statusMap.get("Cancelled")?.count,
                percent: statusMap.get("Cancelled")?.percent,
              },
              {
                ...nameMap.Succeeded,
                count: statusMap.get("Succeeded")?.count,
                percent: statusMap.get("Succeeded")?.percent,
              },

              {
                ...nameMap.Failed,
                count: statusMap.get("Failed")?.count,
                percent: statusMap.get("Failed")?.percent,
              },
            ];
          }
        ),
        jobDailyData: createSelector(
          () => this.state.jobsDailyStatics,
          () => JobStatusMap,
          () => [this.state.dateRange.startDate, this.state.dateRange.endDate],
          (items, statusMap, dates) => {
            let jobsDailyStatics: Array<JobsTypes> = [];
            if (dates[0] && dates[1]) {
              let tempStartDate = moment(dates[0]).format("YYYY-MM-DD");
              const tempEndDate = moment(dates[1]).format("YYYY-MM-DD");
              const list = [];
              while (tempStartDate <= tempEndDate) {
                list.push(tempStartDate);
                tempStartDate = moment(tempStartDate)
                  .add(1, "day")
                  .format("YYYY-MM-DD");
              }
              const dateMap = new Map<string, JobDailyCount>();
              items.forEach((item) =>
                dateMap.set(
                  moment(item.statisticsDate).format("YYYY-MM-DD"),
                  item
                )
              );
              list.forEach((dateItem) => {
                const data = dateMap.get(dateItem);
                if (data) {
                  data.addedJobStatusCounts
                    .map((item) => item)
                    .sort((curItem, Nextitem) => {
                      return statusMap[curItem.status]?.sort >
                        statusMap[Nextitem.status]?.sort
                        ? 1
                        : -1;
                    })
                    .forEach((item) => {
                      jobsDailyStatics.push({
                        date: dateItem,
                        status: item.status,
                        count: item.count,
                        percent: item.percent,
                      });
                    });
                } else {
                  const emptyData = [
                    "Queued",
                    "Running",
                    "Paused",
                    "Cancelled",
                    "Succeeded",
                    "Failed",
                  ].map((item) => ({
                    date: dateItem,
                    status: item as JobStatus,
                    count: 0,
                    percent: 0,
                  }));
                  jobsDailyStatics = jobsDailyStatics.concat(emptyData);
                }
              });
            }
            return jobsDailyStatics;
          }
        ),
        timerangelimitData: createSelector(
          () => this.state.timerangelimit,
          (time) => {
            switch (time.limitType) {
              case "Year":
                return time.limitValue * 365;
              case "Month":
                return time.limitValue * 30;
              case "Day":
                return time.limitValue;
              default:
                return undefined;
            }
          }
        ),
        timerrangelimittipData: createSelector(
          () => this.state.timerangelimit,
          (time) => {
            switch (time.limitType) {
              case "Year":
                return `${
                  time.limitValue
                }${this.dependencies.locale.intl.formatMessage({
                  id: messageIds.dashboard.timeRange.year,
                })}`;
              case "Month":
                return `${
                  time.limitValue
                }${this.dependencies.locale.intl.formatMessage({
                  id: messageIds.dashboard.timeRange.month,
                })}`;
              case "Day":
                return `${
                  time.limitValue
                }${this.dependencies.locale.intl.formatMessage({
                  id: messageIds.dashboard.timeRange.day,
                })}`;
              default:
                return this.dependencies.locale.intl.formatMessage({
                  id: messageIds.dashboard.timeRange.notLimit,
                });
            }
          }
        ),
      };
    }
    public effects() {
      return {
        initializeRequest: async (payload: {
          departmentId: string;
          containsSubDepartment: boolean;
        }) => {
          const [robot, packages, workflow, queue, jobData] = await Promise.all(
            [
              this.actions.robotStatistics.dispatch(payload),
              this.actions.packageStatistics.dispatch(payload),
              this.actions.workflowStatistics.dispatch(payload),
              this.actions.queueStatistics.dispatch(payload),
              this.actions.jobStatistics.dispatch(payload),
            ]
          );
          await this.actions.setRobots.dispatch({
            Disconnected: robot.totalRobotStatusCounts.Disconnected || 0,
            Ready: robot.totalRobotStatusCounts.Ready || 0,
            Busy: robot.totalRobotStatusCounts.Busy || 0,
          });
          await this.actions.setStatics.dispatch({
            packages: packages.totalPackageCount,
            workflow: workflow.totalWorkflowCount,
            queue: queue.totalQueueCount,
            robot: robot.totalRobotCount,
          });

          await this.actions.setJobTotalStatics.dispatch(
            jobData.totalJobStatusCounts || []
          );
          await this.actions.setStatics.dispatch({
            job: jobData.totalJobCount,
          });
          await this.actions.setJobsDailyStatics.dispatch(
            jobData.dailyJobCountDTOs || []
          );
        },
        robotStatistics: async (payload: {
          departmentId: string;
          containsSubDepartment: boolean;
        }) => {
          const { departmentId, containsSubDepartment } = payload;
          return await this.dependencies.serviceClient.dashboard.robotStatistics(
            departmentId,
            containsSubDepartment
          );
        },
        jobStatistics: async (payload: {
          departmentId: string;
          containsSubDepartment: boolean;
        }) => {
          const { departmentId, containsSubDepartment } = payload;
          return await this.dependencies.serviceClient.dashboard.jobStatistics(
            departmentId,
            {
              ...this.state.dateRange,
              timeZone: timezoneOffsetRelativeToUtc,
            },
            containsSubDepartment
          );
        },
        packageStatistics: async (payload: {
          departmentId: string;
          containsSubDepartment: boolean;
        }) => {
          const { departmentId, containsSubDepartment } = payload;
          return await this.dependencies.serviceClient.dashboard.packageStatistics(
            departmentId,
            containsSubDepartment
          );
        },
        workflowStatistics: async (payload: {
          departmentId: string;
          containsSubDepartment: boolean;
        }) => {
          const { departmentId, containsSubDepartment } = payload;
          return await this.dependencies.serviceClient.dashboard.workflowStatistics(
            departmentId,
            containsSubDepartment
          );
        },
        queueStatistics: async (payload: {
          departmentId: string;
          containsSubDepartment: boolean;
        }) => {
          const { departmentId, containsSubDepartment } = payload;
          return await this.dependencies.serviceClient.dashboard.queueStatistics(
            departmentId,
            containsSubDepartment
          );
        },

        timerangelimit: async () => {
          const data =
            await this.dependencies.serviceClient.dashboard.timerangelimit();

          await this.actions.setTimerangelimit.dispatch(data);
        },
      };
    }
  }
);
