import moment from "moment";
import { createModel } from "nyax";
import { DashboardQueryData } from "src/models/dashboard";
import { AiServiceCalledCount } from "src/models/ocr";
import { ModelBase } from "src/store/ModelBase";

export const ServiceStatisticsUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        xField: "usedCount",
        yField: "name",
        dateRange: {
          startDate: moment().subtract(29, "days").format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
        },
        data: [] as AiServiceCalledCount[],
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setDateRange: (
          dateRange: Pick<DashboardQueryData, "startDate" | "endDate">
        ) => {
          this.state.dateRange = dateRange;
        },
        setData: (value: AiServiceCalledCount[]) => {
          this.state.data = value;
        },
      };
    }
    public effects() {
      return {
        list: async () => {
          const beforeTime = this.state.dateRange.startDate;
          const endTime = this.state.dateRange.endDate;

          const data = await this.dependencies.serviceClient.ocr.getDashboard(
            beforeTime,
            endTime
          );

          await this.actions.setData.dispatch(data);
        },
      };
    }
    public selectors() {
      return {};
    }
  }
);
