import { createModel } from "nyax";
import { AddMessageQuery, DataQueue } from "src/models/dataQueue";
import { ModelBase } from "src/store/ModelBase";

export type ConfigType = "view" | "edit";

export const DataQueueDetailUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        messageData: [] as DataQueue[],
        configType: "view" as ConfigType,
      };
    }

    public reducers() {
      return {
        setMessageData: async (value: DataQueue[]) => {
          this.state.messageData = value;
        },
        setConfigType: async (value: ConfigType) => {
          this.state.configType = value;
        },
      };
    }

    public effects() {
      return {
        request: async (payload: { queueName: string }) => {
          const { queueName } = payload;
          this.actions.setMessageData.dispatch([]);
          const items =
            await this.dependencies.serviceClient.dataQueue.getMessages(
              queueName
            );
          await this.actions.setMessageData.dispatch(items);
        },
        create: async (payload: {
          queueName: string;
          param: AddMessageQuery;
        }) => {
          const { queueName, param } = payload;
          const item =
            await this.dependencies.serviceClient.dataQueue.createMessage(
              queueName,
              param
            );

          const items: DataQueue[] = [];
          items.push(...this.state.messageData);
          items.push(item);
          this.actions.setMessageData.dispatch(items);
        },
      };
    }
  }
);
