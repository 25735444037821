// eslint-disable-next-line import/no-anonymous-default-export
export default {
  company: {
    enterInto: "进入",
    selectCompany: "请选择公司",
    companyName: "公司名称",
    connect: {
      companyStructureConfig: "公司架构配置",
      noCompanyGroup: "暂无多级公司关系",
      viewAddCode: "查看公司加入码",
      parentCompanyInfo: "上级公司信息",
      parentCompany: "上级公司",
      noParentCompanyTip: "当前公司尚未加入上级公司，点击",
      joinCompany: "加入上级公司",
      childCompanyInfo: "子公司信息",
      childCompany: "子公司",
      noChildCompany: "当前公司没有子公司信息，请将",
      myAddCode: "公司加入码",
      sendToConnect: "发送给需要添加的子公司以进行连接。",
      mutiCompanyRelation: "多级公司关系图",
      stringSendTip: "请将该连接字符串发送给需要添加的子公司以进行连接",
      inputStringToConnect: "请输入对上级公司的连接字符串以完成连接",
      rejectJoin: "当前公司已有子公司，无法加入上级公司。",
    },
  },
};
