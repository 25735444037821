/** @jsxImportSource @emotion/react */
import { css, Global } from "@emotion/react";
import {
  EncooUIAlertProvider,
  EncooUILocaleContext,
} from "@encoo-web/encoo-ui";
import { ConfigProvider } from "antd";
import { ConnectedRouter } from "connected-react-router";
import "moment/locale/zh-cn";
import React, { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { RawIntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { AlertProvider } from "src/components/Dialog";
import { DependenciesContext, ThemeContext } from "src/contexts";
import { getDependencies } from "src/dependencies";
import cssGlobal from "src/styles/global";

export default React.memo(function AppWrapper(
  // eslint-disable-next-line @typescript-eslint/ban-types
  props: React.PropsWithChildren<{}>
) {
  const { children } = props;

  const [dependencies] = useState(getDependencies());
  const [history] = useState(dependencies.history);
  const [intl] = useState(dependencies.locale.intl);
  const [antdLocale] = useState(dependencies.locale.antdLocale);
  const [lang] = useState(dependencies.locale.intl.locale);
  const [store] = useState(dependencies.redux.store);

  return (
    <DndProvider backend={HTML5Backend}>
      <DependenciesContext.Provider value={dependencies}>
        <RawIntlProvider value={intl}>
          <EncooUILocaleContext.Provider value={lang}>
            <ConfigProvider locale={antdLocale}>
              <Provider store={store}>
                <ThemeContext.Provider value={dependencies.theme.themeSet.main}>
                  <AlertProvider>
                    <EncooUIAlertProvider>
                      <ConnectedRouter history={history}>
                        <Global
                          styles={css`
                            .ant-spin-dot-item {
                              background: ${dependencies.theme.themeSet.main
                                .primaryColor};
                            }
                            ${cssGlobal}
                          `}
                        />
                        {children}
                      </ConnectedRouter>
                    </EncooUIAlertProvider>
                  </AlertProvider>
                </ThemeContext.Provider>
              </Provider>
            </ConfigProvider>
          </EncooUILocaleContext.Provider>
        </RawIntlProvider>
      </DependenciesContext.Provider>
    </DndProvider>
  );
});
