import { Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/lib/input/TextArea";
import React, { memo, useCallback, useMemo } from "react";
import semver from "semver";
import ContentItem from "src/components/ContentItem";
import Modal from "src/components/Modal";
import { useAppsOperation } from "src/containers/apps/components/AppsOperation";
import { useFormatMessage, useGetContainer } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { AppV2, AppVersionV2 } from "src/models/appV2";
import { AppHelperModel } from "src/store/models/entity/apps/helper";
import { AppVersionHelperModel } from "src/store/models/entity/appVersion/helper";
import { inputLimitedLength } from "src/utils/validate";

export const AppReleaseDialog = memo(function AppReleaseDialog({
  app,
  onCancel,
  appVersion,
}: {
  app: AppV2;
  onCancel: () => void;
  appVersion: AppVersionV2;
}) {
  const getContainer = useGetContainer();
  const formatMessage = useFormatMessage();
  const [form] = useForm();

  const appsHelper = getContainer(AppHelperModel);
  const appVersionHelper = getContainer(AppVersionHelperModel);

  const appsOperation = useAppsOperation();

  const onRelease = useCallback(async () => {
    const { version, releaseLog } = await form.validateFields();
    await appsOperation.release(app.id, {
      version,
      releaseLog,
      subAppIds: appVersion.subAppIds,
      navigation: appVersion.navigation,
    });
    await appsHelper.actions.readById.dispatch({ id: app.id, force: true });
    await appVersionHelper.actions.readByAppIds.dispatch({
      appIds: [app.id],
      force: true,
    });
    onCancel();
  }, [
    app.id,
    appVersion.navigation,
    appVersion.subAppIds,
    appVersionHelper.actions.readByAppIds,
    appsHelper.actions.readById,
    appsOperation,
    form,
    onCancel,
  ]);

  const initialNewVersion = useMemo(() => {
    return app.lastVersion ? semver.inc(app.lastVersion, "patch") : "1.0.0";
  }, [app.lastVersion]);

  const onValidateVersion = useCallback(
    (rule, value, callback) => {
      if (!value) {
        callback(
          formatMessage(messageIds.apps.appRelease.versionErrorMessage.required)
        );
      } else if (semver.valid(semver.coerce(value)) !== value) {
        callback(
          formatMessage(
            messageIds.apps.appRelease.versionErrorMessage.patternError
          )
        );
      } else if (app?.lastVersion && !semver.gt(value, app?.lastVersion)) {
        callback(
          formatMessage(
            messageIds.apps.appRelease.versionErrorMessage.nextVersion
          )
        );
      } else {
        callback();
      }
    },
    [app, formatMessage]
  );

  return (
    <Modal
      title={formatMessage(messageIds.apps.appRelease.releaseApp)}
      visible={true}
      onOk={onRelease}
      onCancel={onCancel}
    >
      <Form layout="vertical" form={form}>
        <ContentItem
          name={formatMessage(messageIds.apps.name)}
          value={app.name}
        />
        <ContentItem
          name={formatMessage(messageIds.apps.appRelease.currentAppVersion)}
          value={app.lastVersion}
        />

        <Form.Item
          initialValue={initialNewVersion}
          name="version"
          label={formatMessage(messageIds.apps.appRelease.newAppVersion)}
          rules={[
            {
              validator: onValidateVersion,
            },
          ]}
        >
          <Input placeholder={formatMessage(messageIds.common.pleaseInput)} />
        </Form.Item>
        <Form.Item
          name="releaseLog"
          label={formatMessage(messageIds.common.description)}
          rules={[
            {
              max: inputLimitedLength.description,
              whitespace: true,
            },
          ]}
        >
          <TextArea
            rows={4}
            placeholder={formatMessage(messageIds.common.pleaseInput)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
});
