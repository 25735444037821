// eslint-disable-next-line import/no-anonymous-default-export
export default {
  dataTable: {
    entityTypeName: "Data Table",
    name: "Data Table",
    introduction:
      "Data tables, which manage the creation of data tables by the main user, store the data generated by the process.",
    dataTableName: "Table Name",
    nameMaxLengthMessage: "The name cannot exceed 50 characters",
    nameRegMessage: "Invalid name, may contain invalid characters",
    descriptionMaxLengthMessage: "The description cannot exceed 200 characters",
  },
};
