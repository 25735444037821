import { Auditlog } from "@encoo-web/encoo-lib/types";
import { createModel } from "nyax";
import { AuditlogEntityModel } from "src/store/models/entity/auditlog/entity";
import { createListModel } from "src/store/models/entity/_shared";

export const AuditlogListV2Model = createModel(
  class extends createListModel<Auditlog>({
    setItems: (getContainer, items) =>
      getContainer(AuditlogEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(AuditlogEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(AuditlogEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initialIterator: async (payload: {
          beforetime?: string;
          endtime?: string;
          AuditObjectType?: string;
          UserId?: string;
          force?: boolean;
        }) => {
          const {
            beforetime = "",
            endtime,
            AuditObjectType,
            UserId,
            force = true,
          } = payload;
          this._initialIterator({
            initialAction: () =>
              this.dependencies.serviceClient.auditlogSearch.getlist(
                beforetime,
                endtime,
                AuditObjectType,
                UserId
              ),
            force: force,
          });
        },
      };
    }
  }
);
