import { createModel } from "nyax";
import {
  AppDataSourceUpdateData,
  AppQuery,
  AppV2,
  AppV2CreationData,
  AppVersionV2,
  DataConnectorMappingItem,
} from "src/models/appV2";
import { DataPermissionPayloadData } from "src/models/dataPermission";
import { AppEntityModel } from "src/store/models/entity/apps/entity";
import { AppVersionHelperModel } from "src/store/models/entity/appVersion/helper";
import { DataPermissionHelperModel } from "src/store/models/entity/dataPermission/helper";
import { createHelperModel } from "src/store/models/entity/_shared";
import { newGuid } from "src/utils/uuid";

export const AppHelperModel = createModel(
  class extends createHelperModel<AppV2>({
    setItems: (getContainer, items) =>
      getContainer(AppEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(AppEntityModel).getters.items,
    getItem: (getContainer, id) => getContainer(AppEntityModel).state.byId[id],
  }) {
    private get _dataPermissionHelperModel() {
      return this.getContainer(DataPermissionHelperModel);
    }
    public effects() {
      return {
        ...super.effects(),
        getAll: async (payload: AppQuery) => {
          return await this._readAll({
            getAllAction: async () => {
              const apps = await this.dependencies.serviceClient.appsV2.getAll(
                payload
              );
              const appsIds: DataPermissionPayloadData[] = apps.map((item) => {
                return {
                  resourceType: item.resourceType,
                  id: item.id,
                };
              });
              if (appsIds.length > 0) {
                await this._dataPermissionHelperModel.actions.getAll.dispatch(
                  appsIds
                );
              }
              return apps;
            },
          });
        },
        readById: async (payload: { id: string; force?: boolean }) => {
          const { id, force } = payload;
          return await this._readById({
            id,
            getByIdAction: () => {
              return this.dependencies.serviceClient.appsV2.getById(id);
            },
            force,
          });
        },
        update: async (payload: {
          app: Partial<AppV2> & Pick<AppV2, "id">;
        }) => {
          const { app } = payload;
          return await this._update({
            id: app.id,
            updateAction: () =>
              this.dependencies.serviceClient.appsV2.update(app),
          });
        },
        delete: async (payload: { id: string }) => {
          const { id } = payload;
          return await this._delete({
            id: id,
            deleteAction: () =>
              this.dependencies.serviceClient.appsV2.delete(id),
          });
        },

        deleteBatch: async (payload: { appIds: string[] }) => {
          const { appIds } = payload;

          for (const appId of appIds) {
            await this.actions.delete.dispatch({ id: appId });
          }
        },
        copy: async (payload: { id: string }) => {
          const { id } = payload;

          await this.dependencies.serviceClient.appsV2.copy(id);
        },

        create: async (payload: AppV2CreationData) => {
          const newApp = await this.dependencies.serviceClient.appsV2.createApp(
            payload
          );

          const mainViewId = newGuid();

          const projectData = {
            schemaVersion: 2,
            data: {
              app: {
                type: newApp.appType ?? "App",
                info: {
                  name: newApp.name,
                  version: "1.0.0",
                  description: newApp.description,
                  icon: newApp.icon,
                },
                settings: {},
                variables: [],
              },
              page: {
                views: [
                  {
                    id: mainViewId,
                    name: "Page1",
                    component: {
                      id: newGuid(),
                      name: "View1",
                      type: "View",
                      props: {
                        children: [],
                        style: {
                          flexGrow: 1,
                        },
                      },
                      __ENCOOAPPS_TYPE__: "Component",
                    },
                    __ENCOOAPPS_TYPE__: "View",
                  },
                ],
                mainView: mainViewId,
              },
              topbar: {
                views: [],
                mainView: null,
              },
              sidebar: {
                views: [],
                mainView: null,
              },
            },
          };

          await this.getContainer(
            AppVersionHelperModel
          ).actions.update.dispatch({
            appId: newApp.id,
            appVersionId:
              newApp.draftVersionId ?? "00000000-0000-0000-0000-000000000000",
            params: { configData: JSON.stringify(projectData) },
          });
          return newApp;
        },

        addFavorite: async (payload: { appId: string }) => {
          const { appId } = payload;
          await this.dependencies.serviceClient.appsV2.addAppFavorite(appId);
        },

        deleteFavorite: async (payload: { appId: string }) => {
          const { appId } = payload;
          await this.dependencies.serviceClient.appsV2.deleteAppFavorite(appId);
        },
        updateAppDataSource: async (params: {
          appId: string;
          payload: AppDataSourceUpdateData;
        }) => {
          const { appId, payload } = params;
          const app =
            await this.dependencies.serviceClient.appsV2.updatePatchApp(
              appId,
              payload
            );
          await this.actions.readById.dispatch({ id: app.id, force: true });
        },
        releaseApp: async (params: {
          appId: string;
          payload: Partial<AppVersionV2>;
        }) => {
          const { appId, payload } = params;
          const appVersion =
            await this.dependencies.serviceClient.appsV2.releaseApp(
              appId,
              payload
            );
          return appVersion;
        },
        enableApp: async (params: {
          appId: string;
          payload?: {
            dataConnectorMappings?: DataConnectorMappingItem[];
            launchVersionId?: string;
          };
        }) => {
          const { appId, payload } = params;
          return await this._update({
            id: appId,
            updateAction: () =>
              this.dependencies.serviceClient.appsV2.enableApp(appId, payload),
          });
        },
        disableApp: async (params: { appId: string }) => {
          const { appId } = params;
          return await this._update({
            id: appId,
            updateAction: () =>
              this.dependencies.serviceClient.appsV2.disableApp(appId),
          });
        },
        uploadAppIcon: async (params: {
          appId: string;
          payload?: FormData;
        }) => {
          const { appId, payload } = params;
          await this.dependencies.serviceClient.appsV2.uploadAppIcon(
            appId,
            payload
          );
          await this.actions.readById.dispatch({ id: appId, force: true });
        },
      };
    }
  }
);
