import { createModel, createSelector } from "nyax";
import { Robot, RobotSettings } from "src/models/robot";
import { ModelBase } from "src/store/ModelBase";
import { DataPermissionEntityModel } from "src/store/models/entity/dataPermission/entity";
import { RobotEntityModel } from "src/store/models/entity/robot/entity";

export const RobotSettingsUIModel = createModel(
  class extends ModelBase {
    private get _id() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.containerKey!;
    }

    public initialState() {
      return {
        setting: null as RobotSettings | null,
        canSyncConfig: false,
        robotSdkVersion: undefined as string | undefined,
      };
    }

    public reducers() {
      return {
        setCanSyncConfig: (value: boolean) => {
          this.state.canSyncConfig = value;
        },
        setRobotSdkVersion: (value?: string) => {
          this.state.robotSdkVersion = value;
        },
        setSetting: (value: RobotSettings) => {
          this.state.setting = value;
        },
      };
    }

    public selectors() {
      return {
        robotSettings: createSelector(
          () => this.getContainer(RobotEntityModel).state.byId,
          () => this.getContainer(DataPermissionEntityModel).state.byId,
          (robotById, permissionById): Robot | null => {
            const item = robotById[this._id];
            return item
              ? {
                  ...item,
                  permissionValues:
                    permissionById[this._id]?.permissionValues ?? [],
                }
              : null;
          }
        ),
      };
    }

    public effects() {
      return {
        requestCanSyncConfig: async () => {
          const robotCapabilities =
            await this.dependencies.serviceClient.robot.getCapabilities(
              this._id
            );
          const canSyncConfig = robotCapabilities.canSyncConfig;
          const robotSdkVersion = robotCapabilities.sdkVersion;
          await this.actions.setCanSyncConfig.dispatch(canSyncConfig);
          await this.actions.setRobotSdkVersion.dispatch(robotSdkVersion);
        },

        requestSettings: async () => {
          const Config =
            await this.dependencies.serviceClient.robot.getRobotConfig(
              this._id
            );
          await this.actions.setSetting.dispatch(Config);
        },

        updateConfig: async (payload: {
          settings: Partial<Omit<RobotSettings, "robotId">>;
        }) => {
          const { settings } = payload;
          const config =
            await this.dependencies.serviceClient.robot.updateRobotConfig(
              this._id,
              settings
            );
          await this.actions.setSetting.dispatch(config);
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
