import { createModel, createSelector } from "nyax";
import { AppV2, AppV2CreationData, AppVersionV2 } from "src/models/appV2";
import { ModelBase } from "src/store/ModelBase";
import { AppHelperModel } from "src/store/models/entity/apps/helper";
import { AppListModel } from "src/store/models/entity/apps/list";
import { AppVersionEntityModel } from "src/store/models/entity/appVersion/entity";
import { AppVersionHelperModel } from "src/store/models/entity/appVersion/helper";

export type EnabledAppPayload = {
  appId: string;
  appVersionId: string;
  appVersion?: string;
  handleType?: "enable" | "switch";
  appName: string;
};

export const AppsUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        selectedAppIds: [] as string[],
        enabledAppPayload: null as EnabledAppPayload | null,
        releaseApp: null as AppV2 | null,
        appDraftVersion: null as AppVersionV2 | null,
      };
    }
    public reducers() {
      return {
        setSelectedAppIds: (selectedAppIds: string[]) => {
          this.state.selectedAppIds = selectedAppIds;
        },
        setEnabledAppPayload: (value: EnabledAppPayload | null) => {
          this.state.enabledAppPayload = value;
        },
        setReleaseApp: (value: AppV2 | null) => {
          this.state.releaseApp = value;
        },
        setAppDraftVersion: (value: AppVersionV2 | null) => {
          this.state.appDraftVersion = value;
        },
      };
    }
    public selectors() {
      return {
        enabledAppItem: createSelector(
          () => this.state.enabledAppPayload,
          () => this.getContainer(AppVersionEntityModel).state.byId,
          (enabledAppPayload, versionList) =>
            versionList[enabledAppPayload?.appVersionId ?? ""]
        ),
      };
    }

    public effects() {
      return {
        initializeRequest: async () => {
          await this.getContainer(AppListModel).actions.initial.dispatch(
            this.getContainer(AppListModel).state.query ?? {}
          );
        },
        copy: async (id: string) => {
          await this.getContainer(AppHelperModel).actions.copy.dispatch({
            id: id,
          });
          await this.actions.initializeRequest.dispatch({});
        },
        add: async (payload: AppV2CreationData) => {
          const app = await this.getContainer(
            AppHelperModel
          ).actions.create.dispatch(payload);
          await this.actions.initializeRequest.dispatch({});
          return app;
        },
        getDraftVersion: async (payload: {
          appId: string;
          draftVersionId: string;
        }) => {
          const { appId, draftVersionId } = payload;
          const appDraftVersin = await this.getContainer(
            AppVersionHelperModel
          ).actions.readDraftVersionById.dispatch({ appId, draftVersionId });
          await this.actions.setAppDraftVersion.dispatch(appDraftVersin);
        },
      };
    }
  }
);
