// eslint-disable-next-line import/no-anonymous-default-export
export default {
  job: {
    lastExecuteTime: "上次执行时间",
    terminate: "取消",
    execute: "执行",
    message: "选择流程数目为0",
    messagePriority: "可批量调整状态为等待的任务优先级！",
    messageExecute: "可批量执行状态为成功、失败和取消的任务！",
    messageTerminate: "可批量取消状态为等待、已分配和运行中的任务!",
    introduction:
      "执行记录页面展示当前资源组下所有流程任务的执行状态，支持查看流程执行详情及日志详情。",
    introductionExtra: "执行记录日志详情保留1年，截图与视频保留3个月。",
    logTip: "日志详情保留1年，截图与视频保留3个月（从本次执行创建时间起算）。",
    tips: `该流程包不支持展示执行进度，\n请使用编辑器1.1.2110.*以上版本发布。`,
    jobNumber: "任务编号",
    executableTarget: "执行目标",
    targetName: "目标名称",
    view: "查看详情",
    status: {
      queued: "等待中",
      allocated: "已分配",
      aborted: "已终止",
      running: "运行中",
      failed: "失败",
      succeeded: "成功",
      cancelled: "已取消",
      cancelling: "正在取消",
      pausing: "暂停中",
      paused: "已暂停",
      deleted: "已删除",
    },
    statusTip: {
      queued: "若任务没有可用的机器人资源，则任务状态处于“等待中”。",
      running:
        "情况1：若任务有可用的机器人资源，且正在执行流程，则任务状态处于“运行中”；\n情况2：若任务还有重试机会(如执行次序2或3)，但是此时机器人被占用，正在等待机器人时，则任务状态处于“运行中”。",
      paused:
        "若用户在控制台或机器人端对”运行中“的任务操作“暂停”，则任务状态处于“已暂停”。",
      cancelled:
        "一次流程任务的最终状态。\n情况1:用户在控制台端或机器人端，对“运行中/等待中/已暂停”的任务操作“取消”后，则任务状态处于“已取消”；\n情况2:根据设置的冲突处理策略，当机器人忙碌时，系统自动取消后续下发到此机器人的任务，则任务状态处于“已取消”。",
      failed:
        "一次流程任务的最终状态。一次任务，可能包含多次重试执行（如执行次序1、2、3），任务的最终状态基于最后一次重试执行结果。",
      succeeded:
        "一次流程任务的最终状态。一次任务，可能包含多次重试执行（如执行次序1、2、3），任务的最终状态基于最后一次重试执行结果。",
    },
    executeOrder: "任务优先级",
    deployAssignment: "流程部署赋值",
    executionAssignment: "流程执行赋值",
    adjustPriority: "调整优先级",
    priorityCount: "优先级值",
    defaultCount: "任务优先级默认2000",
    updatePriorityCountTip:
      "任务优先级最低为0，最高为5000，优先级越高任务将越优先执行",
    priorityValidateTips: "请输入0-5000之间的数字",
    lastRobotName: "最新执行机器人",
    tipAllTask: "任务",
    confirmDelete: "确认删除执行记录吗？",
    confirmCancel: "确认取消任务吗？",
    confirmCancelJobs: "确认取消/终止选中的 {jobsNumber} 个任务？",
    monitor: {
      title: "执行进度",
      log: "日志",
    },
    startupType: {
      queue: "任务队列",
      executeManually: "手动执行",
      trigger: "定时任务",
    },
    externalSystem: "外部系统",
    source: "来源",
    allStartMode: "全部启动方式",
    packageName: "流程包",
    packageVersion: "流程包版本",
    triggerName: "定时任务名称",
    vicodeApplication: "Vicode应用",
    ApiInterface: "API接口",
  },
};
