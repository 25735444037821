import {
  AuditlogServiceClient,
  DataPermissionServiceClient,
  HeaderMiddleware,
  HttpClient,
  PermissionServiceClient,
  Profile,
  RequestQueue,
  ResourceGroupServiceClient,
  RoleAssignmentServiceClient,
  RoleServiceClient,
  ServiceCredential,
  TenantServiceClient,
} from "@encoo-web/encoo-lib";
import { EncooOidcClient } from "@encoo-web/oidc-client";
import { History } from "history";
import developmentContainer from "src/dependencies/_development";
import productionContainer from "src/dependencies/_production";
import testContainer from "src/dependencies/_test";
import {
  CompanyHeaderMiddlewareSymbol,
  DepartmentHeaderMiddlewareSymbol,
  ErrorMiddlewareSymbol,
  HistorySymbol,
  HttpClientSymbol,
  ProfileSymbol,
  RequestQueueMiddlewareSymbol,
  ServiceCredentialsSymbol,
  TenantHeaderMiddlewareSymbol,
  UserManagerSettingsSymbol,
} from "src/dependencies/identifiers";
import ApplicationInsightsService from "src/services/ApplicationInsightsService";
import { ApplicationServiceClient } from "src/services/ApplicationService";
import { CalendarsService } from "src/services/CalendarsService";
import { CoeDataAnalysisPackageConfigService } from "src/services/CoeDataAnalysisPackageConfigService";
import { CoeDataAnalysisService } from "src/services/CoeDataAnalysisService";
import { CoeWorkService } from "src/services/CoeWorkService";
import { CompanyServiceClient } from "src/services/Company";
import { CompanyUserServiceClient } from "src/services/CompanyUserService";
import { ConversationService } from "src/services/ConversationService";
import { CustomPermissionRoleServiceClient } from "src/services/CustomPermissionRoleService";
import { DashboardServiceClient } from "src/services/DashboardService";
import { DataConnectionServiceClient } from "src/services/DataConnectionServiceClient";
import { DataPermissionsServiceClient } from "src/services/DataPermission";
import { DataPermissionServiceClientV2 } from "src/services/DataPermissionServiceV2";
import { DataQueueServiceClient } from "src/services/DataQueueService";
import { DataSourceServiceClient } from "src/services/DataSource";
import { DataTableService } from "src/services/DataTableService";
import { DepartmentServiceClient } from "src/services/DepartmentService";
import { DepartmentUserServiceClient } from "src/services/DepartmentUserService";
import { DeviceServiceClient } from "src/services/DeviceService";
import { ExpiryPromptService } from "src/services/ExpiryPromptService";
import { FeatureServiceClient } from "src/services/FeatureService";
import { FileServiceClient } from "src/services/FileServiceClient";
import { InstallationServiceClient } from "src/services/InstallationService";
import { JobQueueServiceClient } from "src/services/JobQueueService";
import { JobServiceClient } from "src/services/JobService";
import { KnowledgeServiceClient } from "src/services/KnowledgeService";
import { LamossConfigServiceClient } from "src/services/LamossConfigService";
import { LocaleService } from "src/services/LocaleService";
import { OCRService } from "src/services/OCRService";
import { PackageServiceClient } from "src/services/PackageService";
import { PaymentOrderClient } from "src/services/PaymentOrderService";
import { PaymentClient } from "src/services/PaymentService";
import { QueueServiceClient } from "src/services/QueueService";
import { QuotaServiceClient } from "src/services/QuotaService";
import { ReduxService } from "src/services/ReduxService";
import { RobotServiceClient } from "src/services/RobotService";
import { RoleManagerServiceClient } from "src/services/RoleManagerService";
import { RpaLicenseServiceServiceClient } from "src/services/RpaLicenseService";
import { RpaConfigServiceClient } from "src/services/RpaPlanConfigService";
import { RuninstanceServiceClient } from "src/services/RuninstanceService";
import { SignalRConnection } from "src/services/SignalRConnection";
import { SystemSettingsServiceClient } from "src/services/SystemSettings";
import { ThemeService } from "src/services/ThemeService";
import { ThirdIntegrationServiceClient } from "src/services/ThirdIntegrationService";
import { UnderstandingSeviceClient } from "src/services/UnderstandingSeviceClient";
import { UserManagerServiceClient } from "src/services/UserManagerService";
import { UserServiceClient } from "src/services/UserService";
import { ViCodeAppServiceClient } from "src/services/ViCodeAppService";
import { WorkflowLayoutService } from "src/services/WorkflowLayoutService";
import { WorkflowServiceClient } from "src/services/WorkflowService";
import { AppTemplateServiceClient } from "src/services/appTemplateService";
import { AppsV2ServiceClient } from "src/services/appsV2Service";
import { AssetV2ServiceClient } from "src/services/assetV2Service";
import { AuditlogSearchServiceClient } from "src/services/auditlogSearchService";
import { EnvironmentServiceClient } from "src/services/environmentService";
import { LicenseBindingsServiceClient } from "src/services/licenseService";
import { ErrorMiddleware } from "src/store/models/ui/error/error";

const container =
  process.env.NODE_ENV === "production"
    ? productionContainer
    : process.env.NODE_ENV === "test"
    ? testContainer
    : developmentContainer;

export interface Dependencies {
  userManagerSettings: Oidc.UserManagerSettings;
  history: History;
  locale: LocaleService;
  http: HttpClient;
  redux: ReduxService;
  theme: ThemeService;
  oidcClient: EncooOidcClient;
  profile: Profile;
  applicationInsights: ApplicationInsightsService;
  serviceCredential: ServiceCredential;

  httpMiddleware: {
    tenantHeader: HeaderMiddleware;
    companyHeader: HeaderMiddleware;
    departmentHeader: HeaderMiddleware;
    requestQueue: RequestQueue;
    errorHandle: ErrorMiddleware;
  };

  serviceClient: {
    application: ApplicationServiceClient;
    knowledge: KnowledgeServiceClient;
    expiryPrompt: ExpiryPromptService;
    coeWork: CoeWorkService;
    coeDataAnalysisPackageConfig: CoeDataAnalysisPackageConfigService;
    coeDataAnalysis: CoeDataAnalysisService;
    calendars: CalendarsService;
    dataTable: DataTableService;
    workflowLayout: WorkflowLayoutService;
    ocr: OCRService;
    conversation: ConversationService;
    auditlog: AuditlogServiceClient;
    auditlogSearch: AuditlogSearchServiceClient;
    user: UserServiceClient;
    resourceGroup: ResourceGroupServiceClient;
    package: PackageServiceClient;
    workflow: WorkflowServiceClient;
    robot: RobotServiceClient;
    device: DeviceServiceClient;
    queue: QueueServiceClient;
    dataQueue: DataQueueServiceClient;
    jobQueue: JobQueueServiceClient;
    role: RoleServiceClient;
    roleAssignment: RoleAssignmentServiceClient;
    appsV2: AppsV2ServiceClient;
    vicodeApp: ViCodeAppServiceClient;
    permission: PermissionServiceClient;
    licenseBindings: LicenseBindingsServiceClient;
    rpaLicense: RpaLicenseServiceServiceClient;
    rpaConfig: RpaConfigServiceClient;
    runInstance: RuninstanceServiceClient;
    job: JobServiceClient;
    understanding: UnderstandingSeviceClient;
    installation: InstallationServiceClient;
    dashboard: DashboardServiceClient;
    environment: EnvironmentServiceClient;
    assetV2: AssetV2ServiceClient;
    tenant: TenantServiceClient;
    file: FileServiceClient;
    dataPermission: DataPermissionServiceClient;
    company: CompanyServiceClient;
    department: DepartmentServiceClient;
    feature: FeatureServiceClient;
    dataPermissions: DataPermissionsServiceClient;
    departmentUser: DepartmentUserServiceClient;
    companyUser: CompanyUserServiceClient;
    systemSettings: SystemSettingsServiceClient;
    dataConnection: DataConnectionServiceClient;
    thirdIntegration: ThirdIntegrationServiceClient;
    dataSource: DataSourceServiceClient;
    roleManager: RoleManagerServiceClient;
    customPermissionRoleServiceClient: CustomPermissionRoleServiceClient;
    userManagerServiceClient: UserManagerServiceClient;
    DataPermissionServiceClientV2: DataPermissionServiceClientV2;
    appTemplate: AppTemplateServiceClient;
    lamossConfig: LamossConfigServiceClient;
    quota: QuotaServiceClient;
    payment: PaymentClient;
    paymentOrder: PaymentOrderClient;
  };
  signalR: {
    connection: SignalRConnection;
  };
}

const dependencies: Dependencies = {
  get userManagerSettings() {
    return container.get<Oidc.UserManagerSettings>(UserManagerSettingsSymbol);
  },

  get http() {
    return container.get<HttpClient>(HttpClientSymbol);
  },

  get history() {
    return container.get<History>(HistorySymbol);
  },

  get locale() {
    return container.get(LocaleService);
  },
  get redux() {
    return container.get(ReduxService);
  },
  get theme() {
    return container.get(ThemeService);
  },

  get oidcClient() {
    return container.get(EncooOidcClient);
  },

  get profile() {
    return container.get<Profile>(ProfileSymbol);
  },

  get applicationInsights() {
    return container.get(ApplicationInsightsService);
  },

  get serviceCredential() {
    return container.get<ServiceCredential>(ServiceCredentialsSymbol);
  },

  httpMiddleware: {
    get tenantHeader() {
      return container.get<HeaderMiddleware>(TenantHeaderMiddlewareSymbol);
    },
    get companyHeader() {
      return container.get<HeaderMiddleware>(CompanyHeaderMiddlewareSymbol);
    },
    get departmentHeader() {
      return container.get<HeaderMiddleware>(DepartmentHeaderMiddlewareSymbol);
    },
    get requestQueue() {
      return container.get<RequestQueue>(RequestQueueMiddlewareSymbol);
    },
    get errorHandle() {
      return container.get<ErrorMiddleware>(ErrorMiddlewareSymbol);
    },
  },

  serviceClient: {
    get application() {
      return container.get(ApplicationServiceClient);
    },
    get knowledge() {
      return container.get(KnowledgeServiceClient);
    },
    get expiryPrompt() {
      return container.get(ExpiryPromptService);
    },
    get coeWork() {
      return container.get(CoeWorkService);
    },
    get coeDataAnalysisPackageConfig() {
      return container.get(CoeDataAnalysisPackageConfigService);
    },
    get coeDataAnalysis() {
      return container.get(CoeDataAnalysisService);
    },
    get conversation() {
      return container.get(ConversationService);
    },
    get calendars() {
      return container.get(CalendarsService);
    },
    get dataTable() {
      return container.get(DataTableService);
    },
    get workflowLayout() {
      return container.get(WorkflowLayoutService);
    },
    get ocr() {
      return container.get(OCRService);
    },
    get auditlog() {
      return container.get(AuditlogServiceClient);
    },
    get auditlogSearch() {
      return container.get(AuditlogSearchServiceClient);
    },
    get resourceGroup() {
      return container.get(ResourceGroupServiceClient);
    },
    get package() {
      return container.get(PackageServiceClient);
    },
    get workflow() {
      return container.get(WorkflowServiceClient);
    },
    get robot() {
      return container.get(RobotServiceClient);
    },
    get device() {
      return container.get(DeviceServiceClient);
    },
    get user() {
      return container.get(UserServiceClient);
    },
    get queue() {
      return container.get(QueueServiceClient);
    },
    get dataQueue() {
      return container.get(DataQueueServiceClient);
    },
    get jobQueue() {
      return container.get(JobQueueServiceClient);
    },
    get role() {
      return container.get(RoleServiceClient);
    },
    get roleAssignment() {
      return container.get(RoleAssignmentServiceClient);
    },

    get appsV2() {
      return container.get(AppsV2ServiceClient);
    },

    get vicodeApp() {
      return container.get(ViCodeAppServiceClient);
    },

    get permission() {
      return container.get(PermissionServiceClient);
    },
    get licenseBindings() {
      return container.get(LicenseBindingsServiceClient);
    },
    get rpaLicense() {
      return container.get(RpaLicenseServiceServiceClient);
    },
    get rpaConfig() {
      return container.get(RpaConfigServiceClient);
    },
    get runInstance() {
      return container.get(RuninstanceServiceClient);
    },
    get job() {
      return container.get(JobServiceClient);
    },
    get understanding() {
      return container.get(UnderstandingSeviceClient);
    },
    get installation() {
      return container.get(InstallationServiceClient);
    },
    get dashboard() {
      return container.get(DashboardServiceClient);
    },
    get environment() {
      return container.get(EnvironmentServiceClient);
    },
    get assetV2() {
      return container.get(AssetV2ServiceClient);
    },
    get tenant() {
      return container.get(TenantServiceClient);
    },
    get file() {
      return container.get(FileServiceClient);
    },
    get dataPermission() {
      return container.get(DataPermissionServiceClient);
    },

    get company() {
      return container.get(CompanyServiceClient);
    },
    get department() {
      return container.get(DepartmentServiceClient);
    },
    get feature() {
      return container.get(FeatureServiceClient);
    },
    get dataPermissions() {
      return container.get(DataPermissionsServiceClient);
    },
    get departmentUser() {
      return container.get(DepartmentUserServiceClient);
    },
    get companyUser() {
      return container.get(CompanyUserServiceClient);
    },
    get systemSettings() {
      return container.get(SystemSettingsServiceClient);
    },
    get dataConnection() {
      return container.get(DataConnectionServiceClient);
    },
    get thirdIntegration() {
      return container.get(ThirdIntegrationServiceClient);
    },
    get dataSource() {
      return container.get(DataSourceServiceClient);
    },
    get roleManager() {
      return container.get(RoleManagerServiceClient);
    },
    get userManagerServiceClient() {
      return container.get(UserManagerServiceClient);
    },
    get customPermissionRoleServiceClient() {
      return container.get(CustomPermissionRoleServiceClient);
    },
    get DataPermissionServiceClientV2() {
      return container.get(DataPermissionServiceClientV2);
    },
    get appTemplate() {
      return container.get(AppTemplateServiceClient);
    },
    get lamossConfig() {
      return container.get(LamossConfigServiceClient);
    },
    get quota() {
      return container.get(QuotaServiceClient);
    },
    get payment() {
      return container.get(PaymentClient);
    },
    get paymentOrder() {
      return container.get(PaymentOrderClient);
    },
  },
  signalR: {
    get connection() {
      return container.get(SignalRConnection);
    },
  },
};

export function getDependencies(): Dependencies {
  return dependencies;
}
