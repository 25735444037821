/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Spin } from "antd";
import React, { useCallback, useEffect } from "react";
import { hot } from "react-hot-loader/root";
import { useSelector } from "react-redux";
import AppLayout from "src/containers/AppLayout";
import TransferToActivateUser from "src/containers/transferToActivateUser/TransferToActivateUser";
import { useFormatMessage, useGetContainer } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { AppModel } from "src/store/models/app";
import { RouterModel } from "src/store/models/router";

// todo 移除前端键盘监听
// const expireTime = 30;

interface EncooWindow extends Window {
  ActiveXObject: unknown;
}

declare const window: EncooWindow;

export default hot(
  React.memo(function App() {
    const formatMessage = useFormatMessage();
    const getContainer = useGetContainer();
    const app = getContainer(AppModel);
    const router = getContainer(RouterModel);

    const currentRouteInfo = useSelector(() => router.getters.currentRouteInfo);

    const isInitialized = useSelector(() => app.state.isInitialized);

    const initIePolyfill = useCallback(() => {
      (function (arr) {
        arr.forEach(function (item) {
          // eslint-disable-next-line no-prototype-builtins
          if (item.hasOwnProperty("remove")) {
            return;
          }
          Object.defineProperty(item, "remove", {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function remove() {
              this.parentNode.removeChild(this);
            },
          });
        });
      })([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
    }, []);

    useEffect(() => {
      if (!!window?.ActiveXObject || "ActiveXObject" in window) {
        initIePolyfill();
      }
    }, [initIePolyfill]);

    useEffect(() => {
      const title =
        window._settings.customizedCustomer === "custom"
          ? formatMessage(messageIds.customizedCustomer.custom.title)
          : formatMessage(messageIds.app.name);
      document.title = title;
    }, [formatMessage]);

    const initHandle = useCallback(async () => {
      await app.actions.initializeRequest.dispatch({});
    }, [app.actions.initializeRequest]);

    useEffect(() => {
      initHandle();
    }, [initHandle]);

    if (currentRouteInfo.type === "transferToActivateUser") {
      return <TransferToActivateUser />;
    }

    return isInitialized ? (
      <AppLayout />
    ) : (
      <Spin
        css={css`
          margin: auto;
        `}
      />
    );
  })
);
