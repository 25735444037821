// eslint-disable-next-line import/no-anonymous-default-export
export default {
  dataQueue: {
    introduction:
      "数据队列是一项可存储大量消息的服务，通常用于可积压可异步处理的工作场景。",
    message: "消息",
    messageText: "消息文本",
    placeholderText: "请输入消息文本",
    tip: "提示：消息的生存时间不能小于1秒且不超过7天。",
    default: "默认 ( 7天 )",
    messageRecord: "消息记录",
    status: "状态",
    insertionTime: "插入时间",
    expirationTime: "到期时间",
    survivalTime: "生存时间",
    messageTip: "显示队列头的前32条消息",
    queue: "队列",
    name: "队列名称",
  },
};
