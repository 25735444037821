/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Dropdown, Menu } from "antd";
import { MenuInfo } from "rc-menu/lib/interface";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import IconExpanded from "src/assets/icon_expanded.svg";
import Icon from "src/components/Icon";
import { useGetContainer } from "src/hooks";
import { supportedLocales } from "src/services/LocaleService";
import { LocaleUIModel } from "src/store/models/ui/locale/locale";
import { getLocale, LocaleLang } from "src/utils/locale";

export default React.memo(function LocaleComponnet() {
  const getContainer = useGetContainer();
  const localModel = getContainer(LocaleUIModel);
  const selectedLocale = useSelector(() => localModel.state.selectedLocal);

  const handleClick = useCallback(
    (params: MenuInfo) => {
      if (params.key !== selectedLocale) {
        localModel.actions.setSelectedLocale.dispatch(params.key as LocaleLang);
      }
    },
    [localModel.actions.setSelectedLocale, selectedLocale]
  );

  const menu = React.useMemo(
    () => (
      <Menu
        onClick={handleClick}
        css={css`
          top: -4px;
          width: 140px;
        `}
      >
        {supportedLocales &&
          supportedLocales.map((locale) => (
            <Menu.Item key={locale.config.locale}>{locale.title}</Menu.Item>
          ))}
      </Menu>
    ),
    [handleClick]
  );

  const [isMenuOpening, setIsMenuOpening] = useState(false);

  const onVisibleChange = useCallback((visible: boolean) => {
    setIsMenuOpening(visible);
  }, []);

  return (
    <Dropdown
      trigger={["click"]}
      overlay={menu}
      css={css`
        background-color: ${isMenuOpening ? "rgba(0,0,0,0.09)" : "none"};
        height: 100%;
      `}
      onVisibleChange={onVisibleChange}
    >
      <div
        css={css`
          padding-left: 15px;
          padding-right: 9px;
          display: flex;
          align-items: center;
          color: #ffffff;
          cursor: pointer;
        `}
      >
        <Icon
          name="icon-translate"
          css={css`
            margin-right: 4px;
            font-size: 14px;
            line-height: 32px;
          `}
        />
        <span
          css={css`
            font-size: 12px;
          `}
        >
          {supportedLocales.find((l) => l.config.locale === getLocale())?.title}
        </span>
        <img
          src={IconExpanded}
          alt="icon-expanded"
          css={css`
            margin-left: 4px;
            transform: rotate(${isMenuOpening ? "180deg" : "0deg"});
          `}
        />
      </div>
    </Dropdown>
  );
});
