import { createModel } from "nyax";
import {
  CoeDataAnalysis,
  CoeDataAnalysisQuery,
} from "src/models/coeDataAnalysis";
import { CoeDataAnalysisEntityModel } from "src/store/models/entity/coeDataAnalysis/entity";
import { createUITableWithPermissionModel } from "src/store/models/ui/_shared";

export type CoeDataAnalysisWithPermission = CoeDataAnalysis & {
  permissionValues: string[];
};

export const CoeDataAnalysisTableUIModel = createModel(
  class extends createUITableWithPermissionModel<
    CoeDataAnalysis,
    CoeDataAnalysisQuery
  >({
    setItems: (getContainer, items) =>
      getContainer(CoeDataAnalysisEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(CoeDataAnalysisEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(CoeDataAnalysisEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      const superEffects = super.effects();
      return {
        ...superEffects,
        updateQuery: async (params: CoeDataAnalysisQuery) => {
          await superEffects.updateQuery(params);
        },
        initial: async (payload: { departmentId: string }) => {
          const { departmentId } = payload;
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query: CoeDataAnalysisQuery
            ) =>
              this.dependencies.serviceClient.coeDataAnalysis.list(
                departmentId,
                pageIndex,
                pageSize,
                query
              ),
          });
        },
      };
    }
  }
);
