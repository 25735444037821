import { createModel } from "nyax";
import { AiServiceType } from "src/models/ocr";
import { MenuExpandedDefinition } from "src/routes/_shared";
import { ModelBase } from "src/store/ModelBase";

export const OCRTableUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        dataSource: [] as AiServiceType[],
        activityItem: {} as MenuExpandedDefinition,
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setDataSource: (value: AiServiceType[]) => {
          this.state.dataSource = value;
        },
        setActivityItem: (value: MenuExpandedDefinition) => {
          this.state.activityItem = value;
        },
      };
    }
    public effects() {
      return {
        getServiceTypes: async (payload: {
          aiCategory: string;
          name?: string;
        }) => {
          const { aiCategory, name } = payload;
          const data =
            await this.dependencies.serviceClient.ocr.getServiceTypes(
              aiCategory,
              name
            );
          this.actions.setDataSource.dispatch(data);
        },
      };
    }
    public selectors() {
      return {};
    }
  }
);
