// eslint-disable-next-line import/no-anonymous-default-export
export default {
  dashboard: {
    title: {
      package: "Flow Package Count",
      workflow: "Flow Deployment Count",
      queue: "Robot Group Count",
      robot: "Robot Count",
      task: "Execution History Overview",
      taskDate: "Task Count / Date",
      taskInfo: "Task Details",
    },
    viewDetail: "View Details",
    table: {
      status: "Status",
      percent: "Percentage",
      count: "Count",
      wait: "Waiting",
      allocated: "Assigned",
      paused: "Paused",
      operation: "Running",
      success: "Succeeded",
      cancelling: "Canceling",
      cancelled: "Cancel",
      fail: "Failed",
    },
    totalCount: "Total",
    sevenDay: "Last 7 days",
    thirtyDay: "Last 30 days",
    today: "Today",
    exeTotalCount: "Execution Count",
    exeTotalTime: "Total Task Execution Time",
    timeRange: {
      year: "Year",
      month: "Month",
      day: "Day",
      notLimit: "Not Limit",
      limitTip: "Query time range should less than {limit}(s)",
    },
  },
  robotRunStatistics: {
    dailyRobot: {
      runInstanceExecutionCount: "Total Task Execution Count",
      successExecutionCount: "Total Succeeded Task Count",
      robotCount: "Total Robot Count",
      robotAliveTime: "Total Robot Time",
      robotBusyTime: "Total Busy Time",
      averageBusyProportion: "Average Busy Ratio",
      averageSuccessProportion: "Average Success rate",
    },
    perRobot: {
      runInstanceExecutionCount: "Task Execution Count",
      successExecutionCount: "Succeeded Task Count",
      busyMilliseconds: "Busy Time",
      aliveMilliseconds: "Robot Time",
      busyProportion: "Robot Busy Ratio",
      successProportion: "Success Rate",
    },
  },
  userFlowStatistics: {
    dailyUserFlow: {
      uploadedPackageCount: "Total Uploaded Flow Package Count",
      deployedPackageCount: "Total Deployed Flow Package Count",
      createdWorkflowCount: "Total Flow Deployment Creation Count",
      createdCronTriggerCount: "Total Task Schedule Creation Count",
      createdJobCount: "Total Task Count",
      jobSuccessCount: "Total Succeeded Task Count",
      totalJobExecutionMilliseconds: "Total Task Execution Time",
    },
    perUserFlow: {
      userName: "User Name",
      uploadedPackageCount: "Uploaded Flow Package Count",
      createdWorkflowCount: "Flow Deployment Creation Count",
      createdCronTriggerCount: "Task Schedule Creation Count",
      createdJobCount: "Task Creation Count",
      jobSuccessCount: "Succeeded Task Count",
      totalJobExecutionMilliseconds: "Task Execution Time",
    },
  },
  robotStatusStatistics: {
    averageBusyRatioOfAvailableRobots: "Average Robot Busy Ratio",
    averageBusyRatio: "Average Busy Ratio",
    todayAverageBusyRatio: "Today Average Busy Ratio",
    todayBusyRatio: "Today Busy Ratio",
    robotUsegeRate: "Robot Busy Ratio",
    usegeRate: "Usege Rate",
    todayUsegeRate: "Today Usege Rate",
    robotBusyTotalTimeTOP10: "Total Robot Task Time TOP10",
    todayAdded: "Today New",
    robotBusyTotalTime: "Total Robot Task Execution Time",
    busyTotalTime: "Total Busy Time",
    robotSuccessRateTOP10: "Robot Success Rate TOP10",
    robotFailRateTOP10: "Robot Failure Rate TOP10",
    robot: "Robot",
    licenseType: { serverLicensed: "licensed", unlicensed: "unlicensed" },
    availableRobotStateDistribution: "Real Time Robot Status Distribution",
    robotTotalCount: "Total Robot Count",
    serverLicensedCount: "Licensed Count",
    unlicensedCount: "Unlicensed Count",
    availableRobotTotalExeCount: "Robot Task Execution Count",
    viewRobotDetail: "View Robot Details",
    exeWorkflowTotalTime: "Total Flow Execution Time",
    faultNumber: "Failure(s)",
    successNumber: "Success(es)",
    robotJobState: "Robot Task Distribution",
  },
  queueStatistics: {
    averageBusyRate: "Average Busy Ratio",
    todayAverageBusyRate: "Today Busy Ratio",
    todayAdded: "Today New",
    busyTotalTime: "Total Busy Time",
    faultNumber: "Failure(s)",
    successNumber: "Success(es)",
    queueDetail: "Robot Group Details",
    queueExeTotalTime: "Total Robot Group Execution Time",
    queueName: "Robot Group Name",
    queueBusyTop10: "Total Robot Group Busy Time TOP10",
    queueCount: "Robot Group Count",
    queueTotalCount: "Total Robot Group Count",
    queueFailTop10: "Robot Group Failure Rate TOP10",
    queueJobState: "Robot Group Task Distribution",
    queueJobCount: "Total Robot Group Task Execution Count",
    aveWaitTime: "Average Waiting Time",
    busyRate: "Busy Ratio",
    robotBindedCount: "Bound Robot Count",
    robotBindedTotalCount: "Bound Robot Count",
    queueJobWaitTop10: "Average Robot Group Task Waiting Time TOP10",
    queueRobotAveBusyRate: "Robot Group Busy Ratio",
    queueSuccessRate: "Robot Group Success Rate TOP10",
    associatedWorkflowCount: "Associated Flow Deployment Count",
    associatedWorkflowTotalCount: "Total Associated Flow Deployment Count",
    exeWorkflowTotalTime: "Total Flow Execution Time",
  },
};
