import { createModel } from "nyax";
import {
  PaymentAppNameEnum,
  PaymentCategoryEnum,
  PaymentProduct,
} from "src/models/payment";
import { createHelperModel } from "src/store/models/entity/_shared";
import { PaymentProductEntityModel } from "src/store/models/entity/payment/product/entity";

export const PaymentProductHelperModel = createModel(
  class extends createHelperModel<PaymentProduct>({
    setItems: (getContainer, items) =>
      getContainer(PaymentProductEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(PaymentProductEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(PaymentProductEntityModel).state.byId[id],
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(PaymentProductEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        getAll: async () => {
          return await this._readAll({
            getAllAction: async () => {
              const robotProducts =
                await this.dependencies.serviceClient.payment.products({
                  appName: PaymentAppNameEnum["Console"],
                  category: PaymentCategoryEnum.Persional,
                });
              const aiProducts =
                await this.dependencies.serviceClient.payment.products({
                  appName: PaymentAppNameEnum["AIPlatform"],
                  category: PaymentCategoryEnum.OCR,
                });
              const vicodeProducts =
                await this.dependencies.serviceClient.payment.products({
                  appName: PaymentAppNameEnum["ViCode"],
                  category: PaymentCategoryEnum.ViCode,
                });
              return [robotProducts, aiProducts, vicodeProducts].flat();
            },
          });
        },
      };
    }
  }
);
