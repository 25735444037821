import { createModel } from "nyax";
import { Queue, QueueQuery } from "src/models/queue";
import { QueueEntityModel } from "src/store/models/entity/queue/entity";
import { QueueSideUIModel } from "src/store/models/ui/queue/queueSide";
import { createUITableWithPermissionModel } from "src/store/models/ui/_shared";

export const QueueTableUIModel = createModel(
  class extends createUITableWithPermissionModel<Queue, QueueQuery>({
    setItems: (getContainer, items) =>
      getContainer(QueueEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(QueueEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(QueueEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      const superEffects = super.effects();
      return {
        ...superEffects,
        updateQuery: async (params: QueueQuery) => {
          await superEffects.updateQuery(params);
          await this.getContainer(QueueSideUIModel).actions.setQuery.dispatch(
            this.state.query
          );
        },
        initial: async () => {
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query: QueueQuery
            ) =>
              this.dependencies.serviceClient.queue.list(
                pageIndex,
                pageSize,
                query
              ),
          });
        },
      };
    }
  }
);
