import { AssetUIModel } from "src/store/models/ui/asset/asset";
import { AssetDetailModel } from "src/store/models/ui/asset/assetDetail";
import { AssetSideUIModel } from "src/store/models/ui/asset/assetSide";
import { AssetTableUIModel } from "src/store/models/ui/asset/assetTable";

export const encooAssetUIModels = {
  Asset: AssetUIModel,
  AssetTable: AssetTableUIModel,
  AssetSide: AssetSideUIModel,
  AssetDetail: AssetDetailModel,
};
