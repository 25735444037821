import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";

import {
  CoeDataAnalysisBasicConfig,
  CoeDataAnalysisPackageConfig,
  CoeDataAnalysisPackageConfigEncooList,
  CoeDataAnalysisPackageConfigQuery,
} from "src/models/coeDataAnalysisPackageConfig";
import { EncooBatchResult, EncooListEntity } from "src/models/_shared";

export const COEDATAANALYSISPACKAGECONFIG_SERVICE_NAME =
  "coeDataAnalysisPackageConfig";
export class CoeDataAnalysisPackageConfigService extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(
      httpClient,
      credential,
      profile,
      COEDATAANALYSISPACKAGECONFIG_SERVICE_NAME
    );
  }

  async list(
    departmentId: string,
    pageIndex: number,
    pageSize: number,
    query?: CoeDataAnalysisPackageConfigQuery
  ): Promise<EncooListEntity<CoeDataAnalysisPackageConfigEncooList>> {
    const req = this.buildRequestV2({
      url: `/v2/ROIAnalyz/PackageConfig`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
      },
      departmentId,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getBasicConfig(): Promise<CoeDataAnalysisBasicConfig> {
    const req = this.buildRequestV2({
      url: `/v2/ROIAnalyz/BasicConfig`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async updateBasicConfig(payload: CoeDataAnalysisBasicConfig): Promise<void> {
    const req = this.buildRequestV2({
      url: `/v2/ROIAnalyz/BasicConfig`,
      method: "POST",
      payload,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async batchUpdateWorkflowCost(
    payload: CoeDataAnalysisPackageConfig[]
  ): Promise<EncooBatchResult> {
    const req = this.buildRequestV2({
      url: `/v2/ROIAnalyz/PackageConfig/Batch/Update`,
      method: "POST",
      payload,
    });
    return await super.updateInternal<EncooBatchResult>(req);
  }
}
