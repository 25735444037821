import iconAiPlatform from "src/assets/menu/icon_aiPlatform.svg";
import iconApps from "src/assets/menu/icon_apps.svg";
import iconDashboard from "src/assets/menu/icon_dashboard.svg";
import iconData from "src/assets/menu/icon_data.svg";
import iconHome from "src/assets/menu/icon_home.svg";
import iconLAMOSS from "src/assets/menu/icon_LAMOSS.svg";
import iconRpa from "src/assets/menu/icon_rpa.svg";
import iconSettings from "src/assets/menu/icon_settings.svg";
import messageIds from "src/locales/messageIds";
import {
  AppRouteType,
  createMenuDefinition,
  MenuDefinition,
  MenuExpandedDefinition,
} from "src/routes/_shared";

export const appMenuDefinitions = [
  createMenuDefinition({
    name: messageIds.common.home,
    icon: iconHome,
    routeType: "index",
  }),

  createMenuDefinition({
    name: messageIds.menu.dashboard.name,
    icon: iconDashboard,
    children: [
      createMenuDefinition({
        name: messageIds.menu.dashboard.overallMonitor.name,
        routeType: "dashboard",
      }),
      createMenuDefinition({
        name: messageIds.menu.dashboard.robotStatusStatistics.name,
        routeType: "robotStatusStatistics",
      }),
      createMenuDefinition({
        name: messageIds.menu.dashboard.queueStatistics.name,
        routeType: "queueStatistics",
      }),
      createMenuDefinition({
        name: messageIds.menu.dashboard.robotRunStatistics.name,
        routeType: "robotRunStatistics",
      }),
      createMenuDefinition({
        name: messageIds.menu.dashboard.userFlowStatistics.name,
        routeType: "userFlowStatistics",
      }),
    ],
  }),

  createMenuDefinition({
    name: messageIds.menu.coe.name,
    icon: iconApps,
    children: [
      createMenuDefinition({
        routeType: "coeMyWork",
        name: messageIds.menu.coe.myWork.name,
        description: messageIds.coeMyWork.introduction,
        children: [
          createMenuDefinition({
            routeType: "coeMyWorkDetail",
            name: messageIds.menu.coe.myWork.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
        ],
      }),
      createMenuDefinition({
        routeType: "coeToDoWork",
        name: messageIds.menu.coe.toDoWork.name,
        description: messageIds.coeToDoWork.introduction,
        children: [
          createMenuDefinition({
            routeType: "coeToDoWorkDetail",
            name: messageIds.menu.coe.toDoWork.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
        ],
      }),
      createMenuDefinition({
        routeType: "coeDataAnalysis",
        name: messageIds.menu.coe.dataAnalysis.name,
      }),
    ],
  }),

  // createMenuDefinition({
  //   name: messageIds.menu.coe.name,
  //   icon: iconApps,
  //   children: [
  //     createMenuDefinition({
  //       routeType: "coeInitiated",
  //       name: messageIds.menu.coe.initiated.name,
  //       description: messageIds.coeInitiated.introduction,
  //       children: [
  //         createMenuDefinition({
  //           routeType: "coeInitiatedDetail",
  //           name: messageIds.menu.coe.initiated.name,
  //           hiddenInMenu: true,
  //           hiddenInBreadcrumb: true,
  //         }),
  //       ],
  //     }),
  //     createMenuDefinition({
  //       routeType: "coePending",
  //       name: messageIds.menu.coe.pending.name,
  //       description: messageIds.coePending.introduction,
  //       children: [
  //         createMenuDefinition({
  //           routeType: "coePendingDetail",
  //           name: messageIds.menu.coe.pending.name,
  //           hiddenInMenu: true,
  //           hiddenInBreadcrumb: true,
  //         }),
  //       ],
  //     }),
  //     createMenuDefinition({
  //       routeType: "coeDataAnalysis",
  //       name: messageIds.menu.coe.dataAnalysis.name,
  //     }),
  //   ],
  // }),

  createMenuDefinition({
    name: messageIds.menu.rpa.name,
    icon: iconRpa,
    children: [
      createMenuDefinition({
        routeType: "package",
        name: messageIds.menu.rpa.package.name,
        description: messageIds.package.introduction,
        children: [
          createMenuDefinition({
            routeType: "packageDetail",
            name: messageIds.menu.rpa.package.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
        ],
      }),
      createMenuDefinition({
        routeType: "robot",
        name: messageIds.menu.rpa.robot.name,
        description: messageIds.robot.introduction,
        children: [
          createMenuDefinition({
            routeType: "robotDetail",
            name: messageIds.menu.rpa.queue.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
        ],
      }),
      createMenuDefinition({
        routeType: "queue",
        name: messageIds.menu.rpa.queue.name,
        description: messageIds.queue.introduction,
        children: [
          createMenuDefinition({
            routeType: "queueDetail",
            name: messageIds.menu.rpa.queue.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
        ],
      }),
      createMenuDefinition({
        routeType: "workflow",
        name: messageIds.menu.rpa.workflow.name,
        description: messageIds.workflow.introduction,
        children: [
          createMenuDefinition({
            routeType: "workflowDetail",
            name: messageIds.menu.rpa.workflow.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
          createMenuDefinition({
            routeType: "triggerDetail",
            name: messageIds.menu.rpa.workflow.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
        ],
      }),
      createMenuDefinition({
        routeType: "workflowLayout",
        name: messageIds.menu.rpa.workflowLayout.name,
        description: messageIds.workflowLayout.introduction,
        children: [
          createMenuDefinition({
            routeType: "workflowLayoutNodesEditor",
            name: messageIds.menu.rpa.workflowLayout.editor.name,
            hiddenInMenu: true,
          }),
          createMenuDefinition({
            routeType: "workflowLayoutDetail",
            name: messageIds.menu.rpa.workflowLayout.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
          createMenuDefinition({
            routeType: "workflowLayoutTriggerDetail",
            name: messageIds.menu.rpa.workflowLayout.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
        ],
      }),
      createMenuDefinition({
        routeType: "jobQueue",
        name: messageIds.menu.rpa.jobQueue.name,
        description: messageIds.jobQueue.introduction,
        children: [
          createMenuDefinition({
            routeType: "jobQueueDetail",
            name: messageIds.menu.rpa.jobQueue.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
        ],
      }),
      createMenuDefinition({
        routeType: "job",
        name: messageIds.menu.rpa.log.name,
        description: messageIds.job.introduction,
        children: [
          createMenuDefinition({
            routeType: "jobDetail",
            name: messageIds.menu.rpa.log.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
          createMenuDefinition({
            routeType: "runInstanceLog",
            name: messageIds.menu.rpa.log.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
          createMenuDefinition({
            routeType: "runningMonitor",
            name: messageIds.menu.rpa.log.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
        ],
      }),
      createMenuDefinition({
        routeType: "device",
        name: messageIds.menu.rpa.device.name,
        description: messageIds.device.introduction,
        children: [
          createMenuDefinition({
            routeType: "deviceDetail",
            name: messageIds.menu.rpa.device.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
        ],
      }),
      createMenuDefinition({
        routeType: "calendars",
        name: messageIds.menu.rpa.calendars.name,
        description: messageIds.calendars.introduction,
        children: [
          createMenuDefinition({
            routeType: "calendarsDetail",
            name: messageIds.menu.rpa.calendars.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
        ],
      }),
    ],
  }),

  createMenuDefinition({
    name: messageIds.menu.apps.name,
    icon: iconApps,
    children: [
      createMenuDefinition({
        routeType: "app",
        name: messageIds.menu.apps.app.name,
        description: messageIds.apps.introduction,
        children: [
          createMenuDefinition({
            routeType: "appDetail",
            name: messageIds.menu.apps.app.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
        ],
      }),
      createMenuDefinition({
        routeType: "liveApp",
        name: messageIds.menu.apps.myApp.name,
        description: messageIds.apps.myApp.introduction,
      }),
    ],
  }),

  createMenuDefinition({
    name: messageIds.menu.aiPlatform.name,
    icon: iconAiPlatform,
    children: [
      createMenuDefinition({
        name: messageIds.menu.understanding.name,
        routeType: "template",
        description: messageIds.understanding.introduction,
        children: [
          createMenuDefinition({
            routeType: "templateEditor",
            name: messageIds.menu.understanding.editor.name,
            hiddenInMenu: true,
          }),
          createMenuDefinition({
            routeType: "templateTester",
            name: messageIds.menu.understanding.tester.name,
            hiddenInMenu: true,
          }),
          createMenuDefinition({
            routeType: "templatePermission",
            name: messageIds.understanding.operation.permissionSetting,
            hiddenInMenu: true,
          }),
        ],
      }),
      createMenuDefinition({
        name: messageIds.menu.aiPlatform.ocr.name,
        routeType: "ocr",
        description: messageIds.ocr.introduction,
        children: [
          createMenuDefinition({
            routeType: "ocrDetail",
            name: messageIds.ocr.ocrDetailName,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
        ],
      }),
      createMenuDefinition({
        name: messageIds.menu.aiPlatform.aiManagement.name,
        routeType: "aiManagement",
        description: messageIds.aiManagement.introduction,
        // children: [
        //   createMenuDefinition({
        //     routeType: "",
        //     name: "",
        //     hiddenInMenu: true,
        //   }),
        // ],
      }),
    ],
  }),
  createMenuDefinition({
    name: messageIds.menu.lamoss.name,
    icon: iconLAMOSS,
    children: [
      createMenuDefinition({
        name: messageIds.knowledge.name,
        routeType: "knowledge",
        description: messageIds.knowledge.description,
        children: [
          createMenuDefinition({
            routeType: "knowledgeDetail",
            name: messageIds.knowledge.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
        ],
      }),
      createMenuDefinition({
        name: messageIds.application.name,
        routeType: "application",
        description: messageIds.application.description,
        children: [
          createMenuDefinition({
            routeType: "applicationDetail",
            name: messageIds.application.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
        ],
      }),
      createMenuDefinition({
        name: messageIds.manage.name,
        routeType: "manage",
        description: messageIds.manage.description,
      }),
    ],
  }),

  createMenuDefinition({
    name: messageIds.menu.data.name,
    icon: iconData,
    children: [
      createMenuDefinition({
        routeType: "asset",
        name: messageIds.menu.data.asset.name,
        description: messageIds.asset.introduction,
        children: [
          createMenuDefinition({
            routeType: "assetDetail",
            name: messageIds.menu.data.asset.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
        ],
      }),
      createMenuDefinition({
        routeType: "dataTable",
        name: messageIds.menu.data.dataTable.name,
        description: messageIds.dataTable.introduction,
        children: [
          createMenuDefinition({
            routeType: "dataTableDetail",
            name: messageIds.menu.data.dataTable.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
        ],
      }),
      createMenuDefinition({
        routeType: "file",
        name: messageIds.menu.data.file.name,
      }),
      createMenuDefinition({
        routeType: "dataConnection",
        name: messageIds.menu.data.dataConnection.name,
        description: messageIds.dataConnection.introduction,
        children: [
          createMenuDefinition({
            routeType: "dataConnectionDetail",
            name: messageIds.menu.data.dataConnection.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
        ],
      }),
      createMenuDefinition({
        routeType: "dataQueue",
        name: messageIds.menu.data.dataQueue.name,
        description: messageIds.dataQueue.introduction,
        children: [
          createMenuDefinition({
            routeType: "dataQueueDetail",
            name: messageIds.menu.data.dataQueue.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
        ],
      }),
    ],
  }),

  createMenuDefinition({
    name: messageIds.menu.setting.name,
    icon: iconSettings,
    children: [
      createMenuDefinition({
        routeType: "organization",
        name: messageIds.menu.setting.organization.name,
      }),
      createMenuDefinition({
        routeType: "roleManager",
        name: messageIds.menu.setting.role.name,
        description: messageIds.role.introduction,
      }),
      createMenuDefinition({
        routeType: "userManager",
        name: messageIds.menu.setting.user.name,
        description: messageIds.user.introduction,
      }),
      createMenuDefinition({
        routeType: "auditlog",
        name: messageIds.menu.setting.auditlog.name,
        description: messageIds.auditlog.introduction,
      }),
      createMenuDefinition({
        routeType: "license",
        name: messageIds.menu.setting.license.name,
        description: messageIds.license.introduction,
      }),
      createMenuDefinition({
        routeType: "systemSetting",
        name: messageIds.menu.setting.systemSetting.name,
      }),
    ],
  }),
];

const expandMenu = function (
  menus: MenuDefinition[],
  parentKey?: string
): Record<string | AppRouteType, MenuExpandedDefinition> {
  let menuInfo: Record<string | AppRouteType, MenuExpandedDefinition> = {};
  for (const menu of menus) {
    const key = menu.routeType ?? menu.name;
    menuInfo[key] = {
      ...menu,
      parentKey,
    };

    if (menu.children) {
      const childrenMenuInfo = expandMenu(menu.children, key);
      menuInfo = {
        ...menuInfo,
        ...childrenMenuInfo,
      };
    }
  }

  return menuInfo;
};

export const APP_MENUS = expandMenu(appMenuDefinitions);
