/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Modal as AntdModal, Space, Switch, Tooltip } from "antd";
import {
  Fragment,
  Key,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import Button from "src/components/Button";
import { DataNode } from "src/components/EditableDirectoryTree";
import Text from "src/components/Text";
import AddUserPanel from "src/containers/apps/appsInitConfig/AddUserPanel";
import { useFormatMessage, useGetContainer, useTheme } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { AppTemplateRolePermissionAdapter } from "src/models/appTemplate";
import {
  PermissionTargetCompanyUser,
  PermissionTargetDepartment,
} from "src/models/department";
import { AppInitConfigUIModel } from "src/store/models/ui/apps/appInitConfig";
import { DepartmentModel } from "src/store/models/ui/organization/department";
import { cssTextTruncate } from "src/styles/text";

export const AppRoleList = memo(function ({
  roleData,
  onSelectRole,
  selectedRole,
}: {
  roleData?: AppTemplateRolePermissionAdapter[];
  onSelectRole: (role: AppTemplateRolePermissionAdapter) => void;
  selectedRole?: AppTemplateRolePermissionAdapter;
}) {
  const theme = useTheme();
  const formatMessage = useFormatMessage();

  return (
    <Fragment>
      <div
        css={css`
          height: 30px;
          border-bottom: 1px solid ${theme.bodyDivider};
          padding-left: 20px;
          line-height: 30px;
        `}
      >
        {formatMessage(messageIds.apps.appInitConfig.role)}
      </div>
      <div>
        {roleData?.map((item) => {
          return (
            <Tooltip title={item.name} key={item.id}>
              <div
                onClick={() => onSelectRole(item)}
                css={[
                  css`
                    padding: 10px;
                    font-size: 14px;
                    cursor: pointer;
                    :hover {
                      background-color: ${theme.bodyFrameBackground};
                    }
                  `,
                  cssTextTruncate,
                  selectedRole?.id === item.id &&
                    css`
                      color: ${theme.primaryColor};
                      background-color: ${theme.bodyFrameBackground};
                    `,
                ]}
              >
                {item.name}
              </div>
            </Tooltip>
          );
        })}
      </div>
    </Fragment>
  );
});

export default memo(function AppRoleConfig({
  roleData,
  onInitApp,
}: {
  roleData?: AppTemplateRolePermissionAdapter[];
  onInitApp: () => void;
}) {
  const getContainer = useGetContainer();
  const theme = useTheme();
  const formatMessage = useFormatMessage();

  const appInitConfigUIContainer = getContainer(AppInitConfigUIModel);
  const departmentModel = getContainer(DepartmentModel);

  const departmentData = useSelector(
    () => departmentModel.state.departmentTreeData
  );
  const companyUserData = useSelector(
    () => departmentModel.state.departmentUserTreeData
  );

  const [departmentCheckedKeys, setDepartmentCheckedKeys] =
    useState<Record<string, Key[]>>();
  const [userCheckedKeys, setUserCheckedKeys] =
    useState<Record<string, Key[]>>();
  const [departmentCheckedNodes, setDepartmentCheckedNodes] =
    useState<Record<string, Array<DataNode & PermissionTargetDepartment>>>();
  const [userCheckedNodes, setUserCheckedNodes] =
    useState<Record<string, Array<DataNode & PermissionTargetCompanyUser>>>();

  const [selectedRole, setSelectedRole] =
    useState<AppTemplateRolePermissionAdapter | null>(null);
  const [isInheritDepartment, setIsInheritDepartment] = useState<boolean>(true);
  const [includSubDepartmentKeys, setIncludSubDepartmentKeys] =
    useState<Record<string, Key[]>>();

  const selectedRoleId = useMemo(() => selectedRole?.id ?? "", [selectedRole]);

  const onCheckDepartment = useCallback(
    (
      checked: Key[],
      checkedNodes: Array<DataNode & PermissionTargetDepartment>
    ) => {
      if (selectedRoleId) {
        setDepartmentCheckedKeys({
          ...departmentCheckedKeys,
          [selectedRoleId]: checked,
        });
        setDepartmentCheckedNodes({
          ...departmentCheckedNodes,
          [selectedRoleId]: checkedNodes,
        });
      }
    },
    [departmentCheckedKeys, departmentCheckedNodes, selectedRoleId]
  );

  const onCheckUser = useCallback(
    (
      checked: Key[],
      checkedNodes: Array<DataNode & PermissionTargetCompanyUser>
    ) => {
      if (selectedRoleId) {
        setUserCheckedKeys({ ...userCheckedKeys, [selectedRoleId]: checked });
        setUserCheckedNodes({
          ...userCheckedNodes,
          [selectedRoleId]: checkedNodes,
        });
      }
    },
    [selectedRoleId, userCheckedKeys, userCheckedNodes]
  );

  const _onCheckedIncludSubDepartmentKeys = useCallback(
    (keys) => {
      setIncludSubDepartmentKeys({
        ...includSubDepartmentKeys,
        [selectedRoleId]: keys,
      });
    },
    [includSubDepartmentKeys, selectedRoleId]
  );

  const rolePermissionAdapters = useMemo(() => {
    const rolePermissionAdapterList = roleData?.map((roleItem) => {
      const roleId = roleItem.id;
      const departmentList = departmentCheckedNodes?.[roleId]?.map(
        (department) => {
          const { id, name } = department;
          const includSubDepartment = includSubDepartmentKeys?.[
            roleId
          ].includes(department.id);
          return {
            id,
            name,
            includSubDepartment:
              includSubDepartment === undefined ? true : includSubDepartment,
          };
        }
      );

      const userList = userCheckedNodes?.[roleId]
        ?.filter((userItem) => userItem.resourceType === "CompanyUser")
        ?.map((user) => {
          const { id, name } = user;
          return { id, name };
        });

      const roleUserInfo = {
        appTemplateRoleId: roleId,
        inherit: isInheritDepartment,
        departments: departmentList,
        users: userList,
      };

      return roleUserInfo;
    });

    return rolePermissionAdapterList;
  }, [
    departmentCheckedNodes,
    includSubDepartmentKeys,
    isInheritDepartment,
    roleData,
    userCheckedNodes,
  ]);

  const onSubmitRole = useCallback(async () => {
    const noUserList = rolePermissionAdapters?.filter(
      (item) => !item.departments?.length && !item.users?.length
    );
    if (noUserList?.length) {
      AntdModal.confirm({
        title: formatMessage(messageIds.apps.appInitConfig.noUserSelect),
        content: (
          <div>
            {noUserList?.map(
              (item) =>
                roleData?.find(
                  (_roleItem) => _roleItem.id === item.appTemplateRoleId
                )?.name
            )}
          </div>
        ),
        onOk: async () => {
          await appInitConfigUIContainer.actions.setModifyUpdateData.dispatch({
            rolePermissionAdapters,
          });
          onInitApp();
        },
      });
    } else {
      await appInitConfigUIContainer.actions.setModifyUpdateData.dispatch({
        rolePermissionAdapters,
      });
      onInitApp();
    }
  }, [
    appInitConfigUIContainer.actions.setModifyUpdateData,
    formatMessage,
    onInitApp,
    roleData,
    rolePermissionAdapters,
  ]);

  useEffect(() => {
    departmentModel.actions.getDepartmentUserTreeData.dispatch({});
  }, [departmentModel.actions.getDepartmentUserTreeData]);

  useEffect(() => {
    if (roleData?.[0]) {
      setSelectedRole(roleData[0]);
    }
  }, [roleData]);

  return (
    <div>
      <div>
        <p
          css={css`
            margin-bottom: 5px;
          `}
        >
          {formatMessage(messageIds.apps.appInitConfig.isInheritDepartment)}：
          <Switch
            checkedChildren={formatMessage(messageIds.common.yes)}
            unCheckedChildren={formatMessage(messageIds.common.no)}
            defaultChecked
            size="small"
            checked={isInheritDepartment}
            onChange={setIsInheritDepartment}
          />
        </p>
        <p>
          <Text type="secondary">
            {formatMessage(messageIds.apps.appInitConfig.inheritTip)}
          </Text>
        </p>
      </div>
      <div
        css={css`
          display: flex;
          min-height: 200px;
        `}
      >
        <div
          css={css`
            width: 140px;
            border: 1px solid ${theme.bodyDivider};
            margin-right: 10px;
          `}
        >
          <AppRoleList
            roleData={roleData}
            onSelectRole={setSelectedRole}
            selectedRole={selectedRole ?? undefined}
          />
        </div>
        <div
          css={css`
            flex: 1;
          `}
        >
          <AddUserPanel
            departmentData={departmentData}
            companyUserData={companyUserData}
            departmentHandleProps={{
              onCheck: onCheckDepartment,
              checkedKeys: departmentCheckedKeys?.[selectedRoleId],
              checkedNodes: departmentCheckedNodes?.[selectedRoleId],
              checkedIncludSubDepartmentKeys:
                includSubDepartmentKeys?.[selectedRoleId],
              onCheckedIncludSubDepartmentKeys:
                _onCheckedIncludSubDepartmentKeys,
            }}
            userHandleProps={{
              onCheck: onCheckUser,
              checkedKeys: userCheckedKeys?.[selectedRoleId],
              checkedNodes: userCheckedNodes?.[selectedRoleId],
            }}
          />
        </div>
      </div>
      <Space
        size={20}
        css={css`
          display: flex;
          justify-content: flex-end;
          padding-top: 30px;
        `}
      >
        <Button type="primary" onClick={onSubmitRole}>
          {formatMessage(messageIds.common.complete)}
        </Button>
      </Space>
    </div>
  );
});
