import { createModel, createSelector } from "nyax";
import { Job } from "src/models/job";
import { PackageVersionArguments } from "src/models/packageVersion";
import { ModelBase } from "src/store/ModelBase";
import { JobEntityModel } from "src/store/models/entity/job/entity";
import { JobHelperModel } from "src/store/models/entity/job/helper";
import { PackageVersionHelperModel } from "src/store/models/entity/packageVersion/helper";
import { QueueHelperModel } from "src/store/models/entity/queue/helper";
import { RobotHelperModel } from "src/store/models/entity/robot/helper";
import { WorkflowHelperModel } from "src/store/models/entity/workflow/helper";
import { handleErrorNotFound } from "src/utils/error";

export type JobArguments = Omit<PackageVersionArguments, "defaultValue"> & {
  packageValue?: string;
  workflowValue?: string;
  jobValue?: string;
};
export const JobDetailModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        jobId: null as string | null,
        workflowArguments: [] as PackageVersionArguments[] | null,
        packageVersionArguments: [] as PackageVersionArguments[] | null,
        robotOrQueueName: "" as string | null,
      };
    }

    public reducers() {
      return {
        setJobId: (value: string | null) => {
          this.state.jobId = value;
        },
        setWorkflowArguments: (value: PackageVersionArguments[] | null) => {
          this.state.workflowArguments = value;
        },
        setPackageVersionArguments: (
          value: PackageVersionArguments[] | null
        ) => {
          this.state.packageVersionArguments = value;
        },
        setRobotOrQueueName: (value: string | null) => {
          this.state.robotOrQueueName = value;
        },
      };
    }

    public selectors() {
      return {
        job: createSelector(
          () => this.state.jobId,
          () => this.getContainer(JobEntityModel).state.byId,
          (id, itemById): Job | undefined => {
            return itemById[id ?? ""];
          }
        ),
        jobDetailArguments: createSelector(
          () => this.state.packageVersionArguments,
          () => this.state.workflowArguments,
          (): PackageVersionArguments[] | undefined =>
            this.getters.job?.arguments,
          (packageVersionArguments, workflowArguments, jobArguments) => {
            if (packageVersionArguments && packageVersionArguments.length > 0) {
              const allArguments = packageVersionArguments?.map(
                (packageVersionArgumentsItem) => {
                  const argumentName = packageVersionArgumentsItem.name;

                  const jobItem = jobArguments?.find(
                    (jobArgumentsItem) => jobArgumentsItem.name === argumentName
                  );

                  const jobValue = jobItem?.assetContent
                    ? jobItem.assetContent.name
                    : jobItem?.defaultValue;

                  const workflowItem = workflowArguments?.find(
                    (workflowArgumentsItem) =>
                      workflowArgumentsItem.name === argumentName
                  );

                  const workflowValue = workflowItem?.assetContent
                    ? workflowItem.assetContent.name
                    : workflowItem?.defaultValue;

                  const packageItem = packageVersionArgumentsItem;

                  const packageValue = packageItem.assetContent
                    ? packageItem.assetContent.name
                    : packageItem.defaultValue;

                  const argumentItem = {
                    ...packageVersionArgumentsItem,
                    packageValue: packageValue ?? undefined,
                    workflowValue: workflowValue ?? undefined,
                    jobValue: jobValue ?? undefined,
                  };
                  delete argumentItem.defaultValue;
                  return argumentItem;
                }
              );
              return allArguments;
            } else {
              return jobArguments?.map((jobArg) => {
                const jobValue = jobArg?.assetContent
                  ? jobArg.assetContent.name
                  : jobArg?.defaultValue;

                const argumentItem = {
                  ...jobArg,
                  packageValue: undefined,
                  workflowValue: undefined,
                  jobValue: jobValue ?? undefined,
                };
                delete argumentItem.defaultValue;
                return argumentItem;
              });
            }
          }
        ),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        requestJobDetail: async (payload: { id: string }) => {
          const { id } = payload;
          const job = await this.getContainer(
            JobHelperModel
          ).actions.readById.dispatch({
            id,
          });
          this.actions.setJobId.dispatch(job?.id ?? null);
          let robotOrQueueJob = null;
          if (job?.isRobotDedicatedQueue && job?.containingQueueId) {
            robotOrQueueJob = await this.getContainer(
              RobotHelperModel
            ).actions.getById.dispatch({
              robotId: job.containingQueueId,
            });
          } else if (job?.containingQueueId) {
            robotOrQueueJob = await this.getContainer(
              QueueHelperModel
            ).actions.getById.dispatch({
              queueId: job.containingQueueId,
            });
          }
          await this.actions.setRobotOrQueueName.dispatch(
            robotOrQueueJob?.name ?? null
          );
        },
        requestJobDetailArguments: async (payload: {
          workflowId: string;
          packageId: string;
          packageVersionId: string;
        }) => {
          const { workflowId, packageId, packageVersionId } = payload;

          try {
            const packageVersion = await this.getContainer(
              PackageVersionHelperModel
            ).actions.getVersionDetail.dispatch({
              packageId: packageId,
              versionId: packageVersionId,
            });
            await this.actions.setPackageVersionArguments.dispatch(
              packageVersion?.arguments ?? null
            );
          } catch (error) {
            handleErrorNotFound(error);
          }

          const workflow = await this.getContainer(
            WorkflowHelperModel
          ).actions.getById.dispatch({
            workflowId,
          });

          await this.actions.setWorkflowArguments.dispatch(
            workflow?.arguments ?? null
          );
        },
        clear: async () => {
          await this.actions.setJobId.dispatch(null);
          await this.actions.setPackageVersionArguments.dispatch(null);
          await this.actions.setWorkflowArguments.dispatch(null);
          await this.actions.setRobotOrQueueName.dispatch(null);
        },
      };
    }
  }
);
