// eslint-disable-next-line import/no-anonymous-default-export
export default {
  queue: {
    queue: "组",
    name: "组名称",
    introduction:
      "机器人组主要用于绑定机器人集群，同时将触发到组中的任务动态分配给空闲机器人进行运行。",
    tasksPerformed: "进行任务数",
    remainingTasks: "剩余任务数",
    robotCount: "机器人数",
    workflowCount: "流程关联数",

    bindRobot: "添加机器人",
    deleteTip: "删除后组中的剩余任务均会终止",
  },
};
