import { createModel } from "nyax";
import {
  CoeDataAnalysisPackageConfig,
  CoeDataAnalysisPackageConfigEncooList,
  CoeDataAnalysisPackageConfigQuery,
} from "src/models/coeDataAnalysisPackageConfig";
import { CoeDataAnalysisPackageConfigEntityModel } from "src/store/models/entity/coeDataAnalysisPackageConfig/entity";
import { createUITableWithPermissionModel } from "src/store/models/ui/_shared";

export type CoeDataAnalysisPackageConfigWithPermission =
  CoeDataAnalysisPackageConfig & {
    permissionValues: string[];
  };

export const CoeDataAnalysisPackageConfigTableUIModel = createModel(
  class extends createUITableWithPermissionModel<
    CoeDataAnalysisPackageConfigEncooList,
    CoeDataAnalysisPackageConfigQuery
  >({
    setItems: (getContainer, items) =>
      getContainer(
        CoeDataAnalysisPackageConfigEntityModel
      ).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(CoeDataAnalysisPackageConfigEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(CoeDataAnalysisPackageConfigEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      const superEffects = super.effects();
      return {
        ...superEffects,

        initial: async (payload: { departmentId: string }) => {
          const { departmentId } = payload;
          this._initial({
            initialAction: (pageIndex: number, pageSize: number) =>
              this.dependencies.serviceClient.coeDataAnalysisPackageConfig.list(
                departmentId,
                pageIndex,
                pageSize
              ),
          });
        },
      };
    }
  }
);
