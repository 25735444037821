import { createModel } from "nyax";
import { AppV2 } from "src/models/appV2";
import { AppEntityModel } from "src/store/models/entity/apps/entity";
import { createUIListV2Model } from "src/store/models/ui/_shared";

export const AppCollectionListModel = createModel(
  class extends createUIListV2Model<AppV2>({
    setItems: (getContainer, items) =>
      getContainer(AppEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(AppEntityModel).getters.items,
    getItem: (getContainer, id) => getContainer(AppEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initial: async () => {
          this._initial({
            initialAction: (pageIndex: number, pageSize: number) =>
              this.dependencies.serviceClient.appsV2.getAppFavorite({
                pageIndex,
                pageSize,
              }),
          });
        },
      };
    }
  }
);
