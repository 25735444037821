export const APPV1_ICON_PATH = "/app_icons/";
export const APPV1_ICON_DEFAULT = `${APPV1_ICON_PATH}default.png`;

export const APPV1_ICONS = [
  `${APPV1_ICON_PATH}company.png`,
  `${APPV1_ICON_PATH}advise.png`,
  `${APPV1_ICON_PATH}scan.png`,
  `${APPV1_ICON_PATH}search.png`,
  `${APPV1_ICON_PATH}taxi.png`,
  `${APPV1_ICON_PATH}pie_chart.png`,
  `${APPV1_ICON_PATH}department.png`,
  `${APPV1_ICON_PATH}schedule.png`,
  `${APPV1_ICON_PATH}travel.png`,
  `${APPV1_ICON_PATH}hotal.png`,
  `${APPV1_ICON_PATH}approval.png`,
  `${APPV1_ICON_PATH}computer.png`,
  `${APPV1_ICON_PATH}airplane.png`,
  `${APPV1_ICON_PATH}tree.png`,
  `${APPV1_ICON_PATH}web_disk.png`,
  `${APPV1_ICON_PATH}fingerpost.png`,
  `${APPV1_ICON_PATH}group_chat.png`,
  `${APPV1_ICON_PATH}todo.png`,
  `${APPV1_ICON_PATH}net_meeting.png`,
  `${APPV1_ICON_PATH}mail.png`,
  `${APPV1_ICON_PATH}guide.png`,
  `${APPV1_ICON_PATH}notice.png`,
  `${APPV1_ICON_PATH}user_auth.png`,
  `${APPV1_ICON_PATH}verif.png`,
  `${APPV1_ICON_PATH}contacts.png`,
  `${APPV1_ICON_PATH}rmb.png`,
  `${APPV1_ICON_PATH}task.png`,
  `${APPV1_ICON_PATH}assistance.png`,
  `${APPV1_ICON_PATH}vote.png`,
  `${APPV1_ICON_PATH}chart.png`,
  `${APPV1_ICON_PATH}subscription.png`,
  `${APPV1_ICON_PATH}message.png`,
  `${APPV1_ICON_PATH}work_circle.png`,
  `${APPV1_ICON_PATH}news.png`,
  `${APPV1_ICON_PATH}robat.png`,
  `${APPV1_ICON_PATH}settings.png`,
  `${APPV1_ICON_PATH}app_center.png`,
  `${APPV1_ICON_PATH}person.png`,
  `${APPV1_ICON_PATH}warn.png`,
  `${APPV1_ICON_PATH}default.png`,
];
