import { createModel, createSelector } from "nyax";
import { ProcessCorrelationData } from "src/models/coeWork";
import { EncooListEntity } from "src/models/_shared";
import { ModelBase } from "src/store/ModelBase";
import { CoeMyWorkHelperModel } from "src/store/models/entity/coeMyWork/helper";
import { CoeWorkerUIModel } from "src/store/models/ui/coeWorks/coeWork";

export const CoeProcessUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        pageIndex: 1,
        pageSize: 10,
        selectedRowKeys: [] as string[] | undefined,
        dataSource: {} as EncooListEntity<ProcessCorrelationData>,
        processData: [] as ProcessCorrelationData[],
      };
    }
    public reducers() {
      return {
        setPageIndex: (value: number) => {
          this.state.pageIndex = value;
        },
        setPageSize: (value: number) => {
          this.state.pageSize = value;
        },
        setSelectedRowKeys: (value: string[] | undefined) => {
          this.state.selectedRowKeys = value;
        },
        setDataSource: (value: EncooListEntity<ProcessCorrelationData>) => {
          this.state.dataSource = value;
        },
        setProcessData: (value: ProcessCorrelationData[]) => {
          this.state.processData = value;
        },
      };
    }
    public selectors() {
      return {
        processData: createSelector(
          () => this.state.processData,
          () => this.getContainer(CoeWorkerUIModel).getters.packageById,
          (process, packageById) => {
            return process.filter((item) => packageById[item.id]);
          }
        ),
      };
    }
    public effects() {
      return {
        packageList: async (payload: {
          pageIndex: number;
          pageSize?: number;
        }) => {
          const { pageIndex, pageSize } = payload;
          const data = await this.getContainer(
            CoeMyWorkHelperModel
          ).actions.getPackages.dispatch({
            pageIndex,
            pageSize,
          });

          const allData = await this.getContainer(
            CoeMyWorkHelperModel
          ).actions.getPackages.dispatch({
            pageIndex,
            pageSize: data.count,
          });

          this.actions.setDataSource.dispatch(allData);
        },
      };
    }
  }
);
