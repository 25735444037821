/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import imgNoPermission from "src/assets/403.png";
import { useFormatMessage, useTheme } from "src/hooks";
import messageIds from "src/locales/messageIds";

export default React.memo(function NoPermissionContainer() {
  const theme = useTheme();
  const formatMessage = useFormatMessage();
  return (
    <div
      css={css`
        flex-grow: 1;
        text-align: center;
        :after {
          content: "";
          display: inline-block;
          height: 100%;
          vertical-align: middle;
        }
      `}
    >
      <div
        css={css`
          display: inline-block;
        `}
      >
        <div
          css={css`
            display: inline-block;
            vertical-align: middle;
          `}
        >
          <img alt="" src={imgNoPermission} />
        </div>
        <div
          css={css`
            display: inline-block;
            vertical-align: middle;
            line-height: 2;
            text-align: left;
          `}
        >
          <div
            css={css`
              font-size: 40px;
              font-weight: 500;
              color: ${theme.bodyText};
            `}
          >
            {formatMessage(messageIds.errors.noPermission.title)}…
          </div>
          <div
            css={css`
              font-size: 18px;
              font-weight: 400;
              color: ${theme.bodySubtext};
            `}
          >
            {formatMessage(messageIds.errors.noPermission.description)}
          </div>
        </div>
      </div>
    </div>
  );
});
