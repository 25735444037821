import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { UserManagerQuery } from "src/containers/userManager/models/UserManagerUserModel";
import { DepartmentUser, InviteResult } from "src/models/DepartmentUser";
import { EncooListEntity } from "src/models/_shared";

export const DEPARTMENT_USER_SERVICE_NAME = "departmentUser";
export class DepartmentUserServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, DEPARTMENT_USER_SERVICE_NAME);
  }

  async getList(departmentId: string): Promise<Array<DepartmentUser>> {
    const req = this.buildRequest({
      url: `/v2/departments/${departmentId}/companyusers`,
      method: "GET",
    });
    return await super.getAllInternal<DepartmentUser>(req);
  }

  list = async (
    departmentId: string,
    pageIndex: number,
    pageSize: number,
    query?: UserManagerQuery
  ): Promise<EncooListEntity<DepartmentUser>> => {
    const req = this.buildRequestV2({
      url: `/v3/CompanyUsers`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
        keyword: query?.keyword,
        status: query?.status,
        assignedDepartmentId: departmentId,
        userName: query?.userName,
        email: query?.email,
        phoneNumber: query?.phoneNumber,
        roleId: query?.roleId,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  };

  async invite(
    name: string,
    email: string,
    departmentId: string,
    roleIds: Array<string>
  ): Promise<InviteResult> {
    const req = this.buildRequest({
      url: `/v2/Companies/users`,
      method: "POST",
      payload: [
        {
          DisplayName: name,
          email,
          assignedDepartmentId: departmentId,
          roleIds: roleIds,
        },
      ],
    });
    return await super.createInternal(req);
  }

  async invitePrivate(
    name: string,
    userName: string,
    password: string,
    departmentId: string,
    roleIds: Array<string>
    // TODO: 预留位
    // description?: string
  ): Promise<InviteResult> {
    const req = this.buildRequest({
      url: `/v2/CompanyAdmin/users`,
      method: "POST",
      payload: {
        displayName: name,
        userName,
        password,
        assignedDepartmentId: departmentId,
        roleIds: roleIds,
        //   description: description,
      },
    });
    return await super.createInternal(req);
  }

  async updatePassword(id: string, password: string): Promise<DepartmentUser> {
    const req = this.buildRequest({
      url: `/v2/CompanyAdmin/users/${id}/password`,
      method: "PATCH",
      payload: {
        password: password,
      },
    });
    return await super.updateInternal(req);
  }

  async updateDepartmentId(
    id: string,
    departmentId: string
  ): Promise<DepartmentUser> {
    const req = this.buildRequest({
      url: `/v2/CompanyUsers/${id}`,
      method: "PATCH",
      payload: {
        assignedDepartmentId: departmentId,
      },
    });
    return await super.updateInternal(req);
  }

  async updateRole(id: string, role: string): Promise<DepartmentUser> {
    const req = this.buildRequest({
      url: `/v2/CompanyUsers/${id}/role`,
      method: "PATCH",
      payload: {
        Role: role,
      },
    });
    return await super.updateInternal(req);
  }

  async updateInfo(
    id: string,
    name: string,
    description?: string
  ): Promise<DepartmentUser> {
    const req = this.buildRequest({
      url: `/v2/CompanyUsers/${id}`,
      method: "PATCH",
      payload: {
        name,
        description,
      },
    });
    return await super.updateInternal(req);
  }

  async deleteById(id: string): Promise<boolean> {
    const req = this.buildRequest({
      url: `/v2/CompanyUsers/${id}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }

  async setAble(id: string, flag: boolean): Promise<DepartmentUser> {
    const req = this.buildRequest({
      url: `/v2/CompanyUsers/${id}`,
      method: "PATCH",
      payload: {
        status: flag ? "Active" : "Banned",
      },
    });
    return await super.updateInternal(req);
  }
}
