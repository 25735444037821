import { createModel } from "nyax";
import { Job, UpdateJobMessage } from "src/models/job";
import { JobEntityModel } from "src/store/models/entity/job/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const JobHelperModel = createModel(
  class extends createHelperModel<Job>({
    setItems: (getContainer, items) =>
      getContainer(JobEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(JobEntityModel).getters.items,
    getItem: (getContainer, id) => getContainer(JobEntityModel).state.byId[id],
  }) {
    public effects() {
      return {
        ...super.effects(),
        readByGroupIds: async (payload: { force?: boolean }) => {
          const { force } = payload;
          return await this._readByParentIds({
            parentIds: [""],
            getAllAction: () => {
              return this.dependencies.serviceClient.job.getAll();
            },
            getEntityParentId: (entity: Job) => "",
            force,
          });
        },
        readByWorkflowIds: async (payload: {
          workflowIds: Array<string>;
          force?: boolean;
        }) => {
          const { workflowIds, force } = payload;
          return await this._readByParentIds({
            parentIds: workflowIds,
            getAllAction: (parentId) => {
              return this.dependencies.serviceClient.workflow.getAllJobs(
                parentId
              );
            },
            getEntityParentId: (entity: Job) => entity.workflowId,
            force,
          });
        },
        readByQueueIds: async (payload: {
          queueIds: Array<string>;
          force?: boolean;
        }) => {
          const { queueIds, force } = payload;
          return await this._readByParentIds({
            parentIds: queueIds,
            getAllAction: (parentId) => {
              return this.dependencies.serviceClient.workflow.getJobsByQueue(
                parentId
              );
            },
            getEntityParentId: (entity: Job) => entity.containingQueueId,
            force,
          });
        },
        readById: async (payload: { id: string; force?: boolean }) => {
          const { id, force } = payload;
          return await this._readById({
            id,
            getByIdAction: () => {
              return this.dependencies.serviceClient.job.getById(id);
            },
            force,
          });
        },
        update: async (payload: {
          jobId: string;
          message?: UpdateJobMessage;
          priority?: number;
        }) => {
          const { jobId, message, priority } = payload;
          await this.dependencies.serviceClient.job.updateJob(jobId, {
            message,
            priority,
          });
        },
        stopJobs: async (payload: { jobIds: Array<string> }) => {
          const { jobIds } = payload;
          return await this.dependencies.serviceClient.job.stopJobs(jobIds);
        },
        executeJobs: async (payload: { jobIds: Array<string> }) => {
          const { jobIds } = payload;
          return await this.dependencies.serviceClient.job.executeJobs(jobIds);
        },
        batchDelete: async (payload: { jobIds: Array<string> }) => {
          const { jobIds } = payload;
          return await this.dependencies.serviceClient.job.batchDelete(jobIds);
        },
        delete: async (payload: { id: string }) => {
          const { id } = payload;
          return await this._delete({
            id,
            deleteAction: () => this.dependencies.serviceClient.job.delete(id),
          });
        },
        batchUpdateJobs: async (payload: {
          priority: string;
          jobIds: Array<string>;
        }) => {
          const { jobIds, priority } = payload;
          return await this.dependencies.serviceClient.job.batchUpdateJobs(
            priority,
            jobIds
          );
        },
      };
    }
  }
);
