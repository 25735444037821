// eslint-disable-next-line import/no-anonymous-default-export
export default {
  runInstance: {
    viewLog: "View Log",
    logInfo: "Log Information",
    logInfoDescription:
      "The page supports viewing and filtering of log data within 100,000 log records; and supports viewing the number of logs within 1,000 when the process is running; the excess part needs to be downloaded and viewed.",
    logTime: "Log Time",
    logRank: "Log Level",
    logType: "Log Type",
    logTypeList: {
      systemLog: "System Log",
      defLog: "Custom Log",
    },
    allLog: "All",
    errLog: "Error",
    infoLog: "Info",
    debugLog: "Debug",
    logContent: "Log Content",
    screenshotShown: "Screenshot Display",
    downloadLogTip: "Download All history log data",
    runinstanceNoVideoTip: "No video record of the current task.",
    videoShow: "Video Display",
    noScreenshotShown: "No screenshot.",
    logTimeIcon: "Time of the robot's computer",
    placeholder: {
      allType: "All Types",
      allLevel: "All Levels",
    },
    noMatch: "No Matches",
  },
  execution: {
    source: "Task Source",
    runOrder: "Execution Order",
    executionRobot: "Execution Robot",
    log: "Log",
    monitor: "Execute progress",
    priority: "Priority Execution",
    restart: "Restart",
    termination: "Termination",
    expansion: "Show All",
    retraction: "Take Back",
    startMode: "Start Mode",
    status: {
      create: "Create",
      running: "Running",
      failed: "Failed",
      cancelled: "Cancel",
      succeeded: "Succeeded",
      cancelling: "Canceling",
    },
    statusTip: {
      running:
        'If the task has available robot resources and is executing the process, the task status is "running".',
      paused:
        'If the user suspends the task operation of "running" on the console or robot end, the task status is "paused".',
      cancelled:
        'The execution result of a process task, such as execution order 1.\nCase1: After the user cancels the task operation of "running / waiting / paused" at the console or robot, the task status is "cancelled";\nCase2: According to the set conflict handling strategy, when the robot is busy, the system automatically cancels the subsequent tasks sent to the robot, and the task status is "cancelled".',
      succeeded:
        'The execution result of a process task of the robot is "succeeded", such as execution order 1.',
      failed:
        'The execution result of a process task of the robot is "failed", such as execution order 1.',
    },
  },
  rpaPlan: {
    logManage: "Job Log Management",
    logFile: "Job Log File",
    videoLog: "Video Recording File",
    videoSize: "Video Storage Capacity",
    stayLast: " Keep only the most recent ",
    stayLog: " Keep only the most recent {number}{unit} file",
    maxSize: " Maximum total video storage capacity ",
    unitFile: " Internal documents",
    year: "year",
    month: "month",
    day: "day",
  },
};
