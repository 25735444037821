import { createModel } from "nyax";
import { Queue, QueueBasicData } from "src/models/queue";
import { QueueEntityModel } from "src/store/models/entity/queue/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const QueueHelperModel = createModel(
  class extends createHelperModel<Queue>({
    setItems: (getContainer, items) =>
      getContainer(QueueEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(QueueEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(QueueEntityModel).state.byId[id],
  }) {
    public effects() {
      return {
        ...super.effects(),

        readByGroupIds: async (payload: { departmentIds: string[] }) => {
          return await this._readByParentIds({
            parentIds: payload.departmentIds,
            getAllAction: (parentId) => {
              return this.dependencies.serviceClient.queue.getAll();
            },
            getEntityParentId: (entity: Queue) => entity.parentId,
            force: true,
          });
        },

        getById: async (payload: { queueId: string }) => {
          const { queueId } = payload;

          return this._readById({
            id: queueId,
            getByIdAction: async () => {
              const item = await this.dependencies.serviceClient.queue.getById(
                queueId
              );
              return item;
            },
          });
        },

        bindRobots: async (payload: {
          queueId: string;
          robotIds: string[];
        }) => {
          const { queueId, robotIds } = payload;
          await this.dependencies.serviceClient.queue.bindRobot(queueId, {
            robotIds,
          });
        },

        create: async (payload: {
          queueBasicData: QueueBasicData;
          selectedIds: string[];
        }) => {
          const { queueBasicData, selectedIds } = payload;
          return await this._create({
            createAction: async () => {
              const item = await this.dependencies.serviceClient.queue.create(
                queueBasicData
              );
              await this.actions.bindRobots.dispatch({
                queueId: item.id,
                robotIds: selectedIds,
              });

              selectedIds.forEach((robotId) => {
                this.dependencies.applicationInsights.sendTrackEvent(
                  { name: "Console_BindRobotToQueueEvent" },
                  {
                    QueueID: item.id,
                    RobotID: robotId,
                  }
                );
              });

              // TODO:接口字段暂无robot
              return item;
            },
          });
        },

        getBindingRobots: async (payload: { queueId: string }) => {
          const { queueId } = payload;
          return await this.dependencies.serviceClient.queue.getAllBindingInfos(
            queueId
          );
        },

        update: async (payload: {
          queueId: string;
          queueBasicData: QueueBasicData;
        }) => {
          const { queueId, queueBasicData } = payload;
          return await this._update({
            id: queueId,
            updateAction: () =>
              this.dependencies.serviceClient.queue.update(
                queueId,
                queueBasicData
              ),
          });
        },
        delete: async (payload: { id: string }) => {
          const { id } = payload;
          return await this._delete({
            id,
            deleteAction: () =>
              this.dependencies.serviceClient.queue.delete(id),
          });
        },
        deleteBatch: async (payload: { queueIds: string[] }) => {
          const { queueIds } = payload;
          return await this.dependencies.serviceClient.queue.batchDelete(
            queueIds
          );
        },
        unbindRobot: async (payload: { queueId: string; robotId: string }) => {
          const { queueId, robotId } = payload;
          await this.dependencies.serviceClient.queue.unbindRobot(
            queueId,
            robotId
          );
        },
      };
    }
  }
);
