import { createModel } from "nyax";
import { DepartmentUser } from "src/models/DepartmentUser";
import { DepartmentUserEntityModel } from "src/store/models/entity/departmentUser/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const DepartmentUserHelperModel = createModel(
  class extends createHelperModel<DepartmentUser>({
    setItems: (getContainer, items) =>
      getContainer(DepartmentUserEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(DepartmentUserEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(DepartmentUserEntityModel).state.byId[id],
  }) {
    public effects() {
      return {
        ...super.effects(),

        invite: async (params: {
          name: string;
          email: string;
          departmentId: string;
          roleIds: Array<string>;
        }) => {
          return await this.dependencies.serviceClient.departmentUser.invite(
            params.name,
            params.email,
            params.departmentId,
            params.roleIds
          );
        },
        invitePrivate: async (params: {
          name: string;
          userName: string;
          password: string;
          departmentId: string;
          roleIds: Array<string>;
          // TODO: 设计预留位
          //   description?: string;
        }) => {
          return await this.dependencies.serviceClient.departmentUser.invitePrivate(
            params.name,
            params.userName,
            params.password,
            params.departmentId,
            params.roleIds
            // params.description
          );
        },
        updatePassword: async (params: { id: string; password: string }) => {
          return this.dependencies.serviceClient.departmentUser.updatePassword(
            params.id,
            params.password
          );
        },
        updateDepartmentId: async (params: {
          id: string;
          departmentId: string;
        }) => {
          return await this._update({
            id: params.id,
            updateAction: () =>
              this.dependencies.serviceClient.departmentUser.updateDepartmentId(
                params.id,
                params.departmentId
              ),
          });
        },
        updateRole: async (params: { id: string; role: string }) => {
          return await this._update({
            id: params.id,
            updateAction: () =>
              this.dependencies.serviceClient.departmentUser.updateRole(
                params.id,
                params.role
              ),
          });
        },
        updateInfo: async (params: {
          id: string;
          name: string;
          description: string;
        }) => {
          return await this._update({
            id: params.id,
            updateAction: () =>
              this.dependencies.serviceClient.departmentUser.updateInfo(
                params.id,
                params.name,
                params.description
              ),
          });
        },
        deleteById: async (id: string) => {
          return await this._delete({
            id: id,
            deleteAction: () =>
              this.dependencies.serviceClient.departmentUser.deleteById(id),
          });
        },
        setAble: async (params: { id: string; flag: boolean }) => {
          return await this._update({
            id: params.id,
            updateAction: () =>
              this.dependencies.serviceClient.departmentUser.setAble(
                params.id,
                params.flag
              ),
          });
        },
      };
    }
  }
);
