/** @jsxImportSource @emotion/react */
import { Entity } from "@encoo-web/encoo-lib";
import { DataConnection, DataConnectionList } from "@encoo-web/encoo-ui";
import { message } from "antd";
import { useCallback } from "react";
import { useDialog } from "src/components/Dialog";
import { useFormatMessage, useGetContainer } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { DataConnectionHelperModel } from "src/store/models/entity/dataConnection/helper";
import { DataConnectionListModel } from "src/store/models/entity/dataConnection/list";
import { QuotaUIModel } from "src/store/models/ui/quota/quota";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useConnectorAction() {
  const getContainer = useGetContainer();
  const formatMessage = useFormatMessage();
  const dataConnectionHelper = getContainer(DataConnectionHelperModel);
  const listContainer = getContainer(DataConnectionListModel);
  const quota = getContainer(QuotaUIModel);

  const Dialog = useDialog();

  const create = useCallback(
    async (values: Omit<DataConnection, keyof Entity | "id">) => {
      const { properties, ...connection } = {
        properties: undefined,
        ...values,
      };
      try {
        const connectionItem =
          await dataConnectionHelper.actions.create.dispatch({
            connection,
            properties,
          });
        message.success(formatMessage(messageIds.common.createSuccess));
        await listContainer.actions.refresh.dispatch({});
        await quota.actions.refreshQuota.dispatch({});
        return connectionItem;
      } catch {
        return false;
      }
    },
    [
      dataConnectionHelper.actions.create,
      formatMessage,
      listContainer.actions.refresh,
      quota.actions.refreshQuota,
    ]
  );

  const testWithConfig = useCallback(
    async (values: Omit<DataConnection, keyof Entity | "id">) => {
      try {
        await dataConnectionHelper.actions.testByConfig.dispatch(values);
        message.success(
          formatMessage(messageIds.dataConnection.testSuccessful)
        );
        return true;
      } catch {
        return false;
      }
    },
    [dataConnectionHelper.actions.testByConfig, formatMessage]
  );

  const onItemTestWithId = useCallback(
    async (id: string) => {
      try {
        await dataConnectionHelper.actions.testById.dispatch({
          id,
        });
        message.success(
          formatMessage(messageIds.dataConnection.testSuccessful)
        );
      } catch (error) {
        return false;
      }
    },
    [dataConnectionHelper.actions.testById, formatMessage]
  );

  const onItemDelete = useCallback(
    (record: DataConnectionList) => {
      Dialog({
        type: "warning",
        content: formatMessage(messageIds.common.info.deletionConfirmation, {
          entityTypeName: "",
          name: record.name,
        }),
        onOk: async () => {
          try {
            await dataConnectionHelper.actions.delete.dispatch({
              id: record.id,
            });
            await listContainer.actions.refresh.dispatch({});
            message.success(
              formatMessage(messageIds.common.info.deleteSuccessfully, {
                entityTypeName: "",
                name: record.name,
              })
            );
            await quota.actions.refreshQuota.dispatch({});
          } catch (error) {
            return false;
          }
        },
      });
    },
    [
      Dialog,
      dataConnectionHelper.actions.delete,
      formatMessage,
      listContainer.actions.refresh,
      quota.actions.refreshQuota,
    ]
  );
  /** 通过文件服务入口创建连接器，创建成功后自动回填新创建的连接器ID */
  const createWithItemIdBack = useCallback(
    async (values: Omit<DataConnection, keyof Entity | "id">) => {
      const { properties, ...connection } = {
        properties: undefined,
        ...values,
      };
      try {
        const item = await dataConnectionHelper.actions.create.dispatch({
          connection,
          properties,
        });
        message.success(formatMessage(messageIds.common.createSuccess));
        await listContainer.actions.refresh.dispatch({});
        return item?.id ?? "";
      } catch {
        return "";
      }
    },
    [
      dataConnectionHelper.actions.create,
      formatMessage,
      listContainer.actions.refresh,
    ]
  );
  return {
    create,
    testWithConfig,
    onItemTestWithId,
    onItemDelete,
    createWithItemIdBack,
  };
}
