import { createModel } from "nyax";
import { Robot, RobotQuery } from "src/models/robot";
import { RobotEntityModel } from "src/store/models/entity/robot/entity";
import { createUITableBaseModel } from "src/store/models/ui/_shared";

export const RobotSelectTableUIModel = createModel(
  class extends createUITableBaseModel<Robot, RobotQuery>({
    setItems: (getContainer, items) =>
      getContainer(RobotEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(RobotEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(RobotEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initial: async (payload: { departmentId: string }) => {
          const { departmentId } = payload;
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query: RobotQuery
            ) => {
              return this.dependencies.serviceClient.robot.list(
                departmentId,
                pageIndex,
                pageSize,
                query
              );
            },
          });
        },
      };
    }
  }
);
