import { createModel, createSelector } from "nyax";
import { Workflow } from "src/models/workflow";
import { ModelBase } from "src/store/ModelBase";
import { DataPermissionEntityModel } from "src/store/models/entity/dataPermission/entity";
import { PackageEntityModel } from "src/store/models/entity/package/entity";
import { PackageHelperModel } from "src/store/models/entity/package/helper";
import { PackageVersionEntityModel } from "src/store/models/entity/packageVersion/entity";
import { PackageVersionHelperModel } from "src/store/models/entity/packageVersion/helper";
import { WorkflowEntityModel } from "src/store/models/entity/workflow/entity";
import { WorkflowHelperModel } from "src/store/models/entity/workflow/helper";
import { handleErrorNotFound } from "src/utils/error";

export const WorkflowDetailModelV2 = createModel(
  class extends ModelBase {
    private get _id() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.containerKey!;
    }

    public selectors() {
      return {
        workflowDetail: createSelector(
          () => this.getContainer(WorkflowEntityModel).state.byId,
          () => this.getContainer(DataPermissionEntityModel).state.byId,
          (workflowById, permissionById): Workflow | null => {
            const workflow = workflowById[this._id];

            return workflow
              ? ({
                  ...workflow,
                  permissionValues:
                    permissionById[this._id]?.permissionValues ?? [],
                } as Workflow)
              : null;
          }
        ),
        associatedPackageInfo: createSelector(
          (): Workflow | null => this.getters.workflowDetail,
          () => this.getContainer(PackageEntityModel).state.byId,
          () => this.getContainer(PackageVersionEntityModel).state.byId,
          (workflow, packageById, packageVersionById) => {
            return {
              package: packageById[workflow?.packageId ?? ""],
              packageVersion:
                packageVersionById[workflow?.packageVersionId ?? ""],
            };
          }
        ),
        hasTriggerReadPermission: createSelector(
          (): Workflow | null => this.getters.workflowDetail,
          (workflow) => workflow?.permissionValues.includes("Trigger_Read")
        ),
        hasWorkflowManagePermission: createSelector(
          (): Workflow | null => this.getters.workflowDetail,
          (workflow) => workflow?.permissionValues.includes("Workflow_Manage")
        ),
        hasJobReadPermission: createSelector(
          (): Workflow | null => this.getters.workflowDetail,
          (workflow) => workflow?.permissionValues.includes("Job_Read")
        ),
        hasTriggerCreatePermission: createSelector(
          (): Workflow | null => this.getters.workflowDetail,
          (workflow) => workflow?.permissionValues.includes("Trigger_Create")
        ),
      };
    }

    public effects() {
      return {
        requestDetail: async (paylaod: { force?: boolean }) => {
          const { force } = paylaod;
          await this.getContainer(WorkflowHelperModel).actions.getById.dispatch(
            { workflowId: this._id, force }
          );
        },
        requestWorkflowPackageInfo: async () => {
          const workflow = this.getters.workflowDetail;

          if (!workflow) {
            return;
          }

          const { packageId, packageVersionId } = workflow;
          try {
            await Promise.all([
              this.getContainer(PackageHelperModel).actions.getById.dispatch({
                packageId,
              }),
              this.getContainer(
                PackageVersionHelperModel
              ).actions.getVersionDetail.dispatch({
                packageId,
                versionId: packageVersionId,
              }),
            ]);
          } catch (error) {
            handleErrorNotFound(error);
          }
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
