// eslint-disable-next-line import/no-anonymous-default-export
export default {
  role: {
    name: "角色名称",
    introduction:
      "角色管理用于新建各类角色，可根据实际需要配置各角色访问及操作权限",
    entityTypeName: "角色",
    search: "权限名称/菜单名称",
    permission: "权限",
    createRole: "创建角色",
    editRole: "编辑角色",
    manageRole: "角色管理",
    create: "新建",
    cancel: "取消",
    confirm: "确定",
    reset: "重置",
    delete: "删除",
    edit: "编辑",
    view: "查看",
    tip: "提示",
    countersign: "确定删除角色",
    countersignDescription: "删除后已经使用该角色的用户相关权限将会失效",
    buildIn: "内置角色",
    customized: "自定义角色",
    columns: {
      name: "角色名称",
      roleFrom: "角色类型",
      description: "角色备注",
      createdAt: "创建时间",
      operation: "操作",
    },
    updateSuccess: "修改成功",
    updateError: "修改失败",
    createSuccess: "新建成功",
    createError: "新建失败",
    empty: "当前选中目标内容为空",
    customPermissionMiddleware: {
      basic: "基础信息",
      permission: "权限配置",
      inputName: "请输入自定义权限模板名称",
      remark: "备注",
      remarks: "备注",
      departmentAuthority: "部门级权限配置",
      departmentAuthorityTip: "提示：部门级功能仅在该角色被分配的部门中生效。",
      companyAuthority: "公司级权限配置",
      companyAuthorityTip: "提示：公司级功能将在公司所有部门中生效。",
      clear: "清空",
      saveContent: "保存的内容",
      saveNameEmptyError: "您必须为该角色定制一个名称",
      saveNameRegExpError: "该角色名称存在非法字符",
      saveNameLengthError: "请确保名称长度小于15个字符",
      savePermissionError: "您必须为该角色选择至少一个权限",
    },
    permissionTableComponent: {
      resource: "资源",
      permission: "权限",
    },
    query: {
      roleFrom: "全部角色类型",
      query: "查询",
    },
  },
};
