// eslint-disable-next-line import/no-anonymous-default-export
export default {
  asset: {
    searchParams: "Search Parameter",
    introduction:
      "Asset Management is mainly used to define various parameters and account password credentials, which supports encrypted storage and invocation during flow execution.",
    paramsName: "Parameter Name",
    assetType: "Asset Type",
    storageType: "Storage Method",
    assetValue: "Asset Value",
    assetName: "Asset Name",
    userName: "User Name",
    password: "Password",
    stringValue: "String value",
    assetStorageType: "Asset Storage Method",
    encryptMode: "Storage Algorithm Type",
    booleanValue: "Boolean value",
    passwordOfAccountCertificate: "Account Credential Password",
    string: "String",
    integer: "Integer type",
    float: "Floating-point type",
    booleanType: "Boolean type",
    ordinaryStorage: "General Storage",
    encryptedStorage: "Encrypted Storage",
    encryptedType: {
      default: "Default Cryptography Scheme",
      guomi: "Commercial Cryptography Scheme",
    },
    true: "Yes",
    false: "No",
    value: "Value",
    validation: {
      float: "Please enter floating-point data",
      int: "Please enter integer data",
    },
    tip: {
      userName:
        "Tips: If you change the user name, you need to fill in the password again.",
      blank:
        "Tips: Leaving the field empty when submitting means that no changes will be made to this field.",
    },
  },
};
