import { AuditlogEntityModel } from "src/store/models/entity/auditlog/entity";
import { AuditlogHelperModel } from "src/store/models/entity/auditlog/helper";
import { AuditlogListModel } from "src/store/models/entity/auditlog/list";
import { AuditlogListV2Model } from "src/store/models/entity/auditlog/listV2";

export const encooAuditlogModels = {
  entity: AuditlogEntityModel,
  helper: AuditlogHelperModel,
  list: AuditlogListModel,
  listV2: AuditlogListV2Model,
};
