/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Badge, Tag, Tooltip } from "antd";
import React, { ReactNode, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import Button from "src/components/Button";
import Icon from "src/components/Icon";
import { useFormatMessage, useGetContainer, useTheme } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { CompanyListModel } from "src/store/models/entity/company/list";
import { GlobalSelectCompanyUIModel } from "src/store/models/logic/globalSelectCompany";
import { UserTenantUIModel } from "src/store/models/ui/tenant/userTenant";

export const useCompanyEditionState = function (): Record<
  "Enterprise" | "CommunityFree" | "CommunityMajor",
  | {
      text: string;
      dom: ReactNode;
    }
  | undefined
> {
  const formatMessage = useFormatMessage();
  const theme = useTheme();

  const getDomRender = useCallback((name, color) => {
    return (
      <Tag
        css={css`
          line-height: initial;
        `}
        color={color}
      >
        {name}
      </Tag>
    );
  }, []);

  return useMemo(
    () => ({
      Enterprise: {
        text: formatMessage(messageIds.userEdition.enterprise),
        dom: getDomRender(
          formatMessage(messageIds.userEdition.enterprise),
          theme.buttonSpecial
        ),
      },
      CommunityFree: {
        text: formatMessage(messageIds.userEdition.community),
        dom: getDomRender(
          formatMessage(messageIds.userEdition.community),
          theme.rownColor
        ),
      },
      CommunityMajor: {
        text: formatMessage(messageIds.userEdition.communityMajor),
        dom: getDomRender(
          formatMessage(messageIds.userEdition.communityMajor),
          theme.warningText
        ),
      },
    }),
    [
      formatMessage,
      getDomRender,
      theme.buttonSpecial,
      theme.rownColor,
      theme.warningText,
    ]
  );
};

export type CompanyItemType = "card" | "item";
export type CompanyType = "company" | "tenant";
export const CompanyItem = React.memo(function (props: {
  name: string;
  userDisplayName?: string;
  id: string;
  edition: ReactNode;
  type?: CompanyItemType;
  companyType?: CompanyType;
  originPathname?: string;
}) {
  const formatMessage = useFormatMessage();
  const theme = useTheme();
  const getContainer = useGetContainer();
  const globalSelectCompanyContainer = getContainer(GlobalSelectCompanyUIModel);
  const userTenantUIContainer = getContainer(UserTenantUIModel);

  const selectedGlobalComponyId = useSelector(
    () => globalSelectCompanyContainer.state.selectedGlobalComponyId
  );

  const {
    name,
    id,
    edition,
    userDisplayName,
    type = "card",
    companyType = "company",
    originPathname,
  } = props;

  const selectCompany = useCallback(() => {
    if (companyType === "company") {
      globalSelectCompanyContainer.actions.switchCompany.dispatch({
        id,
        originPathname,
      });
    } else {
      userTenantUIContainer.actions.switchUserTenant.dispatch({
        id,
        originPathname,
      });
    }
  }, [
    companyType,
    globalSelectCompanyContainer.actions.switchCompany,
    id,
    originPathname,
    userTenantUIContainer.actions.switchUserTenant,
  ]);

  return type === "card" ? (
    <div
      key={id}
      css={css`
        box-shadow: 0px 0px 26px 0px ${theme.boxShadowColor};
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        min-height: 80px;
        margin-bottom: 20px;
      `}
    >
      <div>
        <span
          css={css`
            margin-right: 10px;
            color: ${theme.bodyText};
          `}
        >
          {name}
        </span>
        {edition}
        <br />
        <span
          css={css`
            font-size: 12px;
            color: ${theme.strongText};
            font-weight: 600;
          `}
        >
          {userDisplayName}
        </span>
      </div>
      <div>
        <Button type="primary" size="small" onClick={selectCompany}>
          {formatMessage(messageIds.company.enterInto)}
        </Button>
      </div>
    </div>
  ) : (
    <Tooltip title={name}>
      <div
        key={id}
        onClick={selectCompany}
        css={css`
          display: flex;
          align-items: center;
          padding: 3px 10px;
          font-size: 12px;
          color: ${theme.bodySubtext};
          cursor: pointer;
          &:hover {
            background-color: ${theme.lightPrimaryColor};
          }
        `}
      >
        <Badge
          status="default"
          text={name}
          css={css`
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          `}
        />
        {id === selectedGlobalComponyId && (
          <Icon
            name="icon-check"
            css={css`
              font-size: 8px;
              color: ${theme.primaryColor};
            `}
          />
        )}
      </div>
    </Tooltip>
  );
});

export default React.memo(function CompanySelect(props: {
  itemType: CompanyItemType;
  originPathname?: string;
}) {
  const companyEditionState = useCompanyEditionState();
  const getContainer = useGetContainer();
  const companyListContainer = getContainer(CompanyListModel);

  const { itemType, originPathname } = props;

  const companyList = useSelector(
    () => companyListContainer.getters.dataSource
  );

  return (
    <div>
      {companyList.map((item) => {
        const { id, name, edition, isFree, companyUserName } = item;
        const editionType =
          edition === "Community"
            ? isFree
              ? "CommunityFree"
              : "CommunityMajor"
            : "Enterprise";
        return (
          <CompanyItem
            id={id}
            name={name}
            edition={companyEditionState[editionType]?.dom}
            key={id}
            type={itemType}
            companyType="company"
            userDisplayName={companyUserName}
            originPathname={originPathname}
          />
        );
      })}
    </div>
  );
});
