import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import {
  Device,
  DeviceQuery,
  DeviceResourcesAjax,
  DeviceRobot,
} from "src/models/device";
import { EncooListEntity } from "src/models/_shared";

export const DEVICE_SERVICE_NAME = "device";

export class DeviceServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, DEVICE_SERVICE_NAME);
  }

  public async list(
    departmentId: string,
    pageIndex: number,
    pageSize: number,
    query?: DeviceQuery
  ): Promise<EncooListEntity<Device>> {
    const req = this.buildRequestV2({
      url: `/v2/devices`,
      method: "GET",
      query: {
        searchString: query?.searchString,
        pageIndex,
        pageSize,
      },
      departmentId,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  public async getById(id: string): Promise<Device> {
    const req = this.buildRequestV2({
      url: `/v2/devices/${id}`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  public async update(
    id: string,
    payload: { deviceTags: string[] }
  ): Promise<Device> {
    const req = this.buildRequestV2({
      url: `/v2/devices/${id}`,
      method: "PATCH",
      payload: {
        deviceTags: payload.deviceTags.join(","),
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getRemoteDesktop(robotId: string): Promise<string> {
    const req = this.buildRequestV2({
      url: `/v2/devices/${robotId}/remotedesktop`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body.url;
  }

  async getDeviceRobots(
    deviceId: string
  ): Promise<EncooListEntity<DeviceRobot>> {
    const req = this.buildRequestV2({
      url: `/v2/devices/${deviceId}/robots`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getDeviceResources(deviceId: string): Promise<DeviceResourcesAjax[]> {
    const req = this.buildRequestV2({
      url: `/v2/devices/${deviceId}/hardwarestatus`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body?.lines ?? [];
  }
}
