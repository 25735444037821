import { Theme, ThemeSet } from "src/styles/theme";

const main: Theme = {
  primaryColor: "#3377ff",
  lightBlue: "#5b8ff9",
  lightPrimaryColor: "#EDF2FB",
  defaultColor: "#8F9BB3",
  successColor: "#39bd65",
  rownColor: "#ffa033",
  waitingColor: "#FADB14",
  warningColor: "#f05656",
  errorColor: "#e03200",
  createColor: "#ffba00",
  runningColor: "#39bd65",
  failureColor: "#E03200",
  cancelColor: "#ccddff",
  pausedColor: "#FFA033",
  shelveColor: "#CDDDFF",
  closedColor: "#3D4966",
  cancellingColor: "#ccddee",
  currentSignBackgroundColor: "#ff5503",
  statusEnabledColor: "#39bd65",
  succuessText: "#5C9970",
  warningText: "#ab9467",
  errorText: "#cc6666",
  infoText: "#6699cc",
  strongText: "#1f2e4d",
  statusDisabledColor: "#d3dbeb",
  shadowColor: "rgba(0, 0, 0, 0.15)",

  defaultRadioBackground: "#f7f9fc",

  primaryRadioText: "#3D4966",
  primaryRadioCheckedBorder: "#84adff",
  primaryRadioCheckedText: "#3d4966",
  primaryRadioCheckedBackground: "#e4ebfa",

  disabledRadioBorder: "#f7faff",
  disabledRadioText: "#8f9bb3",

  disabledRadioButtonBorder: "#8f9bb3",

  inputBorder: "#d3dbeb",
  inputFocusShadow: "rgba(51,119,255,0.2)",
  inputDangerFocusShadowColor: "rgba(240,86,86,0.2)",

  bodyText: "#3d4966",
  bodySubtext: "#8f9bb3",
  bodyDivider: "#EBEBEB",
  bodyBackground: "#ffffff",
  bodyFrameBackground: "#f4f5f9",
  bodyFrameDivider: "#dadee6",
  bodyStandoutBackground: "#f7f9fa",
  bodyFrameShadowColor: "rgba(61,73,102,0.2)",

  canvasBackground: "#F7F9FC",
  canvasPageControlText: "#fff",
  canvasPageControlBackground: "rgba(0,0,0,0.4)",
  canvasPageControlBackgroundDisabled: "rgba(0,0,0,0.2)",
  canvasPageControlBackgroundHovered: "rgba(0,0,0,0.3)",
  rectBackground: "rgba(0,0,0,0.2)",
  rectBorder: "rgba(0,0,0,0.3)",
  rectSelectedBackground: "rgba(51,119,255,0.2)",

  contentShadowColor: "rgba(61,73,102,0.1)",
  boxShadowColor: "rgba(139,139,139,0.1)",
  buttonText: "#3D4966",
  buttonSpecial: "#8543e0",
  buttonBackground: "#ffffff",
  buttonBackgroundHovered: "#F4F5F9",
  buttonBackgroundChecked: "#edebe9",
  buttonBorder: "#D3DBEB",
  buttonTextDisabled: "#8F9BB3",

  tableDivider: "#edf2fb",
  tableBackground: "#f7faff",

  menuHoverBackground: "#5b92ff",
  menuSelectedBackground: "#84adff",

  primaryButtonText: "#ffffff",
  primaryButtonBackground: "#2563EB",
  primaryButtonBackgroundHovered: "#3E75ED",
  primaryButtonBackgroundActived: "#2158D3",
  primaryButtonBackgroundFocused: "#2563EB",
  primaryButtonBorderFocused: "#789FF3",
  primaryButtonBackgroundDisabled: "#8F9BB3",

  primaryButtonBackgroundChecked: "#3d6dcc",

  inputStandoutBackground: "#e6e9f0",

  dashboard: {
    robotPieColor: {
      Ready: "#5B8FF9",
      Busy: "#FFA033",
      Disconnected: "#F05656",
      Unlicensed: "#8F9BB3",
    },
    titleLabelColor: "#FFA033",
    cardBoxShadow: "rgba(0, 0, 0, 0.03)",
    bodyBackground: "#f7f9fc",
    robotDashboard: {
      titleTipColor: {
        totalInfoColor: "#FF5503",
        todayAddedColor: "#4E33FF",
      },
      robotBusyRate: ["#B0D0FF", "#0C5AF9", "#EEF1F6"],
      busyTimeTreeMap: [
        "#6395F9",
        "#85CCFE",
        "#78E4E4",
        "#657798",
        "#62DAAB",
        "#78E4E4",
        "#BDE8FF",
        "#94E2A6",
        "#BDF5C7",
        "#AFF5F5",
      ],
      // robotFailRoseColor: [
      //   "#5B8FF9",
      //   "#CDDDFD",
      //   "#5AD8A6",
      //   "#CDF3E4",
      //   "#5D7092",
      //   "#CED4DE",
      //   "#F6BD16",
      //   "#FCEBB9",
      //   "#E86452",
      //   "#F8D0CB",
      //   "#6DC8EC",
      // ],
      robotTaskExecutionColor: [
        "#5B8FF9",
        "#CDDDFD",
        "#5AD8A6",
        "#CDF3E4",
        "#5D7092",
        "#CED4DE",
        "#F6BD16",
        "#FCEBB9",
        "#E86452",
        "#F8D0CB",
      ],
      robotSuccessRoseColor: [
        "#F0F5FF",
        "#D6E4FF",
        "#ADC6FF",
        "#85A5FF",
        "#597EF7",
        "#4869EF",
        "#2F54EB",
        "#1D39C4",
        "#10239E",
        "#061178",
        "#030852",
      ],
      robotFailRoseColor: [
        "#FFCCC7",
        "#FFA39E",
        "#FF7875",
        "#FF5F61",
        "#FF4D4F",
        "#F5222D",
        "#DE1F29",
        "#CF1322",
        "#A8071A",
        "#820014",
        "#5C0011",
      ],
      licensePieColor: {
        serverLicensed: "#6395F9",
        unlicensed: "#657798",
      },
      robotStatePieColor: {
        disconnected: "#BDE8FF",
        busy: "#78E4E4",
        ready: "#85CCFE",
      },
      robotJobStateColor: {
        Queued: "#6F5EF9",
        Allocated: "#78E4E4",
        Running: "#85CCFE",
        Failed: "#657798",
        Succeeded: "#62DAAB",
        Cancelling: "#FCEBB9",
        Cancelled: "#F6BD16",
        Paused: "#78E4E4",
      },
    },
    queueDashboard: {
      queueTaskExecutionColor: [
        "#5B8FF9",
        "#CDDDFD",
        "#5AD8A6",
        "#CDF3E4",
        "#5D7092",
        "#CED4DE",
        "#F6BD16",
        "#FCEBB9",
        "#E86452",
        "#F8D0CB",
      ],
      queueSuccessRateColor: [
        "#F0F5FF",
        "#D6E4FF",
        "#ADC6FF",
        "#85A5FF",
        "#597EF7",
        "#4869EF",
        "#2F54EB",
        "#1D39C4",
        "#10239E",
        "#061178",
        "#030852",
      ],
      queueFailRateColor: [
        "#FFCCC7",
        "#FFA39E",
        "#FF7875",
        "#FF5F61",
        "#FF4D4F",
        "#F5222D",
        "#DE1F29",
        "#CF1322",
        "#A8071A",
        "#820014",
        "#5C0011",
      ],
      queueJobStateColor: {
        Queued: "#6F5EF9",
        Allocated: "#78E4E4",
        Running: "#85CCFE",
        Failed: "#657798",
        Succeeded: "#62DAAB",
        Cancelling: "#FCEBB9",
        Cancelled: "#F6BD16",
        Paused: "#78E4E4",
      },
    },
  },

  module: {
    package: {
      logoBackground: ["#2BE3E3", "#13C2C2"],
    },
    workflow: {
      logoBackground: ["#FFBD00", "#FF8800"],
    },
    queue: {
      logoBackground: ["#BB78F2", "#8543E0"],
    },
  },

  component: {
    tipBox: {
      color: "#6699cc",
      background: "#f2f9ff",
      border: " #e1edfa",
    },
    tab: {
      backgroundColor: "#84ADFF",
      color: "#FFFFFF",
    },
  },
  apps: {
    appDev: {
      card: {
        editButtonBg: "#1890ff",
        editButtonColor: "#fff",
        color: "#8f9bb3",
        titleColor: "#3e434d",
      },
    },
  },
};

export const lightThemeSet: ThemeSet = {
  main,
  header: {
    ...main,
    bodyBackground: "#ffffff",
    bodyFrameShadowColor: "rgba(143,155,179,0.2)",

    buttonText: "#ffffff",
    buttonBackground: "#0042ac",
    buttonBackgroundHovered: "#1a55b4",
    buttonBackgroundChecked: "#265eb8",
  },
  sidebar: {
    ...main,
    bodyBackground: "#ffffff",

    bodyDivider: "#EBEBEB",
    bodyFrameBackground: "#F8F9FA",
    menuHoverBackground: "#3E75ED",
  },
};
