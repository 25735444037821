import { message } from "antd";
import { createModel } from "nyax";
import messageIds from "src/locales/messageIds";
import { EncooFileUploadCallbackInfo } from "src/models/file";
import { filterNonNullish } from "src/shared/array";
import { ModelBase } from "src/store/ModelBase";
import { CoeDepartmentUserEntityModel } from "src/store/models/entity/coeDepartmentUser/entity";
import { CoeDepartmentUserHelperModel } from "src/store/models/entity/coeDepartmentUser/helper";
import { CoeMyWorkHelperModel } from "src/store/models/entity/coeMyWork/helper";
import { CoeWorkMagerEntityModel } from "src/store/models/entity/coeWorkManger/entity";
import { CoeWorkMagerHelperModel } from "src/store/models/entity/coeWorkManger/helper";
import { CompanyPackageEntityModel } from "src/store/models/entity/companyPackage/entity";
import { CompanyPackageHelperModel } from "src/store/models/entity/companyPackage/helper";
import { UploadingUIModel } from "src/store/models/ui/file/uploading";

export const CoeWorkerUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        isInitialized: false,
        uploadingFileRecord: {} as Record<string, EncooFileUploadCallbackInfo>,
      };
    }
    public reducers() {
      return {
        setIsInitialized: (isInitialized: boolean) => {
          this.state.isInitialized = isInitialized;
        },
      };
    }
    public selectors() {
      return {
        mangers: () => this.getContainer(CoeWorkMagerEntityModel).getters.items,
        mangerById: () => this.getContainer(CoeWorkMagerEntityModel).state.byId,
        packages: () =>
          this.getContainer(CompanyPackageEntityModel).getters.items,
        packageById: () =>
          this.getContainer(CompanyPackageEntityModel).state.byId,
        departmentUserById: () =>
          this.getContainer(CoeDepartmentUserEntityModel).state.byId,
      };
    }
    public effects() {
      return {
        initialize: async (force?: boolean) => {
          if (!this.state.isInitialized || force) {
            this.actions.setIsInitialized.dispatch(true);
            await this.getContainer(
              CoeWorkMagerHelperModel
            ).actions.getAll.dispatch({});
            await this.getContainer(
              CoeDepartmentUserHelperModel
            ).actions.getAll.dispatch({});
            await this.getContainer(
              CompanyPackageHelperModel
            ).actions.getAll.dispatch({});
          }
        },

        upload: async (payload: { files: File[] }) => {
          const { files } = payload;
          const uploadingFileRecord =
            this.getContainer(UploadingUIModel).state.uploadingFileRecord;

          const foundUploading = files.find(
            (file) =>
              uploadingFileRecord[file.name] &&
              uploadingFileRecord[file.name].state !== "Completed" &&
              uploadingFileRecord[file.name].state !== "Failed"
          );

          if (foundUploading) {
            message.error(
              this.dependencies.locale.intl.formatMessage({
                id: messageIds.package.sameName,
              })
            );
            return;
          }

          return filterNonNullish(
            (await Promise.all(
              files.map(async (file) => {
                return await this.getContainer(
                  CoeMyWorkHelperModel
                ).actions.upload.dispatch({
                  file: file,
                  processCallback: async (info) => {
                    await this.getContainer(
                      UploadingUIModel
                    ).actions.updateUploadCallbackInfo.dispatch(info);
                  },
                });
              })
            )) ?? []
          );
        },
      };
    }
  }
);
