import { createModel, GetContainer } from "nyax";
import { AppV2 } from "src/models/appV2";
import { AppListModel } from "src/store/models/entity/apps/list";
import { createViCodeAppPaginationBaseModal } from "src/store/models/entity/vicodeApp/_shared";
import { ViCodeAppHelperModel } from "src/store/models/entity/vicodeApp/helper";
import { ViCodeAppListModel } from "src/store/models/entity/vicodeApp/list";
import {
  ViCodeAppCreationData,
  ViCodeAppWithPermission,
} from "src/store/models/ui/vicodeApp/_shared";

export const UIViCodeAppAppModel = createModel(
  class extends createViCodeAppPaginationBaseModal<
    ViCodeAppWithPermission,
    AppV2
  >({
    getList1Pagination: (getContainer: GetContainer) =>
      getContainer(ViCodeAppListModel).getters.pagination,
    getList2Pagination: (getContainer: GetContainer) =>
      getContainer(AppListModel).getters.pagination,

    getDataSource1: (getContainer: GetContainer) =>
      getContainer(ViCodeAppListModel).getters.dataSourceWithPermission,
    getDataSource2: (getContainer: GetContainer) =>
      getContainer(AppListModel).getters.dataSourceWithPermission,

    setPageSizeByList2: async (
      getContainer: GetContainer,
      pageSize: number
    ) => {
      await getContainer(AppListModel).actions.setPageSize.dispatch(pageSize);
    },
  }) {
    private get _appV1ListContainer() {
      return this.getContainer(AppListModel);
    }

    private get _vicodeAppListContainer() {
      return this.getContainer(ViCodeAppListModel);
    }

    public initialState() {
      return {
        ...super.initialState(),
        isCreationModalVisible: false,
        isViCodeAppTab: false,
      };
    }

    public reducers() {
      return {
        ...super.reducers(),

        setIsCreationModalVisible: (value: boolean) => {
          this.state.isCreationModalVisible = value;
        },
        setIsViCodeAppTab: (value: boolean) => {
          this.state.isViCodeAppTab = value;
        },
      };
    }
    public selectors() {
      return {
        ...super.selectors(),
      };
    }

    public effects() {
      return {
        ...super.effects(),

        initialize: async () => {
          if (this.state.isViCodeAppTab) {
            await this._vicodeAppListContainer.actions.initial.dispatch({});
          } else {
            await this._appV1ListContainer.actions.initial.dispatch({});
          }
        },

        create: async (payload: ViCodeAppCreationData) => {
          const app = await this.getContainer(
            ViCodeAppHelperModel
          ).actions.create.dispatch(payload);
          await this.actions.initialize.dispatch({});
          return app;
        },

        copy: async (payload: { appId: string }) => {
          const { appId } = payload;
          await this.getContainer(ViCodeAppHelperModel).actions.copy.dispatch({
            appId,
          });
          await this.actions.initialize.dispatch({});
        },
      };
    }
  }
);
