import { createModel } from "nyax";
import {
  NodeErrorHandlingModel,
  WorkflowNode,
} from "src/models/workflowLayout";
import { ModelBase } from "src/store/ModelBase";

export const WorkflowLayoutNodesEditorUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        name: "" as string,
        nodeErrorHandlingModel: "Break" as NodeErrorHandlingModel,
        tags: [] as string[],
        description: null as string | null,
        nodes: [] as WorkflowNode[],
        isEdit: false,
        isInEditPage: false,
      };
    }

    public reducers() {
      return {
        setName: (value: string) => {
          this.state.name = value;
        },
        setNodeErrorHandlingModel: (value: NodeErrorHandlingModel) => {
          this.state.nodeErrorHandlingModel = value;
        },
        setTags: (value: string[]) => {
          this.state.tags = value;
        },
        setDescription: (value: string | null) => {
          this.state.description = value;
        },
        setNodes: (value: WorkflowNode[]) => {
          this.state.nodes = value;
        },

        setIsEdit: (value: boolean) => {
          this.state.isEdit = value;
        },
        setIsInEditPage: (value: boolean) => {
          this.state.isInEditPage = value;
        },
      };
    }

    public effects() {
      return {
        ...super.effects(),
        initial: async () => {
          await this.actions.setName.dispatch("");
          await this.actions.setTags.dispatch([]);
          await this.actions.setDescription.dispatch("");
          await this.actions.setNodes.dispatch([]);
          await this.actions.setIsEdit.dispatch(false);
        },
      };
    }
  }
);
