import { ConvertRouteInfo, createRouteDefinition } from "src/routes/_shared";

export const appRouteDefinitions = {
  paymentOrder: createRouteDefinition({
    type: "paymentOrder",
    path: "/payment/order/:id",
    component: "./payment/order/PaymentOrderWrapper",
    defaultParams: { id: "" },
  }),
  paymentProduct: createRouteDefinition({
    type: "paymentProduct",
    path: "/payment/product",
    component: "./payment/product/PaymentProductWrapper",
    defaultParams: {},
  }),
  beConfirmedProduct: createRouteDefinition({
    type: "confirmedProduct",
    path: "/payment/confirm/:id",
    component: "./payment/confirm/PaymentConfirmWrapper",
    defaultParams: {
      id: "",
    },
  }),
  renewOrder: createRouteDefinition({
    type: "renewOrder",
    path: "/payment/renew/:id",
    component: "./payment/renew/PaymentRenewWrapper",
    defaultParams: {
      id: "",
    },
  }),
  coeToDoWork: createRouteDefinition({
    type: "coeToDoWork",
    path: "/coeToDoWork",
    component: "./coeToDoWork/CoeToDoWorkTable",
    defaultParams: {},
    permissions: "COE_Manage",
    showPermissions: "COE_Manage",
  }),
  coeToDoWorkDetail: createRouteDefinition({
    type: "coeToDoWorkDetail",
    path: "/coeToDoWorkDetail/:id",
    component: "./coeToDoWork/CoeToDoWorkWrapper",
    defaultParams: {
      id: "",
      activeKey: "",
    },
    permissions: "COE_Manage",
    showPermissions: "COE_Manage",
  }),
  coeMyWork: createRouteDefinition({
    type: "coeMyWork",
    path: "/coeMyWork",
    component: "./coeMyWork/CoeMyWorkTable",
    defaultParams: {},
    permissions: "COE_Invoke",
    showPermissions: "COE_Invoke",
  }),
  coeMyWorkDetail: createRouteDefinition({
    type: "coeMyWorkDetail",
    path: "/coeMyWorkDetail/:id",
    component: "./coeMyWork/CoeMyWorkWrapper",
    defaultParams: {
      id: "",
      activeKey: "",
    },
    permissions: "COE_Invoke",
    showPermissions: "COE_Invoke",
  }),
  coeDataAnalysis: createRouteDefinition({
    type: "coeDataAnalysis",
    path: "/coeDataAnalysis",
    component: "./coeDataAnalysis/CoeDataAnalysis",
    defaultParams: {},
    permissions: "COE_Manage",
    showPermissions: "COE_Manage",
  }),
  calendars: createRouteDefinition({
    type: "calendars",
    path: "/calendars",
    component: "./calendars/Calendars",
    defaultParams: {},
    permissions: "Calendar_Read",
    showPermissions: "Calendar_Read",
  }),
  calendarsDetail: createRouteDefinition({
    type: "calendarsDetail",
    path: "/calendarsDetail/:id",
    component: "./calendars/CalendarsInfoWrapper",
    defaultParams: {
      id: "",
      activeKey: "",
    },
    permissions: "Calendar_Read",
    showPermissions: "Calendar_Read",
  }),
  dataTable: createRouteDefinition({
    type: "dataTable",
    path: "/dataTable",
    component: "./dataTable/DataTableTable",
    defaultParams: {},
    permissions: "Table_Read", //TODO
    showPermissions: "Table_Read",
  }),
  dataTableDetail: createRouteDefinition({
    type: "dataTableDetail",
    path: "/dataTableDetail/:id",
    component: "./dataTable/DataTableInfoWrapper",
    defaultParams: {
      id: "",
      activeKey: "",
    },
    permissions: "Table_Read", //TODO
    showPermissions: "Table_Read",
  }),
  workflowLayout: createRouteDefinition({
    type: "workflowLayout",
    path: "/workflowLayout",
    component: "./workflowLayout/WorkflowLayoutTable",
    defaultParams: {},
    permissions: "WorkflowLayout_Read",
    showPermissions: "WorkflowLayout_Read",
  }),
  workflowLayoutDetail: createRouteDefinition({
    type: "workflowLayoutDetail",
    path: "/workflowLayoutDetail/:id",
    component: "./workflowLayout/WorkflowLayoutInfoWrapper",
    defaultParams: {
      id: "",
      activeKey: "",
    },
    permissions: "WorkflowLayout_Read",
    showPermissions: "WorkflowLayout_Read",
  }),
  workflowLayoutNodesEditor: createRouteDefinition({
    type: "workflowLayoutNodesEditor",
    path: "/workflowLayoutNodesEditor/:id",
    component: "./workflowLayout/WorkflowLayoutNodesEditor",
    defaultParams: {
      id: "",
    },
    permissions: "WorkflowLayout_Read",
    showPermissions: "WorkflowLayout_Read",
  }),
  workflowLayoutTriggerDetail: createRouteDefinition({
    type: "workflowLayoutTriggerDetail",
    path: "/workflowLayout/:workflowLayoutId/workflowLayoutTriggerDetail/:id",
    component: "./workflowLayoutTrigger/TriggerDetail",
    defaultParams: {
      id: "",
      workflowLayoutId: "",
    },
    permissions: "WorkflowLayout_Trigger_Read",
    showPermissions: "WorkflowLayout_Trigger_Read",
  }),
  aiManagement: createRouteDefinition({
    type: "aiManagement",
    path: "/aiManagement",
    component: "./aiManagement/AIManagement",
    defaultParams: {},
    permissions: "OCR_Manage",
    showPermissions: "OCR_Manage",
  }),
  ocr: createRouteDefinition({
    type: "ocr",
    path: "/ocr",
    component: "./ocr/OCRTable",
    defaultParams: {},
    permissions: "OCR_Invoke",
    showPermissions: "OCR_Invoke",
  }),
  ocrDetail: createRouteDefinition({
    type: "ocrDetail",
    path: "/ocrDetail/:id",
    component: "./ocr/OCRInfoWrapper",
    defaultParams: {
      id: "",
      alias: "",
    },
    permissions: "OCR_Invoke",
    showPermissions: "OCR_Invoke",
  }),
  knowledge: createRouteDefinition({
    type: "knowledge",
    path: "/knowledge",
    component: "./lamoss/knowledge/KnowledgeTable",
    defaultParams: {},
    permissions: "Lamoss_Manage",
    showPermissions: "Lamoss_Manage",
  }),
  knowledgeDetail: createRouteDefinition({
    type: "knowledgeDetail",
    path: "/knowledgeDetail/:id",
    component: "./lamoss/knowledge/KnowledgeInfoWrapper",
    defaultParams: {
      id: "",
      activeKey: "",
    },
    permissions: "Lamoss_Manage",
    showPermissions: "Lamoss_Manage",
  }),
  manage: createRouteDefinition({
    type: "manage",
    path: "/lamossManager",
    component: "./lamoss/manage/ManageWrapper",
    defaultParams: {},
    permissions: "Lamoss_Manage",
    showPermissions: "Lamoss_Manage",
  }),
  application: createRouteDefinition({
    type: "application",
    path: "/application",
    component: "./lamoss/application/ApplicationTable",
    defaultParams: {},
    permissions: "Lamoss_Manage",
    showPermissions: "Lamoss_Manage",
  }),
  applicationDetail: createRouteDefinition({
    type: "applicationDetail",
    path: "/applicationDetail/:id",
    component: "./lamoss/application/ApplicationInfoWrapper",
    defaultParams: {
      id: "",
      activeKey: "",
    },
    permissions: "Lamoss_Manage",
    showPermissions: "Lamoss_Manage",
  }),
  index: createRouteDefinition({
    type: "index",
    path: "/",
    component: "./home/Home",
    defaultParams: {},
  }),
  companyEntrySelect: createRouteDefinition({
    type: "companyEntrySelect",
    path: "/companyEntrySelect",
    component: "./company/CompanyEntrySelect",
    defaultParams: {},
  }),
  organization: createRouteDefinition({
    type: "organization",
    path: "/organization/:id",
    component: "./organization/department/DepartmentInfoWrapper",
    defaultParams: {
      id: "",
      activeKey: "",
    },
    permissions: "Department_Read",
    showPermissions: "Department_Read",
  }),
  roleManager: createRouteDefinition({
    type: "roleManager",
    path: "/roleManager",
    component: "./roleManager/RoleManager",
    defaultParams: {},
    permissions: "Role_Read",
    showPermissions: "Role_Read",
  }),
  userManager: createRouteDefinition({
    type: "userManager",
    path: "/userManager",
    component: "./userManager/UserManagerPage",
    defaultParams: {},
    permissions: "CompanyUser_Read",
    showPermissions: "CompanyUser_Read",
  }),
  home: createRouteDefinition({
    type: "index",
    path: "/index",
    component: "./home/Home",
    defaultParams: {},
  }),
  app: createRouteDefinition({
    type: "app",
    path: "/app",
    component: "./vicodeApp/ViCodeApp",
    defaultParams: {},
    permissions: "App_Read",
    showPermissions: "App_Read",
  }),
  appDetail: createRouteDefinition({
    type: "appDetail",
    path: "/appDetail/:id",
    component: "./vicodeApp/ViCodeAppDetail",
    defaultParams: {
      id: "",
      appV2: "",
      isViCodeApp: "",
    },
    permissions: "App_Read",
    showPermissions: "App_Read",
  }),
  liveApp: createRouteDefinition({
    type: "liveApp",
    path: "/liveApp",
    component: "./vicodeApp/ViCodeAppLiveApp",
    defaultParams: {},
    permissions: "MyApp_Read",
    showPermissions: "MyApp_Read",
  }),
  market: createRouteDefinition({
    type: "market",
    path: "/market",
    component: "./market/Market",
    defaultParams: {},
  }),
  package: createRouteDefinition({
    type: "package",
    path: "/package",
    component: "./package/Package",
    defaultParams: {},
    permissions: "Package_Read",
    showPermissions: "Package_Read",
  }),
  packageDetail: createRouteDefinition({
    type: "packageDetail",
    path: "/packageDetail/:id",
    component: "./package/PackageInfoWrapper",
    defaultParams: {
      id: "",
      activeKey: "",
    },
    permissions: "Package_Read",
    showPermissions: "Package_Read",
  }),

  workflow: createRouteDefinition({
    type: "workflow",
    path: "/workflow",
    component: "./workflow/WorkflowListPage",
    defaultParams: {},
    permissions: "Workflow_Read",
    showPermissions: "Workflow_Read",
  }),
  workflowDetail: createRouteDefinition({
    type: "workflowDetail",
    path: "/workflowDetail/:id",
    component: "./workflow/WorkflowInfoWrapper",
    defaultParams: {
      id: "",
      activeKey: "",
    },
    permissions: "Workflow_Read",
    showPermissions: "Workflow_Read",
  }),
  triggerDetail: createRouteDefinition({
    type: "triggerDetail",
    path: "/workflow/:workflowId/triggerDetail/:id",
    component: "./trigger/TriggerDetail",
    defaultParams: {
      id: "",
      workflowId: "",
    },
    permissions: "Trigger_Read",
    showPermissions: "Trigger_Read",
  }),
  job: createRouteDefinition({
    type: "job",
    path: "/job",
    component: "./job/Job",
    defaultParams: {},
    permissions: "Job_Read",
    showPermissions: "Job_Read",
  }),
  jobDetail: createRouteDefinition({
    type: "jobDetail",
    path: "/jobDetail/:id",
    component: "./job/JobDetail",
    defaultParams: {
      id: "",
    },
    permissions: "Job_Read",
    showPermissions: "Job_Read",
  }),
  runningMonitor: createRouteDefinition({
    type: "runningMonitor",
    path: "/runningMonitor",
    component: "./job/RunningMonitor",
    defaultParams: {
      packageId: "",
      versionId: "",
      runInstanceId: "",
    },
    permissions: ["Job_Read", "Package_Read"],
    showPermissions: ["Job_Read", "Package_Read"],
  }),
  runInstanceLog: createRouteDefinition({
    type: "runInstanceLog",
    path: "/job/:jobId/runInstanceLog/:id",
    component: "./runInstance/RunInstanceLog",
    defaultParams: {
      jobId: "",
      id: "",
    },
    permissions: "Runinstance_Read",
    showPermissions: "Runinstance_Read",
  }),
  robot: createRouteDefinition({
    type: "robot",
    path: "/robot",
    component: "./robot/Robot",
    defaultParams: {},
    permissions: "Robot_Read",
    showPermissions: "Robot_Read",
  }),
  robotDetail: createRouteDefinition({
    type: "robotDetail",
    path: "/robotDetail/:id",
    component: "./robot/RobotInfoWrapper",
    defaultParams: {
      id: "",
      activeKey: "",
    },
    permissions: "Robot_Read",
    showPermissions: "Robot_Read",
  }),
  jobQueue: createRouteDefinition({
    type: "jobQueue",
    path: "/jobQueue",
    component: "./jobQueue/JobQueue",
    defaultParams: {},
    permissions: "TaskQueue_Read",
    showPermissions: "TaskQueue_Read",
  }),
  jobQueueDetail: createRouteDefinition({
    type: "jobQueueDetail",
    path: "/jobQueue/:id",
    component: "./jobQueue/JobQueueInfoWrapper",
    defaultParams: {
      id: "",
      activeKey: "",
    },
    permissions: "TaskQueue_Read",
    showPermissions: "TaskQueue_Read",
  }),
  queue: createRouteDefinition({
    type: "queue",
    path: "/queue",
    component: "./queue/Queue",
    defaultParams: {},
    permissions: "Queue_Read",
    showPermissions: "Queue_Read",
  }),
  queueDetail: createRouteDefinition({
    type: "queueDetail",
    path: "/queue/:id",
    component: "./queue/QueueInfoWrapper",
    defaultParams: {
      id: "",
      activeKey: "",
    },
    permissions: "Queue_Read",
    showPermissions: "Queue_Read",
  }),
  template: createRouteDefinition({
    type: "template",
    path: "/template",
    component: "./understanding/Template",
    defaultParams: {},
    permissions: "DocReader_Read",
    showPermissions: "DocReader_Read",
  }),
  templateEditor: createRouteDefinition({
    type: "templateEditor",
    path: "/templateEditor/:id",
    component: "./understanding/TemplateEditor",
    defaultParams: {
      id: "",
    },
    permissions: "DocReader_Read",
    showPermissions: "DocReader_Read",
  }),
  templateTester: createRouteDefinition({
    type: "templateTester",
    path: "/TemplateTester/:id",
    component: "./understanding/TemplateTester",
    defaultParams: { id: "" },
    permissions: "DocReader_Read",
    showPermissions: "DocReader_Read",
  }),
  templatePermission: createRouteDefinition({
    type: "templatePermission",
    path: "/templatePermission/:id",
    component: "./understanding/TemplatePermission",
    defaultParams: {
      id: "",
    },
    permissions: "DocReader_Read",
    showPermissions: "DocReader_Read",
  }),
  systemSetting: createRouteDefinition({
    type: "systemSetting",
    path: "/systemSetting",
    component: "./settings/systemSetting/SystemSetting",
    defaultParams: {},
    permissions: "SystemSetting_Read",
    showPermissions: "SystemSetting_Read",
  }),
  auditlog: createRouteDefinition({
    type: "auditlog",
    path: "/setting/auditlog",
    component: "./settings/auditlog/Auditlog",
    defaultParams: {},
    permissions: "Audit_Read",
    showPermissions: "Audit_Read",
  }),
  license: createRouteDefinition({
    type: "license",
    path: "/license",
    component: "./license/License",
    defaultParams: {},
    permissions: "License_Read",
    showPermissions: "License_Read",
  }),
  dashboard: createRouteDefinition({
    type: "dashboard",
    path: "/dashboard",
    component: "./dashboard/Dashboard",
    defaultParams: {},
    permissions: "Dashboard_Total_Read",
    showPermissions: "Dashboard_Total_Read",
  }),
  robotRunStatistics: createRouteDefinition({
    type: "robotRunStatistics",
    path: "/robotRunStatistics",
    component: "./dashboard/RobotRunStatistics",
    defaultParams: {},
    permissions: "Dashboard_Executions_Read",
    showPermissions: "Dashboard_Executions_Read",
  }),
  robotStatusStatistics: createRouteDefinition({
    type: "robotStatusStatistics",
    path: "/robotStatusStatistics",
    component: "./dashboard/robotStatusStatistics/RobotStatusStatistics",
    defaultParams: {},
    permissions: "Dashboard_Robot_Read",
    showPermissions: "Dashboard_Robot_Read",
  }),
  queueStatistics: createRouteDefinition({
    type: "queueStatistics",
    path: "/queueStatistics",
    component: "./dashboard/queueStatistics/QueueStatistics",
    defaultParams: {},
    permissions: "Dashboard_Queues_Read",
    showPermissions: "Dashboard_Queues_Read",
  }),
  userFlowStatistics: createRouteDefinition({
    type: "userFlowStatistics",
    path: "/userFlowStatistics",
    component: "./dashboard/UserFlowStatistics",
    defaultParams: {},
    permissions: "Dashboard_User_Workflows_Read",
    showPermissions: "Dashboard_User_Workflows_Read",
  }),
  asset: createRouteDefinition({
    type: "asset",
    path: "/asset",
    component: "./asset/Asset",
    defaultParams: {},
    permissions: "Asset_Read",
    showPermissions: "Asset_Read",
  }),
  assetDetail: createRouteDefinition({
    type: "assetDetail",
    path: "/assetDetail/:id",
    component: "./asset/AssetItemWrapper",
    defaultParams: {
      id: "",
      activeKey: "",
    },
    permissions: "Asset_Read",
    showPermissions: "Asset_Read",
  }),
  transferToActivateUser: createRouteDefinition({
    type: "transferToActivateUser",
    path: "/transferToActivateUser",
    component: "./transferToActivateUser/TransferToActivateUser",
    defaultParams: {
      inviteCode: "",
    },
  }),

  file: createRouteDefinition({
    type: "file",
    path: "/file",
    component: "./file/File",
    defaultParams: {
      fullName: "",
    },
    permissions: "File_Read",
    showPermissions: "File_Read",
  }),

  dataConnection: createRouteDefinition({
    type: "dataConnection",
    path: "/dataConnection",
    component: "./dataConnection/DataConnectionPage",
    defaultParams: {},
    permissions: "DataConnection_Read",
    showPermissions: "DataConnection_Read",
  }),

  dataConnectionDetail: createRouteDefinition({
    type: "dataConnectionDetail",
    path: "/dataConnectionDetail/:id",
    component: "./dataConnection/DataConnectionItemWrapper",
    defaultParams: {
      id: "",
      activeKey: "",
    },
    permissions: "DataConnection_Read",
    showPermissions: "DataConnection_Read",
  }),

  dataQueue: createRouteDefinition({
    type: "dataQueue",
    path: "/dataQueue",
    component: "./dataQueue/DataQueue",
    defaultParams: {},
    permissions: "DataQueue_Read",
    showPermissions: "DataQueue_Read",
  }),
  dataQueueDetail: createRouteDefinition({
    type: "dataQueueDetail",
    path: "/dataQueueDetail/:id",
    component: "./dataQueue/DataQueueDetail",
    defaultParams: {
      id: "",
      activeKey: "",
    },
    permissions: "DataQueue_Read",
    showPermissions: "DataQueue_Read",
  }),
  device: createRouteDefinition({
    type: "device",
    path: "/device",
    component: "./device/Device",
    defaultParams: {},
    permissions: "Device_Read",
    showPermissions: "Device_Read",
  }),
  deviceDetail: createRouteDefinition({
    type: "deviceDetail",
    path: "/deviceDetail/:id",
    component: "./device/DeviceInfoWrapper",
    defaultParams: {
      id: "",
      activeKey: "",
    },
    permissions: "Device_Read",
    showPermissions: "Device_Read",
  }),
  discontinued: createRouteDefinition({
    type: "discontinued",
    path: "/discontinued",
    component: "./errors/Discontinued",
    defaultParams: {},
  }),
  "404": createRouteDefinition({
    type: "404",
    path: "/404",
    component: "./errors/NotFound",
    defaultParams: {},
  }),
  userCenter: createRouteDefinition({
    type: "userCenter",
    path: "/userCenter/:path",
    component: "./settings/userCenter/UserCenter",
    defaultParams: {
      path: "settings",
    },
  }),
};

export type AppRouteDefinitions = typeof appRouteDefinitions;
export type AppRouteInfo = {
  [K in keyof AppRouteDefinitions]: ConvertRouteInfo<AppRouteDefinitions[K]>;
}[keyof AppRouteDefinitions];
