import { ServiceClient } from "@encoo-web/encoo-lib";
import {
  License,
  LicenseBinding,
  LicenseBindingData,
} from "src/models/license";
import { EncooListEntity } from "src/models/_shared";

export const RPA_LICENSE_SERVICE_NAME = "rpaLicense";

export class RpaLicenseServiceServiceClient extends ServiceClient {
  public async getAllRpaLicense(
    available?: boolean | undefined
  ): Promise<License[]> {
    const req = this.buildRequestV2({
      url: `/v2/rpalicenses`,
      method: "GET",
      query: {
        pageSize: 1000,
        available,
      },
    });
    const { body } = await super.sendRequest(req);
    return body.list;
  }

  public async listRpaLicenseBindings(
    productName: string,
    sku: string,
    pageIndex: number,
    pageSize: number,
    binded?: boolean | undefined,
    available?: boolean | undefined
  ): Promise<EncooListEntity<LicenseBinding>> {
    const req = this.buildRequestV2({
      url: `/v2/rpalicensebindings`,
      method: "GET",
      query: {
        productName,
        sku,
        pageIndex,
        pageSize,
        binded,
        available,
      },
    });
    const { body } = await super.sendRequest(req);
    return body;
  }

  public async bindTo(bindData: LicenseBindingData): Promise<LicenseBinding> {
    const req = this.buildRequestV2({
      url: `/v2/rpalicensebindings/bindTo`,
      method: "PUT",
      payload: bindData,
    });
    return await super.createInternal(req);
  }

  public async unbind(boundToId: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/rpalicensebindings/bindTo/${boundToId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }
}
