// eslint-disable-next-line import/no-anonymous-default-export
export default {
  device: {
    introduction:
      "计算机管理主要用于管理机器人所在计算机状态、数据监控等，以便于更好的跟踪机器人情况。",
    deviceName: "计算机名称",
    robotNumber: "连接机器人数",
    ipAddress: "IP",
    operationSystem: "操作系统",
    hardwareUsage: "硬盘(剩余/总量)",
    processorName: "处理器",
    memoryUsage: "内存",
    runningTime: "运行时长",
    windowsUserName: "用户(计算机)",
  },
};
