// eslint-disable-next-line import/no-anonymous-default-export
export default {
  license: {
    entityTypeName: "License",
    introduction:
      "This page is used to display and manage the licensing and use of various licenses or services of the console.",
    switch: {
      desc: "仅显示有效期内的许可证",
      open: "开",
      close: "关",
    },
    tabs: {
      details: "Details",
      logs: "Active Log",
    },
    table: {
      id: "Encoding",
      user: "Current User",
      machineName: "Machine Name",
      name: "Robot Name",
      bindTime: "Binding Time",
      importTime: "Importing Time",
      importUser: "User Imported",
      authorizeDate: "Authorization Date",
      startToStopTime: "Start-Stop Time",
      licenseBindingNumber: "License Binding Number",
      emptyCase: {
        empty: "None ",
        data: "Data",
        active: "Activited",
        connect: "Connected",
        publish: "Published",
      },
    },
    licenseDetailModal: {
      applyType: "The Type of License",
      defaultApplyType: "Console License",
      contractNumber: "Contract Number",
      customerName: "Customer Name",
      validityPeriod: "Validity Period",
      uniqueCode: "Unique Code",
      quotaOfStudioInEnterpriseEdition:
        "Quota of Studios In Enterprise Edition",
      quotaOfStudioInFloatingEnterpriseEdition:
        "Quota of Studios In Enterprise Edition (Floating)",
      quotaOfRobotInEnterpriseBasicEdition:
        "Quota of Robot In Enterprise Basic Edition",
      quotaOfRobotInEnterpriseProEdition:
        "Quota of Robot In Enterprise Pro Edition",
      quotaOfRobotInFloatingEnterpriseEdition:
        "Quota of Robot In Enterprise Edition (Floating)",
      quotaOfConsoleInEnterpriseEdition:
        "Quota of Consoles In Enterprise Edition",
      quotaOfVicode: "Quota of ViCode Apps Published",
      quotaOfDUApiPdf: "Quota of Document Understanding API (PDF)",
      quotaOfDUApiOcr: "Quota of Document Understanding API (OCR)",

      desc: "Descriptions",
    },
    button: {
      apply: "Apply for License",
      applyEnterprise: "Apply for Enterprise Edition",
      assign: "Assign License",
      activation: "Activate License",
      detail: "View Usage",
      licenseDetail: "View License Detail",
      remove: {
        studio: "Unbind",
        vicode: "Unpublish",
      },
    },
    tooltip: {
      switchOpen: "Binded / Valid Total",
      switchClose: "Binded / Total",
    },
    editor: {
      name: "Enterprise Edition Studio",
      descriptions: "Number of studios that can be activated",
    },
    robot: {
      name: "Enterprise Advanced Robot",
      descriptions: "Number of robots that can be connected",
    },
    viCode: {
      title: "Vicode",
      name: "Number of Applications Published",
      descriptions: "Number of Applications That Can be Published",
      columns: {
        index: "Encoding",
        name: "Application Names",
        department: "Affiliated Department",
        operation: "Operation",
      },
    },
    documentUnderstanding: {
      name: "Document Understanding Service",
      descriptions:
        "Number of document understanding services that can be called",
    },
    documentUnderstandingTable: {
      modelType: "Built-in Model Type",
      total: "Total Number",
      used: "Used Number",
      rest: "Remaining Number",
      useOf: "Usage Proportion",
      PDF: "Electronic PDF Extraction Model",
      OCR: "OCR Extraction Model",
    },
    dailog: {
      demand: "Requirement Description",
      demandPlaceholder:
        "Please tell us the quantity you need, such as how many robots, or how many processes.",
      feedback:
        "We will contact you as soon as possible upon receipt of your request. In case of urgent need, please call: 400-639-2198",
      customer: "Client Name (Identifier)",
      licensePlaceholder: "Please enter the license number",
      sendScuess: "Successful license application has been sent.",
      sendFailed: "License application failed.",
      licenseSerialNumber: "Please enter the license number",
      unbindQuestions: "Are you sure you want to unbind license?",
      unbindPrompt: "After unbinding，bound {name} will not be available.",
      unpublishQuestions: "Are you sure you want to cancel publishing?",
      unpublishPrompt:
        "After canceling publishing, the app will not be available.",
    },
    messageInfo: {
      activateSuccess: "Activation succeeded.",
      activateFailed: "Activation failed.",
    },
    activateTip: {
      ifNoLicense: "If you do not have an Enterprise Edition License, please",
      tenantIdTip:
        "The identifier is provided to ENCOO staff when applying for license.",
    },
  },
};
