import { ApplicationUIModel } from "src/store/models/ui/application/application";
import { ApplicationDetailUIModel } from "src/store/models/ui/application/applicationDetail";
import { ApplicationSideUIModel } from "src/store/models/ui/application/applicationSide";
import { ApplicationTableUIModel } from "src/store/models/ui/application/applicationTable";

export const encooApplicationUIModels = {
  application: ApplicationUIModel,
  applicationTable: ApplicationTableUIModel,
  applicationSide: ApplicationSideUIModel,
  applicationDetail: ApplicationDetailUIModel,
};
