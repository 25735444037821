import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import {
  Runinstance,
  RuninstanceCreationData,
  RunInstanceLog,
  RunInstanceLogFile,
  RunInstanceResult,
  RunInstanceVideoRecord,
} from "src/models/runInstance";
import { EncooListEntity } from "src/models/_shared";

export const RUNINSTANCE_SERVICE_NAME = "runinstance";

export class RuninstanceServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, RUNINSTANCE_SERVICE_NAME);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async list() {
    const req = this.buildRequestV2({
      url: "/v2/runinstances",
      method: "GET",
    });
    return await super.listInternally<Runinstance>(req);
  }

  async getAll(jobId: string): Promise<Runinstance[]> {
    const req = this.buildRequestV2({
      url: `/v2/jobs/${jobId}/runinstances`,
      method: "GET",
      query: {
        pageIndex: 0,
        pageSize: 1000,
      },
    });
    const { body } = await this.sendRequest(req);
    return body.list;
  }

  async getById(runinstanceId: string): Promise<Runinstance> {
    const req = this.buildRequestV2({
      url: `/v2/runinstances/${runinstanceId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async create(payload: RuninstanceCreationData): Promise<Runinstance> {
    const req = this.buildRequestV2({
      url: `/v2/runinstances`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  async stop(runInstanceId: string): Promise<Runinstance> {
    const req = this.buildRequestV2({
      url: `v2/RunInstances/${runInstanceId}/stop?allowDegradeToNoWait=true`,
      method: "POST",
    });
    return await super.createInternal(req);
  }

  async pause(runInstanceId: string): Promise<Runinstance> {
    const req = this.buildRequestV2({
      // url: `/v2/runinstances/${runInstanceId}/postPauseEvent`, // mark：原接口-发送事件方式；新接口业务有待商榷
      url: `v2/RunInstances/${runInstanceId}/pause?allowDegradeToNoWait=true`,
      method: "POST",
    });
    return await super.createInternal(req);
  }

  async resume(runInstanceId: string): Promise<Runinstance> {
    const req = this.buildRequestV2({
      // url: `/v2/runinstances/${runInstanceId}/postResumeEvent`, // mark：原接口-发送事件方式；新接口业务有待商榷
      url: `v2/RunInstances/${runInstanceId}/resume?allowDegradeToNoWait=true`,
      method: "POST",
    });
    return await super.createInternal(req);
  }

  async getResult(runinstanceId: string): Promise<RunInstanceResult> {
    const req = this.buildRequestV2({
      url: `/v2/runinstances/${runinstanceId}/result`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }
  async getAccessKey(runinstanceId: string): Promise<RunInstanceResult> {
    const req = this.buildRequestV2({
      url: `/v2/runinstances/${runinstanceId}/downloadLogs`,
      method: "POST",
    });
    const { body } = await this.sendRequest(req);
    return body.accessKey;
  }

  async getAllLogsById(
    runinstanceId: string
  ): Promise<EncooListEntity<RunInstanceLog>> {
    const req = this.buildRequestV2({
      url: `/v2/runinstances/${runinstanceId}/logs`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async getListLogsById(
    runinstanceId: string,
    query?: {
      logType?: string;
      logLevel?: string[];
    }
  ) {
    let uri = `/v2/runinstances/${runinstanceId}/logs`;
    if (query?.logType === "System") {
      uri = `/v2/runinstances/${runinstanceId}/systemlogs`;
    } else if (query?.logType === "LogActivity") {
      uri = `/v2/runinstances/${runinstanceId}/businesslogs`;
    }
    const req = this.buildRequestV2({
      url: uri,
      method: "GET",
      query: {
        ...(query ?? {}),
      },
    });
    return await super.listInternally<RunInstanceLog>(req);
  }

  async getResultVideoRecord(
    runinstanceId: string
  ): Promise<RunInstanceVideoRecord> {
    const req = this.buildRequestV2({
      url: `/v2/runinstances/${runinstanceId}/result/recording`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async getLogFileByFileId(
    runinstanceId: string,
    robotId: string,
    fileName: string
  ): Promise<RunInstanceLogFile> {
    const req = this.buildRequestV2({
      url: `/v2/RunInstances/${runinstanceId}/logs/file`,
      method: "GET",
      query: {
        robotId: robotId,
        fileName: fileName,
      },
    });
    return await this.sendRequest(req).then((res) => {
      return res.body;
    });
  }
}
