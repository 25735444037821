/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Button as AntButton, Space } from "antd";
import {
  ButtonProps as AntButtonProps,
  ButtonType,
} from "antd/lib/button/button";
import React, { useMemo } from "react";
import Icon from "src/components/Icon";
import { useTheme } from "src/hooks";

interface ButtonProps extends Omit<AntButtonProps, "type"> {
  className?: string;
  type?: ButtonType;
  iconName?: string;
}

export default React.memo(function Button(props: ButtonProps) {
  const { className, type, iconName, icon, children, size, ...restProps } =
    props;
  const theme = useTheme();

  const sizeStyle = useMemo(() => {
    switch (size) {
      case "small":
        return css`
          padding: 4px 15px;
          font-weight: 400;
          font-size: 12px;
        `;
      case "middle":
        return css`
          padding: 4px 16px;
          font-weight: 400;
          font-size: 14px;
        `;
      default:
        return css`
          padding: 4px 16px;
          font-weight: 400;
          font-size: 14px;
        `;
    }
  }, [size]);

  const typeStyle = useMemo(() => {
    const defaultStyle = css`
      border: solid 1px;
      border-radius: 2px;
      height: auto;
      color: ${theme.buttonText};
      border-color: ${theme.buttonBorder};
      :hover {
        color: ${theme.buttonText};
        background-color: ${theme.buttonBackgroundHovered};
        border-color: ${theme.buttonBorder};
      }
      :disabled {
        color: ${theme.buttonTextDisabled};
        background-color: ${theme.buttonBackground};
        border-color: ${theme.buttonBorder};
      }
    `;
    switch (type) {
      case "primary":
        return css`
          ${defaultStyle}
          color: ${theme.primaryButtonText};
          background-color: ${theme.primaryButtonBackground};
          border-color: ${theme.primaryButtonBackground};
          :hover {
            color: ${theme.primaryButtonText};
            background-color: ${theme.primaryButtonBackgroundHovered};
            border-color: ${theme.primaryButtonBackgroundHovered};
          }
          :focus {
            color: ${theme.primaryButtonText};
            background-color: ${theme.primaryButtonBackgroundFocused};
            border-color: ${theme.primaryButtonBorderFocused};
          }
          :active {
            color: ${theme.primaryButtonText};
            background-color: ${theme.primaryButtonBackgroundActived};
            border-color: ${theme.primaryButtonBackgroundActived};
          }
          :disabled {
            color: ${theme.primaryButtonText};
            background-color: ${theme.primaryButtonBackgroundDisabled};
            border-color: ${theme.primaryButtonBackgroundDisabled};
          }
        `;
      case "link":
        return css`
          padding: 0;
          border: none;
          color: ${theme.primaryColor};
          background: none;
          :hover {
            background: none;
            border: none;
            box-shadow: none;
          }
        `;
      case "dashed":
        return css`
          ${defaultStyle}
          border-style: dashed
        `;
      case "default":
        return defaultStyle;
    }
  }, [theme, type]);

  const styledIcon = useMemo(() => {
    return (
      <div
        css={css`
          display: inline-block;
          svg {
            width: 12px;
            height: 12px;
          }
        `}
      >
        {icon}
      </div>
    );
  }, [icon]);

  return (
    <AntButton
      css={css`
        ${sizeStyle}
        ${typeStyle}
      `}
      type={type}
      icon={styledIcon}
      className={className}
      {...restProps}
    >
      <Space align={"center"} size={8}>
        {iconName && (
          <Icon
            name={iconName}
            css={css`
              width: 12px;
              height: 12px;
            `}
          />
        )}
        {children}
      </Space>
    </AntButton>
  );
});
