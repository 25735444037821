import { CurrentFeatureModel } from "src/store/models/logic/currentFeature";
import { CurrentPermissionModel } from "src/store/models/logic/currentPermission";
import { GlobalSelectCompanyUIModel } from "src/store/models/logic/globalSelectCompany";
import { GlobalSelectDepartmentModel } from "src/store/models/logic/globalSelectDepartment";

export const logicModels = {
  globalSelectCompany: GlobalSelectCompanyUIModel,
  globalSelectDepartment: GlobalSelectDepartmentModel,
  currentPermission: CurrentPermissionModel,
  currentFeature: CurrentFeatureModel,
};
