import {
  ConnectorType,
  CreateRestfulConnectionTemplateData,
  DataConnection,
  DataConnectionProperties,
  RestfulConnectFunctionPayload,
} from "@encoo-web/encoo-ui";
import { createModel } from "nyax";
import { DataConnectionEntityModel } from "src/store/models/entity/dataConnection/entity";
import { DataConnectionListModel } from "src/store/models/entity/dataConnection/list";
import { createHelperModel } from "src/store/models/entity/_shared";

export const DataConnectionHelperModel = createModel(
  class extends createHelperModel<DataConnection>({
    setItems: (getContainer, items) =>
      getContainer(DataConnectionEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(DataConnectionEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(DataConnectionEntityModel).state.byId[id],
    refreshList: (getContainer) =>
      getContainer(DataConnectionListModel).actions.refresh.dispatch({}),
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(DataConnectionEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),

        getAll: async (payload: { type?: ConnectorType }) => {
          const { type } = payload;
          return await this._readAll({
            getAllAction: async () => {
              return this.dependencies.serviceClient.dataConnection.getAllConnection(
                {
                  type,
                }
              );
            },
          });
        },
        getById: async (payload: { id: string; force?: boolean }) => {
          const { id, force = true } = payload;
          return await this._readById({
            id,
            getByIdAction: async () => {
              const item =
                await this.dependencies.serviceClient.dataConnection.getConnection(
                  id
                );
              return item;
            },
            force,
          });
        },

        create: async (payload: {
          connection: Omit<DataConnection, "id" | "properties">;
          properties?: DataConnectionProperties;
        }) => {
          const { connection, properties } = payload;

          return await this._create({
            createAction: () =>
              this.dependencies.serviceClient.dataConnection.createConnection(
                connection,
                properties
              ),
          });
        },

        testByConfig: async (payload: Omit<DataConnection, "id">) => {
          return this.dependencies.serviceClient.dataConnection.run(payload);
        },

        testById: async (payload: { id: string }) => {
          return this.dependencies.serviceClient.dataConnection.runById(
            payload.id
          );
        },

        delete: async (payload: { id: string }) => {
          const { id } = payload;
          return await this._delete({
            id,
            deleteAction: () =>
              this.dependencies.serviceClient.dataConnection.deleteConnection(
                id
              ),
          });
        },

        update: async (payload: {
          id: string;
          connection: Omit<DataConnection, "id" | "properties">;
          properties?: DataConnectionProperties;
        }) => {
          const { id, connection, properties } = payload;
          return await this._update({
            id,
            updateAction: () =>
              this.dependencies.serviceClient.dataConnection.updateConnection(
                id,
                connection,
                properties
              ),
          });
        },

        getTemplates: async () => {
          return await this.dependencies.serviceClient.dataConnection.getDataConnectionTemplates();
        },
        getFunctionVaribleList: async (
          payload: RestfulConnectFunctionPayload
        ) => {
          return await this.dependencies.serviceClient.dataConnection.getFuncrionVaribleList(
            payload
          );
        },
        createDataConnectionTemplate: async (
          payload: CreateRestfulConnectionTemplateData
        ) => {
          return await this.dependencies.serviceClient.dataConnection.createDataConnectionTemplate(
            payload
          );
        },
        deleteCustonTemplate: async (id: string) => {
          return await this.dependencies.serviceClient.dataConnection.deleteCustomTemplate(
            id
          );
        },
      };
    }
  }
);
