// eslint-disable-next-line import/no-anonymous-default-export
export default {
  resourceGroup: {
    title: "Resource Group Name",
    introduction:
      "Resource Group Management is mainly used to define each resource group, and can assign roles with different permissions to each user under different resource groups to achieve fine-grained management and control of user permissions.",
    entityTypeName: "Resource Group",
    name: "Resource Group Name",
    addToResourceGroup: "Add",
    user: "Resource Group User",
    allResourceGroup: "",
  },
};
