// eslint-disable-next-line import/no-anonymous-default-export
export default {
  payment: {
    name: "Payment Center",
    product: {
      version: {
        personal: "Personal Professional Edition",
        freePersonal: "Personal Free Edition",
        chargePersonal: "Personal paid version",
        sass: "Enterprise SASS Edition",
        custom: "Customized version of enterprise privatization",
      },
      utils: {
        amount: "￥",
      },
      label: {
        contactUs: "Contact us",
        email: "Email",
        telephone: "Phone",
        originalPrice: "Original Price",
        payable: "Payable amount",
        versionRights: "Version Rights Comparison",
        scanCode: "Scan the QR code on WeChat and consult now",
        promoCode: "Promotion Code",
      },
      buttons: {
        pay: "Buy Now",
        ApplyNow: "Apply Now",
      },
      titles: {
        ai: "Cloud Expanding AI Services",
        personal:
          "Personal Professional Edition: Purchase and enjoy the professional version of cloud expansion console service",
        viCode: "ViCode application",
      },
      description: {
        personal:
          "*The current account already contains one editor license. If you need more licenses, please purchase the enterprise version",
      },
      env: {
        name: "System Environment",
        windows: "Windows",
        all: "Windows, Kirin, UOS, Euler, etc.",
      },
      cell: {
        editor: "Editor",
        robot: "Robot",
        console: "Console",
        other: "Other",
        debugger: "Run/Debug",
        smartRecord: "Smart Record",
        systemEnv: "System Environment",
        processLib: "Process Library",
        timedRecord: "Timed Record",
        runProcess: "Process execution",
        localActivate: "Local Activation",
        setUp: "Settings",
        dashboard: "Dashboard",
        automationCenter: "Automation Center of Excellence",
        center: {
          RPA: "RPA Center",
          AI: "AI Center",
          data: "Data center",
          management: "Management center",
        },
        viCode: "ViCode",
        smartKnowledge: "Intelligent knowledge base",
        technicalSupport: "Technical Support",
        productCustom: "Product Customization",
        privateDeployment: "Private deployment",
        integration: {
          API: "API Integration",
          enterprise: "Enterprise micro integration",
          dingDing: "Dingding Integration",
        },
        component: {
          common: "Common components",
          console: "Console component",
          AI: "AI Components",
          CV: "CV Components",
          custom: "Custom component",
          library: "Built-in component library",
        },
        SAP: "SAP",
        mobileAutomation: "Mobile Automation",
        remoteDesktop: "Remote Desktop",
        desktopControl: "Desktop proprietary Control",
        market: {
          name: "Market",
          component: "Component market",
          code: "Code Market",
          process: "Process Template Market",
          element: "Element market",
        },
        management: {
          name: "Management",
          git: "Git Version Management",
          uploadToConsole: "Upload to console Management",
          processPackage: "Process package Management",
          robot: "Robot management",
          robotGroup: "Robot Group Management",
          processDeployment: "Process Deployment Management",
          computer: "Computer management",
          calendar: "Calendar Management",
          AIService: "AI Service Management",
          asset: "Asset Management",
          connection: "Connection Management",
          role: "Role management",
          user: "User Management",
          permission: "Permission management",
          local: "local management",
          license: "License Management",
          organizationalStructure: "Organization structure management",
        },
        localExecution: "Local execution",
        consoleScheduling: "Console scheduling",
        basic: "Basic",
        recordVideo: "Record video",
        scheduledTask: "Scheduled task",
        taskRecord: "Task record",
        planExecuted: "Plan to be executed",
        executionScreenshot: "Execution Screenshot",
        runAdmin: "Run as administrator",
        standaloneDesktop: "Independent desktop",
        timeoutSet: "timeout Settings",
        localActivation: "Local activation use",
        autoUnlock: "Automatically unlocks",
        overallMonitoring: "Overall monitoring",
        robotMonitoring: "Robot monitoring",
        robotGroupMonitoring: "Robot GroupMonitoring",
        robotOperationStatistics: "the robot run statistics",
        userProcessStatistic: "User Process Statistics",
        requirementInitiation: "Requirement Initiation",
        requirementFollowUp: "Requirements follow up",
        dataAnalysis: "Data analysis",
        RDPPersistence: "RDP session persistence",
        processOrchestration: "Process Orchestration",
        taskQueue: "Task queue",
        executionRecord: "Execution record",
        unpublishedApp: "Unpublished App",
        publishApp: "Publish Application",
        documentUnpublish: "Document understanding unpublished",
        documentPublish: "Document understand templates",
        OCRService: "OCR service",
        knowledge: "knowledge",
        application: "Application",
        quota: "Quota",
        dataSheet: "DataSheet",
        fileService: "File service",
        dataQueue: "Data queue",
        auditLog: "Audit log",
        systemSetting: "System Setting",
        onlineSupport: "Online Support",
        siteTechnicalSupport: "On-site Technical support",
        freeCountLimit: "Total free calls",
        freeTotalLimit: "Free quota",
        freeLimit: "Free amount",
        freeCount: "Number of free calls",
        support: {
          community: "A community of people",
          online: "VIP Online Technical Support",
          exclusive: "VIP Technical Support",
        },
        number: "Quantity: {count}",
        noLimit: "Unlimited",
        upTo: "less than or equal to",
        freeQuota: "Free credit",
        additionalPurchase: "available for additional purchase",
        freeEcooAiServer:
          "Every cloud-expanded AI service is free: {count} times",
      },
      payTip:
        "*This order is a new purchase, if you need to extend the life of the purchased product, please select [Renew] from the order page to purchase.",
    },
    order: {
      name: "Order",
      label: {
        orderCheck: "Order Confirmation",
        renew: "Renew",
        weiChat: "wechat",
        originalPrice: "Original Price",
        payable: "Payable",
        account: "Account",
        product: "Purchased product",
        versionType: "Version type",
        specifications: "Specifications",
        effectiveDate: "effective date",
        editors: "Number of editors",
        robots: "Number of robots",
        count: "Count",
        payMethod: "Payment method",
        discountCode: "Discount Code",
        discountCodeDeduction: "Discount code deduction",
        treaty: "I have read and agree to Cloud expansion of RPA Software",
        purchaseNotes: "Purchase Information",
      },
      buttons: {
        submit: "Submit the order",
        use: "to use",
        remove: "Remove",
      },
      unit: {
        hour: "Hour",
        minute: "Minute",
        second: "Second",
      },
      tips: {
        discountCode:
          "You can get the promo code from the cloud expansion business staff",
        renew:
          "The effective date of the robot renewal starts from the end of the selected order",
        isExpired:
          "This order has expired. Renewal orders will take effect from the current date",
        nowEffective: "Effective immediately after purchase",
        discountError: {
          name: "promo code invalid",
          options: {
            inputError: "promo code entered incorrectly",
            isExpired: "promo code has expired",
            notMatch: "Does not match the currently selected item rule",
            notApplicable: "This promo code is not applicable to current user",
          },
        },
        discountSuccess: "promo code is valid!",
        pay: {
          wx: "wechat Pay",
          success: "Payment success",
          expire: "Payment expired",
          fail: "Payment fails",
        },

        wx: {
          pay: "Pay by scanning wechat code",
          order: {
            prefix: "Please in",
            suffix:
              "Complete the payment within minutes, timeout will automatically cancel the order",
          },
          tip: "wechat scan, immediately join the VIP technical support group, RPA experts online for you to answer questions",
        },
      },
      notifications: {
        treaty: "Please check the Purchase Instructions",
      },
    },
  },
};
