import { createModel } from "nyax";
import { MenuExpandedDefinition } from "src/routes/_shared";
import { ModelBase } from "src/store/ModelBase";

export type AIViewType =
  | "ai"
  | "drivingLicense"
  | "businessLicense"
  | "bankCard"
  | "licensePlate"
  | "vehicleCertificate"
  | "VATInvoice"
  | "rollTicket"
  | "bankReceipt"
  | "taxiTicket"
  | "airTransport"
  | "recognizeAndValidation"
  | "generalText"
  | "handwrittenNumbers"
  | "generalTable";

export const OCRSideUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        selectViewType: {} as MenuExpandedDefinition,
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setSelectViewType: (value: MenuExpandedDefinition) => {
          this.state.selectViewType = value;
        },
      };
    }
    public effects() {
      return {};
    }
    public selectors() {
      return {};
    }
  }
);
