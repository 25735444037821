import "@egjs/flicking-plugins/dist/pagination.css";
import "@egjs/react-flicking/dist/flicking.css";
import { css } from "@emotion/react";
import "antd/dist/antd.css";
import "normalize.css";

export default css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    height: 100%;
  }

  body {
    height: 100%;
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;
