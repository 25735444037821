/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Form, Input, message } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import React, { useCallback } from "react";
import Modal from "src/components/Modal";
import AppIconSelectDialog from "src/containers/vicodeApp/components/ViCodeAppIconSelectDialog";
import {
  useApplicationInsights,
  useFormatMessage,
  useGetContainer,
} from "src/hooks";
import messageIds from "src/locales/messageIds";
import { ViCodeAppHelperModel } from "src/store/models/entity/vicodeApp/helper";
import { QuotaUIModel } from "src/store/models/ui/quota/quota";
import { UIViCodeAppAppModel } from "src/store/models/ui/vicodeApp/apps";
import { inputLimitedLength } from "src/utils/validate";

const ViCodeAppCreationForm = React.memo(function (props: {
  form: FormInstance;
}) {
  const formatMessage = useFormatMessage();
  const { form } = props;

  return (
    <Form layout="vertical" form={form}>
      <Form.Item
        name="name"
        label={formatMessage(messageIds.apps.name)}
        rules={[
          {
            required: true,
            max: inputLimitedLength.name,
            whitespace: true,
          },
        ]}
      >
        <Input placeholder={formatMessage(messageIds.common.pleaseInput)} />
      </Form.Item>
      <Form.Item
        name="icon"
        label={formatMessage(messageIds.apps.icon)}
        rules={[
          {
            required: true,
          },
        ]}
        css={css`
          .ant-form-item-control-input {
            min-height: 0 !important;
          }
        `}
      >
        <AppIconSelectDialog />
      </Form.Item>
      {/* todo tags */}
      {/* <Form.Item
        name="tagIds"
        label={formatMessage(messageIds.apps.tag)}
        css={css`
          .ant-form-item-control-input {
            min-height: 0 !important;
          }
        `}
      >
        <AppTagsAssociated />
      </Form.Item> */}

      <Form.Item
        name="description"
        label={formatMessage(messageIds.common.description)}
        rules={[
          {
            max: inputLimitedLength.description,
            whitespace: true,
          },
        ]}
      >
        <TextArea
          rows={4}
          placeholder={formatMessage(messageIds.common.pleaseInput)}
        />
      </Form.Item>
    </Form>
  );
});

export default React.memo(function ViCodeAppCreationDialog(props: {
  onCancel: () => void;
}) {
  const getContainer = useGetContainer();
  const formatMessage = useFormatMessage();
  const applicationInsights = useApplicationInsights();

  const uiViCodeAppAppContainer = getContainer(UIViCodeAppAppModel);
  const appHelper = getContainer(ViCodeAppHelperModel);
  const quota = getContainer(QuotaUIModel);

  const { onCancel } = props;
  const [form] = useForm();

  const onCreate = useCallback(async () => {
    const value = await form.validateFields();

    const { name, description, icon } = value;
    // todo tags
    // const tagList = tagIds?.map((value: string) =>
    //   appTags.find((item) => item.id === value)
    //     ? { id: value }
    //     : { name: value }
    // );
    const app = await uiViCodeAppAppContainer.actions.create.dispatch({
      name,
      description,
      // "null" 后端不能为空，随便填的
      icon: typeof icon === "string" ? icon : "null",
    });
    if (typeof icon === "object") {
      await appHelper.actions.uploadViCodeAppIcon.dispatch({
        appId: app.id,
        payload: icon,
      });
    }
    applicationInsights.sendTrackEvent(
      { name: "Vicode_CreatAppEvent" },
      {
        AppID: app.id,
      }
    );
    message.success(formatMessage(messageIds.common.createSuccess));
    onCancel();
    quota.actions.refreshQuota.dispatch({});
  }, [
    appHelper.actions.uploadViCodeAppIcon,
    applicationInsights,
    form,
    formatMessage,
    onCancel,
    uiViCodeAppAppContainer.actions.create,
    quota.actions.refreshQuota,
  ]);

  return (
    <Modal
      visible={true}
      title={formatMessage(messageIds.apps.appDev.addApp)}
      onCancel={onCancel}
      onOk={onCreate}
      destroyOnClose={true}
    >
      <ViCodeAppCreationForm form={form} />
    </Modal>
  );
});
