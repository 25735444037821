import moment from "moment";
import { createModel } from "nyax";
import { DashboardQueryData } from "src/models/dashboard";
import {
  QueueAssociatedCount,
  QueueBusyTimeRank,
  QueueExecutionDetail,
  QueueFailureProportionRank,
  QueueJobStateCount,
  QueueSuccessProportionRank,
  QueueWaitTimeRank,
} from "src/models/queueDashboard";
import { ModelBase } from "src/store/ModelBase";

export const todayString = moment(new Date()).format("YYYY-MM-DD");

export const QueueStatisticsUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        dateRange: {
          startDate: moment().subtract(29, "days").format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
        },
        containsSubDepartment: false,
        queueAssociatedCount: null as null | QueueAssociatedCount,
        queueExecutionDetail: null as null | QueueExecutionDetail,
        queueJobStateCount: null as null | QueueJobStateCount,
        queueAssociatedCountByToday: null as null | QueueAssociatedCount,
        queueExecutionDetailByToday: null as null | QueueExecutionDetail,
        queueJobStateCountByToday: null as null | QueueJobStateCount,
        queueSuccessProportionRank: null as null | QueueSuccessProportionRank[],
        queueFailureProportionRank: null as null | QueueFailureProportionRank[],
        queueBusyTimeRank: null as null | QueueBusyTimeRank[],
        queueWaitTimeRank: null as null | QueueWaitTimeRank[],
      };
    }

    public selectors() {
      return {};
    }

    public reducers() {
      return {
        setDateRange: (
          dateRange: Pick<DashboardQueryData, "startDate" | "endDate">
        ) => {
          this.state.dateRange = dateRange;
        },
        setContainsSubDepartment: (value: boolean) => {
          this.state.containsSubDepartment = value;
        },
        setQueueAssociatedCount: (value: null | QueueAssociatedCount) => {
          this.state.queueAssociatedCount = value;
        },
        setQueueExecutionDetail: (value: null | QueueExecutionDetail) => {
          this.state.queueExecutionDetail = value;
        },
        setQueueJobStateCount: (value: null | QueueJobStateCount) => {
          this.state.queueJobStateCount = value;
        },
        setQueueAssociatedCountByToday: (
          value: null | QueueAssociatedCount
        ) => {
          this.state.queueAssociatedCountByToday = value;
        },
        setQueueExecutionDetailByToday: (
          value: null | QueueExecutionDetail
        ) => {
          this.state.queueExecutionDetailByToday = value;
        },
        setQueueJobStateCountByToday: (value: null | QueueJobStateCount) => {
          this.state.queueJobStateCountByToday = value;
        },
        setQueueSuccessProportionRank: (
          value: null | QueueSuccessProportionRank[]
        ) => {
          this.state.queueSuccessProportionRank = value;
        },
        setQueueFailureProportionRank: (
          value: null | QueueFailureProportionRank[]
        ) => {
          this.state.queueFailureProportionRank = value;
        },
        setQueueBusyTimeRank: (value: null | QueueBusyTimeRank[]) => {
          this.state.queueBusyTimeRank = value;
        },
        setQueueWaitTimeRank: (value: null | QueueWaitTimeRank[]) => {
          this.state.queueWaitTimeRank = value;
        },
      };
    }

    public effects() {
      return {
        initializeRequest: async (payload: {
          departmentId: string;
          query: DashboardQueryData;
          containsSubDepartment: boolean;
        }) => {
          // this.actions.requestQueueAssociatedCount.dispatch(payload);
          this.actions.requestQueueExecutionDetail.dispatch(payload);
          this.actions.requestQueueJobStateCount.dispatch(payload);
          this.actions.requestQueueSuccessProportionRank.dispatch(payload);
          this.actions.requestQueueFailureProportionRank.dispatch(payload);
          this.actions.requestQueueBusyTimeRank.dispatch(payload);
          // this.actions.requestQueueWaitTimeRank.dispatch(payload);
        },
        requestQueueAssociatedCount: async (payload: {
          departmentId: string;
          query: DashboardQueryData;
          containsSubDepartment: boolean;
        }) => {
          const { departmentId, query, containsSubDepartment } = payload;
          const value =
            await this.dependencies.serviceClient.dashboard.queueAssociatedCount(
              departmentId,
              {
                timeZone: query.timeZone,
                startDate: query.startDate,
                endDate: query.endDate,
              },
              containsSubDepartment
            );
          const todayValue =
            await this.dependencies.serviceClient.dashboard.queueAssociatedCount(
              departmentId,
              {
                timeZone: query.timeZone,
                startDate: todayString,
                endDate: todayString,
              },
              containsSubDepartment
            );
          await this.actions.setQueueAssociatedCount.dispatch(value);
          await this.actions.setQueueAssociatedCountByToday.dispatch(
            todayValue
          );
        },
        requestQueueExecutionDetail: async (payload: {
          departmentId: string;
          query: DashboardQueryData;
          containsSubDepartment: boolean;
        }) => {
          const { departmentId, query, containsSubDepartment } = payload;
          const value =
            await this.dependencies.serviceClient.dashboard.queueExecutionDetail(
              departmentId,
              query,
              containsSubDepartment
            );
          const todayValue =
            await this.dependencies.serviceClient.dashboard.queueExecutionDetail(
              departmentId,
              {
                ...query,
                startDate: todayString,
                endDate: todayString,
              },
              containsSubDepartment
            );
          await this.actions.setQueueExecutionDetail.dispatch(value);
          await this.actions.setQueueExecutionDetailByToday.dispatch(
            todayValue
          );
        },
        requestQueueJobStateCount: async (payload: {
          departmentId: string;
          query: DashboardQueryData;
          containsSubDepartment: boolean;
        }) => {
          const { departmentId, query, containsSubDepartment } = payload;
          const value =
            await this.dependencies.serviceClient.dashboard.queueJobStateCount(
              departmentId,
              query,
              containsSubDepartment
            );
          const todayValue =
            await this.dependencies.serviceClient.dashboard.queueJobStateCount(
              departmentId,
              {
                ...query,
                startDate: todayString,
                endDate: todayString,
              },
              containsSubDepartment
            );
          await this.actions.setQueueJobStateCount.dispatch(value);
          await this.actions.setQueueJobStateCountByToday.dispatch(todayValue);
        },
        requestQueueSuccessProportionRank: async (payload: {
          departmentId: string;
          query: DashboardQueryData;
          containsSubDepartment: boolean;
        }) => {
          const { departmentId, query, containsSubDepartment } = payload;
          const value =
            await this.dependencies.serviceClient.dashboard.queueSuccessProportionRank(
              departmentId,
              query,
              containsSubDepartment
            );
          await this.actions.setQueueSuccessProportionRank.dispatch(value);
        },
        requestQueueFailureProportionRank: async (payload: {
          departmentId: string;
          query: DashboardQueryData;
          containsSubDepartment: boolean;
        }) => {
          const { departmentId, query, containsSubDepartment } = payload;
          const value =
            await this.dependencies.serviceClient.dashboard.queueFailureProportionRank(
              departmentId,
              query,
              containsSubDepartment
            );
          await this.actions.setQueueFailureProportionRank.dispatch(value);
        },
        requestQueueBusyTimeRank: async (payload: {
          departmentId: string;
          query: DashboardQueryData;
          containsSubDepartment: boolean;
        }) => {
          const { departmentId, query, containsSubDepartment } = payload;
          const value =
            await this.dependencies.serviceClient.dashboard.queueBusyTimeRank(
              departmentId,
              query,
              containsSubDepartment
            );
          await this.actions.setQueueBusyTimeRank.dispatch(value);
        },
        requestQueueWaitTimeRank: async (payload: {
          departmentId: string;
          query: DashboardQueryData;
          containsSubDepartment: boolean;
        }) => {
          const { departmentId, query, containsSubDepartment } = payload;
          const value =
            await this.dependencies.serviceClient.dashboard.queueWaitTimeRank(
              departmentId,
              query,
              containsSubDepartment
            );
          await this.actions.setQueueWaitTimeRank.dispatch(value);
        },
      };
    }
  }
);
