// eslint-disable-next-line import/no-anonymous-default-export
export default {
  coeWork: {
    common: {
      history: "History",
      logeventstate: "Logeventstate",
      initiate: "Initiate",
      changeStateTo: "Change the state to",
      state: {
        all: "All status",
        todo: "To be processed",
        processing: "Processing",
        passed: "Passed",
        failed: "Failed",
        canceled: "Cancelled",
        development: "Under development",
        evaluation: "Under evaluation",
        shelve: "shelve",
        closed: "Closed",
        completed: "Completed",
      },
      timeUtils: {
        hour: "hour",
        minute: "minute",
        day: "day",
        week: "week",
        month: "month",
      },
      noPermission: "This user has no permission already.",
      placeholders: {
        searchKeyword: "Please enter a search keyword",
      },
      degree: {
        importantEmergency: "important emergency",
        unimportantUrgent: "No emergency",
        importantNotUrgent: "Not important and urgent",
        notImportantNotUrgent: "Not important, not urgent",
      },
      notifications: {
        undo: "Confirm to undo {title}?",
      },
    },
    table: {
      columns: {
        title: "Title",
        importance: "Emergency importance",
        state: "State",
        follower: "The current follower",
        sponsor: "Originator (department)",
        process: "Associated process",
        lastUpdated: "Last updated time",
        operation: "Operation",
      },
      buttons: {
        create: "New demand",
        view: "View",
        undo: "Undo",
      },
    },
    addForm: {
      buttons: {
        submit: "Submit",
        cancel: "Cancel",
      },
      utils: {
        second: "Times",
      },
      title: "Title",
      process: "Process association",
      degree: "Emergency importance",
      consume: "Single labor consumption",
      frequency: "Frequency",
      description: "Requirements Description",
      otherInformation: "Other information",
      follower: "Follow-up person",
    },
    details: {
      created: "Created the",
      changed: "Changed the",
      elapsedTimeOfManual: "Average labor time per session",
      unitOfElapsedTime: "Unit of average labor time per session",
      frequency: "Frequency",
      unitOfFequency: "Unit of frequency",
      detail: "Description",
      otherDetails: "Other Details",
      processCorrelation: "Process Correlation",
      associateProcess: "Associate Process",
      processName: "Process Name",
      assignedTo: "Current Follower",
      attachmentName: "Attachment Name",
    },
  },
};
