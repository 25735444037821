// eslint-disable-next-line import/no-anonymous-default-export
export default {
  company: {
    enterInto: "Enter",
    selectCompany: "Please select the company.",
    companyName: "Company Name",
    connect: {
      companyStructureConfig: "Company Structural Configuration",
      noCompanyGroup: "No multi-level corporate relationship",
      viewAddCode: "View company accession code",
      parentCompanyInfo: "Parent Company Information",
      parentCompany: "Parent Company",
      noParentCompanyTip:
        "Current company hasn’t joined the parent company, click",
      joinCompany: "Join Parent Company",
      childCompanyInfo: "Child Company Information",
      childCompany: "Child Company",
      noChildCompany:
        "Current company doesn't have any information about the child company, please send",
      myAddCode: "company access code",
      sendToConnect: "to the child company that wants to join for connection.",
      mutiCompanyRelation: "Multi-level corporate relationship flow",
      stringSendTip:
        "Please send this connection string to the child company that wants to join for connection.",
      inputStringToConnect:
        "Please enter the connection string to the parent company to complete the connection.",
      rejectJoin:
        "The current company already has a child company and cannot join the parent company.",
    },
  },
};
