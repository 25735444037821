import { createModel } from "nyax";
import { DataSource, DataSourceFoderType } from "src/models/dataSource";
import { DataSourceEntityModel } from "src/store/models/entity/dataSource/entity";
import { createUIListV2Model } from "src/store/models/ui/_shared";

export const DataSourceListModel = createModel(
  class extends createUIListV2Model<DataSource>({
    setItems: (getContainer, items) =>
      getContainer(DataSourceEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(DataSourceEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(DataSourceEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initial: async (payload: {
          force?: boolean;
          dataSourceFoderType: DataSourceFoderType;
          folderId: string;
        }) => {
          const { dataSourceFoderType, folderId } = payload;
          this._initial({
            initialAction: (pageIndex, pageSize) =>
              this.dependencies.serviceClient.dataSource.getList(
                dataSourceFoderType,
                folderId,
                {
                  pageIndex,
                  pageSize,
                }
              ),
          });
        },
      };
    }
  }
);
