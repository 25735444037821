// eslint-disable-next-line import/no-anonymous-default-export
export default {
  jobQueue: {
    introduction:
      "Job queue is mainly used to monitor business data and trigger corresponding process deployment or robot operation.",
    confirmTile: "You can also associate: ",
    createConfig: "No data, please create a new listening object",
    monitor: "New listener",
    lookDetail: "View task details",
    id: "Queue ID",
    idInfo: "Use API when calling code.",
    status: {
      reserved: "Processing",
      toDo: "Untreated",
      done: "Processed",
      delete: "Delete",
    },
    notParameter: "Unassociated",
    monitorObject: "Listening object",
    bindParameter: "Message associated to parameter",
    messageRemove: "Message removal",
    newMessageTrigger: "Triggered when there is a new message",
    removeWay: {
      noRemove: "Do not remove",
      rightNowRemove: "Remove now",
      successRemove: "When the execution is successful",
      finishRemove: "At the end of execution",
    },
    multiJObWait: "When multiple messages are queued",
    waitFinish: "Wait for processing after the last task is completed",
    nowCreate: "New task processing immediately",
    abandon: "Discard",
  },
};
