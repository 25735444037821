import { createModel } from "nyax";
import { CoeToDoWork, CoeToDoWorkCreationData } from "src/models/coeToDoWork";
import { CoeToDoWorkEntityModel } from "src/store/models/entity/coeToDoWork/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const CoeToDoWorkHelperModel = createModel(
  class extends createHelperModel<CoeToDoWork>({
    setItems: (getContainer, items) =>
      getContainer(CoeToDoWorkEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(CoeToDoWorkEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(CoeToDoWorkEntityModel).state.byId[id],
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(CoeToDoWorkEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),

        getById: async (payload: {
          coeToDoWorkId: string;
          force?: boolean;
        }) => {
          const { coeToDoWorkId, force = true } = payload;
          return await this._readById({
            id: coeToDoWorkId,
            getByIdAction: async () => {
              const item =
                await this.dependencies.serviceClient.coeWork.getById(
                  coeToDoWorkId
                );
              // await this.getContainer(
              //   DataPermissionHelperModel
              // ).actions.getAll.dispatch([
              //   { resourceType: item.resourceType, id: item.id },
              // ]);
              return item;
            },
            force,
          });
        },
        create: async (payload: { params: CoeToDoWorkCreationData }) => {
          const { params } = payload;
          return await this._create({
            createAction: () =>
              this.dependencies.serviceClient.coeWork.create(params),
          });
        },
        update: async (params: {
          coeToDoWork: Partial<CoeToDoWork> & Pick<CoeToDoWork, "id">;
        }) => {
          const { coeToDoWork } = params;
          return await this._update({
            id: coeToDoWork.id,
            updateAction: () =>
              this.dependencies.serviceClient.coeWork.update(coeToDoWork),
          });
        },
      };
    }
  }
);
