import { createModel } from "nyax";
import {
  TaskScheduler,
  WorkflowLayoutTriggerCreationData,
  WorkflowLayoutTriggerUpdateData,
} from "src/models/workflowLayout";
import { WorkflowLayoutTriggerEntityModel } from "src/store/models/entity/workflowLayoutTrigger/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const WorkflowLayoutTriggerHelperModel = createModel(
  class extends createHelperModel<TaskScheduler>({
    setItems: (getContainer, items) =>
      getContainer(WorkflowLayoutTriggerEntityModel).actions.setItems.dispatch(
        items
      ),
    getItems: (getContainer) =>
      getContainer(WorkflowLayoutTriggerEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(WorkflowLayoutTriggerEntityModel).state.byId[id],
  }) {
    public effects() {
      return {
        ...super.effects(),

        create: async (payload: {
          workflowLayoutId: string;
          params: WorkflowLayoutTriggerCreationData;
        }) => {
          const { workflowLayoutId, params } = payload;
          return await this._create({
            createAction: () =>
              this.dependencies.serviceClient.workflowLayout.createTrigger(
                workflowLayoutId,
                {
                  ...params,
                }
              ),
          });
        },
        update: async (params: {
          triggerId: string;
          workflowLayoutId: string;
          workflowLayoutTrigger: WorkflowLayoutTriggerUpdateData;
        }) => {
          const { triggerId, workflowLayoutId, workflowLayoutTrigger } = params;
          return await this._update({
            id: triggerId,
            updateAction: () =>
              this.dependencies.serviceClient.workflowLayout.updateTrigger(
                triggerId,
                workflowLayoutId,
                workflowLayoutTrigger
              ),
          });
        },
        delete: async (payload: {
          workflowLayoutId: string;
          triggerId: string;
        }) => {
          const { workflowLayoutId, triggerId } = payload;
          return await this._delete({
            id: triggerId,
            deleteAction: () =>
              this.dependencies.serviceClient.workflowLayout.deleteTrigger(
                workflowLayoutId,
                triggerId
              ),
          });
        },
        getById: async (payload: {
          workflowLayoutId: string;
          triggerId: string;
          force?: boolean;
        }) => {
          const { workflowLayoutId, triggerId, force } = payload;
          return await this._readById({
            id: triggerId,
            getByIdAction: () =>
              this.dependencies.serviceClient.workflowLayout.getTriggerById(
                workflowLayoutId,
                triggerId
              ),
            force,
          });
        },
        // execute: async (payload: {
        //   workflowLayoutId: string;
        //   params: Exclude<Partial<TriggerCreationData>, "triggerType">;
        // }) => {
        //   const { workflowLayoutId, params } = payload;
        //   const item =
        //     await this.dependencies.serviceClient.workflow.createTrigger(
        //       workflowLayoutId,
        //       { ...params, triggerType: "Once" } as TriggerCreationData
        //     );
        //   return item;
        // },
        enableDisabled: async (payload: {
          workflowLayoutId: string;
          triggerId: string;
          isEnabled: boolean;
        }) => {
          const { workflowLayoutId, triggerId, isEnabled } = payload;
          return await this._update({
            id: triggerId,
            updateAction: () =>
              this.dependencies.serviceClient.workflowLayout.enableDisabledTrigger(
                workflowLayoutId,
                triggerId,
                isEnabled
              ),
          });
        },
        // getTriggerScheduler: async (payload: TriggerSchedulerConfig) => {
        //   const item =
        //     await this.dependencies.serviceClient.workflow.calculateTriggerScheduler(
        //       payload
        //     );
        //   return item;
        // },
      };
    }
  }
);
