// eslint-disable-next-line import/no-anonymous-default-export
export default {
  apps: {
    name: "应用名称",
    icon: "应用图标",
    introduction:
      "应用管理主要用于新建并管理各种应用，同时支持应用版本管理以及对应用进行统一上架或下架等操作。",
    workbench: "应用",
    currentVersion: "当前上架版本",
    versionDescription: "版本说明",
    status: {
      all: "全部状态",
      enabled: "已上架",
      disabled: "未上架",
      notReady: "未初始化",
    },
    statusHandle: {
      enabled: "上架",
      disabled: "下架",
      initialize: "立即初始化",
    },
    operation: {
      release: "发布",
      offline: "下架",
    },
    version: "版本号",
    tag: "标签",
    inputTag: "请输入标签",
    changeVersion: "切换到此版本",
    uploadDateTime: "上传时间",
    creater: "开发者",
    device: "应用范围",
    mobile: "移动端",
    pc: "电脑端",
    try: "试用",
    moreVersion: "查看更多版本",
    workflowName: "流程部署名称",
    packageVersion: "流程包版本号",
    appVersionsTitle: "版本列表",
    currentSign: "当前",
    upgradeTip: "此版本不是最新版本，可点击升级至{lastAvailableVersion}版本",
    upgradeConfirm: "确定升级至{lastAvailableVersion}版本?",
    upgradeBatchConfirm: "确定升级?",
    updateAppCurrentVersionConfirm: "是否确认将当前应用切换到此版本?",
    deleteAppVersionMessage:
      "该版本为当前上架版本，无法删除。请切换版本后再执行删除操作",
    versionName: "版本",
    share: "分享",
    switchToVersion: "切换到",
    myApp: {
      card: {
        menu: {
          copyAppLink: "复制链接",
          linkShare: "链接分享",
          qrCodeShare: "二维码分享",
          emailShare: "邮件分享",
          copySuccess: "复制成功",
          copyAppProject: "复制",
          hasCopiedLink: "已复制链接",
          recevierEmail: "收件人邮箱",
          emailTitle: "邀请使用应用",
          emailContent:
            "您好\r\n \t邀请您点击链接，打开应用：{shareUrl} \r\n\r\n 谢谢",
        },
      },
      useApp: "使用",
      appsNotFoundText: "暂无此应用",
      noAppsText: "您还没有添加任何应用",
      introduction: "我的应用主要用于业务人员查看并使用被授权的应用。",
      noPermissionText: "由于您还没开通权限，无法进行操作",
      notFoundText: "点击返回首页",
      searchBoxPlaceholder: "请输入应用名",
      favorite: "收藏",
      unfavorite: "取消收藏",
    },
    appDev: {
      card: {
        menu: {
          copyAppProject: "复制",
        },
      },
      introduction:
        "应用开发主要用于开发者通过应用的零代码开发功能创建应用，并对应用进行编辑，发布和管理。",
      lastUpdate: "上次更新",
      lastVersion: "当前上架版本",
      addApp: "新增",
      newAppName: "应用",
    },
    connector: {
      enableSuccessful: "上架成功",
      appsName: "应用名称",
      enableVersion: "上架版本",
      mappingTip:
        "本页面用于配置测试连接器与生产连接器的映射关系，当应用上架时，系统会根据当前版本应用所配置的映射关系，将数据元中的测试连接器自动映射为生产连接器。当该版本应用下架时，则将数据源中的生产连接器自动映射为测试连接器。",
      connectionMap: "连接映射",
      enableApps: "上架应用",
    },
    viewMode: {
      name: "访问模式",
      anonymous: "匿名访问",
      notAnonymous: "非匿名访问",
      tip: "提示：访问模式选中后将无法修改，请谨慎选择！",
      nonAnonymousTip: "非匿名访问：需要用户登录；",
      anonymousTip: "匿名访问：不需要用户登录。",
    },
    appDetail: {
      tabs: {
        basicInfo: "基本信息",
        versionManage: "版本管理",
        dataPermission: "数据权限配置",
      },
      openEditor: "打开编辑器",
    },
    appType: {
      groupAppDescription: "组合应用：将多个基础应用组合为一个应用。",
      name: "应用类型",
      basicApp: "基础应用",
      groupApp: "组合应用",
      associatedApp: "关联应用",
    },
    appInitConfig: {
      userSelect: {
        department: "部门",
        user: "用户",
      },
      tips: {
        selectEnvironment: "请选择运行环境",
        selectConnection: "请选择连接器",
      },
      connection: {
        devIntroduce:
          "在开发环境，用户可以编辑应用的信息和配置、数据源等，以及发布等版本管理。",
        prodIntroduce: "在生产环境，应用一经创建，便不能再次编辑，请谨慎选择。",
        type: "类型",
        config: "连接器配置",
        select: "连接器选择",
      },
      role: "角色",
      isInheritDepartment: "是否受部门权限配置控制：",
      inheritTip:
        "若选择受部门权限配置控制，系统将会根据自定义权限及部门上的权限配置综合计算得出最终权限。",
      hasSelected: "已选",
      isIncludeSubDepartment: "是否包含子部门",
      initConfig: "立即初始化",
      initSuccessful: "配置成功",
      initSuccessfulProdTip: "应用配置成功，是否立即发布并使用？",
      initSuccessfulDevTip: "应用配置成功，是否立即编辑？",
      tab: {
        environment: "选择运行环境",
        connection: "连接配置",
        permission: "权限配置",
      },
      appsInitialized: "该应用已经初始化",
      noUserSelect: "以下角色没有选择用户",
    },
    appRelease: {
      release: "发布",
      releaseSuccessFully: "发布成功",
      releaseApp: "发布应用",
      currentAppVersion: "当前版本号",
      newAppVersion: "最新版本号",
      versionErrorMessage: {
        required: "请输入最新版本号",
        nextVersion: "最新版本号需大于当前版本号",
        patternError: "格式错误请参考xx.xx.xx",
      },
    },
    appIcon: {
      systemIcon: "系统图标",
      uploadIcon: "上传图标",
      toUploadIcon: "请上传应用图标",
      iconSizeTip: "图标最大不超过1M",
      iconTypeTip: "格式支持jpg、jpeg、png",
    },
  },
};
