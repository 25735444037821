import { createModel } from "nyax";
import { Queue, QueueQuery } from "src/models/queue";
import { QueueEntityModel } from "src/store/models/entity/queue/entity";
import { createUISideListWithPermissionModel } from "src/store/models/ui/_shared";

export const QueueSideUIModel = createModel(
  class extends createUISideListWithPermissionModel<Queue, QueueQuery>({
    setItems: (getContainer, items) =>
      getContainer(QueueEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(QueueEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(QueueEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initial: async () => {
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query: QueueQuery
            ) =>
              this.dependencies.serviceClient.queue.list(
                pageIndex,
                pageSize,
                query
              ),
          });
        },
      };
    }
  }
);
