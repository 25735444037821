import { createModel } from "nyax";
import { AiServiceInfo, InvokeService, UploadUrl } from "src/models/ocr";
import { ModelBase } from "src/store/ModelBase";
import { AIUIModel } from "src/store/models/ui/ai/ai";

export const OCRDetailUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        aiServiceDetail: [] as AiServiceInfo[],
        uploadUrl: [] as UploadUrl[],
        imageUrl: "",
        activeKey: "",
        allowFileTypes: "",
        accept: "",
        activeAlias: "",
        version: "v1" as string | undefined,
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setAiServiceDetail: (value: AiServiceInfo[]) => {
          this.state.aiServiceDetail = value;
        },
        setUploadUrl: (value: UploadUrl[]) => {
          this.state.uploadUrl = value;
        },
        setImageUrl: (value: string) => {
          this.state.imageUrl = value;
        },
        setActiveKey: (value: string) => {
          this.state.activeKey = value;
        },
        setAllowFileTypes: (value: string) => {
          this.state.allowFileTypes = value;
        },
        setAccept: (value: string) => {
          this.state.accept = value;
        },
        setActiveAlias: (value: string) => {
          this.state.activeAlias = value;
        },
        setVersion: (value: string | undefined) => {
          this.state.version = value;
        },
      };
    }
    public effects() {
      return {
        aiServiceList: async (payload: {
          aiCategory: string;
          alias: string;
        }) => {
          const { aiCategory, alias } = payload;
          const data = await this.dependencies.serviceClient.ocr.getAiService(
            aiCategory,
            alias
          );

          await this.actions.setAiServiceDetail.dispatch(data);
        },
        getVersion: async () => {
          const list = this.getContainer(AIUIModel).state.keySchemaData;

          const version = list?.filter(
            (item) =>
              item?.aiPlatform === this.state.activeKey &&
              item?.alias === this.state.activeAlias
          )?.[0]?.version;
          version && this.actions.setVersion.dispatch(version);
        },
        invoke: async (payload: InvokeService) => {
          return await this.dependencies.serviceClient.ocr.invoke(payload);
        },
      };
    }
    public selectors() {
      return {};
    }
  }
);
