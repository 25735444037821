import { AccessToken, ServiceCredential } from "@encoo-web/encoo-lib/types";
import { EncooOidcClient } from "@encoo-web/oidc-client";
import { OidcUser } from "@encoo-web/oidc-client/types/model";
import dd from "dingtalk-jsapi";

export class EncooOidcClientEx extends EncooOidcClient {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  login(params?: any): Promise<OidcUser> {
    if (window._settings.engine === "dingtalk") {
      return new Promise<OidcUser>((resolve) => {
        dd.runtime.permission.requestAuthCode({
          corpId: window._settings.corpId ?? "",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onSuccess: (info: any) => {
            resolve(
              super.login({
                ...params,
                code: info.code,
              })
            );
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any);
      });
    } else {
      return super.login(params);
    }
  }
}

export class OidcClientAuthenticationCredential implements ServiceCredential {
  private _oidcClient: EncooOidcClient;
  constructor(oidcClient: EncooOidcClient) {
    this._oidcClient = oidcClient;
  }
  getAccessToken(): Promise<AccessToken | null> {
    return this._oidcClient.login().then((user) => ({
      token: user?.access_token || "",
      expiresOnTimestamp: user?.expires_at || 0,
    }));
  }
}
