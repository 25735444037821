/** @jsxImportSource @emotion/react */
import React from "react";
import { usePermissionValue } from "src/hooks";

export default React.memo(function AuthorizedV2(props: {
  // TODO,后续删除string[],暂时需要兼容
  permissionKeys: string[];
  children?: React.ReactNode;
  type?: "some" | "every";
  noAuhorizedRender?: () => React.ReactNode;
}) {
  const {
    permissionKeys,
    type = "some",
    noAuhorizedRender = () => null,
    children,
  } = props;

  const permissionFlags = usePermissionValue(permissionKeys);
  if (type === "every") {
    if (permissionFlags.includes(false)) {
      return <React.Fragment>{noAuhorizedRender()}</React.Fragment>;
    }
    return <React.Fragment>{children}</React.Fragment>;
  }
  if (type === "some") {
    if (permissionFlags.includes(true)) {
      return <React.Fragment>{children}</React.Fragment>;
    }
    return <React.Fragment>{noAuhorizedRender()}</React.Fragment>;
  }
  return null;
});
