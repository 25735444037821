import { createModel } from "nyax";
import { LamossModelConfig, LamossRuleConfig } from "src/models/lamoss";
import { ModelBase } from "src/store/ModelBase";
export type CreateQueueContentType = "basic" | "bindRobot";

export const LasmooConfigUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        isInited: false,
        ruleConfig: {} as LamossRuleConfig,
        modelConfig: {} as LamossModelConfig,
      };
    }

    public reducers() {
      return {
        setRuleConfig: (ruleConfig: LamossRuleConfig) => {
          this.state.ruleConfig = ruleConfig;
        },
        setModelConfig: (modelConfig: LamossModelConfig) => {
          this.state.modelConfig = modelConfig;
        },
        setIsInited: (isInited: boolean) => {
          this.state.isInited = isInited;
        },
      };
    }
    public effects() {
      return {
        init: async () => {
          if (this.state.isInited) return;
          this.actions.setIsInited.dispatch(true);
          this.actions.setModelConfig.dispatch(
            await this.dependencies.serviceClient.lamossConfig.getModelConfig()
          );
          this.actions.setRuleConfig.dispatch(
            await this.dependencies.serviceClient.lamossConfig.getRuleConfig()
          );
        },
        updateRuleConfig: async (value: LamossRuleConfig) => {
          await this.dependencies.serviceClient.lamossConfig.updateRuleConfig(
            value
          );
          this.actions.setRuleConfig.dispatch(value);
        },
      };
    }
  }
);
