import axios from "axios";
import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { PaymentOrderEntityModel } from "src/store/models/entity/payment/order/entity";
import { PaymentOrderHelperModel } from "src/store/models/entity/payment/order/helper";
import { GlobalSelectCompanyUIModel } from "src/store/models/logic/globalSelectCompany";
import { GlobalSelectDepartmentModel } from "src/store/models/logic/globalSelectDepartment";

export const paymentOrderUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        isInitialized: false,
        displayOrderId: undefined as undefined | string,
        errMessage: "",
        orderAmount: undefined as undefined | number,
        isSuccessed: false,
        code: "",
      };
    }
    public reducers() {
      return {
        setInitialized: (isInitialized: boolean) => {
          this.state.isInitialized = isInitialized;
        },
        setDisplayOrderId: (orderId?: string) => {
          this.state.displayOrderId = orderId;
        },
        setErrMessage: (errMessage: string) => {
          this.state.errMessage = errMessage;
        },
        setOrderAmount: (orderAmount: number | undefined) => {
          this.state.orderAmount = orderAmount;
        },
        setIsSuccessed: (isSuccessed: boolean) => {
          this.state.isSuccessed = isSuccessed;
        },
        setCode: (code: string) => {
          this.state.code = code;
        },
      };
    }
    public selectors() {
      return {
        orders: () => this.getContainer(PaymentOrderEntityModel).getters.items,
      };
    }
    public effects() {
      return {
        initial: async () => {
          if (!this.state.isInitialized) {
            await this.getContainer(
              PaymentOrderHelperModel
            ).actions.getAll.dispatch(null);
          }
        },
        cancelOrder: async (orderId: string) => {
          return await this.getContainer(
            PaymentOrderHelperModel
          ).actions.cancel.dispatch(orderId);
        },
        getOrder: async (orderId: string) => {
          return await this.getContainer(
            PaymentOrderHelperModel
          ).actions.getById.dispatch(orderId);
        },
        calculate: async (payload: {
          productId: string;
          quantity: number;
          couponCode: string;
        }) => {
          const { productId, quantity, couponCode } = payload;

          const items = [{ productId, quantity }];

          const token =
            await this.dependencies.serviceCredential.getAccessToken({});

          const companyId = await this.getContainer(GlobalSelectCompanyUIModel)
            .state.selectedGlobalComponyId;

          const departmentId = await this.getContainer(
            GlobalSelectDepartmentModel
          ).state.selectedDepartmentId;

          const headers = {
            Authorization: `Bearer ${token?.token}`,
            CompanyId: companyId ?? "",
            DepartmentId: departmentId ?? "",
          };

          axios({
            method: "POST",
            url: `${window._settings.endpoint}/payment/Orders/calculate`,
            headers: Object.assign(
              {
                "Content-Type": "application/json",
                Pragma: "no-cache",
              },
              headers
            ),
            data: {
              items,
              couponCode,
              appName: "Console",
            },
          })
            .then((res) => {
              this.actions.setOrderAmount.dispatch(res.data.orderAmount);
              this.actions.setIsSuccessed.dispatch(true);
              this.actions.setErrMessage.dispatch("");
            })
            .catch((err) => {
              this.actions.setErrMessage.dispatch(err.response.data.message);
              this.actions.setIsSuccessed.dispatch(false);
              this.actions.setOrderAmount.dispatch(undefined);
            });
        },
      };
    }
  }
);
