import { message } from "antd";
import { createModel } from "nyax";
import messageIds from "src/locales/messageIds";
import { EncooFileUploadState } from "src/models/file";
import { KnowledgeFile, KnowledgeFileAttribute } from "src/models/knowledge";
import { ModelBase } from "src/store/ModelBase";
import { KnowledgeHelperModel } from "src/store/models/entity/knowledge/helper";
import { UploadingUIModel } from "src/store/models/ui/file/uploading";

export const UploadFileUIModel = createModel(
  class extends ModelBase {
    public effects() {
      return {
        uploadFiles: async (payload: {
          files: KnowledgeFileAttribute[];
          data: KnowledgeFile[];
        }) => {
          const { files, data } = payload;
          const addedFile: KnowledgeFile[] = [];
          let count = 0;

          const uploadingFileRecord =
            this.getContainer(UploadingUIModel).state.uploadingFileRecord;

          const foundUploading = files.find(
            (file) =>
              uploadingFileRecord[file.fileName] &&
              uploadingFileRecord[file.fileName].state !== "Completed" &&
              uploadingFileRecord[file.fileName].state !== "Failed"
          );

          if (foundUploading) {
            message.error(
              this.dependencies.locale.intl.formatMessage({
                id: messageIds.package.sameName,
              })
            );
            return;
          }

          for (const file of files) {
            const totalSize = file.fileData.size;
            const fileFullName = file.fileName;
            let isSucceed = true;

            await this.getContainer(
              UploadingUIModel
            ).actions.updateUploadCallbackInfo.dispatch({
              fullName: fileFullName,
              nodeName: file.fileName,
              state: EncooFileUploadState["Preparing"],
              percent: 0,
              totalSize,
            });

            const fileType = file.fileData["type"];
            const validTypes = [
              "image/jpeg",
              "image/png",
              "text/plain",
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ];

            if (file.fileData.size && file.fileData.size > 10485760) {
              isSucceed = false;
              await this.getContainer(
                UploadingUIModel
              ).actions.updateUploadCallbackInfo.dispatch({
                fullName: fileFullName,
                nodeName: file.fileName,
                state: EncooFileUploadState["Failed"],
                percent: 0,
                totalSize,
              });
              message.error(
                this.dependencies.locale.intl.formatMessage({
                  id: messageIds.knowledge.error.fileSize,
                })
              );
            } else if (fileType && !validTypes.includes(fileType)) {
              isSucceed = false;
              await this.getContainer(
                UploadingUIModel
              ).actions.updateUploadCallbackInfo.dispatch({
                fullName: fileFullName,
                nodeName: file.fileName,
                state: EncooFileUploadState["Failed"],
                percent: 0,
                totalSize,
              });
              message.error(
                this.dependencies.locale.intl.formatMessage({
                  id: messageIds.knowledge.error.fileType,
                })
              );
            }

            const sameName =
              data.filter((item) => item.fileName === file.fileName).length >
                0 ||
              addedFile.filter((item) => item.fileName === file.fileName)
                .length > 0;

            if (data.length + count >= 20 && !sameName) {
              isSucceed = false;
              await this.getContainer(
                UploadingUIModel
              ).actions.updateUploadCallbackInfo.dispatch({
                fullName: fileFullName,
                nodeName: file.fileName,
                state: EncooFileUploadState["Failed"],
                percent: 0,
                totalSize,
              });
              message.error(
                this.dependencies.locale.intl.formatMessage({
                  id: messageIds.knowledge.error.fileNumber,
                })
              );
            }

            if (isSucceed) {
              try {
                const { status } =
                  await this.dependencies.serviceClient.knowledge.uploadFile(
                    file
                  );

                if (status === 204 || status === 200) {
                  count = count + 1;
                  addedFile.push({
                    knowledgeBaseId: file.id,
                    fileName: file.fileName,
                    fileSize: file.fileData.size,
                    fileLastModifiedAt: new Date(
                      file.fileData.lastModified
                    ).toISOString(),
                    filePath:
                      data[0]?.filePath ??
                      `/Lamoss/${file.id}/${file.fileName}`,
                  });

                  await this.getContainer(
                    UploadingUIModel
                  ).actions.updateUploadCallbackInfo.dispatch({
                    fullName: fileFullName,
                    nodeName: file.fileName,
                    state: EncooFileUploadState["Compoining"],
                    percent: 1,
                    totalSize,
                  });

                  await this.getContainer(
                    KnowledgeHelperModel
                  ).actions.update.dispatch({
                    knowledge: {
                      id: file.id,
                      files: [...data].concat(addedFile),
                    },
                  });

                  await await this.getContainer(
                    UploadingUIModel
                  ).actions.updateUploadCallbackInfo.dispatch({
                    fullName: fileFullName,
                    nodeName: file.fileName,
                    state: EncooFileUploadState["Completed"],
                    percent: 1,
                    totalSize,
                  });
                }
              } catch (err) {
                await this.getContainer(
                  UploadingUIModel
                ).actions.updateUploadCallbackInfo.dispatch({
                  fullName: fileFullName,
                  nodeName: file.fileName,
                  state: EncooFileUploadState["Failed"],
                  percent: 0,
                  totalSize,
                });

                console.log(err);
              }
            }
          }
        },
      };
    }
  }
);
