// eslint-disable-next-line import/no-anonymous-default-export
export default {
  userManagerPage: {
    pageTitle: "User Management",
    introduction:
      "User management is used to manage the console account information, which supports inviting new users. It can perform other operations on users, such as enabling, disabling, editing and permission viewing.",
    entityTypeName: "Role",
    search: "Permission Name/Menu Name",
    permission: "Permission",
    createRole: "New Role",
    editRole: "Edit Role",
    deleteConfirm: {
      title: "Please confirm",
      content: "Confirm to delete user:",
      okText: "Delete",
    },
    query: {
      phoneNumber: "Phone Number",
      status: {
        placeholder: "All Status",
        active: "Active",
        inactive: "Inactive",
        banned: "Banned",
      },
      role: "All Roles",
    },
    column: {
      name: "User Name",
      email: "Email Address",
      assignedDepartmentName: "Affiliated Department",
      roles: "Department Role",
      status: "User Status",
      lastLoginAt: "Last Login Time",
      operation: "Operation",
      delete: "Delete",
    },
    changeUserDepartment: {
      title: "Transfer Department",
      transfer1: "You are sure that you want to transfer the user",
      transfer2: "to the following department:",
      userRole: "Affiliated Role:",
    },
    changeUserRole: {
      title: "Change Role",
      role: "Please select the role to be changed:",
    },
    userAuthorityDetail: {
      checkPermissions: "View Permission",
      permissionsDetails: "Permission Details",
      userDepartmentRoles: "Department Permission",
      userOtherDepartmentRoles: "Have permission from other departments:",
      loading: "Loading",
    },
    userBaseInfoDetail: {
      basicInformation: "Basic Info",
      userName: "User Name:",
      userEmail: "User Email Address:",
      userDepartment: "Affiliated Department:",
      userRole: "Affiliated Role:",
      status: "User Status:",
      createAt: "Creation Time:",
      lastLoginAt: "Last login time:",
      lastModifyAt: "Last modification time:",
      edit: "Edit",
      cancel: "Cancel",
      save: "Save",
    },
    inviteNewUser: {
      inviteUser: "Invite User",
      reInviteUser: "Re-invite User",
      reInviteUserConfirm:
        "Please confirm if you want to re-invite user [{name}] ?",
      account: "Account",
      userEmail: "User Email Address",
      userName: "User Name",
      password: "Password",
      userDepartment: "Affiliated Department",
      userRole: "Affiliated Role",
      sendSuccess: "Sent successfully",
      private: {
        placeholder: {
          userName: "Enter the name of the user in the current company",
          email: "Enter the account to be created. Please use an email.",
        },
        tip: "Tips: Once the creation is completed, please send the account and password to the user. The account will be activated after the user logs in for the first time and changes the password.",
      },
    },
  },
};
