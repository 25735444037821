import { createModel, createSelector } from "nyax";
import { Package } from "src/models/package";
import { ModelBase } from "src/store/ModelBase";
import { PackageEntityModel } from "src/store/models/entity/package/entity";
import { PackageHelperModel } from "src/store/models/entity/package/helper";

export const PackageSelectUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        ids: [] as string[],
      };
    }

    public reducers() {
      return {
        setIds: (value: string[]) => {
          this.state.ids = value;
        },
      };
    }

    public selectors() {
      return {
        packages: createSelector(
          () => this.state.ids,
          () => this.getContainer(PackageEntityModel).state.byId,
          (ids, packageById) => {
            return ids
              .filter((id) => !!packageById[id])
              .map((id) => packageById[id]) as Package[];
          }
        ),
      };
    }
    public effects() {
      return {
        requestAll: async () => {
          const packages = await this.getContainer(
            PackageHelperModel
          ).actions.readAll.dispatch({});
          await this.actions.setIds.dispatch(packages.map((item) => item.id));
        },
      };
    }
  }
);
