/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Dropdown, Menu } from "antd";
import { MenuInfo } from "rc-menu/lib/interface";
import React, { ReactNode, useCallback, useMemo } from "react";
import Icon from "src/components/Icon";
import { useTheme } from "src/hooks";

export interface TableOperationMenuItem {
  key?: string | number;
  name?: ReactNode;
  disabled?: boolean;
  type?: "default" | "warning";
  onClick?: (param: MenuInfo) => void;
  visible?: boolean;
}

export default React.memo(function TableOperation(props: {
  menuList: TableOperationMenuItem[];
  icon?: React.ReactNode;
  className?: string;
}) {
  const { className, icon, menuList } = props;
  const theme = useTheme();

  const visibleMenuList = useMemo(
    () => menuList.filter((menu) => (menu.visible ?? true) === true),
    [menuList]
  );

  const onClickHandle = useCallback(
    (menuInfo: MenuInfo, item: TableOperationMenuItem) => {
      menuInfo.domEvent.stopPropagation();
      if (item?.onClick) {
        item.onClick(menuInfo);
      }
    },
    []
  );

  const onClick = useCallback((e) => e.stopPropagation(), []);

  if (visibleMenuList.length === 0) {
    return null;
  }

  const menu = (
    <Menu
      css={css`
        .ant-dropdown-menu-item-active {
          background: ${theme.bodyFrameBackground};
        }
      `}
    >
      {visibleMenuList.map((item) => {
        return (
          item.name && (
            <Menu.Item
              onClick={(menuInfo) => onClickHandle(menuInfo, item)}
              key={item.key}
              disabled={item.disabled}
            >
              <span
                css={css`
                  font-size: 12px;
                  color: ${item.type === "warning"
                    ? theme.warningColor
                    : theme.bodyText};
                `}
              >
                {item.name}
              </span>
            </Menu.Item>
          )
        );
      })}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomLeft">
      <div
        className={className}
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          :hover {
            color: ${theme.primaryColor};
            border-radius: 4px;
            background: rgba(51, 119, 255, 0.1) !important;
            cursor: pointer;
          }
        `}
        onClick={onClick}
      >
        {icon ?? (
          <Icon
            name="icon-look-more"
            css={css`
              width: 10px;
              height: 24px;
            `}
          />
        )}
      </div>
    </Dropdown>
  );
});
