import { createModel } from "nyax";
import {
  JobQueue,
  JobQueueBasicData,
  WorkflowConfigsUpdateData,
} from "src/models/jobQueue";
import { JobQueueEntityModel } from "src/store/models/entity/jobQueue/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const JobQueueHelperModel = createModel(
  class extends createHelperModel<JobQueue>({
    setItems: (getContainer, items) =>
      getContainer(JobQueueEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(JobQueueEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(JobQueueEntityModel).state.byId[id],
  }) {
    public effects() {
      return {
        ...super.effects(),

        create: async (payload: { jobQueueBasicData: JobQueueBasicData }) => {
          const { jobQueueBasicData } = payload;
          return await this._create({
            createAction: async () => {
              const item =
                await this.dependencies.serviceClient.jobQueue.create(
                  jobQueueBasicData
                );
              return item;
            },
          });
        },
        update: async (payload: { item: JobQueue }) => {
          const { item } = payload;
          const { description, tags, queueName, displayName } = item;
          return await this._update({
            id: item.id,
            updateAction: async () => {
              return await this.dependencies.serviceClient.jobQueue.update(
                queueName,
                displayName,
                description,
                tags
              );
            },
          });
        },
        updateConfig: async (payload: { item: WorkflowConfigsUpdateData }) => {
          const { item } = payload;

          return await this.dependencies.serviceClient.jobQueue.updateConfig(
            item
          );
        },
        delete: async (payload: { queueName: string; id: string }) => {
          const { queueName, id } = payload;
          return await this._delete({
            id,
            deleteAction: () =>
              this.dependencies.serviceClient.jobQueue.delete(queueName),
          });
        },
      };
    }
  }
);
