/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  ConnectionEnvironment,
  CreateConnectorWithOnlyTemplate,
  DataConnection,
  useConnectorTypeList,
} from "@encoo-web/encoo-ui";
import { Input, message, notification, Radio, Select, Space, Tabs } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Button from "src/components/Button";
import Text from "src/components/Text";
import useConnectorAction from "src/containers/dataConnection/components/DataConnectionAction";
import { useConnectionEnvironment } from "src/containers/dataConnection/DataConnectionPage";
import { Entity } from "src/containers/userManager/models/entity";
import { useFormatMessage, useGetContainer } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { AppTemplateConnectionAdapter } from "src/models/appTemplate";
import { AppDataSourceUpdateData } from "src/models/appV2";
import { DataConnectionEntityModel } from "src/store/models/entity/dataConnection/entity";
import { DataConnectionHelperModel } from "src/store/models/entity/dataConnection/helper";
import { AppInitConfigUIModel } from "src/store/models/ui/apps/appInitConfig";
import { DataConnectionUiModel } from "src/store/models/ui/dataConnection/dataConnection";

const { Option } = Select;
const { TabPane } = Tabs;

export const AppInitConnectionEnvironment = memo(function (props: {
  onEnvironmentChange: (value: ConnectionEnvironment | null) => void;
}) {
  const getContainer = useGetContainer();
  const appInitConfigUIContainer = getContainer(AppInitConfigUIModel);
  const formatMessage = useFormatMessage();

  const { onEnvironmentChange } = props;
  const [environment, setEnvironment] = useState<ConnectionEnvironment | null>(
    null
  );

  const environmentList = useConnectionEnvironment();

  const onEnvironmentNextStep = useCallback(async () => {
    if (!environment) {
      message.error(
        formatMessage(messageIds.apps.appInitConfig.tips.selectEnvironment)
      );
    } else {
      onEnvironmentChange(environment);
      await appInitConfigUIContainer.actions.setCurrentConfigStep.dispatch(
        "connectionCreation"
      );
    }
  }, [
    appInitConfigUIContainer.actions.setCurrentConfigStep,
    environment,
    formatMessage,
    onEnvironmentChange,
  ]);

  const _onEnvironmentChange = useCallback((val: RadioChangeEvent) => {
    const environment = val.target.value;
    setEnvironment(environment);
  }, []);

  const descriptionStyle = css`
    font-size: 14px;
    margin-left: 25px;
  `;

  return (
    <div>
      <Radio.Group
        onChange={_onEnvironmentChange}
        css={css`
          .ant-radio-wrapper {
            font-size: 18px;
            font-weight: 500;
          }
        `}
      >
        <Space direction="vertical">
          <Radio value={"dev"}>{environmentList.dev?.name}</Radio>
          <p css={descriptionStyle}>
            {formatMessage(
              messageIds.apps.appInitConfig.connection.devIntroduce
            )}
          </p>
          <Radio value={"prod"}>{environmentList.prod?.name}</Radio>
          <p css={descriptionStyle}>
            {formatMessage(
              messageIds.apps.appInitConfig.connection.prodIntroduce
            )}
          </p>
        </Space>
      </Radio.Group>
      <Space
        size={20}
        css={css`
          display: flex;
          justify-content: flex-end;
          padding-top: 30px;
        `}
      >
        <Button type="primary" onClick={onEnvironmentNextStep}>
          {formatMessage(messageIds.common.nextStep)}
        </Button>
      </Space>
    </div>
  );
});

export const AppInitConnectionConfig = memo(function ({
  connectionAdapter,
  environment,
  onConnectionNextStep,
  okText,
}: {
  connectionAdapter: AppTemplateConnectionAdapter;
  environment: ConnectionEnvironment;
  onConnectionNextStep: (connectionId: string) => void;
  okText?: string;
}) {
  const formatMessage = useFormatMessage();
  const getContainer = useGetContainer();
  const dataConnectionContainer = getContainer(DataConnectionUiModel);

  const [connectionName, setConnectionName] = useState<string>();
  const dataConnectionTemplateInfos = useSelector(
    () => dataConnectionContainer.state.dataConnectionTemplateInfos
  );
  const { create, testWithConfig } = useConnectorAction();

  const additionalConnectorInfo = useMemo(() => {
    const { connectionType } = connectionAdapter;
    return {
      type: connectionType,
      name: connectionName ?? "",
      environment,
    };
  }, [connectionAdapter, connectionName, environment]);

  const onItemTestWithConfig = useCallback(
    async (values: Omit<DataConnection, keyof Entity | "id">) => {
      return await testWithConfig(values);
    },
    [testWithConfig]
  );

  const onItemCreate = useCallback(
    async (values: Omit<DataConnection, keyof Entity | "id">) => {
      if (!values.name) {
        notification.error({
          message: formatMessage(messageIds.dataConnection.tip.name),
        });
        return false;
      }
      const item = await create(values);
      if (item) {
        onConnectionNextStep(item.id);
      }
      return !!item;
    },
    [create, formatMessage, onConnectionNextStep]
  );

  useEffect(() => {
    setConnectionName(connectionAdapter.connectionName);
  }, [connectionAdapter.connectionName]);

  return (
    <div>
      <div>
        <Text
          hasAsterisk
          css={css`
            font-weight: bold;
            color: rgb(61, 73, 102);
            margin-bottom: 8px;
          `}
        >
          {formatMessage(messageIds.dataConnection.listFields.name)}
        </Text>
        <Input
          value={connectionName}
          onChange={(e) => setConnectionName(e.target.value)}
        ></Input>
      </div>

      {dataConnectionTemplateInfos && (
        <CreateConnectorWithOnlyTemplate
          onConnectorTest={onItemTestWithConfig}
          onConnectorCreate={onItemCreate}
          dataConnectionTemplateInfos={dataConnectionTemplateInfos ?? undefined}
          additionalConnectorInfo={additionalConnectorInfo}
          creationBtnText={okText ?? formatMessage(messageIds.common.nextStep)}
        />
      )}
    </div>
  );
});

export const AppInitConnectionSelect = memo(function ({
  connectionAdapter,
  environment,
  onConnectionNextStep,
  okText,
}: {
  connectionAdapter: AppTemplateConnectionAdapter;
  environment: ConnectionEnvironment;
  onConnectionNextStep: (connectionId: string) => void;
  okText?: string;
}) {
  const getContainer = useGetContainer();
  const dataConnectionEntityContainer = getContainer(DataConnectionEntityModel);
  const formatMessage = useFormatMessage();

  const dataConnectionList = useSelector(
    () => dataConnectionEntityContainer.getters.items
  );

  const dataSource = useMemo(
    () => dataConnectionList.filter((item) => item.environment === environment),
    [dataConnectionList, environment]
  );

  const [connectionId, setConnection] = useState<string | null>(null);

  const onNextStepHandle = useCallback(() => {
    if (connectionId) {
      onConnectionNextStep(connectionId);
    } else {
      message.error(
        formatMessage(messageIds.apps.appInitConfig.tips.selectConnection)
      );
    }
  }, [connectionId, formatMessage, onConnectionNextStep]);

  const onConnectionChange = useCallback((val) => {
    setConnection(val);
  }, []);

  return (
    <div>
      <Select
        css={css`
          width: 100%;
        `}
        onChange={onConnectionChange}
      >
        {dataSource.map((item) => (
          <Option value={item.id} key={item.id}>
            {item.name}
          </Option>
        ))}
      </Select>
      <div
        css={css`
          text-align: right;
          padding-top: 20px;
        `}
      >
        <Button type="primary" onClick={onNextStepHandle}>
          {okText ?? formatMessage(messageIds.common.nextStep)}
        </Button>
      </div>
    </div>
  );
});

export const AppInitConnectionCreation = memo(function ({
  connectionAdapter,
  environment,
  onSelectConnectionCallback,
  okText,
}: {
  connectionAdapter: AppTemplateConnectionAdapter;
  environment: ConnectionEnvironment;
  onSelectConnectionCallback: (connectionId: string) => void;
  okText?: string;
}) {
  const formatMessage = useFormatMessage();
  const getContainer = useGetContainer();
  const dataConnectionContainer = getContainer(DataConnectionUiModel);

  const dataConnectionTemplateInfos = useSelector(
    () => dataConnectionContainer.state.dataConnectionTemplateInfos
  );

  const connectorTypeList = useConnectorTypeList({
    dataConnectionTemplateInfos: dataConnectionTemplateInfos ?? undefined,
  });

  const currentConnectionTypeInfo = useMemo(() => {
    return connectorTypeList.find(
      (item) => item.type === connectionAdapter.connectionType
    );
  }, [connectionAdapter.connectionType, connectorTypeList]);

  return (
    <div>
      <div
        css={css`
          font-size: 18px;
          font-weight: 500;
          display: flex;
          align-items: center;
        `}
      >
        {currentConnectionTypeInfo?.icon && (
          <img
            src={currentConnectionTypeInfo.icon ?? ""}
            css={css`
              width: 30px;
              height: 30px;
            `}
            alt=""
          />
        )}
        <span>
          {currentConnectionTypeInfo?.name ?? connectionAdapter.connectionType}
          &nbsp;{formatMessage(messageIds.apps.appInitConfig.connection.type)}
        </span>
      </div>
      <Tabs>
        <TabPane
          tab={formatMessage(messageIds.apps.appInitConfig.connection.config)}
          key="config"
        >
          <AppInitConnectionConfig
            connectionAdapter={connectionAdapter}
            environment={environment}
            onConnectionNextStep={onSelectConnectionCallback}
            okText={okText}
          />
        </TabPane>
        <TabPane
          tab={formatMessage(messageIds.apps.appInitConfig.connection.select)}
          key="select"
        >
          <AppInitConnectionSelect
            connectionAdapter={connectionAdapter}
            environment={environment}
            onConnectionNextStep={onSelectConnectionCallback}
            okText={okText}
          />
        </TabPane>
      </Tabs>
    </div>
  );
});

export default memo(function AppInitConnection({
  connectionAdapterList,
  environment,
  onInitApp,
  hasPermissionConfig,
  getConnectionProgress,
}: {
  connectionAdapterList: AppTemplateConnectionAdapter[];
  environment: ConnectionEnvironment;
  onInitApp: () => void;
  hasPermissionConfig: boolean;
  getConnectionProgress: (val: number) => void;
}) {
  const formatMessage = useFormatMessage();
  const getContainer = useGetContainer();
  const appInitConfigUIContainer = getContainer(AppInitConfigUIModel);
  const dataConnectionContainer = getContainer(DataConnectionUiModel);
  const dataConnectionHelperContainer = getContainer(DataConnectionHelperModel);

  const [connectionIndex, setConnectionIndex] = useState<number>(0);
  const [selectedConnectionKeys, setSelectedConnectionKeys] = useState<
    AppDataSourceUpdateData["connAdapters"]
  >([]);

  const currentConnectionAdapter = useMemo(
    () => connectionAdapterList[connectionIndex],
    [connectionAdapterList, connectionIndex]
  );

  const onSelectConnectionCallback = useCallback(
    async (connectionId: string) => {
      const _selectedConnectionKeys = [
        ...(selectedConnectionKeys ?? []),
        {
          appTemplateConnectionId: currentConnectionAdapter.id,
          destId: connectionId,
        },
      ];
      setSelectedConnectionKeys(_selectedConnectionKeys);
      if (connectionIndex + 1 < connectionAdapterList.length) {
        setConnectionIndex(connectionIndex + 1);
      } else {
        appInitConfigUIContainer.actions.setModifyUpdateData.dispatch({
          connAdapters: _selectedConnectionKeys,
        });
        if (hasPermissionConfig) {
          await appInitConfigUIContainer.actions.setCurrentConfigStep.dispatch(
            "permission"
          );
        } else {
          onInitApp();
        }
      }
    },
    [
      appInitConfigUIContainer.actions.setCurrentConfigStep,
      appInitConfigUIContainer.actions.setModifyUpdateData,
      connectionAdapterList.length,
      connectionIndex,
      currentConnectionAdapter.id,
      hasPermissionConfig,
      onInitApp,
      selectedConnectionKeys,
    ]
  );

  useEffect(() => {
    dataConnectionContainer.actions.fetchTemplates.dispatch(null);
  }, [dataConnectionContainer.actions.fetchTemplates]);

  useEffect(() => {
    dataConnectionHelperContainer.actions.getAll.dispatch({
      type: currentConnectionAdapter.connectionType,
    });
  }, [
    currentConnectionAdapter.connectionType,
    dataConnectionHelperContainer.actions.getAll,
  ]);

  useEffect(() => {
    getConnectionProgress(connectionIndex);
  }, [connectionIndex, getConnectionProgress]);

  return (
    <AppInitConnectionCreation
      connectionAdapter={currentConnectionAdapter}
      environment={environment}
      onSelectConnectionCallback={onSelectConnectionCallback}
      key={connectionIndex}
      okText={
        !hasPermissionConfig &&
        connectionIndex + 1 === connectionAdapterList.length
          ? formatMessage(messageIds.common.complete)
          : formatMessage(messageIds.common.nextStep)
      }
    />
  );
});
