import { RobotDetailUIModel } from "src/store/models/ui/robot/robotDetail";
import { RobotJobUIModel } from "src/store/models/ui/robot/robotJob";
import { RobotSelectTableUIModel } from "src/store/models/ui/robot/robotSelectTable";
import { RobotSettingsUIModel } from "src/store/models/ui/robot/robotSettings";
import { RobotSideUIModel } from "src/store/models/ui/robot/robotSide";
import { RobotTableUIModel } from "src/store/models/ui/robot/robotTable";
import { RobotTaskSchedulerTableUIModel } from "src/store/models/ui/robot/robotTaskSchedulerTable";

export const encooRobotUIModels = {
  robotDetail: RobotDetailUIModel,
  robotTable: RobotTableUIModel,
  robotTaskSchedulerTable: RobotTaskSchedulerTableUIModel,
  robotSelectTable: RobotSelectTableUIModel,
  robotSide: RobotSideUIModel,
  robotJob: RobotJobUIModel,
  robotSetting: RobotSettingsUIModel,
};
