import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import {
  Installation,
  InstallationCreationData,
  InstallationPublishedQuery,
} from "src/models/installation";

export const INSTALLATION_SERVICE_NAME = "installation";

export class InstallationServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, INSTALLATION_SERVICE_NAME);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async list(resourceGroupId?: string) {
    const req = this.buildRequest({
      url: "/v2/installations",
      method: "GET",
      resourceGroupId,
    });
    return await super.listInternally<Installation>(req);
  }

  async getAll(query: InstallationCreationData): Promise<Array<Installation>> {
    const { productName, sku, status, limit } = query;
    const req = this.buildRequest({
      url: `/v2/installations`,
      method: "GET",
      query: { productName, sku, status, limit },
    });
    return await super.getAllInternal(req);
  }

  async getPublishedPackages(
    query: InstallationPublishedQuery
  ): Promise<Array<Installation>> {
    const { sku, productName } = query;
    const req = this.buildRequest({
      url: `/v2/installations/${sku}/publishedpackages/${productName ?? ""}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async getDownloadUrlById(id: string): Promise<string> {
    const req = this.buildRequest({
      url: `/v2/installations/${id}/downloadurl`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }
}
