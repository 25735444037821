export type ReadonlyUnion<T> = Readonly<T> | T;

export type RecordKey = string | number | symbol;
export interface ObjectType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [propName: RecordKey]: any;
}

export type MapFromArray<
  T extends ReadonlyUnion<ObjectType[]> | ReadonlyUnion<[...Array<ObjectType>]>,
  K extends keyof T[number]
> = {
  [Item in T[number] as K extends keyof Item
    ? `${Item[K] extends string ? Item[K] : never}`
    : never]: Item;
};

export function createMapFromArray<
  T extends ReadonlyUnion<ObjectType[]> | ReadonlyUnion<[...Array<ObjectType>]>,
  K extends keyof T[number]
>(data: T, key: K) {
  return Object.fromEntries(
    data.map((item) => [
      item[key as keyof typeof item].toLocaleLowerCase(),
      item,
    ])
  ) as MapFromArray<T, K>;
}
