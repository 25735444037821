import _ from "lodash";
import { createModel, createSelector } from "nyax";
import {
  DepartmentTreeData,
  PermissionTargetDepartment,
} from "src/models/department";
import { ModelBase } from "src/store/ModelBase";
import { DepartmentHelperModel } from "src/store/models/entity/department/helper";
import { RouterModel } from "src/store/models/router";

export type DepartmentTabKey =
  | "user"
  | "dataPermission"
  | "featurePermission"
  | "departmentConfig";

export const DepartmentModel = createModel(
  class extends ModelBase {
    private get _departmentHelperModel() {
      return this.getContainer(DepartmentHelperModel);
    }
    public initialState() {
      return {
        departmentTreeData: null as DepartmentTreeData | null,
        departmentUserTreeData: null as DepartmentTreeData | null,
        departmentRobotTreeData: null as DepartmentTreeData | null,
        isDepartmentSelectOpen: false as boolean,
        isRefreshing: false,
      };
    }

    public selectors() {
      return {
        selectedDepartmentId: createSelector(
          () => {
            const routeInfo =
              this.getContainer(RouterModel).getters.currentRouteInfo;
            if (
              routeInfo.type === "organization" &&
              !_.isEmpty(routeInfo.params.id)
            ) {
              return routeInfo.params.id;
            }

            return null;
          },
          (departmentId) => departmentId
        ),
        departments: createSelector(
          () => this.state.departmentTreeData,
          (data: DepartmentTreeData | null) => {
            const departments = [];
            const stack = data?.rootDepartment ? [data.rootDepartment] : [];

            while (stack.length > 0) {
              const department = stack.pop();

              if (department) {
                departments.push(department);
                stack.push(...(department.children ?? []));
              }
            }

            return departments;
          }
        ),
        byId: createSelector(
          (): PermissionTargetDepartment[] => this.getters.departments,
          (departments) => {
            return Object.fromEntries(
              departments.map((item) => [item.id, item])
            );
          }
        ),
      };
    }

    public reducers() {
      return {
        setDepartmentTreeData: (value: DepartmentTreeData | null) => {
          this.state.departmentTreeData = value;
        },
        setDepartmentUserTreeData: (value: DepartmentTreeData | null) => {
          this.state.departmentUserTreeData = value;
        },
        setDepartmentRobotTreeData: (value: DepartmentTreeData | null) => {
          this.state.departmentRobotTreeData = value;
        },
        setIsDepartmentSelectOpen: (value: boolean) => {
          this.state.isDepartmentSelectOpen = value;
        },
        setIsRefreshing: (value: boolean) => {
          this.state.isRefreshing = value;
        },
      };
    }
    public effects() {
      return {
        refreshDepartmentTree: async () => {
          const value = await this.getContainer(
            DepartmentHelperModel
          ).actions.getTree.dispatch(null);
          await this.actions.setDepartmentTreeData.dispatch(value);
          return value;
        },
        getDepartmentUserTreeData: async () => {
          const data =
            await this._departmentHelperModel.actions.getDepartmentUserTree.dispatch(
              {}
            );
          if (data) {
            await this.actions.setDepartmentUserTreeData.dispatch(data);
          }
        },
        getDepartmentRobotTreeData: async () => {
          const data =
            await this._departmentHelperModel.actions.getDepartmentRobotTree.dispatch(
              {}
            );
          if (data) {
            await this.actions.setDepartmentRobotTreeData.dispatch(data);
          }
        },
        refreshWechatDepartmentTree: async () => {
          try {
            await this.actions.setIsRefreshing.dispatch(true);
            await this.dependencies.serviceClient.company.updateWechatOrganization();
            await this.actions.refreshDepartmentTree.dispatch({});
          } finally {
            await this.actions.setIsRefreshing.dispatch(false);
          }
        },
        refreshDingdingDepartmentTree: async () => {
          try {
            await this.actions.setIsRefreshing.dispatch(true);
            await this.dependencies.serviceClient.company.updateDingdingOrganization();
            await this.actions.refreshDepartmentTree.dispatch({});
          } finally {
            await this.actions.setIsRefreshing.dispatch(false);
          }
        },
      };
    }
  }
);
