import moment from "moment";
import { createModel, createSelector } from "nyax";
import { filter, map, pairwise } from "rxjs/operators";
import { AppV2, AppVersionV2 } from "src/models/appV2";
import { ModelBase } from "src/store/ModelBase";
import { AppEntityModel } from "src/store/models/entity/apps/entity";
import { AppHelperModel } from "src/store/models/entity/apps/helper";
import { AppVersionEntityModel } from "src/store/models/entity/appVersion/entity";
import { AppVersionHelperModel } from "src/store/models/entity/appVersion/helper";
import { DataPermissionEntityModel } from "src/store/models/entity/dataPermission/entity";
import { DataPermissionHelperModel } from "src/store/models/entity/dataPermission/helper";
import { GlobalSelectDepartmentModel } from "src/store/models/logic/globalSelectDepartment";
import { RouterModel } from "src/store/models/router";

export const AppDetailUIModel = createModel(
  class extends ModelBase {
    private get _appHelperContainer() {
      return this.getContainer(AppHelperModel);
    }
    private get _appEntityContainer() {
      return this.getContainer(AppEntityModel);
    }
    private get _appVersionHelperContainer() {
      return this.getContainer(AppVersionHelperModel);
    }
    private get _appVersionEntityContainer() {
      return this.getContainer(AppVersionEntityModel);
    }
    private get _globalSelectDepartmentModel() {
      return this.getContainer(GlobalSelectDepartmentModel);
    }
    private get _dataPermissionEntityModel() {
      return this.getContainer(DataPermissionEntityModel);
    }

    public initialState() {
      return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        appId: this.containerKey!,
        selectedAppVersionId: null as string | null,
        isInitialized: false,
      };
    }
    public reducers() {
      return {
        initializeSuccess: () => {
          this.state.isInitialized = true;
        },

        setSelectedAppVersionId: (selectedAppVersionId: string | null) => {
          this.state.selectedAppVersionId = selectedAppVersionId;
        },
      };
    }

    public selectors() {
      return {
        app: createSelector(
          () => this._globalSelectDepartmentModel.state.selectedDepartmentId,
          () => this.state.isInitialized,
          () => this._appEntityContainer.state.byId[this.state.appId],
          () => this._dataPermissionEntityModel.state.byId,
          (
            selectedDepartmentId,
            isInitialized,
            app,
            dataPermission
          ): AppV2 | undefined => {
            const appDetail = isInitialized ? app : undefined;
            return appDetail?.departmentId === selectedDepartmentId
              ? {
                  ...appDetail,
                  permissionValues:
                    dataPermission[this.state.appId]?.permissionValues ?? [],
                }
              : undefined;
          }
        ),

        appVersions: createSelector(
          (): AppV2 | undefined => this.getters.app,
          () => this._globalSelectDepartmentModel.state.selectedDepartmentId,
          () => this._appVersionEntityContainer.getters.items,
          (app: AppV2 | undefined, selectedDepartmentId, items) => {
            return items
              .filter(
                (item) =>
                  item.appId === app?.id &&
                  app.departmentId === selectedDepartmentId
              )
              .sort((item1, item2) =>
                moment(item1.createdAt) > moment(item2.createdAt)
                  ? -1
                  : moment(item1.createdAt) === moment(item2.createdAt)
                  ? 0
                  : 1
              );
          }
        ),
        selectedAppVersionId: createSelector(
          (): AppV2 | undefined => this.getters.app,
          () => this.state.selectedAppVersionId,
          (app, selectedAppVersionId): string | undefined =>
            selectedAppVersionId ??
            app?.currentVersionId ??
            app?.lastAvailableVersionId
        ),
        selectedAppVersion: (): AppVersionV2 | undefined =>
          this.getters.selectedAppVersionId
            ? this._appVersionEntityContainer.state.byId[
                this.getters.selectedAppVersionId
              ]
            : undefined,
      };
    }

    public effects() {
      return {
        initializeRequest: async () => {
          const app = await this._appHelperContainer.actions.readById.dispatch({
            id: this.state.appId,
            force: true,
          });
          if (app) {
            await this._appVersionHelperContainer.actions.readByAppIds.dispatch(
              {
                appIds: [app.id],
                force: true,
              }
            );
          }
          await this.getContainer(
            DataPermissionHelperModel
          ).actions.getById.dispatch({
            resourceType: "App",
            id: this.state.appId,
          });
          await this.actions.initializeSuccess.dispatch({});
        },
      };
    }

    public epics() {
      return {
        resetSelectedAppVersionIdWhenDeleted: () =>
          this.rootAction$.pipe(
            filter(
              () =>
                this.state.isInitialized && !!this.state.selectedAppVersionId
            ),
            map(() => this.getters.appVersions ?? []),
            pairwise(),
            filter(([oldAppVersions, newAppVersions]: AppVersionV2[][]) => {
              let needUpdate = false;
              if (oldAppVersions !== newAppVersions) {
                const selectedAppVersionId = this.state.selectedAppVersionId;
                if (!selectedAppVersionId) {
                  needUpdate = false;
                } else {
                  needUpdate = !newAppVersions?.some(
                    (item) => item.id === selectedAppVersionId
                  );
                }
              } else {
                needUpdate = false;
              }
              return needUpdate;
            }),
            map(() => this.actions.setSelectedAppVersionId.create(null))
          ),
        resetSelectedAppVersionIdWhenRouteChanged: () =>
          this.rootAction$.pipe(
            filter(
              () =>
                this.state.isInitialized && !!this.state.selectedAppVersionId
            ),
            map(
              () =>
                this.getContainer(RouterModel).getters.currentRouteInfo.params
            ),
            pairwise(),
            filter(
              ([oldCurrentRouteInfo, newCurrentRouteInfo]) =>
                oldCurrentRouteInfo !== newCurrentRouteInfo
            ),
            map(() => this.actions.setSelectedAppVersionId.create(null))
          ),
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
