import { createModel } from "nyax";
import { DataQueue, DataQueueBasicData } from "src/models/dataQueue";
import { DataQueueEntityModel } from "src/store/models/entity/dataQueue/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const DataQueueHelperModel = createModel(
  class extends createHelperModel<DataQueue>({
    setItems: (getContainer, items) =>
      getContainer(DataQueueEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(DataQueueEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(DataQueueEntityModel).state.byId[id],
  }) {
    public effects() {
      return {
        ...super.effects(),

        create: async (payload: { dataQueueBasicData: DataQueueBasicData }) => {
          const { dataQueueBasicData } = payload;
          return await this._create({
            createAction: async () => {
              const item =
                await this.dependencies.serviceClient.dataQueue.create(
                  dataQueueBasicData
                );
              return item;
            },
          });
        },
        update: async (payload: { item: DataQueue }) => {
          const { item } = payload;
          const { description, tags, queueName } = item;
          return await this._update({
            id: item.id,
            updateAction: async () => {
              return await this.dependencies.serviceClient.dataQueue.update(
                queueName,
                description,
                tags
              );
            },
          });
        },
        getMessages: async (payload: { queueName: string }) => {
          const { queueName } = payload;
          return await this.dependencies.serviceClient.dataQueue.getMessages(
            queueName
          );
        },
        delete: async (payload: { queueName: string; id: string }) => {
          const { queueName, id } = payload;
          return await this._delete({
            id,
            deleteAction: () =>
              this.dependencies.serviceClient.dataQueue.delete(queueName),
          });
        },
        deleteBatch: async (payload: { queueIds: string[] }) => {
          const { queueIds } = payload;
          return await this.dependencies.serviceClient.dataQueue.batchDelete(
            queueIds
          );
        },
      };
    }
  }
);
