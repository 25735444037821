/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import Button from "src/components/Button";
import Icon from "src/components/Icon";
import Text from "src/components/Text";
import { useFormatMessage, useTheme } from "src/hooks";
import messageIds from "src/locales/messageIds";

export const CloseButton = React.memo(function (props: {
  close?: () => void;
  className?: string;
}) {
  const formatMessage = useFormatMessage();
  const theme = useTheme();
  const { close, className } = props;
  return (
    <Button
      className={className}
      onClick={close}
      type="text"
      css={css`
        font-size: 14px;
        line-height: 14px;
        color: ${theme.bodyText};
        padding: 0;
      `}
    >
      <Icon
        name="icon-goback"
        css={css`
          font-size: 14px;
          color: ${theme.bodyText};
        `}
      />
      {formatMessage(messageIds.common.goBack)}
    </Button>
  );
});

export default React.memo(function PageHeader(props: {
  title?: React.ReactNode;
  children?: React.ReactNode;
  showClose?: boolean;
  close?: () => void;
  closeButtonClassName?: string;
  hasUnderline?: boolean;
  className?: string;
}) {
  const theme = useTheme();
  const {
    title,
    children,
    showClose,
    close,
    closeButtonClassName,
    hasUnderline = true,
    className,
  } = props;

  const cssBorder = css`
    border-bottom: 1px solid ${theme.bodyFrameDivider};
  `;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
        ${hasUnderline && cssBorder};
      `}
      className={className}
    >
      <div
        css={css`
          font-size: 20px;
          font-weight: 600;
          line-height: 32px;
        `}
      >
        <Text>{title}</Text>
      </div>
      <div>
        <span
          css={css`
            margin-right: 20px;
          `}
        >
          {children}
        </span>
        {showClose && (
          <CloseButton close={close} className={closeButtonClassName} />
        )}
      </div>
    </div>
  );
});
