import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import {
  Calendars,
  CalendarsCreationData,
  CalendarsQuery,
  CalendarsUpdateData,
} from "src/models/calendars";
import { EncooBatchResult, EncooListEntity } from "src/models/_shared";

export const CALENDARS_SERVICE_NAME = "calendars";
export class CalendarsService extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, CALENDARS_SERVICE_NAME);
  }

  async list(
    departmentId: string,
    pageIndex: number,
    pageSize: number,
    query?: CalendarsQuery
  ): Promise<EncooListEntity<Calendars>> {
    const req = this.buildRequestV2({
      url: `/v2/calendars`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
        searchString: query?.searchString,
        startTime: query?.startTime,
        endTime: query?.endTime,
      },
      departmentId,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getById(calendarsId: string): Promise<Calendars> {
    const req = this.buildRequestV2({
      url: `/v2/calendars/${calendarsId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async create(payload: CalendarsCreationData): Promise<Calendars> {
    const req = this.buildRequestV2({
      url: `/v2/calendars`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  async update(payload: {
    id: string;
    params: CalendarsUpdateData;
  }): Promise<Calendars> {
    const req = this.buildRequestV2({
      url: `/v2/calendars/${payload.id}`,
      method: "PATCH",
      payload: payload.params,
    });
    // if (calendars.eTag) {
    //   req.headers?.set(Constants.HeaderConstants.IF_MATCH, calendars.eTag);
    // }
    return await super.updateInternal(req);
  }

  async delete(calendarsId: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/calendars/${calendarsId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }

  async batchDelete(calendarsIds: string[]): Promise<EncooBatchResult> {
    const req = this.buildRequestV2({
      url: `/v2/calendars/batchDelete`,
      method: "POST",
      payload: {
        calendarsIds,
      },
    });
    return await super.updateInternal<EncooBatchResult>(req);
  }
}
