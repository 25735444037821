import { createModel } from "nyax";
import {
  UserManagerQuery,
  UserManagerUserDetail,
} from "src/containers/userManager/models/UserManagerUserModel";
import { UserManagementEntityModel } from "src/store/models/entity/userManagement/entity";
import { createUITableBaseModel } from "src/store/models/ui/_shared";

export const UserManagementTableUIModel = createModel(
  class extends createUITableBaseModel<UserManagerUserDetail, UserManagerQuery>(
    {
      setItems: (getContainer, items) =>
        getContainer(UserManagementEntityModel).actions.setItems.dispatch(
          items
        ),
      getItems: (getContainer) =>
        getContainer(UserManagementEntityModel).getters.items,
      getItem: (getContainer, id) =>
        getContainer(UserManagementEntityModel).state.byId[id],
      getItemId: (item) => item.id,
    }
  ) {
    public effects() {
      return {
        ...super.effects(),
        initial: async () => {
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query: UserManagerQuery
            ) =>
              this.dependencies.serviceClient.userManagerServiceClient.list(
                pageIndex,
                pageSize,
                query
              ),
          });
        },
      };
    }
  }
);
