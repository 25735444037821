/** @jsxImportSource @emotion/react */
import { css, Global } from "@emotion/react";
import { DepartmentName } from "@encoo-web/encoo-ui";
import { Input, Tooltip, Tree, TreeSelect } from "antd";
import { DataNode, TreeProps } from "antd/lib/tree";
import { TreeNode, TreeSelectProps } from "antd/lib/tree-select";
import _ from "lodash";
import { BaseSelectRef } from "rc-select";
import React, {
    Fragment,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import { useSelector } from "react-redux";
import IconExpanded from "src/assets/icon_expanded.svg";
import Icon from "src/components/Icon";
import { useFormatMessage, useGetContainer, useTheme } from "src/hooks";
import messageIds from "src/locales/messageIds";
import {
    Department,
    DepartmentTreeData,
    PermissionTargetDepartment
} from "src/models/department";
import { GlobalSelectCompanyUIModel } from "src/store/models/logic/globalSelectCompany";
import { GlobalSelectDepartmentModel } from "src/store/models/logic/globalSelectDepartment";
import { DepartmentModel } from "src/store/models/ui/organization/department";
export interface DepartmentTreeProps extends TreeProps {
  titleRightContent?: ReactNode;
  itemRightContentRender?: (val: Department) => ReactNode;
  onSelectCompony?: (val: string[]) => void;
  iconName?: string;
}

export default React.memo(function DepartmentTree(props: DepartmentTreeProps) {
  const formatMessage = useFormatMessage();
  const getContainer = useGetContainer();
  const departmentContainer = getContainer(DepartmentModel);
  const theme = useTheme();
  const globalSelectCompanyUIModel = getContainer(GlobalSelectCompanyUIModel);
  const companyFrom = useSelector(
    () => globalSelectCompanyUIModel.getters.companyFrom
  );

  const displayTreeData = useSelector(
    () => departmentContainer.state.departmentTreeData
  );

  const {
    titleRightContent,
    itemRightContentRender,
    onSelectCompony,
    selectedKeys,
    iconName,
    ...restProps
  } = props;

  const treeStyle = css`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    background-color: transparent;
    color: ${theme.bodyText};
    min-width: 0;

    .ant-tree-list-holder {
      display: flex;
      flex-grow: 1;
      min-width: 0;
      width: fit-content;
      min-width: 100%;
    }
    .ant-tree-title {
      width: 100%;
      min-width: 5rem;
      > div {
        width: 100%;
        > div {
          min-width: 1rem;
        }
      }
    }

    .ant-tree-list-holder > div {
      display: flex;
      flex-grow: 1;
      min-width: 0;
    }

    .ant-tree-list-holder-inner {
      display: flex;
      flex-grow: 1;
      min-width: 0;
    }

    .ant-tree-treenode {
      min-width: 0;
      padding: 0;
      display: flex;
      align-items: center;
      :hover {
        background: ${theme.bodyFrameBackground};
        .itemRightContentStyle {
          visibility: visible;
        }
      }
      .ant-tree-node-content-wrapper.ant-tree-node-selected {
        color: ${theme.bodyText} !important;
      }
    }
    .ant-tree-treenode {
      ::before {
        background: transparent !important;
      }
      min-width: 0;
    }
    .ant-tree-treenode-selected {
      ::before {
        background: transparent !important;
      }
      background: ${theme.bodyFrameBackground} !important;
      min-width: 0;
    }
    .ant-tree-switcher {
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${theme.bodySubtext} !important;
      min-width: 0;
      width: 34px;
      padding-left: 10px;
    }
  `;

  const [searchValue, setSearchValue] = useState("");
  const [searchResultTree, setSearchResultTree] =
    useState<PermissionTargetDepartment | null>(null);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const treeData = useMemo(() => {
    return displayTreeData && _.cloneDeep(displayTreeData.rootDepartment);
  }, [displayTreeData]);

  const getTreeData = useCallback(
    (data?: Department[]) => {
      if (data && data.length > 0) {
        return data?.map((departmentItem: Department) => {
          const item = departmentItem as {
            title: ReactNode;
            key: string;
          } & Department;

          const index = item.name.indexOf(searchValue);
          const beforeStr = item.name.substr(0, index);
          const afterStr = item.name.substr(index + searchValue.length);

          item.title = (
            <span
              css={css`
                padding: 4px 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  min-height: 30px;
                `}
              >
                <Icon
                  name={iconName ?? "icon-department-tree"}
                  css={css`
                    color: ${theme.primaryColor};
                    font-size: 25px;
                    padding-right: 10px;
                  `}
                />
                <Tooltip title={item.name}>
                  <div
                    // todo
                    css={[
                      css`
                        display: inline-flex;
                        width: 100%;
                        span {
                          max-width: 10rem;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                          display: inline-block;
                        }
                      `,
                    ]}
                  >
                    {index > -1 && companyFrom !== "QyWechat" ? (
                      <Fragment>
                        <span>{beforeStr}</span>
                        <span
                          css={css`
                            color: #f50;
                          `}
                        >
                          {searchValue}
                        </span>
                        <span>{afterStr}</span>
                      </Fragment>
                    ) : (
                      <DepartmentName
                        openid={item.name}
                        companyFrom={companyFrom}
                      />
                    )}

                    {item.userCount !== undefined ? `(${item.userCount})` : ""}
                  </div>
                </Tooltip>
              </div>
              <span
                className="itemRightContentStyle"
                css={css`
                  visibility: ${selectedKeys?.[0] === item.id
                    ? "visible"
                    : "hidden"};
                `}
              >
                {itemRightContentRender?.(departmentItem)}
              </span>
            </span>
          );
          item.key = item.id;
          if (item.children) {
            getTreeData(item.children);
          }
          // return item as DataNode;
          return item;
        });
      } else {
        const title = (
          <span
            css={css`
              margin-left: 45px;
            `}
          >
            {formatMessage(messageIds.organization.noMatch)}
          </span>
        );
        return [
          {
            title,
            key: "0",
            disabled: true,
            selectable: false,
          },
        ];
      }
    },
    [
      companyFrom,
      formatMessage,
      iconName,
      itemRightContentRender,
      searchValue,
      selectedKeys,
      theme.primaryColor,
    ]
  );

  const departmentTreeDataJsx = useMemo(() => {
    let data: PermissionTargetDepartment | null = null;
    if (searchValue && searchResultTree) {
      data = searchResultTree;
    } else {
      data = treeData;
    }

    const jsxElementData = getTreeData(data?.children);

    return jsxElementData || [];
  }, [treeData, getTreeData, searchResultTree, searchValue]);

  const onSelectCompany = useCallback(() => {
    if (treeData?.id) {
      onSelectCompony?.([treeData?.id]);
    }
  }, [onSelectCompony, treeData?.id]);

  const companyRender = useMemo(() => {
    const isSelected = treeData?.id === selectedKeys?.[0];
    return (
      <div
        onClick={onSelectCompany}
        css={[
          css`
            padding: 8px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 30px;
          `,
          isSelected
            ? css`
                background: ${theme.bodyFrameBackground};
              `
            : css`
                color: ${theme.bodyText};
              `,
          css`
            :hover {
              background: ${theme.bodyFrameBackground};
              cursor: pointer;
            }
          `,
        ]}
      >
        <Icon
          name="icon-tenant"
          css={css`
            margin-right: 8px;
            font-size: 14px;
          `}
        />
        <Tooltip title={treeData?.name ?? ""}>
          <div
            css={css`
              font-weight: 600;
              display: inline-flex;
              width: 100%;
              span {
                max-width: 10rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
              }
            `}
          >
            <DepartmentName
              openid={treeData?.name ?? ""}
              companyFrom={companyFrom}
            />
            <span
              css={css`
                margin-left: 5px;
              `}
            >
              ({treeData?.userCount})
            </span>
          </div>
        </Tooltip>
        {titleRightContent}
      </div>
    );
  }, [
    companyFrom,
    onSelectCompany,
    selectedKeys,
    theme.bodyFrameBackground,
    theme.bodyText,
    titleRightContent,
    treeData?.id,
    treeData?.name,
    treeData?.userCount,
  ]);

  /**
   * @function allTreeNodeList: 扁平化树结构数据
   * @returns PermissionTargetDepartment[]
   */
  const allTreeNodeList = useMemo(() => {
    const stack = treeData?.children?.map((item) => item) ?? [];

    const nodes: PermissionTargetDepartment[] = [];

    treeData && nodes.push({ ...treeData, children: [] });

    while (stack.length > 0) {
      const first = stack.pop();
      if (first) {
        nodes.push({ ...first, children: [] });
        first?.children?.forEach((item) => {
          stack.push(item);
        });
      }
    }

    return nodes;
  }, [treeData]);

  /**
   * @function findAllPathNodes: 找出结果节点;
   * @param input: 输入
   * @param relatedIds: 找到的叶子节点的父node
   * @param allNodes: Tree扁平list
   * @var stack: 深拷贝relatedIds
   * @returns nodesWeNeed: 从扁平tree中过滤出的组成新tree所有所需的node
   */
  const findAllPathNodes = useCallback(
    (
      input: string,
      relatedIds: (string | null)[],
      allNodes: typeof allTreeNodeList
    ) => {
      const stack = _.cloneDeep(relatedIds) ?? [];
      let nodesWeNeed: typeof allTreeNodeList = [];
      // 由于传入的relatedIds是搜索目标的父node, 所以对部门来说还要从平铺的treelist中添入自身

      const departmentHasbeenSearched = allNodes?.filter((item) => {
        return item.name.indexOf(input) > -1;
      });
      nodesWeNeed = departmentHasbeenSearched;
      // 从关联node数组中逐个排出同时对全节点逐个遍历，向结果arr中push；之后压入下一个关联node的记号(parentId)
      while (stack.length > 0) {
        const first = stack.shift();
        for (let i = 0; i < allNodes.length; i++) {
          // 在全nodes里找到同id；并且不在已记录的结果中
          if (
            first === allNodes[i].id &&
            !nodesWeNeed.some((item: { id: string }) => item.id === first)
          ) {
            nodesWeNeed.push({
              ...allNodes[i],
            });

            stack.push(allNodes[i].parentId);
          }
        }
      }

      return nodesWeNeed;
    },
    []
  );

  /**
   * @function toTree: 整合findAllPathNodes()的结果为树结构
   * @returns result[0]: 新Tree结构
   */
  const toTree = useCallback((data: PermissionTargetDepartment[]) => {
    // 空数组
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: any = [];

    if (!Array.isArray(data)) {
      return result;
    }
    // 遍历  删除  children 属性  做初始化操作
    data.forEach((item) => {
      delete item.children;
    });
    //  init空对象
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const map: any = {};
    data.forEach((item) => {
      map[item.id] = item;
    });

    // map对象的 键: 是每个id  值：对应的item
    data.forEach((item) => {
      // item.pid 为0时 返回underfined
      const parent = map[item.parentId];
      if (parent) {
        (parent.children || (parent.children = [])).push(item);
      } else {
        // 这里push的item是pid为0的数据
        result.push(item);
      }
    });

    return result[0];
  }, []);

  const onExpand = useCallback((expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  }, []);

  /**
   * @function onChange: 搜索框输入onChange;
   * @var expandedKey: 与当前输入匹配的node的父节点
   * @var allTreeNodeList: 扁平化后的树结构array
   * @returns setExpandedKeys(expandedKey as string[]):（受控）展开指定的树节点
   * @returns setSearchValue(value): 记录搜索关键词
   * @returns setAutoExpandParent(true): 是否自动展开父节点
   */
  const onChange = useCallback(
    (e) => {
      const { value } = e.target;
      let expandedKey: (string | null)[] = [];

      // 空输入&清空case
      if (value.length === 0) {
        setExpandedKeys([treeData?.id ?? ""]);
        setSearchValue("");
        setSearchResultTree(null);
        setAutoExpandParent(true);
        return;
      } else {
        expandedKey = allTreeNodeList
          .map((item) => {
            if (item.name.indexOf(value) > -1) {
              return item.parentId;
            }
            return null;
          })
          .filter((item, i, self) => item && self.indexOf(item) === i);
        if (expandedKey.length === 0) {
          const rst = {
            ...treeData,
            id: treeData?.id ?? "",
            children: [],
          } as PermissionTargetDepartment;
          setSearchResultTree(rst);
        } else {
          const findNodes = findAllPathNodes(
            value,
            expandedKey,
            allTreeNodeList
          );
          const rst = toTree(findNodes);
          setSearchResultTree(rst);
          setExpandedKeys(expandedKey as string[]);
          setAutoExpandParent(true);
        }
        setSearchValue(value);
        return;
      }
    },
    [allTreeNodeList, treeData, findAllPathNodes, toTree]
  );

  // 防抖
  const debouncedOnChange = _.debounce(onChange, 300);

  return (
    <React.Fragment>
      {companyFrom !== "QyWechat" && (
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
          `}
        >
          <Input
            allowClear
            placeholder={formatMessage(
              messageIds.organization.department.departmentName
            )}
            css={css`
              width: 200px;
              margin-bottom: 5px;
            `}
            onChange={debouncedOnChange}
          />
        </div>
      )}
      {companyRender}
      <Tree.DirectoryTree
        css={treeStyle}
        treeData={departmentTreeDataJsx as DataNode[]}
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        blockNode={true}
        showIcon={false}
        selectedKeys={selectedKeys}
        {...restProps}
      />
    </React.Fragment>
  );
});

export const DepartmentTreeSelect = React.memo(function <T>(
  props: TreeSelectProps<T> & { type?: "header" | "default" }
) {
  const getContainer = useGetContainer();
  const departmentContainer = getContainer(DepartmentModel);
  const theme = useTheme();
  const globalSelectCompanyUIModel = getContainer(GlobalSelectCompanyUIModel);
  const globalSelectDepartmentContainer = getContainer(
    GlobalSelectDepartmentModel
  );
  const companyFrom = useSelector(
    () => globalSelectCompanyUIModel.getters.companyFrom
  );
  const globalSelectDepartmentName = useSelector(
    () => globalSelectDepartmentContainer.getters.globalSelectDepartmentName
  );
  const canEdit = useSelector(() => globalSelectCompanyUIModel.getters.canEdit);

  const isDepartmentSelectOpenGlobal = useSelector(
    () => departmentContainer.state.isDepartmentSelectOpen
  );

  const [isDepartmentSelectOpen, setIsDepartmentSelectOpen] = useState(false);

  useEffect(() => {
    setIsDepartmentSelectOpen(isDepartmentSelectOpenGlobal);
  }, [isDepartmentSelectOpenGlobal]);

  const displayTreeData = useSelector(
    () => departmentContainer.state.departmentTreeData
  );

  const departmentData: DepartmentTreeData | null = useMemo(() => {
    return displayTreeData && _.cloneDeep(displayTreeData);
  }, [displayTreeData]);

  const departmentNodeRender = useCallback(
    (payload: {
      name: string;
      value: string;
      title: ReactNode;
      children?: ReactNode;
    }) => {
      const { name, value, title, children } = payload;
      return (
        <TreeNode name={name} value={value} title={title} key={value}>
          {children}
        </TreeNode>
      );
    },
    []
  );

  const getTreeData = useCallback(
    (data?: Department[]) => {
      return data?.map((departmentItem: Department) => {
        const { id, name, children } = departmentItem;
        const title = (
          <div
            css={css`
              display: flex;
              align-items: center;
              white-space: nowrap;
            `}
          >
            <Icon
              name="icon-department-tree"
              css={css`
                color: ${theme.primaryColor};
                font-size: 25px;
                padding-right: 10px;
              `}
            />
            <DepartmentName openid={name} companyFrom={companyFrom} />
          </div>
        );

        const node = departmentNodeRender({
          name: name,
          value: id,
          title,
          children: getTreeData(children),
        });
        return node;
      });
    },
    [companyFrom, departmentNodeRender, theme.primaryColor]
  );

  const departmentTreeData = useMemo(() => {
    return getTreeData(departmentData?.rootDepartment?.children) || [];
  }, [departmentData, getTreeData]);

  const companyRender = useMemo(() => {
    const title = (
      <div
        css={css`
          :hover {
            cursor: pointer;
          }
        `}
      >
        <Icon
          name="icon-tenant"
          css={css`
            margin-right: 6px;
            font-size: 14px;
          `}
        />
        <span
          css={css`
            font-weight: 600;
          `}
        >
          <DepartmentName
            openid={departmentData?.rootDepartment?.name ?? ""}
            companyFrom={companyFrom}
          />
        </span>
      </div>
    );

    const companyNode =
      departmentData?.rootDepartment &&
      departmentNodeRender({
        title,
        name: departmentData?.rootDepartment?.name,
        value: departmentData?.rootDepartment?.id,
      });
    return companyNode;
  }, [companyFrom, departmentData, departmentNodeRender]);

  const initializeRequest = useCallback(async () => {
    departmentContainer.actions.refreshDepartmentTree.dispatch(null);
  }, [departmentContainer.actions.refreshDepartmentTree]);

  useEffect(() => {
    initializeRequest();
  }, [initializeRequest]);
  const ref = useRef<BaseSelectRef | null>(null);
  const onClick = useCallback(() => {
    if (isDepartmentSelectOpen) {
      ref.current?.blur();
    } else {
      ref.current?.focus();
    }
  }, [isDepartmentSelectOpen]);
  const onOpen = useCallback(() => {
    setIsDepartmentSelectOpen(true);
  }, []);
  const onClose = useCallback(() => {
    setIsDepartmentSelectOpen(false);
  }, []);

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
      `}
    >
      {!canEdit && (
        <div
          css={css`
            position: absolute;
            top: 1px;
            z-index: 1;
            width: ${props.type === "header" ? "170px" : "250px"};
            height: 30px;
            margin-left: 3px;
            padding-top: 5px;
            padding-left: 10px;
            background: ${props.type === "header"
              ? "#5A678B"
              : props.disabled
              ? "#f5f5f5"
              : "white"};
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          `}
          onClick={onClick}
        >
          <DepartmentName
            openid={globalSelectDepartmentName ?? ""}
            companyFrom={companyFrom}
          />
        </div>
      )}
      <Global
        styles={css`
          .department_selector .ant-select-tree-list-holder {
            /*定义滚动条样式*/
            ::-webkit-scrollbar {
              width: 8px;
              height: 8px;
              background-color: #ffffff;
            }
            ::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px #eee;
              border-radius: 6px;
              background-color: #ffffff;
            }
            ::-webkit-scrollbar-thumb {
              border-radius: 6px;
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
              background-color: #cdcfe1;
            }
            .ant-select-tree-switcher-icon {
              font-size: 14px;
              margin-top: 6px;
              color: #9697a0;
            }
            .ant-select-tree-switcher {
              :hover {
                background-color: #e0e2e7;
              }
            }
          }
        `}
      ></Global>
      <TreeSelect
        dropdownClassName="department_selector"
        ref={ref}
        treeNodeLabelProp="name"
        suffixIcon={
          <img
            src={IconExpanded}
            alt="icon-expanded"
            css={css`
              margin-left: 4px;
            `}
          />
        }
        virtual={false}
        dropdownStyle={{ maxHeight: 400, maxWidth: 280, overflow: "auto" }}
        onBlur={canEdit ? undefined : onClose}
        onFocus={canEdit ? undefined : onOpen}
        open={canEdit ? undefined : isDepartmentSelectOpen}
        {...props}
      >
        {companyRender}
        {departmentTreeData}
      </TreeSelect>
    </div>
  );
});
