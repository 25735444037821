import { createModel, GetContainer } from "nyax";
import { AppV2 } from "src/models/appV2";
import { AppCollectionListModel } from "src/store/models/entity/apps/collectionList";
import { ViCodeAppFavoriteListModel } from "src/store/models/entity/vicodeApp/favoriteList";
import { createViCodeAppPaginationBaseModal } from "src/store/models/entity/vicodeApp/_shared";
import { GlobalSelectCompanyUIModel } from "src/store/models/logic/globalSelectCompany";
import { ViCodeAppWithPermission } from "src/store/models/ui/vicodeApp/_shared";

export const UIViCodeAppFavoriteAppModel = createModel(
  class extends createViCodeAppPaginationBaseModal<
    ViCodeAppWithPermission,
    AppV2
  >({
    getList1Pagination: (getContainer: GetContainer) =>
      getContainer(ViCodeAppFavoriteListModel).getters.pagination,
    getList2Pagination: (getContainer: GetContainer) =>
      getContainer(AppCollectionListModel).getters.pagination,

    getDataSource1: (getContainer: GetContainer) =>
      getContainer(ViCodeAppFavoriteListModel).getters.dataSourceWithPermission,
    getDataSource2: (getContainer: GetContainer) =>
      getContainer(AppCollectionListModel).getters.dataSourceWithPermission,
  }) {
    private get _vicodeAppFavoriteListContainer() {
      return this.getContainer(ViCodeAppFavoriteListModel);
    }

    private get _appCollectionListContainer() {
      return this.getContainer(AppCollectionListModel);
    }

    public effects() {
      return {
        ...super.effects(),

        initialize: async () => {
          const globalSelectCompanyUIContainer = this.getContainer(
            GlobalSelectCompanyUIModel
          );
          const edition =
            globalSelectCompanyUIContainer.getters.selectedGlobalCompony
              ?.edition;

          await this.actions.setCurrent.dispatch(1);

          if (edition === "Community") {
            await this._vicodeAppFavoriteListContainer.actions.initial.dispatch(
              {}
            );
            return;
          }

          await this._vicodeAppFavoriteListContainer.actions.initial.dispatch(
            {}
          );
          await this._appCollectionListContainer.actions.initial.dispatch({});
        },
      };
    }
  }
);
