/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { Asset, AssetCreationData, AssetQueryData } from "src/models/asset";
import { EncooListEntity } from "src/models/_shared";
import { decrypt, encrypt } from "src/utils/cryptogram";

export const SERVICE_NAME = "assetV2";

export class AssetV2ServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, SERVICE_NAME);
  }

  // 1. AES256加密列表
  list = async (
    departmentId: string,
    pageIndex?: number,
    pageSize?: number,
    query?: AssetQueryData
  ): Promise<EncooListEntity<Asset>> => {
    const req = this.buildRequestV2({
      url: `/v2/assets/encryptedData/fuzzySearch`,
      method: "GET",
      query: {
        ...query,
        pageIndex,
        pageSize,
      },
      departmentId,
    });
    const { body } = await this.sendRequest(req);
    return await decrypt(body);
  };

  // 2. 加密创建
  // eslint-disable-next-line @typescript-eslint/ban-types
  create = async (payload: AssetCreationData): Promise<Asset> => {
    const dataEncrypted = encrypt(payload);
    const req = this.buildRequestV2({
      url: `v2/assets/encrypteddata`,
      method: "POST",
      payload: { encryptedData: dataEncrypted },
    });

    const { body } = await this.sendRequest(req);
    return await decrypt(body);
  };

  // 3. 加密修改
  update = async (id: string, payload: AssetCreationData): Promise<Asset> => {
    const dataEncrypted = encrypt(payload);
    const req = this.buildRequestV2({
      url: `v2/assets/encrypteddata/${id}`,
      method: "PUT",
      payload: { encryptedData: dataEncrypted },
    });

    const { body } = await this.sendRequest(req);
    return await decrypt(body);
  };

  async getById(id: string): Promise<Asset> {
    const req = this.buildRequestV2({
      url: `/v2/assets/${id}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async delete(id: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/assets/${id}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }
}
