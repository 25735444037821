import { createModel } from "nyax";
import { DataTable, DataTableCreationData } from "src/models/dataTable";
import { DataPermissionHelperModel } from "src/store/models/entity/dataPermission/helper";
import { DataTableEntityModel } from "src/store/models/entity/dataTable/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const DataTableHelperModel = createModel(
  class extends createHelperModel<DataTable>({
    setItems: (getContainer, items) =>
      getContainer(DataTableEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(DataTableEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(DataTableEntityModel).state.byId[id],
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(DataTableEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),

        getById: async (payload: { dataTableId: string; force?: boolean }) => {
          const { dataTableId, force } = payload;
          return await this._readById({
            id: dataTableId,
            getByIdAction: async () => {
              const item =
                await this.dependencies.serviceClient.dataTable.getById(
                  dataTableId
                );
              await this.getContainer(
                DataPermissionHelperModel
              ).actions.getAll.dispatch([
                { resourceType: item.resourceType, id: item.id },
              ]);
              return item;
            },
            force,
          });
        },
        create: async (payload: { params: DataTableCreationData }) => {
          const { params } = payload;
          return await this._create({
            createAction: () =>
              this.dependencies.serviceClient.dataTable.create(params),
          });
        },
        update: async (params: {
          dataTable: Partial<DataTable> & Pick<DataTable, "id">;
        }) => {
          const { dataTable } = params;
          return await this._update({
            id: dataTable.id,
            updateAction: () =>
              this.dependencies.serviceClient.dataTable.update(dataTable),
          });
        },
        delete: async (payload: { dataTableId: string }) => {
          const { dataTableId } = payload;
          return await this._delete({
            id: dataTableId,
            deleteAction: () =>
              this.dependencies.serviceClient.dataTable.delete(dataTableId),
          });
        },
      };
    }
  }
);
