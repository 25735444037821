/** @jsxImportSource @emotion/react */
import { SearchOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { Input } from "antd";
import { InputProps } from "antd/lib/input";
import React, { useCallback, useEffect, useRef, useState } from "react";

export interface SearchInputProps
  extends Omit<InputProps, "prefix" | "className" | "onChange" | "value"> {
  prefix?: React.ReactNode;
  className?: string;
  width?: string;
  value?: string;
  onSearch?: (value: string) => void;
}

export default React.memo(function SearchInput(props: SearchInputProps) {
  const { className, width, placeholder, onSearch, value, ...otherProps } =
    props;

  const [internalValue, setInternalValue] = useState<string | undefined>(
    undefined
  );
  const inputRef = useRef<Input | null>(null);

  useEffect(() => setInternalValue(value), [value]);

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInternalValue(newValue);
  }, []);
  const onInternalSearch = useCallback(() => {
    if (value !== internalValue) {
      onSearch?.(internalValue ?? "");
    }
  }, [internalValue, onSearch, value]);

  const onEnter = useCallback(() => {
    inputRef.current?.blur();
  }, []);

  return (
    <Input.Search
      ref={inputRef}
      className={className}
      prefix={props.prefix ?? <SearchOutlined />}
      placeholder={placeholder}
      css={css`
        width: ${width}px;
        .ant-input-group-addon {
          display: none;
        }
      `}
      value={internalValue}
      onChange={onChange}
      onBlur={onInternalSearch}
      onPressEnter={onEnter}
      {...otherProps}
      allowClear
      onSearch={onSearch}
    />
  );
});
