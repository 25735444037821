// eslint-disable-next-line import/no-anonymous-default-export
export default {
  runInstance: {
    viewLog: "查看日志",
    logInfo: "日志信息",
    logInfoDescription:
      "页面支持查看、过滤10万条以内的日志数据；该流程正在运行中，支持查看1000条以内的日志数；超出部分，需下载查看。",
    logTime: "日志时间",
    logRank: "日志级别",
    logType: "日志类型",
    logTypeList: {
      systemLog: "系统",
      defLog: "业务",
    },
    allLog: "全部",
    errLog: "错误",
    infoLog: "信息",
    debugLog: "调试",
    logContent: "日志内容",
    screenshotShown: "截图展示",
    runinstanceNoVideoTip: "当前任务无视频记录",
    downloadLogTip: "下载全部历史日志数据",
    videoShow: "视频展示",
    noScreenshotShown: "当前无截图记录",
    logTimeIcon: "机器人所在计算机的时间",
    placeholder: {
      allType: "全部类型",
      allLevel: "全部级别",
    },
    noMatch: "无匹配结果",
  },
  execution: {
    source: "任务来源",
    runOrder: "执行次序",
    executionRobot: "执行机器人",
    log: "日志",
    progress: "执行进度",
    priority: "优先执行",
    restart: "重新启动",
    termination: "终止",
    expansion: "展开",
    retraction: "收起",
    startMode: "启动方式",
    status: {
      create: "创建",
      running: "运行",
      failed: "失败",
      cancelled: "已取消",
      succeeded: "成功",
      cancelling: "取消中",
    },
    statusTip: {
      running:
        "若任务有可用的机器人资源，且正在执行流程，则任务状态处于“运行中”。",
      paused:
        "若用户在控制台或机器人端对”运行中“的任务操作“暂停”，则任务状态处于“已暂停”。",
      cancelled:
        "一次流程任务的执行结果，如执行次序1。\n情况1:用户在控制台端或机器人端，对“运行中/等待中/已暂停”的任务操作“取消”后，则任务状态处于“已取消”；\n情况2:根据设置的冲突处理策略，当机器人忙碌时，系统自动取消后续下发到此机器人的任务，则任务状态处于“已取消”。",
      succeeded: "机器人的一次流程任务执行结果为“成功”，如执行次序1。",
      failed: "机器人的一次流程任务执行结果为“失败”，如执行次序1。",
    },
  },
  rpaPlan: {
    logManage: "任务日志管理",
    logFile: "任务日志文件",
    videoLog: "视频录制文件",
    videoSize: "视频存储容量",
    stayLast: " 仅保留最近 ",
    stayLog: " 仅保留最近 {number}{unit} 内文件",
    maxSize: " 总视频存储容量最大 ",
    unitFile: " 内文件",
    year: "年",
    month: "月",
    day: "日",
  },
};
