import { createModel, createSelector } from "nyax";
import { PackageVersion } from "src/models/packageVersion";
import { EncooSort } from "src/models/_shared";
import { PackageVersionEntityModel } from "src/store/models/entity/packageVersion/entity";
import { PackageVersionHelperModel } from "src/store/models/entity/packageVersion/helper";
import { createUITableWithPermissionModel } from "src/store/models/ui/_shared";

export const PackageVersionTableUIModel = createModel(
  class extends createUITableWithPermissionModel<PackageVersion, EncooSort>({
    setItems: (getContainer, items) =>
      getContainer(PackageVersionEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(PackageVersionEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(PackageVersionEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public initialState() {
      return {
        ...super.initialState(),
        showDetailId: null as string | null,
        isDetailEdit: false,
      };
    }

    public reducers() {
      return {
        ...super.reducers(),
        setShowDetailId: (value: string | null) => {
          this.state.showDetailId = value;
          this.state.isDetailEdit = false;
        },
        setDetailEdit: (value: boolean) => {
          this.state.isDetailEdit = value;
        },
      };
    }

    public selectors() {
      return {
        ...super.selectors(),
        detailPackageVersion: createSelector(
          () => this.state.showDetailId,
          () => this.getContainer(PackageVersionEntityModel).state.byId,
          (id, itemById): PackageVersion | undefined => {
            const item = itemById[id ?? ""];
            return item;
          }
        ),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        initial: async (payload: { packageId: string; force?: boolean }) => {
          const { packageId } = payload;
          this._initial({
            initialAction: (pageIndex: number, pageSize: number) =>
              this.dependencies.serviceClient.package.listPackageVersion(
                packageId,
                pageIndex,
                pageSize
              ),
          });
        },

        downloadPackage: async (payload: {
          packageId: string;
          packageVersionId: string;
        }) => {
          const { packageId, packageVersionId } = payload;
          const downloadUrl =
            await this.dependencies.serviceClient.package.getPackageVersionDownloadUri(
              packageId,
              packageVersionId
            );

          const link = document.createElement("a");
          link.style.display = "none";
          link.href = downloadUrl;
          link.setAttribute("download", `download`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        updatePackageVersionDescription: async (description: string) => {
          const packageVersion = this.getters.detailPackageVersion;

          if (packageVersion) {
            await this.getContainer(
              PackageVersionHelperModel
            ).actions.updateCompleted.dispatch({
              packageId: packageVersion.packageId,
              packageVersionId: packageVersion.id,
              packageVersionUpdateData: {
                version: packageVersion.version,
                description: description.trim(),
                eTag: packageVersion.eTag ?? "",
              },
            });
          }

          await this.actions.setShowDetailId.dispatch(null);
        },
      };
    }
  }
);
