// eslint-disable-next-line import/no-anonymous-default-export
export default {
  workflow: {
    title: "Flow Deployment",
    name: "Deployment Name",
    introduction:
      "Flow Deployment is mainly used to configure the targets, parameters, retries, video recording for process execution, and the process can be triggered manually or by task schedule.",
    packageName: "Flow Package Name",
    version: "Version Number",
    trigger: "Task Schedule",
    noTrigger: "No Task Schedules",
    jobList: "Task List",
    batchUpdate: "Batch Updating",
    batchUpdateConfirm:
      "After updating the package version, the flow deployment / triggers / flow sequence / job queue will overwrite the settings by the new version, which may lead to operation failure. Please be careful. Are you sure you want to update?",
    batchUpdateWarning:
      "The following process deployment has changed, please handle it in time：",
    batchExecute: "Batch Execution",
    config: "Configuration",
    packageVersion: "Flow Package Version",
    parameterInfo: "Parameter Information",
    performanceTarget: "Execution Target",
    setExecutionTarget: "Set Execution Target",
    robotOrQueue: "Robot/Queue",
    associateQueue: "Associated Robotics Group",
    associateRobot: "Associated Robots",
    email: "Please fill in the correct notification email",
    emailPlaceholder:
      "Please enter your email address of the notified person and press enter to confirm",
    saveAndSetTrigger: "Save and set the cron job.",
    execute: "Execute",
    workflowExecute: "Flow Execution",
    tryMaxCount: "Number Of Retries",
    tryMaxCountTip:
      "Tips: If task failed, the system will automatically try again according to the set value. The default is 3 times and the setting range is 0 ~ 10 times.",
    executionTimeout: "Execution Timeout",
    executionTimeoutTip:
      "Tips: If the task takes too much time somehow, the system will aborts the task when the time is up",
    runAsAdministrator: "Run as administrator",
    runAsAdministratorTip:
      "Tips: Only work for robot version above 1.1.2206.26",
    enableArgumentsReAssignment: "Enable Parameter Reassignment",
    createSuccess: "Flow deployment created.",
    bindRobotError: "Robot binding failed.",
    noSupportArgument: "Editing complex types is not supported",
    importAsset: "Import Asset",
    selectAsset: "Select Asset",
    isEnableVideoRecord: "Whether to enable video recording",
    open: "ON",
    close: "OFF",
    alwaysReport: "Return All",
    neverReport: "No Return",
    whenSuccessful: "When successful",
    whenFailed: "When failed",
    whenCancelled: "When cancelled",
    pleaseSelectEvent: "Please select an event",
    onlyWhenSucceededReport: "Return Only If It Succeeds",
    onlyWhenFailedReport: "Return Only If It Fails",
    videoRecordTip:
      "Tips: You can view the video playback on the detail page of the task log.",
    executeOutTimeTip:
      "Tips: Specify the time-out time of process deployment execution. If it exceeds this time, the task will be terminated automatically.",
    videoReportCondition: "Return Conditions",
    priority: "Task Priority",
    priorityTip:
      "Tips: The task priority ranges from 0 (lowest) to 5000 (highest). The higher the priority, the earlier to be executed.",
    workflowId: "Flow Deployment ID",
    executeOutTime: "Execution timeout",
    outTime: "Timeout time",
    noResource: {
      title: "When no robot is available",
      anyTime: "Waiting",
      skipWhenNoResource: "Cancel Task",
    },
    argument: "argument",
    addField: "Add field",
    batchEditTip:
      "Tip: the task schedule associated with the process deployment will also change",
  },
};
