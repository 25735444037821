// eslint-disable-next-line import/no-anonymous-default-export
export default {
  user: {
    introduction:
      "User Information is mainly used to manage console account information, which supports operations such as account deactivation, viewing, and deletion.",
    entityTypeName: "User",
    name: "Name",
    nickName: "Alias",
    email: "Email Address",
    password: "Password",
    confirmPassword: "Confirm Password",
    confirmPasswordMessage: "Please confirm password",
    passwordMismatch: "Please make sure your passwords match",
    initializationMessage:
      "* Please send the email address and password to the user. The account will be activated after the first login.",
    addUserSuccessfully: "User added.",
    phoneNumber: "Phone Number",
    isAdmin: "System Administrator",
    setAsAdmin: "Set as Administrator",
    lastLoginTime: "Last Login Time",
    roles: "Role",
    editRoles: "Edit Role",
    addToTenant: "Add",
    status: {
      title: "Status",
      all: "All status",
      enabled: "Enable",
      disabled: "Disable",
      inactive: "Not Activated",
    },
    enable: "Enable",
    disable: "Disable",
    logout: "Exit",
    createUser: "New User",
    createTenant: "New Tenant",
    tenant: {
      entityTypeName: "Tenant",
      name: "Tenant name",
      nameSubtitle:
        "At present, a maximum of three tenants (Enterprise Edition) can be created.",
      namePlaceholder:
        "Please make sure your company name is correct, which cannot be modified upon creation.",
      nameAlert:
        "Tips: When a new tenant is created, you will automatically become the administrator of that tenant and have all the permissions of that tenant.",
      createTenantSuccess: "The tenant is created.",
      createTenantSuccessHint:
        "Click Next to go to the new tenant. Please import the license number to activate the Enterprise Edition feature",
    },
    switchCompany: "Switch Company",
    userNameInTenant: "User Name",
    addUserToTenantTip:
      "* You can invite users who have already registered an ENCOO account to join the current enterprise tenants.",
    userEmail: "User Email Address",
    userEmailInputTip: "Please enter the email address of the user to be added",
    userName: "User Name",
    userNameInputTip:
      "Please fill in the name of the user in the current tenant",
    addMoreUser: "Add more users",
    addUserTip:
      "An invitation link will be sent by email and the user can activate account through the invitation link.",
  },
};
