import { createModel } from "nyax";
import { DataTable, DataTableQuery } from "src/models/dataTable";
import { DataTableEntityModel } from "src/store/models/entity/dataTable/entity";
import { createUISideListWithPermissionModel } from "src/store/models/ui/_shared";

export const DataTableSideUIModel = createModel(
  class extends createUISideListWithPermissionModel<DataTable, DataTableQuery>({
    setItems: (getContainer, items) =>
      getContainer(DataTableEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(DataTableEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(DataTableEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initial: async (payload: { departmentId: string }) => {
          const { departmentId } = payload;
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query: DataTableQuery
            ) =>
              this.dependencies.serviceClient.dataTable.list(
                departmentId,
                pageIndex,
                pageSize,
                query
              ),
          });
        },
      };
    }
  }
);
