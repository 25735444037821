import { createModel } from "nyax";
import { UserManagerUserDetail } from "src/containers/userManager/models/UserManagerUserModel";
import { CoeDepartmentUserEntityModel } from "src/store/models/entity/coeDepartmentUser/entity";
import { CoeMyWorkEntityModel } from "src/store/models/entity/coeMyWork/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const CoeDepartmentUserHelperModel = createModel(
  class extends createHelperModel<UserManagerUserDetail>({
    setItems: (getContainer, items) =>
      getContainer(CoeDepartmentUserEntityModel).actions.setItems.dispatch(
        items
      ),
    getItems: (getContainer) =>
      getContainer(CoeDepartmentUserEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(CoeDepartmentUserEntityModel).state.byId[id],
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(CoeMyWorkEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        getAll: async () => {
          // await this.getters.entity.actions.clear.dispatch({});
          return await this._readAll({
            getAllAction: async () => {
              return (
                await this.dependencies.serviceClient.userManagerServiceClient.list(
                  0,
                  100000
                )
              ).list;
            },
          });
        },
      };
    }
  }
);
