import { createModel } from "nyax";
import { Template } from "src/models/understanding";
import { TemplateEntityModel } from "src/store/models/entity/understandingTemplate/entity";
import { createUIListModel } from "src/store/models/ui/_shared";

export type TemplateWithPermission = Template & {
  permissionValues: string[];
};

export const TemplateListModel = createModel(
  class extends createUIListModel<Template>({
    setItems: (getContainer, items) =>
      getContainer(TemplateEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(TemplateEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(TemplateEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initialIterator: async (payload: {
          namePrefix?: string;
          force?: boolean;
        }) => {
          const { namePrefix, force = true } = payload;
          this._initialIterator({
            initialAction: () =>
              this.dependencies.serviceClient.understanding.list(namePrefix),
            force: force,
          });
        },
      };
    }
  }
);
