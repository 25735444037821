/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Tooltip } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import AppBreadcrumb from "src/containers/layout/components/AppBreadcrumb";
import { useFormatMessage, useGetContainer, useTheme } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { MenuExpandedDefinition } from "src/routes/_shared";
import { RouterModel } from "src/store/models/router";
import { EnvironmentModel } from "src/store/models/ui/environment/environment";

export interface AppDetailLayoutProps {
  children?: React.ReactNode;
  sideNode?: React.ReactNode;
  activityItem?: MenuExpandedDefinition;
}

export default React.memo(function AppMainLayout(props: AppDetailLayoutProps) {
  const { sideNode, children, activityItem } = props;
  const theme = useTheme();
  const formatMessage = useFormatMessage();
  const getContainer = useGetContainer();

  const environmentContainer = getContainer(EnvironmentModel);
  const environmentEdition = useSelector(
    () => environmentContainer.state.environmentEdition
  );

  const router = getContainer(RouterModel);
  const routeType = useSelector(() => router.getters.currentRouteInfo.type);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      `}
    >
      <div
        css={css`
          padding: 10px;
          border-bottom: 1px solid ${theme.bodyDivider};
          background-color: ${theme.bodyBackground};
        `}
      >
        <AppBreadcrumb activityItem={activityItem} />
        {environmentEdition === "Saas" && routeType === "runInstanceLog" && (
          <Tooltip title={formatMessage(messageIds.job.logTip)}>
            <span
              css={css`
                color: #8f9bb3;
              `}
            >
              {formatMessage(messageIds.job.logTip)}
            </span>
          </Tooltip>
        )}
      </div>
      <div
        css={css`
          display: flex;
          padding: 10px;
          flex-grow: 1;
          min-height: 0;
          min-width: 0;
          background-color: ${theme.bodyFrameBackground};
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;

            flex-grow: 1;
            min-height: 0;
            min-width: 0;

            border: 1px solid ${theme.bodyDivider};
            background-color: ${theme.bodyBackground};
          `}
        >
          {sideNode && (
            <div
              css={css`
                display: flex;
                flex-direction: column;

                min-height: 0;
                min-width: 240px;
                max-width: 240px;
                border-right: 1px solid ${theme.bodyDivider};
              `}
            >
              {sideNode}
            </div>
          )}

          <div
            css={css`
              display: flex;
              flex-direction: column;
              padding: 20px;
              flex-grow: 1;
              min-height: 0;
              min-width: 900px;
              overflow-y: auto;
              overflow-x: hidden;
            `}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
});
