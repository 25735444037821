import {
  Constants,
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import {
  AppDataSourceUpdateData,
  AppQuery,
  AppTag,
  AppV2,
  AppV2CreationData,
  AppVersionV2,
  AppVersionV2UpdateData,
  DataConnectorMappingItem,
} from "src/models/appV2";
import { EncooListEntity } from "src/models/_shared";

export const APPS_V2_SERVICE_NAME = "appsV2";
export class AppsV2ServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, APPS_V2_SERVICE_NAME);
  }

  async list(
    payload: {
      pageIndex: number;
      pageSize: number;
    } & AppQuery
  ): Promise<EncooListEntity<AppV2>> {
    const req = this.buildRequestV2({
      url: "/v2/apps",
      method: "GET",
      query: { ...payload, tagIds: payload.tagIds?.join(",") },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getAll(payload: AppQuery): Promise<Array<AppV2>> {
    const req = this.buildRequestV2({
      url: `/v2/apps`,
      method: "GET",
      query: { ...payload, tagIds: payload.tagIds?.join(",") },
    });
    return await super.getAllByPageInternal(req);
  }

  async getById(appId: string): Promise<AppV2> {
    const req = this.buildRequest({
      url: `/v2/apps/${appId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async update(app: Partial<AppV2> & Pick<AppV2, "id">): Promise<AppV2> {
    const req = this.buildRequest({
      url: `/v2/apps/${app.id}`,
      method: "PUT",
      payload: app,
    });
    if (app.eTag) {
      req.headers?.set(Constants.HeaderConstants.IF_MATCH, app.eTag);
    }
    return await super.updateInternal(req);
  }

  async delete(appId: string): Promise<boolean> {
    const req = this.buildRequest({
      url: `/v2/apps/${appId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }

  async appVersionList(
    appId: string,
    payload: {
      pageIndex: number;
      pageSize: number;
    }
  ): Promise<EncooListEntity<AppVersionV2>> {
    const req = this.buildRequestV2({
      url: `/v2/apps/${appId}/versions`,
      method: "GET",
      query: payload,
    });

    const { body } = await super.sendRequest(req);
    return body;
  }

  async getAllAppVersions(appId: string): Promise<Array<AppVersionV2>> {
    const req = this.buildRequestV2({
      url: `/v2/apps/${appId}/versions`,
      method: "GET",
    });
    return await super.getAllByPageInternal(req);
  }

  async getAppVersionById(
    appId: string,
    versionId: string
  ): Promise<AppVersionV2> {
    const req = this.buildRequest({
      url: `/v2/apps/${appId}/versions/${versionId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async updateAppVersion(
    appId: string,
    versionId: string,
    payload: AppVersionV2UpdateData
  ): Promise<AppVersionV2> {
    const req = this.buildRequest({
      url: `/v2/apps/${appId}/versions/${versionId}`,
      method: "PATCH",
      payload,
    });
    return await super.updateInternal(req);
  }

  async deleteAppVersion(appId: string, versionId: string): Promise<boolean> {
    const req = this.buildRequest({
      url: `/v2/apps/${appId}/versions/${versionId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }

  async createApp(payload: AppV2CreationData): Promise<AppV2> {
    const req = this.buildRequest({
      url: `/v2/apps`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  async uploadAppIcon(appId: string, payload?: FormData): Promise<string> {
    const req = this.buildRequest({
      url: `/v2/apps/${appId}/icon`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  async copy(id: string): Promise<AppV2> {
    const req = this.buildRequest({
      url: `/v2/apps/${id}/copy`,
      method: "POST",
    });
    return await super.createInternal(req);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async addAppFavorite(appId: string) {
    const req = this.buildRequestV2({
      url: `/v2/apps/favorites/${appId}`,
      method: "POST",
    });
    return await super.sendRequest(req);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async deleteAppFavorite(appId: string) {
    const req = this.buildRequestV2({
      url: `/v2/apps/favorites/${appId}`,
      method: "DELETE",
    });
    return await super.sendRequest(req);
  }

  async getAppFavorite(payload: {
    pageIndex: number;
    pageSize: number;
  }): Promise<EncooListEntity<AppV2>> {
    const req = this.buildRequestV2({
      url: "/v2/apps/favorites",
      method: "GET",
      query: payload,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async updatePatchApp(
    id: string,
    payload: AppDataSourceUpdateData
  ): Promise<AppV2> {
    const req = this.buildRequestV2({
      url: `/v2/apps/${id}`,
      method: "PATCH",
      payload,
    });
    return await super.updateInternal(req);
  }

  async releaseApp(
    id: string,
    payload: Partial<AppVersionV2>
  ): Promise<AppVersionV2> {
    const req = this.buildRequestV2({
      url: `/v2/apps/${id}/release`,
      method: "POST",
      payload,
    });
    return await super.updateInternal(req);
  }

  async enableApp(
    id: string,
    payload?: {
      dataConnectorMappings?: DataConnectorMappingItem[];
      launchVersionId?: string;
    }
  ): Promise<AppV2> {
    const req = this.buildRequestV2({
      url: `/v2/apps/${id}/launch`,
      method: "POST",
      payload,
    });
    return await super.updateInternal(req);
  }

  async disableApp(id: string): Promise<AppV2> {
    const req = this.buildRequestV2({
      url: `/v2/apps/${id}/offline`,
      method: "DELETE",
    });
    return await super.updateInternal(req);
  }

  async getAllAppTags(): Promise<Array<AppTag>> {
    const req = this.buildRequestV2({ url: `/v2/apps/tags`, method: "GET" });
    return await super.getAllByPageInternal(req);
  }
}
