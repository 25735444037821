import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { ApplicationHelperModel } from "src/store/models/entity/application/helper";

export const SmartChatUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        visible: false,
        isLoading: true,
        knowledgeId: "",
        webLink: "",
      };
    }
    public reducers() {
      return {
        setVisible: (visible: boolean) => {
          this.state.visible = visible;
        },
        setWebLink: (webLink: string) => {
          this.state.webLink = webLink;
        },
        setKnowledgeId: (knowledgeId: string) => {
          this.state.knowledgeId = knowledgeId;
        },
        setIsLoading: (isLoading: boolean) => {
          this.state.isLoading = isLoading;
        },
        resetState: () => {
          this.state.visible = false;
          this.state.isLoading = true;
          this.state.knowledgeId = "";
          this.state.webLink = "";
        },
      };
    }
    public effects() {
      return {
        getWebLink: async (id: string) => {
          const { url } = await this.getContainer(
            ApplicationHelperModel
          ).actions.getKnowledgeBaseStartUrl.dispatch(id);
          this.actions.setWebLink.dispatch(url);
        },
        changeKnowledgeId: async (id: string) => {
          this.actions.setKnowledgeId.dispatch(id);
          const { url } = await this.getContainer(
            ApplicationHelperModel
          ).actions.getKnowledgeBaseStartUrl.dispatch(id);
          this.actions.setWebLink.dispatch(url);
        },
      };
    }
    public selectors() {
      return {};
    }
  }
);
