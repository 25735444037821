import { createModel } from "nyax";
import { User } from "src/models/user";
import { createItemsEntityModel } from "src/store/itemsEntity";

export const UserEntityModel = createModel(
  class extends createItemsEntityModel<User>((item) => item.id) {
    public selectors() {
      return {
        ...super.selectors(),
      };
    }
  }
);
