// eslint-disable-next-line import/no-anonymous-default-export
export default {
  virtualMFA: {
    title: "Dynamic Token Settings",
    subTitle:
      "Once the dynamic token is bound, you can use it for two-factor authentication when logging in.",
    bindingInfo: {
      title: "Binding Status:",
      isBinding: "Bound",
      notBinding: "Unbound",
    },
    bind: "Go to bind",
    unbind: "Go to unbind",
    bindingTips: {
      title:
        "Before binding, please download and install Google Authenticator or Alibaba Cloud App on your mobile phone.",
      iphone: "Search Google Authenticator or Alibaba Cloud App in App Store.",
      android:
        "Search Google Authenticator or Alibaba Cloud App in the application market.",
      subTitle:
        "After the installation is completed, click Next to enter the binding page (if installed, go directly to the next step).",
      bindingSuccess: "Security device is bound successfully",
    },
    unbindTips:
      "To unbind, you need to use Google Authenticator or Alibaba Cloud App to scan the QR code for authentication.",
    MFAconfig: {
      modalTitle: "Dynamic Token Settings",
      step1: "Install Application",
      step2: "Bind Dynamic Token",
      useGoogle:
        "Use Google Authenticator or Alibaba Cloud App to scan the following QR code to obtain the 6-digit verification code.",
      sweepCodeFailure: "Scan failed?",
      bindSuccessTitle: "Security device is bound successfully",
    },

    form: {
      code: {
        name: "Please enter the verification code",
        pleaseholder: "Please enter the six digits.",
        validation: {
          notEmpty: "The verification code cannot be empty.",
        },
      },
    },
    canningFailurePrompt: {
      title:
        "Please manually scan the following QR code in Google Authenticator to obtain a 6-digit verification code.",
      name: "User Name:",
      secretKey: "Secret Key:",
    },
    unbindContainer: {
      title:
        "Account protection is enabled, please follow the instructions below to unbind.",
      subTitle:
        "Please open Alibaba Cloud App or Google Authenticator on your mobile phone, and enter the 6-digit dynamic code.",
      unbindsuccessTitle: "Security device is unbound successfully",
      successTip:
        "Please do not delete or uninstall the App arbitrarily, otherwise you cannot log in with your Alibaba Cloud account. If you need to uninstall the App or change your phone, please unbind the dynamic token first.",
    },
  },
};
