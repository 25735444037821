import { createModel } from "nyax";
import {
  AiAccountService,
  AiBalanceWarningValue,
  KeySchema,
  ServiceCreationData,
} from "src/models/ocr";
import { ModelBase } from "src/store/ModelBase";

export const AIUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        updateContent: {
          isUpdate: false,
          id: "",
        },

        deleteContent: {
          isDelete: false,
          content: {} as ServiceCreationData,
        },
        isSaasService: false,
        isCreate: false,
        isEditingBalanceWarning: false,
        isView: false,
        dataSource: [] as AiAccountService[],
        keySchemaData: [] as KeySchema[],
        aiPlatform: "",
        aiServiceId: "",
        serviceDetail: {} as AiAccountService,
        aiPlatformList: [] as { aiPlatformName: string; aiPlatform: string }[],
        usage: 0,
        balanceWarningConfigValue: undefined as
          | AiBalanceWarningValue
          | undefined,
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setUpdateContent: (value: { isUpdate: boolean; id: string }) => {
          this.state.updateContent = value;
        },
        setDeleteContent: (value: {
          isDelete: boolean;
          content: ServiceCreationData;
        }) => {
          this.state.deleteContent = value;
        },
        setIsSaasService: (value: boolean) => {
          this.state.isSaasService = value;
        },
        setIsCreate: (value: boolean) => {
          this.state.isCreate = value;
        },
        setIsEditingBalanceWarning: (value: boolean) => {
          this.state.isEditingBalanceWarning = value;
        },
        setDataSource: (value: AiAccountService[]) => {
          this.state.dataSource = value;
        },
        setKeySchemaData: (value: KeySchema[]) => {
          this.state.keySchemaData = value;
        },
        setAiPlatform: (value: string) => {
          this.state.aiPlatform = value;
        },
        setAiServiceId: (value: string) => {
          this.state.aiServiceId = value;
        },
        setServiceDetail: (value: AiAccountService) => {
          this.state.serviceDetail = value;
        },
        setAiPlatformList: (
          value: { aiPlatformName: string; aiPlatform: string }[]
        ) => {
          this.state.aiPlatformList = value;
        },
        setIsView: (value: boolean) => {
          this.state.isView = value;
        },
        setUsage: (value: number) => {
          this.state.usage = value;
        },
        setBalanceWarningConfigValue: (value: AiBalanceWarningValue) => {
          this.state.balanceWarningConfigValue = value;
        },
      };
    }
    public effects() {
      return {
        keySchemaList: async () => {
          const data = await this.dependencies.serviceClient.ocr.getKeySchema();

          const list: { aiPlatformName: string; aiPlatform: string }[] = [];
          // eslint-disable-next-line array-callback-return
          data.map((data: KeySchema) => {
            if (
              !list.filter((list) => list.aiPlatform === data.aiPlatform)?.[0]
            ) {
              list.push({
                aiPlatformName: data.aiPlatformName,
                aiPlatform: data.aiPlatform,
              });
            }
          });

          await this.actions.setAiPlatformList.dispatch(list);
          await this.actions.setKeySchemaData.dispatch(data);
        },
        readDetailById: async (accountServiceId: string) => {
          const data = await this.dependencies.serviceClient.ocr.getDetailById(
            accountServiceId
          );

          await this.actions.setServiceDetail.dispatch(data);
        },
        getBalanceWarningConfig: async (payload: AiBalanceWarningValue) => {
          const data =
            await this.dependencies.serviceClient.ocr.getBalanceWarningConfig(
              payload
            );

          data && this.actions.setBalanceWarningConfigValue.dispatch(data);
        },
        updateBalanceWarningConfig: async (payload: AiBalanceWarningValue) => {
          await this.dependencies.serviceClient.ocr.updateBalanceWarningConfig(
            payload
          );
        },
      };
    }
    public selectors() {
      return {};
    }
  }
);
