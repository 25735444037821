import { createModel } from "nyax";
import { PaymentOrder } from "src/models/payment";
import { createHelperModel } from "src/store/models/entity/_shared";
import { PaymentOrderEntityModel } from "src/store/models/entity/payment/order/entity";
export const PaymentOrderHelperModel = createModel(
  class extends createHelperModel<PaymentOrder>({
    setItems: (getContainer, items) =>
      getContainer(PaymentOrderEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(PaymentOrderEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(PaymentOrderEntityModel).state.byId[id],
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(PaymentOrderEntityModel),
      };
    }
    public effects() {
      return {
        ...super.effects(),
        getAll: async () => {
          return await this._readAll({
            getAllAction: () =>
              this.dependencies.serviceClient.paymentOrder.list(),
          });
        },
        create: async (payload: {
          items: {
            productId: string;
            quantity: number;
          }[];
          renewAgainstOrderId?: string;
          couponCode?: string;
        }) => {
          const data =
            await this.dependencies.serviceClient.paymentOrder.create(
              payload.items,
              payload.renewAgainstOrderId,
              payload.couponCode
            );
          this.getContainer(
            PaymentOrderEntityModel
          ).actions.unshiftItem.dispatch(data);
          return data;
        },
        cancel: async (orderId: string) => {
          return await this._update({
            id: orderId,
            updateAction: () =>
              this.dependencies.serviceClient.paymentOrder.cancel(orderId),
          });
        },
        getById: async (orderId: string) => {
          return await this._update({
            id: orderId,
            updateAction: () =>
              this.dependencies.serviceClient.paymentOrder.getById(orderId),
          });
        },
      };
    }
  }
);
