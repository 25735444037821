import { Entity } from "@encoo-web/encoo-lib";

export enum ViCodeAppStatus {
  Disabled = 0,
  Enabled = 1,
}

export interface ViCodeApp extends Entity {
  id: string;
  name: string;
  description?: string;
  icon: string;

  draftVersionId: string;
  liveVersionId?: string | null;
  status: ViCodeAppStatus;
  isFavorite: boolean;
  shortUrl?: string;

  resourceType: "App";

  companyId: string;
  departmentId: string;
}

export interface ViCodeAppVersion {
  id: string;
  appId: string;

  resourceType: "AppVersion";

  configData?: string | null;

  eTag: string;
  companyId: string;
  departmentId: string;
}
