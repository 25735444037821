/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Dropdown, Menu } from "antd";
import React, { useCallback, useState } from "react";
import IconExpanded from "src/assets/icon_expanded.svg";
import IconSupport from "src/assets/icon_support.svg";
import { useFormatMessage } from "src/hooks";
import messageIds from "src/locales/messageIds";

export default React.memo(function SupportComponent() {
  const formatMessage = useFormatMessage();

  const [isMenuOpening, setIsMenuOpening] = useState(false);

  const onVisibleChange = useCallback((visible: boolean) => {
    setIsMenuOpening(visible);
  }, []);

  const closeMenu = useCallback(() => {
    setIsMenuOpening(false);
  }, []);

  const menuNotification = (
    <Menu
      onClick={closeMenu}
      css={css`
        top: -4px;
        width: 140px;
      `}
    >
      <Menu.Item key="forum">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.encoo.com/product-detail/Bvg11AxN"
        >
          {formatMessage(messageIds.home.practicalLink.consoleBBS)}
        </a>
      </Menu.Item>
      <Menu.Item key="academy_docs">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://academy.encoo.com/zh-cn/wiki/Index.md"
        >
          {formatMessage(messageIds.home.practicalLink.consoleAcademyDocs)}
        </a>
      </Menu.Item>
      <Menu.Item key="academy">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://academy.encoo.com/learn"
        >
          {formatMessage(messageIds.home.practicalLink.consoleAcademyCourse)}
        </a>
      </Menu.Item>
      <Menu.Item key="api">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${window._settings.endpoint}/openapi/static/consoleapi.html`}
        >
          {formatMessage(messageIds.home.practicalLink.consoleAPIDocs)}
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={menuNotification}
      trigger={["click"]}
      css={css`
        background-color: ${isMenuOpening ? "rgba(0,0,0,0.09)" : "none"};
        height: 100%;
      `}
      onVisibleChange={onVisibleChange}
    >
      <div
        css={css`
          padding-left: 15px;
          padding-right: 9px;
          display: flex;
          align-items: center;
          color: #ffffff;
          cursor: pointer;
        `}
      >
        <img
          src={IconSupport}
          alt="icon-notification"
          css={css`
            margin-right: 4px;
            font-size: 14px;
            line-height: 32px;
          `}
        />
        <span
          css={css`
            font-size: 12px;
          `}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {formatMessage(messageIds.header.support.name)}
        </span>
        <img
          src={IconExpanded}
          alt="icon-expanded"
          css={css`
            margin-left: 4px;
            transform: rotate(${isMenuOpening ? "180deg" : "0deg"});
          `}
        />
      </div>
    </Dropdown>
  );
});
