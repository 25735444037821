import { ModelBase } from "src/store/ModelBase";

export const ShareSettingsBaseUIModel = class extends ModelBase {
  protected get _id(): string {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.containerKey!;
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public initialState() {
    return {
      visible: false as boolean,
      sharedToDepartmentIds: [] as string[],
    };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public reducers() {
    return {
      setVisible: (value: boolean) => {
        this.state.visible = value;
      },
      setSharedToDepartmentIds: (value: string[]) => {
        this.state.sharedToDepartmentIds = value;
      },
    };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public selectors() {
    return {
      belongDepartmentId: (): string | null => {
        // 子Model实现
        return null;
      },
    };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public effects() {
    return {
      requestSharedToDepartmentIds: async () => {
        // 子Model实现
      },
      updateSharedToDepartmentIds: async (sharedToDepartmentIds: string[]) => {
        // 子Model实现
      },
    };
  }
};
