import { createModel } from "nyax";
import { Workflow } from "src/models/workflow";
import { ModelBase } from "src/store/ModelBase";

export const JobQueueDetailUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        workflowData: [] as Workflow[],
      };
    }

    public reducers() {
      return {
        setWorkflowData: async (value: Workflow[]) => {
          this.state.workflowData = value;
        },
      };
    }

    public effects() {
      return {
        getAll: async () => {
          const workflows =
            await this.dependencies.serviceClient.workflow.getAllWorkflow();
          this.actions.setWorkflowData.dispatch(workflows);
        },
      };
    }
  }
);
