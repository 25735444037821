import { ProductSku } from "@encoo-web/encoo-lib";
import { createModel } from "nyax";
import { Installation, ProductName } from "src/models/installation";
import { InstallationEntityModel } from "src/store/models/entity/installation/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const InstallationHelperModel = createModel(
  class extends createHelperModel<Installation>({
    setItems: (getContainer, items) =>
      getContainer(InstallationEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(InstallationEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(InstallationEntityModel).state.byId[id],
  }) {
    public effects() {
      return {
        ...super.effects(),
        getPublishedPackages: async (payload: {
          productNames: ProductName[];
          sku: ProductSku;
          force?: boolean;
        }) => {
          const { sku } = payload;

          const res =
            await this.dependencies.serviceClient.installation.getPublishedPackages(
              {
                sku,
              }
            );
          return res;
        },
      };
    }
  }
);
