export const DependenciesSymbol = "Dependencies";
export const HistorySymbol = "History";

export const UserManagerSettingsSymbol = "UserManagerSettings";
export const HttpClientSymbol = "HttpClient";
export const ServiceCredentialsSymbol = "ServiceCredentials";
export const TenantHeaderMiddlewareSymbol = "TenantHeaderMiddleware";
export const CompanyHeaderMiddlewareSymbol = "CompanyHeaderMiddleware";
export const DepartmentHeaderMiddlewareSymbol = "DepartmentHeaderMiddleware";
export const ErrorMiddlewareSymbol = "ErrorMiddlewareSymbol";
export const APIErrorMiddlewareSymbol = "APIErrorMiddlewareSymbol";

export const RequestQueueMiddlewareSymbol = "RequestQueueMiddleware";
export const ProfileSymbol = "Profile";
