import {
  Constants,
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { EncooListEntity } from "src/models/_shared";
import {
  Application,
  ApplicationQuery,
  CreateApplication,
  NestedPageUrl,
} from "src/models/application";

export const APPLICATION_SERVICE_NAME = "application";
export class ApplicationServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, APPLICATION_SERVICE_NAME);
  }

  async list(
    pageIndex: number,
    pageSize: number,
    query?: ApplicationQuery
  ): Promise<EncooListEntity<Application>> {
    const req = this.buildRequestV2({
      url: `/v2/lamoss/Application`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
        searchString: query?.searchString,
        startTime: query?.startTime,
        endTime: query?.endTime,
        orderByExpression: query?.orderByExpression,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getById(applicationId: string): Promise<Application> {
    const req = this.buildRequestV2({
      url: `/v2/lamoss/Application/${applicationId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async create(payload: Partial<CreateApplication>): Promise<Application> {
    const req = this.buildRequestV2({
      url: `/v2/lamoss/Application`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  async update(
    application: Partial<Application> & Pick<Application, "id">
  ): Promise<Application> {
    const req = this.buildRequestV2({
      url: `/v2/lamoss/Application/${application.id}`,
      method: "PATCH",
      payload: application,
    });
    if (application.eTag) {
      req.headers?.set(Constants.HeaderConstants.IF_MATCH, application.eTag);
    }
    return await super.updateInternal(req);
  }

  async delete(applicationId: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/lamoss/Application/${applicationId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }

  //获取知识库对话页面url
  async getKnowledgeBaseStartUrl(
    applicationId: string
  ): Promise<NestedPageUrl> {
    const req = this.buildRequestV2({
      url: `/v2/lamoss/Application/${applicationId}/starturl`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  //离线生成小蜜蜂页面url
  async getBeezyStartUrl(): Promise<NestedPageUrl> {
    const req = this.buildRequestV2({
      url: `/v2/lamoss/starturl`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
}
