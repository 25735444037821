import _ from "lodash";
import { createModel, createSelector } from "nyax";
import { CompanyChartItem } from "src/containers/organization/companyConnection/components/CompanyRelation";
import { CompanyConnectInfo, CompanyGroup } from "src/models/company";
import { ModelBase } from "src/store/ModelBase";
import { CompanyHelperModel } from "src/store/models/entity/company/helper";
import { GlobalSelectCompanyUIModel } from "src/store/models/logic/globalSelectCompany";

// todo 接口稳定后删除
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mockA = {
  companyId: "78936b4b-f8c5-496c-a31f-ccf38dd688a6",
  name: "公司200",
  children: [
    {
      companyId: "c781b391-17b8-4dfd-af5f-443473896e07",
      name: "公司201",
      children: [
        {
          companyId: "3333",
          name: "公司2033333",
        },
        {
          companyId: "4444",
          name: "公司4444",
        },
      ],
    },
  ],
};

export type CompanyConnectionModalType = "companyConnect" | "companyJoin";

export const CompanyConnectionUiModel = createModel(
  class extends ModelBase {
    private get _companyHelper() {
      return this.getContainer(CompanyHelperModel);
    }

    private get _globalSelectCompanyContainer() {
      return this.getContainer(GlobalSelectCompanyUIModel);
    }

    public initialState() {
      return {
        modalType: null as CompanyConnectionModalType | null,
        companygroup: null as CompanyGroup | null,
        companyConnectInfo: null as CompanyConnectInfo | null,
      };
    }

    public selectors() {
      return {
        parentCompanyGroup: createSelector(
          () =>
            this._globalSelectCompanyContainer.state.selectedGlobalComponyId,
          () => this.state.companygroup,
          (id, companygroup) => {
            if (!id || !companygroup) {
              return null;
            }
            const treeData = [companygroup];
            return this.getParentTreeNode(id, treeData, null);
          }
        ),
        currentCompanyGroup: createSelector(
          () =>
            this._globalSelectCompanyContainer.state.selectedGlobalComponyId,
          () => this.state.companygroup,
          (id, companygroup) => {
            if (!id || !companygroup) {
              return null;
            }
            const treeData = [companygroup];
            return this.getTreeNode(id, treeData);
          }
        ),
        companyGroupChartData: createSelector(
          () =>
            this._globalSelectCompanyContainer.state.selectedGlobalComponyId,
          () => this.state.companygroup,
          (id, companygroup) => {
            const data = companygroup ? [_.cloneDeep(companygroup)] : null;
            if (!data) {
              return null;
            }
            return this.getCompanyGroupChartData(data, id);
          }
        ),
        companyGroupDepth: createSelector(
          () => this.state.companygroup,
          (companygroup) => {
            return companygroup ? this.getMaxFloor(companygroup) : 0;
          }
        ),
      };
    }

    public reducers() {
      return {
        ...super.reducers(),
        setModalType: (value: CompanyConnectionModalType | null) => {
          this.state.modalType = value;
        },
        setCompanygroup: (value: CompanyGroup | null) => {
          this.state.companygroup = value;
        },
        setCompanyConnectInfo: (value: CompanyConnectInfo | null) => {
          this.state.companyConnectInfo = value;
        },
      };
    }

    public effects() {
      return {
        fetchCompanygroup: async () => {
          const value = await this._companyHelper.actions.getCompanyGroup.dispatch(
            null
          );
          await this.actions.setCompanygroup.dispatch(value);
        },
        fetchConnectionInfo: async () => {
          const value = await this._companyHelper.actions.getConnectionInfo.dispatch(
            null
          );
          await this.actions.setCompanyConnectInfo.dispatch(value);
        },
        initialRequest: async () => {
          await this.actions.fetchCompanygroup.dispatch(null);
          await this.actions.fetchConnectionInfo.dispatch(null);
        },
      };
    }

    private getTreeNode(id: string, data: CompanyGroup[]): CompanyGroup | null {
      for (const item of data) {
        if (item.companyId === id) {
          return item;
        } else if (item.children?.length) {
          const tempValue = this.getTreeNode(id, item.children);
          if (tempValue) {
            return tempValue;
          }
        }
      }
      return null;
    }

    private getParentTreeNode(
      id: string,
      data: CompanyGroup[],
      lastCompanyGroup: CompanyGroup | null
    ): CompanyGroup | null {
      for (const item of data) {
        if (item.companyId === id) {
          return lastCompanyGroup;
        } else if (item.children?.length) {
          const tempValue = this.getParentTreeNode(id, item.children, item);
          if (tempValue) {
            return tempValue;
          }
        }
      }
      return null;
    }

    private getCompanyGroupChartData(
      data: CompanyGroup[],
      id: string | null
    ): CompanyChartItem[] | null {
      return data.map((companyItem) => {
        const item = companyItem as CompanyChartItem;
        if (item.children) {
          this.getCompanyGroupChartData(item.children, id);
        }
        item.label = item.name;
        item.id = item.companyId;
        return item;
      });
    }

    private getMaxFloor(treeData: CompanyGroup) {
      let arr = [];
      arr.push(treeData);
      let depth = 0;
      while (arr.length > 0) {
        const temp = [];
        for (let i = 0; i < arr.length; i++) {
          temp.push(arr[i]);
        }
        arr = [];
        for (let i = 0; i < temp.length; i++) {
          const itemChildren: CompanyGroup[] | null = temp[i].children ?? null;
          if (itemChildren) {
            for (let j = 0; j < itemChildren.length; j++) {
              arr.push(itemChildren[j]);
            }
          }
        }
        if (arr.length >= 0) {
          depth++;
        }
      }
      return depth;
    }
  }
);
