import { createModel } from "nyax";
import { Calendars, CalendarsQuery } from "src/models/calendars";
import { CalendarsEntityModel } from "src/store/models/entity/calendars/entity";
import { createUISideListWithPermissionModel } from "src/store/models/ui/_shared";

export const CalendarsSideUIModel = createModel(
  class extends createUISideListWithPermissionModel<Calendars, CalendarsQuery>({
    setItems: (getContainer, items) =>
      getContainer(CalendarsEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(CalendarsEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(CalendarsEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initial: async (payload: { departmentId: string }) => {
          const { departmentId } = payload;
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query: CalendarsQuery
            ) =>
              this.dependencies.serviceClient.calendars.list(
                departmentId,
                pageIndex,
                pageSize,
                query
              ),
          });
        },
      };
    }
  }
);
