import { createModel } from "nyax";
import { AppVersionV2, AppVersionV2UpdateData } from "src/models/appV2";
import { AppHelperModel } from "src/store/models/entity/apps/helper";
import { AppVersionEntityModel } from "src/store/models/entity/appVersion/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const AppVersionHelperModel = createModel(
  class extends createHelperModel<AppVersionV2>({
    setItems: (getContainer, items) =>
      getContainer(AppVersionEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(AppVersionEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(AppVersionEntityModel).state.byId[id],
  }) {
    public effects() {
      return {
        ...super.effects(),
        readByAppIds: async (payload: {
          appIds: string[];
          force?: boolean;
        }) => {
          const { appIds, force = false } = payload;
          return await this._readByParentIds({
            parentIds: appIds,
            getAllAction: (parentId) => {
              return this.dependencies.serviceClient.appsV2.getAllAppVersions(
                parentId
              );
            },
            getEntityParentId: (entity: AppVersionV2) => entity.appId,
            force,
          });
        },
        readById: async (payload: {
          appId: string;
          versionId: string;
          force?: boolean;
        }) => {
          const { appId, versionId, force } = payload;
          return await this._readById({
            id: versionId,
            getByIdAction: () => {
              return this.dependencies.serviceClient.appsV2.getAppVersionById(
                appId,
                versionId
              );
            },
            force,
          });
        },

        readDraftVersionById: async (payload: {
          appId: string;
          draftVersionId: string;
        }) => {
          const { appId, draftVersionId } = payload;

          return await this.dependencies.serviceClient.appsV2.getAppVersionById(
            appId,
            draftVersionId
          );
        },

        update: async (payload: {
          appId: string;
          appVersionId: string;
          params: AppVersionV2UpdateData;
        }) => {
          const { appId, appVersionId, params } = payload;

          return await this._update({
            id: appVersionId,
            updateAction: () =>
              this.dependencies.serviceClient.appsV2.updateAppVersion(
                appId,
                appVersionId,
                params
              ),
          });
        },
        delete: async (payload: { appId: string; id: string }) => {
          const { id, appId } = payload;
          const appVersion = await this._delete({
            id: id,
            deleteAction: () =>
              this.dependencies.serviceClient.appsV2.deleteAppVersion(
                appId,
                id
              ),
          });

          await this.getContainer(AppHelperModel).actions.readById.dispatch({
            id: appId,
            force: true,
          });

          return appVersion;
        },
      };
    }
  }
);
