// eslint-disable-next-line import/no-anonymous-default-export
export default {
  robot: {
    entityTypeName: "机器人",
    robotName: "机器人名称",
    upgrade: "升级软件",
    introduction:
      "机器人管理主要用于创建并管理专用机器人的连接，同时可对机器人的许可状态进行管理。",
    resourceGroupIntroduction:
      "资源组机器人主要用于创建并管理资源组专用机器人的连接，同时可对资源组机器人的许可状态进行管理。",
    sharedIntroduction:
      "共享机器人主要用于创建并管理租户共享机器人的连接，同时可对共享的机器人许可状态进行管理。",
    lastTime: "上次通讯时间",
    addRobot: "添加机器人",
    remoteDesktop: "远程桌面",
    computerName: "计算机",
    connectStatusName: "连接状态",
    licenseSku: "许可证类型",
    licenseStatusName: "许可状态",
    allLicenseStatusName: "全部连接状态",
    countDown: "刷新倒计时",
    unableGet: "未检测到版本号",
    upgrading: "升级中",
    cpu: "CPU",
    memory: "内存",
    disk: "磁盘",
    network: "网络",
    lastFive: "最近5分钟",
    networkDownlink: "网络下行",
    networkUplink: "网络上行",
    diskRead: "磁盘读取",
    diskWrite: "磁盘写入",
    emptyTip: "未采集到数据，请稍后重试",
    licenseStatus: {
      unlicensed: "未占用",
      licensed: "占用",
    },
    status: {
      all: "全部状态",
      free: "空闲",
      busy: "忙碌",
      noResponse: "无响应",
      break: "中断",
      connect: "连接",
      unLicensed: "未许可",
      unlogged: "未登录",
      tip: {
        unlogged:
          "此机器人所在计算机处于用户未登录状态，请先完成登录，否则会导致流程运行失败。",
      },
    },
    allResource: "全部来源",
    resource: {
      title: "群组迁移",
      current: "当前资源组",
      target: "迁移至资源组",
    },
    detail: {
      title: "机器人管理详情",
      bindUser: "已绑定域名/用户名",
      name: "机器人名称",
      status: "机器人状态",
      code: "机器人连接字符串",
      copyCode:
        "请先复制机器人连接字符串，再将机器人连接字符串粘贴至客户端机器人，完成激活。",
      tag: "机器人标签",
      remark: "机器人备注",
      time: "机器人上次通讯时间",
      workflow: "关联流程部署",
      queue: "关联机器人组",
      cpu: "CPU使用率",
      memory: "内存使用率",
      listeningStatus: "机器人状态",
      listeningStatusPrefix: "当更改为",
      listeningStatusSubfix: "时",
      thresholdTrigger: "达到阈值时",
      emailTo: "自动发送电子邮件",
      terminateCurrTask: "终止当前流程",
      unsettled: "未设置",
      undo: "不处理",
      bigger: "大于",
    },
    validate: {
      name: "机器人名称",
      status: "机器人状态",
      code: "机器人连接字符串",
    },
    placeholder: {
      tags: "请输入标签按回车确认",
      type: "用户机器人",
    },
    info: {
      bindConfirm: "是否为{name}绑定{entityTypeName}?",
      unbindConfirm: "是否要移除{name}{entityTypeName}?",
    },
    tips: {
      copy: "复制成功",
      create: {
        code: "机器人连接字符串",
        content: "机器人创建成功，请复制激活连接字符串用于机器人客户端激活",
        mark: "可在机器人详情页中查看机器人连接字符串",
      },
      unBind:
        "解绑成功后，该客户端机器人将无法执行流程。你确定要解绑机器人吗？",
      bindDelete: "存在绑定的许可证不可删除！",
      bindFail: {
        title: "解绑失败",
        content: "机器人有等待执行和正在执行的任务",
      },
      upgrade: {
        title: "请确认是否升级选中的机器人软件版本至最新版",
        content: {
          title: "升级过程中：",
          network: "-请确保网络连接正常，否则会更新失败",
          robot: "-机器人将不能运行流程，请提前做好准备",
          planTime: "计划时间：",
          rightNow: "立刻",
          whenIdle: "当机器人空闲时立即升级",
          specificTime: "定时升级",
        },
      },
    },
    scope: {
      resourceGroup: "机器人",
      shared: "共享机器人",
    },
    executionPlan: "待执行计划",
    listName: "{name}名称",
    robotScope: "机器人级别",
    tab: {
      warning: "预警配置",
      monitor: "计算机监控",
      configuration: "基本配置",
    },
    hardwareMonitor: "硬件监控",
    warningConfig: "预警阈值配置",
    share: "分享机器人",
    taskName: "任务名称",
    nextRuntime: "下次执行时间",
    effective: "已生效",
    notEffective: "未生效",
    setting: {
      startupSettings: "启动设置:",
      executionProcess: "执行流程:",
      longRange: "RDP远程:",
      setUp: {
        powerOn: "开机启动",
        minimize: "执行流程时界面最小化",
        progressNotice: "执行流程时显示当前执行节点",
        unlockScreen: "执行流程时自动解锁屏幕",
        username: "用户名:",
        password: "密码:",
        resolvingPower: "保持会话，主屏幕分辨率大小:",
      },
      tip: "此选项勾选后，当执行流程时检测到锁屏，机器人将自动解锁屏幕运行流程，执行结束后将自动锁屏。",
      syncTip:
        "此版本的机器人不适用于此功能，即更新[基本配置]不会同步到此机器人。",
    },
    recording: {
      saveTime: "保持时间:",
      storageCapacity: "存储容量:",
      recordingUp: {
        timeBefore: "仅保留最近",
        timeAfter: "日内视频",
        capacityBefore: "总视频存储容量最大",
        capacityAfter: "G",
      },
    },
    hoursUp: {
      timeFrame: "时间范围",
      allDay: "全天",
      specificTime: "特定时间",
      tips: "指定机器人可接受控制台调度的时间范围",
    },
    logUp: {
      featureSwitch: "功能开关:",
      enableErrorLogScreenshot: "流程出现错误日志时生成截图",
      logBefore: "仅保留本机最近",
      logAfter: "日内流程执行的日志文件",
      storagePath: "存储路径:",
    },
    pip: {
      clipboard: "剪贴板:",
      shareClipboard: "共享剪贴板",
    },
  },
};
