import { createModel } from "nyax";
import { User } from "src/models/user";
import { UserEntityModel } from "src/store/models/entity/user/entity";
import { createListModel } from "src/store/models/entity/_shared";

export const UserListModel = createModel(
  class extends createListModel<User>({
    setItems: (getContainer, items) =>
      getContainer(UserEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(UserEntityModel).getters.items,
    getItem: (getContainer, id) => getContainer(UserEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initialIterator: async (payload: {
          queryText?: string;
          resourceGroupId?: string;
          force?: boolean;
        }) => {
          const { force = true, queryText, resourceGroupId } = payload;
          this._initialIterator({
            initialAction: () =>
              this.dependencies.serviceClient.user.list(
                resourceGroupId,
                queryText
              ),
            force: force,
          });
        },
      };
    }
  }
);
