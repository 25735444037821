// eslint-disable-next-line import/no-anonymous-default-export
export default {
  trigger: {
    basic: "Basic",
    timedTask: "Timed Task",
    advanced: "Advanced",
    advancedTips:
      "Other configuration parameters of timed tasks, strategy points when the robot is running, such as time zone, etc.",
    createTips:
      "Timed tasks allow business processes to run according to established time policies by configuring timing schedules, time zones, etc.",
    source: "Source",
    name: "Task Schedule Name",
    preScheduleTime: "Last Run Time",
    nextScheduleTime: "Next Run Time",
    triggerConfig: "Configure Task Schedule Details",
    executionTargetAndArguments: "Confirm Execution Targets and Parameters",
    triggerTime: "Schedule Time",
    setBeginTime: "Set start time for task schedule",
    setEndTime: "Set end time for task schedule",
    jobSetting: "Task Settings",
    enableSuccessfully: "Started",
    disabledSuccessfully: "Ended",
    executeSuccessfully: "Executed",
    status: {
      all: "All status",
      enabled: "Enabled",
      disabled: "Disable",
    },
    enableHandle: {
      enabled: "Enable",
      disabled: "Disable",
    },
    validate: {
      endTimeNotBeforeBeginTime: "End time cannot be earlier than start time.",
      endTimeNotBeforeCurrentTime:
        "End time cannot be earlier than current time.",
      pleaseInputRightcron: "Please enter the correct content",
    },
    timeType: {
      minute: "By minute",
      hour: "By hour",
      day: "By day",
      week: "By week",
      month: "By month",
      once: "Specified time once",
      cron: "By the expression",
      calendar: "By the calendar",
    },
    log: {
      operation: {
        createdTrigger: "A cron job has been created.",
        createdJob: "A task has been created.",
        stopedTrigger: "End Cron Job",
        startedTrigger: "Start Cron Job",
        editedTrigger: "Edit Cron Job",
        updateFailedAndDisable: "Disabled due to update failure",
        executeFailedWorkflowNoRobot:
          "Execution failed: the workflow is not associated with a robot",
        executeFailedUnknownError: "Execution failed: unknown exception",
      },
      title: "Task Schedule Log",
      logName: "Task Schedule Name",
      logTime: "Log Time",
      content: "Log Content",
      operationName: "Operator",
    },
    timeZone: {
      name: "Time Zone",
      tips: "The time zone of the scheduled schedule",
      default: "The current time zone of this machine",
      placeholder:
        "Default tenant time zone - The current time zone of this machine",
      options: {
        china: "(UTC+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi",
        japan: "(UTC+09:00) Osaka, Sapporo, Tokyo",
        us: "(UTC-08:00) Pacific Time (US and Canada)",
      },
    },
    limitations: {
      name: "Limitations",
      calendar: {
        name: "Limit by the calendar",
        tips: "A mode of operation restriction. If the robot is scheduled to configure this policy, the business will not be executed on working calendar days.",
      },
    },
    triggerTask: "trigger the task.",

    cronExpressionTip:
      "The {unit} of the start time is used as the base, and trigger the task execution cyclically according to the scheduled time.",
    cronExpressionIntervalTip:
      "The {unit} of the start time is used as the base, and the execution is cycled at intervals according to the schedule time.",
    cronExpressionOnceTip:
      "The task will be executed once at the specified time.",
    cronExpressionCustomCronTip: "Execution based on cron expressions.",
    cronExpressionCalendarTip: "Execution based on the Calendar.",
    lastThreeExecutions: "Last Three Executions: ",
    noneCalculateResultAvailable: "No valid execution time yet.",
    monthlyOptions: {
      first: "First",
      second: "Second",
      third: "Third",
      fourth: "Fourth",
      fifth: "Fifth",
      firstDays: "Day {number}",
      countBackwardsFalse: "At current month",
      countBackwardsTrue: "At current month, countdown to",
    },
  },
};
