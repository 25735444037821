// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ocr: {
    ocrName: "OCR",
    entityTypeName: "TODO:",
    name: "TODO: Model Name",
    identification: "识别",
    ocrDetailName: "OCR服务比较",
    identifyMaterial: "识别素材",
    fileTypeSupport: " 文件类型支持：",
    identifyResults: "识别结果",
    parsedFailed: "解析失败",
    parsedSuccessful: "解析成功",
    introduction:
      "集成云扩自研和各大顶级AI平台的OCR服务，并提供免费试用服务，可快速比较识别结果，节省选择AI服务的时间。并把所有AI服务封装成自动化组件，拖拽即用。",
    compareButton: "比较",
    uploadOnly: "只能上传 {files} 文件! ",
    QRcode: "请扫码进群，联系云扩获取服务",
    editQRcode: "请扫码进群，联系云扩修改服务",
    deleteQRcode: "请扫码进群，联系云扩删除服务",
    encooAIServicePrice:"云扩AI服务价格",
    discover:"了解",
    aiPlatformType: {
      Encoo: "云扩",
      Alibaba: "阿里",
      Tencent: "腾讯",
      Baidu: "百度",
      GuoPiao: "国票",
      Intsig: "合合",
      Iflytec: "讯飞",
      HuaweiCloud: "华为云",
      Microsoft: "微软",
      PXM: "票小秘",
    },
    comparison: {
      id: "身份证识别比较",
      drivingLicense: "行驶证识别比较",
      businessLicense: "营业执照识别比较",
      bankCard: "银行卡识别比较",
      recognizeAndValidation: "发票识别与验真比较",
      vehicleCertificate: "车辆合格证识别比较",
      licensePlate: "车牌识别比较",
      VATInvoice: "增值税识别比较",
      rollTicket: "卷票识别比较",
      quotaInvoice: "定额发票识别比较",
      bankReceipt: "银行回单识别比较",
      taxiTicket: "出租车票识别比较",
      airTransport: "飞机票识别比较",
      generalText: "通用识别比较",
      handwrittenNumbers: "手写数字识别比较",
      handwrittenText: "手写文本识别比较",
      identificationOfCredentials: "证照识别比较",
      billIdentification: "票据识别比较",
      generalCharacterRecognition: "通用识别比较",
      vehiclePurchaseInvoice: "购车发票识别比较",
      generalTable: "表格识别比较",
    },
    services: {
      id: "身份证识别",
      drivingLicense: "行驶证识别",
      businessLicense: "营业执照识别",
      bankCard: "银行卡识别",
      recognizeAndValidation: "发票识别验真",
      vehicleCertificate: "车辆合格证识别",
      VATInvoice: "增值税发票识别",
      rollTicket: "卷票识别",
      quotaInvoice: "定额发票识别",
      bankReceipt: "银行回单识别",
      taxiTicket: "出租车票识别",
      licensePlate: "车牌识别",
      vehiclePurchaseInvoice: "购车发票识别",
      handwrittenNumbers: "手写数字识别",
      handwrittenText: "手写文本识别",
      generalTable: "表格识别",
      billIdentification: "票据识别",
      airTransport: "飞机票识别",
      generalCharacterRecognition: "通用识别",
    },
  },
};
