import { createModel } from "nyax";
import { JobGroup } from "src/models/job";
import { JobGroupEntityModel } from "src/store/models/entity/jobGroup/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const JobGroupHelperModel = createModel(
  class extends createHelperModel<JobGroup>({
    setItems: (getContainer, items) =>
      getContainer(JobGroupEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(JobGroupEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(JobGroupEntityModel).state.byId[id],
  }) {
    public effects() {
      return {
        ...super.effects(),
        readById: async (payload: { jobGroupId: string; force?: boolean }) => {
          const { jobGroupId, force } = payload;
          return await this._readById({
            id: jobGroupId,
            getByIdAction: () => {
              return this.dependencies.serviceClient.workflowLayout.getJobGroupById(
                jobGroupId
              );
            },
            force,
          });
        },
        stopJob: async (payload: { jobGroupId: string }) => {
          const { jobGroupId } = payload;
          return await this.dependencies.serviceClient.workflowLayout.stopJobGroup(
            jobGroupId
          );
        },
        delete: async (payload: { jobGroupId: string }) => {
          const { jobGroupId } = payload;
          return await this._delete({
            id: jobGroupId,
            deleteAction: () =>
              this.dependencies.serviceClient.workflowLayout.deleteJobGroup(
                jobGroupId
              ),
          });
        },
      };
    }
  }
);
