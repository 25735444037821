import {
  LicenseApplyPlayLoad,
  LicenseBindingData,
  ProductNameOfLicenseCounter,
} from "@encoo-web/encoo-lib";
import { createModel } from "nyax";
import { License, LicenseBindingQueryData } from "src/models/license";
import { LicenseEntityModel } from "src/store/models/entity/license/entity";
import { createHelperModel } from "src/store/models/entity/_shared";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";

export const LicenseHelperModel = createModel(
  class extends createHelperModel<License>({
    setItems: (getContainer, items) =>
      getContainer(LicenseEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(LicenseEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(LicenseEntityModel).state.byId[id],
  }) {
    private get _userUIModel() {
      return this.getContainer(LoginUserUIModel);
    }
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(LicenseEntityModel),
      };
    }
    public effects() {
      return {
        ...super.effects(),
        readAll: async (payload: { force?: boolean }) => {
          const { force } = payload;
          this._readByParentIds({
            parentIds: [""],
            getAllAction: () => {
              return this.dependencies.serviceClient.licenseBindings.getAllV2();
            },
            getEntityParentId: (entity: License) => entity.id,
            force,
          });
        },
        licenseBindings: async () => {
          return this.dependencies.serviceClient.licenseBindings.getAllV2();
        },
        bindToForUnboundLicense: async (playload: {
          licenseBindingData: LicenseBindingData;
        }) => {
          const { licenseBindingData } = playload;

          return this.dependencies.serviceClient.licenseBindings.bindToForUnboundLicenseV2(
            licenseBindingData
          );
        },
        getLicenseByBoundToId: async (boundToId: string) => {
          return this.dependencies.serviceClient.licenseBindings.getLicenseByBoundToIdV2(
            boundToId
          );
        },
        getAllLicenseBindings: async (playload: LicenseBindingQueryData) => {
          const { boundToId, productName, sku, binded } = playload;
          return this.dependencies.serviceClient.licenseBindings.getAllLicenseBindingsV2(
            { boundToId, productName, sku, binded }
          );
        },
        unbind: async (playload: {
          licenseBindingId: string;
          boundToId: string;
        }) => {
          const { licenseBindingId, boundToId } = playload;
          return this.dependencies.serviceClient.licenseBindings.unbindV2(
            licenseBindingId,
            boundToId
          );
        },
        applyLicense: async (playload: LicenseApplyPlayLoad) => {
          const { mobilePhone, email, description } = playload;
          return this.dependencies.serviceClient.licenseBindings.applyLicenseV2(
            {
              mobilePhone,
              email,
              description,
            }
          );
        },
        importLicense: async (playload: string) => {
          return this.dependencies.serviceClient.licenseBindings.importLicenceV2(
            playload
          );
        },
        licenseBindingsBindTo: async (playload: LicenseBindingData) => {
          return this.dependencies.serviceClient.licenseBindings.licenseBindingsBindToV2(
            playload
          );
        },
        getLicenseCounters: async (playload: ProductNameOfLicenseCounter) => {
          return this.dependencies.serviceClient.licenseBindings.getLicensecountersV2(
            playload
          );
        },
      };
    }
  }
);
