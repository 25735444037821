import { createModel } from "nyax";
import { Package } from "src/models/package";
import { CompanyPackageEntityModel } from "src/store/models/entity/companyPackage/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const CompanyPackageHelperModel = createModel(
  class extends createHelperModel<Package>({
    setItems: (getContainer, items) =>
      getContainer(CompanyPackageEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(CompanyPackageEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(CompanyPackageEntityModel).state.byId[id],
  }) {
    public initialState() {
      return {
        ...super.initialState(),
      };
    }
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(CompanyPackageEntityModel),
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
      };
    }
    public effects() {
      return {
        ...super.effects(),
        getAll: async () => {
          await this._readAll({
            getAllAction: async () => {
              const response =
                await this.dependencies.serviceClient.package.getCompanyPackages(
                  0,
                  100000
                );
              return response.list;
            },
          });
        },
      };
    }
  }
);
