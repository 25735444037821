/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import FileUploadingComponent from "src/containers/layout/components/FileUploadingComponent";
import LoadingContainer from "src/containers/loading/Loading";
import { ThemeContext } from "src/contexts";
import { useThemeSet } from "src/hooks";

const AppMainInternal = React.memo(function AppMainInternal(props: {
  children?: React.ReactNode;
}) {
  const { children } = props;
  return (
    <main
      id="app-main"
      css={css`
        display: flex;
        flex-grow: 1;
        min-width: 1250px;
        min-height: 500px;
        position: relative;
        overflow: auto;
      `}
    >
      <LoadingContainer />
      {children}
      <FileUploadingComponent />
    </main>
  );
});

export default React.memo(function AppMain(props: {
  children?: React.ReactNode;
}) {
  const { children } = props;

  const themeSet = useThemeSet();

  return (
    <ThemeContext.Provider value={themeSet.main}>
      <AppMainInternal>{children}</AppMainInternal>
    </ThemeContext.Provider>
  );
});
