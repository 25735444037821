import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { CurrentCompanyUser } from "src/models/currentCompanyUser";

export const COMPANY_USER_SERVICE_NAME = "companyUser";
export class CompanyUserServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, COMPANY_USER_SERVICE_NAME);
  }

  async getCompanyUserForSelf(): Promise<CurrentCompanyUser> {
    const req = this.buildRequest({
      url: `/v2/CompanyUsers/self`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
}
