import { createModel, createSelector } from "nyax";
import { Robot } from "src/models/robot";
import { ModelBase } from "src/store/ModelBase";
import { DataPermissionEntityModel } from "src/store/models/entity/dataPermission/entity";
import { RobotEntityModel } from "src/store/models/entity/robot/entity";
import { RobotHelperModel } from "src/store/models/entity/robot/helper";

export const RobotDetailUIModel = createModel(
  class extends ModelBase {
    private get _id() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.containerKey!;
    }
    public selectors() {
      return {
        robotDetail: createSelector(
          () => this.getContainer(RobotEntityModel).state.byId,
          () => this.getContainer(DataPermissionEntityModel).state.byId,
          (robotById, permissionById): Robot | null => {
            const item = robotById[this._id];
            return item
              ? {
                  ...item,
                  permissionValues:
                    permissionById[this._id]?.permissionValues ?? [],
                }
              : null;
          }
        ),
      };
    }
    public effects() {
      return {
        requestDetail: async () => {
          await this.getContainer(RobotHelperModel).actions.getById.dispatch({
            robotId: this._id,
          });
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
