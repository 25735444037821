import { HttpRequest, HttpResponse, Middleware } from "@encoo-web/encoo-lib";
import { NextMiddleware } from "@encoo-web/encoo-lib/types/http/nextMiddleware";

export class HTTPMethodOverrideMiddleware implements Middleware {
  public async sendRequest(
    httpRequest: HttpRequest,
    next: NextMiddleware
  ): Promise<HttpResponse> {
    if (
      httpRequest.method === "DELETE" ||
      httpRequest.method === "PUT" ||
      httpRequest.method === "PATCH"
    ) {
      httpRequest.headers?.set("X-HTTP-Method-Override", httpRequest.method);
      httpRequest.method = "POST";
    }
    return await next.process();
  }
}
