/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Menu } from "antd";
import { memoize } from "lodash";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { hasAppV2FeatureFlag } from "src/containers/vicodeApp/ViCodeApp";
import { ThemeContext } from "src/contexts";
import {
  useEnvironmentEdition,
  useFormatMessage,
  useGetContainer,
  useTheme,
  useThemeSet,
} from "src/hooks";
import { AppRouteInfo, appRouteDefinitions } from "src/routes";
import {
  AppRouteType,
  MenuDefinition,
  MenuExpandedDefinition,
} from "src/routes/_shared";
import { APP_MENUS } from "src/routes/menu";
import { CurrentPermissionModel } from "src/store/models/logic/currentPermission";
import { RouterModel } from "src/store/models/router";

const AppSidebarInternal = React.memo(function AppSidebarInternal() {
  const theme = useTheme();
  const formatMessage = useFormatMessage();
  const getContainer = useGetContainer();
  const environmentEdition = useEnvironmentEdition();

  const currentPermissionContainer = getContainer(CurrentPermissionModel);
  const permissionShowTypeValues = useSelector(
    () => currentPermissionContainer.getters.permissionShowTypeValues
  );
  const router = getContainer(RouterModel);
  const routeType = useSelector(() => router.getters.currentRouteInfo.type);

  const rootMenuKey = useMemo(() => {
    let routeKey: string | undefined = routeType;
    while (routeKey && APP_MENUS[routeKey]) {
      const menu: MenuExpandedDefinition = APP_MENUS[routeKey];

      if (!menu.parentKey) {
        break;
      }
      routeKey = menu.parentKey;
    }
    return routeKey;
  }, [routeType]);

  const hasPermission = useCallback(
    (menuItem: MenuDefinition) => {
      if (!menuItem.routeType) {
        return true;
      }
      if (menuItem.routeType === "market" && environmentEdition === "Private") {
        return false;
      }
      const feature = appRouteDefinitions[menuItem.routeType].showPermissions;
      if (!feature) {
        return true;
      }

      if (feature instanceof Array) {
        return feature.some(
          (item) => permissionShowTypeValues?.[item] !== "Hidden"
        );
      }
      return permissionShowTypeValues?.[feature] !== "Hidden";
    },
    [environmentEdition, permissionShowTypeValues]
  );

  const routeName = useMemo(
    () =>
      APP_MENUS[rootMenuKey]?.name
        ? formatMessage(APP_MENUS[rootMenuKey].name)
        : undefined,
    [formatMessage, rootMenuKey]
  );

  const subRoutes = useMemo(
    () =>
      APP_MENUS[rootMenuKey]?.children?.filter(
        (item) => !item.hiddenInMenu && hasPermission(item)
      ),
    [hasPermission, rootMenuKey]
  );

  const selectedKeys = useMemo(() => {
    let routeKey: string | undefined = routeType;

    while (routeKey && APP_MENUS[routeKey]) {
      const menu: MenuExpandedDefinition = APP_MENUS[routeKey];
      if (menu.hiddenInMenu) {
        routeKey = menu.parentKey;
      } else {
        break;
      }
    }
    return routeKey ? [routeKey] : [];
  }, [routeType]);

  const onMenuClick = useMemo(
    () =>
      memoize((type?: AppRouteType) => async () => {
        if (!type) {
          return;
        }

        if (type === "app" || type === "liveApp") {
          if (hasAppV2FeatureFlag()) {
            await getContainer(
              RouterModel
            ).actions.navigateByRouteInfo.dispatch({
              type,
              params: { appV2: "true" },
            } as AppRouteInfo);
            return;
          }
        }

        getContainer(RouterModel).actions.navigateByRouteInfo.dispatch({
          type,
          params: {},
        } as AppRouteInfo);
      }),
    [getContainer]
  );

  return (
    <aside
      css={css`
        display: flex;
        flex-direction: column;
        min-width: 160px;
        max-width: 160px;

        border-right: 1px solid ${theme.bodyDivider};
        background-color: ${theme.bodyBackground};
        overflow-y: auto;
        .ant-menu-inline,
        .ant-menu-vertical,
        .ant-menu-vertical-left {
          border-right: 0;
        }
      `}
    >
      <div
        css={css`
          padding: 23px 16px;
          font-weight: 600;
          font-size: 16px;
        `}
      >
        {routeName}
      </div>

      <Menu
        css={css`
          font-weight: 600;
          .ant-menu-item {
            width: 100%;
            height: 48px;
            line-height: 48px;
            font-weight: 400;
            color: ${theme.bodyText};
            margin: 0 !important;
            transition: none;
          }
          .ant-menu-item-selected {
            padding: 0 16px 0 13px;
            color: ${theme.primaryColor};
            font-weight: 600;
            background-color: ${theme.bodyFrameBackground} !important;
            border-left: 3px solid ${theme.primaryColor};
          }
          .ant-menu-item:active,
          .ant-menu-submenu-title:active {
            background-color: ${theme.bodyFrameBackground};
          }
          .ant-menu-light .ant-menu-item:hover {
            color: ${theme.menuHoverBackground};
          }
        `}
        selectedKeys={selectedKeys}
      >
        {subRoutes?.map((sub) => (
          <Menu.Item
            itemID={sub.name}
            title={formatMessage(sub.name)}
            key={sub.routeType}
            onClick={onMenuClick(sub.routeType)}
          >
            {formatMessage(sub.name)}
          </Menu.Item>
        ))}
      </Menu>
    </aside>
  );
});

export default React.memo(function AppSideMenu() {
  const themeSet = useThemeSet();

  return (
    <ThemeContext.Provider value={themeSet.sidebar}>
      <AppSidebarInternal />
    </ThemeContext.Provider>
  );
});
