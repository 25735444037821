/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Menu } from "antd";
import { memoize } from "lodash";
import { MenuInfo } from "rc-menu/lib/interface";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import iconAll from "src/assets/menu/icon_all.svg";
import iconClose from "src/assets/menu/icon_close.svg";
import iconExpandAll from "src/assets/menu/icon_sidebar_expandAll.svg";
import { ThemeContext } from "src/contexts";
import {
  useEnvironmentEdition,
  useFormatMessage,
  useGetContainer,
  useTheme,
  useThemeSet,
} from "src/hooks";
import messageIds from "src/locales/messageIds";
import { MenuDefinition, MenuExpandedDefinition } from "src/routes/_shared";
import { APP_MENUS, appMenuDefinitions } from "src/routes/menu";
import { appRouteDefinitions } from "src/routes/router";
import { CurrentPermissionModel } from "src/store/models/logic/currentPermission";
import { PopMenuModel } from "src/store/models/popMenu";
import { RouterModel } from "src/store/models/router";

const AppPopMenuInternal = React.memo(function AppPopMenuInternal() {
  const formatMessage = useFormatMessage();
  const theme = useTheme();
  const environmentEdition = useEnvironmentEdition();
  const getContainer = useGetContainer();
  const sidebar = getContainer(PopMenuModel);
  const isVisible = useSelector(() => sidebar.state.isVisible);
  const isAllServiceMenuVisible = useSelector(
    () => sidebar.state.isAllServiceMenuVisible
  );
  const router = getContainer(RouterModel);
  const currentPermissionContainer = getContainer(CurrentPermissionModel);
  const permissionShowTypeValues = useSelector(
    () => currentPermissionContainer.getters.permissionShowTypeValues
  );
  const selectedKey = useSelector(() => router.getters.currentRouteInfo.type);

  const selectedKeys = useMemo(() => {
    let routeKey: string | undefined = selectedKey;

    while (routeKey && APP_MENUS[routeKey]) {
      const menu: MenuExpandedDefinition = APP_MENUS[routeKey];
      if (menu.hiddenInMenu) {
        routeKey = menu.parentKey;
      } else {
        break;
      }
    }
    return routeKey ? [routeKey] : [];
  }, [selectedKey]);

  // portal: 添加 dom 到 root 主页层为了事件穿透
  const [targetDom, setTargetDom] = useState(null);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setTargetDom(document.getElementById("root") as any);
  }, []);

  const [isActive, setIsActive] = useState(isVisible);

  const toggleAllServiceMenu = useCallback(
    () =>
      sidebar.actions.setAllServiceMenuVisible.dispatch(
        !isAllServiceMenuVisible
      ),

    [isAllServiceMenuVisible, sidebar.actions.setAllServiceMenuVisible]
  );

  useEffect(() => {
    setIsActive(isVisible);
  }, [isVisible, setIsActive]);

  useEffect(() => {
    if (isActive === false) {
      sidebar.actions.setVisible.dispatch(false);
      sidebar.actions.setAllServiceMenuVisible.dispatch(false);
    }
  }, [isActive, sidebar.actions.setAllServiceMenuVisible, sidebar.actions.setVisible]);

  const onClick = useCallback(
    async (param: MenuInfo) => {
      if (param.key !== "all") {
        await sidebar.actions.navigate.dispatch(param.key as string);
        setIsActive(false);
      }
    },
    [setIsActive, sidebar.actions.navigate]
  );

  const onClickPanelItem = useMemo(
    () =>
      memoize((routeType: string) => {
        return async () => {
          if (routeType !== "all") {
            await sidebar.actions.navigate.dispatch(routeType);
            setIsActive(false);
          }
        };
      }),
    [setIsActive, sidebar.actions.navigate]
  );

  const closePopMenu = useCallback(() => {
    if (isVisible) {
      sidebar.actions.setVisible.dispatch(false);
    }
  }, [isVisible, sidebar.actions.setVisible]);
  const appMenus = useMemo(() => appMenuDefinitions, []);

  const hasFeature = useCallback(
    (menuItem: MenuDefinition) => {
      if (!menuItem.routeType) {
        return true;
      }
      if (menuItem.routeType === "market" && environmentEdition === "Private") {
        return false;
      }
      const feature = appRouteDefinitions[menuItem.routeType].showPermissions;
      if (!feature) {
        return true;
      }
      if (feature instanceof Array) {
        return feature.some(
          (item) => permissionShowTypeValues?.[item] !== "Hidden"
        );
      }
      return permissionShowTypeValues?.[feature] !== "Hidden";
    },
    [environmentEdition, permissionShowTypeValues]
  );

  const hasChildren = useCallback(
    (children?: MenuDefinition[]) => {
      if (!children) {
        return false;
      }

      return (
        children.filter(
          (item) => item.hiddenInMenu !== true && hasFeature(item)
        ).length > 0
      );
    },
    [hasFeature]
  );

  const renderSidebar = useMemo(() => {
    return memoize((menu: MenuDefinition) => {
      const cssIcon = css`
        margin-bottom: 1px;
        font-size: 14px;
        height: 14px;
        width: 14px;
      `;

      /**
       * @param {MenuDefinition | undefined} rightOne: children中第一个有权限的路由地址元素
       */
      const rightOne = menu?.children?.find(
        (val) => hasFeature(val) && val.routeType
      );

      if (hasChildren(menu.children) && rightOne) {
        return (
          <Menu.Item
            key={rightOne?.routeType ?? rightOne?.name}
            icon={
              menu.icon ? (
                <span role="img" className="anticon">
                  <img css={cssIcon} src={menu.icon} alt="icon_menu" />
                </span>
              ) : undefined
            }
            title={formatMessage(menu.name)}
          >
            {formatMessage(menu.name)}
          </Menu.Item>
        );
      } else if (hasFeature(menu) && menu.routeType) {
        return (
          <Menu.Item
            key={menu.routeType ?? menu.name}
            icon={
              menu.icon ? (
                <span role="img" className="anticon">
                  <img css={cssIcon} src={menu.icon} alt="icon_menu" />
                </span>
              ) : undefined
            }
            title={formatMessage(menu.name)}
          >
            {formatMessage(menu.name)}
          </Menu.Item>
        );
      } else {
        return null;
      }
    });
  }, [formatMessage, hasChildren, hasFeature]);

  const renderPanel = useMemo(() => {
    return memoize((menu: MenuDefinition) => {
      /**
       * @param {MenuDefinition | undefined} rightOne: children中第一个有权限的路由地址元素
       */
      const rightOne = menu?.children?.find(
        (val) => hasFeature(val) && val.routeType
      );
      if (hasChildren(menu.children) && rightOne) {
        return (
          <div className="panel-item" key={menu?.routeType ?? menu?.name}>
            <span
              css={css`
                font-weight: 600;
                color: ${selectedKey === (menu?.routeType ?? menu?.name)
                  ? "#2563EB !important"
                  : "#1f2e4d"};
              `}
              key={menu?.routeType ?? menu?.name}
              title={formatMessage(menu.name)}
              // TODO: 目前跳入第一有权限子页面，未来接入服务主页
              onClick={onClickPanelItem(
                menu?.routeType ?? rightOne?.routeType ?? ""
              )}
            >
              {formatMessage(menu.name)}
            </span>
            {menu.children?.map((item) => {
              if (hasFeature(item) && item.routeType) {
                return (
                  <span
                    css={css`
                      padding-left: 26px !important;
                      font-weight: ${selectedKey ===
                      (item?.routeType ?? item?.name)
                        ? "600"
                        : "normal"};
                      color: ${selectedKey === (item?.routeType ?? item?.name)
                        ? "#2563EB !important"
                        : "#1f2e4d"};
                    `}
                    key={item?.routeType ?? item?.name}
                    title={formatMessage(item.name)}
                    onClick={onClickPanelItem(item?.routeType ?? item?.name)}
                  >
                    {formatMessage(item.name)}
                  </span>
                );
              } else {
                return null;
              }
            })}
          </div>
        );
      } else {
        return null;
      }
    });
  }, [formatMessage, hasChildren, hasFeature, onClickPanelItem, selectedKey]);

  // mark: 防抖开关Panel模式UX设计取消
  //   const showAllServiceMenu = useCallback(
  //     () => sidebar.actions.setAllServiceMenuVisible.dispatch(true),
  //     [sidebar.actions.setAllServiceMenuVisible]
  //   );

  //   const closeAllServiceMenu = useCallback(
  //     () => sidebar.actions.setAllServiceMenuVisible.dispatch(false),
  //     [sidebar.actions.setAllServiceMenuVisible]
  //   );

  //   const delayMenuDisappear = _.debounce(closeAllServiceMenu, 150);

  //   const { styles: popperStyles, attributes: popperAttributes } = usePopper(
  //     filterElementRef.current,
  //     elementRef.current,
  //     {
  //       placement: "right-start",
  //     }
  //   );

  return targetDom
    ? ReactDOM.createPortal(
        <React.Fragment>
          {isActive && (
            <div
              css={css`
                display: flex;
                flex-shrink: 0;
                min-width: 900px;
                min-height: 500px;
                width: 100%;
                height: calc(100vh - 50px);
                z-index: 997;
                position: absolute;
                top: 50px;
                user-select: none;
              `}
            >
              <aside
                css={css`
                  display: flex;
                  flex-direction: row;
                  height: 100%;

                  min-width: 220px;
                  transition: all 0.2s;
                  .ant-menu {
                    width: 220px;
                    background: #f4f5f9;
                    border: 1px solid #ebebeb;
                    border-top: 0;
                    border-bottom: 0;
                  }
                  .ant-menu-inline .ant-menu-item-selected::after {
                    border: none !important;
                  }
                  .ant-menu-inline .ant-menu-item-active {
                    color: #1f2e4d;
                  }
                `}
              >
                {/* --------------- Sidebar --------------- */}
                <Menu
                  mode="inline"
                  selectedKeys={selectedKeys}
                  onClick={onClick}
                  css={css`
                    flex-shrink: 0;
                    border: none;
                    color: #1f2e4d;
                    background-color: transparent;
                    overflow-x: hidden;
                    overflow-y: auto;
                    &::-webkit-scrollbar {
                      width: 5px;
                    }
                    .ant-menu {
                      color: #1f2e4d;
                      background: transparent;
                    }
                    .ant-menu-item {
                      height: 40px;
                      line-height: 40px !important;
                      padding-left: 16px !important;
                      margin-top: 0px;
                      margin-bottom: 0px !important;
                    }

                    .ant-menu-item-only-child {
                      padding-left: 54px !important;
                    }

                    .ant-menu-inline {
                      border: none;
                    }

                    .ant-menu-inline .ant-menu-item {
                      width: 100%;
                      margin: 0px auto;
                    }
                    .ant-menu:not(.ant-menu-horizontal)
                      .ant-menu-item-selected {
                      color: #1f2e4d;
                      background-color: rgba(235, 235, 235, 0.6) !important;
                    }
                    .ant-menu-item-selected {
                      color: #1f2e4d;
                      background-color: rgba(235, 235, 235, 0.6) !important;
                    }

                    .ant-menu-item-active {
                      background-color: lightgrey;
                      svg {
                        color: ${theme.bodyFrameBackground};
                      }
                    }
                    .ant-menu-item:active {
                      color: rgba(235, 235, 235, 0.6);
                      background-color: lightgrey;
                    }
                  `}
                >
                  <Menu.Item
                    key="all"
                    icon={
                      <span role="img" className="anticon">
                        <img
                          css={css`
                            margin-bottom: 1px;
                            font-size: 14px;
                            height: 14px;
                            width: 14px;
                          `}
                          src={iconAll}
                          alt="icon_menu_all"
                        />
                      </span>
                    }
                    css={css`
                      height: 48px !important;
                    `}
                    // onMouseOver={showAllServiceMenu}
                    // onMouseLeave={delayMenuDisappear}
                    onClick={toggleAllServiceMenu}
                  >
                    <div
                      css={css`
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                      `}
                    >
                      <span>{formatMessage(messageIds.common.all)}</span>
                      <img src={iconExpandAll} alt="icon_sidebar_expandAll" />
                    </div>
                  </Menu.Item>
                  <Menu.Divider />
                  {appMenus.map((item) => renderSidebar(item))}
                </Menu>
                {/* --------------- Panel --------------- */}
                <div
                  css={css`
                    z-index: 998;
                    flex-shrink: 0;
                    background: #ffffff;
                    width: ${isAllServiceMenuVisible ? "556px" : 0};
                    padding: 20px 0px;
                    padding-left: ${isAllServiceMenuVisible ? "20px;" : " 0px"};
                    padding-right: ${isAllServiceMenuVisible ? "20px;" : "0px"};
                    transition: padding-left ease-in-out 0.1s;
                    transition: padding-right ease-in-out 0.1s;
                    transition: width ease-in-out 0.1s;
                    display: flex;
                    flex-direction: column;

                    /* Card瀑布流样式组 */
                    .panel-container {
                      columns: 225px 2;
                      column-gap: 12px;
                      width: 100%;
                    }

                    .panel-item {
                      width: 240px;
                      span {
                        display: block;
                        line-height: 16px;
                        color: #1f2e4d;
                        font-size: 16px;
                        padding: 13px 16px;
                        cursor: pointer;
                        overflow: hidden;
                        white-space: nowrap;
                        :hover {
                          background-color: #d3d3d3;
                          transition: background-color 0.3s ease-in-out;
                        }
                      }
                      /* 非必须 */
                      margin-bottom: 12px;
                      border-radius: 2px;
                      background-color: #f8f9fa;
                      padding: 3px 0;
                      box-sizing: border-box;
                      break-inside: avoid;
                      display: inline-block;
                      /* 渐入动画 */
                      @keyframes item-fade-in {
                        0% {
                          opacity: 0;
                          transform: translateY(-50px);
                          transform: translateX(-100px);
                        }
                        100% {
                          opacity: 1;
                          transform: translateY(0px);
                          transform: translateX(0px);
                        }
                      }
                      animation: item-fade-in 0.2s ease-in 0s;
                    }

                    /* 将内容块重排为2列 */

                    .panel-container::before,
                    .panel-container::after {
                      content: "";
                      flex-basis: 100%;
                      width: 0;
                      order: 2;
                    }
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      line-height: 18px;
                      height: 18px;
                      width: 100%;
                      margin-bottom: 20px;
                      justify-content: space-between;
                      align-items: center;
                      span {
                        font-size: 18px;
                        font-weight: 600;
                        color: #1f2e4d;
                      }
                      opacity: ${isAllServiceMenuVisible ? 1 : 0};
                      transition: opacity 0.1s ease-in-out 0s;
                    `}
                  >
                    <span>{formatMessage(messageIds.common.all)}</span>
                    <img
                      src={iconClose}
                      alt="icon_close"
                      css={css`
                        cursor: pointer;
                      `}
                      onClick={toggleAllServiceMenu}
                    />
                  </div>
                  <div
                    className="panel-inner"
                    css={css`
                      height: 90vh;
                      overflow-y: auto;
                      width: 100%;
                    `}
                  >
                    {isAllServiceMenuVisible && (
                      <div
                        className="panel-container"
                        css={css``}
                        // onMouseOver={delayMenuDisappear.cancel}
                      >
                        {/* 首页 */}
                        <div className="panel-item">
                          <span
                            css={css`
                              font-weight: 600;
                              color: ${selectedKey === "index"
                                ? "#2563EB !important"
                                : "#1f2e4d"};
                            `}
                            key={"index"}
                            title={formatMessage(messageIds.common.home)}
                            onClick={onClickPanelItem("index")}
                          >
                            {formatMessage(messageIds.common.home)}
                          </span>
                        </div>

                        {appMenus.map((item, i) => renderPanel(item))}
                      </div>
                    )}
                  </div>
                </div>
              </aside>
              <div
                onClick={closePopMenu}
                css={css`
                  right: 0px;
                  height: 100%;
                  width: 100%;
                  background: rgba(0, 0, 0, 0.39);
                `}
              ></div>
            </div>
          )}
        </React.Fragment>,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        targetDom as any
      )
    : null;
});

export default React.memo(function AppPopMenu() {
  const themeSet = useThemeSet();

  return (
    <ThemeContext.Provider value={themeSet.sidebar}>
      <AppPopMenuInternal />
    </ThemeContext.Provider>
  );
});
