import { createModel } from "nyax";
import { TaskScheduler } from "src/models/workflowLayout";
import { EncooSort } from "src/models/_shared";
import { WorkflowLayoutTriggerEntityModel } from "src/store/models/entity/workflowLayoutTrigger/entity";
import { createUITableWithPermissionModel } from "src/store/models/ui/_shared";

export const WorkflowLayoutTriggerUIModel = createModel(
  class extends createUITableWithPermissionModel<TaskScheduler, EncooSort>({
    setItems: (getContainer, items) =>
      getContainer(WorkflowLayoutTriggerEntityModel).actions.setItems.dispatch(
        items
      ),
    getItems: (getContainer) =>
      getContainer(WorkflowLayoutTriggerEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(WorkflowLayoutTriggerEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public initialState() {
      return {
        ...super.initialState(),
        isCreateModalVisible: false,
      };
    }

    public reducers() {
      return {
        ...super.reducers(),
        setCreateModalVisible: (value: boolean) => {
          this.state.isCreateModalVisible = value;
        },
      };
    }

    public effects() {
      return {
        ...super.effects(),
        initialIterator: async (payload: { workflowLayoutId: string }) => {
          const { workflowLayoutId } = payload;
          this._initial({
            initialAction: (pageIndex: number, pageSize: number) =>
              this.dependencies.serviceClient.workflowLayout.triggerList(
                workflowLayoutId,
                pageIndex,
                pageSize
              ),
          });
        },
      };
    }
  }
);
