import { createModel } from "nyax";
import { EncooFileUploadState } from "src/models/file";
import {
  CreateKnowledge,
  Knowledge,
  KnowledgeQuery,
} from "src/models/knowledge";
import { filterNonNullish } from "src/shared/array";
import { KnowledgeEntityModel } from "src/store/models/entity/knowledge/entity";
import { createUITableWithPermissionModel } from "src/store/models/ui/_shared";

export type KnowledgeWithPermission = Knowledge & {
  permissionValues: string[];
};

export const KnowledgeTableUIModel = createModel(
  class extends createUITableWithPermissionModel<Knowledge, KnowledgeQuery>({
    setItems: (getContainer, items) =>
      getContainer(KnowledgeEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(KnowledgeEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(KnowledgeEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public initialState() {
      return {
        ...super.initialState(),
        editorVisible: false,
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setEditorVisible: (visible: boolean) => {
          this.state.editorVisible = visible;
        },
      };
    }
    public effects() {
      return {
        ...super.effects(),
        initial: async () => {
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query: KnowledgeQuery
            ) =>
              this.dependencies.serviceClient.knowledge.list(
                pageIndex,
                pageSize,
                query
              ),
          });
        },
        uploadFile: async (payload: { knowledgeId: string; file: File }) => {
          await this.dependencies.serviceClient.knowledge.uploadFile({
            id: payload.knowledgeId,
            fileName: payload.file.name,
            fileData: payload.file,
            overwrite: true,
          });
        },
        updateKnowledge: async (payload: Partial<Knowledge>) => {
          await this.dependencies.serviceClient.knowledge.update({
            id: payload.id ?? "",
            description: payload.description,
            files: payload.files ?? [],
          });
        },
        createKnowledge: async (
          payload: CreateKnowledge & {
            uploadFiles: { id: string; data: File }[];
            uploadCallback?: (id: string, status: EncooFileUploadState) => void;
          }
        ) => {
          const knowledge =
            await this.dependencies.serviceClient.knowledge.create({
              name: payload.name,
              description: payload.description,
            });
          const uploadList = filterNonNullish(
            await Promise.all(
              payload.uploadFiles.map(async (item) => {
                payload?.uploadCallback?.(
                  item.id,
                  EncooFileUploadState["Uploading"]
                );
                try {
                  await this.actions.uploadFile.dispatch({
                    knowledgeId: knowledge.id,
                    file: item.data,
                  });
                  payload?.uploadCallback?.(
                    item.id,
                    EncooFileUploadState["Completed"]
                  );
                  return item;
                } catch (_err) {
                  payload?.uploadCallback?.(
                    item.id,
                    EncooFileUploadState["Failed"]
                  );
                }
              })
            )
          );
          if (uploadList.length) {
            await this.actions.updateKnowledge.dispatch({
              id: knowledge.id,
              description: knowledge.description,
              files: uploadList.map((item) => {
                return {
                  knowledgeBaseId: knowledge.id,
                  fileSize: item?.data.size ?? 0,
                  fileName: item?.data.name ?? "",
                  fileLastModifiedAt: new Date(
                    item?.data.lastModified
                  ).toISOString(),
                };
              }),
            });
          }
          await this.actions.refresh.dispatch({});
        },
        deleteKnowledge: async (knowledgeId: string) => {
          await this.dependencies.serviceClient.knowledge.delete(knowledgeId);
          await this.actions.refresh.dispatch({});
        },
      };
    }
  }
);
