import { createModel } from "nyax";
import { AppQuery, AppV2 } from "src/models/appV2";
import { AppEntityModel } from "src/store/models/entity/apps/entity";
import { createUIListV2Model } from "src/store/models/ui/_shared";

export const MyAppListModel = createModel(
  class extends createUIListV2Model<AppV2>({
    setItems: (getContainer, items) =>
      getContainer(AppEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(AppEntityModel).getters.items,
    getItem: (getContainer, id) => getContainer(AppEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initial: async (payload: AppQuery) => {
          this._initial({
            initialAction: async (pageIndex: number, pageSize: number) => {
              if (window._settings.appsTagCategories) {
                const list =
                  await this.dependencies.serviceClient.appsV2.getAll({
                    ...payload,
                    ...this.state.query,
                  });
                return { count: list.length ?? 0, list };
              } else {
                return this.dependencies.serviceClient.appsV2.list({
                  ...payload,
                  ...this.state.query,
                  pageIndex,
                  pageSize,
                });
              }
            },
          });
        },
      };
    }
  }
);
