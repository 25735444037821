// eslint-disable-next-line import/no-anonymous-default-export
export default {
  queue: {
    queue: "Group",
    name: "Group Name",
    introduction:
      "The Robot Group is mainly used to bind robot clusters and dynamically allocate the tasks triggered into the group to idle robots for operation.",
    tasksPerformed: "Number of Executed Tasks",
    remainingTasks: "Number of Remaining Tasks",
    robotCount: "Number of Robots",
    workflowCount: "Number of Associated Flows",
    bindRobot: "Add Robot",
    deleteTip:
      "All the remaining tasks in the group will be terminated after deletion.",
  },
};
