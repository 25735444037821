import { createModel } from "nyax";
import { PackageEntityModel } from "src/store/models/entity/package/entity";
import { PackageTableUIModel } from "src/store/models/ui/package/packageTable";
import { ShareSettingsBaseUIModel } from "src/store/models/ui/shareSettings/_shared";

export const PackageShareSettingsUIModel = createModel(
  class extends ShareSettingsBaseUIModel {
    public selectors() {
      return {
        ...super.selectors(),
        belongDepartmentId: () =>
          this.getContainer(PackageEntityModel).state.byId[this._id]
            ?.departmentId ?? null,
      };
    }

    public effects() {
      return {
        requestSharedToDepartmentIds: async () => {
          const departmentIds =
            await this.dependencies.serviceClient.package.getPackageShareToDepartments(
              this._id
            );
          await this.actions.setSharedToDepartmentIds.dispatch(departmentIds);
        },
        updateSharedToDepartmentIds: async (
          sharedToDepartmentIds: string[]
        ) => {
          await this.dependencies.serviceClient.package.updatePackageShareToDepartments(
            this._id,
            sharedToDepartmentIds
          );
          await this.actions.setSharedToDepartmentIds.dispatch(
            sharedToDepartmentIds
          );
          await this.getContainer(PackageTableUIModel).actions.refresh.dispatch(
            {}
          );
        },
      };
    }
  },
  { isDynamic: true, isLazy: true }
);
