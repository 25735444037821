import { createModel } from "nyax";
import {
  Workflow,
  WorkflowBasicData,
  WorkflowCreationData,
  WorkflowUpdateData,
} from "src/models/workflow";
import { createHelperModel } from "src/store/models/entity/_shared";
import { DataPermissionHelperModel } from "src/store/models/entity/dataPermission/helper";
import { WorkflowEntityModel } from "src/store/models/entity/workflow/entity";

export const WorkflowHelperModel = createModel(
  class extends createHelperModel<Workflow>({
    setItems: (getContainer, items) =>
      getContainer(WorkflowEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(WorkflowEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(WorkflowEntityModel).state.byId[id],
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(WorkflowEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),

        readByGroupIds: async (payload: {
          groupIds: string[];
          force?: boolean;
        }) => {
          const { groupIds, force } = payload;
          return await this._readByParentIds({
            parentIds: groupIds,
            getAllAction: () => {
              return this.dependencies.serviceClient.workflow.getAll();
            },
            getEntityParentId: (entity: Workflow) => entity.departmentId,
            force,
          });
        },
        readByPackageId: async (payload: {
          packageIds: string[];
          includeExecutionTarget?: boolean;
          force?: boolean;
        }) => {
          const { packageIds, force, includeExecutionTarget } = payload;
          return await this._readByParentIds({
            parentIds: packageIds,
            getAllAction: (parentId) => {
              return this.dependencies.serviceClient.workflow.getAllByPackageId(
                parentId,
                includeExecutionTarget
              );
            },
            getEntityParentId: (entity: Workflow) => entity.packageId,
            force,
          });
        },
        getById: async (payload: { workflowId: string; force?: boolean }) => {
          const { workflowId, force } = payload;
          return await this._readById({
            id: workflowId,
            getByIdAction: async () => {
              const item =
                await this.dependencies.serviceClient.workflow.getById(
                  workflowId
                );
              await this.getContainer(
                DataPermissionHelperModel
              ).actions.getAll.dispatch([
                { resourceType: item.resourceType, id: item.id },
              ]);
              return item;
            },
            force,
          });
        },
        create: async (payload: { params: WorkflowCreationData }) => {
          const { params } = payload;
          return await this._create({
            createAction: () =>
              this.dependencies.serviceClient.workflow.create(params),
          });
        },
        update: async (params: {
          workflowId: string;
          payload: WorkflowUpdateData;
        }) => {
          const { workflowId, payload } = params;
          return await this._update({
            id: workflowId,
            updateAction: () =>
              this.dependencies.serviceClient.workflow.update(
                workflowId,
                payload
              ),
          });
        },
        batchUpdateWorkflow: async (payload: {
          workflowIds: Array<string>;
          basicData: WorkflowBasicData;
        }) => {
          const { workflowIds, basicData } = payload;
          return await this.dependencies.serviceClient.workflow.batchUpdateWorkflow(
            workflowIds,
            basicData
          );
        },
        batchUpgradeWorkflows: async (payload: {
          workflowIds: Array<string>;
        }) => {
          const { workflowIds } = payload;
          return await this.dependencies.serviceClient.workflow.batchUpgradeWorkflows(
            workflowIds
          );
        },
        batchExecuteWorkflows: async (payload: {
          workflowIds: Array<string>;
        }) => {
          const { workflowIds } = payload;
          return await this.dependencies.serviceClient.workflow.batchExecuteWorkflows(
            workflowIds
          );
        },
        deleteBatch: async (payload: { workflowIds: string[] }) => {
          const { workflowIds } = payload;
          return await this.dependencies.serviceClient.workflow.batchDelete(
            workflowIds
          );
        },
        delete: async (payload: { workflowId: string }) => {
          const { workflowId } = payload;
          return await this._delete({
            id: workflowId,
            deleteAction: () =>
              this.dependencies.serviceClient.workflow.delete(workflowId),
          });
        },
        batchBindRobot: async (params: {
          workflowId: string;
          payload: {
            robotIds: Array<string>;
          };
        }) => {
          const { workflowId, payload } = params;
          const associtedIds =
            await this.dependencies.serviceClient.workflow.batchBindRobot(
              workflowId,
              payload
            );
          return associtedIds;
        },
        batchUpdateBindedTarget: async (params: {
          workflowId: string;
          payload:
            | {
                queueId: string | null | undefined;
              }
            | {
                robotIds: Array<string> | null | undefined;
              };
        }) => {
          const { workflowId, payload } = params;
          const associtedIds =
            await this.dependencies.serviceClient.workflow.batchUpdateBindedTarget(
              workflowId,
              payload
            );
          return associtedIds;
        },
        getRobots: async (payload: { workflowId: string }) => {
          const { workflowId } = payload;
          const associtedRobots =
            await this.dependencies.serviceClient.workflow.getAllRobots(
              workflowId
            );
          return associtedRobots;
        },
        unbindRobot: async (params: {
          workflowId: string;
          robotId: string;
        }) => {
          const { workflowId, robotId } = params;
          const isUnbind =
            await this.dependencies.serviceClient.workflow.undinbRobot(
              workflowId,
              robotId
            );
          return isUnbind;
        },
      };
    }
  }
);
