import {
  CreateRestfulConnectionTemplateData,
  DataConnectionTemplateInfos,
  RestfulConnectFunctionPayload,
  RestfulConnectorFunction,
} from "@encoo-web/encoo-ui";
import Axios from "axios";
import { createModel } from "nyax";
import { ViCodeApp } from "src/models/vicodeApp";
import { ModelBase } from "src/store/ModelBase";
import { DataConnectionHelperModel } from "src/store/models/entity/dataConnection/helper";
import { ViCodeAppHelperModel } from "src/store/models/entity/vicodeApp/helper";
import { GlobalSelectDepartmentModel } from "src/store/models/logic/globalSelectDepartment";

export type DataConnectionModalType = "create";

export const DataConnectionUiModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        modalType: null as DataConnectionModalType | null,
        editStatus: null as string | null,
        dataConnectionTemplateInfos: null as DataConnectionTemplateInfos | null,
        functionVariableList: [] as RestfulConnectorFunction[],
        apps: null as ViCodeApp[] | null,
      };
    }

    public reducers() {
      return {
        ...super.reducers(),
        setModalType: (value: DataConnectionModalType | null) => {
          this.state.modalType = value;
        },
        setDataConnectionTemplates: (
          value: DataConnectionTemplateInfos | null
        ) => {
          this.state.dataConnectionTemplateInfos = value;
        },
        setFunctionVariableList: (value: RestfulConnectorFunction[]) => {
          this.state.functionVariableList = value;
        },
        setEditStatus: (value: string | null) => {
          this.state.editStatus = value;
        },
        setApps: async (value: ViCodeApp[] | null) => {
          this.state.apps = value;
        },
      };
    }

    public effects() {
      return {
        fetchTemplates: async () => {
          const templates = await this.getContainer(
            DataConnectionHelperModel
          ).actions.getTemplates.dispatch(null);
          await this.actions.setDataConnectionTemplates.dispatch(
            templates ?? null
          );
        },
        getFunctionVariableList: async (
          payload: RestfulConnectFunctionPayload
        ) => {
          const data = await this.getContainer(
            DataConnectionHelperModel
          ).actions.getFunctionVaribleList.dispatch(payload);
          if (data) {
            await this.actions.setFunctionVariableList.dispatch(
              data.functionList
            );
            return data.functionList;
          }
        },
        createRestfulTemplate: async (payload: {
          createData: CreateRestfulConnectionTemplateData;
          image: File;
        }) => {
          const { createData, image } = payload;
          const data = await this.getContainer(
            DataConnectionHelperModel
          ).actions.createDataConnectionTemplate.dispatch(createData);
          const { headers, uri } = data.uploadSasUri;
          delete Axios.defaults.headers.put["Content-Type"];
          await Axios({
            method: "PUT",
            headers,
            url: uri,
            data: image,
          });
          await this.actions.fetchTemplates.dispatch(null);
        },
        deleteCustomTemplate: async (id: string) => {
          await await this.getContainer(
            DataConnectionHelperModel
          ).actions.deleteCustonTemplate.dispatch(id);
          await this.actions.fetchTemplates.dispatch(null);
        },
        updateEditStatus: async (action: string | null) => {
          await this.actions.setEditStatus.dispatch(action);
        },
        initialEditStatus: async (key: string | number | null) => {
          if (key) {
            const val = key?.toString();
            await this.actions.setEditStatus.dispatch(val);
          } else {
            await this.actions.setEditStatus.dispatch(null);
          }
        },
        getAsset: async () => {
          const globalSelectDepartmentContainer = this.getContainer(
            GlobalSelectDepartmentModel
          );

          let pageIndex = 0;
          const pageSize = 1000;

          const results = [];

          // eslint-disable-next-line no-constant-condition
          while (true) {
            const body = await this.dependencies.serviceClient.assetV2.list(
              globalSelectDepartmentContainer.state.selectedDepartmentId ?? "",
              pageIndex,
              pageSize,
              { valueType: "String" }
            );

            results.push(...(body.list ?? []));

            if ((pageIndex + 1) * pageSize >= (body.count ?? 0)) {
              break;
            } else {
              pageIndex += 1;
            }
          }

          return results.map((item) => ({
            label: item.name ?? "",
            value: item.id,
          }));
        },
        initialApps: async (payload: { connectionId: string }) => {
          const { connectionId } = payload;

          const apps = await this.getContainer(
            ViCodeAppHelperModel
          ).actions.readByConnectionId.dispatch({ connectionId });
          await this.actions.setApps.dispatch(apps);
        },
      };
    }
  }
);
