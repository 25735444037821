// eslint-disable-next-line import/no-anonymous-default-export
export default {
  workflowLayout: {
    title: "流程编排",
    name: "流程编排名称",
    introduction: "流程编排可自动执行多个流程部署，支持顺序调整和定时开始。",
    lastExecuteTime: "上次执行时间",
    operations: {
      viewLayouts: "查看编排",
      scheduledTask: "定时任务",
    },
    nodeErrorHandlingModel: {
      whenNodeError: "当遇到流程节点失败或取消时",
      operations: {
        continue: "继续执行后续流程节点",
        break: "终止流程编排",
      },
    },
    copySourceTarget: "复制来源: {sourceName}",
    dragAndDrop: "请从左侧流程部署列表中选中一项，拖拽到此处",
    saveConfirmDialog: {
      contentText: "您是否要保存修改内容？",
      tips: "点击”取消“，将不为您保存修改内容。",
    },
    workflowLayoutNodesEditor: {
      name: "编辑编排",
    },
    infoExecute: "确定批量执行所选流程编排？",
  },
};
