import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { PaymentOrder } from "src/models/payment";

export const PAYMENTORDER_SERVICE_NAME = "PaymentOrderService";
export class PaymentOrderClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, PAYMENTORDER_SERVICE_NAME);
  }

  async list(): Promise<PaymentOrder[]> {
    const req = this.buildRequestV2({
      url: `/payment/orders`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getById(orderId: string): Promise<PaymentOrder> {
    const req = this.buildRequestV2({
      url: `/payment/orders/${orderId}`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
  async create(
    items: { productId: string; quantity: number }[],
    renewAgainstOrderId?: string,
    couponCode?: string
  ): Promise<PaymentOrder> {
    const req = this.buildRequestV2({
      url: `/payment/orders`,
      method: "POST",
      payload: {
        items: items,
        renewAgainstOrderId,
        couponCode,
        appName: "Console",
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
  async cancel(orderId: string): Promise<PaymentOrder> {
    const req = this.buildRequestV2({
      url: `/payment/orders/${orderId}/cancel`,
      method: "PATCH",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
}
