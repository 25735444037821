// eslint-disable-next-line import/no-anonymous-default-export
export default {
  virtualMFA: {
    title: "动态令牌设置",
    subTitle: "绑定动态令牌后，您可以在登录时通过它来进行二次校验。",
    bindingInfo: {
      title: "绑定情况:",
      isBinding: "已绑定",
      notBinding: "未绑定",
    },
    bind: "去绑定",
    unbind: "去解绑",
    bindingTips: {
      title: "绑定前请在手机端下载并安装Google Authenticator/阿里云APP。",
      iphone: "在App Store搜索Google Authenticator/阿里云APP。",
      android: "在应用市场搜索Google Authenticator/阿里云APP。",
      subTitle: "安装完成后点击下一步进入绑定页面(如已安装，直接进入下一步)。",
      bindingSuccess: "您已经成功绑定安全设备",
    },
    unbindTips:
      "解绑需要使用Google Authenticator/阿里云APP扫描二维码进行验证。",
    MFAconfig: {
      modalTitle: "动态令牌设置",
      step1: "安装应用",
      step2: "绑定动态令牌",
      useGoogle:
        "使用Google Authenticator/阿里云APP扫描以下二维码，获取6位验证码。",
      sweepCodeFailure: "扫码失败？",
      bindSuccessTitle: "您已成功绑定安全设备",
    },

    form: {
      code: {
        name: "请输入验证码",
        pleaseholder: "请输入6位数",
        validation: {
          notEmpty: "验证码不能为空",
        },
      },
    },
    canningFailurePrompt: {
      title: "请在Google Authenticator中手动输入以下二维码，获取6位验证码",
      name: "用户名：",
      secretKey: "秘钥：",
    },
    unbindContainer: {
      title: "账号保护已开启，请按照以下提示进行解绑。",
      subTitle:
        "请在手机中打开阿里云APP或Google Authenticator，请输入6位动态码。",
      unbindsuccessTitle: "您已成功解绑安全设备",
      successTip:
        "请勿随意删除卸载APP，否则会导致阿里云账号无法登录。如需卸载APP或者更换手机，请先解绑动态令牌。",
    },
  },
};
