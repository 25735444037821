import { createModel, createSelector } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { GlobalSelectDepartmentModel } from "src/store/models/logic/globalSelectDepartment";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";

export const CurrentFeatureModel = createModel(
  class extends ModelBase {
    private get _loginUserUIContainer() {
      return this.getContainer(LoginUserUIModel);
    }

    private get _globalSelectDepartmentContainer() {
      return this.getContainer(GlobalSelectDepartmentModel);
    }

    public initialState() {
      return {
        features: [
          "SystemSetting_Read",
          "SystemSetting_Update",
          "CompanyUser_Read",
          "CompanyUser_Create",
          "CompanyUser_Delete",
          "CompanyUser_Update",
          "CompanyUser_EnableDisable",
          "CompanyUser_EditRole",
          "Role_Read",
          "Role_Create",
          "Role_Delete",
          "Role_Update",
          "Department_View",
          "Department_Create",
          "Department_Delete",
          "Department_Update",
          "Department_Manage",
          "License_View",
          "License_Import",
          "License_AssignAndRemove",
          "Audit_View",
          "Robot_Read",
          "Robot_Create",
          "Robot_Update",
          "Robot_Delete",
          "Robot_Manage",
          "Robot_AssignLicense",
          "Package_Read",
          "Package_Create",
          "Package_Update",
          "Package_Delete",
          "Package_Manage",
          "App_Read",
          "App_Share",
          "App_Create",
          "App_Update",
          "App_Delete",
          "App_Manage",
          "App_Copy",
          "DataSource_Read",
          "DataSource_Create",
          "DataSource_Update",
          "DataSource_Delete",
          "DataSource_Manage",
          "Asset_Read",
          "Asset_Create",
          "Asset_Update",
          "Asset_Delete",
          "Asset_Manage",
          "DataConnection_Read",
          "DataConnection_Create",
          "DataConnection_Update",
          "DataConnection_Delete",
          "DataConnection_Manage",
          "DataConnection_Run",
        ] as Array<string>,
      };
    }

    public selectors() {
      return {
        features: createSelector(() => this.state.features),
      };
    }

    public reducers() {
      return {};
    }

    public effects() {
      return {};
    }
  }
);
