import moment from "moment";
import { createModel, createSelector } from "nyax";
import {
  CalendarsCreationData,
  CalendarsUpdateData,
} from "src/models/calendars";
import { ModelBase } from "src/store/ModelBase";
import { CalendarsWithPermission } from "./calendarsTable";

export const CalendarsCreateDialogUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        currentRecord: null as CalendarsWithPermission | null,
        selectedDate: [] as string[],
        calendarMode: "month" as "year" | "month",
        createDialogVisible: false,
        isEdit: false,
        calendarOptions: [] as {
          label: string;
          value: string;
        }[],
        requestPayload: null as {
          id?: string;
          params: CalendarsCreationData | CalendarsUpdateData;
        } | null,
      };
    }
    public reducers() {
      return {
        setCurrentRecord: (value: CalendarsWithPermission | null) => {
          this.state.currentRecord = value;
        },
        setSelectedDate: (value: string[]) => {
          this.state.selectedDate = value;
        },
        setCalendarMode: (value: "year" | "month") => {
          this.state.calendarMode = value;
        },
        setCreateDialogVisible: (value: boolean) => {
          this.state.createDialogVisible = value;
        },
        setIsEdit: (value: boolean) => {
          this.state.isEdit = value;
        },
        setCalendarOptions: (
          value: {
            label: string;
            value: string;
          }[]
        ) => {
          this.state.calendarOptions = value;
        },
        setRequestPayload: (value: typeof this.state.requestPayload) => {
          this.state.requestPayload = value;
        },
      };
    }
    public selectors() {
      return {
        selectedDateSorted: createSelector(
          () => this.state.selectedDate,
          (arr) => {
            const newArr = [...arr];
            return newArr.sort((a, b) => {
              return (
                moment(a).startOf("day").valueOf() -
                moment(b).startOf("day").valueOf()
              );
            });
          }
        ),
      };
    }
    public effects() {
      const superEffects = super.effects();
      return {
        ...superEffects,

        initial: async () => {
          this.actions.setCurrentRecord.dispatch(null);
          this.actions.setSelectedDate.dispatch([]);
          this.actions.setCalendarMode.dispatch("month");
          this.actions.setCreateDialogVisible.dispatch(false);
          this.actions.setIsEdit.dispatch(false);
          this.actions.setRequestPayload.dispatch(null);
        },
        requestListOptions: async (payload: { departmentId: string }) => {
          const { departmentId } = payload;
          const list = (
            await this.dependencies.serviceClient.calendars.list(
              departmentId,
              0,
              1000
            )
          ).list;
          const options = list?.map((v) => {
            return { label: v?.name, value: v?.id };
          });
          this.actions.setCalendarOptions.dispatch(options);
        },
      };
    }
  }
);
