import { DeviceDetailUIModel } from "src/store/models/ui/device/deviceDetail";
import { DeviceRobotTableUIModel } from "src/store/models/ui/device/deviceRobotTable";
import { DeviceSideUIModel } from "src/store/models/ui/device/deviceSide";
import { DeviceTableUIModel } from "src/store/models/ui/device/deviceTable";

export const encooDeviceUIModels = {
  device: DeviceTableUIModel,
  deviceSide: DeviceSideUIModel,
  deviceDetail: DeviceDetailUIModel,
  deviceRobots: DeviceRobotTableUIModel,
};
