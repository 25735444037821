/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { UserName } from "@encoo-web/encoo-ui";
import { Dropdown, Tooltip } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import head from "src/assets/head_HAP.svg";
import IconExpanded from "src/assets/icon_expanded.svg";
import CompanyComponent from "src/containers/layout/components/CompanyComponent";
import {
  useFormatMessage,
  useGetContainer,
  useTheme,
  useUserEdition,
} from "src/hooks";
import messageIds from "src/locales/messageIds";
import { filterNonNullish } from "src/shared/array";
import { GlobalSelectCompanyUIModel } from "src/store/models/logic/globalSelectCompany";
import { PopMenuModel } from "src/store/models/popMenu";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";
import {
  UserCenterViewType,
  UserInfoUIModel,
} from "src/store/models/ui/userCenter/userInfo";

export interface MenuType {
  type: UserCenterViewType;
  text: string;
  icon: string;
}

export default React.memo(function LoginUserComponnet(props: {
  showUserCenterMenu?: boolean;
}) {
  const formatMessage = useFormatMessage();
  const getContainer = useGetContainer();
  const theme = useTheme();
  const loginUserUIModel = getContainer(LoginUserUIModel);
  const sidebar = getContainer(PopMenuModel);
  const userInfo = getContainer(UserInfoUIModel);
  const userEdition = useUserEdition();
  const currentUserIconInfo = useSelector(
    () => userInfo.state.currentUserIconInfo
  );

  const loggedinUser = useSelector(
    () => loginUserUIModel.state.basicUserProfile
  );
  const user = useSelector(() => loginUserUIModel.state.currentCompanyUser);
  const { showUserCenterMenu = true } = props;
  const loginOut = useCallback(() => {
    loginUserUIModel.actions.logout.dispatch({});
    sessionStorage.removeItem("notificationStatus");
  }, [loginUserUIModel.actions.logout]);
  const globalSelectCompanyUIModel = getContainer(GlobalSelectCompanyUIModel);
  const companyFrom = useSelector(
    () => globalSelectCompanyUIModel.getters.companyFrom
  );

  const userMenu = useMemo(() => {
    const basicMenus: MenuType[] = filterNonNullish([
      {
        type: "userInfo" as const,
        text: formatMessage(messageIds.userCenter.userInfo),
        icon: "icon-userInfo",
      },
      {
        type: "settings" as const,
        text: formatMessage(messageIds.userCenter.settings),
        icon: "icon-userSettings",
      },
      userEdition === "Community"
        ? {
            type: "orders" as const,
            text: formatMessage(messageIds.userCenter.orders),
            icon: "icon-order",
          }
        : null,
    ]);

    return basicMenus;
  }, [formatMessage, userEdition]);

  const navigationTo = useCallback(
    (type: UserCenterViewType) => {
      sidebar.actions.navigate.dispatch("userCenter");
      userInfo.actions.setViewType.dispatch(type);
    },
    [sidebar.actions.navigate, userInfo.actions.setViewType]
  );

  const closeMenu = useCallback(() => {
    setIsMenuOpening(false);
  }, []);

  const menu = React.useMemo(
    () => (
      <div
        onClick={closeMenu}
        css={css`
          position: absolute;
          user-select: none;
          cursor: pointer;
          top: -4px;
          right: -20px;
          width: 260px;
          background: ${theme.bodyBackground};
          box-shadow: 0px 2px 10px 4px rgba(143, 155, 179, 0.2);
          border-radius: 2px;
        `}
      >
        <div
          css={css`
            background: #f4f5f9;
            padding: 22px 0px 1px 37px;
          `}
        >
          <p
            css={css`
              margin-bottom: 6px;
              font-size: 14px;
              color: ${theme.bodyText};
              font-weight: 600;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            `}
          >
            <Tooltip placement="bottom" title={user?.name}>
              <UserName openid={user?.name ?? ""} companyFrom={companyFrom} />
            </Tooltip>
          </p>
          <p
            css={css`
              margin-bottom: 10px;
              font-size: 12px;
              color: ${theme.bodySubtext};
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              cursor: pointer;
            `}
          >
            <Tooltip placement="bottom" title={user?.email}>
              {user?.email}
            </Tooltip>
          </p>
        </div>
        {userEdition !== "Community" && <CompanyComponent />}
        {showUserCenterMenu &&
          userMenu.map((item) => (
            <p
              key={item.text}
              onClick={() => navigationTo(item.type)}
              css={css`
                margin-bottom: 0;
                padding: 0 37px;
                line-height: 42px;
                &:hover {
                  background-color: ${theme.lightPrimaryColor};
                }
              `}
            >
              {item.text}
            </p>
          ))}
        <p
          key={"logout"}
          onClick={loginOut}
          css={css`
            margin-bottom: 0;
            padding: 0 37px;
            line-height: 42px;
            &:hover {
              background-color: ${theme.lightPrimaryColor};
            }
          `}
        >
          {formatMessage(messageIds.user.logout)}
        </p>
      </div>
    ),
    [
      closeMenu,
      companyFrom,
      formatMessage,
      loginOut,
      navigationTo,
      showUserCenterMenu,
      theme.bodyBackground,
      theme.bodySubtext,
      theme.bodyText,
      theme.lightPrimaryColor,
      user?.email,
      user?.name,
      userEdition,
      userMenu,
    ]
  );

  const [isMenuOpening, setIsMenuOpening] = useState(false);

  const onVisibleChange = useCallback((visible: boolean) => {
    setIsMenuOpening(visible);
  }, []);

  const init = useCallback(async () => {
    if (loggedinUser?.id) {
      await userInfo.actions.getCurrentUserAvatar.dispatch(loggedinUser?.id);
    }
  }, [loggedinUser?.id, userInfo.actions.getCurrentUserAvatar]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      css={css`
        background-color: ${isMenuOpening ? "rgba(0,0,0,0.09)" : "none"};
        height: 100%;
      `}
      onVisibleChange={onVisibleChange}
    >
      <div
        css={css`
          padding-left: 15px;
          padding-right: 20px;
          cursor: pointer;
          display: flex;
          align-items: center;
          color: #ffffff;
        `}
      >
        <img
          alt="user-avatar"
          src={
            currentUserIconInfo?.url && currentUserIconInfo?.url.length > 0
              ? currentUserIconInfo?.url
              : head
          }
          css={css`
            border: 1px solid #ffffff;
            box-sizing: border-box;
            width: 28px;
            height: 28px;
            margin-right: 8px;
            border-radius: 50%;
          `}
        />
        <img
          src={IconExpanded}
          alt="icon-expanded"
          css={css`
            transform: rotate(${isMenuOpening ? "180deg" : "0deg"});
          `}
        />
      </div>
    </Dropdown>
  );
});
