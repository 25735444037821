import {
  Constants,
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import {
  CoeMyWork,
  CoeMyWorkCreationData,
  CoeMyWorkQuery,
} from "src/models/coeMyWork";
import { CoeWorkUploadUri, ProcessCorrelationData } from "src/models/coeWork";
import { CompanyUser } from "src/models/companyUser";
import { EncooListEntity } from "src/models/_shared";

export const COEMYWORK_SERVICE_NAME = "coeMyWork";
export class CoeWorkService extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, COEMYWORK_SERVICE_NAME);
  }

  async myList(
    departmentId: string,
    pageIndex: number,
    pageSize: number,
    query?: CoeMyWorkQuery
  ): Promise<EncooListEntity<CoeMyWork>> {
    const req = this.buildRequestV2({
      url: `/v2/workitems/myitems`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
        keyword: query?.keyword,
        state: query?.state,
        isDescendingSort: query?.isDescendingSort,
      },
      departmentId,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async toDoList(
    departmentId: string,
    pageIndex: number,
    pageSize: number,
    query?: CoeMyWorkQuery
  ): Promise<EncooListEntity<CoeMyWork>> {
    const req = this.buildRequestV2({
      url: `/v2/workitems`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
        keyword: query?.keyword,
        state: query?.state,
        isDescendingSort: query?.isDescendingSort,
      },
      departmentId,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getById(coeMyWorkId: string): Promise<CoeMyWork> {
    const req = this.buildRequestV2({
      url: `/v2/workitems/${coeMyWorkId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async create(payload: CoeMyWorkCreationData): Promise<CoeMyWork> {
    const req = this.buildRequestV2({
      url: `/v2/workitems`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  async update(
    coeMyWork: Partial<CoeMyWork> & Pick<CoeMyWork, "id">
  ): Promise<CoeMyWork> {
    const req = this.buildRequestV2({
      url: `/v2/workitems/${coeMyWork.id}`,
      method: "PATCH",
      payload: coeMyWork,
    });
    if (coeMyWork.eTag) {
      req.headers?.set(Constants.HeaderConstants.IF_MATCH, coeMyWork.eTag);
    }
    return await super.updateInternal(req);
  }
  async getPackages(
    pageIndex: number,
    pageSize?: number
  ): Promise<EncooListEntity<ProcessCorrelationData>> {
    const req = this.buildRequestV2({
      url: `/v2/CompanyPackages`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
  async uploadAttachment(payload: {
    name: string;
    size: number;
  }): Promise<CoeWorkUploadUri> {
    const req = this.buildRequestV2({
      url: `/v2/coeattachments`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  async dowloaduri(workItemId: string, attachmentId: string): Promise<string> {
    const req = this.buildRequestV2({
      url: `/v2/workitems/${workItemId}/attachments/${attachmentId}/dowloaduri`,
      method: "GET",
    });
    return await super.createInternal(req);
  }
  async managers(): Promise<CompanyUser[]> {
    const req = this.buildRequestV2({
      url: `/v2/coeusers/managers`,
      method: "GET",
    });
    return await super.createInternal(req);
  }
}
