import { createModel, createSelector } from "nyax";
import { UserTenantInfo } from "src/models/userInfo";
import { ModelBase } from "src/store/ModelBase";
import { RouterModel } from "src/store/models/router";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";

export type TenantMenuModalVisible = "createUserTenant" | null;
export const UserTenantUIModel = createModel(
  class extends ModelBase {
    private get _loginUserUIContainer() {
      return this.getContainer(LoginUserUIModel);
    }

    private get _router() {
      return this.getContainer(RouterModel);
    }

    public selectors() {
      return {
        defaultUserTenant: createSelector(
          () =>
            this._loginUserUIContainer.state.basicUserProfile?.defaultTenantId,
          () => this.state.userTenants,
          (defaultTenantId, userTenants): UserTenantInfo | null => {
            return (
              userTenants?.find((item) => item.tenantId === defaultTenantId) ??
              null
            );
          }
        ),
      };
    }

    public initialState() {
      return {
        tenantMenuModalVisible: null as TenantMenuModalVisible,
        userTenants: null as UserTenantInfo[] | null,
      };
    }

    public reducers() {
      return {
        ...super.reducers(),
        setTenantMenuModalVisible: (value: TenantMenuModalVisible) => {
          this.state.tenantMenuModalVisible = value;
        },
        setUserTenants: (value: UserTenantInfo[] | null) => {
          this.state.userTenants = value;
        },
      };
    }

    public effects() {
      return {
        setTenantHeaderValue: async (tenantId: string) => {
          this.dependencies.httpMiddleware.tenantHeader.setHeaderValue(
            tenantId
          );
        },

        switchUserTenant: async (paylod: {
          id: string;
          originPathname?: string;
        }) => {
          const { id, originPathname } = paylod;
          if (originPathname) {
            window.location.href = `${window._settings.consoleV3Url}${originPathname}?tenantId=${id}`;
          } else {
            window.location.href = `${window._settings.consoleV3Url}?tenantId=${id}`;
          }
        },
        getUserTenants: async () => {
          const list =
            await this.dependencies.serviceClient.company.getListV2();
          if (list) {
            await this.actions.setUserTenants.dispatch(list?.tenants);
          }
          return list;
        },
      };
    }
  }
);
