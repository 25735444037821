import { QueueUIModel } from "src/store/models/ui/queue/queue";
import { QueueDetailUIModel } from "src/store/models/ui/queue/queueDetail";
import { QueueDetailV2UIModel } from "src/store/models/ui/queue/queueDetailV2";
import { QueueJobUIModel } from "src/store/models/ui/queue/queueJob";
import { QueueSideUIModel } from "src/store/models/ui/queue/queueSide";
import { QueueTableUIModel } from "src/store/models/ui/queue/queueTable";

export const encooQueueUIModels = {
  queue: QueueUIModel,
  queueTable: QueueTableUIModel,
  queueSide: QueueSideUIModel,
  queueDetail: QueueDetailUIModel,
  queueDetailV2: QueueDetailV2UIModel,
  queueJob: QueueJobUIModel,
};
