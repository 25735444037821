import { createModel } from "nyax";
import { JobQueueMessage, JobQueueMessageQuery } from "src/models/jobQueue";
import { JobQueueMessageEntityModel } from "src/store/models/entity/jobQueueMessage/entity";
import { createUITableBaseModel } from "src/store/models/ui/_shared";

export const MessageTableUIModel = createModel(
  class extends createUITableBaseModel<JobQueueMessage, JobQueueMessageQuery>({
    setItems: (getContainer, items) =>
      getContainer(JobQueueMessageEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(JobQueueMessageEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(JobQueueMessageEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initial: async (payload: {
          departmentId: string;
          queueName: string;
        }) => {
          const { departmentId, queueName } = payload;
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query?: JobQueueMessageQuery
            ) =>
              this.dependencies.serviceClient.jobQueue.getMessages(
                departmentId,
                queueName,
                pageIndex,
                pageSize,
                query
              ),
          });
        },
      };
    }
  }
);
