import { createModel } from "nyax";
import {
  CoeDataAnalysisBasicConfig,
  CoeDataAnalysisPackageConfig,
  CoeDataAnalysisPackageConfigEncooList,
} from "src/models/coeDataAnalysisPackageConfig";
import { CoeDataAnalysisPackageConfigEntityModel } from "src/store/models/entity/coeDataAnalysisPackageConfig/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const CoeDataAnalysisPackageConfigHelperModel = createModel(
  class extends createHelperModel<CoeDataAnalysisPackageConfigEncooList>({
    setItems: (getContainer, items) =>
      getContainer(
        CoeDataAnalysisPackageConfigEntityModel
      ).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(CoeDataAnalysisPackageConfigEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(CoeDataAnalysisPackageConfigEntityModel).state.byId[id],
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () =>
          this.getContainer(CoeDataAnalysisPackageConfigEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        getBasicConfig: async () => {
          return await this.dependencies.serviceClient.coeDataAnalysisPackageConfig.getBasicConfig();
        },
        updateBasicConfig: async (payload: CoeDataAnalysisBasicConfig) => {
          return await this.dependencies.serviceClient.coeDataAnalysisPackageConfig.updateBasicConfig(
            payload
          );
        },
        batchUpdateWorkflow: async (
          payload: CoeDataAnalysisPackageConfig[]
        ) => {
          return await this.dependencies.serviceClient.coeDataAnalysisPackageConfig.batchUpdateWorkflowCost(
            payload
          );
        },
      };
    }
  }
);
