import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { EncooBatchResult, EncooListEntity } from "src/models/_shared";
import {
  Queue,
  QueueBasicData,
  QueueBindingInfo,
  QueueQuery,
} from "src/models/queue";
import { Robot } from "src/models/robot";

export const QUEUE_SERVICE_NAME = "queue";

export class QueueServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, QUEUE_SERVICE_NAME);
  }

  async list(
    pageIndex: number,
    pageSize: number,
    query?: QueueQuery
  ): Promise<EncooListEntity<Queue>> {
    const req = this.buildRequestV2({
      url: `/v2/queues`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
        searchString: query?.name,
        orderBy: query?.orderBy,
        isDesc: query?.sortOrder === "descend",
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getAll(): Promise<Array<Queue>> {
    const req = this.buildRequestV2({
      url: `/v2/queues`,
      method: "GET",
      query: {
        pageSize: 1000,
      },
    });
    const { body } = await this.sendRequest(req);
    return body.list;
  }

  async getById(queueId: string): Promise<Queue> {
    const req = this.buildRequestV2({
      url: `/v2/queues/${queueId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async create(payload: QueueBasicData): Promise<Queue> {
    const req = this.buildRequestV2({
      url: `/v2/queues`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  async update(queueId: string, payload: QueueBasicData): Promise<Queue> {
    const req = this.buildRequestV2({
      url: `/v2/queues/${queueId}`,
      method: "PATCH",
      payload,
    });
    return await super.updateInternal(req);
  }

  async delete(queueId: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/queues/${queueId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }
  async batchDelete(queueIds: string[]): Promise<EncooBatchResult> {
    const req = this.buildRequestV2({
      url: `/v2/queues/batchDelete`,
      method: "POST",
      payload: {
        ids: queueIds,
      },
    });
    return await super.updateInternal<EncooBatchResult>(req);
  }

  //#region Robot API from queue
  async getAllBindingInfos(queueId: string): Promise<Robot[]> {
    const req = this.buildRequestV2({
      url: `/v2/queues/${queueId}/robots`,
      method: "GET",
      query: {
        pageSize: 1000,
      },
    });
    const { body } = await this.sendRequest(req);
    return body.list;
  }

  async getBindingInfoById(
    queueId: string,
    robotId: string
  ): Promise<QueueBindingInfo> {
    const req = this.buildRequestV2({
      url: `/v2/queues/${queueId}/robots/${robotId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async bindRobot(
    queueId: string,
    payload: {
      robotIds: string[];
    }
  ): Promise<Robot> {
    const req = this.buildRequestV2({
      url: `/v2/queues/${queueId}/robots`,
      method: "PATCH",
      payload,
    });
    return await super.createInternal(req);
  }

  async unbindRobot(queueId: string, robotId: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/queues/${queueId}/robots/${robotId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }

  //#endregion
}
