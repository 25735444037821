// eslint-disable-next-line import/no-anonymous-default-export
export default {
  securityConfig: {
    menu: "多重身份验证配置",
    title: "多重身份验证配置",
    rpa: "RPA策略配置",
    isOpenSecurityValidation: "是否开启多重身份验证",
    securityValidationSubtext:
      "开启后将会在用户通过账户密码登录系统后进行二次验证以保证系统安全，验证方式为下列方式中的一种。",
    instructions: "说明",
    securityType: {
      type1: {
        text: "手机验证码验证",
        subText: "会向用户所绑定手机号发送登录验证码。",
      },
      type2: {
        text: "邮箱验证码验证",
        subText: "会向用户所绑定邮箱发送登录验证码。",
      },
      type3: {
        text: "动态令牌验证",
        subText:
          "需要用户在个人信息-动态令牌设置中配置,完成配置后可通过动态令牌验证码登录。",
      },
    },
    validationScope: {
      title: "验证范围",
      allUsers: "全体用户",
      pecifyUser: "指定用户",
    },
    privateEdition: {
      securityValidationSubtext:
        "开启后将会在用户通过账户密码登录系统后进行二次验证（动态令牌）以保证系统安全。",
      description:
        "说明：动态令牌验证需要用户在个人中心-动态令牌设置中配置，完成配置后可通过动态令牌验证码登录。",
    },
  },
};
