// eslint-disable-next-line import/no-anonymous-default-export
export default {
  organization: {
    description:
      "组织架构管理主要用于对公司中的部门、用户进行管理，同时可对相关部门、用户、机器人的各类权限进行控制。",
    sync: "同步",
    tabs: {
      user: "用户管理",
      dataPermission: "数据权限配置",
      functionPermission: "功能配置",
      departmentConfig: "部门信息配置",
      companyConfig: "公司信息配置",
    },

    functionPermission: {
      entityTypeName: "功能",
      affiliationDepartment: "所属部门",
      affiliationCompany: "所属公司",
      departmentPermissionConfig: "部门功能配置",
      departmentPermissionConfigTip: "请选择当前部门开放的功能",
    },
    company: {
      entityTypeName: "公司",
      companyName: "公司名称",
    },
    department: {
      entityTypeName: "部门",
      departmentName: "部门名称",
      lowerCaseDepartmentName: "部门名称",
      createSubDepartment: "创建子部门",
      departmentUserCount: "部门人数",
    },
    departmentUsers: {
      details: "用户详情",
      inviteUser: "邀请用户",
      userDepartment: "所属部门",
      userRole: "所属角色",
      role: "角色",
      currDepartmentRole: "当前部门角色",
      edit: "用户信息编辑",
      departmentchange: "转移部门",
      disable: "停用",
      enable: "启用",
      delete: "删除",
      userEmail: "用户邮箱",
      userName: "用户姓名",
      cellphoneUser: "手机用户/无邮箱",
      userInfo: "用户信息",
      email: "邮箱",
      description: "备注",
      changeDepartmentTip: "将用户{name}转移到：",
      lastLoginAt: "上次登录时间",
      saveAndContinue: "保存并继续添加",
      changeRoleTitle: "更改角色",
      changeRoleLabel: "请选择更改的角色",
      roleChange: "更改角色",
      private: {
        createUser: "创建用户",
        placeholder: {
          userName: "请填写用户在当前公司内的名称",
          email: "请输入需要创建的账号，请使用邮箱",
          password: "请输入密码",
        },
        tip: "提示：创建完成后请将账号及密码发给用户，用户将首次登陆修改密码后被激活。",
      },
    },
    noMatch: "无匹配结果",
  },
};
