import { createModel } from "nyax";
import { ViCodeAppStatus } from "src/models/vicodeApp";
import { createViCodeAppListBaseModal } from "src/store/models/entity/vicodeApp/_shared";
import { ViCodeAppQuery } from "src/store/models/ui/vicodeApp/_shared";

export const ViCodeAppLiveListModel = createModel(
  class extends createViCodeAppListBaseModal() {
    public effects() {
      return {
        ...super.effects(),
        initial: async (payload: ViCodeAppQuery) => {
          this._initial({
            initialAction: async (pageIndex: number, pageSize: number) => {
              return this.dependencies.serviceClient.vicodeApp.list({
                ...this.state.query,
                appStatus: ViCodeAppStatus.Enabled,
                offset: pageIndex * pageSize,
                limit: pageSize,
              });
            },
          });
        },
      };
    }
  }
);
