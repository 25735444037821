import {
  DailyRobotExecutionInfo,
  PerRobotExecutionInfo,
} from "@encoo-web/encoo-lib";
import moment from "moment";
import { createModel } from "nyax";
import { DashboardQueryData } from "src/models/dashboard";
import { ModelBase } from "src/store/ModelBase";

export const RobotRunStatisticsUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        dateRange: {
          startDate: moment().subtract(29, "days").format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
        },
        containsSubDepartment: false,
        dailyRobotExecutionDetails: null as DailyRobotExecutionInfo[] | null,
        perRobotExecutionData: null as PerRobotExecutionInfo[] | null,
      };
    }
    public reducers() {
      return {
        setDateRange: (
          dateRange: Pick<DashboardQueryData, "startDate" | "endDate">
        ) => {
          this.state.dateRange = dateRange;
        },
        setContainsSubDepartment: (value: boolean) => {
          this.state.containsSubDepartment = value;
        },
        setDailyRobotExecutionDetails: (
          value: DailyRobotExecutionInfo[] | null
        ) => {
          this.state.dailyRobotExecutionDetails = value;
        },
        setRobotExecutionDetails: (value: PerRobotExecutionInfo[] | null) => {
          this.state.perRobotExecutionData = value;
        },
      };
    }

    public effects() {
      return {
        initializeRequest: async (payload: {
          departmentId: string;
          query: DashboardQueryData;
          containsSubDepartment: boolean;
        }) => {
          const value =
            await this.dependencies.serviceClient.dashboard.robotExecutionstatistics(
              payload.departmentId,
              payload.query,
              payload.containsSubDepartment
            );
          await this.actions.setDailyRobotExecutionDetails.dispatch(
            value.dailyRobotExecutionDetails ?? null
          );
          await this.actions.setRobotExecutionDetails.dispatch(
            value.perRobotExecutionDetails ?? null
          );
        },
      };
    }
  }
);
