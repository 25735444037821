/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React, { ReactNode, useMemo } from "react";
import { useTheme } from "src/hooks";

export type TipBoxType = "normal" | "thin";

export default React.memo(function TipBox(props: {
  content: ReactNode;
  type?: TipBoxType;
  className?: string;
}) {
  const theme = useTheme();

  const { content, className } = props;

  const normalCss = css`
    padding: 10px 20px;
    color: ${theme.component.tipBox.color};
    background: ${theme.component.tipBox.background};
    border: 1px solid ${theme.component.tipBox.border};
  `;

  const thinCss = css`
    padding: 8px 14px;
    color: ${theme.component.tipBox.color};
    background: ${theme.component.tipBox.background};
    border: 1px solid ${theme.component.tipBox.border};
    font-size: 12px;
  `;

  const componentStyles = useMemo((): Record<TipBoxType, SerializedStyles> => {
    return { normal: normalCss, thin: thinCss };
  }, [normalCss, thinCss]);

  return (
    <div
      css={[
        css`
          padding: 10px 20px;
          border-radius: 4px;
        `,
        componentStyles[props.type || "normal"],
      ]}
      className={className}
    >
      {content}
    </div>
  );
});
