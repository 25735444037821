import { createModel, createSelector } from "nyax";
import { DeviceRobot } from "src/models/device";
import { ModelBase } from "src/store/ModelBase";
import { DeviceRobotEntityModel } from "src/store/models/entity/deviceRobot/entity";

export const DeviceRobotTableUIModel = createModel(
  class extends ModelBase {
    private get _deviceId() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.containerKey!;
    }

    public initialState() {
      return {
        robotIds: [] as string[],
      };
    }

    public reducers() {
      return {
        setRobotIds: (value: string[]) => {
          this.state.robotIds = value;
        },
      };
    }

    public selectors() {
      return {
        robots: createSelector(
          () => this.state.robotIds,
          () => this.getContainer(DeviceRobotEntityModel).state.byId,
          (robotIds, robotById) => {
            return robotIds
              .filter((robotId) => !!robotById[robotId])
              .map((robotId) => robotById[robotId]) as DeviceRobot[];
          }
        ),
      };
    }

    public effects() {
      const superEffects = super.effects();
      return {
        ...superEffects,

        initial: async () => {
          const result =
            await this.dependencies.serviceClient.device.getDeviceRobots(
              this._deviceId
            );

          await this.getContainer(
            DeviceRobotEntityModel
          ).actions.batchUpdate.dispatch(result.list);
          await this.actions.setRobotIds.dispatch(
            result.list.map((robot) => robot.robotId)
          );
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
