import moment from "moment";
import semver from "semver";

export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const compareVersion = function (v1: string, v2: string): number {
  if (semver.eq(v1, v2)) {
    return 0;
  }

  return semver.lt(v1, v2) ? -1 : 1;
};

export const formatDate = function (
  date?: string,
  format: string = DEFAULT_DATE_FORMAT
): string {
  return date ? moment(date).locale("en").format(format) : "";
};

export const prefixZero = function (num: number, minLength: number): string {
  const numString = num.toString();
  if (numString.length < minLength) {
    return (Array(minLength).join("0") + num).slice(-minLength);
  } else {
    return numString;
  }
};

export const formatByte = function (byteSize?: number): string {
  if (!byteSize) {
    return "0 KB";
  }

  const limit = byteSize;
  let size = "";
  if (limit < 1024) {
    size = limit.toFixed(2) + " B";
  } else if (limit < 1024 * 1024) {
    size = (limit / 1024).toFixed(2) + " KB";
  } else if (limit < 1024 * 1024 * 1024) {
    size = (limit / (1024 * 1024)).toFixed(2) + " MB";
  } else {
    size = (limit / (1024 * 1024 * 1024)).toFixed(2) + " GB";
  }

  const sizeStr = size + "";
  const index = sizeStr.indexOf(".");
  const last = sizeStr.substr(index + 1, 2);
  if (last === "00") {
    return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2);
  }
  return size;
};

export const timezoneOffsetRelativeToUtc = -new Date().getTimezoneOffset();

export const formatMsToDuring = function (ms?: number):
  | {
      days: number;
      hours: number;
      minutes: number;
      seconds: number;
    }
  | undefined {
  if (!ms) {
    return undefined;
  }
  const days = ms / (1000 * 60 * 60 * 24);
  const hours = (ms % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60);
  const minutes = (ms % (1000 * 60 * 60)) / (1000 * 60);
  const seconds = (ms % (1000 * 60)) / 1000;
  return {
    days: parseInt(days as unknown as string),
    hours: parseInt(hours as unknown as string),
    minutes: parseInt(minutes as unknown as string),
    seconds: parseInt(seconds as unknown as string),
  };
};

export const formatMsToHour = (ms?: number): number => {
  return ms ? Math.round((ms / 3600000) * 100) / 100 : 0;
};

export const joinDirectory = function (
  directory1: string,
  directory2: string
): string {
  const newDirectory1 = directory1.endsWith("/")
    ? directory1.substring(0, directory1.length - 1)
    : directory1;
  const newDirectory2 = directory2.startsWith("/")
    ? directory2.substring(1, directory2.length)
    : directory2;

  return `${newDirectory1}/${newDirectory2}`;
};
