import { FilePermissionUpdateData } from "@encoo-web/encoo-lib/types";
import { createModel } from "nyax";
import { EncooSort } from "src/models/_shared";
import { DataPermissionPayloadData } from "src/models/dataPermission";
import { EncooFile } from "src/models/file";
import { createHelperModel } from "src/store/models/entity/_shared";
import { DataPermissionHelperModel } from "src/store/models/entity/dataPermission/helper";
import { FileEntityModel } from "src/store/models/entity/file/entity";

export const FileHelperModel = createModel(
  class extends createHelperModel<EncooFile>({
    setItems: (getContainer, items) =>
      getContainer(FileEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(FileEntityModel).getters.items,
    getItem: (getContainer, id) => getContainer(FileEntityModel).state.byId[id],
  }) {
    private get _dataPermissionHelperModel() {
      return this.getContainer(DataPermissionHelperModel);
    }
    public effects() {
      return {
        ...super.effects(),
        list: async (payload: { fullName: string; query: EncooSort }) => {
          const { fullName, query } = payload;
          return await this.dependencies.serviceClient.file.getFiles(
            fullName,
            query
          );
        },
        getAllChildren: async (payload: {
          parentId: string;
          fullName: string;
          query: EncooSort;
        }) => {
          const { parentId, fullName, query } = payload;
          return this._readByParentIds({
            parentIds: [parentId],
            getAllAction: async () => {
              const files = await this.dependencies.serviceClient.file.getFiles(
                fullName,
                query
              );
              if (!fullName) {
                const fileIds: DataPermissionPayloadData[] = files.map(
                  (item) => {
                    return {
                      resourceType: item.resourceType,
                      id: item.id,
                    };
                  }
                );
                if (fileIds.length > 0) {
                  await this._dataPermissionHelperModel.actions.getAll.dispatch(
                    fileIds
                  );
                }
              }

              return files.map((file) => ({
                ...file,
                parentId,
              }));
            },
            getEntityParentId: (entity: EncooFile) => entity.parentId ?? "",
            force: true,
          });
        },
        getFileByFullName: async (payload: { fullName: string }) => {
          const { fullName } = payload;
          const file = await this.dependencies.serviceClient.file.getMeta(
            fullName
          );
          const entityContailer = this.getContainer(FileEntityModel);
          const entityFile = entityContailer.state.byId[file.id];
          if (entityFile?.parentId) {
            file.parentId = entityFile.parentId;
          }

          await entityContailer.actions.batchUpdate.dispatch([file]);

          return file;
        },
        getDownloadUri: async (payload: { fullName: string }) => {
          return await this.dependencies.serviceClient.file.getFileDownloadUri(
            payload.fullName
          );
        },
        createDirectory: async (payload: {
          parentId: string;
          fullName: string;
          name: string;
          dataConnectionId?: string;
        }) => {
          const { parentId, fullName, name, dataConnectionId } = payload;
          return this._create({
            createAction: async () => {
              const file =
                await this.dependencies.serviceClient.file.createDirectory(
                  fullName,
                  name,
                  dataConnectionId
                );
              return {
                ...file,
                parentId,
              };
            },
          });
        },
        updateRootFile: async (payload: {
          id: string;
          name: string;
          dataConnectionId: string;
          parentId: string;
        }) => {
          const { parentId, id, name, dataConnectionId } = payload;
          return await this._update({
            id,
            updateAction: async () => {
              const file =
                await this.dependencies.serviceClient.file.updateRootFile(
                  id,
                  name,
                  dataConnectionId
                );
              return {
                ...file,
                parentId,
              };
            },
          });
        },
        delete: async (payload: { id: string; fullName: string }) => {
          const { id, fullName } = payload;
          return this._delete({
            id,
            deleteAction: () =>
              this.dependencies.serviceClient.file.delete(fullName),
          });
        },

        deleteBatch: async (payload: { fullNameList: string[] }) => {
          const { fullNameList } = payload;
          return await this.dependencies.serviceClient.file.batchDelete(
            fullNameList
          );
        },

        getFilePermission: async (id: string) => {
          const filePermission =
            await this.dependencies.serviceClient.dataPermission.getById(
              id,
              "file"
            );
          return filePermission;
        },

        updateFilePermission: async (payload: {
          id: string;
          filePermissionData: FilePermissionUpdateData;
        }) => {
          const { id, filePermissionData } = payload;
          return await this.dependencies.serviceClient.dataPermission.update(
            id,
            filePermissionData
          );
        },
      };
    }
  }
);
