import { createModel } from "nyax";
import { User, UserClaim } from "src/models/user";
import {
  UpdateExternalUserPasswordData,
  UpdatePasswordData,
  UpdateUserData,
  VerificationCodeCheckData,
  VerificationCodeData,
} from "src/models/userInfo";
import { createHelperModel } from "src/store/models/entity/_shared";
import { UserEntityModel } from "src/store/models/entity/user/entity";
import { UserListModel } from "src/store/models/entity/user/list";

export const UserHelperModel = createModel(
  class extends createHelperModel<User>({
    setItems: (getContainer, items) =>
      getContainer(UserEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(UserEntityModel).getters.items,
    getItem: (getContainer, id) => getContainer(UserEntityModel).state.byId[id],
    refreshList: (getContainer) =>
      getContainer(UserListModel).actions.refresh.dispatch({}),
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(UserEntityModel),
      };
    }
    public effects() {
      return {
        ...super.effects(),

        avatars: async () => {
          return this.dependencies.serviceClient.user.avatarList();
        },

        sendToken: async (payload: VerificationCodeData) => {
          return await this.dependencies.serviceClient.user.sendToken(payload);
        },

        verifyToken: async (payload: VerificationCodeCheckData) => {
          await this.dependencies.serviceClient.user.verifyToken(payload);
        },

        updatePhoneNumber: async (payload: {
          id: string;
          updateUserData: UpdateUserData;
        }) => {
          const { id, updateUserData } = payload;
          await this.dependencies.serviceClient.user.updatePhoneNumberV2(
            id,
            updateUserData
          );
        },

        unbindPhoneNumber: async (id: string) => {
          await this.dependencies.serviceClient.user.unbindPhoneNumber(id);
        },

        bindingEmail: async (payload: {
          id: string;
          updateUserData: UpdateUserData;
        }) => {
          const { id, updateUserData } = payload;
          await this.dependencies.serviceClient.user.bindingEmail(
            id,
            updateUserData
          );
        },

        unbindEmail: async (id: string) => {
          await this.dependencies.serviceClient.user.unbindEmail(id);
        },

        unbindWeChat: async (id: string) => {
          await this.dependencies.serviceClient.user.unbindWeChat(id);
        },

        updateExternalUserPassword: async (payload: {
          id: string;
          updateUserData: UpdateExternalUserPasswordData;
        }) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { id, updateUserData } = payload;
          await this.dependencies.serviceClient.user.updateExternalUserPassword(
            id,
            updateUserData
          );
        },

        updatePassword: async (payload: {
          id: string;
          updatePasswordData: UpdatePasswordData;
        }) => {
          const { id, updatePasswordData } = payload;
          await this.dependencies.serviceClient.user.updatePassword(
            id,
            updatePasswordData
          );
        },

        updateUser: async (payload: {
          id: string;
          updateValue: UserClaim[];
        }) => {
          const { id, updateValue } = payload;
          await this.dependencies.serviceClient.user.updateUserV2(
            id,
            updateValue
          );
        },

        updateLanguage: async (payload: { id: string; language: string }) => {
          const { id, language } = payload;
          await this.dependencies.serviceClient.user.updateLanguage(
            id,
            language
          );
        },
        updateUserAvatar: async (payload: {
          userId: string;
          avatarId: number;
        }) => {
          const { userId, avatarId } = payload;
          await this.dependencies.serviceClient.user.updateUserAvatar(
            userId,
            avatarId
          );
        },
      };
    }
  }
);
