// eslint-disable-next-line import/no-anonymous-default-export
export default {
  asset: {
    searchParams: "搜索参数",
    introduction:
      "资产管理主要用于定义各类参数及账户密码凭证，支持加密存储并供流程执行过程中进行调用。",
    paramsName: "参数名称",
    assetType: "资产类型",
    storageType: "存储方式",
    assetValue: "资产值",
    assetName: "资产名称",
    userName: "用户名称",
    password: "密码",
    stringValue: "字符串值",
    assetStorageType: "资产存储方式",
    encryptMode: "存储算法类型",
    booleanValue: "布尔值",
    passwordOfAccountCertificate: "账户凭证密码",
    string: "字符串",
    integer: "整型",
    float: "浮点型",
    booleanType: "布尔型",
    ordinaryStorage: "普通存储",
    encryptedStorage: "加密存储",
    encryptedType: {
      default: "默认加密算法",
      guomi: "国密加密算法",
    },
    true: "是",
    false: "否",
    value: "值",
    validation: {
      float: "请输入浮点型数据",
      int: "请输入整型数据",
    },
    tip: {
      userName: "提示：若修改用户名称需要重新填写密码",
      blank: "提示：若保持该项为空提交则意味着不对该项做任何更改",
    },
  },
};
