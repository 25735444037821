import { createModel, createSelector } from "nyax";
import { TaskScheduler } from "src/models/workflowLayout";
import { ModelBase } from "src/store/ModelBase";
import { DataPermissionEntityModel } from "src/store/models/entity/dataPermission/entity";
import { WorkflowLayoutTriggerEntityModel } from "src/store/models/entity/workflowLayoutTrigger/entity";
import { WorkflowLayoutTriggerHelperModel } from "src/store/models/entity/workflowLayoutTrigger/helper";

export const WorkflowLayoutTriggerDetailModel = createModel(
  class extends ModelBase {
    private get _id() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.containerKey!;
    }

    public selectors() {
      return {
        workflowLayoutTriggerDetail: createSelector(
          () => this.getContainer(WorkflowLayoutTriggerEntityModel).state.byId,
          () => this.getContainer(DataPermissionEntityModel).state.byId,
          (workflowLayoutTriggerById, permissionById) => {
            const workflowLayoutTrigger = workflowLayoutTriggerById[this._id];
            return workflowLayoutTrigger
              ? ({
                  ...workflowLayoutTrigger,
                  permissionValues:
                    permissionById[this._id]?.permissionValues ?? [],
                } as TaskScheduler)
              : null;
          }
        ),
      };
    }

    public effects() {
      return {
        requestDetail: async (workflowLayoutId: string) => {
          await this.getContainer(
            WorkflowLayoutTriggerHelperModel
          ).actions.getById.dispatch({
            workflowLayoutId,
            triggerId: this._id,
          });
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
