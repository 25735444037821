import {
  Constants,
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib/";
import {
  Template,
  TemplateCreationData,
  TemplateFile,
  UnderstandingResult,
} from "src/models/understanding";
export const UNDERSTANDING_SERVICE_NAME = "understanding";

export class UnderstandingSeviceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, UNDERSTANDING_SERVICE_NAME);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async list(namePrefix?: string) {
    const req = this.buildRequestV2({
      url: "/v2/Templates",
      method: "GET",
      query: {
        NamePrefix: namePrefix,
      },
    });
    return await super.listInternally<Template>(req);
  }

  async getById(templateId: string): Promise<Template> {
    const req = this.buildRequestV2({
      url: `/v2/Templates/${templateId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async create(payload: TemplateCreationData): Promise<Template> {
    const req = this.buildRequestV2({
      url: `/v2/Templates`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  async update(
    template: Partial<Template> & Pick<Template, "id">
  ): Promise<Template> {
    const req = this.buildRequestV2({
      url: `/v2/Templates/${template.id}`,
      method: "PUT",
      payload: template,
    });
    if (template.eTag) {
      req.headers?.set(Constants.HeaderConstants.IF_MATCH, template.eTag);
    }
    return await super.updateInternal(req);
  }

  async patch(id: string): Promise<TemplateFile> {
    const req = this.buildRequestV2({
      url: `/v2/Templates/${id}`,
      method: "PATCH",
      payload: { RecognizePolicy: 1 },
    });
    return await this.sendRequest(req).then((res) => {
      if (res.status === 200) {
        return res.body;
      }
      return undefined;
    });
  }

  async publish(id: string, isPublished: boolean): Promise<Template> {
    const req = this.buildRequestV2({
      url: `/v2/Templates/${id}/isPublished`,
      method: "PUT",
      payload: {
        value: isPublished,
      },
    });

    return await super.updateInternal(req);
  }

  async delete(templateId: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/Templates/${templateId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }

  async getTemplateFile(id: string): Promise<TemplateFile> {
    const req = this.buildRequestV2({
      url: `/v2/Templates/${id}/File`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async getTemplateImage(id: string, pageIndex: number): Promise<string> {
    const req = this.buildRequestV2({
      url: `/v2/Templates/${id}/File/images/${pageIndex}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async createUnderstandingResult(
    templateId: string
  ): Promise<UnderstandingResult> {
    const req = this.buildRequestV2({
      url: `/v2/UnderstandingResults/${templateId}`,
      method: "POST",
    });
    return await super.createInternal(req);
  }

  async getUnderstandingResultById(id: string): Promise<UnderstandingResult> {
    const req = this.buildRequestV2({
      url: `/v2/UnderstandingResults/${id}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async patchUnderstandingResult(id: string): Promise<UnderstandingResult> {
    const req = this.buildRequestV2({
      url: `/v2/UnderstandingResults/${id}`,
      method: "PATCH",
      payload: {
        recognizePolicy: 1,
      },
    });
    return await this.sendRequest(req).then((res) => {
      if (res.status === 200) {
        return res.body;
      }
      return undefined;
    });
  }

  async getUnderstandingResultImage(
    id: string,
    pageIndex: number
  ): Promise<string> {
    const req = this.buildRequestV2({
      url: `/v2/UnderstandingResults/${id}/images/${pageIndex}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }
}
