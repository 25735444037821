import { createModel } from "nyax";
import { Device } from "src/models/device";
import { DataPermissionHelperModel } from "src/store/models/entity/dataPermission/helper";
import { DeviceEntityModel } from "src/store/models/entity/device/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const DeviceHelperModel = createModel(
  class extends createHelperModel<Device>({
    setItems: (getContainer, items) =>
      getContainer(DeviceEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(DeviceEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(DeviceEntityModel).state.byId[id],
  }) {
    public effects() {
      return {
        ...super.effects(),
        getById: async (payload: { deviceId: string }) => {
          const { deviceId } = payload;
          return this._readById({
            id: deviceId,
            force: true,
            getByIdAction: async () => {
              const device =
                await this.dependencies.serviceClient.device.getById(deviceId);
              const { resourceType, id } = device;
              const dataPermissionPayload = [{ resourceType, id }];

              await this.getContainer(
                DataPermissionHelperModel
              ).actions.getAll.dispatch(dataPermissionPayload);

              return device;
            },
          });
        },
        update: async (payload: { id: string; deviceTags: string[] }) => {
          const { id, deviceTags } = payload;
          return this._update({
            id,
            updateAction: () => {
              return this.dependencies.serviceClient.device.update(id, {
                deviceTags,
              });
            },
          });
        },
        getRemoteDesktop: async (deviceId: string) => {
          return this.dependencies.serviceClient.device.getRemoteDesktop(
            deviceId
          );
        },
        getDeviceResources: async (payload: { deviceId: string }) => {
          const { deviceId } = payload;
          return await this.dependencies.serviceClient.device.getDeviceResources(
            deviceId
          );
        },
      };
    }
  }
);
