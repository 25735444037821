import { createModel } from "nyax";
import { Calendars, CalendarsQuery } from "src/models/calendars";
import { CalendarsEntityModel } from "src/store/models/entity/calendars/entity";
import { CalendarsSideUIModel } from "src/store/models/ui/calendars/calendarsSide";
import { createUITableWithPermissionModel } from "src/store/models/ui/_shared";

export type CalendarsWithPermission = Calendars & {
  permissionValues: string[];
};

export const CalendarsTableUIModel = createModel(
  class extends createUITableWithPermissionModel<Calendars, CalendarsQuery>({
    setItems: (getContainer, items) =>
      getContainer(CalendarsEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(CalendarsEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(CalendarsEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      const superEffects = super.effects();
      return {
        ...superEffects,
        updateQuery: async (params: CalendarsQuery) => {
          await superEffects.updateQuery(params);
          await this.getContainer(CalendarsSideUIModel).actions.setQuery.dispatch(
            this.state.query
          );
        },
        initial: async (payload: { departmentId: string }) => {
          const { departmentId } = payload;
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query: CalendarsQuery
            ) =>
              this.dependencies.serviceClient.calendars.list(
                departmentId,
                pageIndex,
                pageSize,
                query
              ),
          });
        },
      };
    }
  }
);
