import { createModel } from "nyax";
import { UserManagerQuery } from "src/containers/userManager/models/UserManagerUserModel";
import { DepartmentUser } from "src/models/DepartmentUser";
import { DepartmentUserEntityModel } from "src/store/models/entity/departmentUser/entity";
import { createUITableBaseModel } from "src/store/models/ui/_shared";

export const DepartmentUsersTableUIModel = createModel(
  class extends createUITableBaseModel<DepartmentUser, UserManagerQuery>({
    setItems: (getContainer, items) =>
      getContainer(DepartmentUserEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(DepartmentUserEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(DepartmentUserEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    private get _id() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.containerKey!;
    }
    public initialState() {
      return {
        ...super.initialState(),
        selectedUserId: null as string | null,
        isShowInviteUser: false,
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setSelectedId: (id: string | null) => {
          this.state.selectedUserId = id;
        },
        setIsShowInviteUser: (isShowInviteUser: boolean) => {
          this.state.isShowInviteUser = isShowInviteUser;
        },
      };
    }
    public effects() {
      return {
        ...super.effects(),
        initial: async () => {
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query: UserManagerQuery
            ) =>
              this.dependencies.serviceClient.departmentUser.list(
                this.containerKey ?? "",
                pageIndex,
                pageSize,
                query
              ),
          });
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
