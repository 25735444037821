// eslint-disable-next-line import/no-anonymous-default-export
export default {
  calendars: {
    entityTypeName: "日历管理",
    name: "日历名称",
    introduction:
      "管理自定义日历，用于定时计划或运行限制，支持日历的新建、编辑、删除、查看等操作。",
    totalDates: "共计 ",
    create: "创建日历",
    errorMessage: "日历项不能为空",
    updateConfirm: "确认要保存当前修改?",
    updateAlertInfo: {
      startHalf: "已在定时任务",
      endHalf: "中使用，保存后，将同步到使用场景。",
    },
    deleteAlertInfo: {
      startHalf: "已在",
      endHalf: "使用，不可删除，请先解绑！",
    },
  },
};
