import { createModel } from "nyax";
import { Package } from "src/models/package";
import { DataPermissionHelperModel } from "src/store/models/entity/dataPermission/helper";
import { PackageEntityModel } from "src/store/models/entity/package/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const PackageHelperModel = createModel(
  class extends createHelperModel<Package>({
    setItems: (getContainer, items) =>
      getContainer(PackageEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(PackageEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(PackageEntityModel).state.byId[id],
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(PackageEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),

        readAll: async (payload: { force?: boolean }) => {
          return await this._readByParentIds({
            parentIds: [""],
            getAllAction: () => {
              return this.dependencies.serviceClient.package.getAll();
            },
            getEntityParentId: (entity: Package) => "",
            force: true,
          });
        },

        getById: async (payload: { packageId: string }) => {
          const { packageId } = payload;

          return this._readById({
            id: packageId,
            getByIdAction: async () => {
              const item =
                await this.dependencies.serviceClient.package.getById(
                  packageId
                );

              await this.getContainer(
                DataPermissionHelperModel
              ).actions.getAll.dispatch([
                { resourceType: item.resourceType, id: item.id },
              ]);
              return item;
            },
          });
        },
        updateCompletedPackage: async (payload: {
          packageId: string;
          eTag: string;
          tags?: string[];
          description?: string;
        }) => {
          const { packageId, eTag, tags, description } = payload;
          return this._update({
            id: packageId,
            updateAction: () => {
              return this.dependencies.serviceClient.package.updateCompletedPackage(
                packageId,
                eTag,
                tags,
                description
              );
            },
          });
        },
        delete: async (payload: { id: string }) => {
          const { id } = payload;
          const { timestamp } = await this.actions._rw.beginWrite.dispatch({
            ids: [id],
          });
          try {
            await this.dependencies.serviceClient.package.delete(id);
            await this.actions._rw.endWrite.dispatch({
              items: [id],
              timestamp,
            });
          } catch (error) {
            await this.actions._rw.endWrite.dispatch({
              items: [],
              timestamp,
            });
            throw error;
          }
        },
        deleteBatch: async (payload: { packageIds: string[] }) => {
          const { packageIds } = payload;
          return await this.dependencies.serviceClient.package.batchDelete(
            packageIds
          );
        },
      };
    }
  }
);
