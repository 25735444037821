import { createModel } from "nyax";
import {
  Calendars,
  CalendarsCreationData,
  CalendarsUpdateData,
} from "src/models/calendars";
import { DataPermissionHelperModel } from "src/store/models/entity/dataPermission/helper";
import { CalendarsEntityModel } from "src/store/models/entity/calendars/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const CalendarsHelperModel = createModel(
  class extends createHelperModel<Calendars>({
    setItems: (getContainer, items) =>
      getContainer(CalendarsEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(CalendarsEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(CalendarsEntityModel).state.byId[id],
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(CalendarsEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),

        getById: async (payload: { calendarsId: string; force?: boolean }) => {
          const { calendarsId, force } = payload;
          return await this._readById({
            id: calendarsId,
            getByIdAction: async () => {
              const item =
                await this.dependencies.serviceClient.calendars.getById(
                  calendarsId
                );
              await this.getContainer(
                DataPermissionHelperModel
              ).actions.getAll.dispatch([
                { resourceType: item.resourceType, id: item.id },
              ]);
              return item;
            },
            force,
          });
        },
        create: async (payload: { params: CalendarsCreationData }) => {
          const { params } = payload;
          return await this._create({
            createAction: () =>
              this.dependencies.serviceClient.calendars.create(params),
          });
        },
        update: async (payload: {
          id: string;
          params: CalendarsUpdateData;
        }) => {
          const { id } = payload;
          return await this._update({
            id,
            updateAction: () =>
              this.dependencies.serviceClient.calendars.update(payload),
          });
        },
        delete: async (payload: { calendarsId: string }) => {
          const { calendarsId } = payload;
          return await this._delete({
            id: calendarsId,
            deleteAction: () =>
              this.dependencies.serviceClient.calendars.delete(calendarsId),
          });
        },
        deleteBatch: async (payload: { calendarsIds: string[] }) => {
          const { calendarsIds } = payload;
          return await this.dependencies.serviceClient.calendars.batchDelete(
            calendarsIds
          );
        },
      };
    }
  }
);
