import { createModel } from "nyax";
import { AiAccountService } from "src/models/ocr";
import { createItemsEntityModel } from "src/store/itemsEntity";

export const AIEntityModel = createModel(
  class extends createItemsEntityModel<AiAccountService>((item) => item.id) {
    public selectors() {
      return {
        ...super.selectors(),
      };
    }
  }
);
