// eslint-disable-next-line import/no-anonymous-default-export
export default {
  workflowLayout: {
    title: "Flow Sequence",
    name: "Flow Sequence Name",
    introduction:
      "Flow Sequence can automatically deploy multiple flow deployments, and support sequence adjustment and timing start.",
    lastExecuteTime: "Last Execution Time",
    operations: {
      viewLayouts: "View",
      scheduledTask: "Scheduled Task",
    },
    nodeErrorHandlingModel: {
      whenNodeError: "When a node failed or canceled",
      operations: {
        continue: "Continue",
        break: "Break",
      },
    },
    copySourceTarget: "Copy source: {sourceName}",
    dragAndDrop:
      "Please select an item from the list of process deployments on the left and drag and drop it here.",
    saveConfirmDialog: {
      contentText: "Do you want to save the changes?",
      tips: "Clicking Cancel button will not save the changes for you.",
    },
    workflowLayoutNodesEditor: {
      name: "Edit the Sequence",
    },
    infoExecute: "Are you sure to batch execute the selected Flow Sequences?",
  },
};
