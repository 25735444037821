import { createModel } from "nyax";
import {
  CoeDataAnalysisBasicConfig,
  CoeDataAnalysisPackageConfig,
} from "src/models/coeDataAnalysisPackageConfig";

import { ModelBase } from "src/store/ModelBase";
import { CoeDataAnalysisPackageConfigHelperModel } from "src/store/models/entity/coeDataAnalysisPackageConfig/helper";

export const CoeDataAnalysisPackageConfigUIModel = createModel(
  class extends ModelBase {
    private get _helperContainer() {
      return this.getContainer(CoeDataAnalysisPackageConfigHelperModel);
    }

    public initialState() {
      return {
        dataSource: [] as CoeDataAnalysisPackageConfig[],
        basicConfig: {
          avgLaborCostByMonth: 10000, // 平均人工成本/月
          rpaCostByYear: 6000, // RPA投入成本/年
        },
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setBasicConfig: (value: CoeDataAnalysisBasicConfig) => {
          this.state.basicConfig = value;
        },
        setDataSource: (value: CoeDataAnalysisPackageConfig[]) => {
          this.state.dataSource = value;
        },
      };
    }

    public selectors() {
      return {
        ...super.selectors(),
      };
    }

    public effects() {
      return {
        getBasicConfig: async () => {
          const config =
            await this._helperContainer.actions.getBasicConfig.dispatch({});
          this.actions.setBasicConfig.dispatch(config);
        },

        updateBasicConfig: async (payload: CoeDataAnalysisBasicConfig) => {
          await this._helperContainer.actions.updateBasicConfig.dispatch(
            payload
          );
          this.actions.setBasicConfig.dispatch(payload);
        },
        getPackages: async (payload: {
          departmentId: string;
          pageIndex?: number;
          pageSize?: number;
        }) => {
          const { departmentId, pageIndex = 0, pageSize = 1000 } = payload;

          let _pageIdx = pageIndex;
          const results = [];

          // eslint-disable-next-line no-constant-condition
          while (true) {
            const body =
              await this.dependencies.serviceClient.coeDataAnalysisPackageConfig.list(
                departmentId,
                _pageIdx,
                pageSize
              );

            results.push(...(body.list ?? []));

            if ((_pageIdx + 1) * pageSize >= (body.count ?? 0)) {
              break;
            } else {
              _pageIdx += 1;
            }
          }

          const res = results.map((item) => item);

          await this.actions.setDataSource.dispatch(res);

          return res;
        },
      };
    }
  }
);
