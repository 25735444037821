import { message } from "antd";
import { useCallback, useMemo } from "react";
import { useFormatMessage, useGetContainer } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { AppVersionV2 } from "src/models/appV2";
import { AppHelperModel } from "src/store/models/entity/apps/helper";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppsOperation = function () {
  const getContainer = useGetContainer();
  const appsHelper = getContainer(AppHelperModel);
  const formatMessage = useFormatMessage();

  const onRelease = useCallback(
    async (appId: string, payload: Partial<AppVersionV2>) => {
      await appsHelper.actions.releaseApp.dispatch({
        appId,
        payload,
      });
      message.success(
        formatMessage(messageIds.apps.appRelease.releaseSuccessFully)
      );
    },
    [appsHelper.actions.releaseApp, formatMessage]
  );

  const operations = useMemo(
    () => ({
      release: onRelease,
    }),
    [onRelease]
  );

  return operations;
};
