/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Entity } from "@encoo-web/encoo-lib";
import {
  ConnectionEnvironment,
  CreateConnectorItemDialog,
  CreateRestfulConnectionTemplateData,
  DataConnection,
  DataConnectionList,
  RestfulApiTempaltesConfig,
  RestfulConnectFunctionPayload,
  useConnectorTypeList,
} from "@encoo-web/encoo-ui";
import { Divider, Space } from "antd";
import { ColumnProps } from "antd/es/table";
import { memoize } from "lodash";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import iconConnection from "src/assets/connection.svg";
import AuthorizedV2 from "src/components/AuthorizedV2";
import Button from "src/components/Button";
import DescriptionTip from "src/components/DescriptionTip";
import List, { ListItem } from "src/components/List";
import Table from "src/components/Table";
import TableOperation, {
  TableOperationMenuItem,
} from "src/components/TableOperation";
import RestrictionButton from "src/containers/_components/RestrictionButton";
import useConnectorAction from "src/containers/dataConnection/components/DataConnectionAction";
import DataConnectionSearch from "src/containers/dataConnection/components/DataConnectionSearch";
import AppMainLayout from "src/containers/layout/AppMainLayout";
import {
  useFormatMessage,
  useGetContainer,
  useGlobalDepartment,
  useTheme,
} from "src/hooks";
import messageIds from "src/locales/messageIds";
import { DataConnectionListModel } from "src/store/models/entity/dataConnection/list";
import { RouterModel } from "src/store/models/router";
import {
  DataConnectionModalType,
  DataConnectionUiModel,
} from "src/store/models/ui/dataConnection/dataConnection";
import { formatDate } from "src/utils/string";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useConnectionEnvironment = function () {
  const formatMessage = useFormatMessage();

  const environmentList: Record<
    ConnectionEnvironment,
    { name: string } | undefined
  > = useMemo(() => {
    return {
      prod: {
        name: formatMessage(
          messageIds.dataConnection.env.productionEnvironmentType
        ),
      },
      dev: {
        name: formatMessage(
          messageIds.dataConnection.env.developmentEnvironmentType
        ),
      },
    };
  }, [formatMessage]);

  return environmentList;
};

export default React.memo(function DataConnectionPage() {
  const getContainer = useGetContainer();
  const router = getContainer(RouterModel);
  const currentRouteInfo = useSelector(() => router.getters.currentRouteInfo);
  const formatMessage = useFormatMessage();

  const dataConnectionContainer = getContainer(DataConnectionUiModel);
  const dataConnectionList = getContainer(DataConnectionListModel);

  const theme = useTheme();
  const dataSource = useSelector(() =>
    dataConnectionList.getters.dataSourceWithPermission.filter(
      (item) =>
        item.type !== "Table" &&
        item.permissionValues.includes("DataConnection_Read")
    )
  );

  const listDataSource = useSelector(() =>
    dataConnectionList.getters.listDataSourceWithPermission.filter(
      (item) =>
        item.type !== "Table" &&
        item.permissionValues.includes("DataConnection_Read")
    )
  );

  const modalType = useSelector(() => dataConnectionContainer.state.modalType);
  const dataConnectionTemplateInfos = useSelector(
    () => dataConnectionContainer.state.dataConnectionTemplateInfos
  );

  const [showAPiConfigModal, setShowAPiConfigModal] = useState(false);
  const connectorTypeList = useConnectorTypeList({
    dataConnectionTemplateInfos: dataConnectionTemplateInfos ?? undefined,
  });

  const department = useGlobalDepartment();

  const { create, testWithConfig, onItemTestWithId, onItemDelete } =
    useConnectorAction();

  const pagination = useSelector(() => dataConnectionList.getters.pagination);

  const listPagination = useSelector(
    () => dataConnectionList.getters.listPagination
  );

  const isDetailPage = useMemo(
    () => currentRouteInfo.type === "dataConnectionDetail",
    [currentRouteInfo.type]
  );

  const query = useSelector(() => dataConnectionList.state.query);

  const navigateToDetail = useCallback(
    (id: string) => {
      router.actions.navigateByRouteInfo.dispatch({
        type: "dataConnectionDetail",
        params: {
          id,
          activeKey: router.getters.searchParams.get("activeKey") ?? "basic",
        },
      });
    },
    [router.actions.navigateByRouteInfo, router.getters.searchParams]
  );

  const onItemTestWithConfig = useCallback(
    async (values: Omit<DataConnection, keyof Entity | "id">) => {
      return await testWithConfig(values);
    },
    [testWithConfig]
  );

  const onItemCreate = useCallback(
    async (values: Omit<DataConnection, keyof Entity | "id">) => {
      const item = await create(values);
      return !!item;
    },
    [create]
  );

  const getRowOperationMenus = useCallback(
    (
      record: DataConnectionList & { permissionValues: string[] }
    ): Array<TableOperationMenuItem> => [
      {
        key: "view",
        name: formatMessage(messageIds.common.view),
        visible: !isDetailPage,
        onClick: () => navigateToDetail(record.id),
      },
      {
        key: "test",
        name: formatMessage(messageIds.dataConnection.test),
        onClick: () => onItemTestWithId(record.id),
        visible: !!connectorTypeList.find((item) => item.type === record.type)
          ?.runable,
      },
      {
        key: "delete",
        name: formatMessage(messageIds.common.delete),
        type: "warning",
        onClick: () => onItemDelete(record),
        visible: record.permissionValues.includes("DataConnection_Delete"),
      },
    ],
    [
      connectorTypeList,
      formatMessage,
      isDetailPage,
      navigateToDetail,
      onItemDelete,
      onItemTestWithId,
    ]
  );

  const columns = useMemo(
    (): ColumnProps<DataConnectionList>[] => [
      {
        title: formatMessage(messageIds.dataConnection.listFields.name),
        dataIndex: "name",
        render: (text, record) => (
          <Button type="link" onClick={() => navigateToDetail(record.id)}>
            <div
              css={css`
                max-width: 15rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              `}
            >
              {text}
            </div>
          </Button>
        ),
      },
      {
        title: formatMessage(messageIds.dataConnection.listFields.dataType),
        dataIndex: "type",
        render: (text) =>
          connectorTypeList.find((item) => item.type === text)?.name ?? text,
      },
      {
        title: formatMessage(messageIds.common.createdBy),
        dataIndex: "createdByName",
      },
      {
        title: formatMessage(messageIds.common.updateTime),
        dataIndex: "modifiedAt",
        render: (text, record) => formatDate(text ?? record.createdAt),
      },
      {
        title: formatMessage(messageIds.common.operation),
        dataIndex: "",
        render: (record) => (
          <TableOperation menuList={getRowOperationMenus(record)} />
        ),
      },
    ],
    [connectorTypeList, formatMessage, getRowOperationMenus, navigateToDetail]
  );

  const dataConnectionDetailId = useMemo(() => {
    const params = currentRouteInfo.params as Record<string, string>;
    return params.id;
  }, [currentRouteInfo.params]);

  const renderTableList = () => {
    return (
      <List
        title={
          <DescriptionTip
            tip={formatMessage(messageIds.dataConnection.introduction)}
          >
            {formatMessage(messageIds.menu.data.dataConnection.name)}
          </DescriptionTip>
        }
        iconType={iconConnection}
        pagination={listPagination}
      >
        {listDataSource.map((item) => (
          <ListItem
            key={item.id}
            active={dataConnectionDetailId === item.id}
            onClick={() => navigateToDetail(item.id)}
            rightContent={
              <TableOperation menuList={getRowOperationMenus(item)} />
            }
          >
            {item.name}
          </ListItem>
        ))}
      </List>
    );
  };

  const onModalClose = useCallback(() => {
    dataConnectionContainer.actions.setModalType.dispatch(null);
  }, [dataConnectionContainer.actions.setModalType]);

  const onModalHandle = useMemo(
    () =>
      memoize((value: DataConnectionModalType | null) => {
        return () =>
          dataConnectionContainer.actions.setModalType.dispatch(value);
      }),
    [dataConnectionContainer.actions.setModalType]
  );

  const getFunctionVariableList = useCallback(
    async (payload: RestfulConnectFunctionPayload) => {
      return dataConnectionContainer.actions.getFunctionVariableList.dispatch(
        payload
      );
    },
    [dataConnectionContainer.actions.getFunctionVariableList]
  );

  const onSave = useCallback(
    async (payload: CreateRestfulConnectionTemplateData, image: File) => {
      await dataConnectionContainer.actions.createRestfulTemplate.dispatch({
        createData: payload,
        image,
      });
    },
    [dataConnectionContainer.actions.createRestfulTemplate]
  );

  const onCloseRestfulConfigModal = useCallback(() => {
    setShowAPiConfigModal(false);
  }, []);

  const deleteCustomTemplate = useCallback(
    async (id: string) => {
      await dataConnectionContainer.actions.deleteCustomTemplate.dispatch(id);
    },
    [dataConnectionContainer.actions.deleteCustomTemplate]
  );

  const onSearch = useCallback(
    async (value) => {
      await dataConnectionList.actions.updateQuery.dispatch(value);
      await dataConnectionList.actions.initial.dispatch({
        ...dataConnectionList.state.query,
        hidTable: true,
      });
    },
    [
      dataConnectionList.actions.initial,
      dataConnectionList.actions.updateQuery,
      dataConnectionList.state.query,
    ]
  );

  const getAsset = useCallback(async () => {
    return await dataConnectionContainer.actions.getAsset.dispatch({});
  }, [dataConnectionContainer.actions.getAsset]);

  useEffect(() => {
    if (department.id) {
      dataConnectionList.actions.initial.dispatch({
        ...dataConnectionList.state.query,
        hidTable: true,
      });
    }
  }, [dataConnectionList.actions.initial, dataConnectionList.state.query, department.id]);

  useEffect(() => {
    dataConnectionContainer.actions.fetchTemplates.dispatch(null);
  }, [dataConnectionContainer.actions.fetchTemplates]);

  return (
    <Fragment>
      {isDetailPage ? (
        renderTableList()
      ) : (
        <AppMainLayout
          toolsNode={
            <Space size={20}>
              {/* todo 需求隐藏模板连接器 */}
              {/* <AuthorizedV2
                permissionKeys={["DataConnectionTemplate_Management"]}
              >
                <Button type="link" onClick={onOpenRestfulConfigModal}>
                  {formatMessage(messageIds.dataConnection.restfulAPIConfig)}
                </Button>
              </AuthorizedV2> */}

              <AuthorizedV2 permissionKeys={["DataConnection_Create"]}>
                <RestrictionButton
                  type="primary"
                  iconName={"icon-add"}
                  onClick={onModalHandle("create")}
                >
                  {formatMessage(messageIds.common.create)}
                </RestrictionButton>
              </AuthorizedV2>
            </Space>
          }
        >
          <DataConnectionSearch query={query} onSearch={onSearch} />
          <Divider
            css={css`
              margin: 10px 0;
              border-top: 1px solid ${theme.bodyDivider};
            `}
          />
          <Table
            dataSource={dataSource}
            columns={columns}
            rowKey="id"
            pagination={pagination}
          />
        </AppMainLayout>
      )}
      {modalType === "create" && (
        <CreateConnectorItemDialog
          onClose={onModalClose}
          onConnectorTest={onItemTestWithConfig}
          onConnectorCreate={onItemCreate}
          dataConnectionTemplateInfos={dataConnectionTemplateInfos ?? undefined}
          getAsset={getAsset}
        />
      )}
      <RestfulApiTempaltesConfig
        visible={showAPiConfigModal}
        onClose={onCloseRestfulConfigModal}
        getFunctionVariableList={getFunctionVariableList}
        onSave={onSave}
        onDelete={deleteCustomTemplate}
        dataConnectionTemplateInfos={dataConnectionTemplateInfos ?? undefined}
      />
    </Fragment>
  );
});
