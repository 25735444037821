/** @jsxImportSource @emotion/react */
import { SwapOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { Divider } from "antd";
import { cloneDeep } from "lodash";
import React, { Fragment, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import AuthorizedV2 from "src/components/AuthorizedV2";
import Button from "src/components/Button";
import RestrictionButton from "src/containers/_components/RestrictionButton";
import AppCreationDialog from "src/containers/apps/AppCreationDialog";
import { AppV1OperationDialog } from "src/containers/apps/AppV1OperationDialog";
import AppMainLayout from "src/containers/layout/AppMainLayout";
import ViCodeAppSearch from "src/containers/vicodeApp/components/ViCodeAppSearch";
import ViCodeAppCreationDialog from "src/containers/vicodeApp/ViCodeAppCreationDialog";
import ViCodeAppList from "src/containers/vicodeApp/ViCodeAppList";
import {
  useApplicationInsights,
  useFormatMessage,
  useGetContainer,
  useTheme,
  useUserEdition,
} from "src/hooks";
import messageIds from "src/locales/messageIds";
import { AppListModel } from "src/store/models/entity/apps/list";
import { ViCodeAppListModel } from "src/store/models/entity/vicodeApp/list";
import { UIViCodeAppAppModel } from "src/store/models/ui/vicodeApp/apps";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const hasAppV2FeatureFlag = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return Boolean(searchParams.get("appV2"));
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const hasAppV1CreationFeatureFlag = () => {
  // todo
  // const searchParams = new URLSearchParams(window.location.search);
  // return Boolean(searchParams.get("AppV1Creation"));
  return true;
};

interface ToolBarContextProps {
  openCreationModal: () => void;
}

const ToolBarContext = React.memo(function ToolBarContext(
  props: ToolBarContextProps
) {
  const { openCreationModal } = props;
  const getContainer = useGetContainer();

  const formatMessage = useFormatMessage();
  const uiViCodeAppAppContainer = getContainer(UIViCodeAppAppModel);

  const isViCodeAppTab = useSelector(
    () => uiViCodeAppAppContainer.state.isViCodeAppTab
  );

  return (
    <AuthorizedV2 permissionKeys={["App_Create"]}>
      {(hasAppV1CreationFeatureFlag() || isViCodeAppTab) && (
        <RestrictionButton
          onClick={openCreationModal}
          type="primary"
          iconName={"icon-add"}
        >
          {formatMessage(messageIds.common.create)}
        </RestrictionButton>
      )}
    </AuthorizedV2>
  );
});

export default React.memo(function ViCodeApp() {
  const getContainer = useGetContainer();
  const theme = useTheme();
  const formatMessage = useFormatMessage();

  const appV1ListContainer = getContainer(AppListModel);
  const vicodeAppListContainer = getContainer(ViCodeAppListModel);

  const uiViCodeAppAppContainer = getContainer(UIViCodeAppAppModel);

  const query = useSelector(() => appV1ListContainer.state.query);

  const applicationInsights = useApplicationInsights();

  const isCreationModalVisible = useSelector(
    () => uiViCodeAppAppContainer.state.isCreationModalVisible
  );

  const isViCodeAppTab = useSelector(
    () => uiViCodeAppAppContainer.state.isViCodeAppTab
  );

  const userEdition = useUserEdition();

  const onOpenCreationModal = useCallback(async () => {
    uiViCodeAppAppContainer.actions.setIsCreationModalVisible.dispatch(true);
  }, [uiViCodeAppAppContainer.actions.setIsCreationModalVisible]);

  const onCloseCreationModal = useCallback(async () => {
    uiViCodeAppAppContainer.actions.setIsCreationModalVisible.dispatch(false);
  }, [uiViCodeAppAppContainer.actions.setIsCreationModalVisible]);

  const setIsViCodeAppTab = useCallback(async () => {
    await uiViCodeAppAppContainer.actions.setIsViCodeAppTab.dispatch(
      !uiViCodeAppAppContainer.state.isViCodeAppTab
    );
    await uiViCodeAppAppContainer.actions.initialize.dispatch({});
  }, [
    uiViCodeAppAppContainer.actions.initialize,
    uiViCodeAppAppContainer.actions.setIsViCodeAppTab,
    uiViCodeAppAppContainer.state.isViCodeAppTab,
  ]);

  useEffect(() => {
    applicationInsights.sendTrackEvent({ name: "Vicode_AccessMenuEvent" });
  }, [applicationInsights]);

  const onSearch = useCallback(
    async (value) => {
      await appV1ListContainer.actions.updateQuery.dispatch(value);
      const _value = cloneDeep(value);
      delete _value.tagIds;
      await vicodeAppListContainer.actions.updateQuery.dispatch(_value);
      await uiViCodeAppAppContainer.actions.initialize.dispatch({});
    },
    [
      appV1ListContainer.actions.updateQuery,
      uiViCodeAppAppContainer.actions.initialize,
      vicodeAppListContainer.actions.updateQuery,
    ]
  );

  return (
    <Fragment>
      <AppMainLayout
        toolsNode={<ToolBarContext openCreationModal={onOpenCreationModal} />}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <ViCodeAppSearch query={query} onSearch={onSearch} />
          {userEdition === "Community" ? null : (
            <Button onClick={setIsViCodeAppTab}>
              <SwapOutlined />
              {formatMessage(messageIds.apps.switchToVersion)}
              {isViCodeAppTab ? "v1" : "v2 beta"}
            </Button>
          )}
        </div>
        <Divider
          css={css`
            margin: 10px 0;
            border-top: 1px solid ${theme.bodyDivider};
          `}
        />
        <ViCodeAppList type="table" />
      </AppMainLayout>

      {isCreationModalVisible ? (
        hasAppV1CreationFeatureFlag() && !isViCodeAppTab ? (
          <AppCreationDialog onCancel={onCloseCreationModal} />
        ) : (
          isViCodeAppTab && (
            <ViCodeAppCreationDialog onCancel={onCloseCreationModal} />
          )
        )
      ) : null}
      <AppV1OperationDialog />
    </Fragment>
  );
});
