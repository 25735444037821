/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  Constants,
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import Axios from "axios";
import {
  EncooFileUploadCallbackInfo,
  EncooFileUploadState,
} from "src/models/file";
import {
  Package,
  PackageCreationData,
  PackageOutline,
  PackageQuery,
  PreloadedVersionFile,
  VersionDetail,
} from "src/models/package";
import {
  PackageVersion,
  PackageVersionCreationData,
  PackageVersionUpdateData,
} from "src/models/packageVersion";
import { EncooBatchResult, EncooListEntity } from "src/models/_shared";
import { isWebUrl } from "src/utils/url";

export const PACKAGE_SERVICE_NAME = "package";
export class PackageServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, PACKAGE_SERVICE_NAME);
  }

  async list(
    departmentId: string,
    pageIndex: number,
    pageSize: number,
    query?: PackageQuery
  ): Promise<EncooListEntity<Package>> {
    const req = this.buildRequestV2({
      url: `/v2/packages`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
        nameOrTag: query?.name ? query?.name : undefined,
        startTime: query?.startTime,
        endTime: query?.endTime,
        orderBy: query?.orderBy,
        isDesc: query?.sortOrder === "descend",
        timeZone: -new Date().getTimezoneOffset(),
      },
      departmentId,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getAll(): Promise<Package[]> {
    const req = this.buildRequestV2({
      url: `/v2/packages`,
      method: "GET",
      query: {
        pageSize: 1000,
      },
    });
    const { body } = await super.sendRequest(req);
    return body.list;
  }

  async getById(packageId: string): Promise<Package> {
    const req = this.buildRequestV2({
      url: `/v2/packages/${packageId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async create(payload: PackageCreationData): Promise<Package> {
    const req = this.buildRequestV2({
      url: `/v2/packages`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async update(pkg: Package, payload: any): Promise<Package> {
    const req = this.buildRequestV2({
      url: `/v2/packages/${pkg.id}`,
      method: "PUT",
      payload,
    });
    if (pkg.eTag) {
      req.headers?.set(Constants.HeaderConstants.IF_MATCH, pkg.eTag);
    }
    return await super.updateInternal(req);
  }

  async delete(packageId: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/packages/${packageId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }

  async batchDelete(packageIds: string[]): Promise<EncooBatchResult> {
    const req = this.buildRequestV2({
      url: `/v2/packages/batchDelete`,
      method: "POST",
      payload: {
        packageIds,
      },
    });
    return await super.updateInternal<EncooBatchResult>(req);
  }

  async listPackageVersion(
    packageId: string,
    pageIndex: number,
    pageSize: number
  ): Promise<EncooListEntity<PackageVersion>> {
    const req = this.buildRequestV2({
      url: `/v2/packages/${packageId}/versions`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getAllPackageVersions(packageId: string): Promise<PackageVersion[]> {
    const req = this.buildRequestV2({
      url: `/v2/packages/${packageId}/versions`,
      method: "GET",
      query: {
        pageSize: 1000,
      },
    });
    const { body } = await this.sendRequest(req);

    const packageVersions = body.list as PackageVersion[];

    return packageVersions;
  }

  async getPackageVersionById(
    packageId: string,
    versionId: string
  ): Promise<PackageVersion> {
    const req = this.buildRequestV2({
      url: `/v2/packages/${packageId}/versions/${versionId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async createPackageVersion(
    packageId: string,
    payload: PackageVersionCreationData
  ): Promise<PackageVersion> {
    const req = this.buildRequestV2({
      url: `/v2/packages/${packageId}/versions`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  async updateCompletedPackage(
    packageId: string,
    eTag: string,
    tags?: string[],
    description?: string
  ): Promise<Package> {
    const req = this.buildRequestV2({
      url: `/v2/packages/${packageId}`,
      method: "PUT",
      payload: {
        eTag,
        tags,
        description,
      },
    });
    return await super.updateInternal(req);
  }
  async updateCompleted(
    packageId: string,
    versionId: string,
    versionUpdateData: PackageVersionUpdateData
  ): Promise<PackageVersion> {
    const req = this.buildRequestV2({
      url: `/v2/packages/${packageId}/versions/${versionId}`,
      method: "PATCH",
      payload: versionUpdateData,
    });
    return await this.updateInternal(req);
  }

  async deletePackageVersion(
    packageId: string,
    versionId: string
  ): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/packages/${packageId}/versions/${versionId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }

  async getPackageVersionDownloadUri(
    packageId: string,
    versionId: string
  ): Promise<string> {
    const req = this.buildRequestV2({
      url: `/v2/packages/${packageId}/versions/${versionId}/downloaduri`,
      method: "GET",
    });
    return await this.sendRequest(req).then((res) => {
      if (res.status === 200) {
        return res.body.uri;
      }
      return undefined;
    });
  }

  async updatePreloadedVersionFiles(
    tempId: string,
    fileName: string
  ): Promise<VersionDetail> {
    const req = this.buildRequestV2({
      url: `/v2/preloadedVersionFiles/${tempId}`,
      method: "PATCH",
      payload: {
        uploadComplete: true,
        fileName: fileName,
      },
    });
    return await this.sendRequest(req).then((res) => {
      if (res.status === 200) {
        return res.body;
      }
      return undefined;
    });
  }

  async getOutLineTree(
    packageId: string,
    versionId: string
  ): Promise<PackageOutline> {
    const req = this.buildRequestV2({
      url: `/v2/packages/${packageId}/versions/${versionId}/outline`,
      method: "GET",
    });
    return await this.createInternal(req);
  }

  async getPackageShareToDepartments(packageId: string): Promise<string[]> {
    const req = this.buildRequestV2({
      url: `/v2/packages/${packageId}/share/departments`,
      method: "GET",
    });
    const { body } = await super.sendRequest(req);
    return body.list;
  }

  async updatePackageShareToDepartments(
    packageId: string,
    departmentIds: string[]
  ): Promise<void> {
    const req = this.buildRequestV2({
      url: `/v2/packages/${packageId}/share/departments`,
      method: "PUT",
      payload: { departmentIds },
    });
    return await super.updateInternal(req);
  }

  async createPreloadedUri(fileSize: number): Promise<PreloadedVersionFile> {
    const req = this.buildRequestV2({
      url: `/v2/preloadedVersionFiles/common?version=2.1`,
      method: "POST",
      payload: { fileSize },
    });
    const { body } = await this.sendRequest(req);

    return body;
  }

  public async uploadPackage(
    file: File,
    processCallback?: (info: EncooFileUploadCallbackInfo) => void
  ): Promise<void> {
    const totalSize = file.size;
    const fileFullName = file.name;

    await processCallback?.({
      fullName: fileFullName,
      nodeName: file.name,
      state: EncooFileUploadState["Preparing"],
      percent: 0,
      totalSize,
    });

    try {
      const preloadedVersionFile = await this.createPreloadedUri(file.size);
      const id = preloadedVersionFile.id;

      if (preloadedVersionFile.multiparts === false) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const { headers, uri } = preloadedVersionFile.channel!;
        await Axios({
          method: "PUT",
          headers,
          url: uri,
          data: file,
        });
        await this.updatePreloadedVersionFiles(id, fileFullName);
        await processCallback?.({
          fullName: fileFullName,
          nodeName: file.name,
          state: EncooFileUploadState["Completed"],
          percent: 1,
          totalSize,
        });
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const uploadUris = preloadedVersionFile.multiUploadUri!;

      const partUris = uploadUris.filter(
        (uri) => uri.type === "MultiPart" || uri.type === "SingleFile"
      );
      const combineUri = uploadUris.find(
        (uri) => uri.type === "CombineByConsole" || uri.type === "Combine"
      );

      await processCallback?.({
        fullName: fileFullName,
        nodeName: file.name,
        state: EncooFileUploadState["Uploading"],
        percent: 0,
        totalSize,
      });

      let uploadCompleteCount = 0;

      const partResponseDatas: Record<string, string>[] = await Promise.all(
        partUris.map(async (uri) => {
          const response = await Axios({
            method: uri.verb,
            headers: uri.headers,
            url: uri.uri,
            transformRequest: (data, headers) => {
              if (headers) {
                headers["Content-Type"] = "application/octet-stream";
              }

              return data;
            },
            data:
              uri.type === "SingleFile"
                ? file
                : file.slice(
                    uri.partContent.startPos - 1,
                    uri.partContent.startPos - 1 + uri.partContent.length
                  ),
          });
          const partData: Record<string, string> = {};
          uri.partResponseDatas?.forEach((data) => {
            if (data.fromType === "Header") {
              partData[data.name] =
                response.headers[data.fromValue] ??
                response.headers[data.fromValue.toLocaleLowerCase()];
            }
          });

          uploadCompleteCount++;

          processCallback?.({
            fullName: fileFullName,
            nodeName: file.name,
            state: EncooFileUploadState["Uploading"],
            percent: uploadCompleteCount / partUris.length,
            totalSize,
          });

          return partData;
        })
      );

      await processCallback?.({
        fullName: fileFullName,
        nodeName: file.name,
        state: EncooFileUploadState["Compoining"],
        percent: 1,
        totalSize,
      });

      if (combineUri) {
        let data: Record<string, unknown> | string | undefined = {};

        if (combineUri.type === "Combine") {
          data = combineUri.combineContent;
        } else {
          let combineByConsoleContent = combineUri.combineByConsoleContent;
          partResponseDatas.forEach((data) => {
            combineByConsoleContent = { ...combineByConsoleContent, ...data };
          });
          data = combineByConsoleContent;
        }
        const url = isWebUrl(combineUri.uri)
          ? combineUri.uri
          : `${this.environment.endpoint}/${combineUri.uri}`;
        if (combineUri.type === "Combine") {
          await Axios({
            method: combineUri.verb,
            headers: combineUri.headers,
            url,
            data,
          });
        } else {
          const req = this.buildRequestV2({
            url,
            payload: data,
            method: combineUri.verb,
            headers: {
              "content-type":
                combineUri?.headers?.contentType ?? "application/json",
            },
          });
          await this.sendRequest(req);
        }
      }

      await this.updatePreloadedVersionFiles(id, fileFullName);

      await processCallback?.({
        fullName: fileFullName,
        nodeName: file.name,
        state: EncooFileUploadState["Completed"],
        percent: 1,
        totalSize,
      });
    } catch (error) {
      await processCallback?.({
        fullName: fileFullName,
        nodeName: file.name,
        state: EncooFileUploadState["Failed"],
        percent: 0,
        totalSize,
      });

      throw error;
    }
  }
  async getCompanyPackages(
    pageIndex: number,
    pageSize: number
  ): Promise<EncooListEntity<Package>> {
    const req = this.buildRequestV2({
      url: `/v2/CompanyPackages`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
}
