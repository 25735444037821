import "core-js";
import { enableES5 } from "immer";
import Oidc from "oidc-client";
import React from "react";
import "react-app-polyfill/ie11";
import ReactDOM from "react-dom";
import AppWrapper from "src/AppWrapper";
import App from "src/containers/App";
import "src/iconFont";
import * as serviceWorker from "src/serviceWorker";

if (!window._settings) {
  window._settings = {} as typeof window["_settings"];
}

const searchParams = new URLSearchParams(window.location.search);
(window._settings.engine as string | undefined) =
  searchParams.get("engine") ?? undefined;
window._settings.corpId = searchParams.get("corpId") ?? undefined;

enableES5();
if (window.location.pathname?.endsWith("callback")) {
  import("src/containers/callback/Callback");
} else if (window.location.pathname?.endsWith("silent_renew")) {
  new Oidc.UserManager({
    response_mode: "query",
    scope: "openid profile apigateway offline_access",
    userStore: new Oidc.WebStorageStateStore({
      store: window.sessionStorage,
    }),
  }).signinSilentCallback();
} else {
  ReactDOM.render(
    <React.StrictMode>
      <AppWrapper>
        <App />
      </AppWrapper>
    </React.StrictMode>,
    document.getElementById("root")
  );
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}
