import { AssetValueType } from "@encoo-web/encoo-lib/types";
import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";

export const AssetUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        valueType: "Credential" as AssetValueType,
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setValueType: (value: AssetValueType) => {
          this.state.valueType = value;
        },
      };
    }
  }
);
