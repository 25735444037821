/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Button, Select, Tooltip } from "antd";
import { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormatMessage, useGetContainer } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { AppTag } from "src/models/appV2";
import { AppTagEntityModel } from "src/store/models/entity/appTag/entity";
import { AppTagHelperModel } from "src/store/models/entity/appTag/helper";
import { cssTextTruncate } from "src/styles/text";

const { Option } = Select;

export default memo(function AppTags({
  tags,
  tagType = "divider",
  className,
}: {
  tags?: AppTag[];
  tagType?: "button" | "divider";
  className?: string;
}) {
  if (tags?.length) {
    const tagStr = tags.map((item) => item.name).join(" | ");

    if (tagType === "divider") {
      return (
        <Tooltip title={tagStr}>
          <div
            css={[
              cssTextTruncate,
              css`
                line-height: normal;
              `,
            ]}
            className={className}
          >
            {tagStr}
          </div>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={tagStr}>
          <div css={cssTextTruncate}>
            {tags.map((item) => {
              return (
                <Button
                  css={css`
                    padding: 2px 7px;
                    background: #f2f3f5;
                    border-radius: 8px;
                    font-size: 12px;
                    color: #3d4966;
                    height: auto;
                    line-height: 1;
                    border: none;
                    margin-right: 10px;
                  `}
                  key={item.id}
                >
                  {item.name}
                </Button>
              );
            })}
          </div>
        </Tooltip>
      );
    }
  } else {
    return null;
  }
});

export const AppTagsAssociated = memo(function AppTagsAssociated({
  initialValue,
  onChange,
}: {
  initialValue?: string[];
  onChange?: (value?: string[]) => void;
}) {
  const getContainer = useGetContainer();
  const formatMessage = useFormatMessage();
  const appTagEntity = getContainer(AppTagEntityModel);
  const appTagHelper = getContainer(AppTagHelperModel);

  const appTags = useSelector(() => appTagEntity.getters.items);

  useEffect(() => {
    appTagHelper.actions.getAll.dispatch({});
  }, [appTagHelper.actions.getAll]);

  return (
    <Select
      mode="tags"
      defaultValue={initialValue}
      onChange={onChange}
      showSearch
      autoClearSearchValue
      maxTagCount="responsive"
      placeholder={formatMessage(messageIds.apps.inputTag)}
      optionFilterProp="children"
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      css={css`
        width: 100%;
      `}
    >
      {appTags.map((item) => (
        <Option value={item.id} key={item.id}>
          {item.name}
        </Option>
      ))}
    </Select>
  );
});
