// eslint-disable-next-line import/no-anonymous-default-export
export default {
  aiManagement: {
    title: "AI Service Management",
    entityTypeName: "TODO:",
    name: "TODO: Model Name",
    service: "service",
    introduction:
      "View free service statistics and configurable accounts for each AI platform for easy use directly in the process.",
    balanceWarningDialog: {
      name: "Balance Notification",
      trigger:
        "When any ENCOO AI SAAS Service balance is less than or equal to",
      email: "Automatically send email to",
      times: "times",
    },
    notice: "Attention",
    noticeContent:
      "This service is only applicable to ENCOO AI SAAS Service platform.",
    chart: {
      title: "Statistics",
    },
    table: {
      title: "AI Platform",
      amount: "Total Amount",
      minimum: "Minimum",
      maximum: "Maximum",
      necessaryParameters: "Necessary Parameters",
      columns: {
        aiService: "AI Service",
        remainingAmount: "Rest",
        usage: "Used",
      },
    },
    button: {
      config: "ENCOO AI SAAS Service Configuration",
      balanceNotification: "Balance Notification",
    },
  },
};
