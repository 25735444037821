import { createModel } from "nyax";
import { Runinstance } from "src/models/runInstance";
import { RunInstanceEntityModel } from "src/store/models/entity/runInstance/entity";
import { createHelperModel } from "src/store/models/entity/_shared";
import { handleErrorNotFound } from "src/utils/error";

export const RunInstanceHelperModel = createModel(
  class extends createHelperModel<Runinstance>({
    setItems: (getContainer, items) =>
      getContainer(RunInstanceEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(RunInstanceEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(RunInstanceEntityModel).state.byId[id],
  }) {
    public effects() {
      return {
        ...super.effects(),
        readByJobIds: async (payload: {
          jobIds: Array<string>;
          force?: boolean;
        }) => {
          const { jobIds, force } = payload;
          return await this._readByParentIds({
            parentIds: jobIds,
            getAllAction: (parentId) => {
              return this.dependencies.serviceClient.runInstance.getAll(
                parentId
              );
            },
            getEntityParentId: (entity: Runinstance) => entity.jobId,
            force,
          });
        },
        getById: async (runInstanceId: string) => {
          return this._readById({
            id: runInstanceId,
            getByIdAction: () =>
              this.dependencies.serviceClient.runInstance.getById(
                runInstanceId
              ),
          });
        },
        getLogsByRunInstanceId: async (payload: { runinstanceId: string }) => {
          try {
            const { runinstanceId } = payload;
            const logs =
              await this.dependencies.serviceClient.runInstance.getAllLogsById(
                runinstanceId
              );
            return logs;
          } catch (error) {
            handleErrorNotFound(error);
          }
        },
        getLogFileByFileId: async (payload: {
          runinstanceId: string;
          robotId: string;
          fileName: string;
        }) => {
          const { runinstanceId, robotId, fileName } = payload;
          const fileData =
            await this.dependencies.serviceClient.runInstance.getLogFileByFileId(
              runinstanceId,
              robotId,
              fileName
            );
          return fileData;
        },
        getResultVideoRecord: async (payload: { runinstanceId: string }) => {
          const { runinstanceId } = payload;
          const data =
            await this.dependencies.serviceClient.runInstance.getResultVideoRecord(
              runinstanceId
            );
          return data;
        },
        getResult: async (payload: { runinstanceId: string }) => {
          const { runinstanceId } = payload;
          const data =
            await this.dependencies.serviceClient.runInstance.getResult(
              runinstanceId
            );
          return data;
        },
        getAccessKey: async (payload: { runinstanceId: string }) => {
          const { runinstanceId } = payload;
          const data =
            await this.dependencies.serviceClient.runInstance.getAccessKey(
              runinstanceId
            );
          return data;
        },
      };
    }
  }
);
