import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { LamossModelConfig, LamossRuleConfig } from "src/models/lamoss";

export const LAMOSS_SERVICE_NAME = "lamoss";
export class LamossConfigServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, LAMOSS_SERVICE_NAME);
  }
  async getModelConfig(): Promise<LamossModelConfig> {
    const req = this.buildRequestV2({
      url: `/v2/lamoss/Mgmt/LLMConfig`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
  async getRuleConfig(): Promise<LamossRuleConfig> {
    const req = this.buildRequestV2({
      url: `/v2/lamoss/Mgmt/RuleConfig`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
  async updateRuleConfig(data: LamossRuleConfig): Promise<LamossRuleConfig> {
    const req = this.buildRequestV2({
      url: `/v2/lamoss/Mgmt/RuleConfig`,
      method: "PATCH",
      payload: data,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
}
