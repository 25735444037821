import { HttpMethods } from "@encoo-web/encoo-lib/types";
// import { PermissionValue } from "@encoo-web/encoo-ui/types/models/permissionSetting";

export type EncooFileType = "File" | "Directory";
export type EncooFileUploadPartResponseDataFromType = "Header";
export enum EncooFileUploadState {
  "Preparing" = "Preparing",
  "Uploading" = "Uploading",
  "Compoining" = "Compoining",
  "Completed" = "Completed",
  "Failed" = "Failed",
}

export interface EncooFileUploadCallbackInfo {
  nodeName: string;
  fullName: string;
  state: EncooFileUploadState;
  totalSize: number;
  percent: number;
}

export interface EncooFile {
  id: string;
  parentId?: string;
  directory: string;
  nodeName: string;
  fullName: string;
  size: number;
  contentType: string;
  fileType: EncooFileType;
  createdAt: string;
  createdByName: string;
  resourceType: "File";
  //   permissionValue?: PermissionValue;
  // TODO: PermissionValue在未来应该已经改成PermissionValues: string[]了为了对应零散且具体的权限点
  permissionValue?: string;
  permissionValues?: Array<string>;
  departmentId: string;
  isRoot: boolean;
  rootId: string;
  dataConnectionId: string;
}

export interface EncooFileUploadPartContent {
  startPos: number;
  length: number;
  partNumber: number;
}

export interface EncooFileUploadPartResponseData {
  name: string;
  fromType: EncooFileUploadPartResponseDataFromType;
  fromValue: string;
}
export enum EncooFileUploadUriEnum {
  singleFile = "SingleFile",
  multiPart = "MultiPart",
  combine = "Combine",
}
export interface EncooFileUploadUri {
  uri: string;
  headers?: Record<string, string>;
  type: EncooFileUploadUriEnum | string;
  verb: HttpMethods;
  partContent: EncooFileUploadPartContent;
  combineContent?: string;
  combineByConsoleContent?: Record<string, string>;
  partResponseDatas?: EncooFileUploadPartResponseData[];
}
