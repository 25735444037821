import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { CompanyListModel } from "src/store/models/entity/company/list";
import { UserTenantUIModel } from "src/store/models/ui/tenant/userTenant";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";

export const CompanyUIModel = createModel(
  class extends ModelBase {
    private get _loginUserUiContainer() {
      return this.getContainer(LoginUserUIModel);
    }
    private get _companyListContainer() {
      return this.getContainer(CompanyListModel);
    }

    private get _userTenantUIContainer() {
      return this.getContainer(UserTenantUIModel);
    }

    public initialState() {
      return {};
    }

    public reducers() {
      return {
        ...super.reducers(),
      };
    }

    public effects() {
      return {
        getAllCompany: async () => {
          await Promise.all([
            this._companyListContainer.actions.initialIterator.dispatch({}),
            this._userTenantUIContainer.actions.getUserTenants.dispatch(null),
          ]);
        },
      };
    }
  }
);
