import { createModel } from "nyax";
import { AppTemplateVersion } from "src/models/appTemplate";
import { AppTemplateVersionEntityModel } from "src/store/models/entity/appTemplateVersion/entity";
import { createUIListV2Model } from "src/store/models/ui/_shared";

export const AppTemplateVersionListModel = createModel(
  class extends createUIListV2Model<AppTemplateVersion>({
    setItems: (getContainer, items) =>
      getContainer(AppTemplateVersionEntityModel).actions.setItems.dispatch(
        items
      ),
    getItems: (getContainer) =>
      getContainer(AppTemplateVersionEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(AppTemplateVersionEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initialIterator: async (payload: { id: string; force?: boolean }) => {
          const { id } = payload;
          this._initial({
            initialAction: () =>
              this.dependencies.serviceClient.appTemplate.getVersionList(id),
          });
        },
      };
    }
  }
);
