import {
  Auditlog,
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { ObjectType } from "src/models/auditlog";

export const OCR_SERVICE_NAME = "ocr";
export class AuditlogSearchServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, OCR_SERVICE_NAME);
  }

  //模块查询
  async getObjectTypes(objectTypeDisplayName?: string): Promise<ObjectType[]> {
    const req = this.buildRequestV2({
      url: `/v1/Objecttypes`,
      method: "GET",
      query: {
        objectTypeDisplayName,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  //用户查询
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async getUserId(Keyword?: string) {
    const req = this.buildRequestV2({
      url: `/v3/companyusers`,
      method: "GET",
      query: {
        Keyword,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async getlist(
    beforetime: string,
    endtime: string = new Date().toISOString(),
    AuditObjectType?: string,
    UserId?: string
  ) {
    // By default use 1 month for query.
    if (!beforetime) {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 1);
      beforetime = currentDate.toISOString();
    }
    const req = this.buildRequestV2({
      url: `/v2/auditlogs`,
      method: "GET",
      query: {
        beforetime,
        endtime,
        AuditObjectType,
        UserId,
      },
    });
    return await super.listInternally<Auditlog>(req);
  }
}
