import {
  Constants,
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import {
  DataTable,
  DataTableCreationData,
  DataTableQuery,
} from "src/models/dataTable";
import { EncooListEntity } from "src/models/_shared";

export const DATATABLE_SERVICE_NAME = "dataTable";
export class DataTableService extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, DATATABLE_SERVICE_NAME);
  }

  async list(
    departmentId: string,
    pageIndex: number,
    pageSize: number,
    query?: DataTableQuery
  ): Promise<EncooListEntity<DataTable>> {
    const req = this.buildRequestV2({
      url: `/v3/datacenter/tables`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
        keyword: query?.keyword,
        startTime: query?.startTime,
        endTime: query?.endTime,
        isDesc: query?.isDesc,
      },
      departmentId,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getById(dataTableId: string): Promise<DataTable> {
    const req = this.buildRequestV2({
      url: `/v3/datacenter/tables/${dataTableId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async create(payload: DataTableCreationData): Promise<DataTable> {
    const req = this.buildRequestV2({
      url: `/v3/datacenter/tables`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  async update(
    dataTable: Partial<DataTable> & Pick<DataTable, "id">
  ): Promise<DataTable> {
    const req = this.buildRequestV2({
      url: `/v3/datacenter/tables/${dataTable.id}`,
      method: "PATCH",
      payload: dataTable,
    });
    if (dataTable.eTag) {
      req.headers?.set(Constants.HeaderConstants.IF_MATCH, dataTable.eTag);
    }
    return await super.updateInternal(req);
  }

  async delete(dataTableId: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v3/datacenter/tables/${dataTableId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }
}
