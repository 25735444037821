import { createModel } from "nyax";
import { WorkflowLayout, WorkflowLayoutQuery } from "src/models/workflowLayout";
import { WorkflowLayoutEntityModel } from "src/store/models/entity/workflowLayout/entity";
import { WorkflowLayoutSideUIModel } from "src/store/models/ui/workflowLayout/workflowLayoutSide";
import { createUITableWithPermissionModel } from "src/store/models/ui/_shared";

export type WorkflowLayoutWithPermission = WorkflowLayout & {
  permissionValues: string[];
};

export const WorkflowLayoutTableUIModel = createModel(
  class extends createUITableWithPermissionModel<WorkflowLayout, WorkflowLayoutQuery>({
    setItems: (getContainer, items) =>
      getContainer(WorkflowLayoutEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(WorkflowLayoutEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(WorkflowLayoutEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      const superEffects = super.effects();
      return {
        ...superEffects,
        updateQuery: async (params: WorkflowLayoutQuery) => {
          await superEffects.updateQuery(params);
          await this.getContainer(WorkflowLayoutSideUIModel).actions.setQuery.dispatch(
            this.state.query
          );
        },
        initial: async (payload: { departmentId: string }) => {
          const { departmentId } = payload;
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query: WorkflowLayoutQuery
            ) =>
              this.dependencies.serviceClient.workflowLayout.list(
                departmentId,
                pageIndex,
                pageSize,
                query
              ),
          });
        },
      };
    }
  }
);
