// eslint-disable-next-line import/no-anonymous-default-export
export default {
  auditlog: {
    introduction:
      "本页面用于展示控制台各用户的详细操作记录，便于进行审计及跟踪。",
    createdAt: "操作时间",
    auditObjectTypeDisplayName: "操作详情",
    auditObjectType: "操作模块",
    userDisplayName: "操作用户",
    startTime: "开始时间",
    endTime: "结束时间",
  },
};
