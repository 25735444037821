/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Space, Tooltip } from "antd";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import Title from "src/components/Title";
import AppBreadcrumb from "src/containers/layout/components/AppBreadcrumb";
import { useFormatMessage, useGetContainer, useTheme } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { APP_MENUS } from "src/routes/menu";
import { RouterModel } from "src/store/models/router";
import { EnvironmentModel } from "src/store/models/ui/environment/environment";
import { QuotaUIModel } from "src/store/models/ui/quota/quota";
import { cssTextTruncate } from "src/styles/text";

export interface MainLayoutHeaderProps {
  toolsNode?: React.ReactNode;
}

const MainLayoutHeader = React.memo(function MainLayoutHeader(
  props: MainLayoutHeaderProps
) {
  const { toolsNode } = props;

  const getContainer = useGetContainer();
  const formatMessage = useFormatMessage();
  const theme = useTheme();

  const router = getContainer(RouterModel);
  const routeType = useSelector(() => router.getters.currentRouteInfo.type);

  const environmentContainer = getContainer(EnvironmentModel);
  const environmentEdition = useSelector(
    () => environmentContainer.state.environmentEdition
  );

  const quota = getContainer(QuotaUIModel);
  const quotaCount = useSelector(() => quota.state.quotaCount);
  const quotaType = useSelector(() => quota.getters.getQuotaType);
  const totalQuota = useSelector(() => quota.getters.totalQuota);
  const switchType = useSelector(() => quota.getters.switchType);

  const currentPageInfo = useMemo(
    () => APP_MENUS[routeType] ?? undefined,
    [routeType]
  );
  const pageName = useMemo(
    () =>
      currentPageInfo?.name ? formatMessage(currentPageInfo.name) : undefined,
    [currentPageInfo.name, formatMessage]
  );

  const description = useMemo(
    () =>
      currentPageInfo?.description
        ? formatMessage(currentPageInfo.description)
        : undefined,
    [currentPageInfo.description, formatMessage]
  );

  const JudgeIncluding = useMemo(() => {
    return _.includes(quotaType, switchType);
  }, [quotaType, switchType]);

  const JudgeCurrentQuota = useCallback(async () => {
    if (JudgeIncluding) {
      await quota.actions.getQuotaCount.dispatch(switchType);
    } else {
      await quota.actions.setIsOutOfQuota.dispatch(false);
    }
  }, [
    JudgeIncluding,
    quota.actions.getQuotaCount,
    quota.actions.setIsOutOfQuota,
    switchType,
  ]);

  useEffect(() => {
    JudgeCurrentQuota();
  }, [JudgeCurrentQuota]);

  return (
    <div
      css={css`
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 16px;
      `}
    >
      <div
        css={css`
          max-width: 1000px;
        `}
      >
        <Space size={10}>
          <Title
            css={css`
              margin-bottom: 8px;
            `}
          >
            {pageName}
          </Title>
          {JudgeIncluding && (
            <div
              css={css`
                margin-bottom: 8px;
              `}
            >
              (&nbsp;
              <span
                css={css`
                  color: #ebb935;
                `}
              >
                {formatMessage(messageIds.common.quota)}: {quotaCount}/
                {totalQuota}
              </span>
              &nbsp;)
            </div>
          )}
        </Space>
        <Tooltip
          title={`${description}${
            environmentEdition === "Saas" &&
            routeType === "job" &&
            formatMessage(messageIds.job.introductionExtra)
          }`}
        >
          <div
            css={css`
              margin-left: 10px;
              color: ${theme.bodySubtext};
              ${cssTextTruncate};
            `}
          >
            {description}
            {environmentEdition === "Saas" &&
              routeType === "job" &&
              formatMessage(messageIds.job.introductionExtra)}
          </div>
        </Tooltip>
      </div>
      {toolsNode}
    </div>
  );
});

export interface AppMainLayoutProps {
  children?: React.ReactNode;
  toolsNode?: React.ReactNode;
}

export default React.memo(function AppMainLayout(props: AppMainLayoutProps) {
  const { toolsNode, children } = props;
  const theme = useTheme();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      `}
    >
      <div
        css={css`
          padding: 10px 16px 20px 16px;
          border-bottom: 1px solid ${theme.bodyDivider};
          background-color: ${theme.bodyBackground};
        `}
      >
        <AppBreadcrumb />
        <MainLayoutHeader toolsNode={toolsNode} />
      </div>
      <div
        css={css`
          display: flex;
          padding: 16px;
          flex-grow: 1;
          min-height: 0;
          min-width: 0;
          background-color: ${theme.bodyFrameBackground};
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            padding: 20px;
            flex-grow: 1;
            min-height: 0;
            min-width: 0;

            border: 1px solid ${theme.bodyDivider};
            background-color: ${theme.bodyBackground};

            overflow: auto;
          `}
        >
          {children}
        </div>
      </div>
    </div>
  );
});
