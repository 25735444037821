import { createModel } from "nyax";
import { Trigger } from "src/models/workflow";
import { EncooSort } from "src/models/_shared";
import { TriggerEntityModel } from "src/store/models/entity/trigger/entity";
import { createUITableWithPermissionModel } from "src/store/models/ui/_shared";

export const WorkflowTriggerUIModel = createModel(
  class extends createUITableWithPermissionModel<Trigger, EncooSort>({
    setItems: (getContainer, items) =>
      getContainer(TriggerEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(TriggerEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(TriggerEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public initialState() {
      return {
        ...super.initialState(),
        isCreateModalVisible: false,
      };
    }

    public reducers() {
      return {
        ...super.reducers(),
        setCreateModalVisible: (value: boolean) => {
          this.state.isCreateModalVisible = value;
        },
      };
    }

    public effects() {
      return {
        ...super.effects(),
        initialIterator: async (payload: { workflowId: string }) => {
          const { workflowId } = payload;
          this._initial({
            initialAction: (pageIndex: number, pageSize: number) =>
              this.dependencies.serviceClient.workflow.listAllTriggers(
                workflowId,
                pageIndex,
                pageSize
              ),
          });
        },
      };
    }
  }
);
