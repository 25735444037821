import {
  createSelector,
  GetContainer,
  mergeModels,
  mergeSubModels,
} from "nyax";
import { filter, map, pairwise } from "rxjs/operators";
import messageIds from "src/locales/messageIds";
import { EncooListEntity, EncooSort } from "src/models/_shared";
import { createItemsReadWriteModel } from "src/store/itemsReadWrite";
import { ModelBase } from "src/store/ModelBase";
import {
  createListModel,
  createListV2Model,
} from "src/store/models/entity/_shared";
import { DataPermissionEntityModel } from "src/store/models/entity/dataPermission/entity";
import { DataPermissionHelperModel } from "src/store/models/entity/dataPermission/helper";

// 假翻页模式，等后端全部改造完后，废弃
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createUIListModel = function <
  TEntity extends { id: string; resourceType: string }
>(payload: {
  setItems: (
    context: GetContainer,
    items: (string | TEntity)[]
  ) => Promise<void>;
  getItems: (getContainer: GetContainer) => TEntity[];
  getItem: (getContainer: GetContainer, id: string) => TEntity | undefined;
  getItemId: (entity: TEntity) => string;
}) {
  return class extends createListModel<TEntity>({
    ...payload,
    onLoadedItems: async (getContainer, items) => {
      const dataPermissionHelper = getContainer(DataPermissionHelperModel);
      const dataPermissionPayload = items.map((item: TEntity) => {
        const { resourceType, id } = item;
        return { resourceType, id };
      });
      await dataPermissionHelper.actions.getAll.dispatch(dataPermissionPayload);
    },
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        dataSourceWithPermission: createSelector(
          (): TEntity[] => this.getters.dataSource,
          () => this.getContainer(DataPermissionEntityModel).state.byId,
          (items, byId) =>
            items.map((item) => ({
              ...item,
              permissionValues: byId[item.id]?.permissionValues ?? [],
            }))
        ),
      };
    }
  };
};

const DEFAULT_PAGE_SIZE = 20;
// 支持真翻页
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createUIListBaseModel = function <
  // eslint-disable-next-line @typescript-eslint/ban-types
  TEntity extends object,
  UQuery extends EncooSort
>(payload: {
  setItems: (
    context: GetContainer,
    items: (string | TEntity)[]
  ) => Promise<void>;

  getItemId: (entity: TEntity) => string;
  onLoadedItems?: (
    getContainer: GetContainer,
    items: TEntity[]
  ) => Promise<void>;
}) {
  const { setItems, getItemId, onLoadedItems } = payload;
  return class BaseListModel extends mergeModels(
    ModelBase,
    mergeSubModels({
      _rw: createItemsReadWriteModel<TEntity>({
        getItemId: (item) => getItemId(item),
        setItems: ({ getContainer }, items) => setItems(getContainer, items),
      }),
    })
  ) {
    protected _initialAction?: (
      pageIndex: number,
      pageSize: number,
      query: UQuery
    ) => Promise<EncooListEntity<TEntity>>;

    protected async _saveEntity(items: TEntity[], force?: boolean) {
      const { ids: beginReadIds, timestamp } =
        await this.actions._rw.beginRead.dispatch({
          ids: items.map((item) => getItemId(item)),
          force,
        });

      try {
        if (beginReadIds.length > 0) {
          await this.actions._rw.endRead.dispatch({
            items: items,
            timestamp,
          });
        }
      } catch (error) {
        await this.actions._rw.endRead.dispatch({
          items: [],
          timestamp,
        });
        throw error;
      }
    }

    public initialState() {
      return {
        ...super.initialState(),
        currentPageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE,
        totalCount: null as number | null,
        dataIndexs: {} as Record<number, string[] | undefined>,
        isLoading: false,
        query: {} as UQuery,
      };
    }

    public reducers() {
      return {
        ...super.reducers(),

        setCurrentPageIndex: (pageIndex: number) => {
          this.state.currentPageIndex = pageIndex;
        },
        setTotalCount: (totalCount: number | null) => {
          this.state.totalCount = totalCount;
        },
        setDataIndexs: (indexs: Record<number, string[] | undefined>) => {
          this.state.dataIndexs = indexs;
        },
        setIsLoading: (isLoading: boolean) => {
          this.state.isLoading = isLoading;
        },
        setQuery: (query: UQuery) => {
          this.state.query = query;
        },
        setPageSize: (value: number) => {
          this.state.pageSize = value;
        },
      };
    }

    public selectors() {
      return {
        ...super.selectors(),

        pageSize: (): number => this.state.pageSize,
        currentPageNumber: createSelector(
          () => this.state.currentPageIndex,
          (pageIndex) => pageIndex + 1
        ),
        maxPageIndex: createSelector(
          () => this.state.totalCount,
          (): number => this.getters.pageSize,
          (totalCount, pageSize) =>
            totalCount ? Math.ceil(totalCount / pageSize) - 1 : null
        ),
      };
    }

    protected async _initial(payload: {
      initialAction: (
        pageIndex: number,
        pageSize: number,
        query: UQuery
      ) => Promise<EncooListEntity<TEntity>>;
    }) {
      const { initialAction } = payload;

      await this.actions.setTotalCount.dispatch(null);
      await this.actions.setDataIndexs.dispatch([]);

      this._initialAction = initialAction;

      await this.actions.refresh.dispatch({});
    }

    public effects() {
      return {
        ...super.effects(),
        updateQuery: async (params: UQuery) => {
          await this.actions.setQuery.dispatch({
            ...this.state.query,
            ...params,
          });
          await this.actions.refresh.dispatch({});
        },

        refresh: async () => {
          if (this._initialAction) {
            await this.actions.changePage.dispatch({
              pageNumber: 1,
              pageSize: this.getters.pageSize,
            });
          }
        },

        changePage: async (payload: {
          pageNumber: number;
          pageSize: number;
        }) => {
          const { pageNumber, pageSize } = payload;
          let pageIndex = pageNumber - 1;

          if (
            this.getters.maxPageIndex !== null &&
            pageIndex > this.getters.maxPageIndex
          ) {
            return;
          }

          try {
            await this.actions.setIsLoading.dispatch(true);
            let list = await this._initialAction?.(
              pageIndex,
              pageSize,
              this.state.query
            );

            // 参数的页码大于总页码的情况，调整页面到最后一页，再请求
            if (list?.list.length === 0 && list.count > 0) {
              pageIndex = Math.ceil(list.count / pageSize) - 1;

              list = await this._initialAction?.(
                pageIndex,
                pageSize,
                this.state.query
              );
            }

            const entites = list?.list ?? [];
            await onLoadedItems?.(this.getContainer, entites);

            const ids = entites.map((item) => getItemId(item));
            const dataIndexs = { ...this.state.dataIndexs };
            dataIndexs[pageIndex] = ids;
            await this.actions.setDataIndexs.dispatch(dataIndexs);
            await this._saveEntity(entites, true);
            await this.actions.setTotalCount.dispatch(list?.count ?? null);
            await this.actions.setCurrentPageIndex.dispatch(pageIndex);
            await this.actions.setPageSize.dispatch(pageSize);
          } finally {
            await this.actions.setIsLoading.dispatch(false);
          }
        },
      };
    }
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createUITableBaseModel = function <
  TEntity extends { id: string },
  UQuery extends EncooSort
>(payload: {
  setItems: (
    context: GetContainer,
    items: (string | TEntity)[]
  ) => Promise<void>;
  getItems: (getContainer: GetContainer) => TEntity[];
  getItem: (getContainer: GetContainer, id: string) => TEntity | undefined;
  getItemId: (entity: TEntity) => string;
  onLoadedItems?: (
    getContainer: GetContainer,
    items: TEntity[]
  ) => Promise<void>;
}) {
  const { getItems, getItem, ...otherPayload } = payload;
  return class extends createUIListBaseModel<TEntity, UQuery>(otherPayload) {
    public selectors() {
      return {
        ...super.selectors(),
        dataSource: createSelector(
          () => this.state.dataIndexs,
          () => getItems(this.getContainer),
          () => this.state.currentPageIndex,
          (dataIndexs, items, pageIndex) => {
            const pageData: TEntity[] = [];
            const ids = dataIndexs[pageIndex];
            ids?.forEach((id) => {
              const entity = getItem(this.getContainer, id);
              if (entity) {
                pageData.push(entity);
              }
            });

            return pageData;
          }
        ),
        $onChange: createSelector(
          () => (page: number, pageSize: number) =>
            this.actions.changePage.dispatch({ pageNumber: page, pageSize })
        ),
        pagination: createSelector(
          (): number => this.getters.currentPageNumber,
          (): number => this.getters.pageSize,
          (): number => this.state.totalCount ?? 0,
          (): ((page: number, pageSize: number) => void) =>
            this.getters.$onChange,
          (current, pageSize, total, onChange) => {
            return {
              current,
              pageSize,
              total,
              onChange,
              showTotal: (total: number) =>
                this.dependencies.locale.intl.formatMessage(
                  { id: messageIds.common.totalPage },
                  { total }
                ),
              showSizeChanger: true,
            };
          }
        ),
      };
    }

    public effects() {
      return {
        ...super.effects(),

        refresh: async () => {
          if (this._initialAction) {
            await this.actions.changePage.dispatch({
              pageNumber: this.state.currentPageIndex + 1,
              pageSize: this.getters.pageSize,
            });
          }
        },
      };
    }

    public epics() {
      return {
        ...super.epics(),
        // 数据在UI上删除后，dataSource数量变少，跟index中ids不匹配，重新刷新
        onDataSourceCountNotMatch: () =>
          this.rootAction$.pipe(
            filter(() => !this.state.isLoading),
            map(() => this.state.dataIndexs[this.state.currentPageIndex] ?? []),
            pairwise(),
            filter(([oldCurrentPageIds, newCurrentPageIds]: string[][]) => {
              return (
                oldCurrentPageIds === newCurrentPageIds &&
                newCurrentPageIds.length > 0 &&
                newCurrentPageIds.length !== this.getters.dataSource.length
              );
            }),
            map(() => this.actions.refresh.create({}))
          ),
      };
    }
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createUISideListModel = function <
  TEntity extends { id: string; resourceType: string },
  UQuery extends EncooSort
>(payload: {
  setItems: (
    context: GetContainer,
    items: (string | TEntity)[]
  ) => Promise<void>;
  getItems: (getContainer: GetContainer) => TEntity[];
  getItem: (getContainer: GetContainer, id: string) => TEntity | undefined;
  getItemId: (entity: TEntity) => string;
  onLoadedItems?: (
    getContainer: GetContainer,
    items: TEntity[]
  ) => Promise<void>;
}) {
  const { getItems, getItem } = payload;
  return class extends createUIListBaseModel<TEntity, UQuery>(payload) {
    public selectors() {
      return {
        ...super.selectors(),
        dataSource: createSelector(
          () => this.state.dataIndexs,
          () => getItems(this.getContainer),
          () => this.state.currentPageIndex,
          (dataIndexs, items, pageIndex) => {
            const pageData: TEntity[] = [];
            for (let index = 0; index <= pageIndex; index++) {
              const ids = dataIndexs[index];

              if (!ids) {
                break;
              }

              ids.forEach((id) => {
                const entity = getItem(this.getContainer, id);
                if (entity) {
                  pageData.push(entity);
                }
              });
            }
            return pageData;
          }
        ),
        hasNext: () =>
          this.getters.maxPageIndex !== this.state.currentPageIndex,

        $onLoadNext: createSelector(
          () => () => this.actions.loadNext.dispatch(true)
        ),

        pagination: createSelector(
          (): boolean => this.state.isLoading,
          (): boolean => this.getters.hasNext,
          (): (() => void) => this.getters.$onLoadNext,
          (isLoading, hasNext, onLoadNext) => {
            return {
              isLoading,
              hasNext,
              onLoadNext,
            };
          }
        ),
      };
    }

    public effects() {
      return {
        ...super.effects(),

        loadNext: async () => {
          await this.actions.changePage.dispatch({
            pageNumber: this.getters.currentPageNumber + 1,
            pageSize: this.getters.pageSize,
          });
        },
      };
    }
  };
};

async function loadDataPermission<
  TEntity extends { id: string; resourceType: string }
>(getContainer: GetContainer, items: TEntity[]) {
  const dataPermissionHelper = getContainer(DataPermissionHelperModel);
  const dataPermissionPayload = items.map((item: TEntity) => {
    const { resourceType, id } = item;
    return { resourceType, id };
  });

  if (dataPermissionPayload.length === 0) {
    // message.error("暂无数据");
    return;
  }
  if (!items[0].resourceType) {
    // await items;
    return;
  }
  await dataPermissionHelper.actions.getAll.dispatch(dataPermissionPayload);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createUITableWithPermissionModel = function <
  TEntity extends { id: string; resourceType: string },
  UQuery extends EncooSort
>(payload: {
  setItems: (
    context: GetContainer,
    items: (string | TEntity)[]
  ) => Promise<void>;
  getItems: (getContainer: GetContainer) => TEntity[];
  getItem: (getContainer: GetContainer, id: string) => TEntity | undefined;
  getItemId: (entity: TEntity) => string;
}) {
  return class extends createUITableBaseModel<TEntity, UQuery>({
    ...payload,
    onLoadedItems: loadDataPermission,
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        dataSourceWithPermission: createSelector(
          (): TEntity[] => this.getters.dataSource,
          () => this.getContainer(DataPermissionEntityModel).state.byId,
          (items, byId) =>
            items.map((item) => ({
              ...item,
              permissionValues: byId[item.id]?.permissionValues ?? [],
            }))
        ),
      };
    }
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createUISideListWithPermissionModel = function <
  TEntity extends { id: string; resourceType: string },
  UQuery extends EncooSort
>(payload: {
  setItems: (
    context: GetContainer,
    items: (string | TEntity)[]
  ) => Promise<void>;
  getItems: (getContainer: GetContainer) => TEntity[];
  getItem: (getContainer: GetContainer, id: string) => TEntity | undefined;
  getItemId: (entity: TEntity) => string;
}) {
  return class extends createUISideListModel<TEntity, UQuery>({
    ...payload,
    onLoadedItems: loadDataPermission,
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        dataSourceWithPermission: createSelector(
          (): TEntity[] => this.getters.dataSource,
          () => this.getContainer(DataPermissionEntityModel).state.byId,
          (items, byId) =>
            items.map((item) => ({
              ...item,
              permissionValues: byId[item.id]?.permissionValues ?? [],
            }))
        ),
      };
    }
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createUIListV2Model = function <
  TEntity extends { id: string; resourceType: string },
  TQuery extends Partial<Record<keyof TQuery, unknown>> = Record<
    string,
    unknown
  >
>(payload: {
  setItems: (
    context: GetContainer,
    items: (string | TEntity)[]
  ) => Promise<void>;
  getItems: (getContainer: GetContainer) => TEntity[];
  getItem: (getContainer: GetContainer, id: string) => TEntity | undefined;
  getItemId: (entity: TEntity) => string;
}) {
  return class extends createListV2Model<TEntity>({
    ...payload,
    onLoadedItems: async (getContainer, items) => {
      const dataPermissionHelper = getContainer(DataPermissionHelperModel);
      const dataPermissionPayload = items.map((item: TEntity) => {
        const { resourceType, id } = item;
        return { resourceType, id };
      });

      if (dataPermissionPayload.length === 0) {
        // message.error("暂无数据");
        return;
      }
      if (!items[0].resourceType) {
        // await items;
        return;
      }
      await dataPermissionHelper.actions.getAll.dispatch(dataPermissionPayload);
    },
  }) {
    public initialState() {
      return {
        ...super.initialState(),
        query: null as TQuery | null,
      };
    }

    public reducers() {
      return {
        ...super.reducers(),
        setQuery: (value: TQuery | null) => {
          this.state.query = value;
        },
      };
    }

    public selectors() {
      return {
        ...super.selectors(),
        dataSourceWithPermission: createSelector(
          (): TEntity[] => this.getters.dataSource,
          () => this.getContainer(DataPermissionEntityModel).state.byId,
          (items, byId) =>
            items.map((item) => ({
              ...item,
              permissionValues: byId[item.id]?.permissionValues ?? [],
            }))
        ),
        listDataSourceWithPermission: createSelector(
          (): TEntity[] => this.getters.listDataSource,
          () => this.getContainer(DataPermissionEntityModel).state.byId,
          (items, byId) =>
            items.map((item) => ({
              ...item,
              permissionValues: byId[item.id]?.permissionValues ?? [],
            }))
        ),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        updateQuery: async (value: Partial<TQuery>) => {
          await this.actions.setQuery.dispatch({
            ...(this.state.query as TQuery),
            ...value,
          });
        },
      };
    }
  };
};
