export function loadScript(src: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const tag = document.createElement("script");
    tag.async = true;
    tag.src = src;

    tag.addEventListener("load", function () {
      resolve();
    });
    tag.addEventListener("error", function (e: unknown) {
      return reject(e);
    });
    document.body.appendChild(tag);
  });
}
