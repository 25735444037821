import { createModel } from "nyax";
import { RobotEntityModel } from "src/store/models/entity/robot/entity";
import { RobotTableUIModel } from "src/store/models/ui/robot/robotTable";
import { ShareSettingsBaseUIModel } from "src/store/models/ui/shareSettings/_shared";

export const RobotShareSettingsUIModel = createModel(
  class extends ShareSettingsBaseUIModel {
    public selectors() {
      return {
        ...super.selectors(),
        belongDepartmentId: () =>
          this.getContainer(RobotEntityModel).state.byId[this._id]
            ?.departmentId ?? null,
      };
    }

    public effects() {
      return {
        requestSharedToDepartmentIds: async () => {
          const departmentIds =
            await this.dependencies.serviceClient.robot.getRobotShareToDepartments(
              this._id
            );
          await this.actions.setSharedToDepartmentIds.dispatch(departmentIds);
        },
        updateSharedToDepartmentIds: async (
          sharedToDepartmentIds: string[]
        ) => {
          await this.dependencies.serviceClient.robot.updateRobotShareToDepartments(
            this._id,
            sharedToDepartmentIds
          );
          await this.actions.setSharedToDepartmentIds.dispatch(
            sharedToDepartmentIds
          );
          await this.getContainer(RobotTableUIModel).actions.refresh.dispatch(
            {}
          );
        },
      };
    }
  },
  { isDynamic: true, isLazy: true }
);
