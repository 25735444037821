// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ocr: {
    ocrName: "OCR",
    entityTypeName: "TODO:",
    name: "TODO: Model Name",
    identification: "Identification",
    ocrDetailName: "OCR Service Comparison",
    identifyMaterial: "Identify the material",
    fileTypeSupport: "File Type Support: ",
    identifyResults: "Identify results",
    parsedFailed: "Parse failed",
    parsedSuccessful: "Parse successfully",
    introduction:
      "Integrate OCR services from ENCOO's own research and major top AI platforms, and provide free trial services to quickly compare recognition results and save time in choosing AI services. And package all AI services into automated components, drag and drop and use.",
    compareButton: "Compare",
    uploadOnly: "Only {files} files can be uploaded! ",
    QRcode:
      "Please scan the code into the group and contact Encoo to obtain service",
    editQRcode:
      "Please scan the code into the group and contact Encoo to modify service",
    deleteQRcode:
      "Please scan the code into the group and contact Encoo to delete service",
    aiPlatformType: {
      Encoo: "Encoo",
      Alibaba: "Alibaba",
      Tencent: "Tencent",
      Baidu: "Baidu",
      GuoPiao: "GuoPiao",
      Intsig: "Intsig",
      Iflytec: "Iflytec",
      HuaweiCloud: "HuaweiCloud",
      Microsoft: "Microsoft",
      PXM: "PXM",
    },
    comparison: {
      id: "ID Comparison",
      drivingLicense: "Driving License Comparison",
      businessLicense: "Business License Comparison",
      bankCard: "Bank Card Comparison",
      recognizeAndValidation: "Recognize And Validation Comparison",
      vehicleCertificate: "Vehicle Certificate Comparison",
      licensePlate: "LicensePlate Comparison",
      VATInvoice: "VAT Invoice Comparison",
      rollTicket: "RollTicket Comparison",
      quotaInvoice: "Quota Invoice Comparison",
      bankReceipt: "Bank Receipt Comparison",
      taxiTicket: "Taxi Ticket Comparison",
      airTransport: "Air Transport Comparison",
      generalText: "General Text Comparison",
      handwrittenNumbers: "Handwritten Numbers Comparison",
      handwrittenText: "Handwritten Text Comparison",
      identificationOfCredentials: "Identification Of Credentials Comparison",
      billIdentification: "Bill Identification Comparison",
      generalCharacterRecognition: "General Character Recognition Comparison",
      vehiclePurchaseInvoice: "Vehicle Purchase Invoice Comparison",
      generalTable: "General Table Comparison",
    },
    services: {
      id: "ID",
      drivingLicense: "Driving License",
      businessLicense: "Business License",
      bankCard: "Bank Card",
      recognizeAndValidation: "VAT Recognize And Validation",
      vehicleCertificate: "Vehicle Certificate",
      VATInvoice: "VAT Invoice",
      rollTicket: "RollTicket",
      quotaInvoice: "Quota Invoice",
      bankReceipt: "Bank Receipt",
      taxiTicket: "Taxi Ticket",
      licensePlate: "LicensePlate",
      vehiclePurchaseInvoice: "Vehicle Purchase Invoice",
      generalText: "General Text",
      handwrittenNumbers: "Handwritten Numbers",
      handwrittenText: "Handwritten Text",
      generalTable: "General Table",
      billIdentification: "Bill",
      airTransport: "Air Transport",
      generalCharacterRecognition: "General Character Recognition",
    },
  },
};
