import { createModel, createSelector } from "nyax";
import { LicenseBinding } from "src/models/license";
import { EncooSort } from "src/models/_shared";
import { RpaLicenseBindingEntityModel } from "src/store/models/entity/rpaLicenseBinding/entity";
import { createUITableBaseModel } from "src/store/models/ui/_shared";
import { timeValidOrNot } from "src/utils/date";

export const RpaLicenseBindingListModel = createModel(
  class extends createUITableBaseModel<LicenseBinding, EncooSort>({
    setItems: (getContainer, items) =>
      getContainer(RpaLicenseBindingEntityModel).actions.setItems.dispatch(
        items
      ),
    getItems: (getContainer) =>
      getContainer(RpaLicenseBindingEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(RpaLicenseBindingEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(RpaLicenseBindingEntityModel),
        validateDataSource: createSelector(
          (): LicenseBinding[] => this.getters.dataSource,
          (validate): LicenseBinding[] => {
            return validate.filter((item) => timeValidOrNot(item.validTo));
          }
        ),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        initial: async (payload: {
          productName: string;
          sku: string;
          binded?: boolean | undefined;
          available?: boolean | undefined;
        }) => {
          const { productName, sku, binded, available } = payload;
          this._initial({
            initialAction: (pageIndex: number, pageSize: number) =>
              this.dependencies.serviceClient.rpaLicense.listRpaLicenseBindings(
                productName,
                sku,
                pageIndex,
                pageSize,
                binded,
                available
              ),
          });
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
