/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Tabs } from "antd";
import { DataNode, TreeProps } from "antd/lib/tree";
import _ from "lodash";
import { Key, memo, useCallback, useMemo, useState } from "react";
import {
  UserDeleteContent,
  UserDeleteItem,
  UserTree,
} from "src/containers/apps/appsInitConfig/UserTree";
import { useFormatMessage, useTheme } from "src/hooks";
import messageIds from "src/locales/messageIds";
import {
  DepartmentTreeData,
  PermissionTargetCompanyUser,
  PermissionTargetDepartment,
} from "src/models/department";

const { TabPane } = Tabs;

export type UserPanelType = "department" | "user";

export default memo(function AddUserPanel(props: {
  departmentData: DepartmentTreeData | null;
  companyUserData: DepartmentTreeData | null;
  departmentHandleProps?: {
    onCheck?: (
      checked: Key[],
      checkedNodes: Array<DataNode & PermissionTargetDepartment>
    ) => void;
    checkedKeys?: Key[];
    checkedNodes?: Array<DataNode & PermissionTargetDepartment>;
    onCheckedIncludSubDepartmentKeys?: (val: Key[]) => void;
    checkedIncludSubDepartmentKeys?: Key[];
  };
  userHandleProps?: {
    onCheck?: (
      checked: Key[],
      checkedNodes: Array<DataNode & PermissionTargetCompanyUser>
    ) => void;
    checkedKeys?: Key[];
    checkedNodes?: Array<DataNode & PermissionTargetCompanyUser>;
  };
}) {
  const formatMessage = useFormatMessage();
  const theme = useTheme();

  const {
    departmentHandleProps,
    userHandleProps,
    departmentData,
    companyUserData,
  } = props;

  const [activeKey, setActiveKey] = useState<UserPanelType>("department");

  const selectedUsers = useMemo(() => {
    return userHandleProps?.checkedNodes?.filter(
      (item) => item.resourceType === "CompanyUser"
    );
  }, [userHandleProps]);

  const onCheckDepartment = useCallback(
    (
      checked: {
        checked: Key[];
      },
      info: {
        checkedNodes: Array<DataNode & PermissionTargetDepartment>;
      }
    ) => {
      departmentHandleProps?.onCheck?.(checked.checked, info.checkedNodes);
    },
    [departmentHandleProps]
  );

  const onCheckUser = useCallback(
    (
      checked: Key[],
      info: { checkedNodes: Array<DataNode & PermissionTargetCompanyUser> }
    ) => {
      userHandleProps?.onCheck?.(checked, info.checkedNodes);
    },
    [userHandleProps]
  );

  const onDepartmentDelete = useCallback(
    (item) => {
      const _departmentCheckedKeys = _.cloneDeep(
        departmentHandleProps?.checkedKeys ?? []
      );
      const deleteItemIndex = _departmentCheckedKeys.indexOf(item.id);
      if (deleteItemIndex > -1) {
        _departmentCheckedKeys.splice(deleteItemIndex, 1);
      }

      const _departmentCheckedNodes = _.cloneDeep(
        departmentHandleProps?.checkedNodes
      );
      const checkedNodes =
        _departmentCheckedNodes?.filter((_item) => _item.id !== item.id) ?? [];

      departmentHandleProps?.onCheck?.(_departmentCheckedKeys, checkedNodes);
    },
    [departmentHandleProps]
  );

  const onUserDelete = useCallback(
    (item) => {
      const _selectedUsers = _.cloneDeep(selectedUsers);
      const deleteItemIndex =
        _selectedUsers?.findIndex((_item) => _item.id === item.id) ?? -1;
      if (deleteItemIndex > -1) {
        _selectedUsers?.splice(deleteItemIndex, 1);
      }
      const _userCheckedKeys = _selectedUsers?.map((item) => item.id);

      const _userCheckedNodes = _.cloneDeep(userHandleProps?.checkedNodes);
      const checkedNodes =
        _userCheckedNodes?.filter((_item) =>
          _userCheckedKeys?.includes(_item.id)
        ) ?? [];

      userHandleProps?.onCheck?.(_userCheckedKeys ?? [], checkedNodes);
    },
    [selectedUsers, userHandleProps]
  );

  const _onChangeIncludSubDepartment = useCallback(
    (includeValue: boolean, item: DataNode & PermissionTargetDepartment) => {
      const _checkedIncludSubDepartmentKeys = _.cloneDeep(
        departmentHandleProps?.checkedIncludSubDepartmentKeys ?? []
      );
      if (includeValue) {
        departmentHandleProps?.onCheckedIncludSubDepartmentKeys?.([
          ..._checkedIncludSubDepartmentKeys,
          item.id,
        ]);
      } else {
        const list = _checkedIncludSubDepartmentKeys.filter(
          (key) => item.id !== key
        );
        departmentHandleProps?.onCheckedIncludSubDepartmentKeys?.(list);
      }
    },
    [departmentHandleProps]
  );

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        height: 100%;
        flex-grow: 1;
      `}
    >
      <div
        css={css`
          flex: 1;
          border: 1px solid ${theme.bodyDivider};
        `}
      >
        <Tabs
          activeKey={activeKey}
          onChange={(key) => setActiveKey(key as UserPanelType)}
          css={css`
            .ant-tabs-tab {
              height: 30px;
            }
            .ant-tabs-nav {
              padding-left: 20px;
              border-bottom: 1px solid ${theme.bodyDivider};
            }
          `}
        >
          <TabPane
            tab={formatMessage(
              messageIds.apps.appInitConfig.userSelect.department
            )}
            key="department"
          >
            {departmentData && (
              <UserTree
                dataSource={departmentData}
                dataSourceType="department"
                treeProps={{
                  checkStrictly: true,
                  onCheck: onCheckDepartment as unknown as TreeProps["onCheck"],
                  checkedKeys: departmentHandleProps?.checkedKeys,
                }}
              />
            )}
          </TabPane>
          <TabPane
            tab={formatMessage(messageIds.apps.appInitConfig.userSelect.user)}
            key="user"
          >
            {companyUserData && (
              <UserTree
                dataSource={companyUserData}
                dataSourceType="user"
                treeProps={{
                  onCheck: onCheckUser as unknown as TreeProps["onCheck"],
                  checkedKeys: userHandleProps?.checkedKeys,
                }}
              />
            )}
          </TabPane>
        </Tabs>
      </div>
      <div
        css={css`
          flex-basis: 310px;
          border: 1px solid ${theme.bodyDivider};
          margin-left: 10px;
        `}
      >
        {activeKey === "department" && (
          <UserDeleteContent includeSubDepartment={true}>
            {departmentHandleProps?.checkedNodes?.map((item) => (
              <UserDeleteItem
                item={item}
                onDelete={() => onDepartmentDelete(item)}
                includeSubDepartment={true}
                key={item.id}
                onChangeIncludSubDepartment={(val) =>
                  _onChangeIncludSubDepartment(val, item)
                }
              />
            )) ?? null}
          </UserDeleteContent>
        )}
        {activeKey === "user" && (
          <UserDeleteContent>
            {selectedUsers?.map((item) => (
              <UserDeleteItem
                item={item}
                onDelete={() => onUserDelete(item)}
                key={item.id}
              />
            )) ?? null}
          </UserDeleteContent>
        )}
      </div>
    </div>
  );
});
