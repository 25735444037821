import { createModel } from "nyax";
import { TriggerLog } from "src/models/workflow";
import { TriggerLogEntityModel } from "src/store/models/entity/triggerLog/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const TriggerLogHelperModel = createModel(
  class extends createHelperModel<TriggerLog>({
    setItems: (getContainer, items) =>
      getContainer(TriggerLogEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(TriggerLogEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(TriggerLogEntityModel).state.byId[id],
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(TriggerLogEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),

        readByTriggerIds: async (payload: {
          workflowId: string;
          triggerIds: string[];
          force?: boolean;
        }) => {
          const { workflowId, triggerIds, force } = payload;
          return await this._readByParentIds({
            parentIds: triggerIds,
            getAllAction: (triggerId) => {
              return this.dependencies.serviceClient.workflow.getAllTriggerLogs(
                workflowId,
                triggerId
              );
            },
            getEntityParentId: (entity: TriggerLog) => entity.triggerId,
            force,
          });
        },
      };
    }
  }
);
