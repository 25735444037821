/** @jsxImportSource @emotion/react */
import { TagOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { Col, Row, Select } from "antd";
import { memo, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import SearchInput from "src/components/SearchInput";
import { useFormatMessage, useGetContainer, useUserEdition } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { AppQuery } from "src/models/appV2";
import { AppTagEntityModel } from "src/store/models/entity/appTag/entity";
import { AppTagHelperModel } from "src/store/models/entity/appTag/helper";

const { Option } = Select;

export default memo(function ViCodeAppSearch({
  query,
  onSearch,
}: {
  query: AppQuery | null;
  onSearch: (value: Partial<AppQuery>) => void;
}) {
  const getContainer = useGetContainer();
  const formatMessage = useFormatMessage();
  const appTagEntity = getContainer(AppTagEntityModel);
  const appTagHelper = getContainer(AppTagHelperModel);

  const userEdition = useUserEdition();

  const appTags = useSelector(() => appTagEntity.getters.items);

  const onNameChange = useCallback(
    (value) => {
      onSearch({
        nameLike: value,
      });
    },
    [onSearch]
  );

  const onTagChange = useCallback(
    (value) => {
      onSearch({
        tagIds: value,
      });
    },
    [onSearch]
  );

  useEffect(() => {
    appTagHelper.actions.getAll.dispatch({});
  }, [appTagHelper.actions.getAll]);

  return (
    <Row gutter={[24, 24]}>
      <Col>
        <SearchInput
          value={query?.nameLike}
          onSearch={onNameChange}
          width={"320"}
          placeholder={formatMessage(messageIds.common.searchKeyPlaceholder)}
        />
      </Col>
      {userEdition === "Community" ? null : (
        <Col>
          <div
            css={css`
              position: relative;
            `}
          >
            <TagOutlined
              css={css`
                position: absolute;
                left: 11px;
                top: 50%;
                transform: translateY(-50%);
                z-index: 1;
              `}
            />
            <Select
              mode="multiple"
              value={query?.tagIds}
              onChange={onTagChange}
              showSearch
              autoClearSearchValue
              maxTagCount="responsive"
              placeholder={formatMessage(messageIds.apps.inputTag)}
              optionFilterProp="children"
              allowClear
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              css={css`
                width: 320px;
                .ant-select-selector {
                  padding-left: 24px;
                }
                .ant-select-selection-placeholder {
                  padding-left: 22px;
                }
                .ant-select-selection-overflow {
                  padding-left: 4px;
                }
              `}
            >
              {appTags.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
      )}
    </Row>
  );
});
