import { createModel } from "nyax";
import { DataQueue, DataQueueQuery } from "src/models/dataQueue";
import { DataQueueEntityModel } from "src/store/models/entity/dataQueue/entity";
import { createUITableWithPermissionModel } from "src/store/models/ui/_shared";

export const DataQueueTableUIModel = createModel(
  class extends createUITableWithPermissionModel<DataQueue, DataQueueQuery>({
    setItems: (getContainer, items) =>
      getContainer(DataQueueEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(DataQueueEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(DataQueueEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initial: async () => {
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query: DataQueueQuery
            ) =>
              this.dependencies.serviceClient.dataQueue.list(
                pageIndex,
                pageSize,
                query
              ),
          });
        },
      };
    }
  }
);
