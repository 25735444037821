import { createModel } from "nyax";
import {
  Department,
  DepartmentCreationData,
  DepartmentUpdateData
} from "src/models/department";
import { DepartmentEntityModel } from "src/store/models/entity/department/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const DepartmentHelperModel = createModel(
  class extends createHelperModel<Department>({
    setItems: (getContainer, items) =>
      getContainer(DepartmentEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(DepartmentEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(DepartmentEntityModel).state.byId[id],
  }) {
    public effects() {
      return {
        ...super.effects(),
        getTree: async () => {
          return await this.dependencies.serviceClient.department.getTree();
        },
        getDepartmentUserTree: async () => {
          return await this.dependencies.serviceClient.department.getDepartmentUserTree();
        },
        getDepartmentRobotTree: async () => {
          return await this.dependencies.serviceClient.department.getDepartmentRobotTree();
        },
        getById: async (departmentId: string) => {
          return await this.dependencies.serviceClient.department.getById(
            departmentId
          );
        },
        create: async (payload: DepartmentCreationData) => {
          return await this.dependencies.serviceClient.department.create(
            payload
          );
        },
        update: async (params: {
          departmentId: string;
          payload: DepartmentUpdateData;
        }) => {
          const { departmentId, payload } = params;
          return await this.dependencies.serviceClient.department.update(
            departmentId,
            payload
          );
        },
        delete: async (departmentId: string) => {
          return await this.dependencies.serviceClient.department.delete(
            departmentId
          );
        },
      };
    }
  }
);
