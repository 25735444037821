import { createModel, createSelector } from "nyax";
import Oidc from "oidc-client";
import { CurrentCompanyUser } from "src/models/currentCompanyUser";
import { UserInfo, UserTenantInfo } from "src/models/userInfo";
import { ModelBase } from "src/store/ModelBase";
import { GlobalSelectCompanyUIModel } from "src/store/models/logic/globalSelectCompany";
import { RouterModel } from "src/store/models/router";
import { UserTenantUIModel } from "src/store/models/ui/tenant/userTenant";

export interface BasicUserProfile {
  id: string;
  accountUserName?: string;
  userId?: string;
  name?: string;
  displayName?: string;
  email?: string;
  wechatNickName?: string;
  phoneNumber?: string;
  defaultTenantId?: string;
  defaultLanguage?: string | null;
}

export const LoginUserUIModel = createModel(
  class extends ModelBase {
    private get _client() {
      return this.dependencies.oidcClient;
    }

    private get _userTenantUIContainer() {
      return this.getContainer(UserTenantUIModel);
    }

    private get _globalSelectCompanyContainer() {
      return this.getContainer(GlobalSelectCompanyUIModel);
    }

    public selectors() {
      return {
        selectedUserTenant: createSelector(
          (): UserTenantInfo[] | null =>
            this._userTenantUIContainer.state.userTenants,
          (): string | null => this.state.selectedUserTenantId,
          (userTenants, selectedUserTenantId): UserTenantInfo | null => {
            return (
              userTenants?.find(
                (item) => item.tenantId === selectedUserTenantId
              ) ?? null
            );
          }
        ),
      };
    }

    public initialState() {
      return {
        basicUserProfile: null as BasicUserProfile | null,
        loggedinUser: null as UserInfo | null,
        selectedUserTenantId: null as string | null,
        currentCompanyUser: null as CurrentCompanyUser | null,
        currentLoginUserSubId: null as string | null,
      };
    }

    public reducers() {
      return {
        ...super.reducers(),
        setLoggedinUser: (value: UserInfo | null) => {
          this.state.loggedinUser = value;
        },
        setSelectedTenantId: (value: string | null) => {
          this.state.selectedUserTenantId = value;
        },
        setBasicUserProfile: (value: BasicUserProfile | null) => {
          this.state.basicUserProfile = value;
        },
        setCurrentCompanyUser: (
          currentCompanyUser: CurrentCompanyUser | null
        ) => {
          this.state.currentCompanyUser = currentCompanyUser;
        },
        setCurrentLoginUserSubId: (id: string | null) => {
          this.state.currentLoginUserSubId = id;
        },
      };
    }

    public effects() {
      return {
        login: async () => {
          const user = await this._client.login();
          if (user?.profile?.sub) {
            await this.actions.requestBasicUserProfile.dispatch(
              user.profile.sub
            );
            await this.actions.setCurrentLoginUserSubId.dispatch(
              user?.profile?.sub
            );
          }

          return user;
        },

        requestBasicUserProfile: async (profileId: string) => {
          // TODO: ENCOO-Lib更新类型统一后就不需要转类型
          const profile =
            (await this.dependencies.serviceClient.user.getUserProfileV2(
              profileId
            )) as BasicUserProfile;
          const {
            id,
            accountUserName,
            userId,
            name,
            displayName,
            email,
            phoneNumber,
            wechatNickName,
            defaultTenantId,
            defaultLanguage,
          } = profile;
          const basicProfile = {
            id,
            accountUserName,
            userId,
            name,
            displayName,
            email,
            phoneNumber,
            wechatNickName,
            defaultTenantId,
            defaultLanguage,
          };
          await this.actions.setBasicUserProfile.dispatch(basicProfile);
        },

        updateCurrentCompanyUser: async () => {
          if (
            this._globalSelectCompanyContainer.state.selectedGlobalComponyId
          ) {
            const currentCompanyUser =
              await this.dependencies.serviceClient.companyUser.getCompanyUserForSelf();
            this.actions.setCurrentCompanyUser.dispatch(currentCompanyUser);
            return currentCompanyUser;
          }
          return undefined;
        },

        logout: async () => {
          // 退出先不走提示
          await this.getContainer(RouterModel).actions.setNeedConfirm.dispatch(
            false
          );

          await this._client.logOut();
          await this.actions.setLoggedinUser.dispatch(null);
        },

        addLogoutListener: async () => {
          if (this.dependencies.profile.env === "local") {
            return;
          }
          const oidcUserManagerInstance = new Oidc.UserManager(
            this.dependencies.userManagerSettings
          );
          oidcUserManagerInstance.events.addUserSignedOut(() => {
            oidcUserManagerInstance.clearStaleState();
            console.log("sso fontend loginOut");
            this.actions.logout.dispatch({});
          });
        },

        refreshUser: async () => {
          await this.actions.updateCurrentCompanyUser.dispatch(null);
          if (this.state.currentLoginUserSubId) {
            await this.actions.requestBasicUserProfile.dispatch(
              this.state.currentLoginUserSubId
            );
          }
        },
      };
    }
  }
);
