import _ from "lodash";
import { createModel, createSelector } from "nyax";
import { EntityQuota } from "src/models/quota";
import { ModelBase } from "src/store/ModelBase";
import { RouterModel } from "src/store/models/router";
import { createMapFromArray } from "src/utils/array";

export const QuotaUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        quotalist: [] as EntityQuota[],
        quotaCount: 0,
        isOutOfQuota: false,
      };
    }
    public reducers() {
      return {
        setQuotalist: (value: EntityQuota[]) => {
          this.state.quotalist = value;
        },
        setQuotaCount: (value: number) => {
          this.state.quotaCount = value;
        },
        setIsOutOfQuota: (value: boolean) => {
          this.state.isOutOfQuota = value;
        },
      };
    }
    public selectors() {
      return {
        switchType: createSelector(
          () => this.getContainer(RouterModel).getters.currentRouteInfo.type,
          (routeType) => {
            switch (routeType) {
              case "calendars":
                return "calendar";
              case "jobQueue":
                return "taskqueue";
              case "dataTable":
                return "table";
              case "template":
                return "docreader";
              case "knowledge":
                return "lamosskb";
              case "application":
                return "lamossapp";
              default:
                return routeType.toLocaleLowerCase();
            }
          }
        ),
        list: createSelector(
          () => this.state.quotalist,
          (quotalist) => {
            return createMapFromArray(quotalist, "entityType");
          }
        ),
        getQuotaType: createSelector(
          () => this.state.quotalist,
          (list) => {
            const listMap = createMapFromArray(list, "entityType");
            return Object.keys(listMap);
          }
        ),
        totalQuota: createSelector(
          () => this.getContainer(RouterModel).getters.currentRouteInfo.type,
          () => this.state.quotalist,
          (routeType, quotalist) => {
            const list = createMapFromArray(quotalist, "entityType");
            switch (routeType) {
              case "calendars":
                return list["calendar"]?.quota ?? undefined;
              case "jobQueue":
                return list["taskqueue"]?.quota ?? undefined;
              case "dataTable":
                return list["table"]?.quota ?? undefined;
              case "template":
                return list["docreader"]?.quota ?? undefined;
              case "knowledge":
                return list["lamosskb"]?.quota ?? undefined;
              case "application":
                return list["lamossapp"]?.quota ?? undefined;
              default:
                return list[routeType.toLocaleLowerCase()]?.quota ?? undefined;
            }
          }
        ),
      };
    }
    public effects() {
      return {
        getQuotalist: async () => {
          const list =
            await this.dependencies.serviceClient.quota.getQuotalist();
          this.actions.setQuotalist.dispatch(list);
        },

        getQuotaCount: async (entityType: string) => {
          const count =
            await this.dependencies.serviceClient.quota.getQuotaCount(
              entityType
            );
          this.actions.setQuotaCount.dispatch(count);

          const totalQuotaCount = this.getters.totalQuota;
          if (totalQuotaCount && totalQuotaCount <= count) {
            this.actions.setIsOutOfQuota.dispatch(true);
          } else {
            this.actions.setIsOutOfQuota.dispatch(false);
          }
        },

        refreshQuota: async () => {
          const routeType = this.getters.switchType;
          const quotaType = this.getters.getQuotaType;
          if (_.includes(quotaType, routeType)) {
            await this.actions.getQuotaCount.dispatch(routeType);
          }
        },
        forceRefreshQuota: async () => {
          await this.actions.getQuotalist.dispatch({});
        },
      };
    }
  }
);
