import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";

export type FeaturePermissionViewMode = "view" | "edit";

export const FeaturePermissionModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        appId: this.containerKey!,
        featurePermissionViewMode: "view" as FeaturePermissionViewMode,
        checkedFeatures: null as string[] | null,
      };
    }

    public reducers() {
      return {
        setFeaturePermissionViewMode: (value: FeaturePermissionViewMode) => {
          this.state.featurePermissionViewMode = value;
        },
        setCheckedFeatures: (value: string[] | null) => {
          this.state.checkedFeatures = value;
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
