import { message } from "antd";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useModal } from "src/components/Dialog";
import { TableOperationMenuItem } from "src/components/TableOperation";
import {
  useApplicationInsights,
  useFormatMessage,
  useGetContainer,
  useGlobalCompany,
} from "src/hooks";
import messageIds from "src/locales/messageIds";
import { ViCodeAppStatus } from "src/models/vicodeApp";
import { ViCodeAppHelperModel } from "src/store/models/entity/vicodeApp/helper";
import { GlobalSelectDepartmentModel } from "src/store/models/logic/globalSelectDepartment";
import { QuotaUIModel } from "src/store/models/ui/quota/quota";
import { ViCodeAppWithPermission } from "src/store/models/ui/vicodeApp/_shared";
import { UIViCodeAppAppModel } from "src/store/models/ui/vicodeApp/apps";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useViCodeAppOperation = function () {
  const getContainer = useGetContainer();
  const viCodeAppHelper = getContainer(ViCodeAppHelperModel);
  const uiViCodeAppAppContainer = getContainer(UIViCodeAppAppModel);

  const formatMessage = useFormatMessage();
  const applicationInsights = useApplicationInsights();

  const globalSelectDepartmentModel = getContainer(GlobalSelectDepartmentModel);
  const quota = getContainer(QuotaUIModel);
  const globalCompany = useGlobalCompany();
  const selectedDepartmentId = useSelector(
    () => globalSelectDepartmentModel.state.selectedDepartmentId
  );
  const selectCompanyId = useMemo(() => globalCompany.id, [globalCompany.id]);

  const Modal = useModal();

  const onRelease = useCallback(
    async (appId: string) => {
      await viCodeAppHelper.actions.release.dispatch({
        appId,
      });
      message.success(
        formatMessage(messageIds.apps.appRelease.releaseSuccessFully)
      );
    },
    [formatMessage, viCodeAppHelper.actions.release]
  );

  const onOffline = useCallback(
    async (appId: string) => {
      await viCodeAppHelper.actions.offline.dispatch({
        appId,
      });
    },
    [viCodeAppHelper.actions.offline]
  );

  const onCopy = useCallback(
    async (appId: string) => {
      await uiViCodeAppAppContainer.actions.copy.dispatch({
        appId,
      });
    },
    [uiViCodeAppAppContainer.actions.copy]
  );

  const onEdit = useCallback(
    (appId: string) => {
      applicationInsights.sendTrackEvent(
        { name: "Vicode_EditAppEvent" },
        {
          AppID: appId,
        }
      );
      window.open(
        `${window._settings.vicodeUrl}studio/?companyId=${selectCompanyId}&departmentId=${selectedDepartmentId}&appId=${appId}`
      );
    },
    [applicationInsights, selectCompanyId, selectedDepartmentId]
  );

  const onDelete = useCallback(
    (appId: string) => {
      Modal.info({
        content: formatMessage(messageIds.common.deleteConfirmation),
        onOk: () => {
          viCodeAppHelper.actions.delete.dispatch({
            id: appId,
          });
          quota.actions.refreshQuota.dispatch({});
        },
      });
    },
    [
      Modal,
      formatMessage,
      viCodeAppHelper.actions.delete,
      quota.actions.refreshQuota,
    ]
  );

  const operations = useMemo(
    () => ({
      onRelease,
      onEdit,
      onOffline,
      onDelete,
      onCopy,
    }),
    [onCopy, onDelete, onEdit, onOffline, onRelease]
  );

  return operations;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useViCodeAppOperationMenus() {
  const formatMessage = useFormatMessage();
  const viCodeAppOperation = useViCodeAppOperation();

  const getMenus = useCallback(
    (app: ViCodeAppWithPermission): TableOperationMenuItem[] => {
      return [
        {
          key: "release",
          name: formatMessage(messageIds.apps.operation.release),
          visible: app.permissionValues?.includes("App_Launch"),
          onClick: () => viCodeAppOperation.onRelease(app.id),
        },
        {
          key: "offline",
          name: formatMessage(messageIds.apps.operation.offline),
          visible:
            app.status === ViCodeAppStatus.Enabled &&
            app.permissionValues?.includes("App_Launch"),
          onClick: () => viCodeAppOperation.onOffline(app.id),
        },
        {
          key: "edit",
          name: formatMessage(messageIds.common.edit),
          visible: app.permissionValues?.includes("App_Update"),
          onClick: () => viCodeAppOperation.onEdit(app.id),
        },
        {
          key: "copy",
          name: formatMessage(messageIds.apps.appDev.card.menu.copyAppProject),
          visible: app.permissionValues?.includes("App_Copy"),
          onClick: () => {
            viCodeAppOperation.onCopy(app.id);
          },
        },
        {
          key: "delete",
          name: formatMessage(messageIds.common.delete),
          type: "warning",
          visible: app.permissionValues?.includes("App_Delete"),
          onClick: () => viCodeAppOperation.onDelete(app.id),
        },
      ];
    },
    [formatMessage, viCodeAppOperation]
  );

  return getMenus;
}
