import { goBack, push, replace, RouterState } from "connected-react-router";
import { LocationDescriptorObject, Path } from "history";
import { createModel, createSelector } from "nyax";
import { appRouteDefinitions, AppRouteInfo } from "src/routes";
import { ModelBase } from "src/store/ModelBase";
import { GlobalSelectCompanyUIModel } from "src/store/models/logic/globalSelectCompany";
import { GlobalSelectDepartmentModel } from "src/store/models/logic/globalSelectDepartment";
import { assert } from "src/utils/shared";

type RouterBackupNavigateInfo =
  | { type: "back" }
  | { type: "replace" | "navigate"; routeInfo: AppRouteInfo };

export const RouterModel = createModel(
  class extends ModelBase {
    private get _globalSelectDepartmentContainer() {
      return this.getContainer(GlobalSelectDepartmentModel);
    }
    public initialState() {
      return {
        enableConfirm: false,
        needConfirm: false,
        isConfirmDialogVisible: false,
        backupNavigateInfo: null as RouterBackupNavigateInfo | null,
        originPathname: "/",
      };
    }

    public reducers() {
      return {
        setEnableConfirm: (value: boolean) => {
          this.state.enableConfirm = value;
          if (value === false) {
            this.state.needConfirm = false;
          }
        },
        setNeedConfirm: (value: boolean) => {
          if (this.state.enableConfirm) {
            this.state.needConfirm = value;
          }
        },
        setConfirmDialogVisible: (value: boolean) => {
          this.state.isConfirmDialogVisible = value;
        },
        setBackupNavigateInfo: (value: RouterBackupNavigateInfo | null) => {
          this.state.backupNavigateInfo = value;
        },
        setOriginPathname: (value: string) => {
          this.state.originPathname = value;
        },
      };
    }

    public selectors() {
      return {
        router: (): RouterState =>
          this.dependencies.redux.store.getState().router,
        pathname: (): string => this.getters.router.location.pathname,
        searchParams: createSelector(
          (): string => this.getters.router.location.search,
          (search) => new URLSearchParams(search)
        ),
        currentRouteInfo: createSelector(
          (): string => this.getters.pathname,
          (): URLSearchParams => this.getters.searchParams,
          (pathname, searchParams): AppRouteInfo => {
            const routeDefinition = Object.values(appRouteDefinitions).find(
              (e) => {
                let regStr = `^${e.path}$`;
                Object.entries(e.defaultParams).forEach(([key, value]) => {
                  regStr = regStr.replace(`:${key}`, "[^/]*");
                });
                const reg = new RegExp(regStr);
                const flag = pathname
                  ? reg.test(pathname)
                  : e.path === pathname;
                return flag;
              }
            );

            if (!routeDefinition) {
              return { ...appRouteDefinitions["404"], params: {} };
            }

            const params: Record<string, string> = {};
            const urlFragments = pathname.split("/");
            const pathFragments = routeDefinition.path.split("/");

            Object.entries(routeDefinition.defaultParams).forEach(
              ([key, value]) => {
                if (routeDefinition.path.includes(`:${key}`)) {
                  const index = pathFragments.indexOf(`:${key}`);
                  params[key] = urlFragments[index];
                } else {
                  params[key] = searchParams.get(key) ?? value;
                }
              }
            );

            params.companyId = searchParams.get("companyId") ?? "";
            params.departmentId = searchParams.get("departmentId") ?? "";

            return {
              ...routeDefinition,
              params,
            } as AppRouteInfo;
          }
        ),
      };
    }

    public effects() {
      return {
        push: async (pathOrLocation: Path | LocationDescriptorObject) => {
          await this.dependencies.redux.store.dispatch(
            typeof pathOrLocation === "string"
              ? push(pathOrLocation)
              : push(pathOrLocation)
          );
        },
        goBack: async () => {
          if (this.state.enableConfirm && this.state.needConfirm) {
            await this.actions.setBackupNavigateInfo.dispatch({ type: "back" });
            await this.actions.setConfirmDialogVisible.dispatch(true);
            return;
          }
          await this.dependencies.redux.store.dispatch(goBack());
        },
        navigateByRouteInfo: async (routeInfo: AppRouteInfo) => {
          if (this.state.enableConfirm && this.state.needConfirm) {
            await this.actions.setBackupNavigateInfo.dispatch({
              type: "navigate",
              routeInfo,
            });
            await this.actions.setConfirmDialogVisible.dispatch(true);
            return;
          }

          await this.updateSelectedGlobalDepartmentId(routeInfo);
          const { pathname, searchParams } = this.getRouteInfo(routeInfo);

          await this.dependencies.redux.store.dispatch(
            push({
              pathname,
              search: "" + searchParams,
            })
          );
        },
        replaceByRouteInfo: async (routeInfo: AppRouteInfo) => {
          if (this.state.enableConfirm && this.state.needConfirm) {
            await this.actions.setBackupNavigateInfo.dispatch({
              type: "replace",
              routeInfo,
            });
            await this.actions.setConfirmDialogVisible.dispatch(true);
            return;
          }
          await this.updateSelectedGlobalDepartmentId(routeInfo);
          const { pathname, searchParams } = this.getRouteInfo(routeInfo);

          await this.dependencies.redux.store.dispatch(
            replace({
              pathname,
              search: "" + searchParams,
            })
          );
        },

        forceNavigate: async () => {
          await this.actions.setNeedConfirm.dispatch(false);
          await this.actions.setConfirmDialogVisible.dispatch(false);
          const backupNavigateInfo = this.state.backupNavigateInfo;
          await this.actions.setBackupNavigateInfo.dispatch(null);
          switch (backupNavigateInfo?.type) {
            case "back":
              await this.actions.goBack.dispatch({});
              break;
            case "navigate":
              await this.actions.navigateByRouteInfo.dispatch(
                backupNavigateInfo.routeInfo
              );
              break;
            case "replace":
              await this.actions.replaceByRouteInfo.dispatch(
                backupNavigateInfo.routeInfo
              );
              break;
          }
        },
      };
    }

    private async updateSelectedGlobalDepartmentId(routeInfo: AppRouteInfo) {
      const params = routeInfo.params as Record<string, string>;
      if (params?.departmentId) {
        await this._globalSelectDepartmentContainer.actions.setGlobalSelectedDepartmentId.dispatch(
          params.departmentId
        );
      }
    }

    private getRouteInfo(routeInfo: AppRouteInfo): {
      pathname: string;
      searchParams: URLSearchParams;
    } {
      const routeDefinition = Object.values(appRouteDefinitions).find(
        (e) => e.type === routeInfo.type
      );
      assert(routeDefinition);

      let pathname = routeDefinition.path;
      const params: Record<string, string> = {
        ...routeDefinition.defaultParams,
        ...routeInfo.params,
      };

      Object.entries(params).forEach(([key, value]) => {
        if (routeDefinition.path.includes(`:${key}`)) {
          pathname = pathname.replace(`:${key}`, value);
          delete params[key];
        }
      });

      const companyId = this.getContainer(GlobalSelectCompanyUIModel).state
        .selectedGlobalComponyId;
      const departmentId =
        this._globalSelectDepartmentContainer.state.selectedDepartmentId;
      if (companyId) {
        params["companyId"] = companyId;
      }

      if (departmentId) {
        params["departmentId"] = departmentId;
      }

      return {
        pathname,
        searchParams: new URLSearchParams(params),
      };
    }
  }
);
