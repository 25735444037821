import { createModel } from "nyax";
import { License, LicenseBindingData } from "src/models/license";
import { RpaLicenseEntityModel } from "src/store/models/entity/rpaLicense/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const RpaLicenseHelperModel = createModel(
  class extends createHelperModel<License>({
    setItems: (getContainer, items) =>
      getContainer(RpaLicenseEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(RpaLicenseEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(RpaLicenseEntityModel).state.byId[id],
    getItemId: (entity: License) => entity.ownerId,
  }) {
    public effects() {
      return {
        ...super.effects(),
        readAll: async (playload: { available?: boolean }) => {
          const { available } = playload;
          return this._readByParentIds({
            parentIds: [""],
            getAllAction: () => {
              return this.dependencies.serviceClient.rpaLicense.getAllRpaLicense(
                available
              );
            },
            getEntityParentId: () => "",
            force: true,
          });
        },
        bindTo: async (playload: {
          licenseBindingData: LicenseBindingData;
        }) => {
          const { licenseBindingData } = playload;

          return this.dependencies.serviceClient.rpaLicense.bindTo(
            licenseBindingData
          );
        },
        unbind: async (playload: { boundToId: string }) => {
          const { boundToId } = playload;
          return this.dependencies.serviceClient.rpaLicense.unbind(boundToId);
        },
      };
    }
  }
);
