import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";

export const RoleManagementUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        ...super.initialState(),
        creationToggle: false,
        editRoleToggle: false,
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setCreationToggle: (value: boolean) => {
          this.state.creationToggle = value;
        },
        setEditToggle: (value: boolean) => {
          this.state.editRoleToggle = value;
        },
      };
    }
    public effects() {
      return {
        ...super.effects(),
      };
    }
  }
);
