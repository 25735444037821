import { createModel } from "nyax";
import { AiServiceType } from "src/models/ocr";
import { OCREntityModel } from "src/store/models/entity/ocr/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const OCRHelperModel = createModel(
  class extends createHelperModel<AiServiceType>({
    setItems: (getContainer, items) =>
      getContainer(OCREntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(OCREntityModel).getters.items,
    getItem: (getContainer, id) => getContainer(OCREntityModel).state.byId[id],
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(OCREntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),
      };
    }
  }
);
