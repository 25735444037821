import { ServiceClient } from "@encoo-web/encoo-lib";
import { RpaConfig, RpaConfigUnit } from "src/models/rpaConfig";
export const RPA_LICENSE_SERVICE_NAME = "rpaLicense";
export class RpaConfigServiceClient extends ServiceClient {
  public async getRpaConfig(): Promise<RpaConfigUnit[]> {
    const req = this.buildRequestV2({
      url: `/v2/fileAssetCleanSettings`,
      method: "GET",
    });
    const { body } = await super.sendRequest(req);
    return body.list;
  }
  public async updateRpaConfig(payload: RpaConfigUnit[]): Promise<RpaConfig> {
    const req = this.buildRequestV2({
      url: `/v2/fileAssetCleanSettings`,
      method: "PUT",
      payload,
    });
    return await super.updateInternal(req);
  }
}
