import { createModel } from "nyax";
import { JobQuery } from "src/models/job";
import { createJobTableBaseUIModel } from "src/store/models/ui/job/jobTable";

// Job暂时不是Resource，不走实体权限
export const PackageJobUIModel = createModel(
  class extends createJobTableBaseUIModel() {
    public effects() {
      return {
        ...super.effects(),
        initial: async (payload: {
          departmentId: string;
          packageId?: string;
        }) => {
          const { departmentId, packageId } = payload;
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query: JobQuery
            ) =>
              this.dependencies.serviceClient.job.list(
                departmentId,
                pageIndex,
                pageSize,
                query,
                undefined,
                packageId
              ),
          });
        },
      };
    }
  }
);
