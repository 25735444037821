import { createModel, createSelector } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { AssetEntityModel } from "src/store/models/entity/asset/entity";
import { DataPermissionEntityModel } from "src/store/models/entity/dataPermission/entity";
import { AssetWithPermission } from "src/store/models/ui/asset/assetTable";

export const AssetDetailModel = createModel(
  class extends ModelBase {
    private get _id() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.containerKey!;
    }
    public selectors() {
      return {
        assetDetail: createSelector(
          () => this.getContainer(AssetEntityModel).state.byId,
          () => this.getContainer(DataPermissionEntityModel).state.byId,
          (assetById, permissionById): AssetWithPermission | null => {
            const assetItem = assetById[this._id];
            return assetItem
              ? {
                  ...assetItem,
                  permissionValues:
                    permissionById[this._id]?.permissionValues ?? [],
                }
              : null;
          }
        ),
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
