import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import {
  Averagewaittime,
  Busyproportion,
  DashboardQueryData,
  JobQueryData,
  JobStatistics,
  Jobrankstatistics,
  Jobstatistics,
  LicenseStatistics,
  PackageStatistics,
  QueueStatistics,
  RobotExecutionstatistics,
  RobotJobStateCount,
  RobotStatistics,
  TimeRangeLimit,
  UserFlowstatistics,
  WorkflowStatistics,
} from "src/models/dashboard";
import {
  QueueAssociatedCount,
  QueueBusyTimeRank,
  QueueExecutionDetail,
  QueueFailureProportionRank,
  QueueJobStateCount,
  QueueSuccessProportionRank,
  QueueWaitTimeRank,
} from "src/models/queueDashboard";
import {
  RobotBusyTimeRank,
  RobotExecutionDetail,
  RobotFailureRateRank,
  RobotStateCount,
  RobotSuccessRateRank,
} from "src/models/robotDashboard";

export const DASHBOARD_SERVICE_NAME = "dashboard";

export class DashboardServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, DASHBOARD_SERVICE_NAME);
  }

  async robotStatistics(
    departmentId: string,
    containsSubDepartment: boolean
  ): Promise<RobotStatistics> {
    const req = this.buildRequestV2({
      url: `/v2/dashboard/robotstatistics`,
      method: "GET",
      query: {
        containsSubDepartment: containsSubDepartment ? true : undefined,
      },
      departmentId,
    });
    return await super.createInternal(req);
  }

  async jobStatistics(
    departmentId: string,
    query: JobQueryData,
    containsSubDepartment: boolean
  ): Promise<JobStatistics> {
    const req = this.buildRequestV2({
      url: `/v2/dashboard/jobstatistics`,
      method: "GET",
      query: {
        StartDate: query.startDate,
        EndDate: query.endDate,
        timeZone: query.timeZone,
        containsSubDepartment: containsSubDepartment ? true : undefined,
      },
      departmentId,
    });
    return await super.createInternal(req);
  }

  async packageStatistics(
    departmentId: string,
    containsSubDepartment: boolean
  ): Promise<PackageStatistics> {
    const req = this.buildRequestV2({
      url: `/v2/dashboard/packagestatistics`,
      method: "GET",
      query: {
        containsSubDepartment: containsSubDepartment ? true : undefined,
      },
      departmentId,
    });
    return await super.createInternal(req);
  }

  async workflowStatistics(
    departmentId: string,
    containsSubDepartment: boolean
  ): Promise<WorkflowStatistics> {
    const req = this.buildRequestV2({
      url: `/v2/dashboard/workflowstatistics`,
      method: "GET",
      query: {
        containsSubDepartment: containsSubDepartment ? true : undefined,
      },
      departmentId,
    });
    return await super.createInternal(req);
  }

  async queueStatistics(
    departmentId: string,
    containsSubDepartment: boolean
  ): Promise<QueueStatistics> {
    const req = this.buildRequestV2({
      url: `/v2/dashboard/queuestatistics`,
      method: "GET",
      query: {
        containsSubDepartment: containsSubDepartment ? true : undefined,
      },
      departmentId,
    });
    return await super.createInternal(req);
  }

  async licenseStatistics(
    containsSubDepartment: boolean
  ): Promise<LicenseStatistics> {
    const req = this.buildRequestV2({
      url: `/v2/LicenseBindings/statistics`,
      method: "GET",
      query: {
        containsSubDepartment: containsSubDepartment ? true : undefined,
      },
    });
    return await super.createInternal(req);
  }

  async averagewaittime(
    query: DashboardQueryData,
    containsSubDepartment: boolean
  ): Promise<Array<Averagewaittime>> {
    const req = this.buildRequestV2({
      url: `/v2/dashboard/job/averagewaittime`,
      method: "GET",

      query: {
        startDate: query.startDate,
        endDate: query.endDate,
        containsSubDepartment: containsSubDepartment ? true : undefined,
      },
    });
    return await super.createInternal(req);
  }

  async busyproportion(
    query: DashboardQueryData,
    containsSubDepartment: boolean
  ): Promise<Array<Busyproportion>> {
    const req = this.buildRequestV2({
      url: `/v2/dashboard/robot/busyproportion`,
      method: "GET",
      query: {
        startDate: query.startDate,
        endDate: query.endDate,
        containsSubDepartment: containsSubDepartment ? true : undefined,
      },
    });
    return await super.createInternal(req);
  }

  async jobrankstatistics(
    query: DashboardQueryData,
    containsSubDepartment: boolean
  ): Promise<Jobrankstatistics> {
    const req = this.buildRequestV2({
      url: `/v2/dashboard/workflow/jobrankstatistics`,
      method: "GET",
      query: {
        startDate: query.startDate,
        endDate: query.endDate,
        containsSubDepartment: containsSubDepartment ? true : undefined,
      },
    });
    return await super.createInternal(req);
  }

  async jobstatisticsByWorkflow(
    query: DashboardQueryData & { workflowId: string },
    containsSubDepartment: boolean
  ): Promise<Jobstatistics> {
    const req = this.buildRequestV2({
      url: `/v2/dashboard/workflow/jobstatistics`,
      method: "GET",
      query: {
        startDate: query.startDate,
        endDate: query.endDate,
        workflowId: query.workflowId,
        containsSubDepartment: containsSubDepartment ? true : undefined,
      },
    });
    return await super.createInternal(req);
  }

  async robotExecutionstatistics(
    departmentId: string,
    query: DashboardQueryData,
    containsSubDepartment: boolean
  ): Promise<RobotExecutionstatistics> {
    const req = this.buildRequestV2({
      url: `/v2/dashboard/robot/executionstatistics`,
      method: "GET",
      query: {
        ...query,
        containsSubDepartment: containsSubDepartment ? true : undefined,
      },
      departmentId,
    });
    return await super.createInternal(req);
  }

  async userFlowstatistics(
    departmentId: string,
    query: DashboardQueryData,
    containsSubDepartment: boolean
  ): Promise<UserFlowstatistics> {
    const req = this.buildRequestV2({
      url: `/v2/dashboard/user/flowstatistics`,
      method: "GET",
      query: {
        ...query,
        containsSubDepartment: containsSubDepartment ? true : undefined,
      },
      departmentId,
    });
    return await super.createInternal(req);
  }

  /**
   * 机器人监控仪表盘
   */
  async robotExecutionDetail(
    departmentId: string,
    query: DashboardQueryData,
    containsSubDepartment: boolean
  ): Promise<RobotExecutionDetail> {
    const req = this.buildRequestV2({
      url: `/v2/dashboard/robots/executiondetail`,
      method: "GET",
      query: {
        ...query,
        containsSubDepartment: containsSubDepartment ? true : undefined,
      },
      departmentId,
    });
    return await super.createInternal(req);
  }

  async robotFailureRateRank(
    departmentId: string,
    query: DashboardQueryData,
    containsSubDepartment: boolean
  ): Promise<RobotFailureRateRank[]> {
    const req = this.buildRequestV2({
      url: `/v2/dashboard/robots/failureproportionrank`,
      method: "GET",
      query: {
        ...query,
        containsSubDepartment: containsSubDepartment ? true : undefined,
      },
      departmentId,
    });
    return await super.createInternal(req);
  }

  async robotSuccessRateRank(
    departmentId: string,
    query: DashboardQueryData,
    containsSubDepartment: boolean
  ): Promise<RobotSuccessRateRank[]> {
    const req = this.buildRequestV2({
      url: "/v2/dashboard/robots/successproportionrank",
      method: "GET",
      query: {
        ...query,
        containsSubDepartment: containsSubDepartment ? true : undefined,
      },
      departmentId,
    });
    return await super.createInternal(req);
  }

  async robotStateCount(
    departmentId: string,
    query: Partial<DashboardQueryData>,
    containsSubDepartment: boolean
  ): Promise<RobotStateCount> {
    const req = this.buildRequestV2({
      url: `/v2/dashboard/robots/robotstatecount`,
      method: "GET",
      query: {
        ...query,
        containsSubDepartment: containsSubDepartment ? true : undefined,
      },
      departmentId,
    });
    return await super.createInternal(req);
  }

  async robotRunstatecount(
    departmentId: string,
    query: DashboardQueryData,
    containsSubDepartment: boolean
  ): Promise<RobotJobStateCount> {
    const req = this.buildRequestV2({
      url: `/v2/dashboard/robots/runstatecount`,
      method: "GET",
      query: {
        ...query,
        containsSubDepartment: containsSubDepartment ? true : undefined,
      },
      departmentId,
    });
    return await super.createInternal(req);
  }

  async robotBusyTimeRank(
    departmentId: string,
    query: DashboardQueryData,
    containsSubDepartment: boolean
  ): Promise<RobotBusyTimeRank[]> {
    const req = this.buildRequestV2({
      url: `/v2/dashboard/robots/busytimerank`,
      method: "GET",
      query: {
        ...query,
        containsSubDepartment: containsSubDepartment ? true : undefined,
      },
      departmentId,
    });
    return await super.createInternal(req);
  }
  /**
   * 队列监控仪表盘
   */
  async queueAssociatedCount(
    departmentId: string,
    query: DashboardQueryData,
    containsSubDepartment: boolean
  ): Promise<QueueAssociatedCount> {
    const req = this.buildRequestV2({
      url: `/v2/dashboard/queues/associatedcount`,
      method: "GET",
      query: {
        ...query,
        containsSubDepartment: containsSubDepartment ? true : undefined,
      },
      departmentId,
    });
    return await super.createInternal(req);
  }

  async queueExecutionDetail(
    departmentId: string,
    query: DashboardQueryData,
    containsSubDepartment: boolean
  ): Promise<QueueExecutionDetail> {
    const req = this.buildRequestV2({
      url: `/v2/dashboard/queues/executiondetail`,
      method: "GET",
      query: {
        ...query,
        containsSubDepartment: containsSubDepartment ? true : undefined,
      },
      departmentId,
    });
    return await super.createInternal(req);
  }

  async queueSuccessProportionRank(
    departmentId: string,
    query: DashboardQueryData,
    containsSubDepartment: boolean
  ): Promise<QueueSuccessProportionRank[]> {
    const req = this.buildRequestV2({
      url: `/v2/dashboard/queues/successproportionrank`,
      method: "GET",
      query: {
        ...query,
        containsSubDepartment: containsSubDepartment ? true : undefined,
      },
      departmentId,
    });
    return await super.createInternal(req);
  }

  async queueFailureProportionRank(
    departmentId: string,
    query: DashboardQueryData,
    containsSubDepartment: boolean
  ): Promise<QueueFailureProportionRank[]> {
    const req = this.buildRequestV2({
      url: `/v2/dashboard/queues/failureproportionrank`,
      method: "GET",
      query: {
        ...query,
        containsSubDepartment: containsSubDepartment ? true : undefined,
      },
      departmentId,
    });
    return await super.createInternal(req);
  }

  async queueJobStateCount(
    departmentId: string,
    query: DashboardQueryData,
    containsSubDepartment: boolean
  ): Promise<QueueJobStateCount> {
    const req = this.buildRequestV2({
      url: `/v2/dashboard/queues/runstatecount`,
      method: "GET",
      query: {
        ...query,
        containsSubDepartment: containsSubDepartment ? true : undefined,
      },
      departmentId,
    });
    return await super.createInternal(req);
  }

  async queueBusyTimeRank(
    departmentId: string,
    query: DashboardQueryData,
    containsSubDepartment: boolean
  ): Promise<QueueBusyTimeRank[]> {
    const req = this.buildRequestV2({
      url: `/v2/dashboard/queues/busytimerank`,
      method: "GET",
      query: {
        ...query,
        containsSubDepartment: containsSubDepartment ? true : undefined,
      },
      departmentId,
    });
    return await super.createInternal(req);
  }

  async queueWaitTimeRank(
    departmentId: string,
    query: DashboardQueryData,
    containsSubDepartment: boolean
  ): Promise<QueueWaitTimeRank[]> {
    const req = this.buildRequestV2({
      url: `/v2/dashboard/queues/waittimerank`,
      method: "GET",
      query: {
        ...query,
        containsSubDepartment: containsSubDepartment ? true : undefined,
      },
      departmentId,
    });
    return await super.createInternal(req);
  }
  /**
   * 队列监控仪表盘end
   */

  //仪表盘时间范围的限制
  async timerangelimit(): Promise<TimeRangeLimit> {
    const req = this.buildRequestV2({
      url: `/v2/dashboard/timerangelimit`,
      method: "GET",
    });
    return await super.createInternal(req);
  }
}
