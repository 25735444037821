import { DailyUserFlowInfo, PerUserFlowInfo } from "@encoo-web/encoo-lib";
import moment from "moment";
import { createModel } from "nyax";
import { DashboardQueryData } from "src/models/dashboard";
import { ModelBase } from "src/store/ModelBase";

export const UserFlowStatisticsUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        dateRange: {
          startDate: moment().subtract(29, "days").format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
        },
        containsSubDepartment: false,
        dailyUserFlowDetail: null as DailyUserFlowInfo[] | null,
        perUserFlowDetail: null as PerUserFlowInfo[] | null,
      };
    }
    public reducers() {
      return {
        setDateRange: (
          dateRange: Pick<DashboardQueryData, "startDate" | "endDate">
        ) => {
          this.state.dateRange = dateRange;
        },
        setContainsSubDepartment: (value: boolean) => {
          this.state.containsSubDepartment = value;
        },
        setDailyUserFlowDetail: (value: DailyUserFlowInfo[] | null) => {
          this.state.dailyUserFlowDetail = value;
        },
        setPerUserFlowDetail: (value: PerUserFlowInfo[] | null) => {
          this.state.perUserFlowDetail = value;
        },
      };
    }

    public effects() {
      return {
        initializeRequest: async (payload: {
          departmentId: string;
          query: DashboardQueryData;
          containsSubDepartment: boolean;
        }) => {
          const value =
            await this.dependencies.serviceClient.dashboard.userFlowstatistics(
              payload.departmentId,
              payload.query,
              payload.containsSubDepartment
            );
          await this.actions.setDailyUserFlowDetail.dispatch(
            value.dailyUserFlowDetails ?? null
          );
          await this.actions.setPerUserFlowDetail.dispatch(
            value.perUserFlowDetails ?? null
          );
        },
      };
    }
  }
);
