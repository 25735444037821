// eslint-disable-next-line import/no-anonymous-default-export
export default {
  dataPermission: {
    dataPermissionMode: {
      title: "数据权限模式",
      inheritSuperiorAuthority: "是否受部门权限配置控制",
      subTitle:
        "若选择受部门权限配置控制，系统将会根据自定义权限及部门上的权限配置综合计算得出最终权限。",
      inherit: "继承上级部门",
      custom: "自定义",
      userType: {
        human: "用户权限",
        robot: "机器人权限",
      },
      department: "指定部门/用户",
      includeSubdepartment: "是否包含子部门",
      checkCurrDepartmentUserPermissions: "查看当前部门用户权限",
    },
    department: {
      title: "部门配置信息",
      owner: "拥有者",
      subordinateDepartments: "所属部门",
      managers: "管理者",
      manageSubtitle: "上级部门的拥有者会自动作为当前部门的管理员",
    },
    roleName: {
      companyAdmin: "公司管理员",
      organizationAdmin: "组织架构管理员",
      auditAdmin: "审计管理员",
      user: "普通用户",
    },
    user: "用户",
    role: "角色",
    selectRoles: "选择角色",
    selectPermissionValues: "选择权限",
    errorTip: "请重新修改！",
  },
};
