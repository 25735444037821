import { createModel, createSelector } from "nyax";
import { RUN_INSTANCE_STATE } from "src/containers/job/RunningMonitor";
import { PackageOutlineNode } from "src/models/package";
import { RunInstanceLog } from "src/models/runInstance";
import {
  JobLogReceivedResult,
  RunInstanceStateChangeReceivedResult
} from "src/services/SignalRConnection";
import { RunInstanceHelperModel } from "src/store/models/entity/runInstance/helper";
import { RunInstanceLogEntityModel } from "src/store/models/entity/runinstanceLog/entity";
import { createListModel } from "src/store/models/entity/_shared";
import { newGuid } from "src/utils/uuid";

export interface PackageOutlineItem {
  id: string;
  outlineFiles?: PackageOutlineFileItem[];
  packageId: string;
  versionId: string;
}

export interface PackageOutlineFileItem {
  fileName: string;
  rootNode: PackageOutlineNodeItem;
}
export interface PackageOutlineNodeItem {
  id: string;
  nodeId: string;
  name: string;
  subItems?: PackageOutlineNodeItem[];
}

export const RunningMonitorModel = createModel(
  class extends createListModel<RunInstanceLog>({
    setItems: (getContainer, items) =>
      getContainer(RunInstanceLogEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(RunInstanceLogEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(RunInstanceLogEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    private get _runInstanceId() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.containerKey!;
    }

    private _jobLogListenerCallback = async (result: JobLogReceivedResult) => {
      if (this._runInstanceId === result.runInstanceId) {
        await this.getContainer(
          RunInstanceLogEntityModel
        ).actions.batchUpdate.dispatch(result.logs);
        const ids = result.logs.map((log) => log.id);
        await this.actions.setSignalRLogIds.dispatch([
          ...ids,
          ...this.state.signalRLogIds,
        ]);
      }
    };

    private _runInstanceStateChangeCallback = async (
      result: RunInstanceStateChangeReceivedResult
    ) => {
      if (this._runInstanceId === result.runInstanceId) {
        const index = result.state as unknown as number;
        await this.actions.setRunInstanceState.dispatch(
          RUN_INSTANCE_STATE[index]
        );
      }
    };

    // private _runInstanceStateChange

    public initialState() {
      return {
        ...super.initialState(),
        outline: null as PackageOutlineItem | null,
        signalRLogIds: [] as string[],
        runInstanceState: null as string | null,
        isSendingAction: false,
        jobId: null as string | null,
      };
    }

    public reducers() {
      return {
        ...super.reducers(),
        setOutline: (outline: PackageOutlineItem | null) => {
          this.state.outline = outline;
        },
        setSignalRLogIds: (ids: string[]) => {
          this.state.signalRLogIds = ids;
        },
        setRunInstanceState: (state: string | null) => {
          this.state.runInstanceState = state;
        },
        setSendingAction: (value: boolean) => {
          this.state.isSendingAction = value;
        },
        setJobId: (value: string | null) => {
          this.state.jobId = value;
        },
      };
    }

    public selectors() {
      return {
        ...super.selectors(),
        mergedDataSource: createSelector(
          () => this.state.signalRLogIds,
          (): RunInstanceLog[] => this.getters.dataSource,
          (signalRLogIds, dataSource) => {
            const validateLogIds = signalRLogIds.filter(
              (id) => !dataSource.find((data) => data.id === id)
            );
            const validateLogs: RunInstanceLog[] = [];
            const byId = this.getContainer(RunInstanceLogEntityModel).state
              .byId;
            validateLogIds.forEach((id) => {
              const entity = byId[id];
              if (entity) {
                validateLogs.push(entity);
              }
            });

            validateLogs.sort((log1, log2) =>
              log1.createdAt > log2.createdAt
                ? -1
                : log1.createdAt === log2.createdAt
                ? 0
                : 1
            );
            return [...validateLogs, ...dataSource];
          }
        ),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        requestPackageOutline: async (payload: {
          packageId: string;
          versionId: string;
        }) => {
          const outline =
            await this.dependencies.serviceClient.package.getOutLineTree(
              payload.packageId,
              payload.versionId
            );

          const transferNode = (
            node: PackageOutlineNode
          ): PackageOutlineNodeItem => {
            const subItems = node.subItems?.map((n) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              return transferNode(n);
            });

            return {
              id: newGuid(),
              nodeId: node.nodeId,
              name: node.name,
              subItems,
            };
          };

          const files = outline.outlineFiles?.map((item) => {
            return {
              fileName: item.fileName,
              rootNode: transferNode(item.rootNode),
            };
          });

          await this.actions.setOutline.dispatch({
            id: outline.id,
            outlineFiles: files,
            packageId: outline.packageId,
            versionId: outline.versionId,
          });
        },

        requestRunningLogs: async () => {
          const runInstance = await this.getContainer(
            RunInstanceHelperModel
          ).actions.getById.dispatch(this._runInstanceId);
          await this.actions.setJobId.dispatch(runInstance?.jobId ?? null);
          await this.actions.setRunInstanceState.dispatch(
            runInstance?.displayState ?? null
          );
          try {
            this.dependencies.signalR.connection.init(runInstance?.robotId);
            await this.dependencies.signalR.connection.addJobLogListener(
              this._runInstanceId,
              this._jobLogListenerCallback
            );
            await this.dependencies.signalR.connection.addRunInstanceStateChangeListener(
              this._runInstanceId,
              this._runInstanceStateChangeCallback
            );
          } catch (error) {
            // 暂时不需要做其它事情
          }

          await this._initialIterator({
            initialAction: () =>
              this.dependencies.serviceClient.runInstance.getListLogsById(
                this._runInstanceId
              ),
            force: true,
          });
        },

        stop: async () => {
          await this.actions.setSendingAction.dispatch(true);
          try {
            const res = await this.dependencies.serviceClient.runInstance.stop(
              this._runInstanceId
            );
            if (
              res?.displayState !== "Paused" &&
              res?.displayState !== "Running"
            ) {
              await this.actions.setRunInstanceState.dispatch(
                res?.displayState
              );
            }
          } finally {
            await this.actions.setSendingAction.dispatch(false);
          }
        },

        pause: async () => {
          await this.actions.setSendingAction.dispatch(true);
          try {
            const res = await this.dependencies.serviceClient.runInstance.pause(
              this._runInstanceId
            );
            if (res?.displayState === "Paused") {
              await this.actions.setRunInstanceState.dispatch("Paused");
            }
          } finally {
            await this.actions.setSendingAction.dispatch(false);
          }
        },

        resume: async () => {
          await this.actions.setSendingAction.dispatch(true);
          try {
            const res =
              await this.dependencies.serviceClient.runInstance.resume(
                this._runInstanceId
              );
            if (res?.displayState === "Running") {
              await this.actions.setRunInstanceState.dispatch("Running");
            }
          } finally {
            await this.actions.setSendingAction.dispatch(false);
          }
        },

        clear: async () => {
          this.dependencies.signalR.connection.removeJobLogListener(
            this._jobLogListenerCallback
          );
          this.dependencies.signalR.connection.removeRunInstanceStateChangeListener(
            this._runInstanceStateChangeCallback
          );
          await this.actions.setSignalRLogIds.dispatch([]);
          await this.actions.setRunInstanceState.dispatch(null);
          // 暂时只有本页面需要SignalR，为了节省后台服务性能，退出页面先关闭SignalR。
          await this.dependencies.signalR.connection.close();
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
