import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";

export const LoadingUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        isShowLoading: false,
        closed: false,
      };
    }
    public reducers() {
      return {
        setIsShowLoading: (isShowLoading: boolean) => {
          this.state.isShowLoading = isShowLoading;
        },
        setClosed: (closed: boolean) => {
          this.state.closed = closed;
        },
      };
    }
    public effects() {
      return {
        registerLoading: async () => {
          this.dependencies.httpMiddleware.requestQueue.ActionRequestStart(
            () => {
              if (!this.state.isShowLoading && !this.state.closed)
                this.actions.setIsShowLoading.dispatch(true);
            }
          );

          this.dependencies.httpMiddleware.requestQueue.ActionRequestEnd(
            (request, allLoaded) => {
              if (allLoaded) this.actions.setIsShowLoading.dispatch(false);
            }
          );
        },
      };
    }
  }
);
