/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createModel, createSelector } from "nyax";
import { ModelBase } from "./ModelBase";

// eslint-disable-next-line @typescript-eslint/ban-types
export function createItemsEntityModel<TItem extends object>(
  getItemId: (item: TItem) => string
) {
  return createModel(
    class extends ModelBase {
      public initialState() {
        return {
          byId: {} as Record<string, TItem | undefined>,
          allIds: [] as string[],
        };
      }

      public selectors() {
        return {
          items: createSelector(
            () => this.state.byId,
            () => this.state.allIds,
            (byId, allIds) =>
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              allIds.map((id) => byId[id]!).filter((item) => item)
          ),
          getItems: createSelector(() => (ids: string[]) => {
            const byId = this.state.byId;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return ids.map((id) => byId[id]!).filter((item) => item);
          }),
        };
      }

      public reducers() {
        return {
          batchUpdate: (items: TItem[]) => {
            items.forEach((item) => {
              const id = getItemId(item);

              if (!(id in this.state.byId)) {
                this.state.allIds.push(id);
              }
              this.state.byId[id] = item;
            });
          },
          unshiftItem: async (item: TItem) => {
            const id = getItemId(item);
            this.state.allIds = [id, ...this.state.allIds];
            this.state.byId[id] = item;
          },
          batchDelete: (ids: string[]) => {
            const idSet = new Set(ids);
            this.state.allIds = this.state.allIds.filter(
              (id) => !idSet.has(id)
            );

            ids.forEach((id) => {
              delete this.state.byId[id];
            });
          },
        };
      }

      public effects() {
        return {
          clear: async () => {
            await this.actions.batchDelete.dispatch(this.state.allIds);
          },
          setItems: async (items: Array<TItem | string>) => {
            const updateMap = new Map<string, TItem>();
            const deleteSet = new Set<string>();

            items.forEach((item) => {
              if (typeof item === "string") {
                const id = item;
                updateMap.delete(id);
                deleteSet.add(id);
              } else {
                const id = getItemId(item);
                deleteSet.delete(id);
                updateMap.set(id, item);
              }
            });
            if (updateMap.size > 0) {
              await this.actions.batchUpdate.dispatch(
                Array.from(updateMap.values())
              );
            }
            if (deleteSet.size > 0) {
              await this.actions.batchDelete.dispatch(Array.from(deleteSet));
            }
          },
        };
      }
    }
  );
}
