// eslint-disable-next-line import/no-anonymous-default-export
export default {
  trigger: {
    basic: "基本",
    timedTask: "定时计划",
    advanced: "高级",
    advancedTips: "定时任务的其他配置参数，机器人运行时的策略点，如时区等",
    createTips:
      "定时任务通过配置定时计划、时区等， 让业务流程按照既定的时间策略调度运行。",
    source: "任务来源",
    name: "定时任务名称",
    preScheduleTime: "上次运行时间",
    nextScheduleTime: "下次运行时间",
    triggerConfig: "定时任务详情配置",
    executionTargetAndArguments: "执行目标及参数确认",
    triggerTime: "计划时间",
    setBeginTime: "定时任务开始时间",
    setEndTime: "定时任务结束时间",
    jobSetting: "任务设置",
    enableSuccessfully: "启用成功",
    disabledSuccessfully: "停用成功",
    executeSuccessfully: "执行成功",
    status: {
      all: "全部状态",
      enabled: "已启用",
      disabled: "未启用",
    },
    enableHandle: {
      enabled: "启用",
      disabled: "停用",
    },
    validate: {
      endTimeNotBeforeBeginTime: "停止时间不能早于开始时间",
      endTimeNotBeforeCurrentTime: "停止时间不能早于当前时间",
      pleaseInputRightcron: "请输入正确的内容",
    },
    timeType: {
      minute: "按分钟",
      hour: "按小时",
      day: "按天",
      week: "按周",
      month: "按月",
      once: "指定一个时间",
      cron: "按表达式",
      calendar: "按工作日历",
    },
    log: {
      operation: {
        createdTrigger: "定时计划被创建",
        createdJob: "成功创建一个任务",
        stopedTrigger: "停止定时计划",
        startedTrigger: "开启定时计划",
        editedTrigger: "编辑定时计划",
        updateFailedAndDisable: "因更新失败设为禁用",
        executeFailedWorkflowNoRobot: "执行失败：流程部署未关联机器人",
        executeFailedUnknownError: "执行失败：未知异常",
      },
      title: "定时任务日志",
      logName: "定时任务名称",
      logTime: "日志时间",
      content: "日志内容",
      operationName: "操作人",
    },
    timeZone: {
      name: "时区",
      tips: "定时计划的所属时区",
      default: "本机当前时区",
      placeholder: "默认时区-本机当前时区",
      options: {
        china: "(UTC+08:00) 北京，重庆，香港特别行政区，乌鲁木齐",
        japan: "(UTC+09:00) 大阪，札幌，东京",
        us: "(UTC-08:00) 太平洋时间 (美国和加拿大)",
      },
    },
    limitations: {
      name: "运行限制",
      calendar: {
        name: "按工作日历限制",
        tips: "一种运行限制方式，若机器人定时计划配置此策略，那么业务将不在工作日历天执行",
      },
    },
    triggerTask: "触发任务",
    cronExpressionTip:
      "以开始时间的{unit}为基准，并按照计划时间，循环触发任务执行",
    cronExpressionIntervalTip:
      "以开始时间的{unit}为基准，并按照计划时间，间隔循环执行",
    cronExpressionOnceTip: "任务将在指定时间执行一次。",
    cronExpressionCustomCronTip: "根据cron表达式执行。",
    cronExpressionCalendarTip: "按照工作日历，触发任务执行。",
    lastThreeExecutions: "未来最近3次执行时间：",
    noneCalculateResultAvailable: "暂无任何有效的执行时间。",
    monthlyOptions: {
      first: "第一个",
      second: "第二个",
      third: "第三个",
      fourth: "第四个",
      fifth: "第五个",
      firstDays: "第{number}天",
      countBackwardsFalse: "当月的",
      countBackwardsTrue: "当月的倒数",
    },
  },
};
