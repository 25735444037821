/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Tag } from "antd";
import React, { ReactNode, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  CompanyItem,
  CompanyItemType,
} from "src/containers/company/CompanySelect";
import { useFormatMessage, useGetContainer, useTheme } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { UserEdition } from "src/models/userInfo";
import { UserTenantUIModel } from "src/store/models/ui/tenant/userTenant";

export const useUserEditionState = function (): Record<
  UserEdition,
  {
    text: string;
    state: UserEdition;
    dom: ReactNode;
  }
> {
  const formatMessage = useFormatMessage();
  const theme = useTheme();

  const getDomRender = useCallback((name, color) => {
    return (
      <Tag
        css={css`
          line-height: 12px;
          padding: 1px 3px;
        `}
        color={color}
      >
        {name}
      </Tag>
    );
  }, []);

  return useMemo(
    () => ({
      Enterprise: {
        text: formatMessage(messageIds.userEdition.enterprise),
        state: "Enterprise",
        dom: getDomRender(
          formatMessage(messageIds.userEdition.enterprise),
          theme.buttonSpecial
        ),
      },
      Community: {
        text: formatMessage(messageIds.userEdition.community),
        state: "Community",
        dom: getDomRender(
          formatMessage(messageIds.userEdition.community),
          theme.rownColor
        ),
      },
    }),
    [formatMessage, getDomRender, theme.buttonSpecial, theme.rownColor]
  );
};

export default React.memo(function UserTenantSelect(props: {
  itemType: CompanyItemType;
  originPathname?: string;
}) {
  const userEditionState = useUserEditionState();
  const getContainer = useGetContainer();
  const userTenantUIContainer = getContainer(UserTenantUIModel);

  const { itemType, originPathname } = props;

  const userTenants = useSelector(
    () => userTenantUIContainer.state.userTenants
  );

  return (
    <div>
      {userTenants?.map((item) => {
        const { tenantId, displayName, edition, tenantName } = item;

        return (
          <CompanyItem
            id={tenantId}
            name={tenantName}
            userDisplayName={displayName}
            edition={userEditionState[edition]?.dom}
            key={tenantId}
            type={itemType}
            companyType="tenant"
            originPathname={originPathname}
          />
        );
      })}
    </div>
  );
});
