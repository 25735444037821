/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useState } from "react";
import Icon from "src/components/Icon";
import CompanySelect from "src/containers/company/CompanySelect";
import UserTenantSelect from "src/containers/company/UserTenantSelect";
import { useFormatMessage, useTheme } from "src/hooks";
import messageIds from "src/locales/messageIds";

export default React.memo(function CompanyComponent() {
  const formatMessage = useFormatMessage();
  const theme = useTheme();

  const [isShowTenants, setShowTenants] = useState(false);

  return (
    <div
      onMouseEnter={() => setShowTenants(true)}
      onMouseLeave={() => setShowTenants(false)}
      css={css`
        position: relative;
        height: 42px;
        &:hover {
          background-color: ${theme.lightPrimaryColor};
        }
      `}
    >
      <p
        css={css`
          display: flex;
          align-items: center;
          padding: 0 37px;
          line-height: 42px;
        `}
      >
        {formatMessage(messageIds.user.switchCompany)}
        <Icon
          name="icon-next-page"
          css={css`
            margin-left: 10px;
            font-size: 10px;
          `}
        />
      </p>
      {isShowTenants && (
        <div
          css={css`
            position: absolute;
            top: 0px;
            left: -140px;
            width: 140px;
            padding: 10px 0 5px 0;
            background: ${theme.bodyBackground};
            box-shadow: 0px 2px 4px 0px ${theme.bodyFrameShadowColor};
          `}
        >
          <UserTenantSelect itemType="item" />
          <CompanySelect itemType="item" />
          {/* TODO暂时隐藏此功能，需求有问题 */}
          {/* <Divider
            css={css`
              margin-top: 10px;
              margin-bottom: 5px;
            `}
          />
          <div
            css={css`
              text-align: center;
            `}
          >
            <Button onClick={onTenantMenuModalVisible} type="link">
              {formatMessage(messageIds.user.createTenant)}
            </Button>
          </div> */}
        </div>
      )}
    </div>
  );
});
