// eslint-disable-next-line import/no-anonymous-default-export
export default {
  workflow: {
    title: "流程部署",
    name: "流程部署名称",
    introduction:
      "流程部署主要用于对流程执行的目标、参数、重试、视频录制等进行配置，支持通过手动、定时任务等方式触发流程。",
    packageName: "流程包名称",
    version: "版本号",
    trigger: "定时任务",
    noTrigger: "无定时任务",
    batchUpdate: "更新流程包",
    batchExecute: "执行",
    batchUpdateConfirm:
      "更新流程包后，流程部署、定时任务、流程编排、任务队列中新版本参数将覆盖旧版本中的设置，可能会导致运行失败，请谨慎操作。确定是否更新？",
    batchUpdateWarning: "以下流程部署发生了变化，请及时处理：",
    config: "配置",
    packageVersion: "流程包版本",
    parameterInfo: "参数信息",
    performanceTarget: "执行目标",
    setExecutionTarget: "设置执行目标",
    robotOrQueue: "机器人/组",
    associateQueue: "关联机器人组",
    associateRobot: "关联机器人",
    saveAndSetTrigger: "保存并设置定时计划",
    email: "请填写正确的通知邮箱",
    emailPlaceholder: "请输入被通知人邮箱按回车确认",
    execute: "执行",
    workflowExecute: "流程执行",
    tryMaxCount: "失败最大尝试次数",
    tryMaxCountTip:
      "若流程任务执行失败，系统会根据设置的数值进行自动尝试，默认为3次，设置范围为0～10次",
    executionTimeout: "超时时间",
    executionTimeoutTip:
      "若流程因执行代码死循环或者因调用的软件/接口失去响应等原因导致执行时间过久，则会在到达设置的超时时间后终止流程执行。",
    runAsAdministrator: "以管理员权限运行",
    runAsAdministratorTip: "仅对1.1.2206.26以上版本机器人生效",
    enableArgumentsReAssignment: "开启参数重新赋值",
    createSuccess: "流程部署创建成功",
    bindRobotError: "机器人绑定失败",
    noSupportArgument: "不支持编辑复杂类型",
    importAsset: "导入资产",
    selectAsset: "选择资产",
    isEnableVideoRecord: "是否开启视频录制",
    open: "开",
    close: "关",
    alwaysReport: "全部回传",
    neverReport: "不回传",
    onlyWhenSucceededReport: "仅成功时回传",
    whenSuccessful: "成功时",
    whenFailed: "失败时",
    whenCancelled: "取消时",
    pleaseSelectEvent: "请选择事件",
    onlyWhenFailedReport: "仅失败时回传",
    videoRecordTip: "提示：可以在任务日志详情页中查看视频回放",
    executeOutTimeTip:
      "提示：指定流程部署执行时长超时时间，超过此时间则自动终止任务",
    videoReportCondition: "回传条件",
    priority: "任务优先级",
    priorityTip:
      "提示: 任务优先级最低为0，最高为5000，优先级越高任务将越优先执行",
    workflowId: "流程部署Id",
    executeOutTime: "执行超时",
    outTime: "超时时间",
    noResource: {
      title: "无可用机器人时",
      anyTime: "等待",
      skipWhenNoResource: "取消任务",
    },
    argument: "参数",
    addField: "添加字段",
    batchEditTip: "提示：流程部署关联的定时任务也会随之更改",
  },
};
