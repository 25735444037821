import { DataConnectionEntityModel } from "src/store/models/entity/dataConnection/entity";
import { DataConnectionHelperModel } from "src/store/models/entity/dataConnection/helper";
import { DataConnectionListModel } from "src/store/models/entity/dataConnection/list";
import { DataConnectionListEntityModel } from "src/store/models/entity/dataConnection/listEntity";

export const encooDataConnectionModels = {
  entity: DataConnectionEntityModel,
  listEntity: DataConnectionListEntityModel,
  list: DataConnectionListModel,
  helper: DataConnectionHelperModel,
};
