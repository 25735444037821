import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { AppTemplate, AppTemplateVersion } from "src/models/appTemplate";
import { EncooListEntity } from "src/models/_shared";

export const APP_TEMPLATE_SERVICE_NAME = "appTemplate";

export class AppTemplateServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, APP_TEMPLATE_SERVICE_NAME);
  }

  async getList(): Promise<EncooListEntity<AppTemplate>> {
    const req = this.buildRequestV2({
      url: `/v2/appTemplates`,
      method: "GET",
    });

    const { body } = await this.sendRequest(req);
    return body;
  }

  async getAll(): Promise<AppTemplate[]> {
    const req = this.buildRequestV2({
      url: `/v2/appTemplates`,
      method: "GET",
    });
    return super.getAllByPageInternal(req);
  }

  async getById(id: string): Promise<AppTemplate> {
    const req = this.buildRequestV2({
      url: `/v2/appTemplates/${id}`,
      method: "GET",
    });
    return super.getByIdInternal(req);
  }

  async getVersionList(
    id: string
  ): Promise<EncooListEntity<AppTemplateVersion>> {
    const req = this.buildRequestV2({
      url: `/v2/appTemplates/${id}/versions`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getAllVersionList(id: string): Promise<AppTemplateVersion[]> {
    const req = this.buildRequestV2({
      url: `/v2/appTemplates/${id}/versions`,
      method: "GET",
    });
    return await this.getAllByPageInternal(req);
  }

  async getByVersionId(
    id: string,
    versionId: string
  ): Promise<AppTemplateVersion> {
    const req = this.buildRequestV2({
      url: `/v2/appTemplates/${id}/versions/${versionId}`,
      method: "GET",
    });
    return super.getByIdInternal(req);
  }
}
