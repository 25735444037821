import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import {
  DataPermissionInfo,
  DataPermissionPayloadData,
  DataPermissionUpdataData,
  PermissionTable,
} from "src/models/dataPermission";

export const DATAPERMISSION_SERVICE_NAME = "datapermission";

export class DataPermissionsServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, DATAPERMISSION_SERVICE_NAME);
  }

  async getById(payload: {
    resourceType: string;
    id: string;
  }): Promise<DataPermissionInfo> {
    const { resourceType, id } = payload;
    const req = this.buildRequest({
      url: `/v2/permissions/${resourceType}/${id}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async update(payload: {
    resourceType: string;
    id: string;
    dataPermissionInfo: DataPermissionUpdataData;
  }): Promise<DataPermissionInfo> {
    const { resourceType, id, dataPermissionInfo } = payload;
    const req = this.buildRequest({
      url: `/v2/permissions/${resourceType}/${id}`,
      method: "PATCH",
      payload: dataPermissionInfo,
    });
    return await super.updateInternal(req);
  }
  async getBatchPermissionData(
    payload: DataPermissionPayloadData[]
  ): Promise<DataPermissionInfo[]> {
    const req = this.buildRequest({
      url: `/v2/permissions/query`,
      method: "POST",
      payload,
    });
    return await super.getByIdInternal(req);
  }

  async getPermissionTable(payload: {
    resourceType: string;
    id: string;
    query?: {
      permissionTagId?: string;
      //   permissionValue?: PermissionValue;
    };
  }): Promise<PermissionTable> {
    const { resourceType, id, query } = payload;
    const req = this.buildRequest({
      url: `/v2/permissions/${resourceType}/${id}/permissionTable`,
      method: "GET",
      query,
    });
    return await super.getByIdInternal(req);
  }
}
