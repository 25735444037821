/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Col, Row, Select } from "antd";
import { memo, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import SearchInput from "src/components/SearchInput";
import { useFormatMessage, useGetContainer } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { DataConnectionQuery } from "src/models/dataConnection";
import { DataConnectionUiModel } from "src/store/models/ui/dataConnection/dataConnection";

export default memo(function DataConnectionSearch({
  query,
  onSearch,
}: {
  query: DataConnectionQuery | null;
  onSearch: (value: Partial<DataConnectionQuery>) => void;
}) {
  const formatMessage = useFormatMessage();
  const getContainer = useGetContainer();
  const dataConnectionContainer = getContainer(DataConnectionUiModel);
  const dataConnectionTemplateInfos = useSelector(
    () => dataConnectionContainer.state.dataConnectionTemplateInfos
  );

  const typeList = useMemo(() => {
    return dataConnectionTemplateInfos?.map((item) => ({
      label: item.name,
      value: item.type,
    }));
  }, [dataConnectionTemplateInfos]);

  const onNameChange = useCallback(
    (value) => {
      onSearch({
        nameLike: value,
      });
    },
    [onSearch]
  );

  const onTypeChange = useCallback(
    (value) => {
      onSearch({
        type: value,
      });
    },
    [onSearch]
  );

  return (
    <Row gutter={[24, 24]}>
      <Col>
        <SearchInput
          value={query?.nameLike}
          onSearch={onNameChange}
          width={"320"}
          placeholder={formatMessage(messageIds.common.searchKeyPlaceholder)}
        />
      </Col>
      <Col>
        <Select
          value={query?.type}
          onChange={onTypeChange}
          placeholder={formatMessage(
            messageIds.dataConnection.searchPlaceholder.type
          )}
          options={typeList}
          allowClear
          dropdownMatchSelectWidth
          css={css`
            width: 320px;
          `}
        ></Select>
      </Col>
    </Row>
  );
});
