// eslint-disable-next-line import/no-anonymous-default-export
export default {
  organization: {
    description:
      "Organizational Structure Management is mainly used to manage the departments and users in the company, which can also simultaneously control various permissions of the related departments, users, and robots.",
    sync: "Synchronize",
    tabs: {
      user: "User Management",
      dataPermission: "Data Permission Configuration",
      functionPermission: "Function Configuration",
      departmentConfig: "Department Information Configuration",
      companyConfig: "Company Info Configuration",
    },

    functionPermission: {
      entityTypeName: "Function",
      affiliationDepartment: "Affiliated Department",
      affiliationCompany: "Affiliated Company",
      departmentPermissionConfig: "Department Function Configuration",
      departmentPermissionConfigTip:
        "Please select the function enabled by the current department.",
    },
    company: {
      entityTypeName: "Company",
      companyName: "Company Name",
    },
    department: {
      entityTypeName: "Department",
      departmentName: "Department Name",
      lowerCaseDepartmentName: "Department Name",
      createSubDepartment: "Create Sub-department",
      departmentUserCount: "Number of people in each department",
    },
    departmentUsers: {
      details: "User Details",
      inviteUser: "Invite User",
      userDepartment: "Affiliated Department",
      userRole: "Affiliated Role",
      role: "Role",
      currDepartmentRole: "Current Department Role",
      edit: "Edit User Information",
      departmentchange: "Transfer Department",
      disable: "Disable",
      enable: "Enable",
      delete: "Delete",
      userEmail: "User Email Address",
      userName: "User Name",
      cellphoneUser: "Mobile Phone User/No Mailbox",
      userInfo: "User Information",
      email: "Email Address",
      description: "Remarks",
      changeDepartmentTip: "Transfer the user {name} to:",
      lastLoginAt: "Last Login Time",
      confirmAndContinue: "Confirm and Continue to Add",
      changeRoleTitle: "Change Role",
      changeRoleLabel: "Please select the role to be changed",
      roleChange: "Change Role",
      private: {
        createUser: "Create Users",
        placeholder: {
          userName: "Please fill in the user's name in the current company",
          email:
            "Please enter the account to be created, please use the email address",
          password: "Please enter the passwords",
        },
        tip: "Notice: After the creation is complete, please send the account and password to the user. The user will be activated after first time logging in the for changing the password.",
      },
    },
    noMatch: "No Matching Results",
  },
};
