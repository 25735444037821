// eslint-disable-next-line import/no-anonymous-default-export
export default {
  license: {
    entityTypeName: "许可证",
    introduction:
      "本页面用于展示及管理控制台各类许可证或服务的授权及使用情况。",
    switch: {
      desc: "仅显示有效期内的许可证",
      open: "开",
      close: "关",
    },
    tabs: {
      details: "许可证详情",
      logs: "激活记录",
    },
    table: {
      id: "编码",
      user: "当前用户",
      machineName: "机器名称",
      name: "机器人名称",
      bindTime: "绑定时间",
      importTime: "导入时间",
      importUser: "导入用户",
      authorizeDate: "授权日期",
      startToStopTime: "许可证起止时间",
      licenseBindingNumber: "所属许可证编号",
      emptyCase: {
        empty: "暂无",
        data: "数据",
        active: "激活",
        connect: "连接",
        publish: "发布",
      },
    },
    licenseDetailModal: {
      applyType: "申请许可证类型",
      defaultApplyType: "控制台许可证",
      contractNumber: "合同号",
      customerName: "客户名称",
      validityPeriod: "许可证有效期",
      uniqueCode: "机器码",
      quotaOfStudioInEnterpriseEdition: "固定编辑器可激活总量",
      quotaOfStudioInFloatingEnterpriseEdition: "浮动编辑器可激活总量",
      quotaOfRobotInEnterpriseEdition: "固定机器人可激活总量",
      quotaOfRobotInFloatingEnterpriseEdition: "浮动机器人可激活总量",
      quotaOfConsoleInEnterpriseEdition: "企业工作台数量",
      quotaOfVicode: "ViCode应用可上架总量",
      quotaOfDUApiPdf: "文档理解模板可调用总量（PDF）",
      quotaOfDUApiOcr: "文档理解模板可调用总量（OCR）",
      desc: "备注",
    },
    button: {
      apply: "申请许可证",
      applyEnterprise: "申请企业版",
      assign: "分配许可证",
      activation: "激活许可证",
      detail: "查看使用情况",
      licenseDetail: "查看许可详情",
      remove: {
        studioAndRobot: "解除绑定",
        vicode: "取消发布",
      },
    },
    tooltip: {
      switchOpen: "已绑定量 / 有效总量",
      switchClose: "已绑定量 / 总量",
    },
    editor: {
      name: "企业版编辑器",
      descriptions: "已经被激活的编辑器数量",
    },
    robot: {
      name: "企业高级版机器人",
      descriptions: "已经被连接的机器人数量",
    },
    viCode: {
      title: "Vicode",
      name: "应用",
      descriptions: "已经被发布出来的应用数量",
      columns: {
        index: "编码",
        name: "应用名称",
        department: "所属部门",
        operation: "操作",
      },
    },
    documentUnderstanding: {
      name: "文档理解服务",
      descriptions: "已经被调用的文档理解服务数量",
    },
    documentUnderstandingTable: {
      modelType: "内置模型类型",
      total: "总量",
      used: "使用量",
      rest: "剩余量",
      useOf: "使用占比",
      PDF: "电子版PDF抽取模型",
      OCR: "OCR抽取模型",
    },
    dailog: {
      demand: "需求描述",
      demandPlaceholder: "请告知您的需求数量，比如需多少个机器人，多少进程等…",
      feedback:
        "我们收到请求后将会尽快与您取得联系，如需求紧急， 请直接拨打电话: 400-639-2198",
      customer: "客户名称（标识码）",
      licensePlaceholder: "请输入许可证编号",
      sendScuess: "许可证申请成功已发送",
      sendFailed: "许可证申请失败",
      licenseSerialNumber: "请输入许可证编号",
      unbindQuestions: "确认解绑许可证？",
      unbindPrompt: "解绑后，已绑定的{name}将不可使用。",
      unpublishQuestions: "确认取消发布？",
      unpublishPrompt: "取消发布后，应用将不可使用。",
    },
    messageInfo: {
      activateSuccess: "激活成功",
      activateFailed: "激活失败",
    },
    activateTip: {
      ifNoLicense: "如果您还没有企业版许可证，请",
      tenantIdTip: "标识码用于申请许可证时提供给云扩工作人员",
    },
  },
};
