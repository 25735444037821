import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";

export const COEMYWORK_SERVICE_NAME = "conversation";
export class ConversationService extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, COEMYWORK_SERVICE_NAME);
  }

  async getQRCode(): Promise<{ url: string }> {
    const req = this.buildRequestV2({
      url: `/v2/lamoss/starturl`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
}
