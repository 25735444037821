import dd from "dingtalk-jsapi";
import _ from "lodash";
import { createModel } from "nyax";
import { filter, map, pairwise } from "rxjs/operators";
import { appRouteDefinitions } from "src/routes";
import { ModelBase } from "src/store/ModelBase";
import { CurrentFeatureModel } from "src/store/models/logic/currentFeature";
import { CurrentPermissionModel } from "src/store/models/logic/currentPermission";
import { GlobalSelectCompanyUIModel } from "src/store/models/logic/globalSelectCompany";
import { GlobalSelectDepartmentModel } from "src/store/models/logic/globalSelectDepartment";
import { RouterModel } from "src/store/models/router";
import { CompanyUIModel } from "src/store/models/ui/company/company";
import { EnvironmentModel } from "src/store/models/ui/environment/environment";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";

export const AppModel = createModel(
  class extends ModelBase {
    private get _loginUserUIContainer() {
      return this.getContainer(LoginUserUIModel);
    }

    private get _environmentContainer() {
      return this.getContainer(EnvironmentModel);
    }

    private get _globalSelectDepartmentContainer() {
      return this.getContainer(GlobalSelectDepartmentModel);
    }

    private get _router() {
      return this.getContainer(RouterModel);
    }

    private get _currentPermissionContainer() {
      return this.getContainer(CurrentPermissionModel);
    }

    private get _currentFeatureContainer() {
      return this.getContainer(CurrentFeatureModel);
    }

    public initialState() {
      return {
        isInitialized: false,
      };
    }

    public reducers() {
      return {
        initializeSuccess: () => {
          this.state.isInitialized = true;
        },
      };
    }

    public effects() {
      return {
        initializeRequest: async () => {
          let originPathname = "";
          if (!window.name) {
            if (sessionStorage.getItem("originalPathName")) {
              if (
                sessionStorage
                  .getItem("originalPathName")
                  ?.includes("companyEntrySelect")
              ) {
                sessionStorage.setItem("originalPathName", "/");
              }
            } else {
              sessionStorage.setItem(
                "originalPathName",
                this._router.getters.pathname.includes("companyEntrySelect")
                  ? "/"
                  : this._router.getters.pathname
              );
            }
            originPathname = sessionStorage.getItem("originalPathName") ?? "/";
          } else {
            if (this._router.getters.pathname.includes("companyEntrySelect")) {
              originPathname = "/";
            } else {
              originPathname = this._router.getters.pathname;
            }
          }

          const availPathNames = Object.values(appRouteDefinitions)
            .filter((item) => !_.includes(item.path, ":"))
            .map((item) => item.path);

          const actualPathName = _.includes(availPathNames, originPathname)
            ? originPathname
            : "/";

          await this._router.actions.setOriginPathname.dispatch(actualPathName);

          if (window._settings.engine === "dingtalk") {
            await new Promise<void>((resolve) => dd.ready(resolve));
          }

          const user = await this._loginUserUIContainer.actions.login.dispatch(
            null
          );

          if (!user) {
            return;
          }

          await this.getContainer(
            CompanyUIModel
          ).actions.getAllCompany.dispatch(null);

          const currentRouteInfo = this._router.getters.currentRouteInfo;

          if (currentRouteInfo.type === "companyEntrySelect") {
            await this.actions.initializeSuccess.dispatch({});
            return;
          }

          await this.getContainer(
            GlobalSelectCompanyUIModel
          ).actions.initSelectedCompany.dispatch({});
          await this._environmentContainer.actions.initEnvironmentDeploy.dispatch(
            {}
          );
          await this.getContainer(
            CurrentPermissionModel
          ).actions.requestPermission.dispatch({});

          await this.actions.initializeSuccess.dispatch({});

          window.name = "console";
        },
      };
    }
    public epics() {
      return {
        resetPermissionsWhenSelectedDepartmentIdChanged: () =>
          this.rootAction$.pipe(
            filter(() => this.state.isInitialized),
            map(
              () =>
                this._globalSelectDepartmentContainer.state.selectedDepartmentId
            ),
            pairwise(),
            filter(([oldSelectedDepartmentId, newSelectedDepartmentId]) => {
              return oldSelectedDepartmentId !== newSelectedDepartmentId;
            }),
            map(() =>
              this.getContainer(
                CurrentPermissionModel
              ).actions.requestPermission.create({})
            )
          ),
      };
    }
  }
);
