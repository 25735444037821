import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";

export const CoeAttachmentUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        rowKeys: [] as string[],
      };
    }
    public reducers() {
      return {
        setRowKeys: (value: string[]) => {
          this.state.rowKeys = value;
        },
      };
    }
    public selectors() {
      return {};
    }
    public effects() {
      return {};
    }
  }
);
