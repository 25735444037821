export function handleErrorNotFound(e: unknown, callback?: () => void): void {
  const error = e as
    | { ishandled?: boolean; response?: { status: number } }
    | undefined;
  if (error?.response) {
    const { status } = error?.response;
    if (status === 404) {
      error.ishandled = true;
      callback?.();
    }
  }
}
