import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import {
  License,
  LicenseApplyPlayLoad,
  LicenseBinding,
  LicenseBindingData,
  LicenseBindingQueryData,
  LicenseCountersData,
  LicenseStatistics,
  ProductNameOfLicenseCounter,
} from "src/models/license";
export const LICENSEBINDINGS_SERVICE_NAME = "licenseBindings";

export class LicenseBindingsServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, LICENSEBINDINGS_SERVICE_NAME);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async list(resourceGroupId?: string) {
    const req = this.buildRequest({
      url: "/v1/licenses",
      method: "GET",
      resourceGroupId,
    });
    return await super.listInternally<License>(req);
  }

  async getAll(resourceGroupId?: string): Promise<Array<License>> {
    const req = this.buildRequest({
      url: `/v1/licenses`,
      method: "GET",
      resourceGroupId,
    });
    return await super.getAllInternal(req);
  }

  async getAllLicenseBindings(
    query?: LicenseBindingQueryData
  ): Promise<Array<LicenseBinding>> {
    const { productName, sku, binded, boundToId } = query || {};
    const req = this.buildRequest({
      url: `/v1/licenseBindings`,
      method: "GET",
      query: {
        productName,
        sku,
        binded,
        boundToId,
      },
    });
    return await super.getAllInternal(req);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async listLicenseBindings(query?: LicenseBindingQueryData) {
    const { productName, sku, binded, boundToId } = query || {};
    const req = this.buildRequest({
      url: `/v1/licenseBindings`,
      method: "GET",
      query: {
        productName,
        sku,
        binded,
        boundToId,
      },
    });
    return await super.listInternally<LicenseBinding>(req);
  }

  async getLicenseByBoundToId(boundToId: string): Promise<LicenseBinding> {
    const req = this.buildRequest({
      url: `/v1/licenseBindings`,
      method: "GET",
      query: {
        boundToId,
      },
    });
    return await super.getByIdInternal(req);
  }

  async importLicence(payload: string): Promise<License> {
    const req = this.buildRequest({
      url: `/v1/licenses`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  async licenseBindingsBindTo(
    payload: LicenseBindingData
  ): Promise<LicenseBinding> {
    const req = this.buildRequest({
      url: `/v1/licenseBindings/bindTo`,
      method: "PUT",
      payload,
    });
    return await super.createInternal(req);
  }

  async getLicenseBindingsById(
    licenseId: string
  ): Promise<Array<LicenseBinding>> {
    const req = this.buildRequest({
      url: `/v1/licenses/${licenseId}/licenseBindings`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async getLicenseById(licenseBindingId: string): Promise<LicenseBinding> {
    const req = this.buildRequest({
      url: `/v1/licenseBindings/${licenseBindingId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async bindTo(
    licenseBindingId: string,
    payload: LicenseBindingData
  ): Promise<LicenseBinding> {
    const req = this.buildRequest({
      url: `/v1/LicenseBindings/${licenseBindingId}/bindTo`,
      method: "PUT",
      payload,
    });
    return await super.createInternal(req);
  }
  async bindToForUnboundLicense(
    payload: LicenseBindingData,
    tenantId: string,
    resourceGroupId: string
  ): Promise<LicenseBinding> {
    const req = this.buildRequest({
      url: `/v1/LicenseBindings/bindTo`,
      method: "PUT",
      payload,
      tenantId,
      resourceGroupId,
    });
    return await super.createInternal(req);
  }

  async unbind(licenseBindingId: string, boundToId: string): Promise<boolean> {
    const req = this.buildRequest({
      url: `/v1/licenseBindings/${licenseBindingId}/bindTo/${boundToId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }
  async applyLicense(payload: LicenseApplyPlayLoad): Promise<boolean> {
    const req = this.buildRequest({
      url: `/v1/LicenseApply`,
      method: "POST",
      payload,
    });
    return await this.sendRequest(req).then((res) => {
      if (
        res.status === 200 ||
        res.status === 204 ||
        (res.status === 201 && res.body)
      ) {
        return true;
      }
      return false;
    });
  }

  async getLicensecounters(
    type: ProductNameOfLicenseCounter
  ): Promise<LicenseCountersData[]> {
    const req = this.buildRequest({
      url: `/v1/licensecounters?productName=${type}`,
      method: "GET",
    });
    return await super.getAllInternal(req);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async listV2() {
    const req = this.buildRequest({
      url: "/v2/licenses",
      method: "GET",
    });
    return await super.listInternally<License>(req);
  }

  async getAllV2(): Promise<Array<License>> {
    const req = this.buildRequestV2({ url: `/v2/licenses`, method: "GET" });
    return await super.getAllInternal(req);
  }

  async getAllLicenseBindingsV2(
    query?: LicenseBindingQueryData
  ): Promise<LicenseBinding> {
    const { productName, sku, binded, boundToId } = query || {};
    const req = this.buildRequestV2({
      url: `/v2/licenseBindings`,
      method: "GET",
      query: {
        productName,
        sku,
        binded,
        boundToId,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async listLicenseBindingsV2(query?: LicenseBindingQueryData) {
    const { productName, sku, available, boundToId } = query || {};
    const req = this.buildRequestV2({
      url: `/v2/licenseBindings`,
      method: "GET",
      query: {
        productName,
        sku,
        available,
        boundToId,
      },
    });
    return await super.listInternally<LicenseBinding>(req);
  }

  async getLicenseByBoundToIdV2(boundToId: string): Promise<LicenseBinding> {
    const req = this.buildRequestV2({
      url: `/v2/licenseBindings`,
      method: "GET",
      query: {
        boundToId,
      },
    });
    return await super.getByIdInternal(req);
  }

  async importLicenceV2(payload: string): Promise<License> {
    const req = this.buildRequestV2({
      url: `/v2/licenses`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  async licenseBindingsBindToV2(
    payload: LicenseBindingData
  ): Promise<LicenseBinding> {
    const req = this.buildRequestV2({
      url: `/v2/licenseBindings/bindTo`,
      method: "PUT",
      payload,
    });
    return await super.createInternal(req);
  }

  async getLicenseBindingsByIdV2(
    licenseId: string
  ): Promise<Array<LicenseBinding>> {
    const req = this.buildRequestV2({
      url: `/v2/licenses/${licenseId}/licenseBindings`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async getLicenseByIdV2(licenseBindingId: string): Promise<LicenseBinding> {
    const req = this.buildRequestV2({
      url: `/v2/licenseBindings/${licenseBindingId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async bindToV2(
    licenseBindingId: string,
    payload: LicenseBindingData
  ): Promise<LicenseBinding> {
    const req = this.buildRequestV2({
      url: `/v2/LicenseBindings/${licenseBindingId}/bindTo`,
      method: "PUT",
      payload,
    });
    return await super.createInternal(req);
  }

  async bindToForUnboundLicenseV2(
    payload: LicenseBindingData
  ): Promise<LicenseBinding> {
    const req = this.buildRequestV2({
      url: `/v2/LicenseBindings/bindTo`,
      method: "PUT",
      payload,
    });
    return await super.createInternal(req);
  }

  async unbindV2(
    licenseBindingId: string,
    boundToId: string
  ): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/licenseBindings/${licenseBindingId}/bindTo/${boundToId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }

  async applyLicenseV2(payload: LicenseApplyPlayLoad): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/LicenseApply`,
      method: "POST",
      payload,
    });
    return await this.sendRequest(req).then((res) => {
      if (
        res.status === 200 ||
        res.status === 204 ||
        (res.status === 201 && res.body)
      ) {
        return true;
      }
      return false;
    });
  }

  async getLicensecountersV2(
    type: ProductNameOfLicenseCounter
  ): Promise<LicenseCountersData[]> {
    const req = this.buildRequestV2({
      url: `/v2/licensecounters?productName=${type}`,
      method: "GET",
    });
    return await super.getAllInternal(req);
  }

  async licenseStatisticsV2(available?: boolean): Promise<LicenseStatistics> {
    const req = this.buildRequestV2({
      url: `/v2/LicenseBindings/statistics`,
      method: "GET",
      query: {
        available,
      },
    });
    return await super.createInternal(req);
  }
}
