import { ServiceCredential } from "@encoo-web/encoo-lib/types";
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
  MessageHeaders
} from "@microsoft/signalr";
import { RunInstanceLog, RuninstanceState } from "src/models/runInstance";

export interface JobLogReceivedResult {
  runInstanceId: string;
  logs: RunInstanceLog[];
}

export type JobLogListenerCallback = (result: JobLogReceivedResult) => void;

export interface RunInstanceStateChangeReceivedResult {
  runInstanceId: string;
  state: RuninstanceState;
}

export type RunInstanceStateChangeListenerCallback = (
  result: RunInstanceStateChangeReceivedResult
) => void;

export class SignalRConnection {
  protected hubConnection?: HubConnection;
  protected credential: ServiceCredential;
  protected url: string;
  protected robotId?: string;

  constructor(url: string, credential: ServiceCredential) {
    this.url = url;
    this.credential = credential;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected async invoke<T = any>(
    methodName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...args: any[]
  ): Promise<T> {
    if(this.hubConnection === undefined){
      this.createConnection();
    }
    if(this.hubConnection === undefined)
      throw new Error("connection not inited");

    if (this.hubConnection.state === "Disconnected" ||
      this.hubConnection.state === "Disconnecting"
    ) {
      await this.hubConnection.start();
    }

    return this.hubConnection.invoke(methodName, ...args);
  }

  protected createConnection():void {
    let headers: MessageHeaders | undefined;
    if(this.robotId !== undefined){
      headers = {
        "RobotId": this.robotId
      }
    }
    this.hubConnection = new HubConnectionBuilder()
    .withUrl(`${this.url}/v2/clienthub`, {
      withCredentials: false,
      accessTokenFactory: async () => {
        return (await this.credential.getAccessToken({}))?.token ?? "";
      },
      headers: headers
    })
    .withAutomaticReconnect()
    .configureLogging(LogLevel.None)
    .build();
  }

  public init(robotId: string | undefined):void{
    this.robotId = robotId;
    this.createConnection();
  }

  public async addJobLogListener(
    runInstanceId: string,
    callback: JobLogListenerCallback
  ): Promise<void> {
    this.hubConnection?.on("JobLogsRecevied", callback);
    await this.invoke("RequestJobLogs", runInstanceId);
  }

  public removeJobLogListener(callback: JobLogListenerCallback): void {
    this.hubConnection?.off("JobLogsRecevied", callback);
  }

  public async addRunInstanceStateChangeListener(
    runInstanceId: string,
    callback: RunInstanceStateChangeListenerCallback
  ): Promise<void> {
    this.hubConnection?.on("RunInstanceStateChangeRecevied", callback);
    await this.invoke("RequestRunInstanceState", runInstanceId);
  }

  public removeRunInstanceStateChangeListener(
    callback: RunInstanceStateChangeListenerCallback
  ): void {
    this.hubConnection?.off("RunInstanceStateChangeRecevied", callback);
  }

  public async close(): Promise<void> {
    this.hubConnection?.stop();
  }
}
