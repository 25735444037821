import { createModel } from "nyax";
import { EnvironmentEdition } from "src/models/environment";
import { ModelBase } from "src/store/ModelBase";
import { LicenseHelperModel } from "src/store/models/entity/license/helper";
import { GlobalSelectCompanyUIModel } from "src/store/models/logic/globalSelectCompany";
import { RouterModel } from "src/store/models/router";

export const EnvironmentModel = createModel(
  class extends ModelBase {
    private get _licenseBindingsHelper() {
      return this.getContainer(LicenseHelperModel);
    }

    public initialState() {
      return {
        environmentEdition: null as EnvironmentEdition | null,
        environmentUniqueCode: null as string | null,
        environmentVersion: null as string | null,
        hasLicenseAuthorization: false,
      };
    }

    public reducers() {
      return {
        setEnvironmentEdition: (value: EnvironmentEdition | null) => {
          this.state.environmentEdition = value;
        },
        setEnvironmentUniqueCode: (value: string | null) => {
          this.state.environmentUniqueCode = value;
        },
        setEnvironmentVersion: (value: string | null) => {
          this.state.environmentVersion = value;
        },
        setHasLicenseAuthorization: (value: boolean) => {
          this.state.hasLicenseAuthorization = value;
        },
      };
    }

    public effects() {
      return {
        initEnvironmentDeploy: async () => {
          const selectedCompanyId = this.getContainer(
            GlobalSelectCompanyUIModel
          ).state.selectedGlobalComponyId;

          if (selectedCompanyId) {
            await this.actions.updateEnvironmentSystemInfo.dispatch({});
            if (this.state.environmentEdition === "Private") {
              await this.actions.updateEnvironmentUniqueCode.dispatch({});
              await this.actions.initPrivateLicenseBinded.dispatch({});
            } else {
              await this.actions.setHasLicenseAuthorization.dispatch(true);
            }
          }
        },
        updateEnvironmentSystemInfo: async () => {
          const environmentSystemInfo =
            await this.dependencies.serviceClient.environment.getSystemInfo();
          await this.actions.setEnvironmentEdition.dispatch(
            environmentSystemInfo.edition
          );
          await this.actions.setEnvironmentVersion.dispatch(
            environmentSystemInfo.version
          );
        },
        updateEnvironmentUniqueCode: async () => {
          const environmentUniqueCode =
            await this.dependencies.serviceClient.environment.getUniqueCode();
          await this.actions.setEnvironmentUniqueCode.dispatch(
            environmentUniqueCode
          );
        },
        initPrivateLicenseBinded: async () => {
          if (this.state.environmentUniqueCode) {
            try {
              const bindingInfo =
                await this._licenseBindingsHelper.actions.getAllLicenseBindings.dispatch(
                  {
                    productName: "Console",
                    sku: "Enterprise",
                    boundToId: this.state.environmentUniqueCode,
                    binded: true,
                  }
                );

              if (bindingInfo.boundTo === this.state.environmentUniqueCode) {
                await this.actions.setHasLicenseAuthorization.dispatch(true);
              } else {
                await this.actions.setHasLicenseAuthorization.dispatch(false);
              }
            } catch (e) {
              const err = e as
                | {
                    ishandled?: boolean;
                    response: { status: number; data: { code: string } };
                  }
                | undefined;
              if (err?.response) {
                const { status } = err?.response;
                if (status === 404) {
                  err.ishandled = true;
                }
              }
              if (err?.response.data.code === "ResoureNotBinded") {
                await this.navigateToLicense();
              }
            }
          }
        },
        sendEmail: async (payload: {
          title: string;
          content: string;
          receiverEmail: string;
        }) => {
          const { title, content, receiverEmail } = payload;
          await this.dependencies.serviceClient.environment.SendEmail(
            title,
            content,
            receiverEmail
          );
        },
      };
    }

    private async navigateToLicense() {
      await this.getContainer(RouterModel).actions.navigateByRouteInfo.dispatch(
        {
          type: "license",
          params: {},
        }
      );
    }
  }
);
