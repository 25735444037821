import { createModel } from "nyax";
import { JobQuery } from "src/models/job";
import { createJobTableBaseUIModel } from "src/store/models/ui/job/jobTable";

// Job暂时不是Resource，不走实体权限
export const WorkflowJobUIModel = createModel(
  class extends createJobTableBaseUIModel() {
    public effects() {
      return {
        ...super.effects(),
        initial: async (payload: {
          departmentId: string;
          workflowId: string;
        }) => {
          const { departmentId, workflowId } = payload;
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query: JobQuery
            ) =>
              this.dependencies.serviceClient.workflow.listAllJobs(
                departmentId,
                workflowId,
                pageIndex,
                pageSize,
                query
              ),
          });
        },
      };
    }
  }
);
