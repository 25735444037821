import { createModel } from "nyax";
import { ReferencedData } from "src/containers/calendars/components/ReferencedDataAlertDialog";
import { ModelBase } from "src/store/ModelBase";

export const ReferencedDataAlertDialogUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        dialogMode: null as "update" | "delete" | null,
        visible: false,
        referencedData: null as ReferencedData | null,
      };
    }
    public reducers() {
      return {
        setDialogMode: (value: typeof this.state.dialogMode) => {
          this.state.dialogMode = value;
        },
        setVisible: (value: boolean) => {
          this.state.visible = value;
        },
        setReferencedData: (value: ReferencedData | null) => {
          this.state.referencedData = value;
        },
      };
    }

    public effects() {
      const superEffects = super.effects();
      return {
        ...superEffects,

        initial: async () => {
          this.actions.setDialogMode.dispatch(null);
          this.actions.setReferencedData.dispatch(null);
          this.actions.setVisible.dispatch(false);
        },
      };
    }
  }
);
