/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { useTheme } from "src/hooks";

export default React.memo(function Title(props: {
  className?: string;
  children?: React.ReactNode;
  type?: "title" | "subTitle";
  labelColor?: string;
  variant?: "primary" | "normal";
  fontSize?: string;
  fontColor?: string;
}) {
  const theme = useTheme();
  const {
    className,
    children,
    type = "title",
    fontColor = theme.bodyText,
    fontSize = props.type === "subTitle" ? "16px" : "18px",
    labelColor,
    variant = "primary",
  } = props;

  return (
    <h1
      css={[
        css`
          position: relative;
          margin: 0;
          line-height: ${type === "subTitle" ? "16px" : "18px"};
          font-size: ${fontSize};
          font-weight: 600;
          color: ${fontColor};
        `,
        variant === "primary" &&
          css`
            padding-left: 10px;
            ::before {
              content: "";
              position: absolute;
              left: 0px;
              width: 3px;
              height: ${type === "subTitle" ? "16px" : "18px"};
              background: ${labelColor ?? theme.primaryColor};
            }
          `,
      ]}
      className={className}
    >
      {children}
    </h1>
  );
});
