import { createModel, createSelector } from "nyax";
import { Trigger } from "src/models/workflow";
import { ModelBase } from "src/store/ModelBase";
import { DataPermissionEntityModel } from "src/store/models/entity/dataPermission/entity";
import { TriggerEntityModel } from "src/store/models/entity/trigger/entity";
import { TriggerHelperModel } from "src/store/models/entity/trigger/helper";

export const TriggerDetailModel = createModel(
  class extends ModelBase {
    private get _id() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.containerKey!;
    }

    public selectors() {
      return {
        triggerDetail: createSelector(
          () => this.getContainer(TriggerEntityModel).state.byId,
          () => this.getContainer(DataPermissionEntityModel).state.byId,
          (triggerById, permissionById) => {
            const trigger = triggerById[this._id];
            return trigger
              ? ({
                  ...trigger,
                  permissionValues:
                    permissionById[this._id]?.permissionValues ?? [],
                } as Trigger)
              : null;
          }
        ),
      
      };
    }

    public effects() {
      return {
        requestDetail: async (workflowId: string) => {
          await this.getContainer(TriggerHelperModel).actions.getById.dispatch(
            { workflowId, triggerId: this._id }
          );
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
