import { FromEntries } from "src/shared/type";

export function createFromEntries<
  T extends
    | readonly Record<string, unknown>[]
    | readonly [...Array<Record<string, unknown>>],
  K extends keyof T[number]
>(data: T, key: K) {
  return Object.fromEntries(
    data.map((item) => [item[key as keyof typeof item], item])
  ) as FromEntries<T, K>;
}
export function filterNonNullish<T>(arr: (T | null | undefined)[]): T[] {
  return arr.filter((e) => e != null) as T[];
}
