/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useMemo } from "react";

interface IconProps {
  name: string;
  className?: string;
}

const BASE_ICON_CSS = css`
  width: 1em;
  height: 1em;
  vertical-align: -0.07em;
  fill: currentColor;
  overflow: hidden;
  font-size: 18px;
`;

export default React.memo(function Icon(props: IconProps) {
  const { className, name } = props;

  const iconName = useMemo(() => {
    return `#${name}`;
  }, [name]);

  return (
    <svg css={BASE_ICON_CSS} className={className} aria-hidden="true">
      <use href={iconName}></use>
    </svg>
  );
});
