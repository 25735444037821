// eslint-disable-next-line import/no-anonymous-default-export
export default {
  file: {
    files: "文件",
    introduction:
      "文件服务主要用于存储并管理各类文件，提供对应文件的下载服务，您可以自由的新建多层文件夹以进行文件管理",
    newFolder: "新建文件夹",
    newSubFolder: "新建子文件夹",
    fileSize: "文件大小",
    sameDirectoryName: "文件夹名称不能相同",
    uploadFile: "上传文件",
    hasSpecialCharacter:
      '文件名不能包含以下任何字符: \\ / . : * ? " < > | # + & = 并且以 . 开头',
    preparing: "准备中",
    uploading: "上传中",
    compoining: "合并中",
    allCompleted: "全部上传成功！",
    partCompleted: "部分上传成功！",
    completed: "上传成功",
    allFailed: "全部上传失败！",
    failed: "上传失败",
    sameFileNameExist: "有重名文件，是否覆盖？",
    sameFileNameUploading: "有重名名件正在上传，请稍后再试。",
    setPermissions: "权限管理",
    permissionManagement: "请选择",
    addUser: "添加用户",
    permission: {
      admin: "管理权限",
      readOnly: "可读权限",
      writeOnly: "可写权限",
      noPermission: "无权限",
    },
    creator: "创建人",
    specifyUser: "指定用户",
    other: "其他",
    others: "其他用户",
    othersTip: "当前租户下的其他用户",
    placeholder: {
      enterNameOrEmail: "请输入姓名或邮箱",
    },
    otherEmptyTip: "暂无指定用户，请添加",
    addUserTip: {
      warining: "请给所有添加用户设置权限",
    },
    add: "添加",
    checkAll: "全选",
    fileTip: {
      folderEmpty: "暂无文件夹，请先新建",
      fileEmpty: "暂无文件，请上传",
    },
    fileList: "文件列表",
    rootFolderInfo: "根目录信息配置",
    createRootFolder: {
      title: "新建根目录文件夹",
      tooltip:
        "创建根目录文件夹后在其中创建各子文件夹用于管理文件，同时可对根目录文件夹的访问权限进行配置。",
      name: {
        name: "根目录文件夹",
        required: "请填写根文件夹名称",
      },
      dataConnection: {
        name: "绑定文件连接器",
        required: "请选择文件连接器",
      },
      add: "新建文件类连接",
      none: "无文件类连接新建权限",
    },
    connectionErr: "当前文件连接器断开，无法访问，请更换或修改文件连接器",
    fileIntroduce: {
      title1: "什么是文件服务",
      p1: "文件服务主要用于存储并管理各类文件，同时提供预订文件的下载服务。您可以自由的新建多层文件夹，并在各层文件夹中自由管理文件",
      title2: "有哪些操作",
      operation: {
        createFolder: "新建文件夹",
        uploadFile: "上传文件",
        downloadFile: "下载文件",
      },
    },
    CreateConnectorItemDialog: {
      environment: {
        prod: "生产环境",
        ppe: "PPE环境",
        dev: "开发环境",
      },
      MiniO: {
        name: "连接名称",
        environment: "环境类型",
        address: "MiniO服务地址",
        route: "文件存储路径",
        waring: {
          ConnectorName: "请输入正确的MiniO服务",
          MiniOServerUrl: "请输入正确的MiniO服务地址",
          AccessKey: "请输入正确的Access Key",
          SecretKey: "请输入正确的Secret Key",
        },
      },
      EncooStorage: {
        name: "连接名称",
        environment: "环境类型",
        route: "文件存储路径",
        waring: {
          ConnectorName: "请输入正确的连接名称",
        },
      },
      AzureBlob: {
        name: "连接名称",
        environment: "环境类型",
        CharacterString: "连接字符串",
        route: "文件存储路径",
        waring: {
          ConnectorName: "请输入正确的连接名称",
          ConnectorString: "请输入正确的连接字符串",
        },
      },
    },
  },
};
