import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential
} from "@encoo-web/encoo-lib";
import { MFAconfig, SecurityData, SecurityUpdateData, VirtualMFAInfo } from "src/models/securityAuth";

export const SYSTEMSETTINGS_SERVICE_NAME = "systemSettings";

export class SystemSettingsServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, SYSTEMSETTINGS_SERVICE_NAME);
  }

  async getSecurityInfo(): Promise<SecurityData> {
    const req = this.buildRequest({
      url: `/v2/settings/security`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async updateSecurityInfo(payload: SecurityUpdateData): Promise<SecurityData> {
    const req = this.buildRequest({
      url: `/v2/settings/security`,
      method: "POST",
      payload,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getVirtualMFAInfo(): Promise<VirtualMFAInfo> {
    const req = this.buildRequest({
      url: `/v2/settings/totpCode`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getMFAconfig(): Promise<MFAconfig> {
    const req = this.buildRequest({
      url: `/v2/users/qrCode`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async bindMFA(code: string): Promise<MFAconfig> {
    const req = this.buildRequest({
      url: `/v2/Settings/totpCode/bind/${code}`,
      method: "POST",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async unbindMFA(code: string): Promise<MFAconfig> {
    const req = this.buildRequest({
      url: `/v2/settings/totpCode/unbind/${code}`,
      method: "POST",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }


}
