/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useDialog } from "@encoo-web/encoo-ui";
import { message, Space, Tooltip, Tree } from "antd";
import { DataNode } from "antd/lib/tree";
import List from "rc-virtual-list";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import Button from "src/components/Button";
import Icon from "src/components/Icon";
import PageHeader from "src/components/PageHeader";
import Title from "src/components/Title";
import AppDetailLayout from "src/containers/layout/AppDetailLayout";
import { useFormatMessage, useGetContainer, useTheme } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { Job } from "src/models/job";
import { RunInstanceLog } from "src/models/runInstance";
import { JobHelperModel } from "src/store/models/entity/job/helper";
import { RouterModel } from "src/store/models/router";
import {
  PackageOutlineNodeItem,
  RunningMonitorModel,
} from "src/store/models/ui/job/runningMonitor";
import { LoadingUIModel } from "src/store/models/ui/loading/loading";
import { cssTextTruncate } from "src/styles/text";
import { formatDate } from "src/utils/string";

const CAN_STOP_STATES = ["Queued", "Paused", "Running"];
const FINAL_STATES = ["Cancelled", "Failed", "Succeeded"];
export const RUN_INSTANCE_STATE = [
  "Created",
  "Running",
  "Failed",
  "Cancelled",
  "Succeeded",
  "Cancelling",
  "Paused",
  "Aborted",
];

const RunningLogs = React.memo(function RunningLogs(props: {
  logListHeight: number;
  runInstanceId: string;
}) {
  const { runInstanceId, logListHeight } = props;
  const getContainer = useGetContainer();
  const container = getContainer(RunningMonitorModel, runInstanceId);
  const loadContainer = getContainer(LoadingUIModel);
  const logs = useSelector(() => container.getters.mergedDataSource);

  const formatMessage = useFormatMessage();
  const theme = useTheme();

  const isLoading = useSelector(() => container.state.isLoading);
  const hasNext = useSelector(() => container.getters.hasNext);
  const onLoadNext = useCallback(async () => {
    await loadContainer.actions.setClosed.dispatch(true);
    await container.actions.loadNext.dispatch(true);
  }, [container.actions.loadNext, loadContainer.actions.setClosed]);

  const pagination = useMemo(() => {
    return {
      isLoading,
      hasNext,
      onLoadNext,
    };
  }, [hasNext, isLoading, onLoadNext]);

  useEffect(() => {
    return () => {
      loadContainer.actions.setClosed.dispatch(false);
    };
  }, [loadContainer.actions.setClosed]);

  const onScroll = useCallback(
    (event: React.UIEvent<HTMLUListElement, UIEvent>) => {
      const ul = event.currentTarget;
      const rect = ul.getBoundingClientRect();

      if (
        pagination &&
        pagination.hasNext &&
        !pagination.isLoading &&
        ul.scrollTop + rect.height + 44 >= ul.scrollHeight
      ) {
        pagination.onLoadNext?.();
      }
    },
    [pagination]
  );
  const listHeight = useMemo(() => logListHeight - 20 - 34, [logListHeight]);
  return (
    <div
      css={css`
        display: flex;
        flex-grow: 1;
        flex-direction: column;

        min-height: 0;

        border: 2px solid #d3dbeb;
      `}
    >
      <div
        css={css`
          padding: 0 30px;
          min-height: 34px;
          line-height: 34px;
          height: 34px;
          background-color: #d3dbeb;
        `}
      >
        {formatMessage(messageIds.runInstance.logContent)}
      </div>
      <List
        data={logs}
        height={listHeight}
        itemHeight={34}
        itemKey="id"
        onScroll={onScroll}
      >
        {(log: RunInstanceLog, index: number) => (
          <li
            css={[
              css`
                padding: 0 30px;
                height: 34px;
                line-height: 34px;
                min-height: 34px;
                background-color: ${index % 2 === 0
                  ? theme.bodyFrameBackground
                  : theme.bodyBackground};
              `,
              cssTextTruncate,
              index === 0
                ? css`
                    padding: 0 30px 0 24px;
                    border-left: 6px solid #3377ff;
                    background-color: #c6d9ff;
                  `
                : undefined,
            ]}
          >
            <Tooltip
              title={log.message}
              css={css`
                cursor: pointer;
              `}
            >
              <span>{`${formatDate(log.createdAt)} `}</span>
              <span>{log.message}</span>
            </Tooltip>
          </li>
        )}
      </List>
    </div>
  );
});

export default React.memo(function RunningMonitor() {
  const getContainer = useGetContainer();
  const formatMessage = useFormatMessage();
  const theme = useTheme();
  const router = getContainer(RouterModel);

  const currentRouteInfo = useSelector(() => router.getters.currentRouteInfo);
  const params = useMemo(
    () => currentRouteInfo.params as Record<string, string> | undefined,
    [currentRouteInfo.params]
  );
  const packageId = useMemo(() => params?.["packageId"], [params]);
  const versionId = useMemo(() => params?.["versionId"], [params]);
  const runInstanceId = useMemo(
    () => params?.["runInstanceId"] ?? "",
    [params]
  );

  const container = getContainer(RunningMonitorModel, runInstanceId);

  const outline = useSelector(() => container.state.outline);
  const logs = useSelector(() => container.getters.mergedDataSource);
  const [expandKeys, setExpandKeys] = useState<string[]>([]);
  const monitorDiv = useRef<HTMLDivElement>(null);
  const jobHelper = getContainer(JobHelperModel);
  const Dialog = useDialog();

  const [job, setJob] = useState<Job>();

  const findSelectId = useCallback(
    (nodeId: string) => {
      const stack = outline?.outlineFiles?.map((item) => item.rootNode) ?? [];

      let id: string | null = null;
      while (stack.length > 0) {
        const first = stack.pop();

        if (first) {
          for (const item of first?.subItems ?? []) {
            if (item.nodeId === nodeId) {
              id = item.id;
              break;
            }
            stack.push(item);
          }
        }

        if (id) {
          break;
        }
      }

      return id;
    },
    [outline?.outlineFiles]
  );

  const findParentKey = useCallback(
    (id: string) => {
      const stack = outline?.outlineFiles?.map((item) => item.rootNode) ?? [];

      let parentId: string | null = null;
      while (stack.length > 0) {
        const first = stack.pop();

        if (first) {
          for (const item of first?.subItems ?? []) {
            if (item.id === id) {
              parentId = first.id;
              break;
            }
            stack.push(item);
          }
        }

        if (parentId) {
          break;
        }
      }

      return parentId;
    },
    [outline?.outlineFiles]
  );
  const selectedNodeId = useMemo(() => {
    let nodeId = logs?.[0]?.nodeId;
    nodeId = nodeId?.endsWith("|") ? undefined : nodeId;
    return nodeId;
  }, [logs]);

  const selectedIds = useMemo(() => {
    if (selectedNodeId) {
      const selectId = findSelectId(selectedNodeId);
      if (selectId) {
        let parentId = findParentKey(selectId);
        let id = selectId;
        while (parentId) {
          if (!expandKeys.includes(parentId)) {
            id = parentId;
          }

          parentId = findParentKey(parentId);
        }

        return [id ?? selectId];
      }
    }
    return [];
  }, [expandKeys, findParentKey, findSelectId, selectedNodeId]);

  useEffect(() => {
    if (selectedIds.length > 0 && monitorDiv.current) {
      const selectedId = selectedIds[0];
      const items = monitorDiv.current.getElementsByClassName(
        `monitor-tree-node-${selectedId}`
      );

      items?.[0]?.scrollIntoView();
    }
  }, [selectedIds]);

  const transfer: (
    subItems?: PackageOutlineNodeItem[] | undefined
  ) => DataNode[] | undefined = useCallback(
    (subItems?: PackageOutlineNodeItem[]) => {
      return subItems?.map((item) => ({
        key: `${item.id}`,
        title: (
          <span className={`monitor-tree-node-${item.id}`}>{item.name}</span>
        ),
        children: transfer(item.subItems),
      }));
    },
    []
  );

  const outlineInternal = useMemo(
    () =>
      outline?.outlineFiles?.map((item) => ({
        key: `${item.rootNode.id}`,
        title: (
          <div>
            <Icon
              name="icon-xamlicon"
              css={css`
                margin-right: 5px;
                font-size: 14px;
              `}
            />
            {item.fileName}
          </div>
        ),
        children: transfer(item.rootNode.subItems),
      })),
    [outline, transfer]
  );

  useEffect(() => {
    if (packageId && versionId) {
      container.actions.requestPackageOutline.dispatch({
        packageId,
        versionId,
      });

      container.actions.requestRunningLogs.dispatch({});
    }

    return () => {
      container.actions.clear.dispatch({});
    };
  }, [container.actions.clear, container.actions.requestPackageOutline, container.actions.requestRunningLogs, container.actions.setOutline, getContainer, packageId, versionId]);

  const getJobById = useCallback(async () => {
    if (container.state.jobId !== null && container.state.jobId !== undefined) {
      const res = await jobHelper.actions.readById.dispatch({
        id: container.state.jobId,
      });

      setJob(res);
    }
  }, [container.state.jobId, jobHelper.actions.readById]);

  useEffect(() => {
    getJobById();
  }, [getJobById]);

  useEffect(() => {
    const stack = outline?.outlineFiles?.map((item) => item.rootNode) ?? [];
    const keys = [];

    while (stack.length > 0) {
      const first = stack.pop();
      if (first) {
        keys.push(first.id);
        first?.subItems?.forEach((item) => {
          stack.push(item);
        });
      }
    }

    setExpandKeys(keys);
  }, [outline?.outlineFiles]);

  const onExpand = useCallback((expandedKeys: (string | number)[]) => {
    setExpandKeys(expandedKeys as string[]);
  }, []);

  const goBack = useCallback(() => {
    router.actions.goBack.dispatch({});
  }, [router.actions.goBack]);

  const rootDivRef = useRef<HTMLDivElement>(null);
  const [rootHeight, setRootHeight] = useState(0);
  const windowResize = useCallback(() => {
    const rootDiv = rootDivRef.current;
    if (!rootDiv) {
      return;
    }
    const rect = rootDiv.getBoundingClientRect();
    setRootHeight(rect.height);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", windowResize);

    return () => {
      window.removeEventListener("resize", windowResize);
    };
  }, [windowResize]);

  useEffect(() => {
    windowResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pause = useCallback(() => {
    container.actions.pause.dispatch({});
  }, [container.actions.pause]);

  const resume = useCallback(() => {
    container.actions.resume.dispatch({});
  }, [container.actions.resume]);

  const runInstanceState = useSelector(() => container.state.runInstanceState);
  const isSendingAction = useSelector(() => container.state.isSendingAction);

  const stop = useCallback(async () => {
    Dialog({
      type: "warning",
      content: (
        <div>
          <p>{formatMessage(messageIds.job.confirmCancel)}</p>
          <p> {job?.name}</p>

          {(runInstanceState === "Running" ||
            runInstanceState === "Paused") && (
            <p>
              {formatMessage(messageIds.common.startTime)}:&nbsp;
              {formatDate(job?.startedAt).length > 1
                ? formatDate(job?.startedAt)
                : formatMessage(messageIds.common.none)}
            </p>
          )}
        </div>
      ),

      onOk: async () => {
        if (job?.id) {
          try {
            await container.actions.stop.dispatch({});
            if (runInstanceState && FINAL_STATES.includes(runInstanceState)) {
              await container.actions.clear.dispatch({});
              message.success(formatMessage(messageIds.common.operateSuccess));
              goBack();
            }
          } catch (error) {
            // 共通会提示错误，这里什么也不用做
          }
        }
      },
    });
  }, [
    Dialog,
    container.actions.clear,
    container.actions.stop,
    formatMessage,
    goBack,
    job?.id,
    job?.name,
    job?.startedAt,
    runInstanceState,
  ]);

  const actionButton = useMemo(() => {
    if (runInstanceState === "Paused" || runInstanceState === "Running") {
      const stateColor = runInstanceState === "Paused" ? "#3070F0" : "#d44c4c";
      return (
        <Space size={16}>
          {CAN_STOP_STATES.includes(runInstanceState) && (
            <Button onClick={stop} disabled={isSendingAction}>
              {formatMessage(messageIds.common.cancel)}
            </Button>
          )}
          <Button
            onClick={runInstanceState === "Paused" ? resume : pause}
            disabled={isSendingAction}
            css={css`
              margin-left: auto;
              background-color: ${stateColor};
              color: #ffffff;
              border: 1px solid ${stateColor};
              :hover {
                background-color: ${stateColor};
                color: #ffffff;
                border: 1px solid ${stateColor};
              }
              :focus {
                background-color: ${stateColor};
                color: #ffffff;
                border: 1px solid ${stateColor};
              }
            `}
            iconName={
              runInstanceState === "Paused" ? "icon-play" : "icon-suspend"
            }
          >
            {runInstanceState === "Paused"
              ? formatMessage(messageIds.common.resume)
              : formatMessage(messageIds.common.pause)}
          </Button>{" "}
        </Space>
      );
    }
  }, [formatMessage, isSendingAction, pause, resume, runInstanceState, stop]);

  return (
    <AppDetailLayout>
      <PageHeader
        title={formatMessage(messageIds.job.monitor.title)}
        showClose={true}
        close={goBack}
      >
        {actionButton}
      </PageHeader>
      <div
        css={css`
          display: flex;
          flex-grow: 1;
          min-height: 0;
          min-width: 0;
          padding-top: 30px;
        `}
        ref={rootDivRef}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            min-height: 0;
            min-width: 0;
            margin-right: 40px;
          `}
        >
          <Title type="subTitle">
            {formatMessage(messageIds.job.monitor.title)}
          </Title>
          <div
            css={css`
              display: flex;
              flex-grow: 1;
              width: 400px;
              min-height: 0;
              border: 2px solid #d3dbeb;
              margin: 20px 0 0 0;
              overflow: auto;
            `}
            ref={monitorDiv}
          >
            <Tree
              css={css`
                .ant-tree-node-selected {
                  background-color: rgba(96, 191, 77, 0.2) !important;
                }
              `}
              treeData={outlineInternal}
              showIcon={false}
              showLine={{ showLeafIcon: false }}
              expandedKeys={expandKeys}
              selectedKeys={selectedIds}
              onExpand={onExpand}
            />
            {outline &&
              (!outline.outlineFiles || outline.outlineFiles.length === 0) && (
                <div
                  css={css`
                    margin: 50px auto;
                    text-align: center;
                    span {
                      color: ${theme.defaultColor};
                      white-space: pre-wrap;
                    }
                  `}
                >
                  <span>{formatMessage(messageIds.job.tips)}</span>
                </div>
              )}
          </div>
        </div>
        <div
          css={css`
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            min-height: 0;
            width: 0;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              flex-grow: 1;
              margin-bottom: 5px;
            `}
          >
            <Title type="subTitle">
              {formatMessage(messageIds.job.monitor.log)}
            </Title>
          </div>

          <RunningLogs
            runInstanceId={runInstanceId}
            logListHeight={rootHeight - 30 - 20}
          />
        </div>
      </div>
    </AppDetailLayout>
  );
});
