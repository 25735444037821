import { createModel } from "nyax";
import { JobQueueMessage, MessageCreationData } from "src/models/jobQueue";
import { JobQueueMessageEntityModel } from "src/store/models/entity/jobQueueMessage/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const JobQueueMessageHelperModel = createModel(
  class extends createHelperModel<JobQueueMessage>({
    setItems: (getContainer, items) =>
      getContainer(JobQueueMessageEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(JobQueueMessageEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(JobQueueMessageEntityModel).state.byId[id],
  }) {
    public effects() {
      return {
        ...super.effects(),

        createMessage: async (payload: {
          queueName: string;
          param: MessageCreationData;
        }) => {
          const { queueName, param } = payload;
          return await this._create({
            createAction: async () => {
              const item =
                await this.dependencies.serviceClient.jobQueue.createMessage(
                  queueName,
                  param
                );
              return item;
            },
          });
        },
        messageDelete: async (payload: {
          queueName: string;
          id: string;
          popReceipt: string;
        }) => {
          const { queueName, id, popReceipt } = payload;
          return await this._delete({
            id,
            deleteAction: () =>
              this.dependencies.serviceClient.jobQueue.messageDelete(
                queueName,
                id,
                popReceipt
              ),
          });
        },
      };
    }
  }
);
