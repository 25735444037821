/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Spin } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetContainer, useTheme } from "src/hooks";
import { LoadingUIModel } from "src/store/models/ui/loading/loading";

export const Loading = (props: { isShowLoading?: boolean }) => {
  const { isShowLoading } = props;
  const theme = useTheme();
  return (
    <div
      css={css`
        position: absolute;
        z-index: 9999;
        width: 100%;
        height: 100%;
        display: ${isShowLoading ? "block" : "none"};
        text-align: center;
        vertical-align: middle;
        &:after {
          content: "";
          display: inline-block;
          height: 100%;
          vertical-align: middle;
        }
      `}
    >
      <div
        css={css`
          display: block;
          position: fixed;
          width: 60px;
          height: 60px;
          margin: auto;
          padding: 20px;
          inset: 0px;
          border-radius: 0.6em;
          box-shadow: 0 4px 12px ${theme.shadowColor};
          background-color: ${theme.bodyBackground};
        `}
      >
        <Spin size="default" />
      </div>
    </div>
  );
};

export default React.memo(function LoadingContainer() {
  const getContainer = useGetContainer();

  const loadingUIModel = getContainer(LoadingUIModel);

  const isShowLoading = useSelector(() => loadingUIModel.state.isShowLoading);

  useEffect(() => {
    loadingUIModel.actions.registerLoading.dispatch({});
  }, [loadingUIModel.actions.registerLoading]);

  return <Loading isShowLoading={isShowLoading} />;
});
