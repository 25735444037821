import { createModel } from "nyax";
import {
  DataPermissionInfo,
  DataPermissionPayloadData,
  DataPermissionUpdataData,
} from "src/models/dataPermission";
import { DataPermissionEntityModel } from "src/store/models/entity/dataPermission/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const DataPermissionHelperModel = createModel(
  class extends createHelperModel<DataPermissionInfo>({
    setItems: (getContainer, items) =>
      getContainer(DataPermissionEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(DataPermissionEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(DataPermissionEntityModel).state.byId[id],
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(DataPermissionEntityModel),
      };
    }
    public effects() {
      return {
        ...super.effects(),
        getAll: async (payload: DataPermissionPayloadData[]) => {
          return await this._readAll({
            getAllAction: () => {
              return this.dependencies.serviceClient.dataPermissions.getBatchPermissionData(
                payload
              );
            },
          });
        },
        getById: async (payload: { resourceType: string; id: string }) => {
          const { resourceType, id } = payload;
          return this._readById({
            id: id,
            force: true,
            getByIdAction: () => {
              return this.dependencies.serviceClient.dataPermissions.getById({
                resourceType,
                id,
              });
            },
          });
        },
        getPermissionTable: async (payload: {
          resourceType: string;
          id: string;
          query?: {
            permissionTagId?: string;
            // permissionValue?: PermissionValue;
          };
        }) => {
          return await this.dependencies.serviceClient.dataPermissions.getPermissionTable(
            payload
          );
        },
        update: async (payload: {
          resourceType: string;
          id: string;
          //   dataPermissionInfo: DataPermissionUpdataData;
          dataPermissionInfo: DataPermissionUpdataData;
        }) => {
          const { resourceType, id, dataPermissionInfo } = payload;
          return await this._update({
            id,
            updateAction: () =>
              this.dependencies.serviceClient.dataPermissions.update({
                resourceType,
                id,
                dataPermissionInfo,
              }),
          });
        },
      };
    }
  }
);
