import { createModel, createSelector } from "nyax";
import {
  LicenseBinding,
  LicenseCountersData,
  LicenseStatistics,
  ProductNameOfLicenseCounter,
} from "src/models/license";
import { ModelBase } from "src/store/ModelBase";
import { LicenseHelperModel } from "src/store/models/entity/license/helper";
import { RpaLicenseEntityModel } from "src/store/models/entity/rpaLicense/entity";
import { RpaLicenseHelperModel } from "src/store/models/entity/rpaLicense/helper";
import { formatDate } from "src/utils/string";

//sku 对应的license 列表
export type BindLicense = Record<string, Array<LicenseBinding>>;
export type LicenseTabKey = "details" | "logs";

export const LicenseUIModel = createModel(
  class extends ModelBase {
    private get _helperContainer() {
      return this.getContainer(LicenseHelperModel);
    }

    public initialState() {
      return {
        activateLicenseVisible: false,
        dateRange: "",
        licenseCounterData: [] as LicenseCountersData[],
        licenseStatistics: {} as LicenseStatistics,
        tabActiveKey: "details" as LicenseTabKey,
        showAvailable: true,
        consoleWarnings: "" as string | undefined,
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setDateRange: (dateRange: string) => {
          this.state.dateRange = dateRange;
        },
        setActivateLicenseVisible: (value: boolean) => {
          this.state.activateLicenseVisible = value;
        },
        setLicenseCounterData: (value: LicenseCountersData[]) => {
          this.state.licenseCounterData = value;
        },
        setLicenseStatistics: (statistics: LicenseStatistics) => {
          this.state.licenseStatistics = statistics;
        },
        setTabActiveKey: (activeKey: LicenseTabKey) => {
          this.state.tabActiveKey = activeKey;
        },
        setShowAvailable: (value: boolean) => {
          this.state.showAvailable = value;
        },
        setConsoleWarnings: (value: string | undefined) => {
          this.state.consoleWarnings = value;
        },
      };
    }

    public selectors() {
      return {
        ...super.selectors(),
        dateRange: createSelector(
          () => this.getContainer(RpaLicenseEntityModel).getters.items,
          (licenses) => {
            const license = licenses?.[0];

            return license
              ? "".concat(
                  formatDate(license.validFrom),
                  " - ",
                  formatDate(license.validTo)
                )
              : "";
          }
        ),
        robotQuota: createSelector(
          () => this.getContainer(RpaLicenseEntityModel).getters.items,
          (licenses) => {
            return (
              licenses[0]?.licenseCounts?.filter(
                (item) => item.name === "Robot"
              ) ?? []
            );
          }
        ),
        studioQuota: createSelector(
          () => this.getContainer(RpaLicenseEntityModel).getters.items,
          (licenses) => {
            return (
              licenses[0]?.licenseCounts?.filter(
                (item) => item.name === "Studio"
              ) ?? []
            );
          }
        ),
      };
    }

    public effects() {
      return {
        initializeRequest: async (playLoad: {
          available?: boolean;
          type: ProductNameOfLicenseCounter;
        }) => {
          const { type, available } = playLoad;
          await this.actions.getLicenseStatistics.dispatch(available);
          await this.actions.getLicenseCounters.dispatch(type);
          await this.actions.getLicenseInfo.dispatch(available);
          await this.getContainer(LicenseHelperModel).actions.readAll.dispatch({
            force: true,
          });
        },

        getLicenseInfo: async (available?: boolean) => {
          await this.getContainer(
            RpaLicenseHelperModel
          ).actions.readAll.dispatch({
            available: available,
          });
        },
        getLicenseCounters: async (type: ProductNameOfLicenseCounter) => {
          const data =
            await this._helperContainer.actions.getLicenseCounters.dispatch(
              type
            );
          await this.actions.setLicenseCounterData.dispatch(data);
        },
        getLicenseStatistics: async (available?: boolean) => {
          const statistics =
            await this.dependencies.serviceClient.licenseBindings.licenseStatisticsV2(
              available
            );
          await this.actions.setLicenseStatistics.dispatch(statistics);
        },
        getWarnings: async () => {
          const warning =
            await this.dependencies.serviceClient.expiryPrompt.getWarnings();
          await this.actions.setConsoleWarnings.dispatch(
            warning?.consoleWarning
          );
        },
        closeNotice: async () => {
          await this.dependencies.serviceClient.expiryPrompt.closeNotice();
        },
      };
    }
  }
);
