import { createModel, createSelector } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { DataPermissionEntityModel } from "src/store/models/entity/dataPermission/entity";
import { PackageEntityModel } from "src/store/models/entity/package/entity";
import { PackageHelperModel } from "src/store/models/entity/package/helper";
import { PackageWithPermission } from "src/store/models/ui/package/packageTable";

export const PackageDetailV2Model = createModel(
  class extends ModelBase {
    private get _id() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.containerKey!;
    }
    public selectors() {
      return {
        packageDetail: createSelector(
          () => this.getContainer(PackageEntityModel).state.byId,
          () => this.getContainer(DataPermissionEntityModel).state.byId,
          (packageById, permissionById): PackageWithPermission | null => {
            const packageItem = packageById[this._id];
            return packageItem
              ? {
                  ...packageItem,
                  permissionValues:
                    permissionById[this._id]?.permissionValues ?? [],
                }
              : null;
          }
        ),
      };
    }
    public effects() {
      return {
        requestDetail: async () => {
          await this.getContainer(PackageHelperModel).actions.getById.dispatch({
            packageId: this._id,
          });
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
