import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { Job, JobQuery, UpdateJobMessage } from "src/models/job";
import { EncooBatchResult, EncooListEntity } from "src/models/_shared";

export const JOB_SERVICE_NAME = "job";

export class JobServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, JOB_SERVICE_NAME);
  }

  //发请求的地方
  async list(
    departmentId: string,
    pageIndex: number,
    pageSize: number,
    query?: JobQuery,
    robotId?: string,
    packageId?: string
  ): Promise<EncooListEntity<Job>> {
    const req = this.buildRequestV2({
      url: `/v2/jobs`,
      method: "GET",
      query: {
        name: query?.name,
        queryTimeType: query?.timeColumnName,
        startQueryTime: query?.startTime,
        endQueryTime: query?.endTime,
        orderBy: query?.orderBy,
        isDesc: query?.sortOrder === "descend",
        timeZone: -new Date().getTimezoneOffset(),
        displayStates: query?.status,
        sources: query?.source,
        jobStartupType: query?.jobStartupType,
        pageIndex,
        pageSize,
        robotId,
        packageId,
      },
      departmentId,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getAll(): Promise<Job[]> {
    const req = this.buildRequestV2({ url: `/v2/jobs`, method: "GET" });
    return await super.getAllInternal(req);
  }

  async getById(jobId: string): Promise<Job> {
    const req = this.buildRequestV2({
      url: `/v2/jobs/${jobId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async updateJob(
    jobId: string,
    payload: { message?: UpdateJobMessage; priority?: number }
  ): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/jobs/${jobId}`,
      method: "PATCH",
      payload,
    });
    return await super.updateInternal(req);
  }

  async stopJobs(jobIds: Array<string>): Promise<EncooBatchResult> {
    const req = this.buildRequestV2({
      url: `/v2/jobs/batch/stop`,
      method: "POST",
      payload: {
        jobIds,
      },
    });
    return await super.updateInternal<EncooBatchResult>(req);
  }

  async executeJobs(jobIds: Array<string>): Promise<EncooBatchResult> {
    const req = this.buildRequestV2({
      url: `/v2/jobs/batch/execute`,
      method: "POST",
      payload: {
        jobIds,
      },
    });
    return await super.updateInternal<EncooBatchResult>(req);
  }
  async batchDelete(jobIds: string[]): Promise<EncooBatchResult> {
    const req = this.buildRequestV2({
      url: `/v2/jobs/batch/delete`,
      method: "POST",
      payload: {
        ids: jobIds,
      },
    });
    return await super.updateInternal<EncooBatchResult>(req);
  }
  async delete(jobId: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/jobs/${jobId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }
  async batchUpdateJobs(
    priority: string,
    jobIds: Array<string>
  ): Promise<EncooBatchResult> {
    const req = this.buildRequestV2({
      url: `/v2/jobs/priority/batch/modify`,
      method: "POST",
      payload: {
        Priority: priority,
        jobIds,
      },
    });
    return await super.updateInternal<EncooBatchResult>(req);
  }
}
