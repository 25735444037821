import { createModel } from "nyax";
import { AiAccountService } from "src/models/ocr";
import { EncooSort } from "src/models/_shared";
import { AIEntityModel } from "src/store/models/entity/ai/entity";
import { createUITableBaseModel } from "src/store/models/ui/_shared";

export const AITableUIModel = createModel(
  class extends createUITableBaseModel<AiAccountService, EncooSort>({
    setItems: (getContainer, items) =>
      getContainer(AIEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(AIEntityModel).getters.items,
    getItem: (getContainer, id) => getContainer(AIEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initial: async () => {
          this._initial({
            initialAction: (pageIndex: number, pageSize: number) =>
              this.dependencies.serviceClient.ocr.getAiAccountServices(
                pageIndex,
                pageSize
              ),
          });
        },
      };
    }
  }
);
