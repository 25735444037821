import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { DataSource, DataSourceFoderType } from "src/models/dataSource";

export const DATASOURCE_SERVICE_NAME = "dataSource";

export class DataSourceServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, DATASOURCE_SERVICE_NAME);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async getList(
    dataSourceFoderType: DataSourceFoderType,
    folderId: string,
    payload: {
      pageIndex: number;
      pageSize: number;
    }
  ) {
    const req = this.buildRequestV2({
      url: `/v2/datacenter/dataconnector/datasources/${dataSourceFoderType}/${folderId}`,
      method: "GET",
      query: payload,
    });

    const { body } = await this.sendRequest(req);
    return body;
  }

  async getAll(
    dataSourceFoderType: DataSourceFoderType,
    folderId: string
  ): Promise<DataSource[]> {
    const req = this.buildRequestV2({
      url: `/v2/datacenter/dataconnector/datasources/${dataSourceFoderType}/${folderId}`,
      method: "GET",
    });

    return super.getAllByPageInternal<DataSource>(req);
  }
}
