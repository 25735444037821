import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { User, UserClaim, UserIcon } from "src/models/user";
import {
  UpdateExternalUserPasswordData,
  UpdatePasswordData,
  UpdateUserData,
  UserInfo,
  VerificationCodeCheckData,
  VerificationCodeData,
} from "src/models/userInfo";

export const USER_SERVICE_NAME = "user";
export class UserServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, USER_SERVICE_NAME);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async list(resourceGroupId?: string, queryText?: string) {
    const req = this.buildRequest({
      url: "/v1/users",
      method: "GET",
      resourceGroupId,
      query: { queryText },
    });
    return await super.listInternally<User>(req);
  }

  async getUserProfileV2(userId: string): Promise<UserInfo> {
    const req = this.buildRequest({
      url: `/v2/users/${userId}/profile`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async sendToken(payload: VerificationCodeData): Promise<string> {
    const req = this.buildRequest({
      url: `/v1/users/sendToken`,
      method: "POST",
      payload,
    });
    return await this.sendRequest(req).then((res) => {
      if (res.status === 200 && res.body) {
        return res.body;
      }
      return undefined;
    });
  }

  async verifyToken(payload: VerificationCodeCheckData): Promise<boolean> {
    const req = this.buildRequest({
      url: `/v1/users/verifyToken`,
      method: "POST",
      payload,
    });
    return await this.sendRequest(req).then((res) => {
      if (res.status === 200) {
        return true;
      }
      return false;
    });
  }

  async updatePhoneNumberV2(
    id: string,
    payload: UpdateUserData
  ): Promise<boolean> {
    const req = this.buildRequest({
      url: `/v2/users/${id}/updatePhoneNumber`,
      method: "PATCH",
      payload,
    });
    return await this.sendRequest(req).then((res) => {
      if (res.status === 204) {
        return true;
      }
      return false;
    });
  }

  async unbindPhoneNumber(id: string): Promise<boolean> {
    const req = this.buildRequest({
      url: `/v1/users/${id}/unbindPhoneNumber`,
      method: "PATCH",
    });
    return await this.sendRequest(req).then((res) => {
      if (res.status === 204) {
        return true;
      }
      return false;
    });
  }

  async bindingEmail(id: string, payload: UpdateUserData): Promise<boolean> {
    const req = this.buildRequest({
      url: `/v1/users/${id}/bindingEmail`,
      method: "PATCH",
      payload,
    });
    return await this.sendRequest(req).then((res) => {
      if (res.status === 204) {
        return true;
      }
      return false;
    });
  }

  async unbindEmail(id: string): Promise<boolean> {
    const req = this.buildRequest({
      url: `/v1/users/${id}/unbindEmail`,
      method: "PATCH",
    });
    return await this.sendRequest(req).then((res) => {
      if (res.status === 204) {
        return true;
      }
      return false;
    });
  }

  async updatePassword(
    id: string,
    payload: UpdatePasswordData
  ): Promise<boolean> {
    const req = this.buildRequest({
      url: `/v1/users/${id}/updatePassword`,
      method: "PATCH",
      payload,
    });
    return await this.sendRequest(req).then((res) => {
      if (res.status === 204) {
        return true;
      }
      return false;
    });
  }
  async updateUserName(id: string, displayName: string): Promise<boolean> {
    const req = this.buildRequest({
      url: `/v1/users/${id}/updateDisplayName`,
      method: "PATCH",
      payload: {
        displayName,
      },
    });
    return await this.sendRequest(req).then((res) => {
      if (res.status === 204) {
        return true;
      }
      return false;
    });
  }

  async updateLanguage(id: string, language: string): Promise<boolean> {
    const req = this.buildRequest({
      url: `/v1/users/${id}/updateLanguage`,
      method: "PATCH",
      payload: {
        language,
      },
    });
    return await this.sendRequest(req).then((res) => {
      if (res.status === 204) {
        return true;
      }
      return false;
    });
  }

  async updateUserV2(id: string, payload: UserClaim[]): Promise<boolean> {
    const req = this.buildRequest({
      url: `/v2/users/${id}/claims`,
      method: "PUT",
      payload,
    });
    return await this.sendRequest(req).then((res) => {
      if (res.status === 204) {
        return true;
      }
      return false;
    });
  }

  // HttpGet: 获取所有内置的头像
  async avatarList(): Promise<UserIcon[]> {
    const req = this.buildRequest({
      url: "v2/users/BuiltInUserIcons",
      method: "GET",
    });
    return await this.sendRequest(req).then((res) => {
      if (res.status === 200 || (res.status === 201 && res.body)) {
        return res.body;
      }
      return undefined;
    });
  }

  // HttpGet: 获取当前用户头像
  async getUserAvatar(userId: string): Promise<UserIcon> {
    const req = this.buildRequest({
      url: `v2/users/${userId}/UserIcon`,
      method: "GET",
    });
    return await this.sendRequest(req).then((res) => {
      if (res.status === 200 || (res.status === 201 && res.body)) {
        return res.body;
      }
      return undefined;
    });
  }

  // HttpPatch: 更新用户头像
  async updateUserAvatar(userId: string, avatarId: number): Promise<boolean> {
    const req = this.buildRequest({
      url: `v2/users/${userId}/UserIcon`,
      method: "PATCH",
      payload: {
        id: avatarId,
      },
    });
    return await this.sendRequest(req).then((res) => {
      if (res.status === 200) {
        return true;
      }
      return false;
    });
  }

  //
  async updateExternalUserPassword(
    userId: string,
    payload: UpdateExternalUserPasswordData
  ): Promise<boolean> {
    const req = this.buildRequest({
      url: `v2/users/${userId}/updateExternalUserPassword`,
      method: "PATCH",
      payload,
    });
    return await this.sendRequest(req).then((res) => {
      if (res.status === 200) {
        return true;
      }
      return false;
    });
  }

  async unbindWeChat(id: string): Promise<boolean> {
    const req = this.buildRequest({
      url: `/v2/users/${id}/unbindwechat`,
      method: "PATCH",
    });
    return await this.sendRequest(req).then((res) => {
      if (res.status === 204) {
        return true;
      }
      return false;
    });
  }
}
