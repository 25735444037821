// eslint-disable-next-line import/no-anonymous-default-export
export default {
  coeDataAnalysis: {
    entityTypeName: "数据分析",
    dataTableName: "流程数据",
    dataTableNameTip:
      "表格中的数据是在所选时间范围内，根据流程实际运行情况、人工成本以及RPA投入成本计算得出。您可根据实际情况在成本配置中调整默认成本数据，以达到数据的准确性。",
    latestYear: "最近一年",
    export: "导出",
    yuan: " 元",
    times: " 次",
    days: " 天",
    totalUnits: "单位累计",
    costSetting: "成本配置",
    basicSetting: "基础设置",
    averageLaborCost: "平均人工成本/月",
    averageLaborCostTip:
      "根据实际情况预估每月人工成本，将用于计算每个流程的人工花费等数据。",
    averageRobotCost: "RPA投入成本/年",
    averageRobotCostTip:
      "根据实际购买RPA方案整体费用计算出每年平均费用，将用于计算流程回报率等数据。",
    anotherCosts: "其他成本",
    roiPeriod: "投资回报周期",
    workflowCost: "流程成本",
    singleManHour: "单次人工工时",
    processDevelopmentCost: "流程开发成本（元）",
    workflowName: "流程名称",
    chart: {
      totalLaborTimeSaved: "总节省人工耗时",
      top10LaborTimeSavings: "节省人工耗时前10",
      totalLaborCostSaved: "总节省人工费用",
      top10LaborCostSavings: "节省人工费用前10",
      totalROI: "总投资回报率",
      top10ROI: "投资回报率前10",
    },
    columns: {
      departmentName: "部门",
      packageName: "流程名称",
      relatedRequirement: "关联需求",
      manualOperationHours: "人工操作用时",
      manualOperationHoursTip:
        "若关联了需求，则计算得出，等于 需求中的单次时间 / 流程数量；若在流程成本数据中手动配置，则以手动配置的数据计算。",
      robotHours: "机器人用时",
      robotHoursTip: "指此流程的单次平均运行时间",
      firstRunningTime: "首次运行时间",
      daysOnline: "已上线天数",
      robotOccupancyRate: "机器人占用率",
      robotOccupancyRateTip:
        "机器人占用率 = 此流程运行总时长 / 所有流程运行总时长",
      executionTimes: "执行次数",
      executionTimesTip: "所选时间段内的流程运行次数",
      efficiencyElevation: "效率提升",
      efficiencyElevationTip:
        "效率提升 =（人工操作用时 - 机器人用时）/ 人工操作用时",
      savingHours: "节省工时",
      savingHoursTip: "节省工时 = 人工操作用时 * 流程运行次数",
      savingLaborCosts: "节省人工费用",
      savingLaborCostsTip: "节省人工费用 = 人工单次费用 * 流程运行次数",
      roi: "投资回报率",
      roiTip: "投资回报率 = 人工成本 / 机器人成本",
    },
  },
};
