import { createModel, createSelector } from "nyax";
import messageIds from "src/locales/messageIds";
import { CoeWorkUpdationHistory, HistoryList } from "src/models/coeWork";
import { ModelBase } from "src/store/ModelBase";

export const CoeMyWorkHistoryUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        historyData: [] as CoeWorkUpdationHistory[] | undefined,
        dataSource: {} as CoeWorkUpdationHistory | undefined,
      };
    }
    public reducers() {
      return {
        setHistoryData: (value: CoeWorkUpdationHistory[] | undefined) => {
          this.state.historyData = value;
        },
        setDataSource: (value: CoeWorkUpdationHistory | undefined) => {
          this.state.dataSource = value;
        },
      };
    }
    public selectors() {
      return {
        historyItems: createSelector(
          () => this.state.historyData,
          (data) => {
            const histories: CoeWorkUpdationHistory[] = [];
            // eslint-disable-next-line array-callback-return
            data?.map((item) => {
              histories.push({
                ...item,
                changes: item.changes,
              });
            });
            return histories;
          }
        ),
        historyItemsWidth: createSelector(
          () => this.state.dataSource,
          (data) => {
            if (
              data?.changes?.elapsedTimeOfManual ||
              data?.changes?.unitOfElapsedTime
            ) {
              return 150;
            } else if (
              data?.changes?.levelOfResponsibility ||
              data?.changes?.assignedToName
            ) {
              return 100;
            } else if (
              data?.changes?.packageNames ||
              data?.changes?.details ||
              data?.changes?.attachments
            ) {
              return 80;
            } else {
              return 50;
            }
          }
        ),
        historyList: createSelector(
          (): CoeWorkUpdationHistory[] => this.getters.historyItems,
          (data) => {
            const list: HistoryList[] = [];
            // eslint-disable-next-line array-callback-return
            data?.map((item) => {
              const changelist = [];
              if (item?.changes?.name) {
                changelist.push(
                  this.dependencies.locale.intl.formatMessage({
                    id: messageIds.coeWork.table.columns.title,
                  })
                );
              }
              if (item?.changes?.levelOfResponsibility) {
                changelist.push(
                  this.dependencies.locale.intl.formatMessage({
                    id: messageIds.coeWork.addForm.degree,
                  })
                );
              }
              if (item?.changes?.elapsedTimeOfManual) {
                changelist.push(
                  this.dependencies.locale.intl.formatMessage({
                    id: messageIds.coeWork.details.elapsedTimeOfManual,
                  })
                );
              }
              if (item?.changes?.frequency) {
                changelist.push(
                  this.dependencies.locale.intl.formatMessage({
                    id: messageIds.coeWork.details.frequency,
                  })
                );
              }
              if (item?.changes?.unitOfElapsedTime) {
                changelist.push(
                  this.dependencies.locale.intl.formatMessage({
                    id: messageIds.coeWork.details.unitOfElapsedTime,
                  })
                );
              }
              if (item?.changes?.unitOfFequency) {
                changelist.push(
                  this.dependencies.locale.intl.formatMessage({
                    id: messageIds.coeWork.details.unitOfFequency,
                  })
                );
              }
              if (item?.changes?.state) {
                changelist.push(
                  this.dependencies.locale.intl.formatMessage({
                    id: messageIds.common.status,
                  })
                );
              }
              if (item?.changes?.assignedToName) {
                changelist.push(
                  this.dependencies.locale.intl.formatMessage({
                    id: messageIds.coeWork.details.assignedTo,
                  })
                );
              }
              if (item?.changes?.packageNames) {
                changelist.push(
                  this.dependencies.locale.intl.formatMessage({
                    id: messageIds.coeWork.details.associateProcess,
                  })
                );
              }
              if (item?.changes?.details) {
                changelist.push(
                  this.dependencies.locale.intl.formatMessage({
                    id: messageIds.coeWork.details.detail,
                  })
                );
              }
              if (item?.changes?.attachments) {
                changelist.push(
                  this.dependencies.locale.intl.formatMessage({
                    id: messageIds.coeWork.details.otherDetails,
                  })
                );
              }
              if (item?.changes?.description) {
                changelist.push(
                  this.dependencies.locale.intl.formatMessage({
                    id: messageIds.common.description,
                  })
                );
              }
              list.unshift({
                id: item?.id,
                opratorId: item?.opratorId,
                name: item?.opratorName,
                time: item?.opreteAt,
                des: item?.isCreated
                  ? `${this.dependencies.locale.intl.formatMessage({
                      id: messageIds.coeWork.details.created,
                    })} ${changelist.join(",")}`
                  : `${this.dependencies.locale.intl.formatMessage({
                      id: messageIds.coeWork.details.changed,
                    })} ${changelist.join(",")} `,
              });
            });
            return list;
          }
        ),
      };
    }
    public effects() {
      return {};
    }
  }
);
