export function copyText(text: string): void {
  const input = document.createElement("a");
  input.innerHTML = text;
  input.style.cssText =
    "width:1px;height:1px;line-height:0;overflow:hidden;color:transparent;border:none;position:fixed;left:0;bottom:0;background:transparent;box-shadow:none;";
  const body = document.body;
  body.appendChild(input);
  const range = document.createRange();
  range.selectNodeContents(input);
  const selection = window.getSelection();
  if (selection) {
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand("copy");
    selection.removeAllRanges();
  }
  body.removeChild(input);
}
