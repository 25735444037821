import { createModel, createSelector } from "nyax";
import { RpaConfigUnit } from "src/models/rpaConfig";
import { ModelBase } from "src/store/ModelBase";

type DrawerType = "detail" | "edit";

const JobLogFileInit = {
  settingName: "JobLogFile",
  enabled: false,
  typeValue: 0,
  selectedTypeName: "",
};
const VideoFileInit = {
  settingName: "VideoFile",
  enabled: false,
  typeValue: 0,
  selectedTypeName: "",
};
const VideoStorageLimitInit = {
  settingName: "VideoStorageLimitInit",
  enabled: false,
  typeValue: 0,
  selectedTypeName: "",
};
export const RpaPlanConfigUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        type: "detail" as DrawerType,
        rpaConfigData: [
          JobLogFileInit,
          VideoFileInit,
          VideoStorageLimitInit,
        ] as RpaConfigUnit[],
        isEdit: true,
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setType: (value: DrawerType) => {
          this.state.type = value;
        },
        setRpaConfigData: (data: RpaConfigUnit[]) => {
          this.state.rpaConfigData = data;
        },
        setEdit: (value: boolean) => {
          this.state.isEdit = value;
        },
      };
    }

    public effects() {
      return {
        initialState: async () => {
          await this.actions.setType.dispatch("detail");
        },
        initializeRequest: async () => {
          const rpaConfigData =
            await this.dependencies.serviceClient.rpaConfig.getRpaConfig();
          this.actions.setRpaConfigData.dispatch(rpaConfigData);
        },
        updateRpaConfig: async (payload: RpaConfigUnit[]) => {
          await this.dependencies.serviceClient.rpaConfig.updateRpaConfig(
            payload
          );
        },
      };
    }
    public selectors() {
      return {
        jobLogFile: createSelector(
          () => this.state.rpaConfigData,
          (rpaConfigData) =>
            rpaConfigData.find((item) => {
              return item.settingName === "JobLogFile";
            }) ?? JobLogFileInit
        ),
        videoFile: createSelector(
          () => this.state.rpaConfigData,
          (rpaConfigData) =>
            rpaConfigData.find((item) => {
              return item.settingName === "VideoFile";
            }) ?? VideoFileInit
        ),
        videoStorageLimit: createSelector(
          () => this.state.rpaConfigData,
          (rpaConfigData) =>
            rpaConfigData.find((item) => {
              return item.settingName === "VideoStorageLimit";
            }) ?? VideoStorageLimitInit
        ),
      };
    }
  }
);
