// eslint-disable-next-line import/no-anonymous-default-export
export default {
  payment: {
    name: "支付中心",
    product: {
      version: {
        personal: "个人专业版",
        freePersonal: "个人免费版",
        chargePersonal: "个人收费版",
        sass: "企业SASS版",
        custom: "企业私有化定制版",
      },
      utils: {
        amount: "￥",
      },
      label: {
        contactUs: "联系我们",
        email: "邮箱",
        telephone: "电话",
        originalPrice: "原价",
        payable: "应付金额",
        versionRights: "版本权益对比",
        scanCode: "微信扫码，立即咨询",
        promoCode: "优惠码",
      },
      buttons: {
        pay: "立即购买",
        ApplyNow: "立即申请",
      },
      titles: {
        ai: "云扩AI服务",
        personal: "个人专业版：购买即享云扩控制台专业版服务",
        viCode: "ViCode 应用",
      },
      description: {
        personal: "*当前账户已包含1个编辑器许可，如需更多许可请购买企业版",
      },
      env: {
        name: "系统环境",
        windows: "Windows",
        all: "Windows、麒麟、统信、欧拉等",
      },
      cell: {
        editor: "编辑器",
        robot: "机器人",
        console: "控制台",
        other: "其他",
        debugger: "运行/调试",
        smartRecord: "智能录制",
        systemEnv: "系统环境",
        processLib: "流程库",
        timedRecord: "定时记录",
        runProcess: "流程执行",
        localActivate: "本地激活",
        setUp: "设置",
        dashboard: "仪表盘",
        automationCenter: "自动化卓越中心",
        center: {
          RPA: "RPA中心",
          AI: "AI中心",
          data: "数据中心",
          management: "管理中心",
        },
        viCode: "ViCode",
        smartKnowledge: "智能知识库",
        technicalSupport: "技术支持",
        productCustom: "产品定制",
        privateDeployment: "私有化部署",
        integration: {
          API: "API集成",
          enterprise: "企微集成",
          dingDing: "钉钉集成",
        },
        component: {
          common: "常用组件",
          console: "控制台组件",
          AI: "AI组件",
          CV: "CV组件",
          custom: "自定义组件",
          library: "内置组件库",
        },
        SAP: "SAP",
        mobileAutomation: "手机自动化",
        remoteDesktop: "远程桌面",
        desktopControl: "桌面专有控制",
        market: {
          name: "市场",
          component: "组件市场",
          code: "代码市场",
          process: "流程模板市场",
          element: "元素市场",
        },
        management: {
          name: "管理",
          git: "Git版本管理",
          uploadToConsole: "上传到控制台管理",
          processPackage: "流程包管理",
          robot: "机器人管理",
          robotGroup: "机器人组管理",
          processDeployment: "流程部署管理",
          computer: "计算机管理",
          calendar: "日历管理",
          AIService: "AI服务管理",
          asset: "资产管理",
          connection: "连接管理",
          role: "角色管理",
          user: "用户管理",
          permission: "权限管理",
          local: "本地管理",
          license: "许可证管理",
          organizationalStructure: "组织结构管理",
        },
        localExecution: "本地执行",
        consoleScheduling: "控制台调度",
        basic: "基本",
        recordVideo: "录制视频",
        scheduledTask: "定时任务",
        taskRecord: "任务记录",
        planExecuted: "待执行计划",
        executionScreenshot: "执行过程截图",
        runAdmin: "以管理员权限运行",
        standaloneDesktop: "独立桌面",
        timeoutSet: "超时设置",
        localActivation: "本地激活使用",
        autoUnlock: "自动解锁",
        overallMonitoring: "总体监控",
        robotMonitoring: "机器人监控",
        robotGroupMonitoring: "机器人组监控",
        robotOperationStatistics: "机器人运行统计",
        userProcessStatistic: "用户流程统计",
        requirementInitiation: "需求发起",
        requirementFollowUp: "需求跟进",
        dataAnalysis: "数据分析",
        RDPPersistence: "RDP会话保持",
        processOrchestration: "流程编排",
        taskQueue: "任务队列",
        executionRecord: "执行记录",
        unpublishedApp: "未发布应用",
        publishApp: "发布应用",
        documentUnpublish: "文档理解未发布",
        documentPublish: "文档理解模板",
        OCRService: "OCR服务",
        knowledge: "知识库",
        application: "应用",
        quota: "额度",
        dataSheet: "数据表",
        fileService: "文件服务",
        dataQueue: "数据队列",
        auditLog: "审计日志",
        systemSetting: "系统设置",
        onlineSupport: "在线支持",
        siteTechnicalSupport: "现场技术支持",
        freeCountLimit: "免费调用次数总额度",
        freeTotalLimit: "免费总额度",
        freeLimit: "免费额度",
        freeCount: "免费调用次数",
        support: {
          community: "社区大群",
          online: "VIP在线技术支持",
          exclusive: "VIP专属技术支持",
        },
        number: "数量：{count}",
        noLimit: "不限",
        upTo: "小于等于",
        freeQuota: "免费额度",
        additionalPurchase: "需额外购买",
        freeEcooAiServer: "每个云扩AI服务免费：{count}次",
      },
      payTip:
        "*此订单为新增购买，如果需要为已购买产品延长使用期限，请从订单页面选择【续费】购买",
    },
    order: {
      name: "订单",
      label: {
        orderCheck: "订单确认",
        renew: "续费",
        weiChat: "微信",
        originalPrice: "原价",
        payable: "应付金额",
        account: "账号",
        product: "购买产品",
        versionType: "版本类型",
        specifications: "规格",
        effectiveDate: "开始生效日期",
        editors: "编辑器数量",
        robots: "机器人数量",
        count: "数量",
        payMethod: "支付方式",
        discountCode: "优惠码",
        discountCodeDeduction: "优惠码抵扣",
        treaty: "我已阅读并同意云扩RPA软件",
        purchaseNotes: "购买须知",
      },
      buttons: {
        submit: "提交订单",
        use: "使用",
        remove: "移除",
      },
      unit: {
        hour: "时",
        minute: "分",
        second: "秒",
      },
      tips: {
        discountCode: "您可找云扩商务人员获取优惠码",
        renew: "续费的机器人生效日期从选择的订单结束后开始",
        isExpired: "此订单已过期续费订单从当前日期生效",
        nowEffective: "购买后立即生效",
        discountError: {
          name: "优惠码无效",
          options: {
            inputError: "优惠码输入错误",
            isExpired: "优惠码已过期",
            notMatch: "与当前选择的商品规则不匹配",
            notApplicable: "该优惠码不适用于当前用户",
          },
        },
        discountSuccess: "优惠码有效！",
        pay: {
          wx: "微信支付",
          success: "支付成功",
          expire: "支付过期",
          fail: "支付失败",
        },

        wx: {
          pay: "使用微信扫码支付",
          order: {
            prefix: "请在",
            suffix: "内完成支付，超时将自动取消订单",
          },
          tip: "微信扫一扫，立即加入VIP技术支持群，RPA专家在线为您答疑解惑",
        },
      },
      notifications: {
        treaty: "请勾选购买须知",
      },
    },
  },
};
