import { AppCollectionListModel } from "src/store/models/entity/apps/collectionList";
import { AppEntityModel } from "src/store/models/entity/apps/entity";
import { AppHelperModel } from "src/store/models/entity/apps/helper";
import { AppListModel } from "src/store/models/entity/apps/list";
import { MyAppListModel } from "src/store/models/entity/apps/myApplist";

export const encooAppsModels = {
  entity: AppEntityModel,
  helper: AppHelperModel,
  list: AppListModel,
  myAppList: MyAppListModel,
  collectionList: AppCollectionListModel,
};
