import moment from "moment";
import { createModel } from "nyax";
import { JobGroup, JobGroupQuery } from "src/models/job";
import { JobGroupEntityModel } from "src/store/models/entity/jobGroup/entity";
import { createUITableBaseModel } from "src/store/models/ui/_shared";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createJobGroupTableBaseUIModel = function () {
  return class extends createUITableBaseModel<JobGroup, JobGroupQuery>({
    setItems: (getContainer, items) =>
      getContainer(JobGroupEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(JobGroupEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(JobGroupEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public initialState() {
      return {
        ...super.initialState(),
        changedTimeRange: false,
        query: {
          queryTimeType: "CreatedAt",
        } as JobGroupQuery,
      };
    }

    public reducers() {
      return {
        ...super.reducers(),
        setChangedTimeRange: (value: boolean) => {
          this.state.changedTimeRange = value;
        },
      };
    }

    public effects() {
      return {
        ...super.effects(),
        resetTimeChangeToDefault: async () => {
          // 人为改过时间后，就不强行设置为默认七天了
          if (!this.state.changedTimeRange) {
            await this.actions.setQuery.dispatch({
              ...this.state.query,
              startQueryTime: moment()
                .startOf("day")
                .subtract(6, "days")
                .toISOString(),
              endQuerytime: moment().endOf("day").toISOString(),
            });
          }
        },
      };
    }
  };
};

export const WorkflowLayoutJobUIModel = createModel(
  class extends createJobGroupTableBaseUIModel() {
    public effects() {
      return {
        ...super.effects(),
        initial: async (payload: {
          departmentId: string;
          workflowLayoutId: string;
        }) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { departmentId, workflowLayoutId } = payload;
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query: JobGroupQuery
            ) =>
              this.dependencies.serviceClient.workflowLayout.listAllJobs(
                // departmentId,
                workflowLayoutId,
                pageIndex,
                pageSize,
                query
              ),
          });
        },
      };
    }
  }
);
