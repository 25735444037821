import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { NewPermissionRoleInfo } from "src/models/permissionSetting";

export const ROLEMANAGER_SERVICE_NAME = "roleManager";

export class RoleManagerServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, ROLEMANAGER_SERVICE_NAME);
  }

  // 1. 获取请求列表
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async getRoleList() {
    const req = this.buildRequest({
      url: `/v2/permissiontemplates`,
      method: "GET",
    });
    return await this.sendRequest(req);
  }

  // 2. 获取默认的权限表格
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async getPermissionTable() {
    const req = this.buildRequest({
      url: `/v2/permissiontemplates/defaultpermissiontable`,
      method: "GET",
    });
    return await this.sendRequest(req);
  }

  // 3. 创建自定义模板
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async creatNewRole(data: NewPermissionRoleInfo) {
    const payload = data;

    const req = this.buildRequest({
      url: `/v2/permissiontemplates`,
      method: "POST",
      payload,
    });
    return await this.sendRequest(req);
  }

  // 4. 获取权限模板详情
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async getRole(id: string) {
    const req = this.buildRequest({
      url: `/v2/permissiontemplates/${id}`,
      method: "GET",
    });
    return await this.sendRequest(req);
  }

  // 5. 修改权限模板
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async editRole(id: string, data: NewPermissionRoleInfo) {
    const payload = data;

    const req = this.buildRequest({
      url: `/v2/permissiontemplates/${id}`,
      method: "PATCH",
      payload,
    });
    return await this.sendRequest(req);
  }

  // 6. 删除权限模板
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async deleteRole(id: string) {
    const req = this.buildRequest({
      url: `/v2/permissiontemplates/${id}`,
      method: "DELETE",
    });
    return await this.sendRequest(req);
  }
}
