// eslint-disable-next-line import/no-anonymous-default-export
export default {
  device: {
    introduction:
      "Computer management is mainly used to manage the computer status and data monitoring of the robot, so as to better track the robot.",
    deviceName: "Computer name",
    robotNumber: "Robot number",
    ipAddress: "IP",
    operationSystem: "OS",
    hardwareUsage: "Hard disk (remaining / total)",
    processorName: "Processor",
    memoryUsage: "Memory",
    runningTime: "Running time",
  },
};
