import { encooAIModels } from "src/store/models/entity/ai";
import { encooApplicationModels } from "src/store/models/entity/application";
import { encooAppsModels } from "src/store/models/entity/apps";
import { encooAppTagModels } from "src/store/models/entity/appTag";
import { encooAppTemplateModels } from "src/store/models/entity/appTemplate";
import { encooAppTemplateVersionModels } from "src/store/models/entity/appTemplateVersion";
import { encooAppVersionModels } from "src/store/models/entity/appVersion";
import { encooAssetModels } from "src/store/models/entity/asset";
import { encooAuditlogModels } from "src/store/models/entity/auditlog";
import { encooCalendarsModels } from "src/store/models/entity/calendars";
import { encooCoeDataAnalysisModels } from "src/store/models/entity/coeDataAnalysis";
import { encooCoeDataAnalysisPackageConfigModels } from "src/store/models/entity/coeDataAnalysisPackageConfig";
import { encooCoeDepartmentUserModels } from "src/store/models/entity/coeDepartmentUser";
import { encooCoeMyWorkModels } from "src/store/models/entity/coeMyWork";
import { encooCoeToDoWorkModels } from "src/store/models/entity/coeToDoWork";
import { encooCoeWorkMagerkModels } from "src/store/models/entity/coeWorkManger";
import { encooCompanyModels } from "src/store/models/entity/company";
import { encooCompanyPackageModels } from "src/store/models/entity/companyPackage";
import { encooDataConnectionModels } from "src/store/models/entity/dataConnection";
import { encooDataPermissionModels } from "src/store/models/entity/dataPermission";
import { encooDataQueueModels } from "src/store/models/entity/dataQueue";
import { encooDataSourceModels } from "src/store/models/entity/dataSource";
import { encooDataTableModels } from "src/store/models/entity/dataTable";
import { departmentModels } from "src/store/models/entity/department";
import { departmentUserModels } from "src/store/models/entity/departmentUser";
import { encooDeviceModels } from "src/store/models/entity/device";
import { encooDeviceRobotModels } from "src/store/models/entity/deviceRobot";
import { encooFileModels } from "src/store/models/entity/file";
import { encooInstallationModels } from "src/store/models/entity/installation";
import { encooJobModels } from "src/store/models/entity/job";
import { encooJobGroupModels } from "src/store/models/entity/jobGroup";
import { encooJobQueueModels } from "src/store/models/entity/jobQueue";
import { encooJobQueueMessageModels } from "src/store/models/entity/jobQueueMessage";
import { encooKnowledgeModels } from "src/store/models/entity/knowledge";
import { encooLicenseBindings } from "src/store/models/entity/license";
import { encooLicenseModels } from "src/store/models/entity/licenseBindings";
import { encooOCRModels } from "src/store/models/entity/ocr";
import { encooPackageModels } from "src/store/models/entity/package";
import { encooPackageVersionModels } from "src/store/models/entity/packageVersion";
import { encooPaymentOrderModels } from "src/store/models/entity/payment/order";
import { encooPaymentProductModels } from "src/store/models/entity/payment/product";
import { encooQueueModels } from "src/store/models/entity/queue";
import { encooRobotModels } from "src/store/models/entity/robot";
import { encooRobotTaskSchedulerModels } from "src/store/models/entity/robotTaskScheduler";
import { encooRoleManagementModels } from "src/store/models/entity/roleManagement";
import { encooRpaLicenseModels } from "src/store/models/entity/rpaLicense";
import { encooRpaLicenseBindingModels } from "src/store/models/entity/rpaLicenseBinding";
import { encooRunInstanceModels } from "src/store/models/entity/runInstance";
import { encooRunInstanceLogModels } from "src/store/models/entity/runinstanceLog";
import { encooTriggerModels } from "src/store/models/entity/trigger";
import { encooTriggerLogModels } from "src/store/models/entity/triggerLog";
import { encooUnderstandingResultModels } from "src/store/models/entity/understandingResult";
import { encooUnderstandingTemplateModels } from "src/store/models/entity/understandingTemplate";
import { encooUserModels } from "src/store/models/entity/user";
import { encooUserManagementModels } from "src/store/models/entity/userManagement";
import { encooViCodeAppModels } from "src/store/models/entity/vicodeApp";
import { encooWorkflowModels } from "src/store/models/entity/workflow";
import { encooWorkflowLayoutModels } from "src/store/models/entity/workflowLayout";
import { encooWorkflowLayoutTriggerModels } from "src/store/models/entity/workflowLayoutTrigger";

export const entityModels = {
  paymentProduct: encooPaymentProductModels,
  paymentOrder: encooPaymentOrderModels,
  coeWorkMager: encooCoeWorkMagerkModels,
  coeToDoWork: encooCoeToDoWorkModels,
  coeMyWork: encooCoeMyWorkModels,
  coeDepartmentUser: encooCoeDepartmentUserModels,
  companyPackage: encooCompanyPackageModels,
  coeDataAnalysisPackageConfig: encooCoeDataAnalysisPackageConfigModels,
  coeDataAnalysis: encooCoeDataAnalysisModels,
  calendars: encooCalendarsModels,
  dataTable: encooDataTableModels,
  workflowLayout: encooWorkflowLayoutModels,
  workflowLayoutTrigger: encooWorkflowLayoutTriggerModels,

  ocr: encooOCRModels,
  auditlog: encooAuditlogModels,
  robot: encooRobotModels,
  robotTaskScheduler: encooRobotTaskSchedulerModels,
  package: encooPackageModels,
  packageVersion: encooPackageVersionModels,
  device: encooDeviceModels,
  deviceRobot: encooDeviceRobotModels,
  workflow: encooWorkflowModels,
  trigger: encooTriggerModels,
  triggerLog: encooTriggerLogModels,
  queue: encooQueueModels,
  dataQueue: encooDataQueueModels,
  jobQueue: encooJobQueueModels,
  app: encooAppsModels,
  vicodeApp: encooViCodeAppModels,
  appVersion: encooAppVersionModels,
  job: encooJobModels,
  jobGroup: encooJobGroupModels,
  license: encooLicenseBindings,
  rpaLicense: encooRpaLicenseModels,
  jobQueueMessage: encooJobQueueMessageModels,
  rpaLicenseBinding: encooRpaLicenseBindingModels,
  runInstance: encooRunInstanceModels,
  understandingTemplate: encooUnderstandingTemplateModels,
  understandingResult: encooUnderstandingResultModels,
  installation: encooInstallationModels,
  asset: encooAssetModels,
  runInstanceLog: encooRunInstanceLogModels,
  file: encooFileModels,
  licenseList: encooLicenseModels,
  department: departmentModels,
  dataPermission: encooDataPermissionModels,
  departmentUser: departmentUserModels,
  company: encooCompanyModels,
  user: encooUserModels,
  dataConnection: encooDataConnectionModels,
  dataSource: encooDataSourceModels,
  appTemplate: encooAppTemplateModels,
  appTemplateVersion: encooAppTemplateVersionModels,
  userManagement: encooUserManagementModels,
  roleManagement: encooRoleManagementModels,
  appTag: encooAppTagModels,
  ai: encooAIModels,
  application: encooApplicationModels,
  knowledge: encooKnowledgeModels,
};
