import { createModel } from "nyax";
import { DataPermissionInfo } from "src/models/dataPermission";
import { ModelBase } from "src/store/ModelBase";
import { DataPermissionEntityModel } from "src/store/models/entity/dataPermission/entity";
import { DataPermissionHelperModel } from "src/store/models/entity/dataPermission/helper";

export const DataPermissionUIModel = createModel(
  class extends ModelBase {
    private get _id() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.containerKey!;
    }
    private get _dataPermissionContainer() {
      return this.getContainer(DataPermissionHelperModel);
    }
    private get _dataPermissionEntityContainer() {
      return this.getContainer(DataPermissionEntityModel);
    }
    public initialState() {
      return {
        id: this._id,
        dataPermissionInfo: null as DataPermissionInfo | null,
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setDataPermissionInfo: (value: DataPermissionInfo | null) => {
          this.state.dataPermissionInfo = value;
        },
      };
    }
    public selectors() {
      return {
        dataPermissionInfo: () =>
          this._dataPermissionEntityContainer.state.byId[this.state.id],
      };
    }
    public effects() {
      return {
        ...super.effects(),
        initializeRequest: async (payload: {
          resourceType: string;
          id: string;
        }) => {
          const { resourceType, id } = payload;

          const data = await this._dataPermissionContainer.actions.getById.dispatch(
            {
              resourceType,
              id,
            }
          );
          return data;
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
