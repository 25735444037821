import { createModel } from "nyax";
import { CoeMyWork, CoeMyWorkQuery } from "src/models/coeMyWork";
import { CoeMyWorkEntityModel } from "src/store/models/entity/coeMyWork/entity";
import { CoeMyWorkSideUIModel } from "src/store/models/ui/coeMyWork/coeMyWorkSide";
import { createUITableWithPermissionModel } from "src/store/models/ui/_shared";

export type CoeMyWorkWithPermission = CoeMyWork & {
  permissionValues: string[];
};

export const CoeMyWorkTableUIModel = createModel(
  class extends createUITableWithPermissionModel<CoeMyWork, CoeMyWorkQuery>({
    setItems: (getContainer, items) =>
      getContainer(CoeMyWorkEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(CoeMyWorkEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(CoeMyWorkEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      const superEffects = super.effects();
      return {
        ...superEffects,
        updateQuery: async (params: CoeMyWorkQuery) => {
          await superEffects.updateQuery(params);
          await this.getContainer(
            CoeMyWorkSideUIModel
          ).actions.setQuery.dispatch(this.state.query);
        },
        initial: async (payload: { departmentId: string }) => {
          const { departmentId } = payload;
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query: CoeMyWorkQuery
            ) =>
              this.dependencies.serviceClient.coeWork.myList(
                departmentId,
                pageIndex,
                pageSize,
                query
              ),
          });
        },
      };
    }
  }
);
