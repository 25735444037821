import { PackageDetailV2Model } from "src/store/models/ui/package/packageDetailV2";
import { PackageJobUIModel } from "src/store/models/ui/package/packageJob";
import { PackageSelectUIModel } from "src/store/models/ui/package/packageSelect";
import { PackageSideUIModel } from "src/store/models/ui/package/packageSide";
import { PackageTableUIModel } from "src/store/models/ui/package/packageTable";
import { PackageVersionTableUIModel } from "src/store/models/ui/package/packageVersionTable";
import { PackageWorkflowTableUIModel } from "src/store/models/ui/package/packageWorkflow";
import { PackageWorkflowDynamicUIModel } from "src/store/models/ui/package/packageWorkflowDynamic";
import { UploadPackageUIModel } from "src/store/models/ui/package/uploadPackage";

export const encooPackageUIModels = {
  packageTable: PackageTableUIModel,
  packageSide: PackageSideUIModel,
  packageDetailV2: PackageDetailV2Model,
  packageJob: PackageJobUIModel,
  packageWorkflow: PackageWorkflowTableUIModel,
  packageWorkflowDynamic: PackageWorkflowDynamicUIModel,
  packageVersionTable: PackageVersionTableUIModel,
  uploadPackage: UploadPackageUIModel,
  packageSelect: PackageSelectUIModel,
};
