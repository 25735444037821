import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import {
  DataConnection,
  DataConnectionProperties,
  DataConnectionServerEntity,
} from "@encoo-web/encoo-ui";
import Axios, { AxiosRequestConfig } from "axios";
import { EncooBatchResult, EncooSort } from "src/models/_shared";
import {
  EncooFile,
  EncooFileUploadCallbackInfo,
  EncooFileUploadState,
  EncooFileUploadUri,
} from "src/models/file";
import { joinDirectory } from "src/utils/string";
import { isWebUrl } from "src/utils/url";

export const FILE_SERVICE_NAME = "file";

delete Axios.defaults.headers.put["Content-Type"];

const deleteStrSlash = (str: string) => {
  let transformStr = `${str ? `/${str}` : ""}`;
  if (transformStr.charAt(transformStr.length - 1) === "/") {
    transformStr = transformStr.substr(0, transformStr.length - 1);
  }
  return transformStr;
};

export class FileServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, FILE_SERVICE_NAME);
  }
  public async createConnectionByFileAuthority(
    connection: Omit<DataConnection, "id" | "properties">,
    properties?: DataConnectionProperties
  ): Promise<DataConnection> {
    const req = this.buildRequest({
      url: `/v2/datacenter/files/createdataconnector`,
      method: "POST",
      payload: {
        ...connection,
        properties: properties ? JSON.stringify(properties) : undefined,
      },
    });

    const newConnection = (await this.createInternal(
      req
    )) as DataConnectionServerEntity;

    return {
      ...newConnection,
      properties:
        "properties" in newConnection && newConnection["properties"]
          ? JSON.parse(newConnection["properties"])
          : undefined,
    };
  }

  public async createDirectory(
    directoryFullName: string,
    name: string,
    dataConnectionId?: string
  ): Promise<EncooFile> {
    const fullName = directoryFullName ? `/${directoryFullName}` : "";
    const req = this.buildRequestV2({
      url: `/v2/datacenter/files${fullName}`,
      method: "POST",
      payload: {
        name,
        dataConnectionId,
        type: "Directory",
      },
    });

    const files: EncooFile[] = await super.createInternal(req);

    if (files.length > 0) {
      return files[files.length - 1];
    }

    throw new Error("Create directory failed");
  }

  public async createFile(
    directoryFullName: string,
    fileName: string
  ): Promise<EncooFile[]> {
    const req = this.buildRequestV2({
      url: `/v2/datacenter/files/${directoryFullName}`,
      method: "POST",
      payload: {
        name: fileName,
        type: "File",
      },
    });

    return await super.updateInternal(req);
  }

  public async getFiles(
    fullName: string,
    query: EncooSort
  ): Promise<EncooFile[]> {
    fullName = deleteStrSlash(fullName);
    const { orderBy, sortOrder } = query;
    const isDesc =
      sortOrder === "ascend"
        ? false
        : sortOrder === "descend"
        ? true
        : undefined;
    const req = this.buildRequestV2({
      url: `/v2/datacenter/files${fullName}`,
      method: "GET",
      query: {
        orderBy: orderBy,
        isDesc: isDesc,
      },
    });

    return await super.updateInternal(req);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async getFileConnectionList() {
    const req = this.buildRequest({
      url: "/v2/datacenter/files/dataconnectors",
      method: "GET",
    });
    return await super.updateInternal<{ data: Array<DataConnection> }>(req);
  }

  public async getMeta(fullName: string): Promise<EncooFile> {
    fullName = deleteStrSlash(fullName);
    const req = this.buildRequestV2({
      url: `/v2/datacenter/files${fullName}?metadata`,
      method: "GET",
    });

    const { body } = await this.sendRequest(req);
    return body;
  }

  public async delete(fullName: string): Promise<boolean> {
    fullName = deleteStrSlash(fullName);
    const req = this.buildRequestV2({
      url: `/v2/datacenter/files${fullName}`,
      method: "DELETE",
    });

    return await super.deleteInternal(req);
  }

  public async batchDelete(fullNameList: string[]): Promise<EncooBatchResult> {
    const req = this.buildRequestV2({
      url: `v2/datacenter/files/batch/delete`,
      method: "POST",
      payload: {
        fullNameList,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  public async getFileDownloadUri(fileFullName: string): Promise<string> {
    const req = this.buildRequestV2({
      url: `/v2/datacenter/files/${fileFullName}?downloadUri`,
      method: "GET",
    });

    const { body } = await this.sendRequest(req);
    return body;
  }

  public async updateRootFile(
    id: string,
    name: string,
    dataConnectionId: string
  ): Promise<EncooFile> {
    const req = this.buildRequestV2({
      url: `/v2/datacenter/files/${id}`,
      method: "PATCH",
      payload: {
        name,
        dataConnectionId,
      },
    });

    const { body } = await this.sendRequest(req);
    return body;
  }

  public async getFileUploadUris(
    fileFullName: string,
    fileSize: number,
    contentType: string
  ): Promise<EncooFileUploadUri[]> {
    const req = this.buildRequestV2({
      url: `/v2/datacenter/files/${fileFullName}?fileSize=${fileSize}&contentType=${encodeURIComponent(
        contentType
      )}&uploadUri`,
      method: "GET",
    });

    const { body } = await this.sendRequest(req);

    return body;
  }

  public async uploadFile(
    directoryFullName: string,
    file: File,
    conformDelete?: (info: { id: string; fileFullName: string }) => void,
    processCallback?: (info: EncooFileUploadCallbackInfo) => void
  ): Promise<void> {
    const totalSize = file.size;
    const fileFullName = joinDirectory(directoryFullName, file.name);

    await processCallback?.({
      fullName: fileFullName,
      nodeName: file.name,
      state: EncooFileUploadState["Preparing"],
      percent: 0,
      totalSize,
    });

    const info = await this.createFile(directoryFullName, file.name);

    try {
      // await this.createFile(directoryFullName, file.name);
      const uploadUris = await this.getFileUploadUris(
        fileFullName,
        file.size,
        "application/octet-stream"
      );

      const partUris = uploadUris.filter(
        (uri) => uri.type === "MultiPart" || uri.type === "SingleFile"
      );
      const combineUri = uploadUris.find(
        (uri) => uri.type === "CombineByConsole" || uri.type === "Combine"
      );

      await processCallback?.({
        fullName: fileFullName,
        nodeName: file.name,
        state: EncooFileUploadState["Uploading"],
        percent: 0,
        totalSize,
      });

      let uploadCompleteCount = 0;

      const partResponseDatas: Record<string, string>[] = await Promise.all(
        partUris.map(async (uri) => {
          const response = await Axios({
            method: uri.verb,
            headers: uri.headers,
            url: uri.uri,
            transformRequest: (data, headers) => {
              if (headers) {
                headers["Content-Type"] = "application/octet-stream";
              }

              return data;
            },
            data:
              uri.type === "SingleFile"
                ? file
                : file.slice(
                    uri.partContent.startPos - 1,
                    uri.partContent.startPos - 1 + uri.partContent.length
                  ),
          });
          const partData: Record<string, string> = {};
          uri.partResponseDatas?.forEach((data) => {
            if (data.fromType === "Header") {
              partData[data.name] =
                response.headers[data.fromValue] ??
                response.headers[data.fromValue.toLocaleLowerCase()];
            }
          });

          uploadCompleteCount++;

          processCallback?.({
            fullName: fileFullName,
            nodeName: file.name,
            state: EncooFileUploadState["Uploading"],
            percent: uploadCompleteCount / partUris.length,
            totalSize,
          });

          return partData;
        })
      );

      await processCallback?.({
        fullName: fileFullName,
        nodeName: file.name,
        state: EncooFileUploadState["Compoining"],
        percent: 1,
        totalSize,
      });

      if (combineUri) {
        let data: Record<string, unknown> | string | undefined = {};

        if (combineUri.type === "Combine") {
          data = combineUri.combineContent;
        } else {
          let combineByConsoleContent = combineUri.combineByConsoleContent;
          partResponseDatas.forEach((data) => {
            combineByConsoleContent = { ...combineByConsoleContent, ...data };
          });
          data = combineByConsoleContent;
        }
        const url = isWebUrl(combineUri.uri)
          ? combineUri.uri
          : `${this.environment.endpoint}/${combineUri.uri}`;
        if (combineUri.type === "Combine") {
          await Axios({
            method: combineUri.verb,
            headers: combineUri.headers,
            url,
            data,
          });
        } else {
          const req = this.buildRequestV2({
            url,
            payload: data,
            method: combineUri.verb,
            headers: {
              "content-type":
                combineUri?.headers?.contentType ?? "application/json",
            },
          });
          await this.sendRequest(req);
        }
      }

      await processCallback?.({
        fullName: fileFullName,
        nodeName: file.name,
        state: EncooFileUploadState.Completed,
        percent: 1,
        totalSize,
      });
    } catch (error) {
      info.map(async (item) => {
        await conformDelete?.({ id: item.id, fileFullName });
      });

      await processCallback?.({
        fullName: fileFullName,
        nodeName: file.name,
        state: EncooFileUploadState.Failed,
        percent: 0,
        totalSize,
      });

      throw error;
    }
  }

  public async uploadUris(
    uploadUris: EncooFileUploadUri[],
    file: File,
    processCallback?: (info: EncooFileUploadCallbackInfo) => void
  ) {
    const { name, size } = file;
    let uploadCompleteCount = 0;
    const uploadCallback = (info: EncooFileUploadCallbackInfo) =>
      processCallback?.(Object.assign({}, info));
    const processInfo: EncooFileUploadCallbackInfo = {
      nodeName: name,
      fullName: name,
      state: EncooFileUploadState.Uploading,
      percent: 0,
      totalSize: size,
    };
    const combineUri = uploadUris.find(
      (uri) => uri.type === "CombineByConsole" || uri.type === "Combine"
    );
    const partUris = uploadUris.filter(
      (uri) => uri.type === "MultiPart" || uri.type === "SingleFile"
    );
    try {
      uploadCallback?.(processInfo);
      const partResponse = await Promise.all(
        partUris.map(async (part) => {
          const requestConfig: AxiosRequestConfig = {
            method: part.verb,
            url: part.uri,
            headers: {},
          };
          switch (part.type) {
            case "SingleFile":
              requestConfig.data = file;
              requestConfig.headers = part.headers;
              break;
            case "MultiPart":
              requestConfig.headers = Object.assign(
                requestConfig.headers ?? {},
                part.headers ?? {},
                {
                  "Content-Type": "application/octet-stream",
                }
              );
              requestConfig.data = file.slice(
                part.partContent.startPos - 1,
                part.partContent.startPos - 1 + part.partContent.length
              );
              break;
          }
          const response = await Axios(requestConfig);
          uploadCompleteCount++;
          processInfo.percent = uploadCompleteCount / partUris.length;
          uploadCallback?.(processInfo);
          const partData: [string, string][] = [];
          part.partResponseDatas?.forEach((data) => {
            if (data.fromType === "Header") {
              partData.push([
                data.name,
                response.headers[data.fromValue] ??
                  response.headers[data.fromValue.toLocaleLowerCase()],
              ]);
            }
          });
          return partData;
        })
      );
      if (combineUri) {
        const request = this.buildRequestV2({
          method: combineUri.verb,
          url: isWebUrl(combineUri.uri)
            ? combineUri.uri
            : `${this.environment.endpoint}/${combineUri.uri}`,
          headers: {
            "content-type":
              combineUri?.headers?.contentType ?? "application/json",
          },
          payload: Object.assign(
            Object.fromEntries(partResponse.flat()),
            combineUri.combineByConsoleContent
          ),
        });
        await this.sendRequest(request);
        processInfo.state = EncooFileUploadState["Completed"];
      } else {
        processInfo.state = EncooFileUploadState["Completed"];
      }
      uploadCallback?.(processInfo);
    } catch (error) {
      processInfo.state = EncooFileUploadState["Failed"];
      uploadCallback?.(processInfo);
    }
    return processInfo;
  }
}
