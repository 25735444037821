import { createModel, createSelector } from "nyax";
import { DataPermissionInfo } from "src/models/dataPermission";
import { ModelBase } from "src/store/ModelBase";
import { GlobalSelectDepartmentModel } from "src/store/models/logic/globalSelectDepartment";

export const CurrentPermissionModel = createModel(
  class extends ModelBase {
    private get _globalSelectDepartmentContainer() {
      return this.getContainer(GlobalSelectDepartmentModel);
    }

    public initialState() {
      return {
        loadedPermissions: false,
        loadPermissionsFailed: false,
        currentDepartmentPermission: null as DataPermissionInfo | null,
      };
    }

    public reducers() {
      return {
        setLoadedPermissions: (value: boolean) => {
          this.state.loadedPermissions = value;
        },
        setLoadPermissionsFailed: (value: boolean) => {
          this.state.loadPermissionsFailed = value;
        },
        setCurrentDepartmentPermission: (value: DataPermissionInfo | null) => {
          this.state.currentDepartmentPermission = value;
        },
      };
    }

    public selectors() {
      return {
        permissionValues: createSelector(
          () => this.state.currentDepartmentPermission?.permissionValues,
          (val) => {
            return val !== undefined ? val : [];
          }
        ),
        permissionShowTypeValues: createSelector(
          () =>
            this.state.currentDepartmentPermission?.permissionShowTypeValues,
          (val) => {
            return val !== undefined ? val : {};
          }
        ),
      };
    }

    public effects() {
      return {
        requestPermission: async () => {
          const id =
            this._globalSelectDepartmentContainer.state.selectedDepartmentId;
          if (id) {
            await this.actions.setLoadedPermissions.dispatch(false);
            await this.actions.setLoadPermissionsFailed.dispatch(false);
            try {
              const value =
                await this.dependencies.serviceClient.dataPermissions.getById({
                  resourceType: "Department",
                  id,
                });
              await this.actions.setCurrentDepartmentPermission.dispatch(value);
              await this.actions.setLoadedPermissions.dispatch(true);
            } catch (error) {
              await this.actions.setLoadPermissionsFailed.dispatch(true);
            }
          }
        },
      };
    }
  }
);
