// eslint-disable-next-line import/no-anonymous-default-export
export default {
  userManagerPage: {
    pageTitle: "用户管理",
    introduction:
      "用户管理用于管理控制台账户信息，支持邀请新用户进入，同时对用户进行启用、停用、编辑、权限查看等操作",
    entityTypeName: "角色",
    search: "权限名称/菜单名称",
    permission: "权限",
    createRole: "创建角色",
    editRole: "编辑角色",
    deleteConfirm: {
      title: "请确认",
      content: "确认删除用户：",
      okText: "删除",
    },
    query: {
      phoneNumber: "手机号",
      status: {
        placeholder: "全部用户状态",
        active: "启用",
        inactive: "未激活",
        banned: "停用",
      },
      role: "全部角色",
    },
    column: {
      name: "用户名称",
      email: "邮箱",
      assignedDepartmentName: "所属部门",
      roles: "所属部门角色",
      status: "用户状态",
      lastLoginAt: "上次登录时间",
      operation: "操作",
      delete: "删除",
    },
    changeUserDepartment: {
      title: "转移部门",
      transfer1: "您确定要把用户",
      transfer2: "转移到以下部门：",
      userRole: "所属角色：",
    },
    changeUserRole: {
      title: "更改角色",
      role: "请选择更改的角色：",
    },
    userAuthorityDetail: {
      checkPermissions: "查看权限",
      permissionsDetails: "权限详情",
      userDepartmentRoles: "所属部门权限",
      userOtherDepartmentRoles: "拥有其他部门权限",
      loading: "加载中...",
    },
    userBaseInfoDetail: {
      basicInformation: "基本信息",
      userName: "用户姓名：",
      userEmail: "用户邮箱：",
      userDepartment: "所属部门：",
      userRole: "所属角色：",
      status: "用户状态：",
      createAt: "创建时间：",
      lastLoginAt: "上次登录时间：",
      lastModifyAt: "上次修改时间：",
      edit: "编辑",
      cancel: "取消",
      save: "保存",
    },
    inviteNewUser: {
      inviteUser: "邀请用户",
      reInviteUser: "重新邀请",
      reInviteUserConfirm: "是否重新邀请用户【{name}】?",
      account: "账号",
      userEmail: "用户邮箱",
      userName: "用户姓名",
      password: "密码",
      userDepartment: "所属部门",
      userRole: "所属角色",
      sendSuccess: "发送成功",
      private: {
        createUser: "创建用户",
        placeholder: {
          userName: "请填写用户在当前公司内的名称",
          email: "请输入需要创建的账号，请使用邮箱",
          password: "请输入密码",
        },
        tip: "提示：创建完成后请将账号及密码发给用户，用户将首次登陆修改密码后被激活。",
      },
    },
  },
};
