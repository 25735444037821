import { createModel } from "nyax";
import { SecurityData, SecurityUpdateData } from "src/models/securityAuth";
import { ModelBase } from "src/store/ModelBase";

export type SystemSettingViewType = "securityVerificationConfig";

export const SystemSettingUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        selectViewType: "securityVerificationConfig" as SystemSettingViewType,
        securityInfo: null as SecurityData | null,
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setSelectedViewType: (value: SystemSettingViewType) => {
          this.state.selectViewType = value;
        },
        setSecurityInfo: (value: SecurityData) => {
          this.state.securityInfo = value;
        },
      };
    }
    public effects() {
      return {
        getSecurityInfo: async () => {
          const data = await this.dependencies.serviceClient.systemSettings.getSecurityInfo();
          await this.actions.setSecurityInfo.dispatch(data);
        },
        update: async (payload: SecurityUpdateData) => {
          const data = await this.dependencies.serviceClient.systemSettings.updateSecurityInfo(
            payload
          );
          await this.actions.setSecurityInfo.dispatch(data);
        },
      };
    }
    public selectors() {
      return {};
    }
  }
);
