import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";

export const DialogPreviewAppModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        appId: null as null | string,
        isOpen: false,
      };
    }

    public reducers() {
      return {
        setOpen: (value: boolean) => {
          this.state.isOpen = value;
        },
        setAppId: (value: string | null) => {
          this.state.appId = value;
        },
      };
    }

    public effects() {
      return {
        close: async () => {
          await this.actions.setOpen.dispatch(false);
          await this.actions.setAppId.dispatch(null);
        },
      };
    }
  }
);
