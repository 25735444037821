// eslint-disable-next-line import/no-anonymous-default-export
export default {
  dataPermission: {
    dataPermissionMode: {
      title: "Data Permission Mode",
      inheritSuperiorAuthority:
        "Whether to be controlled by department permission configuration",
      subTitle:
        "If you choose to be controlled by department permission configuration, the system will comprehensively calculate the final permission according to the custom permission and the permission configuration on the department.",
      inherit: "Inherit from the Superior Department",
      custom: "Custom",
      userType: {
        human: "User Permission",
        robot: "Robot Permission",
      },
      department: "Specify Department/User",
      includeSubdepartment: "Whether to include sub",
      checkCurrDepartmentUserPermissions:
        "View User Permission of Current Department",
    },
    department: {
      title: "Department Configuration Information",
      owner: "Owner",
      subordinateDepartments: "Affiliated Department",
      managers: "Manager",
      manageSubtitle:
        "The owner of the superior department will automatically become the administrator of the current department.",
    },
    roleName: {
      companyAdmin: "Company Administrator",
      organizationAdmin: "Organizational Structure Administrator",
      auditAdmin: "Audit Administrator",
      user: "Common User",
    },
    user: "User",
    role: "Role",
    selectRoles: "Select Role",
    selectPermissionValues: "Select Permission",
    emptyErrorTips:
      "has a null value in the permission, please choose at least one option!",
    errorTip: "Please modify it again.",
  },
};
