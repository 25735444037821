/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ConnectionEnvironment } from "@encoo-web/encoo-ui";
import { Steps } from "antd";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Modal from "src/components/Modal";
import AppInitConnection, {
  AppInitConnectionEnvironment,
} from "src/containers/apps/appsInitConfig/AppInitConnection";
import AppRoleConfig from "src/containers/apps/appsInitConfig/AppRoleConfig";
import { useAppMenuList } from "src/containers/vicodeApp/components/ViCodeAppLiveAppMenu";
import {
  useApplicationInsights,
  useFormatMessage,
  useGetContainer,
  useGlobalCompany,
  useGlobalDepartment,
} from "src/hooks";
import messageIds from "src/locales/messageIds";
import { AppV2 } from "src/models/appV2";
import { AppHelperModel } from "src/store/models/entity/apps/helper";
import { AppTemplateVersionEntityModel } from "src/store/models/entity/appTemplateVersion/entity";
import { AppTemplateVersionHelperModel } from "src/store/models/entity/appTemplateVersion/helper";
import {
  AppInitConfigStep,
  AppInitConfigUIModel,
} from "src/store/models/ui/apps/appInitConfig";

const { Step } = Steps;

export const InitConfigSuccessfulModal = memo(function ({
  app,
  onCancel,
  environment,
}: {
  app: AppV2;
  onCancel: () => void;
  environment: ConnectionEnvironment;
}) {
  const getContainer = useGetContainer();
  const appsHelper = getContainer(AppHelperModel);

  const appMenuList = useAppMenuList({ app });
  const formatMessage = useFormatMessage();

  const globalCompany = useGlobalCompany();
  const globalDepartment = useGlobalDepartment();
  const applicationInsights = useApplicationInsights();

  const onUseApp = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      const item = appMenuList.find((item) => item.key === "use");
      item?.onClick?.(e);
    },
    [appMenuList]
  );

  const onEditApp = useCallback(() => {
    applicationInsights.sendTrackEvent(
      { name: "Vicode_EditAppEvent" },
      {
        AppID: app.id,
      }
    );
    onCancel();
    window.open(
      `${window._settings.appsUrl}editor?companyId=${globalCompany.id}&departmentId=${globalDepartment.id}&appId=${app.id}`
    );
  }, [
    app.id,
    applicationInsights,
    globalCompany.id,
    globalDepartment.id,
    onCancel,
  ]);

  const onReleaseApp = useCallback(
    async (e) => {
      const appId = app.id;
      await appsHelper.actions.releaseApp.dispatch({
        appId,
        payload: {
          releaseLog: "",
          version: "1.0.0",
        },
      });

      await appsHelper.actions.enableApp.dispatch({
        appId,
      });
      onCancel();
      onUseApp(e);
    },
    [
      app.id,
      appsHelper.actions.enableApp,
      appsHelper.actions.releaseApp,
      onCancel,
      onUseApp,
    ]
  );

  return (
    <Modal
      title={formatMessage(messageIds.apps.appInitConfig.initSuccessful)}
      visible={true}
      onCancel={onCancel}
      onOk={environment === "dev" ? onEditApp : onReleaseApp}
    >
      <div>
        {environment === "dev"
          ? formatMessage(messageIds.apps.appInitConfig.initSuccessfulDevTip)
          : formatMessage(messageIds.apps.appInitConfig.initSuccessfulDevTip)}
      </div>
    </Modal>
  );
});

export default memo(function AppsInitConfig({
  app,
  onCancel,
}: {
  app: AppV2;
  onCancel: () => void;
}) {
  const getContainer = useGetContainer();
  const formatMessage = useFormatMessage();

  const appInitConfigUIContainer = getContainer(AppInitConfigUIModel);
  const appHelper = getContainer(AppHelperModel);
  const appTemplateVersionHelper = getContainer(AppTemplateVersionHelperModel);
  const appTemplateVersionEntity = getContainer(AppTemplateVersionEntityModel);

  const appTemplateVersion = useSelector(
    () =>
      appTemplateVersionEntity.state.byId[app.sourceAppTemplateVersionId ?? ""]
  );

  const initSuccessfulModalVisible = useSelector(
    () => appInitConfigUIContainer.state.initSuccessfulModalVisible
  );
  const [connectionProgress, setConnectionProgress] = useState<number>(0);

  const modalTitle = useMemo((): Record<AppInitConfigStep, string> => {
    return {
      connectionEnvironment: formatMessage(
        messageIds.apps.appInitConfig.tab.environment
      ),
      connectionCreation: formatMessage(
        messageIds.apps.appInitConfig.tab.connection
      ),
      permission: formatMessage(messageIds.apps.appInitConfig.tab.permission),
    };
  }, [formatMessage]);

  const [environment, setEnvironment] = useState<ConnectionEnvironment | null>(
    null
  );

  const initConifgType = useMemo(() => {
    const type = {
      connection: !!appTemplateVersion?.connAdapters?.length,
      permission: !!appTemplateVersion?.rolePermissionAdapters?.length,
    };

    return type;
  }, [appTemplateVersion]);

  const currentConfigStep = useSelector(
    () => appInitConfigUIContainer.state.currentConfigStep
  );

  const steps = useMemo(() => {
    const connectionStep = initConifgType.connection
      ? [
          {
            name: "connectionEnvironment",
            text: modalTitle.connectionEnvironment,
          },
          {
            name: "connectionCreation",
            text: `${modalTitle.connectionCreation}（${
              currentConfigStep === "connectionEnvironment"
                ? 0
                : connectionProgress + 1
            }/${appTemplateVersion?.connAdapters?.length}）`,
          },
        ]
      : [];

    const roleStep = initConifgType.permission
      ? [
          {
            name: "permission",
            text: modalTitle.permission,
          },
        ]
      : [];

    const stepList = connectionStep.concat(roleStep);
    return stepList;
  }, [
    appTemplateVersion,
    connectionProgress,
    currentConfigStep,
    initConifgType.connection,
    initConifgType.permission,
    modalTitle.connectionCreation,
    modalTitle.connectionEnvironment,
    modalTitle.permission,
  ]);

  const currentStepIndex = useMemo(() => {
    return steps.findIndex((item) => item.name === currentConfigStep) ?? 0;
  }, [currentConfigStep, steps]);

  const initializeRequest = useCallback(() => {
    const { sourceAppTemplateId, sourceAppTemplateVersionId } = app;

    if (sourceAppTemplateId && sourceAppTemplateVersionId) {
      appTemplateVersionHelper.actions.getById.dispatch({
        id: sourceAppTemplateId,
        versionId: sourceAppTemplateVersionId,
        force: true,
      });
    }
  }, [app, appTemplateVersionHelper.actions.getById]);

  const _onInitApp = useCallback(async () => {
    if (appInitConfigUIContainer.state.updateData) {
      await appHelper.actions.updateAppDataSource.dispatch({
        appId: app.id,
        payload: appInitConfigUIContainer.state.updateData,
      });
      await appInitConfigUIContainer.actions.setInitSuccessfulModalVisible.dispatch(
        true
      );
    }
  }, [
    app.id,
    appHelper.actions.updateAppDataSource,
    appInitConfigUIContainer.actions.setInitSuccessfulModalVisible,
    appInitConfigUIContainer.state.updateData,
  ]);

  useEffect(() => {
    initializeRequest();
  }, [initializeRequest]);

  useEffect(() => {
    let initFirstStep: AppInitConfigStep | null = null;
    if (initConifgType.connection) {
      initFirstStep = "connectionEnvironment";
    } else if (initConifgType.permission) {
      initFirstStep = "permission";
    }
    appInitConfigUIContainer.actions.setCurrentConfigStep.dispatch(
      initFirstStep
    );
  }, [
    appInitConfigUIContainer.actions.setCurrentConfigStep,
    initConifgType.connection,
    initConifgType.permission,
  ]);

  const onCancelInitSuccessfulModal = useCallback(async () => {
    await appInitConfigUIContainer.actions.setInitSuccessfulModalVisible.dispatch(
      false
    );
    await appInitConfigUIContainer.actions.setCurrentInitConfigApp.dispatch(
      null
    );
  }, [
    appInitConfigUIContainer.actions.setCurrentInitConfigApp,
    appInitConfigUIContainer.actions.setInitSuccessfulModalVisible,
  ]);

  const _getConnectionProgress = useCallback((val: number) => {
    setConnectionProgress(val);
  }, []);

  return (
    <div>
      <Modal
        onCancel={onCancel}
        visible={app && !initSuccessfulModalVisible}
        footer={null}
        width={900}
        title={modalTitle[currentConfigStep ?? "connectionEnvironment"]}
      >
        {steps.length > 1 && (
          <Steps current={currentStepIndex} size="small">
            {steps.map((item) => (
              <Step title={item.text} key={item.name}></Step>
            ))}
          </Steps>
        )}
        <div
          css={css`
            padding: 30px 0;
          `}
        >
          {currentConfigStep === "connectionEnvironment" && (
            <AppInitConnectionEnvironment
              onEnvironmentChange={setEnvironment}
            />
          )}
          {currentConfigStep === "connectionCreation" &&
            appTemplateVersion?.connAdapters?.length &&
            environment && (
              <AppInitConnection
                environment={environment}
                connectionAdapterList={appTemplateVersion?.connAdapters}
                onInitApp={_onInitApp}
                hasPermissionConfig={initConifgType.permission}
                getConnectionProgress={_getConnectionProgress}
              />
            )}
          {currentConfigStep === "permission" &&
            appTemplateVersion?.rolePermissionAdapters?.length && (
              <AppRoleConfig
                roleData={appTemplateVersion.rolePermissionAdapters}
                onInitApp={_onInitApp}
              />
            )}
        </div>
      </Modal>
      {initSuccessfulModalVisible && (
        <InitConfigSuccessfulModal
          environment={environment ?? "dev"}
          app={app}
          onCancel={onCancelInitSuccessfulModal}
        />
      )}
    </div>
  );
});
