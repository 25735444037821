/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Tooltip } from "antd";
import React, { ReactNode } from "react";
import Icon from "src/components/Icon";

export default React.memo(function DescriptionTip(props: {
  children?: ReactNode;
  tip: ReactNode;
  className?: string;
}) {
  const { children, className, tip } = props;

  return (
    <div
      css={[
        css`
          display: inline-flex;
          align-items: center;
        `,
      ]}
      className={className}
    >
      {children}
      <Tooltip title={tip}>
        <span>
          <Icon
            name="icon-commonTip"
            css={[
              css`
                margin-left: 5px;
                cursor: pointer;
                font-size: 16px;
                pointer-events: none;
              `,
            ]}
          />
        </span>
      </Tooltip>
    </div>
  );
});
