import {
  AuditlogServiceClient,
  AxiosHttpClient,
  DataPermissionServiceClient,
  DataTableServiceClient,
  HeaderMiddleware,
  HttpClient,
  PermissionServiceClient,
  Profile,
  RequestQueue,
  ResourceGroupServiceClient,
  RoleAssignmentServiceClient,
  RoleServiceClient,
  ServiceCredential,
  TenantServiceClient,
} from "@encoo-web/encoo-lib";
import { EncooOidcClient } from "@encoo-web/oidc-client";
import { createBrowserHistory } from "history";
import { Container } from "inversify";
import Oidc from "oidc-client";
import { getDependencies } from "src/dependencies";
import { devProfile } from "src/dependencies/_settings";
import {
  APIErrorMiddlewareSymbol,
  CompanyHeaderMiddlewareSymbol,
  DepartmentHeaderMiddlewareSymbol,
  DependenciesSymbol,
  ErrorMiddlewareSymbol,
  HistorySymbol,
  HttpClientSymbol,
  ProfileSymbol,
  RequestQueueMiddlewareSymbol,
  ServiceCredentialsSymbol,
  TenantHeaderMiddlewareSymbol,
  UserManagerSettingsSymbol,
} from "src/dependencies/identifiers";
import {
  EncooOidcClientEx,
  OidcClientAuthenticationCredential,
} from "src/dependencies/oidcClient";
import ApplicationInsightsService, {
  devApplicationInsightsConfig,
  prodApplicationInsightsConfig,
} from "src/services/ApplicationInsightsService";
import { ApplicationServiceClient } from "src/services/ApplicationService";
import { CalendarsService } from "src/services/CalendarsService";
import { CoeDataAnalysisPackageConfigService } from "src/services/CoeDataAnalysisPackageConfigService";
import { CoeDataAnalysisService } from "src/services/CoeDataAnalysisService";
import { CoeWorkService } from "src/services/CoeWorkService";
import { CompanyServiceClient } from "src/services/Company";
import { CompanyUserServiceClient } from "src/services/CompanyUserService";
import { ConversationService } from "src/services/ConversationService";
import { CustomPermissionRoleServiceClient } from "src/services/CustomPermissionRoleService";
import { DashboardServiceClient } from "src/services/DashboardService";
import { DataConnectionServiceClient } from "src/services/DataConnectionServiceClient";
import { DataPermissionsServiceClient } from "src/services/DataPermission";
import { DataPermissionServiceClientV2 } from "src/services/DataPermissionServiceV2";
import { DataQueueServiceClient } from "src/services/DataQueueService";
import { DataSourceServiceClient } from "src/services/DataSource";
import { DataTableService } from "src/services/DataTableService";
import { DepartmentServiceClient } from "src/services/DepartmentService";
import { DepartmentUserServiceClient } from "src/services/DepartmentUserService";
import { DeviceServiceClient } from "src/services/DeviceService";
import { ExpiryPromptService } from "src/services/ExpiryPromptService";
import { FeatureServiceClient } from "src/services/FeatureService";
import { FileServiceClient } from "src/services/FileServiceClient";
import { InstallationServiceClient } from "src/services/InstallationService";
import { JobQueueServiceClient } from "src/services/JobQueueService";
import { JobServiceClient } from "src/services/JobService";
import { KnowledgeServiceClient } from "src/services/KnowledgeService";
import { LamossConfigServiceClient } from "src/services/LamossConfigService";
import { LocaleService } from "src/services/LocaleService";
import { OCRService } from "src/services/OCRService";
import { PackageServiceClient } from "src/services/PackageService";
import { PaymentOrderClient } from "src/services/PaymentOrderService";
import { PaymentClient } from "src/services/PaymentService";
import { QueueServiceClient } from "src/services/QueueService";
import { QuotaServiceClient } from "src/services/QuotaService";
import { ReduxService } from "src/services/ReduxService";
import { RobotServiceClient } from "src/services/RobotService";
import { RoleManagerServiceClient } from "src/services/RoleManagerService";
import { RpaLicenseServiceServiceClient } from "src/services/RpaLicenseService";
import { RpaConfigServiceClient } from "src/services/RpaPlanConfigService";
import { RuninstanceServiceClient } from "src/services/RuninstanceService";
import { SignalRConnection } from "src/services/SignalRConnection";
import { SystemSettingsServiceClient } from "src/services/SystemSettings";
import { ThemeService } from "src/services/ThemeService";
import { ThirdIntegrationServiceClient } from "src/services/ThirdIntegrationService";
import { UnderstandingSeviceClient } from "src/services/UnderstandingSeviceClient";
import { UserManagerServiceClient } from "src/services/UserManagerService";
import { UserServiceClient } from "src/services/UserService";
import { ViCodeAppServiceClient } from "src/services/ViCodeAppService";
import { WorkflowLayoutService } from "src/services/WorkflowLayoutService";
import { WorkflowServiceClient } from "src/services/WorkflowService";
import { AppTemplateServiceClient } from "src/services/appTemplateService";
import { AppsV2ServiceClient } from "src/services/appsV2Service";
import { AssetV2ServiceClient } from "src/services/assetV2Service";
import { AuditlogSearchServiceClient } from "src/services/auditlogSearchService";
import { EnvironmentServiceClient } from "src/services/environmentService";
import { LicenseBindingsServiceClient } from "src/services/licenseService";
import { HTTPMethodOverrideMiddleware } from "src/services/middleware/HTTPMethodOverrideMiddleware";
import { ErrorMiddleware } from "src/store/models/ui/error/error";
import { createInjectableFactory } from "src/utils/inversify";

export interface ExtraQueryParams {
  inviteCode: string;
}

declare global {
  interface Window {
    _settings: {
      endpoint: string;
      managementUrl: string;
      consoleV3Url: string;
      appsUrl: string;
      vicodeUrl: string;
      env: string;
      customizedCustomer?: "tss" | "custom" | null;
      disableApplicationInsights?: false;
      httpMethodOverride?: boolean;
      marketOrigin: string;
      userAccountAllowBindingContacts: boolean;

      appsTagCategories?: string[];
      engine?: "dingtalk" | "wechat";
      corpId?: string;
      appsPreviewDialog?: boolean;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    WxLogin: any;
  }
}

const container = new Container({
  defaultScope: "Singleton",
});

container.bind(DependenciesSymbol).toDynamicValue(() => {
  return getDependencies();
});
container.bind(HistorySymbol).toDynamicValue(() => {
  return createBrowserHistory();
});

container
  .bind(LocaleService)
  .toDynamicValue(createInjectableFactory(LocaleService));
container
  .bind(ReduxService)
  .toDynamicValue(createInjectableFactory(ReduxService));
container
  .bind(ThemeService)
  .toDynamicValue(createInjectableFactory(ThemeService));

container
  .bind(TenantHeaderMiddlewareSymbol)
  .toDynamicValue(() => new HeaderMiddleware("TenantId", ""));

container
  .bind(CompanyHeaderMiddlewareSymbol)
  .toDynamicValue(() => new HeaderMiddleware("CompanyId", ""));

container
  .bind(DepartmentHeaderMiddlewareSymbol)
  .toDynamicValue(() => new HeaderMiddleware("DepartmentId", ""));

const hostPrefix = `${window.location.protocol}//${window.location.host}`;

container.bind(UserManagerSettingsSymbol).toDynamicValue((context) => {
  const extraQueryParams = {} as ExtraQueryParams;
  const inviteCode = new URLSearchParams(window.location.search).get(
    "inviteCode"
  );
  if (inviteCode) {
    extraQueryParams.inviteCode = inviteCode;
  }

  return {
    authority: context.container.get<Profile>(ProfileSymbol).managementUrl,
    /* eslint-disable */
    client_id: "encoo_console_v3",
    redirect_uri: `${hostPrefix}/callback`,
    silent_redirect_uri: `${hostPrefix}/silent_renew`,
    post_logout_redirect_uri: `${hostPrefix}/index`,
    response_type: "code",
    /* eslint-enable */
    scope: "openid profile apigateway offline_access",
    accessTokenExpiringNotificationTime: 4,
    automaticSilentRenew: false,
    filterProtocolClaims: true,
    response_mode: "query",
    extraQueryParams,
    userStore: new Oidc.WebStorageStateStore({ store: window.sessionStorage }),
  };
});

if (
  window._settings &&
  window._settings.endpoint &&
  window._settings.managementUrl
) {
  container.bind(ProfileSymbol).toDynamicValue(() => window._settings);
} else {
  container.bind(ProfileSymbol).toDynamicValue(() => devProfile);
}

container
  .bind(EncooOidcClient)
  .toDynamicValue(
    (context) =>
      new EncooOidcClientEx(context.container.get(UserManagerSettingsSymbol))
  );

container
  .bind(ServiceCredentialsSymbol)
  .toDynamicValue(
    (context) =>
      new OidcClientAuthenticationCredential(
        context.container.get(EncooOidcClient)
      )
  );

container
  .bind(RequestQueueMiddlewareSymbol)
  .toDynamicValue(() => new RequestQueue());

container.bind(HTTPMethodOverrideMiddleware).toDynamicValue((context) => {
  return new HTTPMethodOverrideMiddleware();
});

container.bind(APIErrorMiddlewareSymbol).toDynamicValue((context) => {
  const errorMiddleware = new ErrorMiddleware();

  errorMiddleware.setErrorAction((err) => {
    if (!err.response) return;
    switch (err.response.status) {
      case 400: {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);

        if (err.response?.data?.code === "IncorrectDepartment") {
          params.delete("departmentId");

          console.log(url.origin + url.pathname + params + url.hash);
          window.location.replace(
            url.origin + url.pathname + "?" + params + "#" + url.hash
          );
        }
        return;
      }
      case 401:
        context.container.get(EncooOidcClient).signinSilent();
        return;
    }
  });
  return errorMiddleware;
});

container
  .bind(ErrorMiddlewareSymbol)
  .toDynamicValue(() => new ErrorMiddleware());

container.bind(HttpClientSymbol).toDynamicValue((context) => {
  const client = new AxiosHttpClient();
  const settings = context.container.get<Window["_settings"]>(ProfileSymbol);

  if (settings.httpMethodOverride) {
    client.use(context.container.get(HTTPMethodOverrideMiddleware));
  }

  client.use(context.container.get(APIErrorMiddlewareSymbol));
  client.use(context.container.get(ErrorMiddlewareSymbol));
  client.use(context.container.get(TenantHeaderMiddlewareSymbol));
  client.use(context.container.get(RequestQueueMiddlewareSymbol));
  client.use(context.container.get(CompanyHeaderMiddlewareSymbol));
  client.use(context.container.get(DepartmentHeaderMiddlewareSymbol));
  return client;
});

container
  .bind(ResourceGroupServiceClient)
  .toDynamicValue(
    (context) =>
      new ResourceGroupServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(PackageServiceClient)
  .toDynamicValue(
    (context) =>
      new PackageServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(WorkflowServiceClient)
  .toDynamicValue(
    (context) =>
      new WorkflowServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(RobotServiceClient)
  .toDynamicValue(
    (context) =>
      new RobotServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(DeviceServiceClient)
  .toDynamicValue(
    (context) =>
      new DeviceServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(RoleServiceClient)
  .toDynamicValue(
    (context) =>
      new RoleServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(RoleAssignmentServiceClient)
  .toDynamicValue(
    (context) =>
      new RoleAssignmentServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(UserServiceClient)
  .toDynamicValue(
    (context) =>
      new UserServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(AuditlogServiceClient)
  .toDynamicValue(
    (context) =>
      new AuditlogServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(AuditlogSearchServiceClient)
  .toDynamicValue(
    (context) =>
      new AuditlogSearchServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(QueueServiceClient)
  .toDynamicValue(
    (context) =>
      new QueueServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(DataQueueServiceClient)
  .toDynamicValue(
    (context) =>
      new DataQueueServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(JobQueueServiceClient)
  .toDynamicValue(
    (context) =>
      new JobQueueServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(PermissionServiceClient)
  .toDynamicValue(
    (context) =>
      new PermissionServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(LicenseBindingsServiceClient)
  .toDynamicValue(
    (context) =>
      new LicenseBindingsServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(RpaLicenseServiceServiceClient)
  .toDynamicValue(
    (context) =>
      new RpaLicenseServiceServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(RpaConfigServiceClient)
  .toDynamicValue(
    (context) =>
      new RpaConfigServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(RuninstanceServiceClient)
  .toDynamicValue(
    (context) =>
      new RuninstanceServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(JobServiceClient)
  .toDynamicValue(
    (context) =>
      new JobServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(UnderstandingSeviceClient)
  .toDynamicValue(
    (context) =>
      new UnderstandingSeviceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(InstallationServiceClient)
  .toDynamicValue(
    (context) =>
      new InstallationServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(DashboardServiceClient)
  .toDynamicValue(
    (context) =>
      new DashboardServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(EnvironmentServiceClient)
  .toDynamicValue(
    (context) =>
      new EnvironmentServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(AssetV2ServiceClient)
  .toDynamicValue(
    (context) =>
      new AssetV2ServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(TenantServiceClient)
  .toDynamicValue(
    (context) =>
      new TenantServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(FileServiceClient)
  .toDynamicValue(
    (context) =>
      new FileServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(DataPermissionServiceClient)
  .toDynamicValue(
    (context) =>
      new DataPermissionServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(DataTableServiceClient)
  .toDynamicValue(
    (context) =>
      new DataTableServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(DepartmentServiceClient)
  .toDynamicValue(
    (context) =>
      new DepartmentServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(CompanyServiceClient)
  .toDynamicValue(
    (context) =>
      new CompanyServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(FeatureServiceClient)
  .toDynamicValue(
    (context) =>
      new FeatureServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(DataPermissionsServiceClient)
  .toDynamicValue(
    (context) =>
      new DataPermissionsServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(UserManagerServiceClient)
  .toDynamicValue(
    (context) =>
      new UserManagerServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container.bind(ApplicationInsightsService).toDynamicValue((context) => {
  const env = context.container.get<Profile>(ProfileSymbol).env;
  const config =
    env === "prod"
      ? prodApplicationInsightsConfig
      : devApplicationInsightsConfig;
  return new ApplicationInsightsService({
    ...config,
    disableTelemetry: !!window._settings.disableApplicationInsights,
  });
});

container
  .bind(DepartmentUserServiceClient)
  .toDynamicValue(
    (context) =>
      new DepartmentUserServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(DataConnectionServiceClient)
  .toDynamicValue(
    (context) =>
      new DataConnectionServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(CompanyUserServiceClient)
  .toDynamicValue(
    (context) =>
      new CompanyUserServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(AppsV2ServiceClient)
  .toDynamicValue(
    (context) =>
      new AppsV2ServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(ViCodeAppServiceClient)
  .toDynamicValue(
    (context) =>
      new ViCodeAppServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(SystemSettingsServiceClient)
  .toDynamicValue(
    (context) =>
      new SystemSettingsServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(ThirdIntegrationServiceClient)
  .toDynamicValue(
    (context) =>
      new ThirdIntegrationServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(DataSourceServiceClient)
  .toDynamicValue(
    (context) =>
      new DataSourceServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(RoleManagerServiceClient)
  .toDynamicValue(
    (context) =>
      new RoleManagerServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(CustomPermissionRoleServiceClient)
  .toDynamicValue(
    (context) =>
      new CustomPermissionRoleServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(DataPermissionServiceClientV2)
  .toDynamicValue(
    (context) =>
      new DataPermissionServiceClientV2(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(AppTemplateServiceClient)
  .toDynamicValue(
    (context) =>
      new AppTemplateServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(SignalRConnection)
  .toDynamicValue(
    (context) =>
      new SignalRConnection(
        context.container.get<Window["_settings"]>(ProfileSymbol).endpoint,
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol)
      )
  );

container
  .bind(OCRService)
  .toDynamicValue(
    (context) =>
      new OCRService(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(WorkflowLayoutService)
  .toDynamicValue(
    (context) =>
      new WorkflowLayoutService(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(DataTableService)
  .toDynamicValue(
    (context) =>
      new DataTableService(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(CalendarsService)
  .toDynamicValue(
    (context) =>
      new CalendarsService(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(CoeDataAnalysisService)
  .toDynamicValue(
    (context) =>
      new CoeDataAnalysisService(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(CoeDataAnalysisPackageConfigService)
  .toDynamicValue(
    (context) =>
      new CoeDataAnalysisPackageConfigService(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(CoeWorkService)
  .toDynamicValue(
    (context) =>
      new CoeWorkService(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(ExpiryPromptService)
  .toDynamicValue(
    (context) =>
      new ExpiryPromptService(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(ConversationService)
  .toDynamicValue(
    (context) =>
      new ConversationService(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(ApplicationServiceClient)
  .toDynamicValue(
    (context) =>
      new ApplicationServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(KnowledgeServiceClient)
  .toDynamicValue(
    (context) =>
      new KnowledgeServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(LamossConfigServiceClient)
  .toDynamicValue(
    (context) =>
      new LamossConfigServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(PaymentClient)
  .toDynamicValue(
    (context) =>
      new PaymentClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(PaymentOrderClient)
  .toDynamicValue(
    (context) =>
      new PaymentOrderClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(QuotaServiceClient)
  .toDynamicValue(
    (context) =>
      new QuotaServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

export default container;
