import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { LienceExpiryPrompt } from "src/models/license";

export const EXPIRYPROMPT_SERVICE_NAME = "expiryPrompt";

export class ExpiryPromptService extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, EXPIRYPROMPT_SERVICE_NAME);
  }

  //获取过期通知
  async getWarnings(): Promise<LienceExpiryPrompt> {
    const req = this.buildRequestV2({
      url: `/v2/licenses/warnings`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  //关闭通知
  async closeNotice() {
    const req = this.buildRequestV2({
      url: `/v2/licenses/warnings/hideforcurrentuser`,
      method: "POST",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
}
