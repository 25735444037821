import {
  Constants,
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { EncooBatchResult, EncooListEntity } from "src/models/_shared";
import { Job, JobQuery, JobUpdateData } from "src/models/job";
import { Robot } from "src/models/robot";
import {
  Trigger,
  TriggerCreationData,
  TriggerLog,
  TriggerSchedulerConfig,
  TriggerUpdateData,
  Workflow,
  WorkflowBasicData,
  WorkflowCreationData,
  WorkflowQuery,
  WorkflowUpdateData,
} from "src/models/workflow";
export const WORKFLOW_SERVICE_NAME = "workflow";

export class WorkflowServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, WORKFLOW_SERVICE_NAME);
  }

  async list(
    pageIndex: number,
    pageSize: number,
    query?: WorkflowQuery,
    packageId?: string
  ): Promise<EncooListEntity<Workflow>> {
    const req = this.buildRequestV2({
      url: `/v2/workflows`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
        packageVersionId: query?.packageVersionId,
        searchString: query?.name,
        orderBy: query?.orderBy,
        isDesc: query?.sortOrder === "descend",
        packageId,
        includeExecutionTarget: true,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
  async getAllByPackageId(
    packageId: string,
    includeExecutionTarget?: boolean
  ): Promise<Workflow[]> {
    const req = this.buildRequestV2({
      url: `/v2/workflows`,
      method: "GET",
      query: {
        pageIndex: 0,
        pageSize: 1000,
        packageId,
        includeExecutionTarget,
      },
    });
    const { body } = await this.sendRequest(req);
    return body.list;
  }
  async getAllWorkflow(): Promise<Array<Workflow>> {
    const req = this.buildRequestV2({
      url: `/v2/workflows`,
      method: "GET",
      query: {
        pageSize: 1000,
      },
    });
    const { body } = await this.sendRequest(req);
    return body.list;
  }
  async getAll(): Promise<Array<Workflow>> {
    const req = this.buildRequestV2({ url: `/v2/workflows`, method: "GET" });
    return await super.getAllInternal(req);
  }

  async getById(workflowId: string): Promise<Workflow> {
    const req = this.buildRequestV2({
      url: `/v2/workflows/${workflowId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async create(payload: WorkflowCreationData): Promise<Workflow> {
    const req = this.buildRequestV2({
      url: `/v2/workflows`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  async update(
    workflowId: string,
    payload: WorkflowUpdateData
  ): Promise<Workflow> {
    const req = this.buildRequestV2({
      url: `/v2/workflows/${workflowId}`,
      method: "PATCH",
      payload,
    });
    if (payload.eTag) {
      req.headers?.set(Constants.HeaderConstants.IF_MATCH, payload.eTag);
    }
    return await super.updateInternal(req);
  }

  async delete(workflowId: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/workflows/${workflowId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }

  //#region  Triggers API
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async listAllTriggers(
    workflowId: string,
    pageIndex: number,
    pageSize: number
  ) {
    const req = this.buildRequestV2({
      url: `/v2/workflows/${workflowId}/triggers?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getAllTriggers(workflowId: string): Promise<Array<Trigger>> {
    const req = this.buildRequestV2({
      url: `/v2/workflows/${workflowId}/triggers`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body.list;
  }

  async createPlan(
    workflowId: string,
    payload: TriggerCreationData
  ): Promise<Trigger> {
    if (payload.cronExpression) {
      const cornArr = payload.cronExpression.split("&");
      payload.cronExpression = cornArr[0];
      payload.RegionCode = cornArr[1];
    }
    const req = this.buildRequest({
      url: `/v2/workflows/${workflowId}/triggers`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  async createTrigger(
    workflowId: string,
    payload: TriggerCreationData
  ): Promise<Trigger> {
    if (payload.cronExpression) {
      const cornArr = payload.cronExpression.split("&");
      payload.cronExpression = cornArr[0];
      payload.RegionCode = cornArr[1];
    }

    const req = this.buildRequest({
      url: `/v2/workflows/${workflowId}/execute`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  async updateTrigger(
    workflowId: string,
    triggerId: string,
    payload: TriggerUpdateData
  ): Promise<Trigger> {
    const req = this.buildRequestV2({
      url: `/v2/workflows/${workflowId}/triggers/${triggerId}`,
      method: "PATCH",
      payload,
    });
    if (payload.eTag) {
      req.headers?.set(Constants.HeaderConstants.IF_MATCH, payload.eTag);
    }
    return await super.createInternal(req);
  }

  async getTrigger(workflowId: string, triggerId: string): Promise<Trigger> {
    const req = this.buildRequestV2({
      url: `/v2/workflows/${workflowId}/triggers/${triggerId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async deleteTrigger(workflowId: string, triggerId: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/workflows/${workflowId}/triggers/${triggerId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }
  //#endregion

  async listTriggerLogs(
    workflowId: string,
    triggerId: string,
    pageIndex: number,
    pageSize: number
  ): Promise<EncooListEntity<TriggerLog>> {
    const req = this.buildRequestV2({
      url: `/v2/workflows/${workflowId}/triggers/${triggerId}/logs`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
  async enableDisabled(
    workflowId: string,
    triggerId: string,
    isEnabled: boolean
  ): Promise<Trigger> {
    let url = `/v2/workflows/${workflowId}/triggers/${triggerId}/disable`;
    if (isEnabled) {
      url = `/v2/workflows/${workflowId}/triggers/${triggerId}/enable`;
    }
    const req = this.buildRequestV2({
      url: url,
      method: "POST",
    });
    return await this.updateInternal(req);
  }

  async getAllTriggerLogs(
    workflowId: string,
    triggerId: string
  ): Promise<TriggerLog[]> {
    const req = this.buildRequestV2({
      url: `/v2/workflows/${workflowId}/triggers/${triggerId}/logs`,
      method: "GET",
    });
    return await super.getAllInternal(req);
  }

  //#region  Jobs API
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async listAllJobs(
    departmentId: string,
    workflowId: string,
    pageIndex: number,
    pageSize: number,
    query?: JobQuery
  ) {
    const req = this.buildRequestV2({
      url: `/v2/workflows/${workflowId}/jobs`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
        orderBy: query?.orderBy,
        isDesc: query?.sortOrder === "descend",
        sources: query?.source,
        name: query?.name,
        jobStartupType: query?.jobStartupType,
        queryTimeType: query?.timeColumnName,
        startQueryTime: query?.startTime,
        endQueryTime: query?.endTime,
        timeZone: -new Date().getTimezoneOffset(),
        displayStates: query?.status,
      },
      departmentId,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getAllJobs(workflowId: string): Promise<Array<Job>> {
    const req = this.buildRequestV2({
      url: `/v2/workflows/${workflowId}/jobs`,
      method: "GET",
    });
    return await super.getAllInternal(req);
  }

  async getJob(workflowId: string, jobId: string): Promise<Job> {
    const req = this.buildRequest({
      url: `/v2/workflows/${workflowId}/jobs/${jobId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async upateJob(
    workflowId: string,
    jobId: string,
    payload: JobUpdateData
  ): Promise<Job> {
    const req = this.buildRequest({
      url: `/v2/workflows/${workflowId}/jobs/${jobId}`,
      method: "PATCH",
      payload,
    });
    return await super.updateInternal(req);
  }
  //#endregion

  //#region Robot API
  async getAllRobots(workflowId: string): Promise<Robot[]> {
    const req = this.buildRequestV2({
      url: `/v2/workflows/${workflowId}/robots`,
      method: "GET",
      query: {
        pageSize: 1000,
      },
    });
    const { body } = await this.sendRequest(req);
    return body.list;
  }
  // async getAllRobots(pageIndex: number,pageSize: number,workflowId: string){
  //   const req = this.buildRequest({
  //     url: `/v2/workflows/${workflowId}/robots?pageIndex=${pageIndex}&pageSize=${pageSize}`,
  //     method: "GET",
  //   });
  //   const {body} = await this.sendRequest(req);
  //   return body;
  // }
  async bindRobot(
    workflowId: string,
    robotId: string
  ): Promise<{
    workflowId: string;
    robotId: string;
  }> {
    const req = this.buildRequestV2({
      url: `/v2/workflows/${workflowId}/robots/${robotId}`,
      method: "PUT",
    });
    return await super.createInternal(req);
  }

  async batchUpdateWorkflow(
    workflowIds: Array<string>,
    payload: WorkflowBasicData
  ): Promise<EncooBatchResult> {
    const req = this.buildRequestV2({
      url: `/v2/workflows/batch/edit`,
      method: "POST",
      payload: {
        workflowIds,
        ...payload,
      },
    });
    return await super.updateInternal<EncooBatchResult>(req);
  }

  async batchUpgradeWorkflows(
    workflowIds: Array<string>
  ): Promise<EncooBatchResult> {
    const req = this.buildRequestV2({
      url: `/v2/workflows/batch/upgrade`,
      method: "POST",
      payload: {
        workflowIds,
      },
    });
    return await super.updateInternal<EncooBatchResult>(req);
  }
  async batchExecuteWorkflows(
    workflowIds: Array<string>
  ): Promise<EncooBatchResult> {
    const req = this.buildRequestV2({
      url: `/v2/workflows/batch/execute`,
      method: "POST",
      payload: {
        workflowIds,
      },
    });
    return await super.updateInternal<EncooBatchResult>(req);
  }
  async batchDelete(workflowIds: string[]): Promise<EncooBatchResult> {
    const req = this.buildRequestV2({
      url: `/v2/workflows/batch/delete`,
      method: "POST",
      payload: {
        workflowIds,
      },
    });
    return await super.updateInternal<EncooBatchResult>(req);
  }
  async batchBindRobot(
    workflowId: string,
    payload: { robotIds: Array<string> }
  ): Promise<
    Array<{
      workflowId: string;
      robotId: string;
    }>
  > {
    const req = this.buildRequestV2({
      url: `/v2/workflows/${workflowId}/robots`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  async batchUpdateBindedTarget(
    workflowId: string,
    payload:
      | {
          queueId: string | null | undefined;
        }
      | {
          robotIds: Array<string> | null | undefined;
        }
  ): Promise<
    Array<{
      workflowId: string;
      robotId: string;
    }>
  > {
    const req = this.buildRequestV2({
      url: `/v2/workflows/${workflowId}/executiontarget`,
      method: "PATCH",
      payload,
    });
    return await super.createInternal(req);
  }

  async undinbRobot(workflowId: string, robotId: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/workflows/${workflowId}/robots/${robotId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }

  async getRobotById(workflowId: string, robotId: string): Promise<Trigger> {
    const req = this.buildRequestV2({
      url: `/v2/workflows/${workflowId}/robots/${robotId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async listJobsByQueue(
    departmentId: string,
    queueId: string,
    pageIndex: number,
    pageSize: number,
    query?: JobQuery
  ) {
    const req = this.buildRequestV2({
      url: `/v2/queues/${queueId}/jobs`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
        sources: query?.source,
        orderBy: query?.orderBy,
        isDesc: query?.sortOrder === "descend",
        name: query?.name,
        jobStartupType: query?.jobStartupType,
        queryTimeType: query?.timeColumnName,
        startQueryTime: query?.startTime,
        endQueryTime: query?.endTime,
        timeZone: -new Date().getTimezoneOffset(),
        displayStates: query?.status,
      },
      departmentId,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getJobsByQueue(queueId: string): Promise<Array<Job>> {
    const req = this.buildRequestV2({
      url: `/v2/queues/${queueId}/jobs`,
      method: "GET",
    });
    return await super.getAllInternal(req);
  }
  //#endregion

  // 计算定时器近n次执行时间
  async calculateTriggerScheduler(
    payload: TriggerSchedulerConfig
  ): Promise<{ executionAtDtList: string[] }> {
    const req = this.buildRequestV2({
      url: `/v2/triggers/scheduler/calculate`,
      method: "POST",
      payload,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
}
