// eslint-disable-next-line import/no-anonymous-default-export
export default {
  jobQueue: {
    introduction:
      "任务队列主要用于监听业务数据并触发给相应的流程部署或机器人运行。",
    confirmTile: "您还可以：",
    createConfig: "无数据，请新建监听对象",
    lookDetail: "查看任务详情",
    id: "队列标识",
    idInfo: "在代码中调用API时使用",
    status: {
      reserved: "处理中",
      toDo: "未处理",
      done: "已处理",
      delete: "已删除",
    },
    monitor: "新建监听者",
    notParameter: "不关联",
    monitorObject: "监听对象",
    bindParameter: "消息关联至参数",
    messageRemove: "消息移除",
    newMessageTrigger: "有新消息时触发",
    newMessageTriggerTip:
      "当队列中有新消息时将自动触发并执行流程；若未选中，新消息不会触发执行流程",
    removeWay: {
      noRemove: "不移除",
      rightNowRemove: "立刻移除",
      successRemove: "执行成功时",
      finishRemove: "执行结束时",
    },
    multiJObWait: "多个消息排队时",
    waitFinish: "等待上一任务结束后处理",
    nowCreate: "立刻新建任务处理",
    abandon: "放弃",
  },
};
