import moment from "moment";

export const timeValidOrNot = (
  validTo: string,
  format = "YYYY-MM-DD hh:mm:ss A"
): boolean => {
  const now = moment().locale("en").format(format);
  const validToFormat = moment(validTo).locale("en").format(format);

  return validToFormat > now;
};
