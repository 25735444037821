import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { EntityQuota } from "src/models/quota";

export const QUOTA_SERVICE_NAME = "quota";

export class QuotaServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, QUOTA_SERVICE_NAME);
  }

  //查限制数量
  async getQuotalist(): Promise<EntityQuota[]> {
    const req = this.buildRequestV2({
      url: `/v2/EntityQuota`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  //查已创建数量
  async getQuotaCount(entityType: string): Promise<number> {
    const req = this.buildRequestV2({
      url: `/v2/EntityQuota/count?entityType=${entityType}`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  //校验是否用完限制数量
  async IsOutOfQuota(entityType: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/EntityQuota/IsOutOfQuota?entityType=${entityType}`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
}
