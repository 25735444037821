import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import {
  JobQueue,
  JobQueueBasicData,
  JobQueueMessage,
  JobQueueMessageQuery,
  JobQueueQuery,
  MessageCreationData,
  WorkflowConfigs,
  WorkflowConfigsUpdateData,
} from "src/models/jobQueue";
import { EncooListEntity } from "src/models/_shared";

export const QUEUE_SERVICE_NAME = "queue";

export class JobQueueServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, QUEUE_SERVICE_NAME);
  }

  async list(
    pageIndex: number,
    pageSize: number,
    query?: JobQueueQuery
  ): Promise<EncooListEntity<JobQueue>> {
    const req = this.buildRequestV2({
      url: `/v2/taskqueues`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
        searchString: query?.name,
        orderBy: query?.orderBy,
        isDesc: query?.sortOrder === "descend",
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async create(payload: JobQueueBasicData): Promise<JobQueue> {
    const req = this.buildRequestV2({
      url: `/v2/taskqueues`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }
  async update(
    queueName: string,
    displayName: string,
    description: string | null,
    tags?: string[]
  ): Promise<JobQueue> {
    const req = this.buildRequestV2({
      url: `/v2/taskqueues/${queueName}`,
      method: "PATCH",
      payload: {
        description,
        tags,
        displayName,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
  async updateConfig(
    updateData: WorkflowConfigsUpdateData
  ): Promise<WorkflowConfigs> {
    const {
      triggerPolicy,
      dequeuePolicy,
      workflowId,
      argumentMappings,
      queueName,
      dataPolicy,
    } = updateData;

    const payload = {
      configurations: [
        {
          workflowId,
          dequeuePolicy:
            argumentMappings?.mapping === "None" ? "None" : dequeuePolicy,
          triggerPolicy,
          dataPolicy,
          lifeTimeType:
            argumentMappings?.mapping === "None" ? "notification" : "managed",
          argumentMappings,
        },
      ],
    };
    const req = this.buildRequestV2({
      url: `/v2/taskqueues/${queueName}/configs`,
      method: "PUT",
      payload,
    });
    const { body } = await this.sendRequest(req);
    return body.list[0];
  }
  async delete(queueName: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/taskqueues/${queueName}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }
  async messageDelete(
    queueName: string,
    messageId: string,
    popReceipt: string
  ): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/taskqueues/${queueName}/messages/${messageId}?popReceipt=${popReceipt}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }
  async getConfig(queueName: string): Promise<WorkflowConfigs> {
    const req = this.buildRequestV2({
      url: `/v2/taskqueues/${queueName}/configs`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body.list[0];
  }
  async getMessages(
    departmentId: string,
    queueName: string,
    pageIndex: number,
    pageSize: number,
    query?: JobQueueMessageQuery
  ): Promise<EncooListEntity<JobQueueMessage>> {
    const req = this.buildRequestV2({
      url: `/v2/taskqueues/${queueName}/messageRecords`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
        status: query?.status ? query?.status : undefined,
      },
      departmentId,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
  async createMessage(
    queueName: string,
    payload: MessageCreationData
  ): Promise<JobQueueMessage> {
    const { messageText, messagettl } = payload;
    const req = this.buildRequestV2({
      url: `/v2/taskqueues/${queueName}/messages`,
      method: "POST",
      payload: {
        messageText,
      },
      query: {
        messagettl,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  //#endregion
}
