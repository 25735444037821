import { createModel, createSelector } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { DataPermissionEntityModel } from "src/store/models/entity/dataPermission/entity";
import { WorkflowLayoutEntityModel } from "src/store/models/entity/workflowLayout/entity";
import { WorkflowLayoutHelperModel } from "src/store/models/entity/workflowLayout/helper";
import { WorkflowLayoutWithPermission } from "src/store/models/ui/workflowLayout/workflowLayoutTable";

export const WorkflowLayoutDetailUIModel = createModel(
  class extends ModelBase {
    private get _id() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.containerKey!;
    }
    public selectors() {
      return {
        workflowLayoutDetail: createSelector(
          () => this.getContainer(WorkflowLayoutEntityModel).state.byId,
          () => this.getContainer(DataPermissionEntityModel).state.byId,
          (
            workflowLayoutById,
            permissionById
          ): WorkflowLayoutWithPermission | null => {
            const item = workflowLayoutById[this._id];
            const itemWithPermission: WorkflowLayoutWithPermission | null = item
              ? ({
                  ...item,
                  permissionValues:
                    permissionById[this._id]?.permissionValues ?? [],
                } as WorkflowLayoutWithPermission)
              : null;

            return itemWithPermission;
          }
        ),

        hasTriggerReadPermission: createSelector(
          (): WorkflowLayoutWithPermission | null =>
            this.getters.workflowLayoutDetail,
          (workflow) =>
            workflow?.permissionValues.includes("WorkflowLayout_Trigger_Read") // 任务计划查看
        ),
        hasTriggerCreatePermission: createSelector(
          (): WorkflowLayoutWithPermission | null =>
            this.getters.workflowLayoutDetail,
          (workflow) =>
            workflow?.permissionValues.includes("WorkflowLayout_Trigger_Create") // 任务计划创建
        ),
        hasTriggerUpdatePermission: createSelector(
          (): WorkflowLayoutWithPermission | null =>
            this.getters.workflowLayoutDetail,
          (workflow) =>
            workflow?.permissionValues.includes("WorkflowLayout_Trigger_Update") // 任务计划修改
        ),
        hasTriggerDeletePermission: createSelector(
          (): WorkflowLayoutWithPermission | null =>
            this.getters.workflowLayoutDetail,
          (workflow) =>
            workflow?.permissionValues.includes("WorkflowLayout_Trigger_Delete") // 任务计划修改
        ),
        hasWorkflowLayoutManagePermission: createSelector(
          (): WorkflowLayoutWithPermission | null =>
            this.getters.workflowLayoutDetail,
          (workflow) =>
            workflow?.permissionValues.includes("WorkflowLayout_Manage") // 权限管理
        ),
        hasJobGroupReadPermission: createSelector(
          (): WorkflowLayoutWithPermission | null =>
            this.getters.workflowLayoutDetail,
          (workflow) => workflow?.permissionValues.includes("JobGroup_Read") // 任务组查看
        ),
        hasJobGroupUpdatePermission: createSelector(
          (): WorkflowLayoutWithPermission | null =>
            this.getters.workflowLayoutDetail,
          (workflow) => workflow?.permissionValues.includes("JobGroup_Update") // 任务组修改
        ),
        hasJobGroupDeletePermission: createSelector(
          (): WorkflowLayoutWithPermission | null =>
            this.getters.workflowLayoutDetail,
          (workflow) => workflow?.permissionValues.includes("JobGroup_Delete") // 任务组删除
        ),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        requestDetail: async () => {
          await this.getContainer(
            WorkflowLayoutHelperModel
          ).actions.getById.dispatch({
            workflowLayoutId: this._id,
            force: true,
          });
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
