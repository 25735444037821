import { message } from "antd";
import { createModel } from "nyax";
import messageIds from "src/locales/messageIds";
import { ModelBase } from "src/store/ModelBase";
import { UploadingUIModel } from "src/store/models/ui/file/uploading";
import { PackageTableUIModel } from "src/store/models/ui/package/packageTable";

export const UploadPackageUIModel = createModel(
  class extends ModelBase {
    public effects() {
      return {
        uploadPackages: async (payload: { files: File[] }) => {
          const { files } = payload;

          const uploadingFileRecord =
            this.getContainer(UploadingUIModel).state.uploadingFileRecord;

          const foundUploading = files.find(
            (file) =>
              uploadingFileRecord[file.name] &&
              uploadingFileRecord[file.name].state !== "Completed" &&
              uploadingFileRecord[file.name].state !== "Failed"
          );

          if (foundUploading) {
            message.error(
              this.dependencies.locale.intl.formatMessage({
                id: messageIds.package.sameName,
              })
            );
            return;
          }

          await Promise.all(
            files.map((file) =>
              this.dependencies.serviceClient.package.uploadPackage(
                file,
                async (info) => {
                  await this.getContainer(
                    UploadingUIModel
                  ).actions.updateUploadCallbackInfo.dispatch(info);
                }
              )
            )
          );

          await this.getContainer(PackageTableUIModel).actions.refresh.dispatch(
            {}
          );
        },
      };
    }
  }
);
