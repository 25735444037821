import CryptoJS from "crypto-js";

const key = CryptoJS.enc.Utf8.parse("Xp2s5v8y/B?E(H+M");
const iv = CryptoJS.enc.Utf8.parse("Xp2s5v8y/B?E(H+M");

//**************************************************************
//*字符串/对象加密
//*   data：需要解密的字符串或对象
//****************************************************************/

/**
 * @function encrypt: 对象加密
 * @returns dataEncrypted: AES256加密后字符串
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function encrypt<T extends object>(data: T): string {
  const preData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
  const dataEncrypted = CryptoJS.AES.encrypt(preData, key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();

  return dataEncrypted;
}

export function decrypt<T>(dataEncrypted: string): T {
  const secretText = dataEncrypted;
  // padding填充采用默认的Pkcs7 (the default) crypto 的mode默认：CBC (the default)
  const decrypt = CryptoJS.AES.decrypt(secretText, key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  const decryptedData = JSON.parse(decrypt.toString(CryptoJS.enc.Utf8));

  return decryptedData;
}
