import { createModel } from "nyax";
import {
  CustomPermissionRoleInfo,
  PermissionTable,
} from "src/models/permissionSetting";
import { RoleQuery } from "src/models/role";
import { RoleManagementEntityModel } from "src/store/models/entity/roleManagement/entity";
import { RoleManagementHelperModel } from "src/store/models/entity/roleManagement/helper";
import { createUITableBaseModel } from "src/store/models/ui/_shared";

export const RoleManagementTableUIModel = createModel(
  class extends createUITableBaseModel<CustomPermissionRoleInfo, RoleQuery>({
    setItems: (getContainer, items) =>
      getContainer(RoleManagementEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(RoleManagementEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(RoleManagementEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public initialState() {
      return {
        ...super.initialState(),
        permissionTable: null as PermissionTable | null,
        isCreating: false,
      };
    }

    public reducers() {
      return {
        ...super.reducers(),
        setPermissionTable: (value: PermissionTable | null) => {
          this.state.permissionTable = value;
        },
      };
    }

    public effects() {
      return {
        ...super.effects(),
        initial: async () => {
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query: RoleQuery
            ) =>
              this.dependencies.serviceClient.customPermissionRoleServiceClient.list(
                pageIndex,
                pageSize,
                query
              ),
          });
        },
        getPermissionTable: async () => {
          const tableStructureData = await this.getContainer(
            RoleManagementHelperModel
          ).actions.getPermissionTable.dispatch({});
          await this.actions.setPermissionTable.dispatch(tableStructureData);
        },
      };
    }
  }
);
