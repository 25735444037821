/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Space } from "antd";
import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import consoleLogoEn from "src/assets/customizedCustomer/en-US/consoleLogo.svg";
import consoleLogoZh from "src/assets/customizedCustomer/zh-Hans/consoleLogo.svg";
import IconBy from "src/assets/icon_buy.svg";
import iconHeaderLogoEn from "src/assets/icon_header_logo_en.svg";
import iconHeaderLogoZh from "src/assets/icon_header_logo_zh.svg";
import Icon from "src/components/Icon";
import AppPopMenu from "src/containers/layout/AppPopMenu";
import DepartmentComponent from "src/containers/layout/components/DepartmentComponent";
import LocaleComponent from "src/containers/layout/components/LocaleComponent";
import LoginUserComponent from "src/containers/layout/components/LoginUserComponent";
import SupportComponent from "src/containers/layout/components/SupportComponent";
import { ThemeContext } from "src/contexts";
import {
  useFormatMessage,
  useGetContainer,
  useThemeSet,
  useUserEdition,
} from "src/hooks";
import messageIds from "src/locales/messageIds";
import { GlobalSelectCompanyUIModel } from "src/store/models/logic/globalSelectCompany";
import { GlobalSelectDepartmentModel } from "src/store/models/logic/globalSelectDepartment";
import { PopMenuModel } from "src/store/models/popMenu";
import { RouterModel } from "src/store/models/router";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";
import { getLocale } from "src/utils/locale";
const AppHeaderInternal = React.memo(function AppHeaderInternal(props: {
  children?: React.ReactNode;
}) {
  const { children } = props;

  const getContainer = useGetContainer();
  const router = getContainer(RouterModel);
  const globalSelectCompanyUIModel = getContainer(GlobalSelectCompanyUIModel);
  const globalSelectDepartmentContainer = getContainer(
    GlobalSelectDepartmentModel
  );
  const loginUserContainer = getContainer(LoginUserUIModel);
  const sidebar = getContainer(PopMenuModel);
  const userEdition = useUserEdition();
  const formatMessage = useFormatMessage();
  const currentRouteInfo = useSelector(() => router.getters.currentRouteInfo);

  const currentCompanyUser = useSelector(
    () => loginUserContainer.state.currentCompanyUser
  );

  const dingTalk = useMemo(
    () => router.getters.searchParams.get("dingTalk"),
    [router.getters.searchParams]
  );

  const isVisible = useSelector(() => sidebar.state.isVisible);

  const changePopMenuVisible = useCallback(() => {
    sidebar.actions.setVisible.dispatch(!isVisible);
    sidebar.actions.setAllServiceMenuVisible.dispatch(!isVisible);
  }, [
    isVisible,
    sidebar.actions.setAllServiceMenuVisible,
    sidebar.actions.setVisible,
  ]);

  const closePopMenu = useCallback(() => {
    if (isVisible) {
      sidebar.actions.setVisible.dispatch(false);
    }
  }, [isVisible, sidebar.actions.setVisible]);

  const navigateToHome = useCallback(() => {
    router.actions.navigateByRouteInfo.dispatch({
      type: "index",
      params: {},
    });
  }, [router.actions.navigateByRouteInfo]);

  const navigateToPaymentProduct = useCallback(() => {
    router.actions.navigateByRouteInfo.dispatch({
      type: "paymentProduct",
      params: {},
    });
  }, [router.actions.navigateByRouteInfo]);

  const initConfig = useCallback(async () => {
    await globalSelectCompanyUIModel.actions.initConfig.dispatch({});
  }, [globalSelectCompanyUIModel.actions.initConfig]);

  useEffect(() => {
    initConfig();
  });

  useEffect(() => {
    if (userEdition !== "Enterprise") {
      const currentParams = currentRouteInfo.params as Record<string, string>;
      const departmentId = currentParams.departmentId
        ? currentParams.departmentId
        : currentCompanyUser?.assignedDepartmentId;
      globalSelectDepartmentContainer.actions.setGlobalSelectedDepartmentId.dispatch(
        departmentId ?? null
      );
    }
  }, [
    currentCompanyUser,
    currentRouteInfo.params,
    globalSelectDepartmentContainer.actions.setGlobalSelectedDepartmentId,
    userEdition,
  ]);

  return (
    <React.Fragment>
      <header
        onClick={closePopMenu}
        css={css`
          display: flex;
          justify-content: space-between;
          min-width: 1420px; // TODO: 首页样式兼容（右侧有留白）
          min-height: 50px;
          background-color: #3c4a73;
          box-shadow: 0px 2px 8px 2px #eaeaea;
        `}
      >
        {/* 左侧MenuButton与Logo */}
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={css`
              height: 50px;
              width: 50px;
              position: relative;
              span {
                display: block;
                position: absolute;

                width: 20px;
                height: 2px;
                background: rgb(255, 255, 255);
                transition: all 0.3s ease-out 0s;
              }
              border: none;
              cursor: pointer;
              background-color: rgba(95, 107, 140, 1);
            `}
            onClick={changePopMenuVisible}
          >
            <span
              css={
                !isVisible
                  ? css`
                      top: 17px;
                      left: 15px;
                      transform: rotate(0deg);
                    `
                  : css`
                      top: 17px;
                      left: 18px;
                      transform: rotate(45deg);
                      transform-origin: left center;
                    `
              }
            ></span>
            <span
              css={
                !isVisible
                  ? css`
                      left: 15px;
                      top: 24px;
                      opacity: 1;
                    `
                  : css`
                      left: 15px;
                      top: 24px;
                      opacity: 0;
                    `
              }
            ></span>
            <span
              css={
                !isVisible
                  ? css`
                      top: 31px;
                      left: 15px;
                    `
                  : css`
                      top: 31px;
                      left: 18px;
                      transform: rotate(-45deg);
                      transform-origin: left center;
                    `
              }
            ></span>
          </div>
          <img
            src={
              window._settings.customizedCustomer === "custom"
                ? getLocale() === "zh-Hans"
                  ? consoleLogoZh
                  : consoleLogoEn
                : getLocale() === "zh-Hans"
                ? iconHeaderLogoZh
                : iconHeaderLogoEn
            }
            alt="icon_header_logo"
            css={css`
              margin-left: 24px;
              cursor: pointer;
            `}
            onClick={navigateToHome}
          />

          {children}
        </div>
        {/* 右侧Dropdowns */}
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          {userEdition === "Community" && (
            <div
              css={css`
                display: flex;
                margin: 0 12px;
                color: #fff;
                justify-items: center;
                :hover {
                  cursor: pointer;
                }
                column-gap: 4px;
              `}
              onClick={navigateToPaymentProduct}
            >
              <img src={IconBy} alt="" />
              <span>{formatMessage(messageIds.common.buy)}</span>
            </div>
          )}
          {userEdition === "Enterprise" ? (
            <div
              css={css`
                margin-right: 25px;
                .ant-select-selector {
                  background-color: rgba(211, 219, 235, 0.2) !important;
                  border: none !important;
                }
                .ant-select-selection-item {
                  font-size: 14px;
                  font-weight: 400;
                  color: #ffffff;
                  line-height: 20px;
                }
                .ant-select-arrow {
                  color: #ffffff;
                }
              `}
            >
              <DepartmentComponent type={"header"} />
            </div>
          ) : null}
          <Space
            align="center"
            size={0}
            css={css`
              height: 100%;
              .ant-space-item {
                height: 100%;
              }
            `}
          >
            {window._settings.customizedCustomer === "custom" ? (
              <LocaleComponent />
            ) : (
              <React.Fragment>
                {/* 支持 */}
                <SupportComponent />
                {/* 本地化 */}
                <LocaleComponent />
                {/* 帮助 */}
                <HelpComponent />
                {/* TODO: 通知 */}
                {/* <NotificationComponent /> */}
              </React.Fragment>
            )}
            <LoginUserComponent />
          </Space>
        </div>
      </header>
      {!dingTalk && <AppPopMenu />}
    </React.Fragment>
  );
});

const HelpComponent = React.memo(function (props) {
  const formatMessage = useFormatMessage();
  const imgSize = css`
    font-size: 14px;
  `;

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://academy.encoo.com/zh-cn/wiki/Console/README.md?uuid=731e27d0-adf7-4658-9987-b43b277b229f"
      css={css`
        width: 100%;
        height: 100%;
        padding-left: 15px;
        padding-right: 9px;
        display: flex;
        align-items: center;
        color: #ffffff;
        :hover {
          background-color: rgba(0, 0, 0, 0.09);
          color: #ffffff;
          transition: background-color 0.3s ease-in-out;
        }
      `}
    >
      <Icon name="icon-question-circle" css={imgSize} />
      <span
        css={css`
          margin-left: 6px;
          font-size: 12px;
        `}
      >
        {formatMessage(messageIds.common.help)}
      </span>
    </a>
  );
});

export default React.memo(function AppHeader(props: {
  children?: React.ReactNode;
}) {
  const { children } = props;

  const themeSet = useThemeSet();

  return (
    <ThemeContext.Provider value={themeSet.header}>
      <AppHeaderInternal>{children}</AppHeaderInternal>
    </ThemeContext.Provider>
  );
});
