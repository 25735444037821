import moment from "moment";
import { createModel } from "nyax";
import { Job, JobQuery } from "src/models/job";
import { JobEntityModel } from "src/store/models/entity/job/entity";
import { createUITableBaseModel } from "src/store/models/ui/_shared";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createJobTableBaseUIModel = function () {
  return class extends createUITableBaseModel<Job, JobQuery>({
    setItems: (getContainer, items) =>
      getContainer(JobEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(JobEntityModel).getters.items,
    getItem: (getContainer, id) => getContainer(JobEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public initialState() {
      return {
        ...super.initialState(),
        changedTimeRange: false,
        query: {
          timeColumnName: "createdAt", // MARK: feat: 52108 - 默认：创建时间
        } as JobQuery,
      };
    }

    public reducers() {
      return {
        ...super.reducers(),
        setChangedTimeRange: (value: boolean) => {
          this.state.changedTimeRange = value;
        },
      };
    }

    public effects() {
      return {
        ...super.effects(),
        resetTimeChangeToDefault: async () => {
          // 人为改过时间后，就不强行设置为默认七天了
          if (!this.state.changedTimeRange) {
            await this.actions.setQuery.dispatch({
              ...this.state.query,
              startTime: moment()
                .startOf("day")
                .subtract(6, "days")
                .toISOString(),
              endTime: moment().endOf("day").toISOString(),
            });
          }
        },
      };
    }
  };
};

// Job暂时不是Resource，不走实体权限
export const JobTableUIModel = createModel(
  class extends createJobTableBaseUIModel() {
    public effects() {
      return {
        ...super.effects(),
        initial: async (payload: { departmentId: string }) => {
          const { departmentId } = payload;
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query?: JobQuery
            ) =>
              this.dependencies.serviceClient.job.list(
                departmentId,
                pageIndex,
                pageSize,
                query
              ),
          });
        },
      };
    }
  }
);
