// eslint-disable-next-line import/no-anonymous-default-export
export default {
  conversation: {
    name: "Conversation",
    description:
      "Integrate GPT, scan the QR code on WeChat or click the web link to talk to GPT directly",
    tip: "To protect your rights, please do not share it with others",
    mobile:
      "Mobile: Open WeChat, scan the QR code and start a conversation with GPT",
    webPrefix: "Web: Click",
    webSuffix: "Open the web to start a conversation",
    expriedTip: "If it expires, click Refresh",
  },
};
