import { createModel } from "nyax";
import { JobQueueMessage } from "src/models/jobQueue";
import { createItemsEntityModel } from "src/store/itemsEntity";

export const JobQueueMessageEntityModel = createModel(
  class extends createItemsEntityModel<JobQueueMessage>((item) => item.id) {
    public selectors() {
      return {
        ...super.selectors(),
      };
    }
  }
);
