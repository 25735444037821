/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { DepartmentTreeSelect } from "src/containers/organization/department/components/DepartmentTree";
import { useGetContainer } from "src/hooks";
import { AppRouteInfo } from "src/routes";
import { GlobalSelectDepartmentModel } from "src/store/models/logic/globalSelectDepartment";
import { RouterModel } from "src/store/models/router";
import { DepartmentModel } from "src/store/models/ui/organization/department";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";
import { getRouteOfList } from "src/utils/router";

export default React.memo(function DepartmentComponent(props: {
  type?: "header" | "default";
}) {
  const getContainer = useGetContainer();
  const globalSelectDepartmentContainer = getContainer(
    GlobalSelectDepartmentModel
  );
  const loginUserContainer = getContainer(LoginUserUIModel);
  const router = getContainer(RouterModel);

  const currentRouteInfo = useSelector(() => router.getters.currentRouteInfo);

  const currentCompanyUser = useSelector(
    () => loginUserContainer.state.currentCompanyUser
  );

  const globalSelectDepartmentId = useSelector(
    () => globalSelectDepartmentContainer.state.selectedDepartmentId
  );
  const departmentContainer = getContainer(DepartmentModel);

  const listRoute = useMemo(() => {
    return getRouteOfList(currentRouteInfo.type);
  }, [currentRouteInfo.type]);

  const onSelectDepartment = useCallback(
    async (val: string) => {
      const currentParams = currentRouteInfo.params as Record<string, string>;
      if (currentParams.departmentId !== val) {
        // 当前页详情的情况下，切换department调转回列表
        await router.actions.navigateByRouteInfo.dispatch({
          ...(listRoute ?? currentRouteInfo),
          params: { departmentId: val },
        } as AppRouteInfo);
      }
      departmentContainer.actions.setIsDepartmentSelectOpen.dispatch(false);
    },
    [
      currentRouteInfo,
      departmentContainer.actions.setIsDepartmentSelectOpen,
      listRoute,
      router.actions.navigateByRouteInfo,
    ]
  );

  useEffect(() => {
    const currentParams = currentRouteInfo.params as Record<string, string>;
    const departmentId = currentParams.departmentId
      ? currentParams.departmentId
      : currentCompanyUser?.assignedDepartmentId;
    globalSelectDepartmentContainer.actions.setGlobalSelectedDepartmentId.dispatch(
      departmentId ?? null
    );
  }, [
    currentCompanyUser,
    currentRouteInfo.params,
    globalSelectDepartmentContainer.actions.setGlobalSelectedDepartmentId,
    onSelectDepartment,
  ]);

  return (
    <React.Fragment>
      <DepartmentTreeSelect
        css={css`
          width: 200px;
        `}
        value={globalSelectDepartmentId}
        onChange={(val) => onSelectDepartment(val as string)}
        type={props.type}
      />
    </React.Fragment>
  );
});
