import { createModel } from "nyax";
import {
  WorkflowLayout,
  WorkflowLayoutCopyData,
  WorkflowLayoutCreationData,
  WorkflowLayoutUpdateData,
} from "src/models/workflowLayout";
import { DataPermissionHelperModel } from "src/store/models/entity/dataPermission/helper";
import { WorkflowLayoutEntityModel } from "src/store/models/entity/workflowLayout/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const WorkflowLayoutHelperModel = createModel(
  class extends createHelperModel<WorkflowLayout>({
    setItems: (getContainer, items) =>
      getContainer(WorkflowLayoutEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(WorkflowLayoutEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(WorkflowLayoutEntityModel).state.byId[id],
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(WorkflowLayoutEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),

        getById: async (payload: {
          workflowLayoutId: string;
          force?: boolean;
        }) => {
          const { workflowLayoutId, force } = payload;
          return await this._readById({
            id: workflowLayoutId,
            getByIdAction: async () => {
              const item =
                await this.dependencies.serviceClient.workflowLayout.getById(
                  workflowLayoutId
                );
              await this.getContainer(
                DataPermissionHelperModel
              ).actions.getAll.dispatch([
                { resourceType: item.resourceType, id: item.id },
              ]);
              return item;
            },
            force,
          });
        },
        create: async (payload: { params: WorkflowLayoutCreationData }) => {
          const { params } = payload;
          return await this._create({
            createAction: () =>
              this.dependencies.serviceClient.workflowLayout.create(params),
          });
        },
        update: async (params: {
          id: string;
          workflowLayout: WorkflowLayoutUpdateData;
        }) => {
          const { id } = params;
          return await this._update({
            id,
            updateAction: () =>
              this.dependencies.serviceClient.workflowLayout.update(params),
          });
        },

        delete: async (payload: { workflowLayoutId: string }) => {
          const { workflowLayoutId } = payload;
          return await this._delete({
            id: workflowLayoutId,
            deleteAction: () =>
              this.dependencies.serviceClient.workflowLayout.delete(
                workflowLayoutId
              ),
          });
        },
        deleteBatch: async (payload: { workflowLayoutIds: string[] }) => {
          const { workflowLayoutIds } = payload;
          return await this.dependencies.serviceClient.workflowLayout.batchDelete(
            workflowLayoutIds
          );
        },
        executeBatch: async (payload: { workflowLayoutIds: string[] }) => {
          const { workflowLayoutIds } = payload;
          return await this.dependencies.serviceClient.workflowLayout.batchExecute(
            workflowLayoutIds
          );
        },
        execute: async (payload: { workflowLayoutId: string }) => {
          const { workflowLayoutId } = payload;
          return await this.dependencies.serviceClient.workflowLayout.execute(
            workflowLayoutId
          );
        },
        copy: async (params: {
          workflowLayoutId: string;
          payload: WorkflowLayoutCopyData;
        }) => {
          const { workflowLayoutId, payload } = params;
          return await this.dependencies.serviceClient.workflowLayout.copy(
            workflowLayoutId,
            payload
          );
        },
      };
    }
  }
);
