import { createModel, createSelector } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { ApplicationEntityModel } from "src/store/models/entity/application/entity";
import { ApplicationHelperModel } from "src/store/models/entity/application/helper";
import { DataPermissionEntityModel } from "src/store/models/entity/dataPermission/entity";

export const ApplicationDetailUIModel = createModel(
  class extends ModelBase {
    private get _id() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.containerKey!;
    }
    public selectors() {
      return {
        applicationDetail: createSelector(
          () => this.getContainer(ApplicationEntityModel).state.byId,
          () => this.getContainer(DataPermissionEntityModel).state.byId,
          (applicationById, permissionById) => {
            const application = applicationById[this._id];
            return application
              ? {
                  ...application,
                  permissionValues:
                    permissionById[this._id]?.permissionValues ?? [],
                }
              : null;
          }
        ),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        requestDetail: async () => {
          await this.getContainer(
            ApplicationHelperModel
          ).actions.getById.dispatch({
            applicationId: this._id,
          });
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
