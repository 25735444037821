import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { EncooListEntity } from "src/models/_shared";
import {
  UserManagerQuery,
  UserManagerUserDetail,
  UserReInvite,
} from "../containers/userManager/models/UserManagerUserModel";

export const SERVICE_NAME = "UserManagerServiceClient";
// let instance: UserManagerServiceClient;

export class UserManagerServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, SERVICE_NAME);
  }

  list = async (
    pageIndex: number,
    pageSize: number,
    query?: UserManagerQuery
  ): Promise<EncooListEntity<UserManagerUserDetail>> => {
    const req = this.buildRequestV2({
      url: `/v3/CompanyUsers`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
        keyword: query?.keyword,
        status: query?.status,
        userName: query?.userName,
        email: query?.email,
        phoneNumber: query?.phoneNumber,
        roleId: query?.roleId,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async getRoleList() {
    const req = this.buildRequestV2({
      url: `/v2/RoleManagement`,
      method: "GET",
    });
    return await this.sendRequest(req);
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async getRolesAssignments(companyUserid: string) {
    const req = this.buildRequestV2({
      url: `/v2/Permissions/companyusers/${companyUserid}/allrolesAssignments`,
      method: "GET",
    });
    return await this.sendRequest(req);
  }
  async updataUserInfo(
    userInfo: Partial<UserManagerUserDetail>
  ): Promise<UserManagerUserDetail> {
    const id = userInfo.id;
    delete userInfo.id;
    const req = this.buildRequestV2({
      url: `/v2/CompanyUsers/${id}`,
      method: "PATCH",
      payload: userInfo,
    });
    return await super.updateInternal(req);
  }
  async deleteUser(id: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/CompanyUsers/${id}`,
      method: "DELETE",
    });
    return await this.deleteInternal(req);
  }
  async inviteUser(
    name: string,
    email: string,
    departmentId: string,
    roleIds: Array<string>
  ): Promise<UserManagerUserDetail> {
    const req = this.buildRequestV2({
      url: `/v2/Companies/users`,
      method: "POST",
      payload: [
        {
          DisplayName: name,
          email,
          assignedDepartmentId: departmentId,
          roleIds: roleIds,
        },
      ],
    });
    return await super.createInternal(req);
  }
  async reInviteUser(companyUserId: string): Promise<UserReInvite> {
    const req = this.buildRequestV2({
      url: `/v2/Companies/users/${companyUserId}`,
      method: "PATCH",
    });
    return await super.updateInternal(req);
  }
}
