// eslint-disable-next-line import/no-anonymous-default-export
export default {
  role: {
    name: "Role Name",
    introduction:
      "Role Management is used to create various roles. The access and operation permissions of each role can be configured according to actual demand.",
    entityTypeName: "Role",
    search: "Permission Name/Menu Name",
    permission: "Permission",
    createRole: "New Role",
    editRole: "Edit Role",
    manageRole: "Role Management",
    create: "New",
    cancel: "Cancel",
    confirm: "OK",
    reset: "Reset",
    delete: "Delete",
    edit: "Edit",
    view: "View",
    tip: "Tips",
    countersign: "Confirm to delete the role",
    countersignDescription:
      "After deletion, the relevant permissions of users who have already used this role will be invalid.",
    buildIn: "Built-in Role",
    customized: "Custom Role",
    columns: {
      name: "Role Name",
      roleFrom: "Role Type",
      description: "Role Remarks",
      createdAt: "Creation Time",
      operation: "Operation",
    },
    updateSuccess: "Changed.",
    updateError: "Change failed.",
    createSuccess: "Creation succeeded",
    createError: "Creation failed",
    empty: "The currently selected target content is empty",
    customPermissionMiddleware: {
      basic: "Basic Information",
      permission: "Permission Configuration",
      inputName: "Enter the name of the custom permission template",
      remark: "Remarks",
      remarks: "Remarks",
      departmentAuthority: "Department-level Permission Configuration",
      departmentAuthorityTip:
        "Tips: Department-level features are only available in the department to which the role is assigned.",
      companyAuthority: "Company-level Permission Configuration",
      companyAuthorityTip:
        "Tips: Company-level features will be available in all departments of the company.",
      clear: "Clear",
      saveContent: "Saved Content",
      saveNameEmptyError: "You must customize a name for the role",
      saveNameRegExpError: "There are invalid characters in the role name",
      saveNameLengthError:
        "Please make sure that the name length is less than 15 characters",
      savePermissionError:
        "You must select at least one permission for the role",
    },
    permissionTableComponent: {
      resource: "Resources",
      permission: "Permission",
    },
    query: {
      roleFrom: "Role Type",
      query: "Query",
    },
  },
};
