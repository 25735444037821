// eslint-disable-next-line import/no-anonymous-default-export
export default {
  coeDataAnalysis: {
    entityTypeName: "Data Analaysis",
    dataTableName: "Packages' Data",
    dataTableNameTip:
      "The data in the table is calculated based on actual process operation, labor costs, and RPA input costs over the selected time frame. You can adjust the default cost data in Cost Configuration to achieve the accuracy of the data as appropriate.",
    latestYear: "Latest Year",
    export: "Export",
    yuan: " Yuan",
    times: " Times",
    days: " Days",
    totalUnits: "Total Units",
    costSetting: "Cost Configuration",
    basicSetting: "Basic Setting",
    averageLaborCost: "Average Labor Cost per Month",
    averageLaborCostTip:
      "Estimated monthly labor costs based on actual conditions will be used to calculate data such as labor spend for each process.",
    averageRobotCost: "RPA Input Cost per Year",
    averageRobotCostTip:
      "The average annual cost based on the actual overall cost of purchasing the RPA solution will be used to calculate data such as the return on process.",
    anotherCosts: "Another Costs",
    roiPeriod: "ROI Period",
    workflowCost: "Package Cost",
    singleManHour: "Single manual work hours",
    processDevelopmentCost: "Process development cost (yuan)",
    workflowName: "Package Name",
    chart: {
      totalLaborTimeSaved: "Total Labor Time Savings",
      top10LaborTimeSavings: "TOP 10 Labor Time Savings",
      totalLaborCostSaved: "Total Labor Cost Savings",
      top10LaborCostSavings: "Top 10 Labor Cost savings",
      totalROI: "Total ROI",
      top10ROI: "Top 10 ROI",
    },
    columns: {
      departmentName: "Department Name",
      packageName: "Package Name",
      relatedRequirement: "Related Requirement",
      manualOperationHours: "Manual Operation Hours",
      manualOperationHoursTip:
        "If a demand is associated, it is calculated and is equal to `Single Time in Demand / Number of Processes`; if manually configured in the process cost data, it is calculated with the manually configured data.",
      robotHours: "Robot Hours",
      robotHoursTip: "The single average run time of this process",
      firstRunningTime: "First Run Date",
      daysOnline: "Online Days",
      robotOccupancyRate: "Robot Occupancy Rate",
      robotOccupancyRateTip:
        "Robot Occupancy = Total time this process runs / Total time all processes run",
      executionTimes: "Execution Times",
      executionTimesTip: "Number of process runs in the selected time period",
      efficiencyElevation: "Efficiency Elevation",
      efficiencyElevationTip:
        "Efficiency improvement = (manual operation time - robot time) / manual operation time",
      savingHours: "Saving Hours",
      savingHoursTip:
        "Man-hours saved = man-hours spent * number of process runs",
      savingLaborCosts: "Saving Labor Costs",
      savingLaborCostsTip:
        "Labor cost savings = Labor unit cost * Number of process runs",
      roi: "ROI",
      roiTip: "ROI = Labor cost / Robot cost",
    },
  },
};
