// eslint-disable-next-line import/no-anonymous-default-export
export default {
  package: {
    package: "Flow Package",
    version: "Version Number",
    introduction:
      "Flow Package Management is mainly used to manage the flow packages uploaded or manually uploaded by the studio and their corresponding version details, which supports flow package download, deletion and other operations.",
    uploadTime: "Upload Time",
    uploadUser: "Upload User",
    packageDetail: "Flow Package Version Details",
    count: "Flow Package Deployment Frequency",
    packageNote: "Flow Package Remarks",
    deploymentName: "Flow Deployment Name",
    deploymentCount: "Flow Deployment Frequency",
    deploymentNote: "Deployment Remarks",
    deploymentDetail: "Deployment Details",
    deployed: "Deployed",
    notDeploy: "Not Deployed",
    preStep: "Back",
    nextStep: "Next",
    save: "Save and set the trigger.",
    share: "Share Package",
    baseConfig: "Basic Config",
    performanceTargets: "Execution Target",
    packageName: "Package Name",
    parameterManagement: "Flow Package Parameter Management",
    name: "Name",
    parameterType: "Parameter Type",
    direction: {
      title: "Parameter Direction",
      In: "Input",
      Out: "Output",
      InOut: "Input/Output",
      Property: "Property",
    },
    noConnectedWorkflow: "No Connected Workflows",
    parameterDefault: "Default Value",
    queueExecution: "Specify Execution Robot Group",
    robotExecution: "Specify Execution Robot",
    addWorkflow: "Create Workflow",
    uploadPackage: "Upload Flow Package",
    previousVersion: "Previous Version Number",
    latestVersion: "Latest Version Number",
    packageVersionFormat: "The version number format is x.x.x.",
    runAsAdmin: "Run As Admin",
    sameName: "Same name of package is uploading.",
    overfile:
      "The number of selected files has exceeded the remaining amount, The number of selected files is {count}，The remaining amount is {limit}",
  },
};
