import { createModel } from "nyax";
import { DataPermissionInfo } from "src/models/dataPermission";
import { createItemsEntityModel } from "src/store/itemsEntity";

export const DataPermissionEntityModel = createModel(
  class extends createItemsEntityModel<DataPermissionInfo>(
    (item) => item.resourceId
  ) {
    public selectors() {
      return {
        ...super.selectors(),
      };
    }
  }
);
