import { createModel, createSelector } from "nyax";
import { UserIcon } from "src/models/user";
import { ModelBase } from "src/store/ModelBase";
import { RouterModel } from "src/store/models/router";

export type UserCenterViewType =
  | "userInfo"
  | "settings"
  | "orders"
  | "virtualMFA";

export const UserInfoUIModel = createModel(
  class extends ModelBase {
    private get _router() {
      return this.getContainer(RouterModel);
    }
    public initialState() {
      return {
        userIcons: null as UserIcon[] | null,
        currentUserIconInfo: null as UserIcon | null,
        isSelectAvatarModalVisible: false,
        bindSuccess: false,
      };
    }

    public reducers() {
      return {
        ...super.reducers(),
        setUserIcons: (value: UserIcon[]) => {
          this.state.userIcons = value;
        },
        setCurrentUserIconInfo: (value: UserIcon) => {
          this.state.currentUserIconInfo = value;
        },
        setIsSelectAvatarModalVisible: (value: boolean) => {
          this.state.isSelectAvatarModalVisible = value;
        },
        setBindSuccess: (value: boolean) => {
          this.state.bindSuccess = value;
        },
      };
    }

    public selectors() {
      return {
        selectViewType: createSelector(
          (): { path: UserCenterViewType } =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this._router.getters.currentRouteInfo.params as any,
          (params) => {
            return params.path ?? "userInfo";
          }
        ),
      };
    }

    public effects() {
      return {
        setViewType: async (viewType: UserCenterViewType) => {
          this._router.actions.navigateByRouteInfo.dispatch({
            type: "userCenter",
            params: {
              path: viewType,
            },
          });
        },
        getUserIcons: async () => {
          const data = await this.dependencies.serviceClient.user.avatarList();
          await this.actions.setUserIcons.dispatch(data);
          return data;
        },
        getCurrentUserAvatar: async (userId: string) => {
          const data = await this.dependencies.serviceClient.user.getUserAvatar(
            userId
          );
          await this.actions.setCurrentUserIconInfo.dispatch(data);
          return data;
        },
        updateUserAvatar: async (payload: {
          userId: string;
          avatarId: number;
        }) => {
          const { userId, avatarId } = payload;
          await this.dependencies.serviceClient.user.updateUserAvatar(
            userId,
            avatarId
          );
        },
      };
    }
  }
);
