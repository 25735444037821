import { encooAIUIModels } from "src/store/models/ui/ai";
import { encooApplicationUIModels } from "src/store/models/ui/application";
import { AppDetailUIModel } from "src/store/models/ui/apps/appDetail";
import { AppInitConfigUIModel } from "src/store/models/ui/apps/appInitConfig";
import { DialogPreviewAppModel } from "src/store/models/ui/apps/appPreview";
import { AppsUIModel } from "src/store/models/ui/apps/apps";
import { encooAssetUIModels } from "src/store/models/ui/asset";
import { AuditlogUIModel } from "src/store/models/ui/auditlog/auditlog";
import { encooCalendarsUIModels } from "src/store/models/ui/calendars";
import { CoeAttachmentUIModel } from "src/store/models/ui/coeAttachment/coeAttachment";
import { encooCoeDataAnalysisUIModels } from "src/store/models/ui/coeDataAnalysis";
import { encooCoeDataAnalysisPackageConfigUIModels } from "src/store/models/ui/coeDataAnalysisPackageConfig";
import { encooCoeMyWorkUIModels } from "src/store/models/ui/coeMyWork";
import { CoeProcessUIModel } from "src/store/models/ui/coeProcess/coeProcess";
import { encooCoeToDoWorkUIModels } from "src/store/models/ui/coeToDoWork";
import { CoeWorkerUIModels } from "src/store/models/ui/coeWorks";
import { CompanyUIModel } from "src/store/models/ui/company/company";
import { CompanyConnectionUiModel } from "src/store/models/ui/company/companyConnection";
import { DashboardUIModel } from "src/store/models/ui/dashboard/dashboard";
import { QueueStatisticsUIModel } from "src/store/models/ui/dashboard/queueStatistics";
import { RobotRunStatisticsUIModel } from "src/store/models/ui/dashboard/robotRunStatistics";
import { RobotStatusStatisticsUIModel } from "src/store/models/ui/dashboard/robotStatusStatistics";
import { UserFlowStatisticsUIModel } from "src/store/models/ui/dashboard/userFlowStatistics";
import { DataConnectionUiModel } from "src/store/models/ui/dataConnection/dataConnection";
import { DataPermissionUIModel } from "src/store/models/ui/dataPermission/dataPermission";
import { encooDataQueueUIModels } from "src/store/models/ui/dataQueue";
import { encooDataTableUIModels } from "src/store/models/ui/dataTable";
import { encooDeviceUIModels } from "src/store/models/ui/device";
import { EnvironmentModel } from "src/store/models/ui/environment/environment";
import { ErrorUIModel } from "src/store/models/ui/error/error";
import { FeatureModel } from "src/store/models/ui/feature/feature";
import { fileModels } from "src/store/models/ui/file";
import { InstallationUIModel } from "src/store/models/ui/installation/installation";
import { encooJobUIModels } from "src/store/models/ui/job";
import { encooJobQueueUIModels } from "src/store/models/ui/jobQueue";
import { encooKnowledgeUIModels } from "src/store/models/ui/knowledge";
import { encooLamossUIModels } from "src/store/models/ui/lamoss";
import { LicenseUIModel } from "src/store/models/ui/license/license";
import { RpaLicenseBindingListModel } from "src/store/models/ui/license/rpaLicenseBindingTable";
import { LoadingUIModel } from "src/store/models/ui/loading/loading";
import { LocaleUIModel } from "src/store/models/ui/locale/locale";
import { encooOCRUIModels } from "src/store/models/ui/ocr";
import { DepartmentUserDetailUIModel } from "src/store/models/ui/organization/DepartmentUserDetail";
import { DepartmentUsersTableUIModel } from "src/store/models/ui/organization/DepartmentUsers";
import { DepartmentModel } from "src/store/models/ui/organization/department";
import { DepartmentDetailModel } from "src/store/models/ui/organization/departmentDetail";
import { FeaturePermissionModel } from "src/store/models/ui/organization/featurePermission";
import { encooPackageUIModels } from "src/store/models/ui/package";
import { encooPaymentUIModels } from "src/store/models/ui/payment";
import { encooQueueUIModels } from "src/store/models/ui/queue";
import { QuotaUIModel } from "src/store/models/ui/quota/quota";
import { ResourceGroupModel } from "src/store/models/ui/resourceGroup";
import { encooRobotUIModels } from "src/store/models/ui/robot";
import { encooRoleManagementUIModels } from "src/store/models/ui/roleManagement";
import { RpaPlanConfigUIModel } from "src/store/models/ui/rpaPlanConfig/rpaPlanConfig";
import { RunInstanceUIModel } from "src/store/models/ui/runInstance/runInstance";
import { RunInstanceLogModel } from "src/store/models/ui/runInstance/runInstanceLog";
import { encooShareSettingsUIModels } from "src/store/models/ui/shareSettings";
import { SystemSettingUIModel } from "src/store/models/ui/systemSetting/systemSetting";
import { UserTenantUIModel } from "src/store/models/ui/tenant/userTenant";
import { encooTriggerUIModels } from "src/store/models/ui/trigger";
import { understandingModels } from "src/store/models/ui/understanding";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";
import { UserInfoUIModel } from "src/store/models/ui/userCenter/userInfo";
import { VirtualMFAUIModel } from "src/store/models/ui/userCenter/virtualMFA";
import { encooUserManagementUIModels } from "src/store/models/ui/userManagement";
import { UIViCodeAppSharedModel } from "src/store/models/ui/vicodeApp/_shared";
import { UIViCodeAppAppDetailModel } from "src/store/models/ui/vicodeApp/appDetail";
import { UIViCodeAppAppModel } from "src/store/models/ui/vicodeApp/apps";
import { UIViCodeAppFavoriteAppModel } from "src/store/models/ui/vicodeApp/favoriteApp";
import { UIViCodeAppLiveAppModel } from "src/store/models/ui/vicodeApp/liveApp";
import { encooWorkflowUIModels } from "src/store/models/ui/workflow";
import { encooWorkflowLayoutUIModels } from "src/store/models/ui/workflowLayout";
import { encooWorkflowLayoutTriggerUIModels } from "src/store/models/ui/workflowLayoutTrigger";

export const uiModels = {
  coeDoWorks: CoeWorkerUIModels,
  coeToDoWork: encooCoeToDoWorkUIModels,
  coeMyWork: encooCoeMyWorkUIModels,
  coeDataAnalysisPackageConfig: encooCoeDataAnalysisPackageConfigUIModels,
  coeDataAnalysis: encooCoeDataAnalysisUIModels,
  coeProcess: CoeProcessUIModel,
  coeAttachment: CoeAttachmentUIModel,
  calendars: encooCalendarsUIModels,
  dataTable: encooDataTableUIModels,
  workflowLayout: encooWorkflowLayoutUIModels,
  workflowLayoutTrigger: encooWorkflowLayoutTriggerUIModels,
  ocr: encooOCRUIModels,
  rpaPlanConfig: RpaPlanConfigUIModel,
  package: encooPackageUIModels,
  robot: encooRobotUIModels,
  understanding: understandingModels,
  auditlog: AuditlogUIModel,
  resourceGroup: ResourceGroupModel,
  local: LocaleUIModel,
  workflow: encooWorkflowUIModels,
  queue: encooQueueUIModels,
  dataQueue: encooDataQueueUIModels,
  jobQueue: encooJobQueueUIModels,
  trigger: encooTriggerUIModels,
  job: encooJobUIModels,
  device: encooDeviceUIModels,
  shareSettings: encooShareSettingsUIModels,
  rpaLicenseBinding: RpaLicenseBindingListModel,

  app: AppsUIModel,
  appDetail: AppDetailUIModel,
  appPreview: DialogPreviewAppModel,

  vicodeAppApp: UIViCodeAppAppModel,
  vicodeAppliveApp: UIViCodeAppLiveAppModel,
  vicodeAppFavoriteApp: UIViCodeAppFavoriteAppModel,
  vicodeAppAppDetail: UIViCodeAppAppDetailModel,
  vicodeAppShared: UIViCodeAppSharedModel,

  loginUser: LoginUserUIModel,
  runInstance: RunInstanceUIModel,
  runInstanceLog: RunInstanceLogModel,
  installation: InstallationUIModel,
  license: LicenseUIModel,
  dashboard: DashboardUIModel,
  environment: EnvironmentModel,
  Loading: LoadingUIModel,
  error: ErrorUIModel,
  userTenant: UserTenantUIModel,
  userCenter: UserInfoUIModel,
  asset: encooAssetUIModels,
  robotRunStatistics: RobotRunStatisticsUIModel,
  userFlowStatistics: UserFlowStatisticsUIModel,
  robotStatusStatisticsUIModel: RobotStatusStatisticsUIModel,
  queueStatisticsUIModel: QueueStatisticsUIModel,
  file: fileModels,

  department: DepartmentModel,
  departmentDetail: DepartmentDetailModel,
  featurePermission: FeaturePermissionModel,
  dataPermission: DataPermissionUIModel,
  departmentUserDetail: DepartmentUserDetailUIModel,
  departmentUsers: DepartmentUsersTableUIModel,
  company: CompanyUIModel,
  feature: FeatureModel,
  companyConnection: CompanyConnectionUiModel,
  systemSetting: SystemSettingUIModel,
  dataConnection: DataConnectionUiModel,
  virtualMFA: VirtualMFAUIModel,
  appInitConfig: AppInitConfigUIModel,
  userManagement: encooUserManagementUIModels,
  roleManagement: encooRoleManagementUIModels,
  aiManagement: encooAIUIModels,
  application: encooApplicationUIModels,
  knowledge: encooKnowledgeUIModels,
  lamoss: encooLamossUIModels,
  quota: QuotaUIModel,
  payment: encooPaymentUIModels,
};
