import { createModel, createSelector } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { DataPermissionEntityModel } from "src/store/models/entity/dataPermission/entity";
import { CoeToDoWorkEntityModel } from "src/store/models/entity/coeToDoWork/entity";
import { CoeToDoWorkHelperModel } from "src/store/models/entity/coeToDoWork/helper";

export const CoeToDoWorkDetailUIModel = createModel(
  class extends ModelBase {
    private get _id() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.containerKey!;
    }
    public selectors() {
      return {
        coeToDoWorkDetail: createSelector(
          () => this.getContainer(CoeToDoWorkEntityModel).state.byId,
          () => this.getContainer(DataPermissionEntityModel).state.byId,
          (coeToDoWorkById, permissionById) => {
            const coeToDoWork = coeToDoWorkById[this._id];
            return coeToDoWork
              ? {
                  ...coeToDoWork,
                  permissionValues:
                    permissionById[this._id]?.permissionValues ?? [],
                }
              : null;
          }
        ),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        requestDetail: async () => {
          await this.getContainer(CoeToDoWorkHelperModel).actions.getById.dispatch({
            coeToDoWorkId: this._id,
          });
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
