/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Form, Input, message, Radio } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import DescriptionTip from "src/components/DescriptionTip";
import Modal from "src/components/Modal";
import TipBox from "src/components/TipBox";
import { useAppV1Type } from "src/containers/apps/AppV1OperationDialog";
import { AppTagsAssociated } from "src/containers/apps/components/AppTags";
import AppIconSelectDialog from "src/containers/vicodeApp/components/ViCodeAppIconSelectDialog";
import {
  useApplicationInsights,
  useFormatMessage,
  useGetContainer,
} from "src/hooks";
import messageIds from "src/locales/messageIds";
import { AppHelperModel } from "src/store/models/entity/apps/helper";
import { AppTagEntityModel } from "src/store/models/entity/appTag/entity";
import { AppsUIModel } from "src/store/models/ui/apps/apps";
import { QuotaUIModel } from "src/store/models/ui/quota/quota";
import { inputLimitedLength } from "src/utils/validate";

export const AppCreationForm = React.memo(function (props: {
  form: FormInstance;
}) {
  const formatMessage = useFormatMessage();
  const { form } = props;
  const appTypeRecord = useAppV1Type();
  return (
    <Form layout="vertical" form={form}>
      <Form.Item
        name="appType"
        label={
          <DescriptionTip
            tip={formatMessage(messageIds.apps.appType.groupAppDescription)}
          >
            {formatMessage(messageIds.apps.appType.name)}
          </DescriptionTip>
        }
        initialValue={"App"}
        css={css`
          margin-bottom: 10px;
        `}
      >
        <Radio.Group>
          <Radio value={"App"}>{appTypeRecord.App}</Radio>
          <Radio value={"AppGroup"}>{appTypeRecord.AppGroup}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="name"
        label={formatMessage(messageIds.apps.name)}
        rules={[
          {
            required: true,
            max: inputLimitedLength.name,
            whitespace: true,
          },
        ]}
      >
        <Input placeholder={formatMessage(messageIds.common.pleaseInput)} />
      </Form.Item>
      <Form.Item
        name="icon"
        label={formatMessage(messageIds.apps.icon)}
        rules={[
          {
            required: true,
          },
        ]}
        css={css`
          .ant-form-item-control-input {
            min-height: 0 !important;
          }
        `}
      >
        <AppIconSelectDialog version="v1" />
      </Form.Item>
      <Form.Item
        name="tagIds"
        label={formatMessage(messageIds.apps.tag)}
        css={css`
          .ant-form-item-control-input {
            min-height: 0 !important;
          }
        `}
      >
        <AppTagsAssociated />
      </Form.Item>
      <Form.Item
        name="anonymous"
        label={
          <DescriptionTip
            tip={
              <div>
                {formatMessage(messageIds.apps.viewMode.nonAnonymousTip)} <br />
                {formatMessage(messageIds.apps.viewMode.anonymousTip)}
              </div>
            }
          >
            {formatMessage(messageIds.apps.viewMode.name)}
          </DescriptionTip>
        }
        initialValue={false}
        css={css`
          margin-bottom: 10px;
        `}
      >
        <Radio.Group>
          <Radio value={false}>
            {formatMessage(messageIds.apps.viewMode.notAnonymous)}
          </Radio>
          <Radio value={true}>
            {formatMessage(messageIds.apps.viewMode.anonymous)}
          </Radio>
        </Radio.Group>
      </Form.Item>
      <TipBox
        css={css`
          margin-bottom: 32px;
        `}
        content={formatMessage(messageIds.apps.viewMode.tip)}
      />
      <Form.Item
        name="description"
        label={formatMessage(messageIds.common.description)}
        rules={[
          {
            max: inputLimitedLength.description,
            whitespace: true,
          },
        ]}
      >
        <TextArea
          rows={4}
          placeholder={formatMessage(messageIds.common.pleaseInput)}
        />
      </Form.Item>
    </Form>
  );
});

export default React.memo(function AppCreationDialog(props: {
  onCancel: () => void;
}) {
  const getContainer = useGetContainer();
  const formatMessage = useFormatMessage();
  const applicationInsights = useApplicationInsights();

  const appsUiContainer = getContainer(AppsUIModel);
  const appTagEntity = getContainer(AppTagEntityModel);
  const appHelper = getContainer(AppHelperModel);
  const quota = getContainer(QuotaUIModel);

  const appTags = useSelector(() => appTagEntity.getters.items);

  const { onCancel } = props;
  const [form] = useForm();

  const onCreate = useCallback(async () => {
    const value = await form.validateFields();

    const { name, anonymous, description, icon, appType, tagIds } = value;
    const tagList = tagIds?.map((value: string) =>
      appTags.find((item) => item.id === value)
        ? { id: value }
        : { name: value }
    );
    const app = await appsUiContainer.actions.add.dispatch({
      name,
      anonymous,
      description,
      icon: typeof icon === "string" ? icon : undefined,
      appType,
      tagList,
    });
    if (typeof icon === "object") {
      await appHelper.actions.uploadAppIcon.dispatch({
        appId: app.id,
        payload: icon,
      });
    }
    applicationInsights.sendTrackEvent(
      { name: "Vicode_CreatAppEvent" },
      {
        AppType: anonymous ? "匿名" : "非匿名",
        AppID: app.id,
      }
    );
    message.success(formatMessage(messageIds.common.createSuccess));
    onCancel();
    quota.actions.refreshQuota.dispatch({});
  }, [
    appHelper.actions.uploadAppIcon,
    appTags,
    applicationInsights,
    appsUiContainer.actions.add,
    form,
    formatMessage,
    onCancel,
    quota.actions.refreshQuota,
  ]);

  return (
    <Modal
      visible={true}
      title={formatMessage(messageIds.apps.appDev.addApp)}
      onCancel={onCancel}
      onOk={onCreate}
      destroyOnClose={true}
    >
      <AppCreationForm form={form} />
    </Modal>
  );
});
