// eslint-disable-next-line import/no-anonymous-default-export
export default {
  file: {
    files: "File",
    introduction:
      "File Service is mainly used to store and manage various files and provides download services of corresponding files. You can create multi-layer folders for file management.",
    newFolder: "New Directory",
    newSubFolder: "New Subfolder",
    fileSize: "File Size",
    sameDirectoryName: "Folder names cannot be the same.",
    uploadFile: "Upload File",
    hasSpecialCharacter:
      'The file name cannot contain any of the following characters: \\ / . : * ? " < > | # + & = or start with . ',
    preparing: "Preparing",
    uploading: "Uploading",
    compoining: "Merging",
    allCompleted: "All files uploaded!",
    partCompleted: "Partial files uploaded!",
    completed: "Uploaded.",
    allFailed: "All files upload failed!",
    failed: "Upload failed.",
    sameFileNameExist: "File already exists. Do you want to overwrite it?",
    sameFileNameUploading:
      "A file with the same name is being uploaded. Please try again later.",
    setPermissions: "Permission Management",
    permissionManagement: "Please select",
    addUser: "Add User",
    permission: {
      admin: "Manage Permissions",
      readOnly: "Read-only Permission",
      writeOnly: "Write Permission",
      noPermission: "No Permission",
    },
    creator: "Creator",
    specifyUser: "Specify User",
    other: "Others",
    others: "Other User",
    othersTip: "Other User under the Current Tenant",
    placeholder: {
      enterNameOrEmail: "Please enter your name or email address",
    },
    otherEmptyTip: "No specified user. Please add.",
    addUserTip: {
      warining: "Please set permissions for all added users",
    },
    add: "Add",
    checkAll: "Select All",
    fileTip: {
      folderEmpty: "No folder. Please create a new one.",
      fileEmpty: "No files. Please upload.",
    },
    fileList: "File List",
    rootFolderInfo: "Root Directory Info Configuration",
    createRootFolder: {
      title: "New Root Directory Folder",
      tooltip:
        "After the root directory folder is created, you can create subfolders in it to manage files and configure the access permissions to the root directory folder.",
      name: {
        name: "Root Directory Folder",
        required: "Please enter the root folder name.",
      },
      dataConnection: {
        name: "Bound File Connector",
        required: "Please select a file connector.",
      },
      add: "New File Class Connection",
      none: "No permission to new file class connection",
    },
    connectionErr:
      "The current file connector is disconnected and cannot be accessed. Please replace or modify the file connector.",
    fileIntroduce: {
      title1: "What is File Service?",
      p1: "The File Service is mainly used to store and manage various files, as well as to provide download services of the reserved files. You can create multi-layer folders and manage files in each layer folder.",
      title2: "What are the operations?",
      operation: {
        createFolder: "New Directory",
        uploadFile: "Upload File",
        downloadFile: "Download File",
      },
    },
    CreateConnectorItemDialog: {
      environment: {
        prod: "Production Environment",
        ppe: "PPE Environment",
        dev: "Development Environment",
      },
      MiniO: {
        name: "Connection Name",
        environment: "Environment Type",
        address: "MiniO Service Address",
        route: "File Storage Path",
        waring: {
          ConnectorName: "Enter the correct MiniO service",
          MiniOServerUrl: "Enter the correct MiniO service address",
          AccessKey: "Enter the correct Access Key",
          SecretKey: "Enter the correct Secret Key",
        },
      },
      EncooStorage: {
        name: "Connection Name",
        environment: "Environment Type",
        route: "File Storage Path",
        waring: {
          ConnectorName: "Enter the correct connection name",
        },
      },
      AzureBlob: {
        name: "Connection Name",
        environment: "Environment Type",
        CharacterString: "Connection String",
        route: "File Storage Path",
        waring: {
          ConnectorName: "Enter the correct connection name",
          ConnectorString: "Enter the correct connection string",
        },
      },
    },
  },
};
