import { createModel } from "nyax";
import { DepartmentUser } from "src/models/DepartmentUser";
import { ModelBase } from "src/store/ModelBase";

export const DepartmentUserDetailUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        ...super.initialState(),
        isEdit: false,
        user: null as DepartmentUser | null,
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setEdit: (value: boolean) => {
          this.state.isEdit = value;
        },
        setUser: (value: DepartmentUser) => {
          this.state.user = value;
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
