import { createModel, createSelector } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { DataPermissionEntityModel } from "src/store/models/entity/dataPermission/entity";
import { CoeMyWorkEntityModel } from "src/store/models/entity/coeMyWork/entity";
import { CoeMyWorkHelperModel } from "src/store/models/entity/coeMyWork/helper";

export const CoeMyWorkDetailUIModel = createModel(
  class extends ModelBase {
    private get _id() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.containerKey!;
    }
    public selectors() {
      return {
        coeMyWorkDetail: createSelector(
          () => this.getContainer(CoeMyWorkEntityModel).state.byId,
          () => this.getContainer(DataPermissionEntityModel).state.byId,
          (coeMyWorkById, permissionById) => {
            const coeMyWork = coeMyWorkById[this._id];
            return coeMyWork
              ? {
                  ...coeMyWork,
                  permissionValues:
                    permissionById[this._id]?.permissionValues ?? [],
                }
              : null;
          }
        ),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        requestDetail: async () => {
          await this.getContainer(CoeMyWorkHelperModel).actions.getById.dispatch({
            coeMyWorkId: this._id,
          });
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
