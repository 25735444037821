// eslint-disable-next-line import/no-anonymous-default-export
export default {
  dataConnection: {
    test: "Test",
    introduction:
      "Connection management is mainly used to create and manage various data connections and establish connections with various types of external data to facilitate subsequent data operations.",
    listFields: {
      name: "Connection Name",
      dataType: "Connection Type",
      databaseName: "Database Name",
      env: "Environment Type",
    },
    env: {
      productionEnvironmentType: "Production Environment",
      ppeEnvironmentType: "PPE Environment",
      developmentEnvironmentType: "Development Environment",
    },
    testSuccessful: "Tested successfully",
    restfulAPIConfig: "Configuration of API class connection template",
    tip: {
      name: "Please enter the correct connection name",
    },
    searchPlaceholder: {
      type: "Select a connection type",
    },
    associatedApp: "Associated Applications",
  },
};
