import { createModel } from "nyax";
import { MFAconfig, VirtualMFAInfo } from "src/models/securityAuth";
import { ModelBase } from "src/store/ModelBase";

export const VirtualMFAUIModel = createModel(
  class extends ModelBase {


    public initialState() {
      return {
        isShowBindingModal: false,
        isShowUnbindingModal: false,
        virtualMFAInfo: null as VirtualMFAInfo | null,
        MFAconfig: null as MFAconfig | null,
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setIsShowBindngModal: (value: boolean) => {
          this.state.isShowBindingModal = value;
        },
        setIsShowUnbindingModal: (value: boolean) => {
          this.state.isShowUnbindingModal = value;
        },
        setVirtualMFAinfo: (value: VirtualMFAInfo) => {
          this.state.virtualMFAInfo = value;
        },
        setMFAConfig: (value: MFAconfig) => {
          this.state.MFAconfig = value;
        }

      };
    }
    public effects() {
      return {
        getVirtualMFAInfo: async () => {
          const data = await this.dependencies.serviceClient.systemSettings.getVirtualMFAInfo();
          await this.actions.setVirtualMFAinfo.dispatch(data)
        },
        getVirtualConfig: async () => {
          const data = await this.dependencies.serviceClient.systemSettings.getMFAconfig();
          await this.actions.setMFAConfig.dispatch(data);
        },
        bindMFA: async (value: string) => {
          await this.dependencies.serviceClient.systemSettings.bindMFA(value);
          await this.actions.getVirtualMFAInfo.dispatch(null);
        },
        unbindMFA: async (value: string) => {
          await this.dependencies.serviceClient.systemSettings.unbindMFA(value);
          await this.actions.getVirtualMFAInfo.dispatch(null);
        }
      };
    }
    public selectors() {
      return {};
    }
  }
);
