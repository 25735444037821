import { first } from "lodash";
import { createModel, createSelector } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { PaymentOrderHelperModel } from "src/store/models/entity/payment/order/helper";
import { PaymentProductEntityModel } from "src/store/models/entity/payment/product/entity";
import { PaymentProductHelperModel } from "src/store/models/entity/payment/product/helper";

export const paymentProductUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        isInitialized: false,
        selectedProductId: "",
      };
    }
    public reducers() {
      return {
        setInitialized: (isInitialized: boolean) => {
          this.state.isInitialized = isInitialized;
        },
        setSelectedProductId: (selectedProductId: string) => {
          this.state.selectedProductId = selectedProductId;
        },
      };
    }
    public selectors() {
      return {
        products: () =>
          this.getContainer(PaymentProductEntityModel).getters.items,
        selectedProduct: createSelector(
          () => this.getContainer(PaymentProductEntityModel).getters.items,
          () => this.state.selectedProductId,
          (products, selectedProductId) => {
            return products.find((item) => item.id === selectedProductId);
          }
        ),
      };
    }
    public effects() {
      return {
        refreshProducts: async () => {
          return await this.getContainer(
            PaymentProductHelperModel
          ).actions.getAll.dispatch(null);
        },
        initial: async () => {
          if (!this.state.isInitialized) {
            const allProduct = await this.actions.refreshProducts.dispatch({});
            this.actions.setInitialized.dispatch(true);
            const firstId = first(
              allProduct.filter((item) => item.purchasable)
            )?.id;
            if (firstId) {
              this.actions.setSelectedProductId.dispatch(firstId);
            }
          }
        },
        createOrder: async (payload: {
          productId: string;
          quantity: number;
          renewAgainstOrderId?: string;
          couponCode?: string;
        }) => {
          return await this.getContainer(
            PaymentOrderHelperModel
          ).actions.create.dispatch({
            items: [
              {
                productId: payload.productId,
                quantity: payload.quantity,
              },
            ],
            renewAgainstOrderId: payload.renewAgainstOrderId,
            couponCode: payload.couponCode,
          });
        },
        cancelOrder: async (orderId: string) => {
          return await this.getContainer(
            PaymentOrderHelperModel
          ).actions.cancel.dispatch(orderId);
        },
        qrCode: async (orderId: string) => {
          return await this.dependencies.serviceClient.payment.PayUrl({
            orderId,
            payMethod: "wechatpay_native",
          });
        },
      };
    }
  }
);
