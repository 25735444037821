import { createModel } from "nyax";
import { UserManagerUserDetail } from "src/containers/userManager/models/UserManagerUserModel";
import { createItemsEntityModel } from "src/store/itemsEntity";

export const UserManagementEntityModel = createModel(
  class extends createItemsEntityModel<UserManagerUserDetail>(
    (item) => item.id
  ) {
    public selectors() {
      return {
        ...super.selectors(),
      };
    }
  }
);
