// eslint-disable-next-line import/no-anonymous-default-export
export default {
  userCenter: {
    userInfo: "Personal Information",
    settings: "Basic settings",
    orders: "User orders",
    name: "Name",
    phone: "Phone Number",
    email: "Email Address",
    wechat: "WeChat",
    binding: "Bind",
    replace: "Change",
    unbind: "Unbind",
    setDefaultTenant: "Set Default Tenant",
    setDefaultResource: "Set Default Resource Group",
    setDefaultTimeZone: "Set Default Timezone",
    setDefaultLanguage: "Set Default Language",
    updateName: "Modify Name",
    currentName: "Current Name",
    currentPhone: "Current Phone Number",
    currentEmail: "Current Email Address",
    placeholder: {
      inputName: "Name cannot be empty",
      inputBindingEmail: "Please enter the bound email address",
      inputEmailCode: "Please enter the verification code sent to your mailbox",
      inputOriginalPassword: "Please enter your original password",
      inputNewPassword:
        "Please enter your new password (Your password must be between 8-16 characters and contain at least three character categories among the following: uppercase characters, lowercase characters, numbers, and special characters)",
      inputNewPasswordAgain: "Please re-enter your new password",
      inputNewEmail: "Please enter your new email address",
      inputNewPhone: "Please enter your new phone number",
      inputPhoneCode: "Please enter the verification code sent to your phone",
      passwordNotMatch: "The passwords you entered do not match!",
      nameLimited: "Your name shall be less than 35 characters!",
    },
    passwordConditions:
      "Your password must be between 8-16 characters and contain at least 3 character categories among the following: uppercase characters, lowercase characters, numbers, and special characters",
    passwordConditionError: "Please enter your password in the correct format",
    unbindEmailTips:
      "Whether to unbind the {email} email address? After unbinding, you will not be able to log in and receive platform information through this {type}.",
    unbindPhoneTips:
      "Whether to unbind the {phoneNumber} phone number? After unbinding, you will not be able to log in and receive platform information through this {type}.",
    unbindWeChatTips:
      "Whether to unbind the {wechat} WeChat? After unbinding, you will not be able to log in and receive platform information through this {type}.",
    bindEmail: "Bind Email Address",
    updatePassword: "Modify Password",
    updatePhone: "Change Phone Number",
    updateEmail: "Change Email Address",
    bindWeChat: "Bind WeChat",
    bindWeChatTip:
      "After binding wechat, you can use wechat scan code to log in",
    getVerificationCode: "Get Verification Code",
    tenantName: "User Name",
    selectBuiltInAvatar: "Select Built-in Avatar",
    signOut: "Cancel an account",
  },
};
