import { createModel } from "nyax";
import { CoeMyWork, CoeMyWorkCreationData } from "src/models/coeMyWork";
import {
  EncooFileUploadCallbackInfo,
  EncooFileUploadState,
} from "src/models/file";
import { CoeMyWorkEntityModel } from "src/store/models/entity/coeMyWork/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const CoeMyWorkHelperModel = createModel(
  class extends createHelperModel<CoeMyWork>({
    setItems: (getContainer, items) =>
      getContainer(CoeMyWorkEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(CoeMyWorkEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(CoeMyWorkEntityModel).state.byId[id],
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(CoeMyWorkEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        getById: async (payload: { coeMyWorkId: string; force?: boolean }) => {
          const { coeMyWorkId, force = true } = payload;
          return await this._readById({
            id: coeMyWorkId,
            getByIdAction: async () => {
              const item =
                await this.dependencies.serviceClient.coeWork.getById(
                  coeMyWorkId
                );
              // await this.getContainer(
              //   DataPermissionHelperModel
              // ).actions.getAll.dispatch([
              //   { resourceType: item.resourceType, id: item.id },
              // ]);
              return item;
            },
            force,
          });
        },
        create: async (payload: { params: CoeMyWorkCreationData }) => {
          const { params } = payload;
          return await this._create({
            createAction: () =>
              this.dependencies.serviceClient.coeWork.create(params),
          });
        },
        update: async (params: {
          coeMyWork: Partial<CoeMyWork> & Pick<CoeMyWork, "id">;
        }) => {
          const { coeMyWork } = params;
          return await this._update({
            id: coeMyWork.id,
            updateAction: () =>
              this.dependencies.serviceClient.coeWork.update(coeMyWork),
          });
        },
        getPackages: async (params: {
          pageIndex: number;
          pageSize?: number;
        }) => {
          const { pageIndex, pageSize } = params;
          return await this.dependencies.serviceClient.coeWork.getPackages(
            pageIndex,
            pageSize
          );
        },
        dowload: async (params: {
          workItemId: string;
          attachmentId: string;
        }) => {
          const { workItemId, attachmentId } = params;
          return await this.dependencies.serviceClient.coeWork.dowloaduri(
            workItemId,
            attachmentId
          );
        },
        upload: async (params: {
          file: File;
          processCallback?: (info: EncooFileUploadCallbackInfo) => void;
        }) => {
          const { processCallback, file } = params;
          const { name, size } = file;
          const attachment =
            await this.dependencies.serviceClient.coeWork.uploadAttachment({
              name,
              size,
            });
          const { multiUploadUri } = attachment;

          const status = await this.dependencies.serviceClient.file.uploadUris(
            multiUploadUri.uris,
            params.file,
            processCallback
          );
          if (status.state === EncooFileUploadState.Completed) {
            return attachment;
          }
          return;
        },
      };
    }
  }
);
