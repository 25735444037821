import { createModel, createSelector } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { DataPermissionEntityModel } from "src/store/models/entity/dataPermission/entity";
import { KnowledgeEntityModel } from "src/store/models/entity/knowledge/entity";
import { KnowledgeHelperModel } from "src/store/models/entity/knowledge/helper";

export const KnowledgeDetailUIModel = createModel(
  class extends ModelBase {
    private get _id() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.containerKey!;
    }
    public selectors() {
      return {
        knowledgeDetail: createSelector(
          () => this.getContainer(KnowledgeEntityModel).state.byId,
          () => this.getContainer(DataPermissionEntityModel).state.byId,
          (knowledgeById, permissionById) => {
            const knowledge = knowledgeById[this._id];
            return knowledge
              ? {
                  ...knowledge,
                  permissionValues:
                    permissionById[this._id]?.permissionValues ?? [],
                }
              : null;
          }
        ),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        requestDetail: async () => {
          await this.getContainer(
            KnowledgeHelperModel
          ).actions.getById.dispatch({
            knowledgeId: this._id,
          });
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
