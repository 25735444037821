// eslint-disable-next-line import/no-anonymous-default-export
export default {
  job: {
    lastExecuteTime: "Last Execution Time",
    terminate: "Cancel",
    execute: "Execute",
    message: "Select the number of flow as 0",
    messagePriority:
      "The priority of the tasks whose status is pending can be adjusted in batch.",
    messageExecute:
      "The tasks whose status is success, failed, and canceled can be executed in batch.",
    messageTerminate:
      "The tasks whose status is pending, assigned, and running can be canceled in batch.",
    introduction:
      "The Execution history page displays the execution status of all flow tasks under the current resource group and supports viewing flow execution details and log details.",
    introductionExtra:
      "Keep the details of execution logs for one year, and screenshots and videos for three months.",
    logTip:
      "Log details are reserved for 1 year, and screenshots and videos are reserved for 3 months (starting from the creation time of this execution).",
    tips: "The package does not support displaying the execution progress. \nPlease use the studio 1.1.2110.* or above version to publish.",
    jobNumber: "Task Number",
    executableTarget: "Execution Target",
    targetName: "Target Name",
    view: "View Details",
    status: {
      queued: "Waiting",
      allocated: "Assigned",
      running: "Running",
      aborted: "Aborted",
      failed: "Failed",
      succeeded: "Succeeded",
      cancelled: "Canceled",
      cancelling: "Canceling...",
      pausing: "Pausing...",
      paused: "Paused",
      deleted: "Deleted",
    },
    statusTip: {
      queued:
        'If the task has no available robot resources, the task status is "waiting"',
      running:
        'Case1: If the task has available robot resources and is executing the process, the task status is "running";\nCase2: If the task still has a chance to retry (such as execution order 2 or 3), but the robot is occupied and waiting for the robot, the task state is "running".',
      paused:
        'If the user suspends the task operation of "running" on the console or robot end, the task status is "paused".',
      cancelled:
        'The final status of a process task.\nCase1: After the user cancels the task operation of "running / waiting / paused" at the console or robot, the task status is "cancelled";\nCase2: According to the set conflict handling strategy, when the robot is busy, the system automatically cancels the subsequent tasks sent to the robot, and the task status is "cancelled".',
      failed:
        "The final status of a process task.\nA task may include multiple retries (such as execution order 1, 2 and 3). The final status of the task is based on the execution result of the last retry.",
      succeeded:
        "The final status of a process task.\nA task may include multiple retries (such as execution order 1, 2 and 3). The final status of the task is based on the execution result of the last retry.",
    },
    executeOrder: "Task Priority",
    deployAssignment: "Flow Deployment Assignment",
    executionAssignment: "Flow Execution Assignment",
    adjustPriority: "Adjust Priority",
    priorityCount: "Priority Value",
    defaultCount: "Task priority defaults to 2000",
    updatePriorityCountTip:
      "The task priority ranges from 0 (lowest) to 5000 (highest). The higher the priority, the earlier to be executed.",
    priorityValidateTips: "Please enter a number between 0-5000",
    lastRobotName: "Latest Execution Robot",
    tipAllTask: "tasks",
    confirmDelete: "Are you sure you want to delete this job record?",
    confirmCancel: "Are you sure you want to cancel this task?",
    confirmCancelJobs:
      "Are you sure you want to cancel or terminate the selected {jobsNumber} tasks?",
    monitor: {
      title: "Execute progress",
      log: "Log",
    },
    startupType: {
      queue: "Job Queue",
      executeManually: "Manual Execution",
      trigger: "Task Schedule",
    },
    externalSystem: "External System",
    source: "Source",
    allStartMode: "All Start Modes",
    packageName: "Flow Package",
    packageVersion: "Flow Package Version",
    triggerName: "Task Schedule Name",
    vicodeApplication: "Vicode Application",
    ApiInterface: "API Interface",
  },
};
