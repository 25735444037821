import { createModel } from "nyax";
import { Workflow, WorkflowQuery } from "src/models/workflow";
import { WorkflowEntityModel } from "src/store/models/entity/workflow/entity";
import { WorkflowSideUIModel } from "src/store/models/ui/workflow/workflowSide";
import { createUITableWithPermissionModel } from "src/store/models/ui/_shared";

export const WorkflowTableUIModel = createModel(
  class extends createUITableWithPermissionModel<Workflow, WorkflowQuery>({
    setItems: (getContainer, items) =>
      getContainer(WorkflowEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(WorkflowEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(WorkflowEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      const superEffects = super.effects();
      return {
        ...superEffects,
        updateQuery: async (params: WorkflowQuery) => {
          await superEffects.updateQuery(params);
          await this.getContainer(
            WorkflowSideUIModel
          ).actions.setQuery.dispatch(this.state.query);
        },
        initial: async () => {
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query: WorkflowQuery
            ) =>
              this.dependencies.serviceClient.workflow.list(
                pageIndex,
                pageSize,
                query
              ),
          });
        },
      };
    }
  }
);
