import { createModel } from "nyax";
import {
  Application,
  ApplicationQuery,
  CreateApplication,
} from "src/models/application";
import { ApplicationEntityModel } from "src/store/models/entity/application/entity";
import { createUITableWithPermissionModel } from "src/store/models/ui/_shared";

export type ApplicationWithPermission = Application & {
  permissionValues: string[];
};

export const ApplicationTableUIModel = createModel(
  class extends createUITableWithPermissionModel<Application, ApplicationQuery>(
    {
      setItems: (getContainer, items) =>
        getContainer(ApplicationEntityModel).actions.setItems.dispatch(items),
      getItems: (getContainer) =>
        getContainer(ApplicationEntityModel).getters.items,
      getItem: (getContainer, id) =>
        getContainer(ApplicationEntityModel).state.byId[id],
      getItemId: (item) => item.id,
    }
  ) {
    public effects() {
      const superEffects = super.effects();
      return {
        ...superEffects,
        initial: async () => {
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query: ApplicationQuery
            ) =>
              this.dependencies.serviceClient.application.list(
                pageIndex,
                pageSize,
                query
              ),
          });
        },
        deleteApplication: async (applicationId: string) => {
          await this.dependencies.serviceClient.application.delete(
            applicationId
          );
          await this.actions.refresh.dispatch({});
        },
        createApplication: async (application: Partial<CreateApplication>) => {
          const createdApplication =
            await this.dependencies.serviceClient.application.create(
              application
            );
          await this.actions.refresh.dispatch({});
          return createdApplication;
        },
        updateApplication: async (
          application: Partial<Application> & { id: string }
        ) => {
          const updatedApplication =
            await this.dependencies.serviceClient.application.update(
              application
            );
          this.getContainer(ApplicationEntityModel).actions.setItems.dispatch([
            updatedApplication,
          ]);
        },
      };
    }
  }
);
