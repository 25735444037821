import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import {
  AddMessageQuery,
  DataQueue,
  DataQueueBasicData,
  DataQueueQuery,
} from "src/models/dataQueue";
import { EncooBatchResult, EncooListEntity } from "src/models/_shared";

export const QUEUE_SERVICE_NAME = "queue";

export class DataQueueServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, QUEUE_SERVICE_NAME);
  }

  async list(
    pageIndex: number,
    pageSize: number,
    query?: DataQueueQuery
  ): Promise<EncooListEntity<DataQueue>> {
    const req = this.buildRequestV2({
      url: `/v2/dataCenter/queues`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
        searchString: query?.name,
        orderBy: query?.orderBy,
        isDesc: query?.sortOrder === "descend",
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async create(payload: DataQueueBasicData): Promise<DataQueue> {
    const req = this.buildRequestV2({
      url: `/v2/dataCenter/queues`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  async createMessage(
    queueName: string,
    payload: AddMessageQuery
  ): Promise<DataQueue> {
    const { messageText, messagettl } = payload;
    const req = this.buildRequestV2({
      url: `/v2/dataCenter/queues/${queueName}/messages`,
      method: "POST",
      payload: {
        messageText,
      },
      query: {
        messagettl,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getMessages(queueName: string): Promise<DataQueue[]> {
    const req = this.buildRequestV2({
      url: `/v2/dataCenter/queues/${queueName}/messages?peekonly=${true}&numofmessages=${32}`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body.data;
  }
  async update(
    queueName: string,
    description: string | null,
    tags?: string[]
  ): Promise<DataQueue> {
    const req = this.buildRequestV2({
      url: `/v2/dataCenter/queues/${queueName}`,
      method: "PATCH",
      payload: {
        description,
        tags,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async delete(queueName: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/dataCenter/queues/${queueName}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }
  async batchDelete(queueIds: string[]): Promise<EncooBatchResult> {
    const req = this.buildRequestV2({
      url: `/v2/dataCenter/queues/batch/delete`,
      method: "POST",
      payload: {
        ids: queueIds,
      },
    });
    return await super.updateInternal<EncooBatchResult>(req);
  }
  //#endregion
}
