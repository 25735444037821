import { APP_MENUS } from "src/routes/menu";
import { appRouteDefinitions, AppRouteDefinitions } from "src/routes/router";
import { MenuExpandedDefinition } from "src/routes/_shared";

export function getBreadcrumbRouters(
  menuKey: string | undefined
): MenuExpandedDefinition[] {
  const routers = [];

  while (menuKey && APP_MENUS[menuKey]) {
    const menu: MenuExpandedDefinition = APP_MENUS[menuKey];
    if (!menu.hiddenInBreadcrumb) {
      routers.unshift(menu);
    }

    menuKey = menu.parentKey;
  }

  return routers;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getRouteOfList(menuKey: string | undefined) {
  while (menuKey && APP_MENUS[menuKey]) {
    menuKey = APP_MENUS[menuKey].parentKey;

    const route = appRouteDefinitions[menuKey as keyof AppRouteDefinitions];
    if (!route) {
      continue;
    }

    const defaultParams = route.defaultParams;
    if (Object.keys(defaultParams).length === 0) {
      return route;
    }
  }
}
