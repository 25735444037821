import { createModel } from "nyax";
import { createViCodeAppListBaseModal } from "src/store/models/entity/vicodeApp/_shared";
import { ViCodeAppQuery } from "src/store/models/ui/vicodeApp/_shared";

export const ViCodeAppListModel = createModel(
  class extends createViCodeAppListBaseModal() {
    public effects() {
      return {
        ...super.effects(),
        initial: async (payload: ViCodeAppQuery) => {
          this._initial({
            initialAction: (pageIndex: number, pageSize: number) =>
              this.dependencies.serviceClient.vicodeApp.list({
                ...this.state.query,
                offset: pageIndex * pageSize,
                limit: pageSize,
              }),
          });
        },
      };
    }
  }
);
