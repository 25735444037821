import customizedCustomer from "src/assets/customizedCustomer/en-US/customizedCustomer";
import aiManagement from "src/locales/en-US/aiManagement";
import apps from "src/locales/en-US/apps";
import asset from "src/locales/en-US/asset";
import auditlog from "src/locales/en-US/auditlog";
import calendars from "src/locales/en-US/calendars";
import coeDataAnalysis from "src/locales/en-US/coeDataAnalysis";
import coeMyWork from "src/locales/en-US/coeMyWork";
import coeToDoWork from "src/locales/en-US/coeToDoWork";
import coeWork from "src/locales/en-US/coeWork";
import common from "src/locales/en-US/common";
import company from "src/locales/en-US/company";
import conversation from "src/locales/en-US/conversation";
import dashboard from "src/locales/en-US/dashboard";
import dataConnection from "src/locales/en-US/dataConnection";
import dataPermission from "src/locales/en-US/dataPermission";
import dataQueue from "src/locales/en-US/dataQueue";
import dataTable from "src/locales/en-US/dataTable";
import device from "src/locales/en-US/device";
import file from "src/locales/en-US/file";
import job from "src/locales/en-US/job";
import jobQueue from "src/locales/en-US/jobQueue";
import lamoss from "src/locales/en-US/lamoss";
import layout from "src/locales/en-US/layout";
import license from "src/locales/en-US/license";
import ocr from "src/locales/en-US/ocr";
import organization from "src/locales/en-US/organization";
import packageData from "src/locales/en-US/package";
import payment from "src/locales/en-US/payment";
import personal from "src/locales/en-US/personal";
import queue from "src/locales/en-US/queue";
import resourceGroup from "src/locales/en-US/resourceGroup";
import robot from "src/locales/en-US/robot";
import role from "src/locales/en-US/role";
import runInstance from "src/locales/en-US/runInstance";
import systemSetting from "src/locales/en-US/systemSetting";
import trigger from "src/locales/en-US/trigger";
import understanding from "src/locales/en-US/understanding";
import user from "src/locales/en-US/user";
import userCenter from "src/locales/en-US/userCenter";
import userManager from "src/locales/en-US/userManager";
import virtualMFA from "src/locales/en-US/virtualMFA";
import workflow from "src/locales/en-US/workflow";
import workflowLayout from "src/locales/en-US/workflowLayout";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...payment,
  ...personal,
  ...coeWork,
  ...coeToDoWork,
  ...coeMyWork,
  ...coeDataAnalysis,
  ...calendars,
  ...dataTable,
  ...workflowLayout,
  ...common,
  ...layout,
  ...packageData,
  ...role,
  ...robot,
  ...workflow,
  ...job,
  ...trigger,
  ...understanding,
  ...resourceGroup,
  ...queue,
  ...jobQueue,
  ...user,
  ...auditlog,
  ...apps,
  ...license,
  ...dashboard,
  ...runInstance,
  ...asset,
  ...userCenter,
  ...company,
  ...customizedCustomer,
  ...file,
  ...dataTable,
  ...dataQueue,
  ...organization,
  ...dataPermission,
  ...dataConnection,
  ...systemSetting,
  ...virtualMFA,
  ...userManager,
  ...device,
  ...ocr,
  ...aiManagement,
  ...conversation,
  ...lamoss,
};
