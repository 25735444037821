import { ViCodeAppEntityModel } from "src/store/models/entity/vicodeApp/entity";
import { ViCodeAppFavoriteListModel } from "src/store/models/entity/vicodeApp/favoriteList";
import { ViCodeAppHelperModel } from "src/store/models/entity/vicodeApp/helper";
import { ViCodeAppListModel } from "src/store/models/entity/vicodeApp/list";
import { ViCodeAppLiveListModel } from "src/store/models/entity/vicodeApp/liveList";

export const encooViCodeAppModels = {
  entity: ViCodeAppEntityModel,
  helper: ViCodeAppHelperModel,
  list: ViCodeAppListModel,
  liveList: ViCodeAppLiveListModel,
  favoriteList: ViCodeAppFavoriteListModel,
};
