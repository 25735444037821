import {
  connectRouter,
  routerMiddleware as createRouterMiddleware,
} from "connected-react-router";
import { createNyax, GetContainer, Nyax } from "nyax";
import { applyMiddleware, compose, createStore, Store } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { Dependencies } from "src/dependencies";
import { DependenciesSymbol } from "src/dependencies/identifiers";
import { rootModels } from "src/store/models";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: <R>(o: R) => R;
  }
}

const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?? compose
    : compose;

export class ReduxService {
  public static injectable = [DependenciesSymbol];

  private readonly _nyax: Nyax;

  public get store(): Store {
    return this._nyax.store;
  }

  public get getContainer(): GetContainer {
    return this._nyax.getContainer;
  }

  public get registerModels(): Nyax["registerModels"] {
    return this._nyax.registerModels;
  }

  public get reload(): Nyax["reload"] {
    return this._nyax.reload;
  }

  constructor(dependencies: Dependencies) {
    this._nyax = createNyax({
      dependencies,
      createStore: ({ reducer, epic, middleware }) => {
        const routerReducer = connectRouter(dependencies.history);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const rootReducer = (state: any, action: any) => {
          let nextRootState = reducer(state, action);

          const nextRouterState = routerReducer(nextRootState.router, action);
          if (nextRouterState !== nextRootState.router) {
            nextRootState = { ...nextRootState, router: nextRouterState };
          }

          return nextRootState;
        };

        const routerMiddleware = createRouterMiddleware(dependencies.history);
        const epicMiddleware = createEpicMiddleware();

        const enhancer = composeEnhancers(
          applyMiddleware(routerMiddleware, middleware, epicMiddleware)
        );

        const store = createStore(rootReducer, enhancer);

        epicMiddleware.run(epic);

        return store;
      },
      onUnhandledEffectError: (error, promise) => {
        if (promise) {
          promise.catch(() => {
            // noop
          });
        }

        console.error("[Unhandled Effect Error]\n", error);
      },
      onUnhandledEpicError: (error, caught) => {
        console.error("[Unhandled Epic Error]\n", error);
        return caught;
      },
    });

    this._nyax.registerModels(rootModels);
  }
}
