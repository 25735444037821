/** @jsxImportSource @emotion/react */
import { Tooltip } from "antd";
import {
  ButtonProps as AntButtonProps,
  ButtonType,
} from "antd/lib/button/button";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import Button from "src/components/Button";
import { useFormatMessage, useGetContainer } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { QuotaUIModel } from "src/store/models/ui/quota/quota";

interface ButtonProps extends Omit<AntButtonProps, "type"> {
  className?: string;
  type?: ButtonType;
  iconName?: string;
}

export default React.memo(function RestrictionButton(props: ButtonProps) {
  const { type, children, ...otherProps } = props;

  const formatMessage = useFormatMessage();
  const getContainer = useGetContainer();
  const quota = getContainer(QuotaUIModel);
  const isOutOfQuota = useSelector(() => quota.state.isOutOfQuota);

  return (
    <Fragment>
      {isOutOfQuota ? (
        <Tooltip title={formatMessage(messageIds.common.quotaTip)}>
          <span>
            <Button type={type} disabled={isOutOfQuota} {...otherProps}>
              {children}
            </Button>
          </span>
        </Tooltip>
      ) : (
        <Button type={type} {...otherProps}>
          {children}
        </Button>
      )}
    </Fragment>
  );
});
