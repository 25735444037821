import { createModel } from "nyax";
import {
  WorkflowConfigs,
  WorkflowConfigsUpdateData,
} from "src/models/jobQueue";
import { ModelBase } from "src/store/ModelBase";

export const JobQueueMonitorDetailUIModel = createModel(
  class extends ModelBase {
    private get _id() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.containerKey!;
    }

    public initialState() {
      return {
        monitorConfig: null as WorkflowConfigs | null,
        loadingMonitorConfig: false as boolean,
      };
    }

    public reducers() {
      return {
        setMonitorConfig: async (value: WorkflowConfigs | null) => {
          this.state.monitorConfig = value;
        },
        setLoadingMonitorConfig: async (value: boolean) => {
          this.state.loadingMonitorConfig = value;
        },
      };
    }

    public effects() {
      return {
        getConfig: async (payload: { queueName: string }) => {
          const { queueName } = payload;

          await this.actions.setLoadingMonitorConfig.dispatch(true);
          try {
            const config =
              await this.dependencies.serviceClient.jobQueue.getConfig(
                queueName
              );
            await this.actions.setMonitorConfig.dispatch(config);
          } finally {
            await this.actions.setLoadingMonitorConfig.dispatch(false);
          }
        },
        updateConfig: async (payload: { item: WorkflowConfigsUpdateData }) => {
          const { item } = payload;

          const config =
            await this.dependencies.serviceClient.jobQueue.updateConfig(item);

          await this.actions.setMonitorConfig.dispatch(config);
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
