import moment from "moment";
import { createModel, createSelector } from "nyax";
import { CoeDataAnalysisRankingChartLists } from "src/models/coeDataAnalysis";

import { ModelBase } from "src/store/ModelBase";
import { CoeDataAnalysisHelperModel } from "src/store/models/entity/coeDataAnalysis/helper";

export type ChartType = "roi" | "timeSaved" | "costSaved";

export interface TopNFiltered {
  [K: string]: ChartInfo;
}

export interface ChartInfo {
  total: number;
  list: {
    labelString: string;
    packageName: string;
    count: number;
    departmentName: string;
  }[];
}

export const CoeDataAnalysisUIModel = createModel(
  class extends ModelBase {
    private get _helperContainer() {
      return this.getContainer(CoeDataAnalysisHelperModel);
    }

    public initialState() {
      return {
        topN: {
          roi: { total: 0, list: [] },
          timeSaved: { total: 0, list: [] },
          costSaved: { total: 0, list: [] },
        } as CoeDataAnalysisRankingChartLists,
        dateRange: {
          startDate: moment().subtract(366, "days").format("YYYY-MM-DD"),
          endDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
        },
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setTopN: (value: CoeDataAnalysisRankingChartLists) => {
          this.state.topN = value;
        },
        setTopNRoi: (value: CoeDataAnalysisRankingChartLists["roi"]) => {
          this.state.topN = { ...this.state.topN, roi: value };
        },
        setTopNTimeSaved: (
          value: CoeDataAnalysisRankingChartLists["timeSaved"]
        ) => {
          this.state.topN = { ...this.state.topN, timeSaved: value };
        },
        setTopNCostSaved: (
          value: CoeDataAnalysisRankingChartLists["costSaved"]
        ) => {
          this.state.topN = { ...this.state.topN, costSaved: value };
        },
      };
    }

    public selectors() {
      return {
        ...super.selectors(),
        topNFiltered: createSelector(
          () => this.state.topN,
          (topNSource) => {
            const newObj: TopNFiltered = {};
            Object.keys(topNSource).forEach((key) => {
              const _key = key as ChartType;
              newObj[_key] = {
                total:
                  _key === "timeSaved"
                    ? Math.round((topNSource[_key].total / 60) * 10) / 10
                    : Math.round(topNSource[_key].total * 100) / 100,
                list: topNSource[_key].list.map((val) => {
                  return {
                    labelString: `${val.packageName} (${val.departmentName})`,
                    packageName: val.packageName,
                    count:
                      _key === "timeSaved"
                        ? Math.round((val.value / 60) * 10) / 10
                        : Math.round(val.value * 100) / 100,
                    departmentName: val.departmentName,
                  };
                }),
              };
            });

            return newObj;
          }
        ),
      };
    }

    public effects() {
      return {
        initializeRankingChart: async (query: {
          start: string;
          end: string;
        }) => {
          const { start, end } = query;
          if (start && end) {
            const allTopNData =
              await this._helperContainer.actions.getAllTopNData.dispatch({
                start,
                end,
              });
            this.actions.setTopN.dispatch(allTopNData);
          }
          return;
        },
      };
    }
  }
);
