// eslint-disable-next-line import/no-anonymous-default-export
export default {
  dataQueue: {
    introduction:
      "Data queue is a service that can store a large number of messages. It is usually used in work scenarios that can be backlogged and processed asynchronously.",
    message: "Message",
    messageText: "Message text",
    messageRecord: "Message record",
    placeholderText: "Please enter the message text",
    default: "Default ( 7 days )",
    tip: "Tip: the message lifetime cannot be less than 1 second and not more than 7 days. ",
    status: "Status",
    insertionTime: "Insertion time",
    expirationTime: "Expiration time",
    survivalTime: "Survival time",
    messageTip: "Displays the first 32 messages in the queue header",
    queue: "Queue",
    name: "Queue name",
  },
};
