import { createModel } from "nyax";
import { RobotTaskScheduler } from "src/models/robot";
import { RobotTaskSchedulerEntityModel } from "src/store/models/entity/robotTaskScheduler/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const RobotTaskSchedulerHelperModel = createModel(
  class extends createHelperModel<RobotTaskScheduler>({
    setItems: (getContainer, items) =>
      getContainer(RobotTaskSchedulerEntityModel).actions.setItems.dispatch(
        items
      ),
    getItems: (getContainer) =>
      getContainer(RobotTaskSchedulerEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(RobotTaskSchedulerEntityModel).state.byId[id],
  }) {
    public effects() {
      return {
        ...super.effects(),

        readAll: async (payload: { robotId: string }) => {
          const { robotId } = payload;
          return await this._readByParentIds({
            parentIds: [""],
            getAllAction: () => {
              return this.dependencies.serviceClient.robot.getTaskSchedulers(
                robotId
              );
            },
            getEntityParentId: (entity: RobotTaskScheduler) => "",
            force: true,
          });
        },
      };
    }
  }
);
