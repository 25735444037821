// eslint-disable-next-line import/no-anonymous-default-export
export default {
  knowledge: {
    name: "知识库",
    description: "支持上传多种文件（PDF，WORD，图片），构建专属知识库。",
    searchName: "请输入知识库名称",
    file: "知识库文件",
    editor: {
      title: "新增知识库",
      uploadFile: {
        label: "上传文件",
        describe:
          "最多可上传20个文件， 单个文件需要小于10M，仅支持PDF、Word、txt和图片类型",
        required: "上传文件不能为空",
      },
      name: {
        label: "知识库名称",
        describe: "请根据应用场景为知识库输入一个合适的名称",
      },
      remark: {
        label: "备注",
        describe: "请尽量可能的描述此知识库的内容和用途",
      },
      columns: {
        uploadStatus: {
          label: "上传状态",
          options: {
            await: "等待上传",
            uploading: "正在上传",
            uploaded: "上传完成",
            failed: "上传失败",
          },
        },
      },
    },
    columns: {
      name: "知识库名称",
      count: "文件数量（个）",
      remark: "备注",
      lastUpdateTime: "最后更新时间",
      uploadFile: "上传文件 ",
      operation: "操作",
      fileName: "文件名",
      index: "序号",
    },
    buttons: {
      test: "测试对话",
      parse: "解析中",
      failed: "解析失败",
      view: "查看",
      delete: "删除",
    },
    messages: {
      parse: "知识库正在解析中，请稍后再试",
      failed: "知识库解析失敗",
      complete: "知识库解析完成",
    },
    error: {
      fileNumber: "最多允许20个文件",
      fileSize: "单个文件必须小于10M",
      fileType: "只允许PDF, Word, txt和图像类型",
      fileExist: "文件已存在",
      fileMax: "当前最多再上传其中{max}个文件",
    },
    analyze: "知识库正在解析中，请稍后再试。",
  },
  application: {
    name: "应用",
    description:
      "基于自定义的知识库，提供各类使用大模型的交互机器人，并支持集成到钉钉、企业微信等常用软件。",
    searchName: "请输入应用名称",
    fields: {
      createApplication: "新建应用",
      appName: {
        title: "应用名称",
        describe: "根据应用场景为该应用设置一个简单的名称。",
      },
      knowledge: {
        title: "知识库",
        describe:
          "根据应用场景为该应用选择对应的知识库，问答内容将基于此知识库。",
      },
      limit: {
        title: "用量限额",
        describe: "根据实际情况设置总限额。",
        validate: {
          lessThan: "用量限额必须为正",
        },
      },
      mark: "备注",
      describe: "描述",
      createTime: "创建时间",
      lastUpdateTime: "创建时间",
      operation: "操作",
      status: "状态",
      interactive: {
        title: "交互方式",
        describe:
          "将会生成特定二维码和网页链接，使用微信扫码或在浏览器打开后即可开始对话。",
        options: {
          web: "网页/移动端",
        },
      },
      webpageLink: "网页链接",
      validity: "有效期",
      QRCode: "二维码",
      steps: {
        basic: "基本配置",
        interaction: "设置交互方式",
      },
      radios: {
        enable: "启用",
        disabled: "禁用",
      },
      alerts: {
        useMethod: "使用微信扫码或者在浏览器打开链接即可开始对话",
      },
    },
    status: {
      isEnable: "已启用",
      isDisabled: "已禁用",
    },
    unit: {
      count: "次",
    },
    buttons: {
      switch: "开关",
      open: "开启",
      close: "关闭",
    },
  },
  manage: {
    name: "管理",
    description: "自配置合规审查与大模型选择，使其更符合您的实际业务。",
    tabs: {
      rule: {
        panelName: "合规审查",
        description:
          "设置您的过滤规则，为您的业务定制专属审核标准；所设置的规则会在接入应用时生效",
        custom: {
          label: "自定义规则",
          description: `您可以以自然语言或关键词的方式描述您要设定的审核规则，示例：
      不可输出以下内容：
      1.公司财务数据：员工工资信息，员工绩效奖金，公司收入支出资金流水等
      2.客户隐私信息：客户姓名，手机号码，身份证信息等
            `,
        },
      },
      model: {
        panelName: "大模型",
      },
    },
  },
};
