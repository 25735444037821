import _ from "lodash";
import { GetContainer } from "nyax";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useDialog } from "src/components/Dialog";
import { DependenciesContext, ThemeContext } from "src/contexts";
import { Dependencies } from "src/dependencies";
import messageIds from "src/locales/messageIds";
import { CompanyEdition } from "src/models/company";
import { PermissionShowType } from "src/models/dataPermission";
import { EnvironmentEdition } from "src/models/environment";
import ApplicationInsightsService from "src/services/ApplicationInsightsService";
import { CurrentPermissionModel } from "src/store/models/logic/currentPermission";
import { GlobalSelectCompanyUIModel } from "src/store/models/logic/globalSelectCompany";
import { GlobalSelectDepartmentModel } from "src/store/models/logic/globalSelectDepartment";
import { RouterModel } from "src/store/models/router";
import { EnvironmentModel } from "src/store/models/ui/environment/environment";
import { ResourceGroupModel } from "src/store/models/ui/resourceGroup";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";
import { Theme, ThemeSet } from "src/styles/theme";

export function useDependencies(): Dependencies {
  return useContext(DependenciesContext);
}

export function useGetContainer(): GetContainer {
  return useDependencies().redux.getContainer;
}

export function useThemeSet(): ThemeSet {
  return useDependencies().theme.themeSet;
}

export function useTheme(): Theme {
  return useContext(ThemeContext);
}

export function useApplicationInsights(): ApplicationInsightsService {
  return useDependencies().applicationInsights;
}

export function useResourceGroup(): { selectedResourceGroupId: string } {
  const resourceGroup = useGetContainer()(ResourceGroupModel);
  const selectedResourceGroupId = useSelector(
    () => resourceGroup.state.selectedResourceGroupId
  );

  return { selectedResourceGroupId };
}

export function useTenant(): { selectedTenantId: string | null } {
  const loginUser = useGetContainer()(LoginUserUIModel);
  const selectedTenantId = useSelector(
    () => loginUser.state.selectedUserTenantId
  );
  return { selectedTenantId };
}

export function useGlobalCompany(): { id: string | null } {
  const globalSelectCCompanyContainer = useGetContainer()(
    GlobalSelectCompanyUIModel
  );
  const selectedGlobalComponyId = useSelector(
    () => globalSelectCCompanyContainer.state.selectedGlobalComponyId
  );
  return {
    id: selectedGlobalComponyId,
  };
}

export function useGlobalDepartment(): { id: string | null } {
  const globalSelectDepartmentContainer = useGetContainer()(
    GlobalSelectDepartmentModel
  );
  const selectedDepartmentId = useSelector(
    () => globalSelectDepartmentContainer.state.selectedDepartmentId
  );
  return {
    id: selectedDepartmentId,
  };
}

export function usePermissionValue(checkedPermissions: string[]): boolean[] {
  const currentPermissionContainer = useGetContainer()(CurrentPermissionModel);
  const permissionValues = useSelector(
    () => currentPermissionContainer.getters.permissionValues
  );

  const hasPermissions = useMemo(
    () =>
      _.map(checkedPermissions, (pv) =>
        _.some(permissionValues, (val) => val === pv)
      ),
    [checkedPermissions, permissionValues]
  );

  return hasPermissions;
}

export function usePermissionTypeValue(
  permissions: string[]
): PermissionShowType[] {
  const currentPermissionContainer = useGetContainer()(CurrentPermissionModel);
  const permissionShowTypeValues = useSelector(
    () => currentPermissionContainer.getters.permissionShowTypeValues
  );

  const hasPermissionType = useMemo(
    () => _.map(permissions, (pv) => permissionShowTypeValues[pv]),
    [permissionShowTypeValues, permissions]
  );

  return hasPermissionType;
}

export function useEnvironmentEdition(): EnvironmentEdition | null {
  const environmentContainer = useGetContainer()(EnvironmentModel);
  const environmentEdition = useSelector(
    () => environmentContainer.state.environmentEdition
  );
  return environmentEdition;
}

export function useUserEdition(): CompanyEdition | null {
  const globalSelectCompanyUI = useGetContainer()(GlobalSelectCompanyUIModel);
  const selectedGlobalCompony = useSelector(
    () => globalSelectCompanyUI.getters.selectedGlobalCompony
  );
  return selectedGlobalCompony?.edition ?? null;
}

export function useUserIsFree(): boolean {
  const globalSelectCompanyUI = useGetContainer()(GlobalSelectCompanyUIModel);
  const selectedGlobalCompony = useSelector(
    () => globalSelectCompanyUI.getters.selectedGlobalCompony
  );
  return selectedGlobalCompony?.isFree ?? false;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useFormatMessage() {
  const intl = useIntl();
  return useCallback(
    (messageId: string, values?: Record<string, string | number>) => {
      return intl.formatMessage(
        {
          id: messageId,
          defaultMessage: messageId,
        },
        values
      );
    },
    [intl]
  );
}

export function useInterval(callback: () => void, delay: number | null): void {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay === null) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);
    return () => clearInterval(id);
  }, [delay]);
}

// 待移除--- 新版本的编辑模式统一用container下的hooks
export function useRouterConfirm(isEdit: boolean): {
  onFormChange: () => void;
} {
  const getContainer = useGetContainer();
  const formatMessage = useFormatMessage();
  const router = getContainer(RouterModel);

  const onFormChange = useCallback(() => {
    router.actions.setNeedConfirm.dispatch(true);
  }, [router.actions.setNeedConfirm]);

  useEffect(() => {
    router.actions.setEnableConfirm.dispatch(isEdit);
  }, [isEdit, router.actions.setEnableConfirm]);

  useEffect(() => {
    const beforeUpload = (event: BeforeUnloadEvent) => {
      if (router.state.needConfirm) {
        event.preventDefault();
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", beforeUpload);

    return () => {
      window.removeEventListener("beforeunload", beforeUpload);
    };
  }, [router]);

  useEffect(() => {
    return () => {
      router.actions.setEnableConfirm.dispatch(false);
    };
  }, [router.actions.setEnableConfirm]);

  const isConfirmDialogVisible = useSelector(
    () => router.state.isConfirmDialogVisible
  );
  const Dialog = useDialog();

  useEffect(() => {
    if (isConfirmDialogVisible) {
      Dialog({
        title: formatMessage(messageIds.leaveDialog.title),
        type: "info",
        content: formatMessage(messageIds.leaveDialog.message),
        onOk: async () => {
          router.actions.forceNavigate.dispatch({});
        },
        onCancel: async () => {
          router.actions.setConfirmDialogVisible.dispatch(false);
        },
      });
    }
  }, [
    Dialog,
    formatMessage,
    isConfirmDialogVisible,
    router.actions.forceNavigate,
    router.actions.setConfirmDialogVisible,
  ]);

  return {
    onFormChange,
  };
}
