import { createModel } from "nyax";
import { Robot } from "src/models/robot";
import { ModelBase } from "src/store/ModelBase";
import { QueueHelperModel } from "src/store/models/entity/queue/helper";
import { WorkflowHelperModel } from "src/store/models/entity/workflow/helper";

export const WorkflowDetailModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        robotListForWorkflow: [] as Array<Robot>,
      };
    }

    public reducers() {
      return {
        setRobotListForWorkflow: (value: Array<Robot>) => {
          this.state.robotListForWorkflow = value;
        },
      };
    }

    public effects() {
      return {
        ...super.effects(),
        requestWorkflowRobots: async (payload: { workflowId: string }) => {
          const { workflowId } = payload;
          const robotList = await this.getContainer(
            WorkflowHelperModel
          ).actions.getRobots.dispatch({
            workflowId,
          });

          await this.actions.setRobotListForWorkflow.dispatch(robotList);
        },

        requestWorkflowQueueInfo: async (payload: { queueId: string }) => {
          const { queueId } = payload;
          await this.getContainer(QueueHelperModel).actions.getById.dispatch({
            queueId,
          });
        },
      };
    }
  }
);
