import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { FeatureData } from "src/models/feature";

export const FEATURE_PERMISSION_NAME = "featurePermission";

export class FeatureServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, FEATURE_PERMISSION_NAME);
  }

  async getAll(): Promise<FeatureData> {
    const req = this.buildRequest({
      url: `/v2/features`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
}
