import { createModel } from "nyax";
import { Department } from "src/models/department";
import { ModelBase } from "src/store/ModelBase";
import { DepartmentHelperModel } from "src/store/models/entity/department/helper";

export type DepartmentDetailViewMode = "view" | "edit";

export const DepartmentDetailModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        appId: this.containerKey!,
        departmentDetailViewMode: "view" as DepartmentDetailViewMode,
        selectedDepartment: null as Department | null,
      };
    }

    public reducers() {
      return {
        setDepartmentDetailViewMode: (value: DepartmentDetailViewMode) => {
          this.state.departmentDetailViewMode = value;
        },
        setSelectedDepartment: (value: Department | null) => {
          this.state.selectedDepartment = value;
        },
      };
    }

    public effects() {
      return {
        requestSelectedDepartment: async (id: string) => {
          const value = await this.getContainer(
            DepartmentHelperModel
          ).actions.getById.dispatch(id);
          this.actions.setSelectedDepartment.dispatch(value);
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
