import { createModel, createSelector } from "nyax";
import { LicenseBinding, LicenseBindingQueryData } from "src/models/license";
import { LicenseBindingsEntityModel } from "src/store/models/entity/licenseBindings/entity";
import { createListModel } from "src/store/models/entity/_shared";
import { timeValidOrNot } from "src/utils/date";

export const LicenseBindingListModel = createModel(
  class extends createListModel<LicenseBinding>({
    setItems: (getContainer, items) =>
      getContainer(LicenseBindingsEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(LicenseBindingsEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(LicenseBindingsEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(LicenseBindingsEntityModel),
        validateDataSource: createSelector(
          (): LicenseBinding[] => this.getters.dataSource,
          (validate): LicenseBinding[] => {
            return validate.filter((item) => timeValidOrNot(item.validTo));
          }
        ),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        initialIterator: async (payload: LicenseBindingQueryData) => {
          const { boundToId, productName, sku, available } = payload;
          this._initialIterator({
            initialAction: () =>
              this.dependencies.serviceClient.licenseBindings.listLicenseBindingsV2(
                { boundToId, productName, sku, available }
              ),
            force: true,
          });
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
