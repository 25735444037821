
import { TriggerDetailModel } from "src/store/models/ui/trigger/triggerDetail";
import { TriggerLogTableUIModel } from "src/store/models/ui/trigger/triggerLogTable";
import { WorkflowTriggerUIModel } from "src/store/models/ui/trigger/triggerTable";

export const encooTriggerUIModels = {
  triggerDeital: TriggerDetailModel,
  triggerTable: WorkflowTriggerUIModel,
  triggerLogTable: TriggerLogTableUIModel,
};
