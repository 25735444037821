import { createModel } from "nyax";
import { UserManagerUserDetail } from "src/containers/userManager/models/UserManagerUserModel";
import { UserManagementEntityModel } from "src/store/models/entity/userManagement/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const UserManagementHelperModel = createModel(
  class extends createHelperModel<UserManagerUserDetail>({
    setItems: (getContainer, items) =>
      getContainer(UserManagementEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(UserManagementEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(UserManagementEntityModel).state.byId[id],
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(UserManagementEntityModel),
      };
    }
    public effects() {
      return {
        ...super.effects(),
        getPermissionTable: async () => {
          return await this.dependencies.serviceClient.customPermissionRoleServiceClient.getPermissionTable();
        },
        create: async (payload: {
          name: string;
          email: string;
          departmentId: string;
          roleIds: Array<string>;
        }) => {
          const { name, email, departmentId, roleIds } = payload;
          return await this._create({
            createAction: () =>
              this.dependencies.serviceClient.userManagerServiceClient.inviteUser(
                name,
                email,
                departmentId,
                roleIds
              ),
          });
        },
        reInvite: async (payload: { companyUserId: string }) => {
          const { companyUserId } = payload;
          return await this.dependencies.serviceClient.userManagerServiceClient.reInviteUser(
            companyUserId
          );
        },
        update: async (data: Partial<UserManagerUserDetail>) => {
          return this._update({
            id: data.id ?? "",
            updateAction: () => {
              return this.dependencies.serviceClient.userManagerServiceClient.updataUserInfo(
                data
              );
            },
          });
        },
        delete: async (id: string) => {
          return await this._delete({
            id,
            deleteAction: () =>
              this.dependencies.serviceClient.userManagerServiceClient.deleteUser(
                id
              ),
          });
        },
      };
    }
  }
);
