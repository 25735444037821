// eslint-disable-next-line import/no-anonymous-default-export
export default {
  validate: {
    phone: "请正确填写手机号",
    fax: "请正确填写传真号码",
    email: "请正确填写邮箱",
    notEmpty: "填写项不能为空",
    image: "填写项不能为空",
    video: "填写项不能为空",
    guid: "填写项不是Guid",
    illegalChar: "不能包含非法字符",
    stringLength: "{name}须在{min}-{max}字符之间",
  },
  common: {
    buy: "购买",
    null: "空",
    or: "或",
    calendar: "日历",
    preview: "预览",
    baseOn: "按照",
    account: "账号",
    console: "控制台",
    help: "帮助",
    noData: "无数据",
    required: "填写项不能为空",
    frequency: "请输入失败最大尝试次数",
    priority: "请输入任务优先级",
    goHome: "返回主页",
    emailValidation: "填写正确的邮件地址",
    nameValidation: "名称不能包含特殊字符：/\\|:*?><|",
    all: "全部",
    selectAll: "全选",
    robot: "机器人",
    initiator: "发起人",
    today: "今天",
    currentMonth: "当月",
    editor: "编辑器",
    copy: "复制",
    saveImage: "保存图片",
    placeholder: "请输入",
    alertTitle: "提示",
    updateTime: "更新时间",
    updater: "更新人",
    unBind: "解绑",
    bound: "已绑定",
    nobound: "未绑定",
    unBound: "未解绑",
    packageName: "流程包名称",
    create: "新建",
    multiControl: "批量操作",
    tag: "标签",
    delete: "删除",
    remove: "移除",
    rename: "重命名",
    operation: "操作",
    upload: "上传",
    resourceMove: "资源组迁移",
    processDeployment: "流程部署",
    status: "状态",
    note: "备注",
    view: "查看",
    log: "日志",
    loading: "加载中...",
    loadNext: "加载下一页",
    phone: "电话",
    download: "下载",
    upgrade: "升级",
    cancel: "取消",
    edit: "编辑",
    batchEdit: "批量编辑",
    complete: "完成",
    type: "类型",
    createTime: "创建时间",
    description: "备注",
    notice: "通知",
    addEmail: "新增邮箱",
    creationTime: "创建时间",
    createdBy: "创建人",
    close: "关闭",
    refresh: "刷新",
    confirm: "确定",
    save: "保存",
    pleaseInput: "请输入",
    pleaseSelect: "请选择",
    resume: "继续",
    pause: "暂停",
    none: "无",
    robotName: "机器人名称",
    deleteConfirmation: "确定删除",
    contact: "联系{attrType}",
    name: "名称",
    baseConfig: "基本配置",
    bindRobot: "关联机器人",
    add: "新增",
    more: "更多",
    step: "步骤",
    nextStep: "下一步",
    preStep: "上一步",
    createSuccess: "添加成功",
    createFailed: "添加失败",
    uploadSuccess: "上传成功",
    config: " 配置信息",
    bindWorkflow: "关联流程部署",
    empty: "无",
    home: "首页",
    yes: "是",
    no: "否",
    number: "序号",
    uploadFile: "上传文件",
    update: "修改",
    operateSuccess: "操作成功",
    deleteSuccess: "删除成功",
    user: "用户",
    attachment: "附件",
    permission: "数据权限配置",
    infoExecute: "确定批量执行所选流程部署？",
    infoDelete: "确定批量删除{number}个{name}？",
    infoAssign: "确定批量为选中的{number}个机器人分配许可证？",
    infoRemove: "确定批量移除选中的{number}个机器人许可证？",
    infoExecuteTwo: "建议执行前先确认流程部署配置是否正确。",
    includeSubDepartment: "包含子部门数据",
    goToUpgrade: "前往升级",
    upgradeTip: "请升级至个人专业版或企业版后使用",
    quota: "额度",
    quotaTip: "额度已用完",
    info: {
      createEntity: "新建{entityTypeName}",
      editEntity: "修改{entityTypeName}",
      createSuccessfully: "创建 {entityTypeName} {name} 成功",
      createFailed: "创建 {entityTypeName} {name} 失败",
      removeConfirmation: "确定移除 {entityTypeName} {name} ?",
      removeFailed: "移除 {entityTypeName} {name} 失败",
      removeSuccessfully: "移除 {entityTypeName} {name} 成功",
      executeConfirmation: "确定执行 {entityTypeName} {name} ?",
      copyConfirmation: "确定复制 {entityTypeName} {name} ?",
      deletionConfirmation: "确定删除 {entityTypeName} [ {name} ] ?",
      deletionFailed: "删除 {entityTypeName} {name} 失败",
      deleteSuccessfully: "删除 {entityTypeName} {name} 成功",
      executeSuccessfully: "执行 {entityTypeName} {name} 成功",
      copySuccessfully: "复制 {entityTypeName} {name} 成功",
      updateSuccessfully: "更新 {entityTypeName} {name} 成功",
      upgradeSuccessfully: "升级 {entityTypeName} {name} 成功",
      updateFailed: "更新 {entityTypeName} {name} 失败",
      bindSuccessfully: "{name} 绑定 {entityTypeName}  成功",
      batchBindSuccessfully: "绑定成功",
      unbindSuccessfully: "{name} 解绑 {entityTypeName}  成功",
      batchUnbindSuccessfully: "解绑成功",
      bindFailed: "{name} 绑定 {entityTypeName}  失败",
      unbindFailed: "{name} 解绑 {entityTypeName}  失败",
    },
    updateSuccessfully: "修改成功",
    updateFailed: "修改失败",
    copySuccess: "复制成功",
    copyFailed: "复制失败",
    pleaseSelectTime: "请选择时间",
    startTime: "开始时间",
    endTime: "结束时间",
    baseInfo: "基本信息",
    baseSettings: "基本设置",
    videoRecording: "视频录制",
    pipSettings: "独立桌面",
    workHours: "工作时间",

    executeResult: "执行结果",
    versionInfo: "版本信息",
    prePage: "上一页",
    nextPage: "下一页",
    clickHere: "点击此处",
    time: {
      month: " 月",
      week: " 周",
      day: " 天",
      hour: " 小时",
      minute: " 分钟",
      second: " 秒",
      months: " 月",
      weeks: " 周",
      days: " 天",
      hours: " 小时",
      minutes: " 分钟",
      seconds: " 秒",
    },
    versionNumber: "版本号",
    consoleVersionNumber: "版本",
    lastVersionNumber: "最新版本号",
    uploadComponent: {
      localUpload: "本地上传",
      supportExtensions: "支持扩展名",
      import: "导入",
    },
    pcs: " 个",
    tipAll: "共批量处理 ",
    tipSuccess: "其中成功  ",
    tipFail: "失败  ",
    tipMessage: "失败信息如下：",
    operateOk: "确认",
    goBack: "返回",
    premission: "权限",
    otherPermission: "其他权限",
    append: "添加",
    title: "标题",
    content: "正文",
    search: "搜索",
    searchKeyPlaceholder: "请输入搜索关键字",
    to: "至",
    totalPage: "共 {total} 条",

    at: "在",
  },
  app: {
    name: "云扩超自动化平台",
  },
  environment: {
    uniqueCode: "机器码",
  },
  week: {
    MON: "星期一",
    TUE: "星期二",
    WED: "星期三",
    THU: "星期四",
    FRI: "星期五",
    SAT: "星期六",
    SUN: "星期日",
  },
  cron: {
    atCurrent: "在当前{unit}的第",
    every: "每",
    atTime: "第",
    minuteExecute: "分触发执行",
    everyhourExecuteOnce: "小时执行一次",
    everyDay: "每天的",
    execute: "执行",
    everyMonthExecuteOnce: "月执行一次",
    month: "个月",
    day: "日",
    hour: "时",
    enterCron: "请输入Cron表达式",
    clickToGenerateCron: "点击在线生成表达式",
    byDay: {
      at: "在当天",
    },
  },
  errors: {
    noPermission: {
      title: "很遗憾",
      description: "由于您还没开通权限，无法进行操作",
    },
    notFound: {
      title: "很遗憾",
      description: "抱歉，你访问的页面不存在",
      button: "返回首页",
      request: "请求资源不存在",
    },
    incorrectDepartment: {
      description: "您之前所处的部门因某些原因已不存在。",
    },
    disabled: {
      title: "很遗憾",
      description: "您已被当前租户停用，请联系管理员启用后访问",
    },
    login: {
      ssoErrorTip: "登录出现未知错误：",
      ssoErrorFixTip: "请按照以下方案排查：",
      ssoErrorTip1: "1. 请排查当前客户端时间与服务器时间是否一致;",
      ssoErrorTip2: "2. 请尝试点击“返回主页”后重新登录;",
      ssoErrorReasonMessage:
        "本页面地址为中转临时Url，请不要请不要保存/收藏本页地址，或者复制本页面地址给其他人使用!",
    },
  },
  upgrade: {
    title: "升级提示",
    description: "我们已于近期对控制台进行升级, 主要包括:",
    tip1: "对部分企业级功能进行升级完善",
    tip2: "修复了win7/winserver 2008 环境下机器人无法连接问题（请下载最新的Robot安装包进行修复）",
  },
  userEdition: {
    community: "个人免费版",
    communityMajor: "个人专业版",
    enterprise: "企业版",
  },
  leaveDialog: {
    title: "更改未保存",
    message:
      "您对本页所做的更改未保存，如果您离开本页，这些更改将会丢失。您确认离开本页吗？",
  },
  yes: "是",
  no: "否",
};
