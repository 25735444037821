import { createModel, GetContainer } from "nyax";
import { AppType, AppV2 } from "src/models/appV2";
import { MyAppListModel } from "src/store/models/entity/apps/myApplist";
import { createViCodeAppPaginationBaseModal } from "src/store/models/entity/vicodeApp/_shared";
import { ViCodeAppLiveListModel } from "src/store/models/entity/vicodeApp/liveList";
import { ViCodeAppWithPermission } from "src/store/models/ui/vicodeApp/_shared";

export type ActiveAppTab = "AppGroup" | "App";

export const UIViCodeAppLiveAppModel = createModel(
  class extends createViCodeAppPaginationBaseModal<
    ViCodeAppWithPermission,
    AppV2
  >({
    getList1Pagination: (getContainer: GetContainer) =>
      getContainer(ViCodeAppLiveListModel).getters.pagination,
    getList2Pagination: (getContainer: GetContainer) =>
      getContainer(MyAppListModel).getters.pagination,

    getDataSource1: (getContainer: GetContainer) =>
      getContainer(ViCodeAppLiveListModel).getters.dataSourceWithPermission,
    getDataSource2: (getContainer: GetContainer) =>
      getContainer(MyAppListModel).getters.dataSourceWithPermission,

    setPageSizeByList2: async (
      getContainer: GetContainer,
      pageSize: number
    ) => {
      await getContainer(MyAppListModel).actions.setPageSize.dispatch(pageSize);
    },
  }) {
    private get _myAppListContainer() {
      return this.getContainer(MyAppListModel);
    }

    private get _vicodeAppLiveListContainer() {
      return this.getContainer(ViCodeAppLiveListModel);
    }

    public initialState() {
      return {
        ...super.initialState(),
        isList: false,
        activeTab: "App" as ActiveAppTab,
        isViCodeAppTab: false,
      };
    }

    public reducers() {
      return {
        ...super.reducers(),

        swithIsList: () => {
          this.state.isList = !this.state.isList;
        },
        setActiveTab: (value: ActiveAppTab) => {
          this.state.activeTab = value;
        },
        setIsViCodeAppTab: (value: boolean) => {
          this.state.isViCodeAppTab = value;
        },
      };
    }

    public effects() {
      return {
        ...super.effects(),

        initialize: async (payload: { appType: AppType }) => {
          const { appType } = payload;

          if (this.state.isViCodeAppTab && appType === "App") {
            await this._vicodeAppLiveListContainer.actions.initial.dispatch({});
          } else {
            await this._myAppListContainer.actions.initial.dispatch({
              appStatus: "Enabled",
              appType,
            });
          }
        },
      };
    }
  }
);
