// eslint-disable-next-line import/no-anonymous-default-export
export default {
  validate: {
    phone: "Please fill in your mobile phone number correctly.",
    fax: "Please fill in your fax number correctly.",
    email: "Please fill in your email address correctly.",
    notEmpty: "Entries cannot be empty.",
    image: "Entries cannot be empty.",
    video: "Entries cannot be empty.",
    guid: "Entries are not GUIDs.",
    illegalChar: "Illegal characters are not allowed.",
    stringLength: "{name} must be between {min}-{max} characters.",
  },
  common: {
    buy: "Buy",
    null: "Null",
    or: "or",
    calendar: "Calendar",
    preview: "Preview",
    baseOn: "Base on",
    account: "Account",
    console: "Console",
    help: "Help",
    noData: "No data.",
    required: "Entries cannot be empty.",
    frequency: "Please enter the maximum number of failed attempts",
    priority: "Please enter task priority",
    goHome: "Home",
    emailValidation: "Please enter the correct email address.",
    nameValidation: "The name cannot contain special characters: /\\|:*?><|",
    all: "All",
    selectAll: "Select All",
    robot: "Robot",
    initiator: "Initiator",
    today: "Today",
    currentMonth: "Current Month",
    editor: "Studio",
    copy: "Copy",
    saveImage: "Save Image",
    placeholder: "Please enter",
    alertTitle: "Tips",
    updateTime: "Update Time",
    updater: "Renewer",
    unBind: "Unbind",
    bound: "Bound",
    nobound: "Unbound",
    unBound: "Not Unbind",
    packageName: "Flow Package Name",
    create: "New",
    multiControl: "Batch Operation",
    tag: "Tag",
    delete: "Delete",
    remove: "Remove",
    rename: "Rename",
    operation: "Operation",
    upload: "Upload",
    resourceMove: "Resource Group Migration",
    processDeployment: "Flow Deployment",
    status: "Status",
    note: "Remarks",
    view: "View",
    log: "Log",
    loading: "Loading",
    loadNext: "Load the Next Page",
    phone: "Phone",
    download: "Download",
    upgrade: "Upgrade",
    cancel: "Cancel",
    edit: "Edit",
    batchEdit: "Batch Edit",
    complete: "Completed",
    type: "Type",
    createTime: "Creation Time",
    resume: "Resume",
    pause: "Pause",
    description: "Remarks",
    notice: "Notification on Execution Failure",
    addEmail: "Add Mailbox",
    creationTime: "Creation Time",
    createdBy: "Founder",
    close: "Close",
    refresh: "Refresh",
    confirm: "OK",
    save: "Save",
    pleaseInput: "Please enter",
    pleaseSelect: "Please select",
    none: "none",
    robotName: "Robot Name",
    deleteConfirmation: "Are you sure you want to delete?",
    contact: "Contact {attrType}",
    name: "Name",
    baseConfig: "Basic Config",
    bindRobot: "Associated Robots",
    add: "New",
    more: "More",
    step: "Step",
    nextStep: "Next",
    preStep: "Back",
    createSuccess: "Added.",
    createFailed: "Adding failed",
    uploadSuccess: "Uploaded.",
    config: " Config Info",
    bindWorkflow: "Associated Flow Deployment",
    empty: "N/A",
    home: "Home",
    yes: "Yes",
    no: "No",
    number: "No.",
    uploadFile: "Upload File",
    update: "Change",
    operateSuccess: "Operation succeeded.",
    deleteSuccess: "Deletion succeeded",
    user: "User",
    attachment: "Attachment",
    permission: "Data Permission Configuration",
    infoExecute: "Are you sure to batch execute the selected workflows?",
    infoDelete: "Are you sure to batch delete the {number} {name}?",
    infoAssign:
      "Are you sure to batch assign licenses to the selected {number} robots?",
    infoRemove:
      "Are you sure to batch remove licenses to the selected {number} robots?",
    infoExecuteTwo:
      "It is recommended to confirm that the flow deployment configuration is correct before execution.",
    includeSubDepartment: "Include Sub Department's Data",
    goToUpgrade: "Go To Upgrade",
    upgradeTip:
      "Please upgrade to Personal Professional Edition or Enterprise Edition before use",
    quota: "Quota",
    quotaTip: "The quota has been used up",
    info: {
      createEntity: "Create {entityTypeName}",
      editEntity: "Change {entityTypeName}",
      createSuccessfully: "{entityTypeName} {name} created.",
      createFailed: "Creating {entityTypeName} {name} failed.",
      removeConfirmation:
        "Are you sure you want to remove {entityTypeName} {name}?",
      removeFailed: "Removing {entityTypeName} {name} failed.",
      removeSuccessfully: "{entityTypeName} {name} removed.",
      executeConfirmation:
        "Are you sure you want to execute {entityTypeName} {name}?",
      copyConfirmation:
        "Are you sure you want to copy {entityTypeName} {name}?",
      deletionConfirmation:
        "Are you sure you want to delete {entityTypeName} [ {name} ]?",
      deletionFailed: "Deleting {entityTypeName} {name} failed.",
      deleteSuccessfully: "{entityTypeName} {name} deleted.",
      executeSuccessfully: "{entityTypeName} {name} executed.",
      copySuccessfully: "{entityTypeName} {name} copied.",
      updateSuccessfully: "{entityTypeName} {name} updated.",
      upgradeSuccessfully: "{entityTypeName} {name} upgraded.",
      updateFailed: "Updating {entityTypeName} {name} failed.",
      bindSuccessfully: "Binding {name} to {entityTypeName} succeeded.",
      batchBindSuccessfully: "Binding succeeded",
      unbindSuccessfully: "Unbinding {name} from {entityTypeName} succeeded.",
      batchUnbindSuccessfully: "Unbinding succeeded",
      bindFailed: "Binding {name} to {entityTypeName} failed.",
      unbindFailed: "Unbinding {name} from {entityTypeName} failed.",
      to: "To",
    },
    updateSuccessfully: "Changed.",
    updateFailed: "Change failed.",
    copySuccess: "Copied.",
    copyFailed: "Copy Failed.",
    pleaseSelectTime: "Please select a time.",
    startTime: "Start Time",
    endTime: "End Time",
    baseInfo: "Basic Info",
    baseSettings: "Basic settings",
    videoRecording: "video recording",
    pipSettings: "Standalone desktop",
    workHours: "working hours",

    executeResult: "Execution Result",
    versionInfo: "Version Information",
    prePage: "Previous Page",
    nextPage: "Next Page",
    clickHere: "Click here",
    time: {
      month: " Month",
      week: " Week",
      day: " Day",
      hour: " Hour",
      minute: " Minute",
      second: " Second",
      months: " Months",
      weeks: " Weeks",
      days: " Days",
      hours: " Hours",
      minutes: " Minutes",
      seconds: " Seconds",
    },
    versionNumber: "Version Number",
    consoleVersionNumber: "Version",
    lastVersionNumber: "Last Version Number",
    uploadComponent: {
      localUpload: "Local Upload",
      supportExtensions: "Supported Extensions",
      import: "Import",
    },
    pcs: " Count",
    tipAll: "Batch processing ",
    tipSuccess: "Among them, success  ",
    tipFail: "Failed  ",
    tipMessage: "The failure information is as follows：",
    operateOk: "OK",
    goBack: "Return",
    premission: "Permission",
    otherPermission: "Other Permissions",
    append: "Add",
    title: "Title",
    content: "Body",
    search: "Search",
    searchKeyPlaceholder: "Please enter a search keyword",
    to: "to",
    totalPage: "Total {total} items",
    at: "at",
  },
  app: {
    name: "ENCOO HyperAutomation",
  },
  environment: {
    uniqueCode: "Machine code",
  },
  week: {
    MON: "Monday",
    TUE: "Tuesday",
    WED: "Wednesday",
    THU: "Thursday",
    FRI: "Friday",
    SAT: "Saturday",
    SUN: "Sunday",
  },
  cron: {
    atCurrent: "in the current first",
    every: "Per",
    atTime: "-",
    minuteExecute: "minutes execute",
    everyhourExecuteOnce: "Execute hourly",
    everyDay: "Everyday",
    execute: "Execute",
    everyMonthExecuteOnce: "Execute monthly",
    month: "Month(s)",
    day: "Day(s)",
    hour: "O'clock",
    enterCron: "Please enter Cron expression.",
    clickToGenerateCron: "Click to generate expressions online.",
    byDay: {
      at: "at that day's",
    },
  },
  errors: {
    noPermission: {
      title: "Sorry",
      description:
        "Since you have not activated the permission, you cannot perform the operation.",
    },
    notFound: {
      title: "Sorry",
      description: "The page you are visiting does not exist.",
      button: "Home",
      request: "Requested resource does not exist.",
    },
    incorrectDepartment: {
      description: "Previous Department is no longer existed for some reason.",
    },
    disabled: {
      title: "Sorry",
      description:
        "Your account has been deactivated by the current tenant. Please contact the administrator to enable access.",
    },
    login: {
      ssoErrorTip: "Login error, Please check the time.",
      ssoErrorTip1:
        "1. Please check whether the time on the current client is consistent with that on the server.",
      ssoErrorTip2: "2. Please try clicking 'Back to home' and log in again.",
      ssoErrorReasonMessage:
        "The address of this page is a temporary Url for transit, please do not save/favorite the address of this page, or copy the address of this page for other people to use!",
    },
  },
  upgrade: {
    title: "Upgrade Alert",
    description: "We have recently upgraded the console, including:",
    tip1: "Upgrading and improving some enterprise-level features;",
    tip2: "Fixing the problem of being unable to connect to Robot in Windows 7 or Windows Server 2008 environment (please download the latest Robot installation package to fix it).",
  },
  userEdition: {
    community: "Personal Free Edition",
    communityMajor: "Personal Professional Edition",
    enterprise: "Enterprise Edition",
  },
  leaveDialog: {
    title: "Unsaved Changes",
    message:
      "Your changes to this page have not been saved. If you leave this page, these changes will be lost. Are you sure you want to leave this page?",
  },
  yes: "Yes",
  no: "No",
};
