/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Switch, Tree } from "antd";
import { DataNode, TreeProps } from "antd/lib/tree";
import _ from "lodash";
import { Fragment, memo, ReactNode, useCallback, useMemo } from "react";
import Icon from "src/components/Icon";
import Text from "src/components/Text";
import { UserPanelType } from "src/containers/apps/appsInitConfig/AddUserPanel";
import { useFormatMessage, useTheme } from "src/hooks";
import messageIds from "src/locales/messageIds";
import {
  DepartmentTreeData,
  PermissionTargetCompanyUser,
  PermissionTargetDepartment,
} from "src/models/department";

export const TreeTitleContent = memo(function ({
  iconName,
  title,
}: {
  iconName: string;
  title: ReactNode;
}) {
  return (
    <div>
      <Icon
        name={iconName}
        css={css`
          font-size: 20px;
          margin-right: 4px;
        `}
      ></Icon>
      {title}
    </div>
  );
});

export const UserTree = memo(function (props: {
  dataSourceType: UserPanelType;
  dataSource: DepartmentTreeData;
  treeProps?: TreeProps;
  dataNodeRender?: (
    item: PermissionTargetDepartment | PermissionTargetCompanyUser
  ) => DataNode;
}) {
  const { dataSourceType, dataSource, treeProps, dataNodeRender } = props;
  const _dataSource = _.cloneDeep(dataSource);

  const treeRootKey = useMemo(() => {
    return _dataSource.rootDepartment?.id;
  }, [_dataSource.rootDepartment]);

  const initDepartmentTreeData = useCallback(
    (data: PermissionTargetDepartment[]): DataNode[] => {
      return data.map((item) => {
        return {
          ...item,
          key: item.id,
          title: (
            <TreeTitleContent
              iconName={
                item.parentResourceType === "Company"
                  ? "icon-tenant"
                  : "icon-department-tree"
              }
              title={item.name}
            />
          ),
          children: item.children && initDepartmentTreeData(item.children),
          ...(dataNodeRender?.(item) ?? {}),
        };
      });
    },
    [dataNodeRender]
  );

  const initUserTreeData = useCallback(
    (data: PermissionTargetDepartment[]): DataNode[] => {
      return data.map((item) => {
        let children = item.children && initUserTreeData(item.children);
        const userChildren =
          item.companyUsers &&
          item.companyUsers.map((item) => {
            return {
              ...item,
              title: (
                <TreeTitleContent
                  iconName={"icon-user-tree"}
                  title={item.name}
                />
              ),
              key: item.id,
              ...(dataNodeRender?.(item) ?? {}),
            };
          });

        children = (children ?? []).concat(userChildren ?? []);

        return {
          ...item,
          key: item.id,
          title: (
            <TreeTitleContent
              iconName={
                item.parentResourceType === "Company"
                  ? "icon-tenant"
                  : "icon-department-tree"
              }
              title={item.name}
            />
          ),
          children,
        };
      });
    },
    [dataNodeRender]
  );

  const treeData = useMemo(() => {
    if (_dataSource.rootDepartment) {
      if (dataSourceType === "department") {
        return initDepartmentTreeData([_dataSource.rootDepartment]);
      } else if (dataSourceType === "user") {
        return initUserTreeData([_dataSource.rootDepartment]);
      }
    }
  }, [
    _dataSource.rootDepartment,
    dataSourceType,
    initDepartmentTreeData,
    initUserTreeData,
  ]);

  return (
    <Tree
      checkable
      defaultExpandedKeys={treeRootKey ? [treeRootKey] : undefined}
      treeData={treeData}
      {...treeProps}
    ></Tree>
  );
});

export const UserDeleteContent = memo(
  ({
    children,
    includeSubDepartment,
  }: {
    children: ReactNode;
    includeSubDepartment?: boolean;
  }) => {
    const theme = useTheme();
    const formatMessage = useFormatMessage();

    return (
      <Fragment>
        <div
          css={css`
            height: 30px;
            line-height: 30px;
            display: flex;
            justify-content: ${includeSubDepartment
              ? "space-between"
              : "flex-start"};
            padding: 0 20px;
            border-bottom: 1px solid ${theme.bodyDivider};
          `}
        >
          <span>
            {formatMessage(messageIds.apps.appInitConfig.hasSelected)}
          </span>
          {includeSubDepartment && (
            <span>
              {formatMessage(
                messageIds.apps.appInitConfig.isIncludeSubDepartment
              )}
            </span>
          )}
        </div>

        <div
          css={css`
            padding: 20px;
          `}
        >
          {children}
        </div>
      </Fragment>
    );
  }
);

export const UserDeleteItem = memo(
  ({
    item,
    onDelete,
    includeSubDepartment,
    onChangeIncludSubDepartment,
  }: {
    item:
      | (DataNode & PermissionTargetDepartment)
      | (DataNode & PermissionTargetCompanyUser);
    onDelete: () => void;
    includeSubDepartment?: boolean;
    onChangeIncludSubDepartment?: (val: boolean) => void;
  }) => {
    const theme = useTheme();
    const formatMessage = useFormatMessage();

    return (
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
        `}
      >
        <div
          css={css`
            line-height: 1;
            max-width: 200px;
          `}
        >
          <Text>{item.name}</Text>
          <Text
            type="secondary"
            css={css`
              font-size: 12px;
              display: block;
              margin-top: 2px;
            `}
          >
            {item.departmentPath}
          </Text>
        </div>
        <div>
          {includeSubDepartment && (
            <Switch
              checkedChildren={formatMessage(messageIds.common.yes)}
              unCheckedChildren={formatMessage(messageIds.common.no)}
              size="small"
              css={css`
                margin-right: 15px;
              `}
              onChange={onChangeIncludSubDepartment}
              defaultChecked
            />
          )}
          <span onClick={onDelete}>
            <Icon
              name="icon-circle-fill-del"
              css={css`
                font-size: 14px;
                color: ${theme.errorColor};
                cursor: pointer;
              `}
            />
          </span>
        </div>
      </div>
    );
  }
);
