import { ServiceClient } from "@encoo-web/encoo-lib";
import {
  ConnectorType,
  CreateRestfulConnectionTemplateData,
  DataConnection,
  DataConnectionList,
  DataConnectionProperties,
  DataConnectionServerEntity,
  DataConnectionTemplateInfos,
  RestfulConnectFunctionPayload,
  RestfulConnectionResultData,
  RestfulConnectorFunction,
} from "@encoo-web/encoo-ui";
import { DataConnectionQuery } from "src/models/dataConnection";
import { ViCodeApp } from "src/models/vicodeApp";
import { EncooListEntity } from "src/models/_shared";

export class DataConnectionServiceClient extends ServiceClient {
  public async getConnectionList(
    payload: {
      pageIndex: number;
      pageSize: number;
    } & DataConnectionQuery
  ): Promise<EncooListEntity<DataConnectionList>> {
    const req = this.buildRequestV2({
      url: "/DataConnections",
      method: "GET",
      query: payload as Record<keyof typeof payload, unknown>,
    });
    const { body } = await super.sendRequest(req);
    return body;
  }

  public async getAllConnection(payload: {
    type?: ConnectorType;
  }): Promise<DataConnectionList[]> {
    const req = this.buildRequestV2({
      url: "/DataConnections",
      method: "GET",
      query: payload,
    });

    return super.getAllByPageInternal(req);
  }

  public async createConnection(
    connection: Omit<DataConnection, "id" | "properties">,
    properties?: DataConnectionProperties
  ): Promise<DataConnection> {
    const req = this.buildRequestV2({
      url: `/DataConnections`,
      method: "POST",
      payload: {
        ...connection,
        properties: properties ? JSON.stringify(properties) : undefined,
      },
    });

    const newConnection = (await this.createInternal(
      req
    )) as DataConnectionServerEntity;

    return {
      ...newConnection,
      properties:
        "properties" in newConnection && newConnection["properties"]
          ? JSON.parse(newConnection["properties"])
          : undefined,
    };
  }

  public async getConnection(connectionId: string): Promise<DataConnection> {
    const req = this.buildRequest({
      url: `/DataConnections/${connectionId}`,
      method: "GET",
    });

    const newConnection = (await this.getByIdInternal(
      req
    )) as DataConnectionServerEntity;

    return {
      ...newConnection,
      properties:
        "properties" in newConnection && newConnection["properties"]
          ? JSON.parse(newConnection["properties"])
          : undefined,
    };
  }

  public async updateConnection(
    id: string,
    connection: Omit<DataConnection, "id" | "properties">,
    properties?: DataConnectionProperties
  ): Promise<DataConnection> {
    const req = this.buildRequest({
      url: `/DataConnections/${id}`,
      method: "PUT",
      payload: {
        ...connection,
        properties: properties ? JSON.stringify(properties) : undefined,
      },
    });

    const newConnection = (await this.updateInternal(
      req
    )) as DataConnectionServerEntity;

    return {
      ...newConnection,
      properties:
        "properties" in newConnection && newConnection["properties"]
          ? JSON.parse(newConnection["properties"])
          : undefined,
    };
  }

  public async deleteConnection(connectionId: string): Promise<boolean> {
    const req = this.buildRequest({
      url: `/DataConnections/${connectionId}`,
      method: "DELETE",
    });
    return await this.deleteInternal(req);
  }

  public async getAppsByConnectionId(
    connectionId: string
  ): Promise<ViCodeApp[]> {
    const req = this.buildRequestV2({
      url: `/DataConnections/${connectionId}/apps`,
      method: "GET",
    });

    const { body } = await this.sendRequest(req);
    return body;
  }

  public async runById(id: string): Promise<boolean> {
    const req = this.buildRequest({
      url: `/DataConnections/test`,
      method: "POST",
      payload: {
        dataConnectionId: id,
      },
    });
    const { body } = await this.sendRequest(req);
    return body.result;
  }

  public async run(payload: Omit<DataConnection, "id">): Promise<boolean> {
    const req = this.buildRequest({
      url: `/DataConnections/test`,
      method: "POST",
      payload: {
        ...payload,
        properties:
          "properties" in payload && payload["properties"]
            ? JSON.stringify(payload["properties"])
            : undefined,
      },
    });
    const { body } = await this.sendRequest(req);
    return body.result;
  }

  public async getDataConnectionTemplates(): Promise<DataConnectionTemplateInfos> {
    const req = this.buildRequestV2({
      url: `/DataConnectionTemplates`,
      method: "GET",
    });
    return await this.getAllByPageInternal(req);
  }

  public async getDataConnectionTemplatesByIdOrType(
    id: string
  ): Promise<DataConnectionTemplateInfos> {
    const req = this.buildRequestV2({
      url: `/DataConnectionTemplates/${id}`,
      method: "GET",
    });
    return await this.getAllByPageInternal(req);
  }

  public async getFuncrionVaribleList(
    payload: RestfulConnectFunctionPayload
  ): Promise<{
    functionList: RestfulConnectorFunction[];
  }> {
    const req = this.buildRequestV2({
      url: `/DataConnectionTemplates/restfulconnector/functionvariablelist`,
      method: "POST",
      payload,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  public async createDataConnectionTemplate(
    payload: CreateRestfulConnectionTemplateData
  ): Promise<RestfulConnectionResultData> {
    const req = this.buildRequestV2({
      url: `/DataConnectionTemplates`,
      method: "POST",
      payload,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async deleteCustomTemplate(id: string): Promise<boolean> {
    const req = this.buildRequest({
      url: `/DataConnectionTemplates/${id}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }
}
