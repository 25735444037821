import { JobQueueDetailUIModel } from "src/store/models/ui/jobQueue/jobQueueDetail";
import { JobQueueJobUIModel } from "src/store/models/ui/jobQueue/jobQueueJob";
import { JobQueueMonitorDetailUIModel } from "src/store/models/ui/jobQueue/jobQueueMonitorDetail";
import { JobQueueSideUIModel } from "src/store/models/ui/jobQueue/jobQueueSide";
import { JobQueueTableUIModel } from "src/store/models/ui/jobQueue/jobQueueTable";
import { MessageTableUIModel } from "src/store/models/ui/jobQueue/MessageTable";

export const encooJobQueueUIModels = {
  jobQueueTable: JobQueueTableUIModel,
  jobQueueSide: JobQueueSideUIModel,
  jobQueueDetail: JobQueueDetailUIModel,
  jobQueueJob: JobQueueJobUIModel,
  JobQueueMonitorDetail: JobQueueMonitorDetailUIModel,
  messageTable: MessageTableUIModel,
};
