import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import {
  PaymentAppNameEnum,
  PaymentCategoryEnum,
  PaymentProduct,
} from "src/models/payment";
import { WorkflowLayout } from "src/models/workflowLayout";

export const PAYMENT_SERVICE_NAME = "payment";
export type PaymentProductPayload =
  | {
      appName: PaymentAppNameEnum.Console;
      category: PaymentCategoryEnum.Persional;
    }
  | {
      appName: PaymentAppNameEnum.AIPlatform;
      category: PaymentCategoryEnum.OCR;
    }
  | {
      appName: PaymentAppNameEnum.ViCode;
      category: PaymentCategoryEnum.ViCode;
    };

export class PaymentClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, PAYMENT_SERVICE_NAME);
  }

  async products(payload: PaymentProductPayload): Promise<PaymentProduct[]> {
    const req = this.buildRequestV2({
      url: `/payment/products`,
      method: "GET",
      query: payload,
    });
    const { body } = await this.sendRequest(req);
    return body.map((item: PaymentProduct) =>
      Object.assign(
        { appName: payload.appName, category: payload.category },
        item
      )
    );
  }

  async PayUrl(payload: {
    orderId: string;
    payMethod: "wechatpay_native";
  }): Promise<{
    code_url: string;
  }> {
    const req = this.buildRequestV2({
      url: `/payment/orderpayments`,
      method: "POST",
      payload,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async checkOrder(id: string): Promise<WorkflowLayout> {
    const req = this.buildRequestV2({
      url: `/payment/orderpayments/${id}`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
}
