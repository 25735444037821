import { createModel } from "nyax";
import { ViCodeApp } from "src/models/vicodeApp";
import { ViCodeAppEntityModel } from "src/store/models/entity/vicodeApp/entity";
import { createHelperModel } from "src/store/models/entity/_shared";
import { ViCodeAppCreationData } from "src/store/models/ui/vicodeApp/_shared";

export const ViCodeAppHelperModel = createModel(
  class extends createHelperModel<ViCodeApp>({
    setItems: (getContainer, items) =>
      getContainer(ViCodeAppEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(ViCodeAppEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(ViCodeAppEntityModel).state.byId[id],
  }) {
    public effects() {
      return {
        ...super.effects(),

        readByConnectionId: async (payload: {
          connectionId: string;
          force?: boolean;
        }) => {
          const { connectionId } = payload;
          return await this._readAll({
            getAllAction: () => {
              return this.dependencies.serviceClient.dataConnection.getAppsByConnectionId(
                connectionId
              );
            },
          });
        },

        readById: async (payload: { id: string; force?: boolean }) => {
          const { id, force } = payload;
          return await this._readById({
            id,
            getByIdAction: () => {
              return this.dependencies.serviceClient.vicodeApp.getById(id);
            },
            force,
          });
        },

        update: async (payload: {
          app: Partial<ViCodeApp> & Pick<ViCodeApp, "id">;
        }) => {
          const { app } = payload;
          return await this._update({
            id: app.id,
            updateAction: () =>
              this.dependencies.serviceClient.vicodeApp.update(app),
          });
        },

        delete: async (payload: { id: string }) => {
          const { id } = payload;
          return await this._delete({
            id: id,
            deleteAction: () =>
              this.dependencies.serviceClient.vicodeApp.delete(id),
          });
        },

        create: async (payload: ViCodeAppCreationData) => {
          return await this._create({
            createAction: async () => {
              const newApp =
                await this.dependencies.serviceClient.vicodeApp.create(payload);
              return newApp;
            },
          });
        },

        copy: async (payload: { appId: string }) => {
          const { appId } = payload;

          await this.dependencies.serviceClient.vicodeApp.copy(appId);
        },

        addFavorite: async (payload: { appId: string }) => {
          const { appId } = payload;
          await this.dependencies.serviceClient.vicodeApp.addViCodeAppFavorite(
            appId
          );
        },

        deleteFavorite: async (payload: { appId: string }) => {
          const { appId } = payload;
          await this.dependencies.serviceClient.vicodeApp.deleteViCodeAppFavorite(
            appId
          );
        },

        release: async (params: { appId: string }) => {
          const { appId } = params;
          const appVersion =
            await this.dependencies.serviceClient.vicodeApp.release(appId);
          await this.actions.readById.dispatch({ id: appId, force: true });
          return appVersion;
        },

        offline: async (params: { appId: string }) => {
          const { appId } = params;
          return await this._update({
            id: appId,
            updateAction: () =>
              this.dependencies.serviceClient.vicodeApp.offline(appId),
          });
        },

        uploadViCodeAppIcon: async (params: {
          appId: string;
          payload?: FormData;
        }) => {
          const { appId, payload } = params;
          await this.dependencies.serviceClient.vicodeApp.uploadViCodeAppIcon(
            appId,
            payload
          );
          await this.actions.readById.dispatch({ id: appId, force: true });
        },
      };
    }
  }
);
