import { createModel } from "nyax";
import { Robot } from "src/models/robot";
import { ModelBase } from "src/store/ModelBase";

export const QueueDetailUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        bindingRobots: [] as Robot[],
      };
    }

    public reducers() {
      return {
        setBindingRobots: async (value: Robot[]) => {
          this.state.bindingRobots = value;
        },
      };
    }

    public effects() {
      return {
        getBindingRobots: async (payload: { queueId: string }) => {
          const { queueId } = payload;
          const robots =
            await this.dependencies.serviceClient.queue.getAllBindingInfos(
              queueId
            );
          await this.actions.setBindingRobots.dispatch(robots);
        },
      };
    }
  }
);
