import { WorkflowAssetImportTableUIModel } from "src/store/models/ui/workflow/workflowAssetImportTable";
import { WorkflowDetailModel } from "src/store/models/ui/workflow/workflowDetail";
import { WorkflowDetailModelV2 } from "src/store/models/ui/workflow/workflowDetailV2";
import { WorkflowJobUIModel } from "src/store/models/ui/workflow/workflowJob";
import { WorkflowScheduledTaskUIModel } from "src/store/models/ui/workflow/workflowScheduledTask";
import { WorkflowSideUIModel } from "src/store/models/ui/workflow/workflowSide";
import { WorkflowTableUIModel } from "src/store/models/ui/workflow/workflowTable";

export const encooWorkflowUIModels = {
  workflowTable: WorkflowTableUIModel,
  workflowSide: WorkflowSideUIModel,
  workflowDetail: WorkflowDetailModel,
  workflowDetailV2: WorkflowDetailModelV2,
  packageJob: WorkflowJobUIModel,
  workflowAssetImport: WorkflowAssetImportTableUIModel,
  workflowScheduledTaskUIModel: WorkflowScheduledTaskUIModel,
};
