// eslint-disable-next-line import/no-anonymous-default-export
export default {
  coeWork: {
    common: {
      history: "历史",
      logeventstate: "状态变更",
      initiate: "发起",
      changeStateTo: "改变状态为",
      state: {
        all: "全部状态",
        todo: "待处理",
        processing: "处理中",
        passed: "已通过",
        failed: "未通过",
        canceled: "已撤销",
        development: "开发中",
        evaluation: "评估中",
        shelve: "搁置",
        closed: "已关闭",
        completed: "已完成",
      },
      timeUtils: {
        hour: "小时",
        minute: "分钟",
        day: "天",
        week: "周",
        month: "月",
      },
      noPermission: "该用户已无权限",
      placeholders: {
        searchKeyword: "请输入搜索关键字",
      },
      degree: {
        importantEmergency: "重要紧急",
        unimportantUrgent: "不重要紧急",
        importantNotUrgent: "重要不紧急",
        notImportantNotUrgent: "不重要不紧急",
      },
      notifications: {
        undo: "确认撤销{title}?",
      },
    },
    table: {
      columns: {
        title: "标题",
        importance: "紧急重要度",
        state: "状态",
        follower: "当前跟进人",
        sponsor: "发起人（部门）",
        process: "关联流程",
        lastUpdated: "最后更新时间",
        operation: "操作",
      },
      buttons: {
        create: "新需求",
        view: "查看",
        undo: "撤销",
      },
    },
    addForm: {
      buttons: {
        submit: "提交",
        cancel: "取消",
      },
      utils: {
        second: "次",
      },
      title: "标题",
      process: "流程关联",
      degree: "紧急重要度",
      consume: "单次人工耗时",
      frequency: "频次",
      description: "需求描述",
      otherInformation: "其他资料",
      follower: "跟进人",
    },
    details: {
      created: "创建了",
      changed: "更改了",
      elapsedTimeOfManual: "单次平均人工耗时",
      unitOfElapsedTime: "单次平均人工耗时单位",
      frequency: "频次",
      unitOfFequency: "频次单位",
      detail: "详细描述",
      otherDetails: "其他资料",
      processCorrelation: "流程关联",
      associateProcess: "关联流程",
      processName: "流程名称",
      assignedTo: "当前跟进人",
      attachmentName: "附件名称",
    },
    message: {
      changeDepartment:
        "是否切换部门至 {departmentName} 查看流程 {processName}",
    },
  },
};
