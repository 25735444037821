/** @jsxImportSource @emotion/react */
import { message } from "antd";
import { memoize } from "lodash";
import React, { Fragment, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useModal } from "src/components/Dialog";
import { TableOperationMenuItem } from "src/components/TableOperation";
import AppEnableDialog from "src/containers/apps/AppEnableDialog";
import { AppReleaseDialog } from "src/containers/apps/AppReleaseDialog";
import AppsInitConfig from "src/containers/apps/AppsInitConfig";
import {
  useApplicationInsights,
  useFormatMessage,
  useGetContainer,
  useGlobalCompany,
} from "src/hooks";
import messageIds from "src/locales/messageIds";
import { AppStatus, AppType, AppV2 } from "src/models/appV2";
import { AppHelperModel } from "src/store/models/entity/apps/helper";
import { GlobalSelectDepartmentModel } from "src/store/models/logic/globalSelectDepartment";
import { RouterModel } from "src/store/models/router";
import { AppInitConfigUIModel } from "src/store/models/ui/apps/appInitConfig";
import { AppsUIModel, EnabledAppPayload } from "src/store/models/ui/apps/apps";
import { QuotaUIModel } from "src/store/models/ui/quota/quota";

export const useAppV1Type = function (): Record<AppType, string> {
  const formatMessage = useFormatMessage();
  return useMemo(() => {
    return {
      App: formatMessage(messageIds.apps.appType.basicApp),
      AppGroup: formatMessage(messageIds.apps.appType.groupApp),
    };
  }, [formatMessage]);
};

export const useAppV1Status = function (): Record<
  AppStatus,
  string | undefined
> {
  const formatMessage = useFormatMessage();

  return useMemo(() => {
    return {
      Enabled: formatMessage(messageIds.apps.status.enabled),
      Disabled: formatMessage(messageIds.apps.status.disabled),
      NotReady: formatMessage(messageIds.apps.status.notReady),
    };
  }, [formatMessage]);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppV1Operation = function () {
  const formatMessage = useFormatMessage();
  const getContainer = useGetContainer();
  const Modal = useModal();

  const appsUiContainer = getContainer(AppsUIModel);
  const appsHelper = getContainer(AppHelperModel);
  const globalSelectDepartmentModel = getContainer(GlobalSelectDepartmentModel);
  const appInitConfigUIContainer = getContainer(AppInitConfigUIModel);
  const quota = getContainer(QuotaUIModel);

  const globalCompany = useGlobalCompany();

  const applicationInsights = useApplicationInsights();

  const selectedDepartmentId = useSelector(
    () => globalSelectDepartmentModel.state.selectedDepartmentId
  );

  const selectCompanyId = useMemo(() => globalCompany.id, [globalCompany.id]);

  const onDeleting = useMemo(
    () =>
      memoize((ids: string[]) => {
        return () => {
          Modal.info({
            content: formatMessage(messageIds.common.deleteConfirmation),
            onOk: () => {
              appsHelper.actions.deleteBatch.dispatch({
                appIds: ids,
              });
              quota.actions.refreshQuota.dispatch({});
            },
          });
        };
      }),
    [
      Modal,
      appsHelper.actions.deleteBatch,
      formatMessage,
      quota.actions.refreshQuota,
    ]
  );

  const onHandleEnableAppPayload = useCallback(
    async (val: EnabledAppPayload | null) => {
      await appsUiContainer.actions.setEnabledAppPayload.dispatch(val);
    },
    [appsUiContainer.actions.setEnabledAppPayload]
  );

  const onEnabledItem = useMemo(
    () =>
      memoize((record: AppV2) => {
        return async () => {
          await onHandleEnableAppPayload({
            appId: record.id,
            appName: record.name,
            appVersionId:
              record.currentVersionId ?? record.lastAvailableVersionId ?? "",
          });
        };
      }),
    [onHandleEnableAppPayload]
  );

  const onDisabled = useMemo(
    () =>
      memoize((id: string) => {
        return () => {
          appsHelper.actions.disableApp.dispatch({
            appId: id,
          });
        };
      }),
    [appsHelper.actions.disableApp]
  );

  const onCopyClick = useCallback(
    (app: AppV2) => {
      appsUiContainer.actions.copy.dispatch(app.id);
    },
    [appsUiContainer.actions.copy]
  );

  const onEditorClick = useCallback(
    (app: AppV2) => {
      applicationInsights.sendTrackEvent(
        { name: "Vicode_EditAppEvent" },
        {
          AppID: app.id,
        }
      );
      window.open(
        `${window._settings.appsUrl}editor?companyId=${selectCompanyId}&departmentId=${selectedDepartmentId}&appId=${app.id}`
      );
    },
    [applicationInsights, selectCompanyId, selectedDepartmentId]
  );

  const showInitConfigModal = useCallback(
    async (item) => {
      await appInitConfigUIContainer.actions.setUpdateData.dispatch(null);
      await appInitConfigUIContainer.actions.setCurrentInitConfigApp.dispatch(
        item
      );
    },
    [
      appInitConfigUIContainer.actions.setCurrentInitConfigApp,
      appInitConfigUIContainer.actions.setUpdateData,
    ]
  );

  const showReleaseModal = useMemo(
    () =>
      memoize((item: AppV2) => {
        return async () => {
          await appsUiContainer.actions.setReleaseApp.dispatch(item);
          await appsUiContainer.actions.getDraftVersion.dispatch({
            appId: item.id,
            draftVersionId: item.draftVersionId,
          });
        };
      }),
    [
      appsUiContainer.actions.getDraftVersion,
      appsUiContainer.actions.setReleaseApp,
    ]
  );

  const getMenuItems: (rowData: AppV2) => TableOperationMenuItem[] =
    useCallback(
      (rowData) => {
        let menuItems: TableOperationMenuItem[] = [];
        if (rowData.status === "Enabled") {
          menuItems.push({
            key: "disabled",
            name: formatMessage(messageIds.apps.statusHandle.disabled),
            visible: !!(
              rowData?.lastAvailableVersion &&
              rowData.permissionValues?.includes("App_Launch")
            ),
            onClick: onDisabled(rowData.id),
          });
        } else if (rowData.status === "Disabled") {
          menuItems.push({
            key: "enabled",
            name: formatMessage(messageIds.apps.statusHandle.enabled),
            visible: !!(
              rowData?.lastAvailableVersion &&
              rowData.permissionValues?.includes("App_Launch")
            ),
            onClick: onEnabledItem(rowData),
          });
        } else if (rowData.status === "NotReady") {
          menuItems.push({
            key: "appInitConfig",
            name: formatMessage(messageIds.apps.appInitConfig.initConfig),
            onClick: () => showInitConfigModal(rowData),
            visible: rowData.permissionValues?.includes("App_Update"),
          });
        }

        menuItems = menuItems.concat([
          {
            key: "copy",
            name: formatMessage(
              messageIds.apps.appDev.card.menu.copyAppProject
            ),
            visible:
              !rowData?.anonymous &&
              rowData.permissionValues?.includes("App_Copy") &&
              rowData.status !== "NotReady",
            onClick: () => {
              onCopyClick(rowData);
            },
          },
          {
            key: "edit",
            name: formatMessage(messageIds.common.edit),
            visible:
              rowData.permissionValues?.includes("App_Update") &&
              rowData.status !== "NotReady",
            onClick: () => {
              onEditorClick(rowData);
            },
          },
          {
            key: "delete",
            name: formatMessage(messageIds.common.delete),
            type: "warning",
            visible: rowData.permissionValues?.includes("App_Delete"),
            onClick: onDeleting([rowData.id]),
          },
          {
            key: "release",
            name: formatMessage(messageIds.apps.appRelease.release),
            visible:
              rowData.permissionValues?.includes("App_Publish") &&
              rowData.status !== "NotReady",
            onClick: showReleaseModal(rowData),
          },
        ]);

        return menuItems;
      },
      [
        formatMessage,
        onCopyClick,
        onDeleting,
        onDisabled,
        onEditorClick,
        onEnabledItem,
        showInitConfigModal,
        showReleaseModal,
      ]
    );

  const getMenuList = useCallback(
    (app: AppV2) => {
      return getMenuItems(app);
    },
    [getMenuItems]
  );

  return getMenuList;
};

export const AppV1OperationDialog = React.memo(function () {
  const formatMessage = useFormatMessage();
  const getContainer = useGetContainer();

  const appsUiContainer = getContainer(AppsUIModel);
  const appsHelper = getContainer(AppHelperModel);
  const router = getContainer(RouterModel);
  const appInitConfigUIContainer = getContainer(AppInitConfigUIModel);

  const currentInitConfigApp = useSelector(
    () => appInitConfigUIContainer.state.currentInitConfigApp
  );

  const releaseApp = useSelector(() => appsUiContainer.state.releaseApp);
  const releaseAppVersion = useSelector(
    () => appsUiContainer.state.appDraftVersion
  );

  const enabledAppPayload = useSelector(
    () => appsUiContainer.state.enabledAppPayload
  );

  const onHandleEnableAppPayload = useCallback(
    async (val: EnabledAppPayload | null) => {
      await appsUiContainer.actions.setEnabledAppPayload.dispatch(val);
    },
    [appsUiContainer.actions.setEnabledAppPayload]
  );

  const showInitConfigModal = useCallback(
    async (item) => {
      await appInitConfigUIContainer.actions.setUpdateData.dispatch(null);
      await appInitConfigUIContainer.actions.setCurrentInitConfigApp.dispatch(
        item
      );
    },
    [
      appInitConfigUIContainer.actions.setCurrentInitConfigApp,
      appInitConfigUIContainer.actions.setUpdateData,
    ]
  );

  const cancelReleaseModal = useCallback(() => {
    appsUiContainer.actions.setReleaseApp.dispatch(null);
  }, [appsUiContainer.actions.setReleaseApp]);

  const onCancelAppInit = useCallback(() => {
    appInitConfigUIContainer.actions.setCurrentInitConfigApp.dispatch(null);
  }, [appInitConfigUIContainer.actions.setCurrentInitConfigApp]);

  const initAppConfigByMarketUrl = useCallback(async () => {
    const params = router.getters.searchParams;
    const initId = params.get("appInitIdByMarket");

    if (initId) {
      const app = await appsHelper.actions.readById.dispatch({
        id: initId,
        force: true,
      });

      if (app?.status === "NotReady") {
        showInitConfigModal(app);
      } else {
        message.info(
          formatMessage(messageIds.apps.appInitConfig.appsInitialized)
        );
      }
    }
  }, [
    appsHelper.actions.readById,
    formatMessage,
    router.getters.searchParams,
    showInitConfigModal,
  ]);

  useEffect(() => {
    initAppConfigByMarketUrl();
  }, [initAppConfigByMarketUrl]);

  return (
    <Fragment>
      {enabledAppPayload && (
        <AppEnableDialog
          appId={enabledAppPayload.appId}
          appVersionId={enabledAppPayload.appVersionId}
          onCancle={() => onHandleEnableAppPayload(null)}
          appVersion={enabledAppPayload.appVersion}
          handleType={enabledAppPayload.handleType}
          appName={enabledAppPayload.appName}
        />
      )}
      {currentInitConfigApp && (
        <AppsInitConfig app={currentInitConfigApp} onCancel={onCancelAppInit} />
      )}
      {releaseApp && releaseAppVersion && (
        <AppReleaseDialog
          app={releaseApp}
          onCancel={cancelReleaseModal}
          appVersion={releaseAppVersion}
        />
      )}
    </Fragment>
  );
});
