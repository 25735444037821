import { createModel } from "nyax";
import { AiAccountService, ServiceCreationData } from "src/models/ocr";
import { AIEntityModel } from "src/store/models/entity/ai/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const AIHelperModel = createModel(
  class extends createHelperModel<AiAccountService>({
    setItems: (getContainer, items) =>
      getContainer(AIEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(AIEntityModel).getters.items,
    getItem: (getContainer, id) => getContainer(AIEntityModel).state.byId[id],
  }) {
    public effects() {
      return {
        ...super.effects(),

        // readDetailById: async (payload: { id: string; force?: boolean }) => {
        //   const { id, force } = payload;
        //   return await this._readById({
        //     id,
        //     getByIdAction: () => {
        //       return this.dependencies.serviceClient.ocr.getDetailById(id);
        //     },
        //     force,
        //   });
        // },

        create: async (payload: {
          serviceCreationData: ServiceCreationData;
        }) => {
          const { serviceCreationData } = payload;
          await this.dependencies.serviceClient.ocr.create(serviceCreationData);
        },

        update: async (payload: {
          id: string;
          serviceCreationData: ServiceCreationData;
        }) => {
          const { id, serviceCreationData } = payload;
          await this.dependencies.serviceClient.ocr.update(
            id,
            serviceCreationData
          );
        },

        delete: async (payload: { id: string }) => {
          const { id } = payload;
          await this.dependencies.serviceClient.ocr.delete(id);
        },
      };
    }
  }
);
