// eslint-disable-next-line import/no-anonymous-default-export
export default {
  aiManagement: {
    title: "AI服务管理",
    entityTypeName: "TODO:",
    name: "TODO: Model Name",
    service: "服务",
    introduction:
      "查看免费服务统计及可配置各AI平台账号，以便于直接在流程中使用。",
    balanceWarningDialog: {
      name: "余额通知",
      trigger: "当任意云扩AI服务余额少于等于",
      email: "自动发送邮件至",
      times: "次",
    },
    notice: "注意",
    noticeContent: "此服务仅适用于云扩AI平台的服务。",

    chart: {
      title: "数据统计",
    },
    table: {
      title: "AI平台",
      amount: "总额度",
      minimum: "最小值",
      maximum: "最大值",
      necessaryParameters: "必要参数",
      columns: {
        aiService: "AI服务",
        remainingAmount: "剩余量",
        usage: "使用量",
      },
      button: {
        config: "云扩AI SAAS服务配置",
      },
    },
  },
};
