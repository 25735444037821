import { createModel, createSelector } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { DataPermissionEntityModel } from "src/store/models/entity/dataPermission/entity";
import { CalendarsEntityModel } from "src/store/models/entity/calendars/entity";
import { CalendarsHelperModel } from "src/store/models/entity/calendars/helper";

export const CalendarsDetailUIModel = createModel(
  class extends ModelBase {
    private get _id() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.containerKey!;
    }
    public selectors() {
      return {
        calendarsDetail: createSelector(
          () => this.getContainer(CalendarsEntityModel).state.byId,
          () => this.getContainer(DataPermissionEntityModel).state.byId,
          (calendarsById, permissionById) => {
            const calendars = calendarsById[this._id];
            return calendars
              ? {
                  ...calendars,
                  permissionValues:
                    permissionById[this._id]?.permissionValues ?? [],
                }
              : null;
          }
        ),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        requestDetail: async () => {
          await this.getContainer(CalendarsHelperModel).actions.getById.dispatch({
            calendarsId: this._id,
          });
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
