import { createModel, createSelector } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { DataPermissionEntityModel } from "src/store/models/entity/dataPermission/entity";
import { DataPermissionHelperModel } from "src/store/models/entity/dataPermission/helper";
import { ViCodeAppEntityModel } from "src/store/models/entity/vicodeApp/entity";
import { ViCodeAppHelperModel } from "src/store/models/entity/vicodeApp/helper";
import { GlobalSelectDepartmentModel } from "src/store/models/logic/globalSelectDepartment";
import { AppDetailUIModel } from "src/store/models/ui/apps/appDetail";
import { ViCodeAppWithPermission } from "src/store/models/ui/vicodeApp/_shared";

export type AppDetailTabActiveKey =
  | "basicInfo"
  | "versionManagement"
  | "permissionConfig";

export type AppViewMode = "view" | "update";

export const UIViCodeAppAppDetailModel = createModel(
  class extends ModelBase {
    private get _appHelperContainer() {
      return this.getContainer(ViCodeAppHelperModel);
    }
    private get _appEntityContainer() {
      return this.getContainer(ViCodeAppEntityModel);
    }
    private get _globalSelectDepartmentModel() {
      return this.getContainer(GlobalSelectDepartmentModel);
    }
    private get _dataPermissionEntityModel() {
      return this.getContainer(DataPermissionEntityModel);
    }

    public initialState() {
      return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        appId: this.containerKey!,
        tabActiveKey: "basicInfo" as AppDetailTabActiveKey,
        viewMode: "view" as AppViewMode,
      };
    }
    public reducers() {
      return {
        setTabActiveKey: (value: AppDetailTabActiveKey) => {
          this.state.tabActiveKey = value;
        },
        setViewMode: (value: AppViewMode) => {
          this.state.viewMode = value;
        },
      };
    }

    public selectors() {
      return {
        app: createSelector(
          () => this._globalSelectDepartmentModel.state.selectedDepartmentId,
          () => this._appEntityContainer.state.byId[this.state.appId],
          () => this._dataPermissionEntityModel.state.byId,
          (
            selectedDepartmentId,
            app,
            dataPermission
          ): ViCodeAppWithPermission | undefined => {
            return app?.departmentId === selectedDepartmentId
              ? {
                  ...app,
                  permissionValues:
                    dataPermission[this.state.appId]?.permissionValues ?? [],
                }
              : undefined;
          }
        ),
      };
    }

    public effects() {
      return {
        initialize: async (payload: { isVicodeApp: boolean }) => {
          const { isVicodeApp } = payload;

          if (isVicodeApp) {
            await this._appHelperContainer.actions.readById.dispatch({
              id: this.state.appId,
              force: true,
            });
            await this.getContainer(
              DataPermissionHelperModel
            ).actions.getById.dispatch({
              resourceType: "App",
              id: this.state.appId,
            });
          } else {
            await this.getContainer(
              AppDetailUIModel,
              this.state.appId
            ).actions.initializeRequest.dispatch({});
          }
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
