// eslint-disable-next-line import/no-anonymous-default-export
export default {
  calendars: {
    entityTypeName: "Calendar Management",
    name: "Calendar Name",
    introduction:
      "Manage custom calendars; it is used for timing schedules or running restrictions, and supports operations such as creating, editing, deleting, and viewing calendars.",
    totalDates: "Total ",
    create: "Create the Calendar",
    errorMessage: "Selected date can not be null.",
    updateConfirm: "Are you sure you want to save current changes?",
    updateAlertInfo: {
      startHalf: "It has been used in ",
      endHalf: ". After saving, it will be synchronized to the usage scene.",
    },
    deleteAlertInfo: {
      startHalf: "It has been used in ",
      endHalf: ". Unable to be deleted, please unbind first!",
    },
  },
};
