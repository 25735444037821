import { createModel } from "nyax";
import { CompanyUser } from "src/models/companyUser";
import { CoeMyWorkEntityModel } from "src/store/models/entity/coeMyWork/entity";
import { CoeWorkMagerEntityModel } from "src/store/models/entity/coeWorkManger/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const CoeWorkMagerHelperModel = createModel(
  class extends createHelperModel<CompanyUser>({
    setItems: (getContainer, items) =>
      getContainer(CoeWorkMagerEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(CoeWorkMagerEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(CoeWorkMagerEntityModel).state.byId[id],
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(CoeMyWorkEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        getAll: async () => {
          return await this._readAll({
            getAllAction: async () => {
              return await this.dependencies.serviceClient.coeWork.managers();
            },
          });
        },
      };
    }
  }
);
