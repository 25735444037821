import { createModel } from "nyax";
import {
  Robot,
  RobotCreationData,
  RobotUpgradePlanTime,
} from "src/models/robot";
import { DataPermissionHelperModel } from "src/store/models/entity/dataPermission/helper";
import { RobotEntityModel } from "src/store/models/entity/robot/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const RobotHelperModel = createModel(
  class extends createHelperModel<Robot>({
    setItems: (getContainer, items) =>
      getContainer(RobotEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(RobotEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(RobotEntityModel).state.byId[id],
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(RobotEntityModel),
      };
    }
    public effects() {
      return {
        ...super.effects(),
        readByGroupIds: async (payload: { force?: boolean }) => {
          const { force } = payload;
          this._readByParentIds({
            parentIds: [""],
            getAllAction: () => {
              return this.dependencies.serviceClient.robot.getAll();
            },
            getEntityParentId: () => "",
            force,
          });
        },
        getById: async (payload: { robotId: string }) => {
          const { robotId } = payload;
          return this._readById({
            id: robotId,
            force: true,
            getByIdAction: async () => {
              const robot = await this.dependencies.serviceClient.robot.getById(
                robotId
              );
              const { resourceType, id } = robot;
              const dataPermissionPayload = [{ resourceType, id }];

              await this.getContainer(
                DataPermissionHelperModel
              ).actions.getAll.dispatch(dataPermissionPayload);

              return robot;
            },
          });
        },
        create: async (payload: { robot: RobotCreationData }) => {
          const { robot } = payload;
          return this._create({
            createAction: () => {
              return this.dependencies.serviceClient.robot.create(robot);
            },
          });
        },
        delete: async (payload: { robotId: string }) => {
          const { robotId } = payload;
          return this._delete({
            id: robotId,
            deleteAction: () => {
              return this.dependencies.serviceClient.robot.delete(robotId);
            },
          });
        },
        deleteBatch: async (payload: { robotIds: string[] }) => {
          const { robotIds } = payload;
          return await this.dependencies.serviceClient.robot.batchDelete(
            robotIds
          );
        },
        batchRemove: async (payload: { robotIds: string[] }) => {
          const { robotIds } = payload;
          return await this.dependencies.serviceClient.robot.batchRemove(
            robotIds
          );
        },
        batchUpgrade: async (payload: {
          robotIds: string[];
          planTime: RobotUpgradePlanTime;
          fixedTime?: string | undefined;
        }) => {
          const { robotIds, planTime, fixedTime } = payload;
          return await this.dependencies.serviceClient.robot.batchUpgrade(
            robotIds,
            planTime,
            fixedTime
          );
        },
        batchAssign: async (payload: { robotIds: string[] }) => {
          const { robotIds } = payload;
          return await this.dependencies.serviceClient.robot.batchAssign(
            robotIds
          );
        },
        update: async (payload: {
          robotId: string;
          robot: Partial<Omit<Robot, "id">>;
        }) => {
          const { robotId, robot } = payload;
          return this._update({
            id: robotId,
            updateAction: () => {
              return this.dependencies.serviceClient.robot.update(
                robotId,
                robot
              );
            },
          });
        },
        upgrade: async (payload: {
          robotId: string;
          planTime: RobotUpgradePlanTime;
          fixedTime?: string | undefined;
        }) => {
          const { robotId, planTime, fixedTime } = payload;
          return this._update({
            id: robotId,
            updateAction: () => {
              return this.dependencies.serviceClient.robot.upgrade(
                robotId,
                planTime,
                fixedTime
              );
            },
          });
        },
        updateThresholdSettings: async (payload: {
          robotId: string;
          robot: Partial<Omit<Robot, "id">>;
        }) => {
          const { robotId, robot } = payload;
          return this._update({
            id: robotId,
            updateAction: () => {
              return this.dependencies.serviceClient.robot.updateThresholdSettings(
                robotId,
                robot
              );
            },
          });
        },
        getRunInstance: async (payload: { robotId: string }) => {
          const { robotId } = payload;
          return await this.dependencies.serviceClient.robot.getRunInstance(
            robotId
          );
        },
        getRemoteDesktop: async (payload: { robotId: string }) => {
          const { robotId } = payload;
          return await this.dependencies.serviceClient.robot.getRemoteDesktop(
            robotId
          );
        },
        createRunInstance: async (payload: {
          robotId: string;
          robot: RobotCreationData;
        }) => {
          const { robotId, robot } = payload;
          return await this.dependencies.serviceClient.robot.createRunInstance(
            robotId,
            robot
          );
        },
        deleteRunInstance: async (payload: { robotId: string }) => {
          const { robotId } = payload;
          return await this.dependencies.serviceClient.robot.deleteRunInstance(
            robotId
          );
        },
      };
    }
  }
);
