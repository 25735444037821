/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Breadcrumb, Button } from "antd";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useFormatMessage, useGetContainer, useTheme } from "src/hooks";
import { appRouteDefinitions, AppRouteInfo } from "src/routes";
import { AppRouteType, MenuExpandedDefinition } from "src/routes/_shared";
import { RouterModel } from "src/store/models/router";
import { getBreadcrumbRouters } from "src/utils/router";

export default React.memo(function AppBreadcrumb(props: {
  activityItem?: MenuExpandedDefinition;
}) {
  const { activityItem } = props;
  const theme = useTheme();
  const getContainer = useGetContainer();
  const router = getContainer(RouterModel);
  const formatMessage = useFormatMessage();

  const currentRouteInfo = useSelector(() => router.getters.currentRouteInfo);

  const breadcrumbRouters = useMemo(() => {
    if (activityItem) {
      return getBreadcrumbRouters(currentRouteInfo.type).concat([activityItem]);
    } else {
      return getBreadcrumbRouters(currentRouteInfo.type);
    }
  }, [activityItem, currentRouteInfo.type]);

  const onBreadcrumbClicked = useCallback(
    (type: AppRouteType) => {
      const defaultParams = appRouteDefinitions[type].defaultParams;

      const currentParams = currentRouteInfo.params as Record<string, string>;
      const params: Record<string, string> = {};
      Object.keys(defaultParams).forEach(
        (name) => (params[name] = currentParams[name])
      );
      router.actions.navigateByRouteInfo.dispatch({
        type,
        params,
      } as AppRouteInfo);
    },
    [currentRouteInfo.params, router.actions.navigateByRouteInfo]
  );

  const cssBreadcrumbButton = useMemo(
    () => css`
      padding: 0;
      color: ${theme.bodyText};
      &:hover {
        color: ${theme.primaryColor};
      }
      &:focus {
        color: ${theme.bodyText};
      }
    `,
    [theme.bodyText, theme.primaryColor]
  );

  return (
    <Breadcrumb
      css={css`
        .ant-breadcrumb-link {
          color: ${theme.bodyText};
        }
        .ant-breadcrumb-separator {
          margin: 0;
        }
      `}
      separator="/"
    >
      {/* <Breadcrumb.Item>
        <Button
          type="link"
          css={cssBreadcrumbButton}
          onClick={() => onBreadcrumbClicked("index")}
        >
          {formatMessage(messageIds.common.home)}
        </Button>
      </Breadcrumb.Item> */}
      {breadcrumbRouters.map((menu, index) => (
        <Breadcrumb.Item key={menu.name}>
          {index === breadcrumbRouters.length - 1 ? (
            <span>{menu.name ? formatMessage(menu.name) : menu.name}</span>
          ) : menu.routeType ? (
            <Button
              type="link"
              css={cssBreadcrumbButton}
              onClick={() => onBreadcrumbClicked(menu.routeType ?? "index")}
            >
              {formatMessage(menu.name)}
            </Button>
          ) : (
            <span
              css={css`
                color: ${theme.bodyText};
              `}
            >
              {formatMessage(menu.name)}
            </span>
          )}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
});
