/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Form, Input, notification } from "antd";
import TextArea from "antd/lib/input/TextArea";
import QRCode from "qrcode.react";
import React, { ReactNode, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import hasCopyUrl from "src/assets/apps/app_dialog_has_copy_url.png";
import Button from "src/components/Button";
import { useDialog } from "src/components/Dialog";
import TableOperation, {
  TableOperationMenuItem,
} from "src/components/TableOperation";
import {
  useApplicationInsights,
  useFormatMessage,
  useGetContainer,
  useTheme,
} from "src/hooks";
import messageIds from "src/locales/messageIds";
import { AppV2 } from "src/models/appV2";
import { ViCodeApp } from "src/models/vicodeApp";
import { AppHelperModel } from "src/store/models/entity/apps/helper";
import { GlobalSelectCompanyUIModel } from "src/store/models/logic/globalSelectCompany";
import { GlobalSelectDepartmentModel } from "src/store/models/logic/globalSelectDepartment";
import { DialogPreviewAppModel } from "src/store/models/ui/apps/appPreview";
import { EnvironmentModel } from "src/store/models/ui/environment/environment";
import { ViCodeAppWithPermission } from "src/store/models/ui/vicodeApp/_shared";
import { copyText } from "src/utils/copyText";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppMenuList = function (props: {
  app: AppV2 | ViCodeAppWithPermission;
}) {
  const { app } = props;
  const isViCodeApp = useMemo(() => {
    return !("anonymous" in app);
  }, [app]);

  const getContainer = useGetContainer();
  const Dialog = useDialog();
  const [emailForm] = Form.useForm();
  const globalSelectCompanyContainer = getContainer(GlobalSelectCompanyUIModel);
  const globalSelectDepartmentModel = getContainer(GlobalSelectDepartmentModel);
  const environmentModel = getContainer(EnvironmentModel);
  const appsHelper = getContainer(AppHelperModel);

  const applicationInsights = useApplicationInsights();
  const dialogPreviewAppContainer = getContainer(DialogPreviewAppModel);
  const selectCompanyId = useSelector(
    () => globalSelectCompanyContainer.state.selectedGlobalComponyId
  );
  const selectDepaetmentId = useSelector(
    () => globalSelectDepartmentModel.state.selectedDepartmentId
  );

  const formatMessage = useFormatMessage();

  const shareUrl = useCallback(
    (app: AppV2 | ViCodeApp, includeEngine?: boolean) => {
      const isViCodeApp = !("anonymous" in app);

      const baseUrl = isViCodeApp
        ? window._settings.vicodeUrl
        : window._settings.appsUrl;

      const searchParams = new URLSearchParams();

      if (isViCodeApp && "shortUrl" in app && app.shortUrl) {
        return app.shortUrl;
      } else {
        if (!("short" in app && app.short)) {
          selectCompanyId && searchParams.set("companyId", selectCompanyId);
          selectDepaetmentId &&
            searchParams.set("departmentId", selectDepaetmentId);
          searchParams.set("appId", app.id);

          if (!isViCodeApp) {
            const accessKey = (app as AppV2).accessKey;
            accessKey && searchParams.set("accessKey", accessKey);
          }
        }

        if (includeEngine) {
          if (window._settings.engine === "dingtalk") {
            searchParams.set("engine", "dingtalk");
            searchParams.set("corpId", window._settings.corpId ?? "");
          }
        }

        if ("short" in app && app.short) {
          return `${baseUrl}app/#/short/${app.short}${
            searchParams.toString() ? "?" + searchParams.toString() : ""
          }`;
        } else {
          return `${baseUrl}app/?${searchParams.toString()}`;
        }
      }
    },
    [selectCompanyId, selectDepaetmentId]
  );

  const qrCodeId = useCallback(
    (app: AppV2 | ViCodeAppWithPermission) => {
      return isViCodeApp ? app.id : `${app.id}_${(app as AppV2).lastVersionId}`;
    },
    [isViCodeApp]
  );

  const copyAppLink = useCallback(
    (app: AppV2 | ViCodeAppWithPermission) => {
      copyText(shareUrl(app));
      notification.success({
        message: formatMessage(messageIds.apps.myApp.card.menu.copySuccess),
      });
    },
    [shareUrl, formatMessage]
  );

  const convertBase64UrlToBlob = useCallback((dataURL: string) => {
    const parts = dataURL.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; i++) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
  }, []);

  const downloadQrCode = useCallback(
    (app: AppV2 | ViCodeAppWithPermission) => {
      const canvasImg = document.getElementById(qrCodeId(app));
      if (canvasImg) {
        const canvas = canvasImg as HTMLCanvasElement;
        const dataURL = canvas.toDataURL("image/png");
        // 兼容IE
        const userAgent = navigator.userAgent;
        if (userAgent.indexOf("Trident") > -1) {
          const blob = convertBase64UrlToBlob(dataURL);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window.navigator as any).msSaveBlob(blob, app.name + ".png");
        } else {
          const a = document.createElement("a");
          a.download = `${app.name}.png`;
          a.href = dataURL;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      }
    },
    [convertBase64UrlToBlob, qrCodeId]
  );

  const openCopyLink = useCallback(
    (app: AppV2 | ViCodeAppWithPermission) => {
      copyAppLink(app);
      Dialog({
        title: formatMessage(messageIds.apps.myApp.card.menu.linkShare),
        content: (
          <div
            css={css`
              padding-bottom: 30px;
            `}
          >
            <div
              css={css`
                text-align: center;
                margin-bottom: 10px;
              `}
            >
              <img
                src={hasCopyUrl}
                alt=""
                css={css`
                  width: 54px;
                `}
              />
              <div
                css={css`
                  margin-top: 10px;
                `}
              >
                {formatMessage(messageIds.apps.myApp.card.menu.hasCopiedLink)}
              </div>
            </div>
            <div
              css={css`
                display: inline-flex;
                width: 100%;
                text-align: center;
              `}
            >
              <Input
                value={shareUrl(app)}
                css={css`
                  width: 340px;
                  margin-left: 30px;
                  padding-right: 65px;
                `}
              />
              <Button
                type="primary"
                css={css`
                  margin-left: -65px;
                `}
                onClick={() => copyAppLink(app)}
              >
                {formatMessage(messageIds.common.copy)}
              </Button>
            </div>
          </div>
        ),
        footer: null,
      });
    },
    [Dialog, copyAppLink, formatMessage, shareUrl]
  );

  const openScanQrCode = useCallback(
    (app: AppV2 | ViCodeAppWithPermission) => {
      Dialog({
        title: formatMessage(messageIds.apps.myApp.card.menu.qrCodeShare),
        content: (
          <div
            css={css`
              text-align: center;
              padding-bottom: 20px;
            `}
          >
            <QRCode
              id={qrCodeId(app)}
              value={shareUrl(app)}
              size={200}
              css={css`
                margin: 10px;
              `}
            />
            <br />
            <Button
              type="primary"
              css={css`
                width: 200px;
              `}
              onClick={() => downloadQrCode(app)}
            >
              {formatMessage(messageIds.common.saveImage)}
            </Button>
          </div>
        ),
        footer: null,
      });
    },
    [Dialog, downloadQrCode, formatMessage, qrCodeId, shareUrl]
  );

  const openSendEmail = useCallback(
    (app: AppV2 | ViCodeAppWithPermission) => {
      emailForm.resetFields();
      emailForm.setFields([
        {
          name: "title",
          value: formatMessage(messageIds.apps.myApp.card.menu.emailTitle),
        },
      ]);
      emailForm.setFields([
        {
          name: "content",
          value: formatMessage(messageIds.apps.myApp.card.menu.emailContent, {
            shareUrl: shareUrl(app),
          }),
        },
      ]);
      Dialog({
        title: formatMessage(messageIds.apps.myApp.card.menu.emailShare),
        content: (
          <Form colon={false} layout="vertical" form={emailForm}>
            <Form.Item
              label={formatMessage(
                messageIds.apps.myApp.card.menu.recevierEmail
              )}
              name="receiverEmail"
              rules={[
                {
                  required: true,
                  message: formatMessage(messageIds.common.required),
                },
                {
                  type: "email",
                  validateTrigger: "onBlur",
                },
              ]}
            >
              <Input
                placeholder={formatMessage(messageIds.common.placeholder)}
              />
            </Form.Item>
            <Form.Item
              label={formatMessage(messageIds.common.title)}
              name="title"
              rules={[
                {
                  required: true,
                  message: formatMessage(messageIds.common.required),
                },
              ]}
            >
              <Input
                // disabled={true}
                placeholder={formatMessage(messageIds.common.placeholder)}
              />
            </Form.Item>
            <Form.Item
              label={formatMessage(messageIds.common.content)}
              name="content"
              rules={[
                {
                  required: true,
                  message: formatMessage(messageIds.common.required),
                },
              ]}
            >
              <TextArea
                // disabled={true}
                placeholder={formatMessage(messageIds.common.placeholder)}
                rows={10}
              />
            </Form.Item>
          </Form>
        ),
        onOk: async () => {
          const { title, content, receiverEmail } =
            await emailForm.validateFields();

          await environmentModel.actions.sendEmail.dispatch({
            title,
            content,
            receiverEmail,
          });
          notification.success({
            message: formatMessage(messageIds.common.operateSuccess),
          });
        },
      });
    },
    [
      Dialog,
      emailForm,
      environmentModel.actions.sendEmail,
      formatMessage,
      shareUrl,
    ]
  );

  const onUseApp = useCallback(
    (app: AppV2 | ViCodeAppWithPermission) => {
      applicationInsights.sendTrackEvent(
        { name: "Vicode_UseAppEvent" },
        {
          AppID: app.id,
        }
      );
      if (
        window._settings.appsPreviewDialog !== false &&
        (window._settings.engine === "dingtalk" ||
          window._settings.appsPreviewDialog)
      ) {
        dialogPreviewAppContainer.actions.setAppId.dispatch(app.id);
        dialogPreviewAppContainer.actions.setOpen.dispatch(true);
      } else {
        window.open(shareUrl(app));
      }
    },
    [
      applicationInsights,
      dialogPreviewAppContainer.actions.setAppId,
      dialogPreviewAppContainer.actions.setOpen,
      shareUrl,
    ]
  );

  const onFavorite = useCallback(
    async (app: AppV2 | ViCodeAppWithPermission) => {
      if (app.isFavorite) {
        await appsHelper.actions.deleteFavorite.dispatch({ appId: app.id });
      } else {
        await appsHelper.actions.addFavorite.dispatch({ appId: app.id });
      }
      await appsHelper.actions.readById.dispatch({
        id: app.id,
      });
    },
    [
      appsHelper.actions.addFavorite,
      appsHelper.actions.deleteFavorite,
      appsHelper.actions.readById,
    ]
  );

  const getMenuItems: (
    rowData: AppV2 | ViCodeAppWithPermission
  ) => TableOperationMenuItem[] = useCallback(
    (rowData) => {
      const menuItems: TableOperationMenuItem[] = [
        {
          key: "use",
          name: formatMessage(messageIds.apps.myApp.useApp),
          onClick: () => onUseApp(rowData),
        },
        {
          key: "favorite",
          name: formatMessage(
            rowData.isFavorite
              ? messageIds.apps.myApp.unfavorite
              : messageIds.apps.myApp.favorite
          ),
          onClick: () => onFavorite(rowData),
          visible: app.permissionValues?.includes("App_Favorite"),
        },
        {
          key: "share_link",
          name: formatMessage(messageIds.apps.myApp.card.menu.linkShare),
          onClick: () => openCopyLink(rowData),
          visible: app.permissionValues?.includes("App_Share"),
        },
        {
          key: "share_qr",
          name: formatMessage(messageIds.apps.myApp.card.menu.qrCodeShare),
          onClick: () => openScanQrCode(rowData),
          visible: app.permissionValues?.includes("App_Share"),
        },
        {
          key: "share_email",
          name: formatMessage(messageIds.apps.myApp.card.menu.emailShare),
          onClick: () => openSendEmail(rowData),
          visible: app.permissionValues?.includes("App_Share"),
        },
      ];
      return menuItems;
    },
    [
      app.permissionValues,
      formatMessage,
      onFavorite,
      onUseApp,
      openCopyLink,
      openScanQrCode,
      openSendEmail,
    ]
  );

  const menuList = useMemo(() => getMenuItems(app), [app, getMenuItems]);
  return menuList;
};

export const AppMenuItem = React.memo(function (props: {
  app: AppV2 | ViCodeAppWithPermission;
  menuName?: ReactNode;
  menuKey: TableOperationMenuItem["key"];
  icon?: ReactNode;
  className?: string;
}) {
  const theme = useTheme();
  const { app, menuName, menuKey, icon, className } = props;
  const menuList = useAppMenuList({ app });
  const menuItem = menuList.find((item) => menuKey === item.key);

  return menuItem?.visible ?? menuItem?.visible === undefined ? (
    <Button
      onClick={
        menuItem?.onClick as React.MouseEventHandler<HTMLElement> | undefined
      }
      css={css`
        :hover {
          color: ${theme.primaryColor};
        }
      `}
      className={className}
    >
      {icon}
      {menuName ?? menuItem?.name}
    </Button>
  ) : null;
});

export default React.memo(function AppMenu(props: {
  app: AppV2 | ViCodeAppWithPermission;
  icon?: ReactNode;
  menuKeys?: TableOperationMenuItem["key"][];
}) {
  const { app, icon, menuKeys } = props;
  const menuList = useAppMenuList({ app });
  const _menuList = menuList.filter((item) => menuKeys?.includes(item.key));

  return (
    <TableOperation menuList={menuKeys ? _menuList : menuList} icon={icon} />
  );
});
