// eslint-disable-next-line import/no-anonymous-default-export
export default {
  dataConnection: {
    test: "测试",
    introduction:
      "连接管理主要用户创建并管理各类数据连接器，建立与各类外部数据之间的连接，便于后续进行各类数据操作。",
    listFields: {
      name: "连接名称",
      dataType: "连接类型",
      databaseName: "数据库名称",
      env: "环境类型",
    },
    env: {
      productionEnvironmentType: "生产环境",
      ppeEnvironmentType: "PPE环境",
      developmentEnvironmentType: "开发环境",
    },
    testSuccessful: "测试成功",
    restfulAPIConfig: "API类连接模板配置",
    tip: {
      name: "请输入正确的连接名称",
    },
    searchPlaceholder: {
      type: "请选择连接类型",
    },
    associatedApp: "关联应用",
  },
};
