import { KnowledgeDetailUIModel } from "src/store/models/ui/knowledge/knowledgeDetail";
import { KnowledgeSideUIModel } from "src/store/models/ui/knowledge/knowledgeSide";
import { KnowledgeTableUIModel } from "src/store/models/ui/knowledge/knowledgeTable";
import { SmartChatUIModel } from "src/store/models/ui/knowledge/smartChat";
import { UploadFileUIModel } from "src/store/models/ui/knowledge/uploadFile";

export const encooKnowledgeUIModels = {
  knowledgeTable: KnowledgeTableUIModel,
  knowledgeSide: KnowledgeSideUIModel,
  KnowledgeDetail: KnowledgeDetailUIModel,
  smartChat: SmartChatUIModel,
  uploadFile: UploadFileUIModel,
};
