import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { PermissionSetting } from "src/models/dataPermission";

export const SERVICE_NAME = "DataPermissionV2";

export class DataPermissionServiceClientV2 extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, SERVICE_NAME);
  }

  // 1. 获取权限设置API
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async getPermissionConfig(resourceType: string, id: string) {
    const req = this.buildRequest({
      url: `/v2/permissions/${resourceType}/${id}`,
      method: "GET",
    });
    return await this.sendRequest(req);
  }

  // 2. 获取资源的权限模板API
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async getResourcePermissionTemplate(resourceType: string) {
    const req = this.buildRequest({
      url: `/v2/PermissionTemplates/${resourceType}/permissionitems`,
      method: "GET",
    });
    return await this.sendRequest(req);
  }

  // 3. 修改权限设置API
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async updatePermissionConfig(
    resourceType: string,
    id: string,
    data: PermissionSetting
  ) {
    const payload = data;
    const req = this.buildRequest({
      url: `/v2/permissions/${resourceType}/${id}`,
      method: "PATCH",
      payload,
    });
    return await this.sendRequest(req);
  }
}
