// eslint-disable-next-line import/no-anonymous-default-export
export default {
  dataTable: {
    entityTypeName: "数据表",
    name: "数据表",
    introduction: "数据表管理主要用户创建数据表，存储流程生成的数据。",
    dataTableName: "数据表名称",
    nameMaxLengthMessage: "名称长度不能超过50个字符",
    nameRegMessage: "名称无效，可能包含无效字符",
    descriptionMaxLengthMessage: "备注长度不能超过200个字符",
  },
};
