/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Table as AntdTable, Tooltip } from "antd";
import {
  ColumnType,
  TablePaginationConfig,
  TableProps as AntTableProps,
} from "antd/lib/table";
import { SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";
import React, { useCallback, useMemo } from "react";
import Icon from "src/components/Icon";
import { useTheme } from "src/hooks";
import { EncooSortOrder } from "src/models/_shared";
import { cssTextTruncate } from "src/styles/text";
import { reactMemo } from "src/utils/react";

const ExpandIcon = reactMemo(function ExpandIconContent(props: {
  className?: string;
}) {
  const { className } = props;
  const theme = useTheme();
  return (
    <Icon
      className={className}
      name="icon-next-page"
      css={css`
        font-size: 8px;
        color: ${theme.primaryColor};
        cursor: pointer;
      `}
    />
  );
});

export type TableProps<T> = Omit<AntTableProps<T>, "onChange"> & {
  overrideColumnDescription?: boolean;
  onSort?: (columnName?: string | number, sortOrder?: EncooSortOrder) => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default reactMemo(function Table<T extends object>(
  props: TableProps<T>
) {
  const {
    className,
    columns,
    dataSource,
    rowKey = "id",
    pagination,
    expandable,
    onSort,
    overrideColumnDescription = true,
    ...restProps
  } = props;

  const theme = useTheme();
  const internalPagination = useMemo(() => {
    if (!pagination) return false;
    return {
      ...pagination,
    };
  }, [pagination]);

  const expandableBase = useMemo(
    () => ({
      expandIcon: (payload: {
        expanded: boolean;
        onExpand: (record: T, event: React.MouseEvent<HTMLElement>) => void;
        record: T;
        expandable: boolean;
      }) => {
        const { expanded, onExpand, record, expandable } = payload;
        if (!expandable) {
          return (
            <ExpandIcon
              css={css`
                color: ${theme.bodyFrameDivider};
              `}
            />
          );
        }
        return expanded ? (
          <div onClick={(e) => onExpand(record, e)}>
            <ExpandIcon
              css={css`
                transform: rotate(90deg);
              `}
            />
          </div>
        ) : (
          <div onClick={(e) => onExpand(record, e)}>
            <ExpandIcon
              css={css`
                color: ${theme.bodyText};
              `}
            />
          </div>
        );
      },
    }),
    [theme.bodyFrameDivider, theme.bodyText]
  );

  const internalExpandable = useMemo(
    () =>
      expandable
        ? {
            ...expandableBase,
            ...expandable,
          }
        : {},
    [expandable, expandableBase]
  );

  const internalColumns = useMemo(() => {
    return columns?.map((item) => {
      if (
        (item as ColumnType<T>).dataIndex === "description" &&
        overrideColumnDescription
      ) {
        return {
          ...item,
          ellipsis: true,
          render: (text?: string) => {
            const span = (
              <span
                css={css`
                  display: inline-block;
                  max-width: 200px;
                  ${cssTextTruncate}
                `}
              >
                {text ? text : "-"}
              </span>
            );

            return text ? <Tooltip title={text}>{span}</Tooltip> : span;
          },
        };
      }
      return item;
    });
  }, [columns, overrideColumnDescription]);

  const onChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, (React.Key | boolean)[] | null>,
      sorterItemOrArray: SorterResult<T> | SorterResult<T>[],
      extra: TableCurrentDataSource<T>
    ) => {
      if (extra.action === "sort") {
        const sorter = Array.isArray(sorterItemOrArray)
          ? sorterItemOrArray[0]
          : sorterItemOrArray;

        const field = Array.isArray(sorter.field)
          ? sorter.field[0]
          : sorter.field;
        onSort?.(
          sorter.order === undefined ? undefined : field,
          sorter.order ?? undefined
        );
      }
    },
    [onSort]
  );

  return (
    <AntdTable<T>
      className={className}
      columns={internalColumns}
      dataSource={dataSource}
      rowKey={rowKey}
      pagination={internalPagination}
      expandable={internalExpandable}
      onChange={onChange}
      {...restProps}
      css={css`
        table {
          table-layout: auto !important;
        }
        .ant-table-row-expand-icon-cell {
          width: 62px;
        }
        .ant-btn-link {
          padding: 0;
          font-size: 12px;
        }
        .ant-table-column-sorters {
          padding: 0;
        }
        .ant-table-thead > tr > th,
        .ant-table-tbody > tr > td {
          color: ${theme.bodyText};
        }
        .ant-table-thead > tr > th {
          padding: 10px 13px;
          height: 40px;
          line-height: 1.4;
          min-width: 80px;
          font-weight: 500;
          background: #f7faff;
        }
        .ant-table-tbody > tr.ant-table-row:hover > td {
          background-color: ${theme.bodyFrameBackground};
        }
        .ant-table-tbody > tr {
          height: 48px;
        }
        .ant-table-tbody > tr > td {
          padding: 8px 12px;
          font-weight: 400;
          font-size: 12px;
          border-bottom: 1px solid ${theme.tableDivider};
        }
        .ant-table-tbody > tr:not(.selectedRow) > td {
          background-color: ${theme.bodyBackground};
        }
        .ant-table-tbody {
          font-size: 12px;
        }
        .ant-table-tbody .selectedRow {
          position: relative;
          padding: 10px 13px;
          background: ${theme.bodyFrameBackground};
        }
        .selectedRow > td:nth-of-type(1)::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0px;
          width: 2px;
          height: 100%;
          background-color: ${theme.primaryColor};
        }
        .tr.ant-table-expanded-row > td,
        .tr.ant-table-expanded-row:hover > td {
          background: ${theme.bodyBackground};
        }
      `}
    />
  );
});
