import {
  Constants,
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { EncooListEntity } from "src/models/_shared";
import {
  CreateKnowledge,
  Knowledge,
  KnowledgeQuery,
} from "src/models/knowledge";

export const KNOWLEDGE_SERVICE_NAME = "knowledge";
export class KnowledgeServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, KNOWLEDGE_SERVICE_NAME);
  }

  async list(
    pageIndex: number,
    pageSize: number,
    query?: KnowledgeQuery
  ): Promise<EncooListEntity<Knowledge>> {
    const req = this.buildRequestV2({
      url: `/v2/lamoss/KnowledgeBase`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
        searchString: query?.searchString,
        startTime: query?.startTime,
        endTime: query?.endTime,
        orderByExpression: query?.orderByExpression,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getById(knowledgeId: string): Promise<Knowledge> {
    const req = this.buildRequestV2({
      url: `/v2/lamoss/KnowledgeBase/${knowledgeId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async create(payload: CreateKnowledge): Promise<Knowledge> {
    const req = this.buildRequestV2({
      url: `/v2/lamoss/KnowledgeBase`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  async update(
    knowledge: Partial<Knowledge> & Pick<Knowledge, "id">
  ): Promise<Knowledge> {
    const req = this.buildRequestV2({
      url: `/v2/lamoss/KnowledgeBase/${knowledge.id}`,
      method: "PATCH",
      payload: knowledge,
    });
    if (knowledge.eTag) {
      req.headers?.set(Constants.HeaderConstants.IF_MATCH, knowledge.eTag);
    }
    return await super.updateInternal(req);
  }

  async delete(knowledgeId: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/lamoss/KnowledgeBase/${knowledgeId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }

  async uploadFile(payload: {
    id: string;
    fileName: string;
    lastModifiedAt?: string;
    overwrite?: boolean;
    fileData: File;
  }) {
    const req = this.buildRequestV2({
      url: `/v2/lamoss/KnowledgeBase/${payload.id}/files/upload`,
      method: "POST",
      query: {
        fileName: payload.fileName,
        lastModifiedAt: payload?.lastModifiedAt,
        overwrite: payload.overwrite,
      },
      headers: {
        "Content-Type": "application/octet-stream",
      },
      payload: payload.fileData,
    });

    const { body, status } = await this.sendRequest(req);
    return { body, status };
  }

  async deleteFile(knowledgeId: string, fileId: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/lamoss/KnowledgeBase/${knowledgeId}/files/${fileId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }
}
