type EventListener = () => void;
type EventName = "loadAppInsights";
export default class EventEmitter {
  events: Map<EventName, EventListener[]>;

  constructor() {
    this.events = new Map();
  }

  addListener(eventName: EventName, listener: EventListener): void {
    const listeners = this.events.get(eventName) || [];
    listeners.push(listener);
    this.events.set(eventName, listeners);
  }

  removeListener(eventName: EventName): void {
    this.events.delete(eventName);
  }

  emit(eventName: EventName): void {
    this.events.get(eventName)?.forEach((callback) => callback());
  }

  clear(): void {
    this.events = new Map();
  }
}

export const eventEmitter = new EventEmitter();
