import { HttpRequest, HttpResponse, Middleware } from "@encoo-web/encoo-lib";
import { NextMiddleware } from "@encoo-web/encoo-lib/types/http/nextMiddleware";
import { AxiosError } from "axios";
import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";

export type ErrorActionType = (res: AxiosError) => void;
export class ErrorMiddleware implements Middleware {
  errorAction?: ErrorActionType;
  public async sendRequest(
    httpRequest: HttpRequest,
    next: NextMiddleware
  ): Promise<HttpResponse> {
    try {
      return await next.process();
    } catch (e) {
      const error = e as { ishandled?: boolean };
      setTimeout(() => {
        if (!error.ishandled) {
          this.errorAction && this.errorAction(error as AxiosError);
          return;
        }
      }, 0);
      throw error;
    }
  }
  public setErrorAction(errorAction?: ErrorActionType): void {
    this.errorAction = errorAction;
  }
}

export const ErrorUIModel = createModel(
  class extends ModelBase {
    public effects() {
      return {
        registerError: async (payload: { errorAction: ErrorActionType }) => {
          const { errorAction } = payload;
          this.dependencies.httpMiddleware.errorHandle.setErrorAction(
            errorAction
          );
        },
      };
    }
  }
);
