/** @jsxImportSource @emotion/react */
import { PlusOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { message, Radio } from "antd";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Button from "src/components/Button";
import Icon from "src/components/Icon";
import Modal from "src/components/Modal";
import { VICODE_ICONS } from "src/containers/vicodeApp/components/vicodeAppIconConstants";
import { APPV1_ICONS } from "src/containers/vicodeApp/components/vicodeAppIconV1Constants";
import { useFormatMessage, useTheme } from "src/hooks";
import messageIds from "src/locales/messageIds";

type ViCodeAppIconSelectVersionType = "v1" | "v2";

export const AppLocalIconSelect = React.memo(function (props: {
  onChange?: (val?: string) => void;
  selectedIcon?: string;
  version?: ViCodeAppIconSelectVersionType;
}) {
  const theme = useTheme();

  const { onChange, selectedIcon, version = "v2" } = props;

  const icons = useMemo(
    () => (version === "v2" ? VICODE_ICONS : APPV1_ICONS),
    [version]
  );

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 378px;
        height: 320px;
        overflow: auto;
        border: 1px solid ${theme.bodyDivider};
        padding: 10px 0 10px 10px;
      `}
    >
      {icons?.map((iconUrl) => (
        <Button
          css={css`
            position: relative;
            width: 48px;
            height: 48px;
            margin: 0px 10px 10px 0px;
            padding: 0;
            border-radius: 2px;
            overflow: hidden;

            &:hover,
            &:active,
            &:focus {
              border-color: ${theme.defaultColor};
            }
          `}
          key={iconUrl}
          onClick={() => onChange?.(iconUrl)}
        >
          <img
            css={css`
              width: 48px;
              height: 48px;
            `}
            src={iconUrl}
            alt=""
          />

          {(selectedIcon === iconUrl || selectedIcon === "." + iconUrl) && (
            <div
              css={css`
                position: absolute;
                display: flex;
                top: 0px;
                left: 0px;
                width: 48px;
                height: 48px;
                background-color: rgba(0, 0, 0, 0.5);
              `}
            >
              <Icon
                css={css`
                  font-size: 30px;
                  margin: auto;
                  color: #ffffff;
                `}
                name={"icon-check"}
              />
            </div>
          )}
        </Button>
      ))}
    </div>
  );
});

export const AppIconSelect = React.memo(function AppIconSelectDialog(props: {
  onChange?: (val?: string | File) => void;
  initialValue?: string;
  visible: boolean;
  onCloseModal: () => void;
  version?: ViCodeAppIconSelectVersionType;
}) {
  const {
    onChange,
    initialValue,
    visible,
    onCloseModal,
    version = "v2",
  } = props;

  const theme = useTheme();
  const formatMessage = useFormatMessage();

  const options = useMemo(() => {
    const _options = [
      {
        label: formatMessage(messageIds.apps.appIcon.systemIcon),
        value: "local",
      },
      {
        label: formatMessage(messageIds.apps.appIcon.uploadIcon),
        value: "api",
      },
    ];

    return _options;
  }, [formatMessage]);

  const [iconSource, setIconSource] = useState<"api" | "local">("local");

  const [fileInfo, setFileInfo] = useState<File | null>(null);
  const [selectedLocalIcon, setSelectedLocalIcon] = useState<
    string | undefined
  >(undefined);

  const [imageReaderStr, setImageReaderStr] = useState<string | undefined>(
    undefined
  );

  const iconUploadInputRef = useRef<HTMLInputElement>(null);

  const onOk = useCallback(() => {
    if (iconSource === "local") {
      onChange?.(selectedLocalIcon);
    } else if (iconSource === "api") {
      if (!fileInfo) {
        message.info(formatMessage(messageIds.apps.appIcon.toUploadIcon));
        return;
      }
      onChange?.(fileInfo);
    }
    onCloseModal();
  }, [
    fileInfo,
    formatMessage,
    iconSource,
    onChange,
    onCloseModal,
    selectedLocalIcon,
  ]);

  const onLocalIconChange = useCallback((val?: string) => {
    setSelectedLocalIcon(val);
  }, []);

  const onUploadIcon = useCallback(() => {
    iconUploadInputRef?.current?.click();
  }, []);

  const onApiIconChange = useCallback(
    (e) => {
      const file: File | undefined = e.target.files?.[0];
      if (!file) {
        setFileInfo(null);
      } else if (file?.size > 1024 * 1024) {
        message.info(formatMessage(messageIds.apps.appIcon.iconSizeTip));
      } else if (
        ["jpg", "jpeg", "png"].every((item) => !file?.type.includes(item))
      ) {
        message.info(formatMessage(messageIds.apps.appIcon.iconTypeTip));
      } else {
        setFileInfo(file);
      }
    },
    [formatMessage]
  );

  useEffect(() => {
    if (fileInfo) {
      const reader = new FileReader();
      reader.readAsDataURL(fileInfo);
      reader.onload = () => {
        setImageReaderStr(reader.result?.toString());
      };
    }
  }, [fileInfo]);

  useEffect(() => {
    if (initialValue?.startsWith("http")) {
      setIconSource("api");
      setImageReaderStr(initialValue);
      setSelectedLocalIcon(undefined);
    } else {
      setIconSource("local");
      setSelectedLocalIcon(initialValue);
      setFileInfo(null);
    }
  }, [initialValue, visible]);

  return (
    <Modal
      visible={visible}
      title={formatMessage(messageIds.apps.icon)}
      onOk={onOk}
      onCancel={onCloseModal}
      destroyOnClose={true}
      okDisabled={
        (iconSource === "api" && !fileInfo) ||
        (iconSource === "local" && !selectedLocalIcon)
      }
    >
      <Radio.Group
        options={options}
        onChange={(e) => setIconSource(e.target.value)}
        value={iconSource}
        optionType="button"
        buttonStyle="solid"
        css={css`
          display: flex;
          margin-bottom: 20px;
          .ant-radio-button-wrapper {
            flex-grow: 1;
            text-align: center;
          }
          .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            background-color: ${theme.primaryColor};
          }
        `}
      />

      <div
        css={css`
          display: flex;
          justify-content: center;
        `}
      >
        {iconSource === "local" && (
          <AppLocalIconSelect
            onChange={onLocalIconChange}
            selectedIcon={selectedLocalIcon}
            version={version}
          />
        )}
        {iconSource === "api" && (
          <div
            css={css`
              height: 320px;
              padding-top: 68px;
              display: flex;
              flex-direction: column;
              align-items: center;
            `}
          >
            <input
              ref={iconUploadInputRef}
              type="file"
              onChange={onApiIconChange}
              css={css`
                display: none;
              `}
            />
            {imageReaderStr ? (
              <img
                onClick={onUploadIcon}
                src={imageReaderStr}
                alt=""
                css={css`
                  width: 72px;
                  height: 72px;
                  cursor: pointer;
                `}
              />
            ) : (
              <Button
                type="dashed"
                onClick={onUploadIcon}
                css={css`
                  height: 72px;
                  width: 72px;
                `}
              >
                <PlusOutlined
                  css={css`
                    font-size: 20px;
                    color: #8f9bb3;
                  `}
                />
              </Button>
            )}
            <div
              css={css`
                color: #8f9bb3;
                margin-top: 14px;
                text-align: center;
                p {
                  margin-bottom: 0;
                }
              `}
            >
              <p>{formatMessage(messageIds.apps.appIcon.iconSizeTip)}</p>
              <p>{formatMessage(messageIds.apps.appIcon.iconTypeTip)}</p>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
});

export default React.memo(function ViCodeAppIconSelectDialog(props: {
  onChange?: (val?: string | FormData) => void;
  initialValue?: string;
  disabled?: boolean;
  version?: ViCodeAppIconSelectVersionType;
}) {
  const { onChange, initialValue, disabled, version = "v2" } = props;
  const [iconModalVisible, setIconModalVisible] = useState(false);

  const [selectedIcon, setSelectedIcon] = useState<string | undefined>(
    undefined
  );

  const onOpenIconModal = useCallback(() => {
    if (!disabled) {
      setIconModalVisible(true);
    }
  }, [disabled]);

  useEffect(() => {
    setSelectedIcon(initialValue);
  }, [initialValue]);

  const _onChange = useCallback(
    (val?: string | File) => {
      if (typeof val === "object") {
        const reader = new FileReader();
        reader.readAsDataURL(val);
        reader.onload = () => {
          setSelectedIcon(reader.result?.toString());
        };

        const formData = new FormData();
        formData.append(val.name, val);
        onChange?.(formData);
      } else {
        onChange?.(val);
        setSelectedIcon(val);
      }
    },
    [onChange]
  );

  return (
    <div>
      <Fragment>
        {selectedIcon ? (
          <img
            onClick={onOpenIconModal}
            src={selectedIcon[0] === "." ? selectedIcon.slice(1) : selectedIcon}
            alt=""
            css={css`
              width: 72px;
              height: 72px;
              cursor: pointer;
            `}
          />
        ) : (
          <Button
            type="dashed"
            onClick={() => setIconModalVisible(true)}
            css={css`
              padding: 12px 13px;
            `}
            disabled={disabled}
          >
            <PlusOutlined
              css={css`
                font-size: 20px;
              `}
            />
          </Button>
        )}
      </Fragment>
      <AppIconSelect
        visible={iconModalVisible}
        onCloseModal={() => setIconModalVisible(false)}
        onChange={_onChange}
        initialValue={selectedIcon}
        version={version}
      />
    </div>
  );
});
