import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import {
  AiAccountService,
  AiBalanceWarningValue,
  AiServiceCalledCount,
  AiServiceInfo,
  AiServiceType,
  InvokeInfo,
  InvokeService,
  KeySchema,
  ServiceAndCount,
  ServiceCreationData,
} from "src/models/ocr";

export const OCR_SERVICE_NAME = "ocr";
export class OCRService extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, OCR_SERVICE_NAME);
  }

  //获取AI服务类型列表
  async getServiceTypes(
    aiCategory: string,
    name?: string
  ): Promise<AiServiceType[]> {
    const req = this.buildRequestV2({
      url: `/v2/aiServices/${aiCategory}`,
      method: "GET",
      query: {
        name: name,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  //获取Ai服务列表
  async getAiService(
    aiCategory: string,
    alias: string
  ): Promise<AiServiceInfo[]> {
    const req = this.buildRequestV2({
      url: `/v2/aiServices/${aiCategory}/${alias}`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  //获取KeySchema
  async getKeySchema(): Promise<KeySchema[]> {
    const req = this.buildRequestV2({
      url: `/v2/aiServices/keySchema`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  //获取用户服务调用Dashboard
  async getDashboard(
    BeforeTime: string,
    EndTime: string
  ): Promise<AiServiceCalledCount[]> {
    const req = this.buildRequestV2({
      url: `/v2/invoking/dashboard`,
      method: "GET",
      query: {
        BeforeTime,
        EndTime,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  //获取用户账户服务
  async getAiAccountServices(
    pageIndex: number,
    pageSize: number
  ): Promise<ServiceAndCount> {
    const req = this.buildRequestV2({
      url: `/v2/aiAccountServices`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  //获取用户账户详情
  async getDetailById(accountServiceId: string): Promise<AiAccountService> {
    const req = this.buildRequestV2({
      url: `/v2/aiAccountServices/${accountServiceId}`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  //新增用户账号服务
  async create(payload: ServiceCreationData): Promise<AiAccountService> {
    const req = this.buildRequestV2({
      url: `/v2/aiAccountServices`,
      method: "POST",
      payload: payload,
    });
    return await super.createInternal(req);
  }

  //修改用户账号服务
  async update(
    accountServiceId: string,
    payload: ServiceCreationData
  ): Promise<AiAccountService> {
    const req = this.buildRequestV2({
      url: `/v2/aiAccountServices/${accountServiceId}`,
      method: "PUT",
      payload: payload,
    });
    return await super.updateInternal(req);
  }

  //删除用户账号服务
  async delete(accountServiceId: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/aiAccountServices/${accountServiceId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }

  //调用用户账号服务
  async invoke(payload: InvokeService): Promise<InvokeInfo> {
    const { aiCategory, platform, serviceType, version, imgBase64 } = payload;
    const req = this.buildRequestV2({
      url: `/v2/invoking/${aiCategory}/${platform}/${serviceType}/${version}`,
      method: "POST",
      payload: {
        ImgBase64: imgBase64,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  // 获取余额通知数据
  async getBalanceWarningConfig(
    payload: AiBalanceWarningValue
  ): Promise<AiBalanceWarningValue> {
    const req = this.buildRequestV2({
      url: `/v2/ainotificationsettings`,
      method: "GET",
      payload,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  // 更新余额通知
  async updateBalanceWarningConfig(
    payload: AiBalanceWarningValue
  ): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/ainotificationsettings`,
      method: "PUT",
      payload,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
}
