// eslint-disable-next-line import/no-anonymous-default-export
export default {
  personal: {
    myOrder: {
      name: "我的订单",
      version: {
        personal: "个人专业版",
        free: "个人免费版",
        sass: "企业SASS版",
        custom: "企业私有化定制版",
      },
      status: {
        new: "订单已创建",
        awaitingPayment: "待付款",
        paid: "已付款",
        processing: "订单处理中",
        shipped: "订单已发货",
        completed: "已支付",
        canceled: "已取消",
        refunding: "待退款",
        refunded: "已退款",
        refundFailed: "退款失败",
        timeout: "订单超时",
        payed: "已支付",
        unPay: "未支付",
        awaitPay: "待支付",
      },
      label: {
        orderInfo: "订单详情",
        invoicing: "开票",
        invoicingAmount: "开票金额",
        validity: "有效期至",
        dateRange: "有效期",
        orderRecord: "订单记录",
        orderId: "订单号",
        wxOrder: "微信支付单号",
        version: "版本",
        productType: "商品类型",
        orderType: "规格",
        product: "产品",
        count: "数量",
        robotCount: "机器人数量",
        licenseValid: "许可证有期限",
        robotInfo: "机器人详情",
        time: "时间",
        status: "状态",
        serialId: "序号",
        licenseId: "许可证编号",
        orderStatus: "订单状态",
        orderPrice: "实付金额",
        discountCode: "优惠码",
        discountPrice: "优惠价",
        discountDeduction: "优惠抵扣",
        payTime: "支付时间",
        orderTime: "下单时间",
        validTime: "有效期",
        originalPrice: "原价",
        invoiceType: "发票类型",
        invoiceOptions: "发票选项",
        invoiceAmount: "发票金额",
        invoiceTitle: "发票抬头",
        invoiceContent: "发票内容",
        email: "联系邮箱",
        operation: "操作",
        vipSupport: "VIP技术支持",
      },
      buttons: {
        renew: "续费",
        invoicing: "开具发票",
        invoicingRecord: "开票记录",
        personalVersion: "升级至个人专业版",
        enterpriseVersion: "升级至企业版",
        info: "详情",
        cancel: "取消",
        pay: "支付",
        refund: "退款",
        cancelOrder: "取消订单",
      },
      tips: {
        cancelOrder: "请确定是否取消该订单",
        invoicing: "如需开票，请加VIP技术支持群联系管理员",
        joinUs: "欢迎加入",
        vipGroup: "VIP技术支持交流群",
        invoicingInstructions: "我已阅读并同意《开票须知 》",
        contactUs: {
          name: "请联系我们",
          email: "邮箱",
          tel: "电话",
        },
        refund: {
          name: "退款申请",
          label: "请加企微客服，并提供支付订单号或微信支付",
        },
      },
    },
  },
};
