import { createModel } from "nyax";
import { AppTemplate } from "src/models/appTemplate";
import { AppTemplateEntityModel } from "src/store/models/entity/appTemplate/entity";
import { AppTemplateListModel } from "src/store/models/entity/appTemplate/list";
import { createHelperModel } from "src/store/models/entity/_shared";

export const AppTemplateHelperModel = createModel(
  class extends createHelperModel<AppTemplate>({
    setItems: (getContainer, items) =>
      getContainer(AppTemplateEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(AppTemplateEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(AppTemplateEntityModel).state.byId[id],
    refreshList: (getContainer) =>
      getContainer(AppTemplateListModel).actions.refresh.dispatch({}),
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(AppTemplateEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),

        getById: async (payload: {
          id: string;
          versionId: string;
          force?: boolean;
        }) => {
          const { id, force = true } = payload;
          return await this._readById({
            id,
            getByIdAction: async () => {
              const item =
                await this.dependencies.serviceClient.appTemplate.getById(id);
              return item;
            },
            force,
          });
        },
      };
    }
  }
);
