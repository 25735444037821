import { createModel } from "nyax";
import { Application, ApplicationQuery } from "src/models/application";
import { Knowledge } from "src/models/knowledge";
import { ApplicationEntityModel } from "src/store/models/entity/application/entity";
import { ApplicationHelperModel } from "src/store/models/entity/application/helper";
import { createUITableBaseModel } from "src/store/models/ui/_shared";

export const ApplicationUIModel = createModel(
  class extends createUITableBaseModel<Application, ApplicationQuery>({
    setItems: (getContainer, items) =>
      getContainer(ApplicationEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(ApplicationEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(ApplicationEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public initialState() {
      return {
        ...super.initialState(),
        editorVisible: false,
        knowledgeBaseData: [] as Knowledge[],
        webLink: undefined as string | undefined,
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setEditorVisible: (visible: boolean) => {
          this.state.editorVisible = visible;
        },
        setKnowledgeBaseData: (data: Knowledge[]) => {
          this.state.knowledgeBaseData = data;
        },
        setWebLink: (url: string | undefined) => {
          this.state.webLink = url;
        },
      };
    }
    public effects() {
      return {
        ...super.effects(),
        initial: async () => {
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query: ApplicationQuery
            ) =>
              this.dependencies.serviceClient.application.list(
                pageIndex,
                pageSize,
                query
              ),
          });
        },
        knowledgeBaseList: async () => {
          const data = await this.getContainer(
            ApplicationHelperModel
          ).actions.getKnowledgeBaseList.dispatch({
            pageIndex: 0,
            pageSize: 20,
          });

          if (data.count > 20) {
            const allData = await this.getContainer(
              ApplicationHelperModel
            ).actions.getKnowledgeBaseList.dispatch({
              pageIndex: 0,
              pageSize: data.count,
            });
            this.actions.setKnowledgeBaseData.dispatch(allData.list);
          } else {
            this.actions.setKnowledgeBaseData.dispatch(data.list);
          }
        },

        getWebLink: async (id: string) => {
          const { url } = await this.getContainer(
            ApplicationHelperModel
          ).actions.getKnowledgeBaseStartUrl.dispatch(id);
          this.actions.setWebLink.dispatch(url);
        },
      };
    }
  }
);
