// eslint-disable-next-line import/no-anonymous-default-export
export default {
  apps: {
    name: "Application Name",
    icon: "Application Icon",
    introduction:
      "Application management is used to create and manage applications, manage application versions, and mount or remove applications in a unified manner.",
    workbench: "Application",
    currentVersion: "Current Enabled Version",
    versionDescription: "Version Notes",
    status: {
      all: "All status",
      enabled: "Enabled",
      disabled: "Disabled",
      notReady: "NotReady",
    },
    statusHandle: {
      enabled: "Enable",
      disabled: "Disable",
      initialize: "Initialize",
    },
    operation: {
      release: "Release",
      offline: "Offline",
    },
    version: "Version Number",
    tag: "Tag",
    inputTag: "Please enter tags",
    changeVersion: "Switch to this version",
    uploadDateTime: "Upload Time",
    creater: "Developer",
    device: "Application Scope",
    mobile: "Mobile",
    pc: "PC",
    try: "Trial",
    moreVersion: "View more versions",
    workflowName: "Flow Deployment Name",
    packageVersion: "Flow Package Version Number",
    appVersionsTitle: "Version List",
    currentSign: "Current",
    upgradeTip:
      "You are not using the latest version. Click to upgrade to {lastAvailableVersion} version.",
    upgradeConfirm: "Do you want to upgrade to {lastAvailableVersion} version?",
    upgradeBatchConfirm: "Are you sure you want to upgrade?",
    updateAppCurrentVersionConfirm:
      "Are you sure you want to switch the current application to this version?",
    deleteAppVersionMessage:
      "This version is currently in use and cannot be deleted. Please switch to other version before deleting the current one.",
    versionName: "Version",
    share: "Share",
    switchToVersion: "Switch to",
    myApp: {
      card: {
        menu: {
          copyAppLink: "Copy Link",
          linkShare: "Link Share",
          qrCodeShare: "QR Code Share",
          emailShare: "Email Share",
          copySuccess: "Copied",
          copyAppProject: "Copy",
          hasCopiedLink: "Link copied",
          recevierEmail: "Recipient Email",
          emailTitle: "Invite to Use the Application",
          emailContent:
            "Hello\r\n \t,You are invited to click the link and open the application: {shareUrl} \r\n\r\n Thanks",
        },
      },
      useApp: "Use",
      appsNotFoundText: "The application does not exist.",
      noAppsText: "You haven't added any applications yet.",
      introduction:
        "My application is mainly used by business personnel to view and use authorized applications.",
      noPermissionText:
        "Since you have not activated the permission, you cannot perform the operation.",
      notFoundText: "Click to return to the home page.",
      searchBoxPlaceholder: "Please input the application name.",
      favorite: "Add To Favorites",
      unfavorite: "Remove From Favorites",
    },
    appDev: {
      card: {
        menu: {
          copyAppProject: "Copy",
        },
      },
      introduction:
        "Application development is mainly used by developers to create applications through the no-code development function, and to edit, publish, and manage applications.",
      lastUpdate: "Last Update",
      lastVersion: "Current enabled version",
      addApp: "New",
      newAppName: "Application",
    },
    connector: {
      enableSuccessful: "Started",
      appsName: "Application Name",
      enableVersion: "Enable Version",
      mappingTip:
        "This page is used to configure the mapping relationship between the test connection and the production connection. Once the application is published, the system will automatically map the test connection in the data source to the production connection according to the mapping relationship configured by the current version of the application. When this version of the application is unpublished, the production connection in the data source will be automatically mapped to the test connection.",
      connectionMap: "Connection Mapping",
      enableApps: "Enable Application",
    },
    viewMode: {
      name: "Access Mode",
      anonymous: "Anonymous Access",
      notAnonymous: "Non-Anonymous Access",
      tip: "Tips: The access mode will not be modified after being chosen, please choose carefully.",
      nonAnonymousTip: "Non-anonymous access: requires user login;",
      anonymousTip: "Anonymous access: does not require user login.",
    },
    appDetail: {
      tabs: {
        basicInfo: "Basic Information",
        versionManage: "Version Management",
        dataPermission: "Data Permission Configuration",
      },
      openEditor: "Open the editor",
    },
    appType: {
      groupAppDescription:
        "Combined application: Combine multiple basic applications into one application",
      name: "Application Type",
      basicApp: "Basic Application",
      groupApp: "Combined Application",
      associatedApp: "Associated Application",
    },
    appInitConfig: {
      userSelect: {
        department: "Department",
        user: "User",
      },
      tips: {
        selectEnvironment: "Please select the running environment",
        selectConnection: "Please select connection",
      },
      connection: {
        devIntroduce:
          "In the development environment, users can edit application information and configuration, data sources, etc., as well as release and other versioning.",
        prodIntroduce:
          "In a production environment, once an application is created, it cannot be edited again, so choose carefully.",
        type: "Type",
        config: "Connection configuration",
        select: "Connection selection",
      },
      role: "Role",
      isInheritDepartment:
        "Is it controlled by the department permission configuration:",
      inheritTip:
        "If the selection is controlled by the department permissions configuration, the system will calculate the final permissions according to the custom permissions and the permissions configuration on the department.",
      hasSelected: "Selected",
      isIncludeSubDepartment: "Include subdepartments",
      initConfig: "Initialize",
      initSuccessful: "Configuration is successful",
      initSuccessfulProdTip:
        "The application has been configured successfully. Do you want to release and use it immediately?",
      initSuccessfulDevTip:
        "Application configuration successful, do you want to edit now?",
      tab: {
        environment: "Select the running environment",
        connection: "Connection configuration",
        permission: "Permission configuration",
      },
      appsInitialized: "The app has been initialized",
      noUserSelect: "The following roles do not select users",
    },
    appRelease: {
      release: "Release",
      releaseSuccessFully: "Release successFully",
      releaseApp: "Release app",
      currentAppVersion: "Current version",
      newAppVersion: "new version",
      versionErrorMessage: {
        required: "The version number cannot be empty",
        nextVersion:
          "The latest version number must be greater than the current version number.",
        patternError: "Please refer to xx.xx.xx for format errors.",
      },
    },
    appIcon: {
      systemIcon: "System icon",
      uploadIcon: "Upload icon",
      toUploadIcon: "Please upload icon",
      iconSizeTip: "The icon size cannot exceed 1 MB",
      iconTypeTip: "The type can be JPG, JPEG, PNG",
    },
  },
};
