import { CoeMyWorkDetailUIModel } from "src/store/models/ui/coeMyWork/coeMyWorkDetail";
import { CoeMyWorkHistoryUIModel } from "src/store/models/ui/coeMyWork/coeMyWorkHistory";
import { CoeMyWorkSideUIModel } from "src/store/models/ui/coeMyWork/coeMyWorkSide";
import { CoeMyWorkTableUIModel } from "src/store/models/ui/coeMyWork/coeMyWorkTable";

export const encooCoeMyWorkUIModels = {
  coeMyWorkTable: CoeMyWorkTableUIModel,
  coeMyWorkSide: CoeMyWorkSideUIModel,
  coeMyWorkDetail: CoeMyWorkDetailUIModel,
  coeMyWorkHistory: CoeMyWorkHistoryUIModel,
};
