import { createModel, createSelector } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { RunInstanceEntityModel } from "src/store/models/entity/runInstance/entity";
import { RunInstanceHelperModel } from "src/store/models/entity/runInstance/helper";

export const RunInstanceUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: this.containerKey!,
      };
    }

    public selectors() {
      return {
        runInstances: createSelector(
          () => this.getContainer(RunInstanceEntityModel).getters.items,
          (items) =>
            items
              .filter((item) => item.jobId === this.state.id)
              .sort((item1, item2) =>
                item2.createdAt === item1.createdAt
                  ? 0
                  : (item2.createdAt ?? "") > (item1.createdAt ?? "")
                  ? 1
                  : -1
              )
        ),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        requestRunInstanceByJobId: async () => {
          await this.getContainer(
            RunInstanceHelperModel
          ).actions.readByJobIds.dispatch({
            jobIds: [this.state.id],
            force: true,
          });
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
