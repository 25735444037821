import { initQyWechatConfig } from "@encoo-web/encoo-ui";
import { createModel, createSelector } from "nyax";
import { Company } from "src/models/company";
import { ModelBase } from "src/store/ModelBase";
import { CompanyListModel } from "src/store/models/entity/company/list";
import { RouterModel } from "src/store/models/router";
import { LocaleUIModel } from "src/store/models/ui/locale/locale";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";
import { loadScript } from "src/utils/loadScript";

export const GlobalSelectCompanyUIModel = createModel(
  class extends ModelBase {
    private get _localeUIContainer() {
      return this.getContainer(LocaleUIModel);
    }

    private get _loginUserUiContainer() {
      return this.getContainer(LoginUserUIModel);
    }

    private get _router() {
      return this.getContainer(RouterModel);
    }

    private get _companyListContainer() {
      return this.getContainer(CompanyListModel);
    }

    public initialState() {
      return {
        selectedGlobalComponyId: null as string | null,
      };
    }

    public selectors() {
      return {
        selectedGlobalCompony: createSelector(
          () => this._companyListContainer.getters.dataSource,
          () => this.state.selectedGlobalComponyId,
          (list, id): Company | null => {
            return list.find((item) => item.id === id) ?? null;
          }
        ),
        companyFrom: createSelector(
          (): Company | null => this.getters.selectedGlobalCompony,
          (company): string => {
            if (!company) {
              return "";
            }
            const companyFrom = company.properties?.From;
            return companyFrom ?? "";
          }
        ),
        canEdit: createSelector(
          (): string => this.getters.companyFrom,
          (companyFrom): boolean => {
            return companyFrom !== "QyWechat";
          }
        ),
      };
    }

    public reducers() {
      return {
        ...super.reducers(),

        setSelectedGlobalComponyId: (value: string | null) => {
          this.state.selectedGlobalComponyId = value;
        },
      };
    }

    public effects() {
      return {
        setSelectedGlobalComponyId: async (value: string | null) => {
          this.dependencies.httpMiddleware.companyHeader.setHeaderValue(
            value ?? ""
          );
        },
        switchCompany: async (payload: {
          id: string;
          originPathname?: string;
        }) => {
          const { id, originPathname } = payload;
          if (originPathname) {
            window.location.href = `${window.location.origin}${originPathname}?companyId=${id}`;
          } else {
            window.location.href = `${window.location.origin}?companyId=${id}`;
          }
        },
        initSelectedCompany: async () => {
          const currentRouteInfo = this._router.getters.currentRouteInfo;

          if (currentRouteInfo.type === "companyEntrySelect") {
            return;
          }

          if (this.state.selectedGlobalComponyId) {
            return;
          }
          const searchParams = currentRouteInfo.params as Record<
            string,
            string
          >;
          const companyId = searchParams.companyId;

          if (companyId) {
            await this.actions.setSelectedGlobalComponyId.dispatch(companyId);
            await this._loginUserUiContainer.actions.updateCurrentCompanyUser.dispatch(
              null
            );
            const companyFrom = this.getters.companyFrom;
            if (companyFrom === "QyWechat") {
              await Promise.all([
                loadScript("https://res.wx.qq.com/open/js/jweixin-1.2.0.js"),
                loadScript(
                  "https://open.work.weixin.qq.com/wwopen/js/jwxwork-1.0.0.js"
                ),
              ]);
            }
          } else {
            await this._router.actions.navigateByRouteInfo.dispatch({
              type: "companyEntrySelect",
              params: {},
            });
          }
        },
        initConfig: async () => {
          const currentCompany = this.getters.selectedGlobalCompony;
          if (currentCompany) {
            const currentCompanyId = currentCompany.companyId;
            const companyFrom = this.getters.companyFrom;
            if (companyFrom) {
              if (companyFrom === "QyWechat") {
                const ticket =
                  await this.dependencies.serviceClient.thirdIntegration.getQyWechatTicket(
                    currentCompanyId
                  );
                await initQyWechatConfig({
                  corpid: ticket.corpId,
                  agentid: ticket.agentId,
                  timestamp: ticket.timeStamp,
                  nonceStr: ticket.nonceStr,
                  signature: ticket.signature,
                  corpSignature: ticket.corpSignature,
                });
              }
            }
          }
        },
        initHandle: async () => {
          await this._loginUserUiContainer.actions.setCurrentCompanyUser.dispatch(
            null
          );
          await this._localeUIContainer.actions.initDefaultLanguage.dispatch(
            {}
          );
          const user = await this.dependencies.oidcClient.getOidcUser();
          if (user?.profile.external_companyid) {
            window.location.href = `${window.location.origin}?companyId=${user?.profile.external_companyid}`;
          }
        },
      };
    }
  }
);
