import { Moment } from "moment";
import { createModel } from "nyax";
import { RangeValue } from "rc-picker/es/interface";
import { ObjectType } from "src/models/auditlog";
import { UserInfo } from "src/models/userInfo";
import { ModelBase } from "src/store/ModelBase";
import { AuditlogListV2Model } from "src/store/models/entity/auditlog/listV2";

export const AuditlogUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        open: false,
        rangeValue: null as RangeValue<Moment> | null,
        allOptions: [] as ObjectType[],
        statusOptions: [] as ObjectType[],
        userOptions: [] as UserInfo[],
        fetching: false,
        auditObjectType: "",
        userId: "",
      };
    }

    public reducers() {
      return {
        setOpen: (value: boolean) => {
          this.state.open = value;
        },
        setRangeValue: (rangeValue: RangeValue<Moment>) => {
          this.state.rangeValue = rangeValue;
        },
        setStatusOptions: (value: ObjectType[]) => {
          this.state.statusOptions = value;
        },
        setFetching: (value: boolean) => {
          this.state.fetching = value;
        },
        setAuditObjectType: (value: string) => {
          this.state.auditObjectType = value;
        },
        setUserId: (value: string) => {
          this.state.userId = value;
        },
        setUserOptions: (value: UserInfo[]) => {
          this.state.userOptions = value;
        },
        setAllOptions: (value: ObjectType[]) => {
          this.state.allOptions = value;
        },
      };
    }

    public effects() {
      return {
        getObjectTypes: async (objectType?: string) => {
          const data =
            await this.dependencies.serviceClient.auditlogSearch.getObjectTypes(
              objectType
            );
          this.actions.setStatusOptions.dispatch(data);
          if (
            objectType === undefined ||
            objectType === "" ||
            objectType === null
          ) {
            this.actions.setAllOptions.dispatch(data);
          }
          this.actions.setFetching.dispatch(false);
        },

        getUserId: async (Keyword?: string) => {
          const data =
            await this.dependencies.serviceClient.auditlogSearch.getUserId(
              Keyword
            );
          this.actions.setUserOptions.dispatch(data.list);
          this.actions.setFetching.dispatch(false);
        },

        getlist: async () => {
          const payload = {
            beforetime: this.state.rangeValue?.[0]?.toISOString(),
            endtime: this.state.rangeValue?.[1]?.toISOString(),
            UserId: this.state.userId,
            AuditObjectType: this.state.auditObjectType,
          };
          await this.getContainer(
            AuditlogListV2Model
          ).actions.initialIterator.dispatch(payload);
        },
      };
    }
  }
);
