import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import {
  Company,
  CompanyConnectInfo,
  CompanyGroup,
  CompanyV2,
} from "src/models/company";

export const COMPANY_SERVICE_NAME = "company";

export class CompanyServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, COMPANY_SERVICE_NAME);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async getList() {
    const req = this.buildRequest({
      url: `/v2/companies`,
      method: "GET",
    });
    return super.listInternally<Company>(req);
  }

  // V2新的获取公司+租户合并过滤后列表的接口
  async getListV2(): Promise<CompanyV2> {
    const req = this.buildRequest({
      url: `v2/companies/companylist`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getCompanyGroup(): Promise<CompanyGroup> {
    const req = this.buildRequest({
      url: `/v2/Companies/companygroup`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getConnectionInfo(): Promise<CompanyConnectInfo> {
    const req = this.buildRequest({
      url: `/v2/companies/invite`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async joinParentCompany(payload: Partial<CompanyConnectInfo>): Promise<null> {
    const req = this.buildRequest({
      url: `/v2/companies/join`,
      method: "POST",
      payload,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async updateWechatOrganization() {
    const req = this.buildRequest({
      url: `/v1/qywechat/sync`,
      method: "GET",
    });
    await this.sendRequest(req);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async updateDingdingOrganization() {
    const req = this.buildRequest({
      url: `v1/dingtalk/IntraDingTalk`,
      method: "GET",
    });
    await this.sendRequest(req);
  }
}
