import customizedCustomer from "src/assets/customizedCustomer/zh-Hans/customizedCustomer";
import aiManagement from "src/locales/zh-CN/aiManagement";
import apps from "src/locales/zh-CN/apps";
import asset from "src/locales/zh-CN/asset";
import auditlog from "src/locales/zh-CN/auditlog";
import calendars from "src/locales/zh-CN/calendars";
import coeDataAnalysis from "src/locales/zh-CN/coeDataAnalysis";
import coeMyWork from "src/locales/zh-CN/coeMyWork";
import coeToDoWork from "src/locales/zh-CN/coeToDoWork";
import coeWork from "src/locales/zh-CN/coeWork";
import common from "src/locales/zh-CN/common";
import company from "src/locales/zh-CN/company";
import dashboard from "src/locales/zh-CN/dashboard";
import dataConnection from "src/locales/zh-CN/dataConnection";
import dataPermission from "src/locales/zh-CN/dataPermission";
import dataQueue from "src/locales/zh-CN/dataQueue";
import dataTable from "src/locales/zh-CN/dataTable";
import device from "src/locales/zh-CN/device";
import file from "src/locales/zh-CN/file";
import job from "src/locales/zh-CN/job";
import jobQueue from "src/locales/zh-CN/jobQueue";
import lamoss from "src/locales/zh-CN/lamoss";
import layout from "src/locales/zh-CN/layout";
import license from "src/locales/zh-CN/license";
import ocr from "src/locales/zh-CN/ocr";
import organization from "src/locales/zh-CN/organization";
import packageData from "src/locales/zh-CN/package";
import payment from "src/locales/zh-CN/payment";
import personal from "src/locales/zh-CN/personal";
import queue from "src/locales/zh-CN/queue";
import resourceGroup from "src/locales/zh-CN/resourceGroup";
import robot from "src/locales/zh-CN/robot";
import role from "src/locales/zh-CN/role";
import runInstance from "src/locales/zh-CN/runInstance";
import systemSetting from "src/locales/zh-CN/systemSetting";
import trigger from "src/locales/zh-CN/trigger";
import understanding from "src/locales/zh-CN/understanding";
import user from "src/locales/zh-CN/user";
import userCenter from "src/locales/zh-CN/userCenter";
import userManager from "src/locales/zh-CN/userManager";
import virtualMFA from "src/locales/zh-CN/virtualMFA";
import workflow from "src/locales/zh-CN/workflow";
import workflowLayout from "src/locales/zh-CN/workflowLayout";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...personal,
  ...payment,
  ...coeWork,
  ...coeToDoWork,
  ...coeMyWork,
  ...coeDataAnalysis,
  ...calendars,
  ...dataTable,
  ...workflowLayout,
  ...common,
  ...layout,
  ...packageData,
  ...role,
  ...robot,
  ...workflow,
  ...job,
  ...trigger,
  ...understanding,
  ...resourceGroup,
  ...queue,
  ...user,
  ...auditlog,
  ...apps,
  ...license,
  ...dashboard,
  ...runInstance,
  ...userCenter,
  ...asset,
  ...company,
  ...customizedCustomer,
  ...file,
  ...dataTable,
  ...dataQueue,
  ...jobQueue,
  ...organization,
  ...dataPermission,
  ...dataConnection,
  ...systemSetting,
  ...virtualMFA,
  ...userManager,
  ...device,
  ...ocr,
  ...aiManagement,
  ...lamoss,
};
