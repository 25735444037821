/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Tooltip } from "antd";
import { ColumnProps } from "antd/lib/table";
import { memoize } from "lodash";
import React, { Fragment, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import iconApp from "src/assets/app.png";
import appEditImage from "src/assets/apps/appEdit.svg";
import Button from "src/components/Button";
import DescriptionTip from "src/components/DescriptionTip";
import { useModal } from "src/components/Dialog";
import Icon from "src/components/Icon";
import List, { ListItem } from "src/components/List";
import Table from "src/components/Table";
import TableOperation from "src/components/TableOperation";
import Text from "src/components/Text";
import {
  useAppV1Operation,
  useAppV1Status,
  useAppV1Type,
} from "src/containers/apps/AppV1OperationDialog";
import AppTags from "src/containers/apps/components/AppTags";
import { VICODEAPP_ICON_DEFAULT } from "src/containers/vicodeApp/components/vicodeAppIconConstants";
import { APPV1_ICON_DEFAULT } from "src/containers/vicodeApp/components/vicodeAppIconV1Constants";
import useViCodeAppOperationMenus from "src/containers/vicodeApp/components/ViCodeAppOperation";
import { hasAppV2FeatureFlag } from "src/containers/vicodeApp/ViCodeApp";
import { useFormatMessage, useGetContainer, useTheme } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { AppStatus, AppTag, AppType, AppV2 } from "src/models/appV2";
import { ViCodeAppStatus } from "src/models/vicodeApp";
import { AppHelperModel } from "src/store/models/entity/apps/helper";
import { AppListModel } from "src/store/models/entity/apps/list";
import { ViCodeAppListModel } from "src/store/models/entity/vicodeApp/list";
import { GlobalSelectDepartmentModel } from "src/store/models/logic/globalSelectDepartment";
import { RouterModel } from "src/store/models/router";
import { AppsUIModel } from "src/store/models/ui/apps/apps";
import { UIViCodeAppAppModel } from "src/store/models/ui/vicodeApp/apps";
import { ViCodeAppWithPermission } from "src/store/models/ui/vicodeApp/_shared";
import { compareVersion, formatDate } from "src/utils/string";

export const useViCodeAppStatus = function (): Record<
  ViCodeAppStatus,
  string | undefined
> {
  const formatMessage = useFormatMessage();

  return useMemo(() => {
    return {
      [ViCodeAppStatus.Enabled]: formatMessage(messageIds.apps.status.enabled),
      [ViCodeAppStatus.Disabled]: formatMessage(
        messageIds.apps.status.disabled
      ),
    };
  }, [formatMessage]);
};

export default React.memo(function ViCodeAppList(props: {
  type: "list" | "table";
}) {
  const { type } = props;

  const formatMessage = useFormatMessage();
  const getContainer = useGetContainer();
  const theme = useTheme();

  const router = getContainer(RouterModel);
  const globalSelectDepartmentModel = getContainer(GlobalSelectDepartmentModel);

  const uiViCodeAppAppContainer = getContainer(UIViCodeAppAppModel);
  const appsV1UiContainer = getContainer(AppsUIModel);
  const appV1Helper = getContainer(AppHelperModel);
  const vicodeAppListContainer = getContainer(ViCodeAppListModel);
  const appV1ListContainer = getContainer(AppListModel);

  const appStatusRecord = useViCodeAppStatus();
  const appV1StatusRecord = useAppV1Status();
  const appV1Operation = useAppV1Operation();
  const Modal = useModal();

  const viCodeAppOperationMenus = useViCodeAppOperationMenus();
  const appTypeRecord = useAppV1Type();

  const currentRouteInfo = useSelector(() => router.getters.currentRouteInfo);

  const isViCodeAppTab = useSelector(
    () => uiViCodeAppAppContainer.state.isViCodeAppTab
  );

  const vicodeAppDataSource = useSelector(() =>
    vicodeAppListContainer.getters.dataSourceWithPermission.filter((item) =>
      item.permissionValues?.includes("App_Read")
    )
  );

  const appV1DataSource = useSelector(() =>
    appV1ListContainer.getters.dataSourceWithPermission.filter((item) =>
      item.permissionValues?.includes("App_Read")
    )
  );

  const vicodeAppListDataSource = useSelector(() =>
    vicodeAppListContainer.getters.listDataSourceWithPermission.filter((item) =>
      item.permissionValues.includes("App_Read")
    )
  );

  const appV1ListDataSource = useSelector(() =>
    appV1ListContainer.getters.listDataSourceWithPermission.filter((item) =>
      item.permissionValues.includes("App_Read")
    )
  );

  const vicodeAppPagination = useSelector(
    () => vicodeAppListContainer.getters.pagination
  );
  const vicodeAppListPagination = useSelector(
    () => vicodeAppListContainer.getters.listPagination
  );

  const appV1Pagination = useSelector(
    () => appV1ListContainer.getters.pagination
  );
  const appV1ListPagination = useSelector(
    () => appV1ListContainer.getters.listPagination
  );

  const selectedDepartmentId = useSelector(
    () => globalSelectDepartmentModel.state.selectedDepartmentId
  );

  const navigateToDetail = useMemo(
    () =>
      memoize((id: string, isViCodeApp?: boolean) => {
        return () => {
          router.actions.navigateByRouteInfo.dispatch({
            type: "appDetail",
            params: {
              id,
              appV2: hasAppV2FeatureFlag() ? "true" : "",
              isViCodeApp: isViCodeApp ? "true" : "",
            },
          });
        };
      }),
    [router.actions.navigateByRouteInfo]
  );

  useEffect(() => {
    if (selectedDepartmentId) {
      uiViCodeAppAppContainer.actions.initialize.dispatch({});
    }
  }, [
    appsV1UiContainer.actions.initializeRequest,
    selectedDepartmentId,
    uiViCodeAppAppContainer.actions.initialize,
  ]);

  const getHasPermission = useCallback((value: string[] | undefined) => {
    if (value) {
      return (
        value.includes("App_Launch") ||
        value.includes("App_Copy") ||
        value.includes("App_Delete")
      );
    }
    return false;
  }, []);

  const onUpgrade = useMemo(
    () =>
      memoize((app: AppV2) => {
        return () => {
          Modal.info({
            content: formatMessage(messageIds.apps.upgradeConfirm, {
              lastAvailableVersion: app.lastAvailableVersion ?? "",
            }),
            onOk: async () => {
              await appV1Helper.actions.enableApp.dispatch({
                appId: app.id,
                payload: {
                  launchVersionId: app.lastAvailableVersionId,
                },
              });
            },
          });
        };
      }),
    [Modal, appV1Helper.actions.enableApp, formatMessage]
  );

  const _columns: ColumnProps<ViCodeAppWithPermission | AppV2>[] = useMemo(
    () => [
      {
        title: formatMessage(messageIds.apps.name),
        dataIndex: "name",
        render: (text, rowData) => (
          <div>
            <img
              src={
                rowData.icon ||
                (!("anonymous" in rowData)
                  ? VICODEAPP_ICON_DEFAULT
                  : APPV1_ICON_DEFAULT)
              }
              alt=""
              css={css`
                width: 40px;
                margin-right: 17px;
              `}
            />
            <Button
              type="link"
              onClick={navigateToDetail(rowData.id, !("anonymous" in rowData))}
            >
              {text}
            </Button>
          </div>
        ),
      },

      {
        title: formatMessage(messageIds.apps.appType.name),
        dataIndex: "appType",
        render: (text?: AppType) => (text ? appTypeRecord[text] : "-"),
      },
      {
        title: formatMessage(messageIds.apps.viewMode.name),
        dataIndex: "anonymous",
        render: (text?: boolean) =>
          text === undefined
            ? "-"
            : text
            ? formatMessage(messageIds.apps.viewMode.anonymous)
            : formatMessage(messageIds.apps.viewMode.notAnonymous),
      },
      {
        title: formatMessage(messageIds.common.status),
        dataIndex: "status",
        render: (text: ViCodeAppStatus | AppStatus) => (
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <div
              css={css`
                width: 6px;
                height: 6px;
                margin-right: 4px;
                border-radius: 3px;
                background-color: ${text === ViCodeAppStatus.Enabled ||
                text === "Enabled"
                  ? theme.statusEnabledColor
                  : theme.statusDisabledColor};
              `}
            />
            <Text>
              {appStatusRecord[text as ViCodeAppStatus] ??
                appV1StatusRecord[text as AppStatus]}
            </Text>
          </div>
        ),
      },
      {
        title: formatMessage(messageIds.apps.appDev.lastVersion),
        dataIndex: "currentVersion",
        render: (text, rowData) =>
          !("anonymous" in rowData) ? (
            "-"
          ) : (
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <Text>{rowData.status === "Enabled" ? text : ""}</Text>
              {rowData.status === "Enabled" &&
                rowData.permissionValues?.includes("App_Launch") &&
                rowData.lastAvailableVersion &&
                rowData.currentVersion &&
                compareVersion(
                  rowData.lastAvailableVersion,
                  rowData.currentVersion
                ) > 0 && (
                  <Tooltip
                    placement="top"
                    title={formatMessage(messageIds.apps.upgradeTip, {
                      lastAvailableVersion: rowData.lastAvailableVersion,
                    })}
                  >
                    <Button
                      css={css`
                        padding-left: 4px !important;
                      `}
                      type="link"
                      onClick={onUpgrade(rowData)}
                    >
                      <Icon
                        css={css`
                          font-size: 12px;
                          color: ${theme.rownColor};
                        `}
                        name="icon-circle-fill-tip"
                      />
                    </Button>
                  </Tooltip>
                )}
            </div>
          ),
      },
      {
        title: formatMessage(messageIds.apps.tag),
        dataIndex: "tagList",
        render: (tagList: AppTag[] | undefined, rowData) => {
          return !("anonymous" in rowData) ? (
            "-"
          ) : (
            <AppTags
              tags={tagList}
              css={css`
                max-width: 240px;
              `}
            />
          );
        },
      },
      {
        title: formatMessage(messageIds.common.createdBy),
        dataIndex: "createdByName",
      },
      {
        title: formatMessage(messageIds.common.updateTime),
        dataIndex: "modifiedAt",
        render: (text) => {
          return formatDate(text);
        },
      },
      {
        title: formatMessage(messageIds.common.operation),
        dataIndex: "operation",
        render: (_text, rowData) => {
          const isViCodeApp = !("anonymous" in rowData);
          const menuListV1 = appV1Operation(rowData as AppV2);

          const menuList = isViCodeApp
            ? viCodeAppOperationMenus(rowData as ViCodeAppWithPermission)
            : menuListV1;

          const editMenuItem = menuList.find((item) => item.key === "edit");

          return (
            <div
              css={css`
                display: flex;
                justify-content: start;
                align-items: center;
              `}
            >
              <Button
                onClick={
                  editMenuItem?.onClick as
                    | React.MouseEventHandler<HTMLElement>
                    | undefined
                }
                disabled={
                  !editMenuItem?.visible && editMenuItem?.visible !== undefined
                }
                css={css`
                  display: flex;
                  align-items: center;
                  margin-right: 5px;
                  :hover {
                    color: ${theme.primaryColor};
                  }
                `}
              >
                <img src={appEditImage} alt={""}></img>
                {editMenuItem?.name}
              </Button>
              {getHasPermission(rowData.permissionValues) ? (
                <TableOperation
                  menuList={menuList.filter((item) => item.key !== "edit")}
                />
              ) : null}
            </div>
          );
        },
      },
    ],
    [
      appStatusRecord,
      appTypeRecord,
      appV1Operation,
      appV1StatusRecord,
      formatMessage,
      getHasPermission,
      navigateToDetail,
      onUpgrade,
      theme.primaryColor,
      theme.rownColor,
      theme.statusDisabledColor,
      theme.statusEnabledColor,
      viCodeAppOperationMenus,
    ]
  );

  const columns = useMemo(() => {
    if (isViCodeAppTab) {
      return _columns.filter(
        (item) =>
          !["appType", "anonymous", "currentVersion", "tagList"].includes(
            item.dataIndex as string
          )
      );
    } else {
      return _columns;
    }
  }, [_columns, isViCodeAppTab]);

  const appIdByRouterParams = useMemo(() => {
    const params = currentRouteInfo.params as Record<string, string>;
    const { id } = params;
    return id;
  }, [currentRouteInfo]);

  const renderTableList = () => {
    return (
      <List
        title={
          <DescriptionTip tip={formatMessage(messageIds.apps.introduction)}>
            {formatMessage(messageIds.apps.name)}
          </DescriptionTip>
        }
        iconType={iconApp}
        pagination={
          isViCodeAppTab ? vicodeAppListPagination : appV1ListPagination
        }
      >
        {(isViCodeAppTab ? vicodeAppListDataSource : appV1ListDataSource).map(
          (item) => {
            const isViCodeApp = !("anonymous" in item);
            const menuListV1 = appV1Operation(item as AppV2);

            const menuList = isViCodeApp
              ? viCodeAppOperationMenus(item as ViCodeAppWithPermission)
              : menuListV1;

            return (
              <ListItem
                key={item.id}
                active={appIdByRouterParams === item.id}
                onClick={navigateToDetail(item.id, !("anonymous" in item))}
                rightContent={
                  getHasPermission(item.permissionValues) ? (
                    <TableOperation menuList={menuList} />
                  ) : null
                }
              >
                {item.name}
              </ListItem>
            );
          }
        )}
      </List>
    );
  };

  return (
    <Fragment>
      {type === "list" ? (
        renderTableList()
      ) : (
        <Table
          rowKey="id"
          dataSource={isViCodeAppTab ? vicodeAppDataSource : appV1DataSource}
          columns={columns}
          pagination={isViCodeAppTab ? vicodeAppPagination : appV1Pagination}
        />
      )}
    </Fragment>
  );
});
