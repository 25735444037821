import { RobotBusyTimeRank, RobotFailureRateRank } from "@encoo-web/encoo-lib";
import moment from "moment";
import { createModel } from "nyax";
import { DashboardQueryData, RobotJobStateCount } from "src/models/dashboard";
import {
  RobotExecutionDetail,
  RobotSuccessRateRank,
} from "src/models/robotDashboard";
import { ModelBase } from "src/store/ModelBase";

export type RobotExecuteTotalCountInfoType = {
  totalExecutionCount?: number;
  todayExecutionCount?: number;
};

export type RobotBusyTotalBurningTimeInfoType = {
  totalBusyMilliseconds?: number;
  todayBusyMilliseconds?: number;
};

export const todayString = moment(new Date()).format("YYYY-MM-DD");

export const RobotStatusStatisticsUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        dateRange: {
          startDate: moment().subtract(29, "days").format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
        },
        containsSubDepartment: false,
        robotExecutionDetail: null as RobotExecutionDetail | null,
        robotTodayExecutionDetail: null as RobotExecutionDetail | null,
        robotFailureRateRank: null as RobotFailureRateRank[] | null,
        robotSuccessRateRank: null as RobotSuccessRateRank[] | null,
        // robotStateCount: null as RobotStateCount | null,
        robotBusyTimeRank: null as RobotBusyTimeRank[] | null,
        // robotJobStateCount: null as null | RobotJobStateCount,
        // robotJobStateCountByToday: null as null | RobotJobStateCount,
        robotRunStateCount: null as null | RobotJobStateCount,
        robotRunStateCountByToday: null as null | RobotJobStateCount,
      };
    }

    public selectors() {
      return {};
    }

    public reducers() {
      return {
        setDateRange: (
          dateRange: Pick<DashboardQueryData, "startDate" | "endDate">
        ) => {
          this.state.dateRange = dateRange;
        },
        setContainsSubDepartment: (value: boolean) => {
          this.state.containsSubDepartment = value;
        },
        setRobotExecutionDetail: (value: RobotExecutionDetail | null) => {
          this.state.robotExecutionDetail = value;
        },
        setRobotTodayExecutionDetail: (value: RobotExecutionDetail | null) => {
          this.state.robotTodayExecutionDetail = value;
        },
        setRobotFailureRateRank: (value: RobotFailureRateRank[] | null) => {
          this.state.robotFailureRateRank = value;
        },
        setRobotSuccessRateRank: (value: RobotSuccessRateRank[] | null) => {
          this.state.robotSuccessRateRank = value;
        },
        // setRobotStateCount: (value: RobotStateCount | null) => {
        //   this.state.robotStateCount = value;
        // },
        setRobotBusyTimeRank: (value: RobotBusyTimeRank[] | null) => {
          this.state.robotBusyTimeRank = value;
        },
        // setRobotJobStateCount: (value: null | RobotJobStateCount) => {
        //   this.state.robotJobStateCount = value;
        // },
        // setRobotJobStateCountByToday: (value: null | RobotJobStateCount) => {
        //   this.state.robotJobStateCountByToday = value;
        // },
        setRobotRunStateCount: (value: null | RobotJobStateCount) => {
          this.state.robotRunStateCount = value;
        },
        setRobotRunStateCountByToday: (value: null | RobotJobStateCount) => {
          this.state.robotRunStateCountByToday = value;
        },
      };
    }

    public effects() {
      return {
        initializeRequest: async (payload: {
          departmentId: string;
          query: DashboardQueryData;
          containsSubDepartment: boolean;
        }) => {
          const { departmentId, query, containsSubDepartment } = payload;

          const [
            robotRunStateCountData,
            robotRunStateCountByTodayData,
            robotExecutionDetailData,
            robotTodayExecutionDetailData,
            robotSuccessRateRankData,
            robotFailureRateRankData,
            // robotStateCountData,
            robotBusyTimeRankData,
          ] = await Promise.all([
            this.actions.requestRobotRunstatecount.dispatch(payload),
            this.actions.requestRobotRunstatecount.dispatch({
              departmentId,
              query: {
                ...query,
                startDate: todayString,
                endDate: todayString,
              },
              containsSubDepartment,
            }),
            this.actions.requestRobotExecutionDetail.dispatch(payload),
            this.actions.requestRobotExecutionDetail.dispatch({
              departmentId,
              query: {
                ...query,
                startDate: todayString,
                endDate: todayString,
              },
              containsSubDepartment,
            }),
            this.actions.requestRobotSuccessRateRank.dispatch(payload),
            this.actions.requestRobotFailureRateRank.dispatch(payload),
            // this.actions.requestRobotStateCount.dispatch(payload),
            this.actions.requestRobotBusyTimeRank.dispatch(payload),
          ]);

          await this.actions.setRobotRunStateCount.dispatch(
            robotRunStateCountData
          );
          await this.actions.setRobotRunStateCountByToday.dispatch(
            robotRunStateCountByTodayData
          );

          await this.actions.setRobotExecutionDetail.dispatch(
            robotExecutionDetailData
          );
          await this.actions.setRobotTodayExecutionDetail.dispatch(
            robotTodayExecutionDetailData
          );
          await this.actions.setRobotSuccessRateRank.dispatch(
            robotSuccessRateRankData
          );
          await this.actions.setRobotFailureRateRank.dispatch(
            robotFailureRateRankData
          );

          // await this.actions.setRobotStateCount.dispatch(robotStateCountData);
          await this.actions.setRobotBusyTimeRank.dispatch(
            robotBusyTimeRankData
          );
        },
        requestRobotExecutionDetail: async (payload: {
          departmentId: string;
          query: DashboardQueryData;
          containsSubDepartment: boolean;
        }) => {
          const { departmentId, query, containsSubDepartment } = payload;
          return await this.dependencies.serviceClient.dashboard.robotExecutionDetail(
            departmentId,
            query,
            containsSubDepartment
          );
        },
        requestRobotFailureRateRank: async (payload: {
          departmentId: string;
          query: DashboardQueryData;
          containsSubDepartment: boolean;
        }) => {
          const { departmentId, query, containsSubDepartment } = payload;
          return await this.dependencies.serviceClient.dashboard.robotFailureRateRank(
            departmentId,
            query,
            containsSubDepartment
          );
        },

        requestRobotSuccessRateRank: async (payload: {
          departmentId: string;
          query: DashboardQueryData;
          containsSubDepartment: boolean;
        }) => {
          const { departmentId, query, containsSubDepartment } = payload;
          return await this.dependencies.serviceClient.dashboard.robotSuccessRateRank(
            departmentId,
            query,
            containsSubDepartment
          );
        },

        requestRobotRunstatecount: async (payload: {
          departmentId: string;
          query: DashboardQueryData;
          containsSubDepartment: boolean;
        }) => {
          const { departmentId, query, containsSubDepartment } = payload;
          return await this.dependencies.serviceClient.dashboard.robotRunstatecount(
            departmentId,
            query,
            containsSubDepartment
          );
        },

        requestRobotStateCount: async (payload: {
          departmentId: string;
          query: Partial<DashboardQueryData>;
          containsSubDepartment: boolean;
        }) => {
          const { departmentId, query, containsSubDepartment } = payload;
          return await this.dependencies.serviceClient.dashboard.robotStateCount(
            departmentId,
            query,
            containsSubDepartment
          );
        },
        requestRobotBusyTimeRank: async (payload: {
          departmentId: string;
          query: DashboardQueryData;
          containsSubDepartment: boolean;
        }) => {
          const { departmentId, query, containsSubDepartment } = payload;
          return await this.dependencies.serviceClient.dashboard.robotBusyTimeRank(
            departmentId,
            query,
            containsSubDepartment
          );
        },
      };
    }
  }
);
