/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Spin } from "antd";
import Oidc from "oidc-client";
import React, { useCallback, useEffect } from "react";
import { useDependencies, useGetContainer } from "src/hooks";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";
export default React.memo(function TransferToActivateUser() {
  const useDeps = useDependencies();
  const getContainer = useGetContainer();
  const loginUserUIContainer = getContainer(LoginUserUIModel);

  const onRedirect = useCallback(async () => {
    const oidcUserManagerInstance = new Oidc.UserManager(
      useDeps.userManagerSettings
    );
    oidcUserManagerInstance.clearStaleState();
    await oidcUserManagerInstance.removeUser();
    loginUserUIContainer.actions.login.dispatch({});
  }, [loginUserUIContainer.actions.login, useDeps.userManagerSettings]);

  useEffect(() => {
    onRedirect();
  }, [onRedirect]);

  return (
    <Spin
      css={css`
        margin: auto;
      `}
    />
  );
});
