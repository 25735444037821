import { createModel } from "nyax";
import { Asset, AssetQueryData } from "src/models/asset";
import { AssetEntityModel } from "src/store/models/entity/asset/entity";
import { createUISideListWithPermissionModel } from "src/store/models/ui/_shared";

export const AssetSideUIModel = createModel(
  class extends createUISideListWithPermissionModel<Asset, AssetQueryData>({
    setItems: (getContainer, items) =>
      getContainer(AssetEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(AssetEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(AssetEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initial: async (payload: { departmentId: string }) => {
          const { departmentId } = payload;
          this._initial({
            initialAction: (pageIndex: number, pageSize: number) =>
              this.dependencies.serviceClient.assetV2.list(
                departmentId,
                pageIndex,
                pageSize
              ),
          });
        },
      };
    }
  }
);
