import { createModel, createSelector } from "nyax";
import { RobotTaskScheduler } from "src/models/robot";
import { ModelBase } from "src/store/ModelBase";
import { RobotTaskSchedulerEntityModel } from "src/store/models/entity/robotTaskScheduler/entity";
import { RobotTaskSchedulerHelperModel } from "src/store/models/entity/robotTaskScheduler/helper";

export const RobotTaskSchedulerTableUIModel = createModel(
  class extends ModelBase {
    private get _id() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.containerKey!;
    }

    public initialState() {
      return {
        ids: [] as string[],
      };
    }

    public reducers() {
      return {
        setIds: (value: string[]) => {
          this.state.ids = value;
        },
      };
    }

    public selectors() {
      return {
        robotTaskSchedules: createSelector(
          () => this.state.ids,
          () => this.getContainer(RobotTaskSchedulerEntityModel).state.byId,
          (ids, robotTaskScheduleById) => {
            return ids
              .filter((id) => !!robotTaskScheduleById[id])
              .map((id) => robotTaskScheduleById[id]) as RobotTaskScheduler[];
          }
        ),
      };
    }
    public effects() {
      return {
        requestAll: async () => {
          const packages = await this.getContainer(
            RobotTaskSchedulerHelperModel
          ).actions.readAll.dispatch({ robotId: this._id });
          await this.actions.setIds.dispatch(packages.map((item) => item.id));
        },
      };
    }
  },
  { isDynamic: true, isLazy: true }
);
