import { createModel } from "nyax";
import { AppTemplateVersion } from "src/models/appTemplate";
import { AppTemplateVersionEntityModel } from "src/store/models/entity/appTemplateVersion/entity";
import { AppTemplateVersionListModel } from "src/store/models/entity/appTemplateVersion/list";
import { createHelperModel } from "src/store/models/entity/_shared";

// todo mock 接口稳定后删除
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const appTemplate = {
  appTemplateId: "939b7f34-14e8-44fc-a135-9e61293f2a4d",
  description: "Description",
  version: "1.0.0.1",
  status: "Publish",
  verifier: "9ccc1c97-a802-482b-a2d6-23751ff82163",
  verifierName: "ddd",
  verifyTime: "2021-07-14T11:55:35.3216014+00:00",
  verifyDescription: "系统自动审核",
  originAppId: "64ef916f-2a60-4f90-8bd4-c93e9cf17580",
  originAppVersionId: "64ef916f-2a60-4f90-8bd4-c93e9cf17580",
  companyId: "998bb62f-bb27-40dd-bc33-127df2f0462d",
  departmentId: "96bd4481-6dba-42a8-b67c-d4b21976b1d4",
  anonymous: false,
  dataSourceTagMappings: [],
  connAdapters: [
    {
      id: "2d502df3-7505-48c0-a6e3-636cab1b78f9",
      connectionName: "kk",
      connectionType: "ManageDataBase",
      originAppDataSources: [
        { id: "426abb8f-f6c8-4745-8e58-1a69a3ede619", name: "aaaaa" },
      ],
    },
  ],
  rolePermissionAdapters: [
    {
      id: "e9dfd1eb-f1c0-4009-b21c-aaf7e0c037cd",
      name: "普通用户",
      dataSourcePermissionValues: [
        {
          dataSourceId: "426abb8f-f6c8-4745-8e58-1a69a3ede619",
          dataSourceName: "aaaaa",
          permissionValues: ["DataSource_Run", "DataSource_Read"],
        },
      ],
    },
  ],
  createdAt: "2021-07-14T11:55:34.7752157+00:00",
  createdBy: "9ccc1c97-a802-482b-a2d6-23751ff82163",
  createdByName: "ddd",
  modifiedAt: "2021-07-14T11:55:35.3585905+00:00",
  modifiedBy: "9ccc1c97-a802-482b-a2d6-23751ff82163",
  modifiedByName: "ddd",
  id: "a8bb42bb-fc68-4d5d-b00c-0313a1897c4f",
  eTag: "W/\"datetime'2021-07-14T11%3A55%3A35.3971615Z'\"",
};

export const AppTemplateVersionHelperModel = createModel(
  class extends createHelperModel<AppTemplateVersion>({
    setItems: (getContainer, items) =>
      getContainer(AppTemplateVersionEntityModel).actions.setItems.dispatch(
        items
      ),
    getItems: (getContainer) =>
      getContainer(AppTemplateVersionEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(AppTemplateVersionEntityModel).state.byId[id],
    refreshList: (getContainer) =>
      getContainer(AppTemplateVersionListModel).actions.refresh.dispatch({}),
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(AppTemplateVersionEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),

        getById: async (payload: {
          id: string;
          versionId: string;
          force?: boolean;
        }) => {
          const { id, versionId, force = true } = payload;
          return await this._readById({
            id: versionId,
            getByIdAction: async () => {
              const item =
                await this.dependencies.serviceClient.appTemplate.getByVersionId(
                  id,
                  versionId
                );
              return item;
              // todo mock
              // return Promise.resolve(
              //   appTemplate as unknown as AppTemplateVersion
              // );
            },
            force,
          });
        },
      };
    }
  }
);
