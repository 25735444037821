import { createModel } from "nyax";
import { JobQueue, JobQueueQuery } from "src/models/jobQueue";
import { JobQueueEntityModel } from "src/store/models/entity/jobQueue/entity";
import { JobQueueSideUIModel } from "src/store/models/ui/jobQueue/jobQueueSide";
import { createUITableWithPermissionModel } from "src/store/models/ui/_shared";

export const JobQueueTableUIModel = createModel(
  class extends createUITableWithPermissionModel<JobQueue, JobQueueQuery>({
    setItems: (getContainer, items) =>
      getContainer(JobQueueEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(JobQueueEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(JobQueueEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      const superEffects = super.effects();
      return {
        ...superEffects,
        updateQuery: async (params: JobQueueQuery) => {
          await superEffects.updateQuery(params);
          await this.getContainer(
            JobQueueSideUIModel
          ).actions.setQuery.dispatch(this.state.query);
        },
        initial: async () => {
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query: JobQueueQuery
            ) =>
              this.dependencies.serviceClient.jobQueue.list(
                pageIndex,
                pageSize,
                query
              ),
          });
        },
      };
    }
  }
);
