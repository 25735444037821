import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential
} from "@encoo-web/encoo-lib";
import {
  Robot,
  RobotCapability,
  RobotCreationData,
  RobotQuery,
  RobotSettings,
  RobotTaskScheduler,
  RobotUpgradePlanTime,
  tagData
} from "src/models/robot";
import { Runinstance } from "src/models/runInstance";
import { EncooBatchResult, EncooListEntity } from "src/models/_shared";

export const ROBOT_SERVICE_NAME = "robot";

export class RobotServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, ROBOT_SERVICE_NAME);
  }

  async list(
    departmentId: string,
    pageIndex: number,
    pageSize: number,
    query?: RobotQuery
  ): Promise<EncooListEntity<Robot>> {
    const req = this.buildRequestV2({
      url: `/v2/robots`,
      method: "GET",
      query: {
        searchString: query?.searchString,
        statuses: query?.status,
        isDesc: query?.sortOrder === "descend",
        orderBy: query?.orderBy,
        pageIndex,
        pageSize,
      },
      departmentId,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
  async getAll(): Promise<Array<Robot>> {
    const req = this.buildRequestV2({ url: `/v2/robots`, method: "GET" });
    return await super.getAllInternal(req);
  }
  async getTag(): Promise<tagData> {
    const req = this.buildRequestV2({ url: `/v2/robots/tags`, method: "GET" });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getById(robotId: string): Promise<Robot> {
    const req = this.buildRequestV2({
      url: `/v2/robots/${robotId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async getGlobalById(robotId: string): Promise<Robot> {
    const req = this.buildRequestV2({
      url: `/v2/robots/global/${robotId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async create(payload: RobotCreationData): Promise<Robot> {
    const req = this.buildRequestV2({
      url: `/v2/Robots`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  async createGlobal(payload: RobotCreationData): Promise<Robot> {
    const req = this.buildRequestV2({
      url: `/v2/Robots/global`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  async update(
    robotId: string,
    payload: Partial<Omit<Robot, "id">>
  ): Promise<Robot> {
    const req = this.buildRequestV2({
      url: `/v2/robots/${robotId}`,
      method: "PATCH",
      payload: {
        ...payload,
        updateDescription: true,
        updateExceededSettings: false,
      },
    });
    const { body } = await super.sendRequest(req);
    return body.data;
  }
  async upgrade(
    robotId: string,
    planTime: RobotUpgradePlanTime,
    fixedTime?: string | undefined
  ): Promise<Robot> {
    const req = this.buildRequestV2({
      url: `/v2/robots/${robotId}/upgrade`,
      method: "POST",
      payload: {
        mode: planTime,
        upgradeTime: fixedTime,
      },
    });
    const { body } = await super.sendRequest(req);
    return body.data;
  }

  async updateThresholdSettings(
    robotId: string,
    payload: Partial<Omit<Robot, "id">>
  ): Promise<Robot> {
    const req = this.buildRequestV2({
      url: `/v2/robots/${robotId}`,
      method: "PATCH",
      payload: {
        ...payload,
        updateDescription: false,
        updateExceededSettings: true,
      },
    });
    const { body } = await super.sendRequest(req);
    return body.data;
  }

  async getRobotShareToDepartments(robotId: string): Promise<string[]> {
    const req = this.buildRequestV2({
      url: `/v2/robots/${robotId}/share/departments`,
      method: "GET",
    });
    const { body } = await super.sendRequest(req);
    return body.list;
  }

  async updateRobotShareToDepartments(
    robotId: string,
    departmentIds: string[]
  ): Promise<void> {
    const req = this.buildRequestV2({
      url: `/v2/robots/${robotId}/share/departments`,
      method: "PUT",
      payload: { shareTo: departmentIds },
    });
    return await super.updateInternal(req);
  }

  async batchDelete(robotIds: string[]): Promise<EncooBatchResult> {
    const req = this.buildRequestV2({
      url: `/v2/robots/batch/delete`,
      method: "POST",
      payload: {
        ids: robotIds,
      },
    });
    return await super.updateInternal<EncooBatchResult>(req);
  }
  async batchRemove(robotIds: string[]): Promise<EncooBatchResult> {
    const req = this.buildRequestV2({
      url: `/v2/robots/batch/unbindLicense`,
      method: "POST",
      payload: {
        ids: robotIds,
      },
    });
    return await super.updateInternal<EncooBatchResult>(req);
  }
  async batchUpgrade(
    robotIds: string[],
    planTime: RobotUpgradePlanTime,
    fixedTime?: string | undefined
  ): Promise<EncooBatchResult> {
    const req = this.buildRequestV2({
      url: `/v2/robots/batch/upgrade`,
      method: "POST",
      payload: {
        ids: robotIds,
        mode: planTime,
        upgradeTime: fixedTime,
      },
    });
    return await super.updateInternal<EncooBatchResult>(req);
  }

  async batchAssign(robotIds: string[]): Promise<EncooBatchResult> {
    const req = this.buildRequestV2({
      url: `/v2/robots/batch/bindLicense`,
      method: "POST",
      payload: {
        ids: robotIds,
      },
    });
    return await super.updateInternal<EncooBatchResult>(req);
  }

  async delete(robotId: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/robots/${robotId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }

  //#region run instance API
  async getRunInstance(robotId: string): Promise<Runinstance> {
    const req = this.buildRequestV2({
      url: `/v2/robots/${robotId}/runinstance`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }
  async getRemoteDesktop(robotId: string): Promise<string> {
    const req = this.buildRequestV2({
      url: `/v2/robots/${robotId}/remotedesktop`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body.url;
  }

  async createRunInstance(
    robotId: string,
    payload: RobotCreationData
  ): Promise<Robot> {
    const req = this.buildRequestV2({
      url: `/v2/robots/${robotId}/runinstance`,
      method: "PUT",
      payload,
    });
    return await super.createInternal(req);
  }

  async deleteRunInstance(robotId: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/v2/robots/${robotId}/runinstance`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }
  //#endregion

  async getRobotConfig(robotId: string): Promise<RobotSettings> {
    const req = this.buildRequestV2({
      url: `/v2/robots/${robotId}/config`,
      method: "GET",
    });
    const { body } = await super.sendRequest(req);
    return body;
  }

  async updateRobotConfig(
    robotId: string,
    payload: Partial<Omit<RobotSettings, "robotId">>
  ): Promise<RobotSettings> {
    const req = this.buildRequestV2({
      url: `/v2/robots/${robotId}/config`,
      method: "PATCH",
      payload,
    });
    const { body } = await super.sendRequest(req);
    return body;
  }

  async getCapabilities(robotId: string): Promise<RobotCapability> {
    const req = this.buildRequestV2({
      url: `/v2/robots/${robotId}/capabilities`,
      method: "GET",
    });
    const { body } = await super.sendRequest(req);
    return body;
  }

  async getTaskSchedulers(robotId: string): Promise<RobotTaskScheduler[]> {
    const req = this.buildRequestV2({
      url: `/v2/robots/${robotId}/taskSchedulers?pagesize=1000`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body.list;
  }
}
