import { createModel } from "nyax";
import { DataSource, DataSourceFoderType } from "src/models/dataSource";
import { DataSourceEntityModel } from "src/store/models/entity/dataSource/entity";
import { DataSourceListModel } from "src/store/models/entity/dataSource/list";
import { createHelperModel } from "src/store/models/entity/_shared";

export const DataSourceHelperModel = createModel(
  class extends createHelperModel<DataSource>({
    setItems: (getContainer, items) =>
      getContainer(DataSourceEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(DataSourceEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(DataSourceEntityModel).state.byId[id],
    refreshList: (getContainer) =>
      getContainer(DataSourceListModel).actions.refresh.dispatch({}),
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(DataSourceEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        getAll: async (payload: {
          dataSourceFoderType: DataSourceFoderType;
          folderId: string;
        }) => {
          return await this._readAll({
            getAllAction: () => {
              const { dataSourceFoderType, folderId } = payload;
              return this.dependencies.serviceClient.dataSource.getAll(
                dataSourceFoderType,
                folderId
              );
            },
          });
        },
      };
    }
  }
);
