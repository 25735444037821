import { createModel } from "nyax";
import { CoeToDoWork, CoeToDoWorkQuery } from "src/models/coeToDoWork";
import { CoeToDoWorkEntityModel } from "src/store/models/entity/coeToDoWork/entity";
import { CoeToDoWorkSideUIModel } from "src/store/models/ui/coeToDoWork/coeToDoWorkSide";
import { createUITableWithPermissionModel } from "src/store/models/ui/_shared";

export type CoeToDoWorkWithPermission = CoeToDoWork & {
  permissionValues: string[];
};

export const CoeToDoWorkTableUIModel = createModel(
  class extends createUITableWithPermissionModel<CoeToDoWork, CoeToDoWorkQuery>(
    {
      setItems: (getContainer, items) =>
        getContainer(CoeToDoWorkEntityModel).actions.setItems.dispatch(items),
      getItems: (getContainer) =>
        getContainer(CoeToDoWorkEntityModel).getters.items,
      getItem: (getContainer, id) =>
        getContainer(CoeToDoWorkEntityModel).state.byId[id],
      getItemId: (item) => item.id,
    }
  ) {
    public effects() {
      const superEffects = super.effects();
      return {
        ...superEffects,
        updateQuery: async (params: CoeToDoWorkQuery) => {
          await superEffects.updateQuery(params);
          await this.getContainer(
            CoeToDoWorkSideUIModel
          ).actions.setQuery.dispatch(this.state.query);
        },
        initial: async (payload: { departmentId: string }) => {
          const { departmentId } = payload;
          this._initial({
            initialAction: (
              pageIndex: number,
              pageSize: number,
              query: CoeToDoWorkQuery
            ) =>
              this.dependencies.serviceClient.coeWork.toDoList(
                departmentId,
                pageIndex,
                pageSize,
                query
              ),
          });
        },
      };
    }
  }
);
