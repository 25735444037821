import { createModel } from "nyax";
import { AppDataSourceUpdateData, AppV2 } from "src/models/appV2";
import { ModelBase } from "src/store/ModelBase";
export type AppInitConfigStep =
  | "connectionEnvironment"
  | "connectionCreation"
  | "permission";

export const AppInitConfigUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        currentInitConfigApp: null as AppV2 | null,
        currentConfigStep: null as AppInitConfigStep | null,
        updateData: null as null | AppDataSourceUpdateData,
        initSuccessfulModalVisible: false,
      };
    }
    public reducers() {
      return {
        setCurrentConfigStep: (value: AppInitConfigStep | null) => {
          this.state.currentConfigStep = value;
        },
        setModifyUpdateData: (
          value: Partial<AppDataSourceUpdateData> | null
        ) => {
          this.state.updateData = { ...this.state.updateData, ...value };
        },
        setUpdateData: (value: AppDataSourceUpdateData | null) => {
          this.state.updateData = value;
        },
        setCurrentInitConfigApp: (value: AppV2 | null) => {
          this.state.currentInitConfigApp = value;
        },
        setInitSuccessfulModalVisible: (value: boolean) => {
          this.state.initSuccessfulModalVisible = value;
        },
      };
    }
    public effects() {
      return {};
    }
  }
);
