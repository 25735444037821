import { createModel, createSelector } from "nyax";
import messageIds from "src/locales/messageIds";
import { EncooSort } from "src/models/_shared";
import { EncooFileUploadCallbackInfo } from "src/models/file";
import { ModelBase } from "src/store/ModelBase";
import { DataPermissionEntityModel } from "src/store/models/entity/dataPermission/entity";
import { FileEntityModel } from "src/store/models/entity/file/entity";
import { FileHelperModel } from "src/store/models/entity/file/helper";
import { GlobalSelectDepartmentModel } from "src/store/models/logic/globalSelectDepartment";
import { FileItem, FileItemUIModel } from "src/store/models/ui/file/fileItem";
import { UploadingUIModel } from "src/store/models/ui/file/uploading";
import { newGuid } from "src/utils/uuid";

export type FileTabKey = "fileList" | "permissionConfig" | "settings";

export const FileUIModel = createModel(
  class extends ModelBase {
    private get _entityContainer() {
      return this.getContainer(FileEntityModel);
    }
    private get _helperContainer() {
      return this.getContainer(FileHelperModel);
    }
    private get _globalSelectDepartmentModel() {
      return this.getContainer(GlobalSelectDepartmentModel);
    }
    private get _dataPermissionEntityModel() {
      return this.getContainer(DataPermissionEntityModel);
    }

    public initialState() {
      return {
        rootId: null as string | null,
        currentId: null as string | null,
        uploadingInfo: null as EncooFileUploadCallbackInfo | null,
        permissionSettingFileId: null as string | null,
        isShowFilePermission: false,
        tabActiveKey: "fileList" as FileTabKey,
        query: {} as EncooSort,
        dataSource: [] as FileItem[],
        currentPageIndex: 0,
        pageSize: 20,
      };
    }

    public reducers() {
      return {
        setRootId: (rootId: string) => {
          this.state.rootId = rootId;
        },
        setCurrentId: (id: string | null) => {
          this.state.currentId = id;
        },
        setShowFilePermisson: (value: boolean) => {
          this.state.isShowFilePermission = value;
        },
        setPermissionSettingFileId: (id: string | null) => {
          this.state.permissionSettingFileId = id;
        },
        setUploadingInfo: (info: EncooFileUploadCallbackInfo | null) => {
          this.state.uploadingInfo = info;
        },
        setTabActiveKey: (value: FileTabKey) => {
          this.state.tabActiveKey = value;
        },
        setQuery: (value: EncooSort) => {
          this.state.query = value;
        },
        setCurrentPageIndex: (value: number) => {
          this.state.currentPageIndex = value;
        },
        setPageSize: (value: number) => {
          this.state.pageSize = value;
        },
      };
    }

    public selectors() {
      return {
        files: createSelector(
          () => this.state.rootId,
          () => this._dataPermissionEntityModel.state.byId,
          () =>
            this.getContainer(FileItemUIModel, this.state.rootId ?? "").getters
              .file.children,
          (rootId, byId, files) => {
            if (rootId) {
              const files = this.getContainer(FileItemUIModel, rootId).getters
                .file.children;
              return (
                files &&
                files
                  .map((item) => ({
                    ...item,
                    permissionValues: byId[item.id]?.permissionValues ?? [],
                  }))
                  .filter((item) => item.permissionValues.length > 0)
              );
            }
          }
        ),
        currentFile: createSelector(
          () => this.state.currentId,
          () =>
            this._dataPermissionEntityModel.state.byId[
              this.getContainer(FileItemUIModel, this.state.currentId ?? "")
                .getters.file.rootId
            ],
          () =>
            this.getContainer(FileItemUIModel, this.state.currentId ?? "")
              .getters.file,
          (currentId, permission, file) => {
            if (currentId) {
              return file
                ? {
                    ...file,
                    permissionValues: permission?.permissionValues ?? [],
                  }
                : null;
            }
          }
        ),
        dataSource: createSelector(
          (): number => this.getters.currentPageNumber,
          (): number => this.getters.pageSize,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (): any => this.getters.currentFile,
          (current, pageSize, file) => {
            const start = (current - 1) * pageSize;
            const end = current * pageSize;
            const data: FileItem[] = file.children?.filter(
              (child: FileItem) => child.fileType === "File"
            );
            return data ? data.slice(start, end) : undefined;
          }
        ),
        totalCount: createSelector(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (): any => this.getters.currentFile,
          (file) => {
            if (file) {
              return file.children
                ? file.children.filter(
                    (child: FileItem) => child.fileType === "File"
                  ).length
                : 0;
            }
          }
        ),
        pageSize: (): number => this.state.pageSize,
        currentPageNumber: createSelector(
          () => this.state.currentPageIndex,
          (pageIndex) => pageIndex + 1
        ),
        $onChange: createSelector(
          () => (page: number, pageSize: number) =>
            this.actions.changePage.dispatch({ pageNumber: page, pageSize })
        ),
        pagination: createSelector(
          (): number => this.getters.currentPageNumber,
          (): number => this.getters.pageSize,
          (): number => this.getters.totalCount ?? 0,
          (): ((page: number, pageSize: number) => void) =>
            this.getters.$onChange,
          (current, pageSize, total, onChange) => {
            return {
              current,
              pageSize,
              total,
              onChange,
              showTotal: (total: number) =>
                this.dependencies.locale.intl.formatMessage(
                  { id: messageIds.common.totalPage },
                  { total }
                ),
              showSizeChanger: true,
            };
          }
        ),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        changePage: async (payload: {
          pageNumber: number;
          pageSize: number;
        }) => {
          const { pageNumber, pageSize } = payload;
          this.actions.setCurrentPageIndex.dispatch(pageNumber - 1);
          this.actions.setPageSize.dispatch(pageSize);
        },
        updateQuery: async (params: EncooSort) => {
          await this.actions.setQuery.dispatch(params);
        },
        initializeRequest: async () => {
          const rootId = newGuid();
          const query = this.getContainer(FileUIModel).state.query;
          await this.actions.setRootId.dispatch(rootId);
          await this.getContainer(
            FileItemUIModel,
            rootId
          ).actions.requestChildrenFiles.dispatch({ isRoot: true, query });
        },
        requestChildren: async (payload: {
          parentId: string;
          force?: boolean;
        }) => {
          const { parentId, force = true } = payload;
          const query = this.getContainer(FileUIModel).state.query;
          await this.getContainer(
            FileItemUIModel,
            parentId
          ).actions.requestChildrenFiles.dispatch({
            isRoot: false,
            force,
            query,
          });
        },
        createDirectory: async (payload: {
          parentId?: string;
          directoryName: string;
          dataConnectionId?: string;
        }) => {
          const { parentId, directoryName, dataConnectionId } = payload;
          const newParentId = parentId ?? this.state.rootId;
          if (newParentId) {
            return await this.getContainer(
              FileItemUIModel,
              newParentId
            ).actions.createChildDirectory.dispatch({
              directoryName,
              dataConnectionId,
            });
          }
        },
        updateRootFile: async (payload: {
          parentId: string;
          id: string;
          name: string;
          dataConnectionId: string;
        }) => {
          const { parentId, id, name, dataConnectionId } = payload;
          await this._helperContainer.actions.updateRootFile.dispatch({
            parentId,
            id,
            name,
            dataConnectionId,
          });
          await this.actions.requestChildren.dispatch({
            parentId: id,
            force: true,
          });
        },
        delete: async (id: string) => {
          await this.getContainer(FileItemUIModel, id).actions.delete.dispatch(
            {}
          );
        },
        updateCurrentIdByFullName: async (payload: {
          fullName: string | null;
          query: EncooSort;
        }) => {
          const { fullName, query } = payload;
          if (fullName) {
            try {
              const file =
                await this._helperContainer.actions.getFileByFullName.dispatch({
                  fullName,
                });
              if (file) {
                await this.actions.setCurrentId.dispatch(file.id);
                await this.getContainer(
                  FileItemUIModel,
                  file.id
                ).actions.requestChildrenFiles.dispatch({ query });
              }
            } catch (err) {
              await this.actions.setCurrentId.dispatch(null);
            }
          } else {
            await this.actions.setCurrentId.dispatch(null);
          }
        },
        upload: async (payload: { parentId: string; file: File }) => {
          const { parentId, file } = payload;
          const parentContainer = this.getContainer(FileItemUIModel, parentId);
          const query = this.getContainer(FileUIModel).state.query;
          await this.dependencies.serviceClient.file.uploadFile(
            parentContainer.getters.fullName,
            file,
            async (info) => {
              await this.getContainer(FileHelperModel).actions.delete.dispatch({
                id: info.id,
                fullName: info.fileFullName,
              });
            },
            async (info) => {
              await this.getContainer(
                UploadingUIModel
              ).actions.updateUploadCallbackInfo.dispatch(info);
            }
          );
          await this.getContainer(
            FileItemUIModel,
            parentId
          ).actions.requestChildrenFiles.dispatch({ force: true, query });
        },
      };
    }
  }
);
