// eslint-disable-next-line import/no-anonymous-default-export
export default {
  user: {
    introduction:
      "用户信息主要用于管理控制台账户信息，支持账户停用、查看、删除等操作。",
    entityTypeName: "用户",
    name: "姓名",
    nickName: "花名",
    email: "邮箱",
    password: "密码",
    confirmPassword: "确认密码",
    confirmPasswordMessage: "请确认密码",
    passwordMismatch: "请确认输入密码一致",
    initializationMessage:
      "* 请将邮箱及密码发送给用户，账户将在首次登录后被激活。",
    addUserSuccessfully: "添加用户成功",
    phoneNumber: "手机号码",
    isAdmin: "系统管理员",
    setAsAdmin: "设为管理员",
    lastLoginTime: "上次登录时间",
    roles: "角色",
    editRoles: "编辑角色",
    addToTenant: "添 加",
    status: {
      title: "状态",
      all: "全部状态",
      enabled: "启用",
      disabled: "停用",
      inactive: "未激活",
    },
    enable: "启用",
    disable: "停用",
    logout: "退 出",
    createUser: "创建新用户",
    createTenant: "创建新租户",
    tenant: {
      entityTypeName: "租户",
      name: "租户名称",
      nameSubtitle: "目前最多允许创建3个企业版租户",
      namePlaceholder: "请使用真实的公司名称，创建后无法修改",
      nameAlert:
        "提示: 新租户创建之后， 您将自动成为该租户的管理员，同时拥有该租户的所有权限。",
      createTenantSuccess: "租户创建成功",
      createTenantSuccessHint:
        "点击下一步将跳转至新租户，同时请导入许可证编号以激活企业版功能",
    },
    switchCompany: "切换公司",
    userNameInTenant: "用户姓名",
    addUserToTenantTip:
      "* 您可以邀请已经注册过云扩账号的用户进入当前企业租户。",
    userEmail: "用户邮箱",
    userEmailInputTip: "请输入需要添加的用户邮箱",
    userName: "用户姓名",
    userNameInputTip: "请填写用户在当前租户中的名称",
    addMoreUser: "添加更多用户",
    addUserTip: "将通过邮件发送邀请链接，用户可通过邀请链接进行激活",
  },
};
