import { createModel } from "nyax";
import { AppVersionV2 } from "src/models/appV2";
import { AppVersionEntityModel } from "src/store/models/entity/appVersion/entity";
import { createUIListV2Model } from "src/store/models/ui/_shared";

export const AppVersionListModel = createModel(
  class extends createUIListV2Model<AppVersionV2>({
    setItems: (getContainer, items) =>
      getContainer(AppVersionEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(AppVersionEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(AppVersionEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initialIterator: async (payload: {
          appId: string;
          force?: boolean;
        }) => {
          const { appId } = payload;
          this._initial({
            initialAction: (pageIndex: number, pageSize: number) =>
              this.dependencies.serviceClient.appsV2.appVersionList(appId, {
                pageIndex,
                pageSize,
              }),
          });
        },
      };
    }
  }
);
