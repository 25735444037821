import {
  Constants,
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { ViCodeApp, ViCodeAppVersion } from "src/models/vicodeApp";
import { EncooListEntity } from "src/models/_shared";
import {
  ViCodeAppCreationData,
  ViCodeAppQuery,
} from "src/store/models/ui/vicodeApp/_shared";

export const VICODEAPP_SERVICE_NAME = "vicodeApp";

export class ViCodeAppServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, VICODEAPP_SERVICE_NAME);
  }

  async list(
    payload: {
      offset: number;
      limit: number;
    } & ViCodeAppQuery
  ): Promise<EncooListEntity<ViCodeApp>> {
    const { offset, limit, ...restPayload } = payload;
    const req = this.buildRequestV2({
      url: "/apps",
      method: "GET",
      query: {
        ...restPayload,
        offset,
        limit,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getById(appId: string): Promise<ViCodeApp> {
    const req = this.buildRequestV2({
      url: `/apps/${appId}`,
      method: "GET",
    });
    return await super.getByIdInternal(req);
  }

  async create(payload: ViCodeAppCreationData): Promise<ViCodeApp> {
    const req = this.buildRequestV2({
      url: `/apps`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  async update(
    app: Partial<ViCodeApp> & Pick<ViCodeApp, "id">
  ): Promise<ViCodeApp> {
    const req = this.buildRequestV2({
      url: `/apps/${app.id}`,
      method: "PUT",
      payload: app,
    });
    if (app.eTag) {
      req.headers?.set(Constants.HeaderConstants.IF_MATCH, app.eTag);
    }
    return await super.updateInternal(req);
  }

  async delete(appId: string): Promise<boolean> {
    const req = this.buildRequestV2({
      url: `/apps/${appId}`,
      method: "DELETE",
    });
    return await super.deleteInternal(req);
  }

  async release(appId: string): Promise<ViCodeAppVersion> {
    const req = this.buildRequestV2({
      url: `/apps/${appId}/release`,
      method: "POST",
    });
    return await super.updateInternal(req);
  }

  async offline(appId: string): Promise<ViCodeApp> {
    const req = this.buildRequestV2({
      url: `/apps/${appId}/offline`,
      method: "POST",
    });
    return await super.updateInternal(req);
  }

  async copy(appId: string): Promise<ViCodeApp> {
    const req = this.buildRequestV2({
      url: `/apps/${appId}/copy`,
      method: "POST",
    });
    return await super.createInternal(req);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async addViCodeAppFavorite(appId: string) {
    const req = this.buildRequestV2({
      url: `/apps/${appId}/favorites`,
      method: "POST",
    });

    return await super.sendRequest(req);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async deleteViCodeAppFavorite(appId: string) {
    const req = this.buildRequestV2({
      url: `/apps/${appId}/favorites`,
      method: "DELETE",
    });
    return await super.sendRequest(req);
  }

  async getViCodeAppFavoriteList(payload: {
    offset: number;
    limit: number;
  }): Promise<EncooListEntity<ViCodeApp>> {
    const { offset, limit, ...restPayload } = payload;

    const req = this.buildRequestV2({
      url: "/apps/favorites",
      method: "GET",
      query: {
        ...restPayload,
        offset,
        limit,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async uploadViCodeAppIcon(
    appId: string,
    payload?: FormData
  ): Promise<string> {
    const req = this.buildRequestV2({
      url: `/apps/${appId}/icon`,
      method: "POST",
      payload,
    });
    return await super.createInternal(req);
  }

  async getAppVersionById(
    appId: string,
    versionId: string,
    payload: {
      returnConfigData: boolean;
    }
  ): Promise<ViCodeAppVersion> {
    const req = this.buildRequestV2({
      url: `/apps/${appId}/versions/${versionId}`,
      method: "GET",
      payload,
    });

    return await super.getByIdInternal(req);
  }

  async updateAppVersion(
    appId: string,
    versionId: string,
    payload: {
      configData: string;
      // eTag: string
    }
  ): Promise<ViCodeAppVersion> {
    const req = this.buildRequestV2({
      url: `/apps/${appId}/versions/${versionId}`,
      method: "PUT",
      payload,
    });

    return await super.updateInternal(req);
  }
}
