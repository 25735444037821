/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { ReactNode } from "react";
import Text from "src/components/Text";
import { useTheme } from "src/hooks";

export interface ContentItemProps {
  name: ReactNode;
  value: ReactNode;
  background?: boolean;
  className?: string;
  type?: "drawer" | "detail";
}

export default React.memo(function ContentItem(props: ContentItemProps) {
  const theme = useTheme();
  const { name, value, background = false, className, type = "drawer" } = props;

  return (
    <div
      css={css`
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        line-height: 14px;
        word-break: break-all;
      `}
      className={className}
    >
      {type === "drawer" ? (
        <Text
          css={css`
            font-weight: 600;
          `}
        >
          {name}:
        </Text>
      ) : (
        <Text>{name}</Text>
      )}
      <Text
        type="secondary"
        css={[
          css`
            margin-top: 12px;
            min-height: 34px;
          `,
          background &&
            css`
              background-color: ${theme.canvasBackground};
              padding: 10px 13px;
            `,
        ]}
      >
        {value}
      </Text>
    </div>
  );
});
