import { createModel, createSelector } from "nyax";
import { Department } from "src/models/department";
import { ModelBase } from "src/store/ModelBase";
import { DepartmentModel } from "src/store/models/ui/organization/department";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";

export const GlobalSelectDepartmentModel = createModel(
  class extends ModelBase {
    private get _loginUserUIContainer() {
      return this.getContainer(LoginUserUIModel);
    }

    private get _departmentContainer() {
      return this.getContainer(DepartmentModel);
    }

    public initialState() {
      return {
        selectedDepartmentId: null as string | null,
      };
    }

    public selectors() {
      return {
        selectedDepartment: createSelector(
          () => this.state.selectedDepartmentId,
          () =>
            this._departmentContainer.state.departmentTreeData
              ?.rootDepartment ?? null,
          (id, data): Department | null => {
            const treeData = data ? [data] : null;
            if (!id || !treeData) {
              return null;
            }
            return this.getTreeNode(id, treeData);
          }
        ),
        globalSelectDepartmentName: createSelector(
          (): Department | null => this.getters.selectedDepartment,
          (department): string => {
            if (!department) {
              return "";
            }
            return department.name;
          }
        ),
      };
    }

    public reducers() {
      return {
        setGlobalSelectedDepartmentId: (value: string | null) => {
          this.state.selectedDepartmentId = value;
        },
      };
    }

    public effects() {
      return {
        setGlobalSelectedDepartmentId: async (value: string | null) => {
          this.dependencies.httpMiddleware.departmentHeader.setHeaderValue(
            value ?? ""
          );
        },
      };
    }

    private getTreeNode(id: string, data: Department[]): Department | null {
      for (const item of data) {
        if (item.id === id) {
          return item;
        } else if (item.children?.length) {
          const tempValue = this.getTreeNode(id, item.children);
          if (tempValue) {
            return tempValue;
          }
        }
      }
      return null;
    }
  }
);
