// eslint-disable-next-line import/no-anonymous-default-export
export default {
  menu: {
    dashboard: {
      name: "Dashboard",
      overallMonitor: { name: "Overall Monitoring" },
      userFlowStatistics: { name: "User Flow Statistical Table" },
      robotRunStatistics: { name: "Robot Execution Statistical Table" },
      robotStatusStatistics: { name: "Robot Monitoring" },
      queueStatistics: { name: "Robot Group Monitoring" },
    },
    coe: {
      name: "Center of Excellence",
      myWork: { name: "I initiated" },
      toDoWork: { name: "Pending" },
      initiated: { name: "I initiated" },
      pending: { name: "Pending" },
      dataAnalysis: { name: "Data Analysis" },
    },
    rpa: {
      name: "RPA",
      package: { name: "Flow Package Management" },
      workflow: { name: "Flow Deployment" },
      workflowLayout: {
        name: "Flow Sequence",
        editor: {
          name: "Edit Layout",
        },
      },
      robot: { name: "Robot Management" },
      jobQueue: { name: "Job Queue" },
      queue: { name: "Robot Group" },
      log: { name: "Execution History" },
      device: { name: "Computer Management" },
      calendars: { name: "Calendar Management" },
    },
    apps: {
      app: { name: "App Manager" },
      name: "ViCode",
      myApp: { name: "My App" },
      appDev: { name: "App Develop" },
    },
    market: {
      name: "Market",
    },
    aiPlatform: {
      name: "AI Center",
      understanding: {
        name: "Document Understanding",
      },
      ocr: {
        name: "OCR",
      },
      aiManagement: {
        name: "AI Service Management",
      },
    },
    lamoss: {
      name: "Intelligent Knowledge Base",
      knowledge: {
        name: "Knowledge Base",
      },
      application: {
        name: "Application",
      },
      manage: {
        name: "Manager",
      },
    },
    understanding: {
      name: "Document Understanding",
      editor: {
        name: "Edit Template",
      },
      tester: {
        name: "Test Result",
      },
    },
    data: {
      name: "Data Center",
      asset: { name: "Asset Management" },
      file: { name: "File Service" },
      dataTable: { name: "Data Table Management" },
      dataConnection: { name: "Connection Management" },
      dataQueue: { name: "Data Queue" },
    },
    setting: {
      name: "Management",
      organization: { name: "Organizational Structure Management" },
      user: { name: "User Management" },
      role: { name: "Role Management" },
      userManager: { name: "User Management" },
      resourcegroup: { name: "Resource Group Management" },
      auditlog: { name: "Audit Log" },
      license: { name: "License" },
      systemSetting: { name: "System Settings" },
    },
  },
  home: {
    tabs: {
      default: "Default",
      security: "Security Control",
      custom: "Custom",
    },
    welcomePanel: {
      title: "Welcome to use ENCOO HyperAutomation",
      lastLandingTime: "Last Login Time",
      currentCompany: "Current Company",
      enterprise: " Enterprise Edition",
      community: " Individual Edition",
      personal: "Personal Professional Edition",
      applyForEnterprise: "Apply for Enterprise Edition License",
    },
    navigation: {
      title: "Common Navigation",
      dashboard: "Dashboard",
      uploadPackage: "Upload Flow Package",
      processDeployment: "Flow Deployment",
      robotManagement: "Robot Management",
      performLog: "Execution History",
    },
    service: {
      title: "More Services",
      documentationUnderstand: "Document Understanding",
      appManagement: "Application Management",
      dataCenter: "Data Center",
    },
    practicalLink: {
      title: "Quick Links",
      consoleDocument: "PRODUCT DOCUMENTATION",
      consoleCourse: "ENCOO ACADEMY",
      consoleBBS: "ENCOO FORUM",
      consoleAcademy: "ENCOO ACADEMY",
      consoleAcademyCourse: "ENCOO COURSE",
      consoleAcademyDocs: "ENCOO DOCS",
      consoleAPIDocs: "ENCOO API DOCS",
    },
    colltion: {
      title: "Add to Favorites",
      more: "More Favorites",
    },
    packageDownload: {
      title: "Installation Package Download",
      name: "Application Name",
      editName: "ENCOO RPA Studio",
      robotName: "ENCOO RPA Robot",
      releaseTime: "Date",
      version: "Version",
      download: "Download",
    },
    serviceAddress: {
      title: "Service Address",
      address: "Connection String",
      reminder: "(This is used to activate the studio. Click the button)",
      signScuess: "Logging in...",
    },
  },
  header: {
    support: {
      name: "Support",
    },
    notification: { name: "Notification", content: "Deploying" },
  },
};
