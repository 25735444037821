import {
  ApplicationInsights,
  IEventTelemetry,
  Snippet,
} from "@microsoft/applicationinsights-web";
export interface ApplicationInsightsCommonProperties {
  Url: string;
  UserID?: string;
  ConsoleType?: "个人免费版" | "公有云企业版" | "私有化企业版";
  CompanyID?: string;
  CompanyName?: string;
  DepartmentID?: string;
  UserPhone?: string;
  UserEmail?: string;
  Time: string;
  ConsoleVersion?: string;
  BrowserVersion: string;
}

export const prodApplicationInsightsConfig = {
  instrumentationKey: "2698d9c1-8fca-c899-a683-e710cc8602da",
  endpointUrl: "https://dc.applicationinsights.azure.cn/v2/track",
};

export const devApplicationInsightsConfig = {
  instrumentationKey: "1dd4d97e-34c3-cde1-a796-f2db651ad08b",
  endpointUrl: "https://dc.applicationinsights.azure.cn/v2/track",
};
export default class ApplicationInsightsService extends ApplicationInsights {
  _commonProperties: ApplicationInsightsCommonProperties;

  constructor(config: Snippet["config"]) {
    const commonConfig = {
      autoTrackPageVisitTime: false,
      disableAjaxTracking: true,
      enableAutoRouteTracking: false,
      disableTelemetry: false,
    };
    super({ config: { ...commonConfig, ...config } });

    this._commonProperties = {
      Url: window.location.href,
      Time: Date(),
      BrowserVersion: window.navigator.appVersion,
    };
  }

  get commonProperties(): ApplicationInsightsCommonProperties {
    return this._commonProperties;
  }

  set commonProperties(attrs: Partial<ApplicationInsightsCommonProperties>) {
    this._commonProperties = { ...this._commonProperties, ...attrs };
  }

  sendTrackEvent(
    event: IEventTelemetry,
    customProperties?: { [key: string]: unknown }
  ): void {
    return this.trackEvent(event, {
      ...this.commonProperties,
      ...{ Url: window.location.href, Time: Date() },
      ...customProperties,
    });
  }

  sendTrackPageView(customProperties?: { [key: string]: unknown }): void {
    return this.trackPageView({
      properties: {
        ...this.commonProperties,
        ...{ Url: window.location.href, Time: Date() },
        ...customProperties,
      },
    });
  }
}
