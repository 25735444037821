import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import {
  CoeDataAnalysis,
  CoeDataAnalysisQuery,
  CoeDataAnalysisRankingChartLists,
} from "src/models/coeDataAnalysis";
import { EncooListEntity } from "src/models/_shared";

export const COEDATAANALYSIS_SERVICE_NAME = "coeDataAnalysis";
export class CoeDataAnalysisService extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, COEDATAANALYSIS_SERVICE_NAME);
  }

  async list(
    departmentId: string,
    pageIndex: number,
    pageSize: number,
    query?: CoeDataAnalysisQuery
  ): Promise<EncooListEntity<CoeDataAnalysis>> {
    const req = this.buildRequestV2({
      url: `/v2/ROIAnalyz`,
      method: "GET",
      query: {
        pageIndex,
        pageSize,
        start: query?.start,
        end: query?.end,
        orderBy: query?.orderBy,
        isDesc: query?.sortOrder === "descend",
      },
      departmentId,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getTopROI(
    query?: CoeDataAnalysisQuery
  ): Promise<CoeDataAnalysisRankingChartLists["roi"]> {
    const req = this.buildRequestV2({
      url: `/v2/ROIAnalyz/TopN/ROI`,
      method: "GET",
      query: {
        start: query?.start,
        end: query?.end,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
  async getTopSaveLaborElapsed(
    query?: CoeDataAnalysisQuery
  ): Promise<CoeDataAnalysisRankingChartLists["timeSaved"]> {
    const req = this.buildRequestV2({
      url: `/v2/ROIAnalyz/TopN/SaveLaborElapsed`,
      method: "GET",
      query: {
        start: query?.start,
        end: query?.end,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
  async getTopSaveLaborCost(
    query?: CoeDataAnalysisQuery
  ): Promise<CoeDataAnalysisRankingChartLists["costSaved"]> {
    const req = this.buildRequestV2({
      url: `/v2/ROIAnalyz/TopN/SaveLaborCost`,
      method: "GET",
      query: {
        start: query?.start,
        end: query?.end,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async getAccessKey(query: { userId: string }): Promise<string> {
    const req = this.buildRequestV2({
      url: `/v2/ROIAnalyz/download`,
      method: "POST",
      query,
    });
    const { body } = await this.sendRequest(req);
    return body.accessKey;
  }

  async getExportResult(
    accessKey: string,
    query?: CoeDataAnalysisQuery
  ): Promise<string> {
    const req = this.buildRequestV2({
      url: `/v2/ROIAnalyz/download`,
      method: "GET",
      query: {
        start: query?.start,
        end: query?.end,
        orderBy: query?.orderBy,
        isDesc: query?.sortOrder === "descend",
        accessKey,
      },
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
}
