import { AdditionalData, Entity } from "@encoo-web/encoo-lib";

export enum InstallationStatus {
  NotUpload = 0,
  Uploaded = 1,
  Published = 2,
  deleted = 3,
}

export enum ProductSku {
  Community = "Community",
  Enterprise = "Enterprise",
  EnterpriseBasic = "Enterprise_basic",
  EnterprisePro = "Enterprise_pro",
  PdfText = "PdfText",
  PdfImage = "PdfImage",
}

export enum ProductName {
  Studio = "Studio",
  Robot = "Robot",
  Console = "Console",
  App = "App",
  DocumentUnderstanding = "DocumentUnderstanding",
  AndroidAutomation = "AndroidAutomation",
  IOSAutomation = "IOSAutomation",
  AndroidAutomationV2 = "AndroidAutomationV2",
  OCROffline = "OCROffline",
}

export interface InstallationCreationData {
  productName: ProductName;
  sku: ProductSku;
  status: InstallationStatus;
  limit?: number;
}

export interface InstallationPublishedQuery {
  productName?: ProductName;
  sku?: ProductSku;
}

export interface Installation
  extends InstallationCreationData,
    Entity,
    AdditionalData {
  id: string;
  packageName: string;
  version: string;
  publishedAt: string;
  description?: string;
}
