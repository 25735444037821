import { createModel } from "nyax";
import {
  CoeDataAnalysis,
  CoeDataAnalysisQuery,
  CoeDataAnalysisRankingChartLists,
} from "src/models/coeDataAnalysis";
import { CoeDataAnalysisEntityModel } from "src/store/models/entity/coeDataAnalysis/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const CoeDataAnalysisHelperModel = createModel(
  class extends createHelperModel<CoeDataAnalysis>({
    setItems: (getContainer, items) =>
      getContainer(CoeDataAnalysisEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(CoeDataAnalysisEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(CoeDataAnalysisEntityModel).state.byId[id],
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(CoeDataAnalysisEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),

        getTopROI: async (query: { start: string; end: string }) => {
          const data =
            await this.dependencies.serviceClient.coeDataAnalysis.getTopROI(
              query
            );
          return data;
        },
        getTopSaveLaborElapsed: async (query: {
          start: string;
          end: string;
        }) => {
          const data =
            await this.dependencies.serviceClient.coeDataAnalysis.getTopSaveLaborElapsed(
              query
            );
          return data;
        },
        getTopSaveLaborCost: async (query: { start: string; end: string }) => {
          const data =
            await this.dependencies.serviceClient.coeDataAnalysis.getTopSaveLaborCost(
              query
            );
          return data;
        },
        getAllTopNData: async (query: { start: string; end: string }) => {
          const newObj: CoeDataAnalysisRankingChartLists = {};

          await Promise.all(
            [
              async () => this.actions.getTopSaveLaborElapsed.dispatch(query),
              async () => this.actions.getTopSaveLaborCost.dispatch(query),
              async () => this.actions.getTopROI.dispatch(query),
            ].map((item, index) =>
              (async () => {
                try {
                  switch (index) {
                    case 0:
                      newObj.timeSaved = await item();
                      break;
                    case 1:
                      newObj.costSaved = await item();
                      break;
                    case 2:
                      newObj.roi = await item();
                      break;
                  }
                } catch (error) {
                  console.log(error);
                }
              })()
            )
          );

          return newObj;
        },

        getAccessKey: async (query: { userId: string }) => {
          const data =
            await this.dependencies.serviceClient.coeDataAnalysis.getAccessKey(
              query
            );
          return data;
        },
        getExportResult: async (payload: {
          accessKey: string;
          query?: CoeDataAnalysisQuery;
        }) => {
          const data =
            await this.dependencies.serviceClient.coeDataAnalysis.getExportResult(
              payload.accessKey,
              payload?.query
            );
          return data;
        },
      };
    }
  }
);
