import { createModel } from "nyax";
import { RunInstanceLog } from "src/models/runInstance";
import { RunInstanceLogEntityModel } from "src/store/models/entity/runinstanceLog/entity";
import { createListModel } from "src/store/models/entity/_shared";

export const RunInstanceLogListModel = createModel(
  class extends createListModel<RunInstanceLog>({
    setItems: (getContainer, items) =>
      getContainer(RunInstanceLogEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(RunInstanceLogEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(RunInstanceLogEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        maxLimited: (): number => 100000,
      };
    }

    public effects() {
      return {
        ...super.effects(),
        initialIterator: async (payload: {
          runinstanceId: string;
          params?: { logType?: string; logLevel?: string[] };
          force?: boolean;
        }) => {
          const { runinstanceId, params, force = true } = payload;
          this._initialIterator({
            initialAction: () =>
              this.dependencies.serviceClient.runInstance.getListLogsById(
                runinstanceId,
                params
              ),
            force,
          });
        },
      };
    }
  }
);
