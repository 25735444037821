import { createModel } from "nyax";
import { AppTag } from "src/models/appV2";
import { AppTagEntityModel } from "src/store/models/entity/appTag/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const AppTagHelperModel = createModel(
  class extends createHelperModel<AppTag>({
    setItems: (getContainer, items) =>
      getContainer(AppTagEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(AppTagEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(AppTagEntityModel).state.byId[id],
  }) {
    public effects() {
      return {
        ...super.effects(),
        getAll: async () => {
          return await this._readAll({
            getAllAction: async () => {
              return await this.dependencies.serviceClient.appsV2.getAllAppTags();
            },
          });
        },
      };
    }
  }
);
