// eslint-disable-next-line import/no-anonymous-default-export
export default {
  knowledge: {
    name: "Knowledge Base",
    description:
      "Support to upload a variety of files (PDF, WORD, pictures), build exclusive knowledge base.",
    searchName: "Please enter a knowledge base name",
    file: "Knowledge Base File",
    editor: {
      title: "Add Knowledge Base",
      uploadFile: {
        label: "Upload files",
        describe:
          "Up to 20 files can be uploaded, each file needs to be less than 10MG, and supports PDF, Word, txt and image types",
        required: "The uploaded file cannot be empty",
      },
      name: {
        label: "Knowledge Base Name",
        describe:
          "Enter a qualified name for the knowledge base based on the application scenario",
      },
      remark: {
        label: "Upload files",
        describe:
          "Please describe the content and purpose of this knowledge base as closely as possible",
      },
      columns: {
        uploadStatus: {
          label: "Upload Status",
          options: {
            await: "Waiting",
            uploading: "uploading",
            uploaded: "Completed",
            failed: "Failed",
          },
        },
      },
    },
    columns: {
      name: "Knowledge Base Name",
      count: "Number of files (PCS)",
      remark: "Remark",
      lastUpdateTime: "Last Update Time",
      uploadFile: "Upload files",
      operation: "Operation",
      fileName: "Filename",
      index: "Serial Number",
    },
    buttons: {
      test: "Test Session",
      parse: "Parsing",
      failed: "Parsing Failed",
      view: "View",
      delete: "Delete",
    },
    messages: {
      parse: "The knowledge base is being parsed, please try again later",
      failed: "Knowledge base parsing failure",
      complete: "Knowledge base parsing completed",
    },
    error: {
      fileNumber: "Max 20 files are allowed",
      fileSize: "Each file must be less than 10MB",
      fileType: "Only PDF, Word, txt and image types are allowed",
      fileExist: "File already exists",
      fileMax: "A maximum of {max} files can be uploaded",
    },
    analyze: "The knowledge base is being parsed, please try again later.",
  },
  application: {
    name: "Application",
    description:
      "Based on the customized knowledge base, we provide all kinds of interactive robots using large models, and support the collection of Dingding, enterprise wechat and other common software.",
    searchName: "Please enter the application name",
    fields: {
      createApplication: "New Application",
      appName: {
        title: "Application Name",
        describe:
          "Set a simple name for the application based on the application scenario.",
      },
      knowledge: {
        title: "Knowledge Base",
        describe:
          "Select a knowledge base for the application based on the application scenario. The Q&A content is based on the knowledge base.",
      },
      limit: {
        title: "Usage Quota",
        describe: "Set the total limit according to the actual situation.",
        validate: {
          lessThan: "The usage limit must be positive",
        },
      },
      mark: "Remark",
      describe: "Description",
      createTime: "Create Time",
      lastUpdateTime: "Last Update Time",
      operation: "Operation",
      status: "Status",
      interactive: {
        title: "Interactive Mode",
        describe:
          "A specific QR code and webpage link will be generated, and the conversation can be started by scanning the code using wechat or by opening the browser.",
        options: {
          web: "Web/Mobile",
        },
      },
      webpageLink: "Webpage Link",
      validity: "Period of Validity",
      QRCode: "QR Code",
      steps: {
        basic: "Basic Configuration",
        interaction: "Set interaction mode",
      },
      radios: {
        enable: "Enable",
        disabled: "Disable",
      },
      alerts: {
        useMethod:
          "Use wechat to scan the code or open the link in your browser to start the conversation",
      },
    },
    status: {
      isEnable: "Enabled",
      isDisabled: "Disabled",
    },
    unit: {
      count: "Times",
    },
    buttons: {
      switch: "Switch",
      open: "Enable",
      close: "Disable",
    },
  },
  manage: {
    name: "Management",
    description:
      "Self-configure compliance reviews with large model options to make them more relevant to your actual business.",
    tabs: {
      rule: {
        panelName: "Compliance Review",
        description:
          "Set your filtering rules and customize your own audit criteria for your business; The configured rules take effect when the application is added.",
        custom: {
          label: "Custom Rule",
          description: `You can describe the auditing rules you want to set in natural language or by keyword, for example:
          Do not output the following content:
          1. Company financial data: employee salary information, employee performance bonus, company income and expenditure fund flow, etc
          2. Customer privacy information: customer name, mobile phone number, ID card information, etc
            `,
        },
      },
      model: {
        panelName: "Large Scale Model",
      },
    },
  },
};
